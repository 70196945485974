
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "About";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">About</Title>
    <h2 {...{
      "id": "about-scope"
    }}>{`About SCOPE`}</h2>
    <p>{`Scoping Progress in Education (SCOPE) brings together administrative data, household surveys, learning assessments and education finance from various data producers, notably the UNESCO Institute for Statistics, to explore the progress made towards SDG 4, the global education goal.`}</p>
    <p>{`It complements the `}<a parentName="p" {...{
        "href": "https://en.unesco.org/gem-report/allreports"
      }}>{`printed edition`}</a>{` of the Global Education Monitoring Report, enabling users to interact with the data to understand the achievements and challenges of countries and regions as they aim to reach the targets. Shareable and downloadable, users can create images and data files to explore further, print, or use online or in presentations.`}</p>
    <h2 {...{
      "id": "about-the-global-education-monitoring-report"
    }}>{`About the Global Education Monitoring Report`}</h2>
    <p>{`The Global Education Monitoring Report is an editorially independent annual report hosted and published by UNESCO. According to the `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
      }}>{`Education 2030 Framework for Action`}</a>{`, it has the mandate to monitor progress on education in the SDGs and on the implementation of national and international education strategies to help hold all relevant partners to account for their commitments.`}</p>
    <LinkBlock title="Visit the website" url="https://en.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "about-sustainable-development-goal-4"
    }}>{`About Sustainable Development Goal 4`}</h2>
    <p>{`In September 2015, at the United Nations Sustainable Development Summit in New York, Member States formally adopted the `}<a parentName="p" {...{
        "href": "https://sustainabledevelopment.un.org/post2015/transformingourworld"
      }}>{`2030 Agenda for Sustainable Development`}</a>{`. The agenda contains 17 goals, one of which, SDG 4, is to ‘ensure inclusive and equitable quality education and promote lifelong learning opportunities for all’. SDG 4 has seven targets and three means of implementation.`}</p>
    <LinkBlock title="View the targets and associated monitoring frameworks" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="en" mdxType="FederalMinistryGermanyLogo" />
    <p>{`Special thanks go to the German Federal Ministry of Education and Research for its partnership and generous contribution to develop the Education Progress website.`}</p>
    <Aside mdxType="Aside">
      <h2 {...{
        "id": "contact"
      }}>{`Contact`}</h2>
      <hr></hr>
      <p>{`General Email`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`The Team`}</p>
      <p>{`GEM Report Team, UNESCO`}</p>
      <hr></hr>
      <p>{`Our Address`}</p>
      <p>{`Global Education Monitoring Report Team (GEM Report),
UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;