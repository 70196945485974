
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Qualität";
export const lead = "Hochwertige Bildung kann nicht mit Lernergebnissen gleichgesetzt oder darauf reduziert werden. Zu vielen Kindern werden ausreichend ausgebildete Lehrkräfte, eine gute Schulinfrastruktur oder ein sicheres, gewaltfreies Lernumfeld verwehrt.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const QualityFramework = makeShortcode("QualityFramework");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_QUALITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Hochwertige Bildung kann nicht mit Lernergebnissen gleichgesetzt oder darauf reduziert werden. Zu vielen Kindern werden ausreichend ausgebildete Lehrkräfte, eine gute Schulinfrastruktur oder ein sicheres, gewaltfreies Lernumfeld verwehrt.`}</p>
    </blockquote>
    <p>{`Hochwertige Bildung zu messen und zu überwachen, ist schwer, da jedes Land sie anders versteht und definiert. Neben den Lernergebnissen gibt es nicht viele Indikatoren zur Qualitätsmessung innerhalb des globalen Bildungsziels SDG 4.`}</p>
    <h2 {...{
      "id": "lehrerinnen-und-lehrer"
    }}>{`Lehrerinnen und Lehrer`}</h2>
    <IndicatorReference id="4.c.2" mdxType="IndicatorReference">
      <p>{`Lehrerinnen und Lehrer sind der Grundpfeiler von Qualität im Bildungsbereich. Seit dem Jahr 2000 und dem dringenden Bestreben, Grundschulbildung für alle zu erreichen, drängten jedoch viel mehr Schülerinnen und Schüler in die Klassenzimmer und die Rekrutierung von Lehrkräften hielt nicht Schritt. Das Ergebnis sind überfüllte Unterrichtsräume und die Bildungsqualität leidet.`}</p>
      <QualityFramework highlighted="teachers" description="Mehr über den Referenzrahmen für Bildungsqualität erfahren" mdxType="QualityFramework" />
      <p>{`Die folgenden Abbildungen untersuchen die Relation zwischen Schülern und Lehrern an Schulen. In einigen Ländern Subsahara-Afrikas hat sich die Schüler-Lehrer-Relation zwar seit 2000 verbessert, ist aber immer noch schlechter als in den 1980er Jahren.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PTR" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Subsahara-Afrika`}</HighlightByIndex>{` drängten nach dem Vorstoß zwischen 1990 und 2000, die Grundschulbildung kostenlos zu gestalten, schneller neue Schülerinnen und Schüler in die Klassenzimmer, als die Länder neue Lehrkräfte rekrutieren konnten. Infolgedessen stiegen die Schüler-Lehrer-Relationen häufig dramatisch an.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Währenddessen liegt die Relation in `}<HighlightByIndex index="6" mdxType="HighlightByIndex">{`Nordamerika und Europa`}</HighlightByIndex>{` seit 2005 konstant bei etwa 15 Schülerinnen und Schülern pro Lehrkraft.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Unterrichten ist eine herausfordernde Aufgabe und unzureichend vorbereitete Lehrkräfte betreten die Klassenzimmer mit einem ernsten Defizit. Die Länder messen auf zweierlei Art, wie gut sie vorbereitet sind. Lehrkräfte werden folgendermaßen bewertet:`}</p>
    <p>{`• Erstens: ob sie `}<strong parentName="p">{`qualifiziert`}</strong>{` sind, also ob sie entsprechend der nationalen Standards über die erwarteten akademischen Qualifikationen verfügen oder nicht.`}</p>
    <p>{`• Zweitens: ob sie `}<strong parentName="p">{`ausgebildet`}</strong>{` sind, hier wird unabhängig von ihrer Qualifikation bewertet, ob sie eine angemessene Lehrkräfteausbildung erhalten haben oder nicht.`}</p>
    <IndicatorReference id="4.c.1" mdxType="IndicatorReference">
      <p>{`Ausgebildet zu sein, bedeutet, dass Lehrkräfte eine Ausbildung in den Bereichen `}<Tooltip title="Pedagogical knowledge" content="Knowledge on approaches, methods and techniques of teaching." mdxType="Tooltip">{`pädagogisches Wissen`}</Tooltip>{` und `}<Tooltip title="Professional knowledge" content="Knowledge on, for example, the legal frameworks governing the teaching profession." mdxType="Tooltip">{`Fachwissen`}</Tooltip>{` erhalten haben. Einige Programme decken auch `}<Tooltip title="Content knowledge" content="Knowledge of the curriculum, subject matter and use of relevant materials." mdxType="Tooltip">{`inhaltliches Wissen`}</Tooltip>{` ab. Doch die Art der erforderlichen Ausbildung variiert von Land zu Land und relevante Informationen dazu sind selten. Aufgrund dieser Unterschiede ist der Indikator zu ausgebildeten Lehrkräften `}{`[4.c.1]`}{` zwar ein zentrales Element von hochwertiger Bildung, doch einer der am wenigsten vergleichbaren innerhalb des Monitoring-Rahmens zu SDG 4.`}</p>
      <p>{`Wie die folgende Abbildung zeigt, ist die Mehrzahl der Lehrkräfte weltweit qualifiziert, ausgebildet oder beides. Beispielsweise in `}<HighlightByIndex index="4" mdxType="HighlightByIndex">{`Lateinamerika und der Karibik`}</HighlightByIndex>{` sind über 80% der Lehrerinnen und Lehrer ausgebildet.`}</p>
      <p>{`Anders in `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Subsahara-Afrika`}</HighlightByIndex>{`: hier sank der Anteil ausgebildeter Lehrkräfte von 2000 bis 2019 von 84% auf 69%.`}</p>
      <Figure id="TRAINED" mdxType="Figure" />
      <p>{`Für Länder mit einem Mangel an qualifizierten und ausgebildeten Lehrerinnen und Lehrern gibt es keine einfache Lösung. Viele verfügen angesichts der geringen Zahl an Hochschulabsolventen insgesamt nicht über ausreichend verfügbare Lehrkräfte, um dem Bedarf gerecht zu werden. Dies zeigt deutlich, wie eng Probleme innerhalb von Bildungssystemen miteinander zusammenhängen können: Schwierigkeiten in einem Bereich, zum Beispiel dem Abschluss der unteren Sekundarstufe, können leicht zu Auswirkungen an anderer Stelle führen, beispielsweise einer begrenzten Zahl an ausgebildeten Lehrkräften für künftige Schülerinnen und Schüler.`}</p>
    </IndicatorReference>
    <h2 {...{
      "id": "infrastruktur"
    }}>{`Infrastruktur`}</h2>
    <IndicatorReference id="4.a.1e" mdxType="IndicatorReference">
      <p>{`Ein geeignetes Lernumfeld muss über adäquate `}<strong parentName="p">{`Wasserversorgung`}</strong>{` und sanitäre Anlagen verfügen, besonders für Mädchen. Dennoch war eine Wassergrundversorgung nur in 42% der Grundschulen in Subsahara-Afrika, 78% in Ost- und Südostasien und 83% in Zentral- und Südasien verfügbar.`}</p>
      <QualityFramework highlighted="material" description="Mehr über den Referenzrahmen für Bildungsqualität erfahren" mdxType="QualityFramework" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1f" mdxType="IndicatorReference">
      <p>{`Insbesondere `}<strong parentName="p">{`sanitäre`}</strong>{` Anlagen ohne Privatsphäre können nicht als sicher gelten, während Einrichtungen, wo keine Menstruationshygiene praktiziert werden kann, Mädchen davon abhalten können, zur Schule zu gehen. Das Vorhandensein von nach Geschlechtern getrennten Toiletten ist daher ein wesentlicher strategischer Schritt, um die weitverbreitete späte Einschulung in die Grundschule und das Abbrechen der Schule von Mädchen im Lauf der Zeit zu verhindern.`}</p>
      <p>{`Die folgende Abbildung zeigt, dass nicht nur in Subsahara-Afrika, sondern auch in asiatischen Ländern wie Bangladesch, der Demokratischen Volksrepublik Laos und Nepal, weniger als die Hälfte der Grundschulen über nach Geschlechtern getrennte sanitäre Einrichtungen verfügen. In Afghanistan bieten nur 26% der Grundschulen, 56% der unteren und 75% der oberen Sekundarschulen Zugang zu getrennten Sanitäranlagen.`}</p>
      <Figure id="SANITATION" mdxType="Figure" />
      <p>{`Bei der Interpretation dieser Zahlen gilt es, vorsichtig zu sein, da die Definitionen variieren. Einige gehen davon aus, dass extrem einfache Toiletten – wie Grubenlatrinen – angemessene Sanitäranlagen sind, andere zählen hier nur funktionierende Toiletten und wieder andere nur Einrichtungen, die nationalen Standards genügen. `}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1a" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Elektrizität`}</strong>{` ist eine Grundvoraussetzung für ein hochwertiges Lernumfeld und dennoch müssen viele Länder ohne auskommen. In Ländern mit niedrigem Einkommen haben 72% der Grundschulen und 53% der oberen Sekundarschulen keinen Strom.`}</p>
      <p>{`Wie die Abbildung unten zeigt, haben weniger als die Hälfte der Grundschulen in Afghanistan, Kiribati und Nepal Zugang zu elektrischem Strom. In einigen Ländern Subsahara-Afrikas ist der Zugang von Schulen zu Elektrizität extrem selten: in Madagaskar haben 8% der Schulen elektrischen Strom, in der Zentralafrikanischen Republik nur 4% und in Tschad 3%. Ohne Elektrizität kann es zu dunkel sein und Lehrkräfte können keine Computer zu Verwaltungs- oder Weiterbildungszwecken nutzen. Fehlender elektrischer Strom bedeutet auch, dass es weder Lüftung, Kühlung oder Heizung gibt, wodurch Unterrichtsräume für Lehre und Lernen ungeeignet werden können.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1b" mdxType="IndicatorReference">
      <p>{`Kein Strom heißt auch, dass Technologie und das `}<strong parentName="p">{`Internet`}</strong>{` nicht genutzt werden können. Elektrizität ist jedoch nicht der einzige Faktor, der die Verbreitung des Internet verlangsamt. In Ländern mit mittlerem Einkommen verfügten 43% der Grundschulen und 30% der oberen Sekundarschulen zwar über Strom, aber keinen Internetzugang. Selbst wenn Schulen an eine Stromquelle angeschlossen sind, erleben sie häufig Überspannung und partielle Stromausfälle. Die Internetkonnektivität hängt von der nationalen Telekommunikationsinfrastruktur, den geografischen Verhältnissen und der Zahlungsfähigkeit der Schulen ab.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="ELECTRIC" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="EGY" mdxType="ScrollySection">
          <p>{`In `}<strong parentName="p">{`Ägypten`}</strong>{` sind zwar alle Grundschulen mit Stromversorgung ausgestattet, aber nur 71% verfügen über Zugang zum Internet. Dasselbe gilt für 64% der Schulen in Aserbaidschan und 12% in Jordanien.`}</p>
        </ScrollySection>
        <ScrollySection id="ECU,URY" mdxType="ScrollySection">
          <p>{`In vielen lateinamerikanischen Ländern haben Schulen weder Zugang zu Elektrizität noch zum Internet. Nur 41% der Schulen in `}<strong parentName="p">{`Ecuador`}</strong>{` und 5% in Paraguay können angeben, dass sie über beides verfügen. Eine Ausnahme bildet `}<strong parentName="p">{`Uruguay`}</strong>{`, wo 100% der Grundschulen Zugang zu beidem haben.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "gewalt"
    }}>{`Gewalt`}</h2>
    <p>{`Gewalt oder die Androhung von Gewalt an Schulen kann psychologischer, körperlicher oder sexueller Art sein und sowohl im Schulgelände, als auch auf dem Schulweg, Zuhause oder im Cyberspace erfolgen. Häufig kommt es aufgrund ungleicher Machtverhältnisse dazu und oft ist sie die Folge von negativen geschlechterspezifischen Normen und Stereotypen.`}</p>
    <p>{`Während sich die Aufmerksamkeit üblicherweise auf Extremereignisse, wie Schießereien, konzentriert, sind es die verbreiteteren und häufig unbemerkten Formen von Gewalt, zum Beispiel Mobbing, die die größten negativen Auswirkungen auf die Bildung von Kindern und Heranwachsenden haben. Die Daten zu diesen Problemen sind nach wie vor unzureichend, da die bei Datenerhebungen gestellten Fragen mit unterschiedlichen Definitionen von gewalttätigem Verhalten, anderen Zeitrahmen für die Berichtslegung, nicht vergleichbaren Antwortoptionen, Datenschutzregelungen oder ethischen Rahmen arbeiten. Dies erfolgt zusätzlich zu unzureichender Berichtlegung auch für mit Tabus belegten Formen von Gewalt, beispielsweise Mobbing aufgrund von Homophobie.`}</p>
    <QualityFramework highlighted="school" description="Mehr über den Referenzrahmen für Bildungsqualität erfahren" mdxType="QualityFramework" />
    <IndicatorReference id="4.a.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Mobbing`}</strong>{` ist die am umfassendsten dokumentierte Form von Gewalt an Schulen. Es kann körperliche Gewalt, verbale Angriffe und die Absicht einschließen, durch Demütigung oder Ausgrenzung psychisches Leid zu verursachen. Die Abbildung unten zeigt, dass mindestens jeder dritte Heranwachsende – Jungen häufiger als Mädchen – von Mobbing betroffen ist.`}</p>
      <Figure id="BULLY_FIGHT" mdxType="Figure" />
    </IndicatorReference>
    <p><strong parentName="p">{`Physische Gewalt`}</strong>{` umfasst, an körperlichen Auseinandersetzungen an der Schule beteiligt zu sein, eine Waffe – zum Beispiel eine Pistole, ein Messer oder einen Schlagstock – auf dem Schulgelände zu tragen oder damit bedroht zu werden, Mitglied einer Gang zu sein oder ihr nahe zu stehen, beziehungsweise durch eine Mitarbeiterin oder einen Mitarbeiter körperliche Gewalt zu erleiden.`}</p>
    <p>{`Über 30% der Heranwachsenden in 26 Ländern berichteten, dass sie zwischen 2012 und 2017 an körperlichen Auseinandersetzungen beteiligt waren, wobei die Häufigkeit in Tuvalu über 70% erreichte. Auch in den Golfstaaten, in Ländern wie Bahrain, Kuwait und Oman, ist Gewalt an Schulen ein größeres Problem.`}</p>
    <h2 {...{
      "id": "schlussbemerkung"
    }}>{`Schlussbemerkung`}</h2>
    <p>{`International vergleichbare Daten zu den Lehrkräfte-Indikatoren sind zugleich überraschend knapp. Relativ wenige Länder melden zumindest einen Bestand an Lehrkräften, der noch keine Unterrichtsstunden, Lehrerinnen und Lehrer in Verwaltungsposten und andere Komplexitäten berücksichtigt. Es bedarf einer Standardtypologie für ausgebildete Lehrkräfte, damit wir die Differenzen sachgemäß einschätzen und dieses Thema auf die globale Ebene bringen können.`}</p>
    <p>{`Die Bereitstellung von „kinder-, behinderten- und geschlechtergerechten“ Bildungseinrichtungen und „sicheren, gewaltfreien, inklusiven und effektiven Lernumgebungen für alle“ ist, wie dieser Artikel zeigt, in vielen Ländern eine Herausforderung. Zugleich ist es schwierig, Fortschritte in diesem Bereich zu messen, da der entsprechende globale Indikator keine Einzelmessung, sondern eine Zusammenstellung mehrerer Dimensionen darstellt.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.oecd-ilibrary.org/education/education-at-a-glance_19991487"
          }}>{`Education at a glance – OECD`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theirworld.org/news/safe-schools-report-reveals-crises-challenges-action-needed-to-educate-children"
          }}>{`Safe Schools Report – Their World`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/fs46-more-than-half-children-not-learning-en-2017.pdf"
          }}>{`More Than One-Half of Children and Adolescents Are Not Learning Worldwide – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232107"
          }}>{`School-related gender-based violence is preventing the achievement of quality education for all`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;