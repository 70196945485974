
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Равенство";
export const lead = "Во всем мире наблюдается прогресс в достижении гендерного паритета в образовании, однако значительный разрыв между жителями сельских и городских районов, а также между материально обеспеченными и малоимущими слоями населения по-прежнему сохраняется.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const PageLink = makeShortcode("PageLink");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Стремление никого не оставить без внимания проходит красной нитью по всем целям в области устойчивого развития (ЦУР) и четко отражено в пятой задаче цели в области образования ЦУР 4. Такой подход гарантирует, что разработчики политики будут в первую очередь уделять внимание поддержке наиболее отстающих.`}</p>
    </blockquote>
    <p>{`Для мониторинга сокращения разрывов в образовании нам нужны данные в разбивке по `}<strong parentName="p">{`доходам, полу, возрасту, расе, этнической принадлежности, миграционному статусу, инвалидности, географическому положению и другим характеристикам`}</strong>{`, имеющим значение в том или ином национальном контексте, которые собираются в ходе обследования домохозяйств.`}</p>
    <p>{`Начиная с 2010 г. во `}<a parentName="p" {...{
        "href": "https://www.education-inequalities.org/"
      }}>{`Всемирной базе данных о неравенстве в области образования`}</a>{` используются обследования домохозяйств, с тем чтобы показать, насколько велики разрывы в области образования в разных странах. В этой статье обобщаются некоторые ключевые факты и тенденции в области неравенства в образовании, тогда как в статье о `}<PageLink name="finance" mdxType="PageLink">{`финансах`}</PageLink>{` рассматриваются некоторые дополнительные аспекты.`}</p>
    <h2 {...{
      "id": "гендерные-аспекты"
    }}>{`Гендерные аспекты`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`Приведенные ниже графики показывают разрыв в уровне образования между такими группами, как девочки и мальчики или наиболее бедные и богатые. Этот разрыв в области образования измеряется с помощью `}<Tooltip title="индекса паритета" content="In a parity index, if the value is close to 0, it shows the disadvantaged group is very far behind; values close to 1 mean there is parity; values close to 2 mean that the advantaged group is the furthest behind. A parity index of 0.5, for instance, could show that there are 30% of girls but 60% of boys enrolled. A parity index of 1.5 would indicate there were 30% of boys but 60% of girls enrolled." mdxType="Tooltip">{`индекса паритета`}</Tooltip>{`.`}</p>
      <p>{`Несмотря на то, что это отмечается редко, в прошлом поколении наметилось постоянное движение к `}<Tooltip title="гендерному паритету" content="Where there are equal numbers of boys to girls" mdxType="Tooltip">{`гендерному паритету`}</Tooltip>{`. Число девочек и молодых женщин во многих, в основном более богатых, частях мира превысило число мальчиков и молодых мужчин в школах и университетах.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`Любая линия ниже `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`серого`}</HighlightByIndex>{` цвета показывает, что мальчиков или молодых людей зачислено больше, чем девочек.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`B `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`странах Африки к югу от Сахары `}</HighlightByIndex>{`на каждых пятерых молодых мужчин, посещающих высшие учебные заведения, по-прежнему приходится менее 4 молодых женщин.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <HighlightByIndex index="2" mdxType="HighlightByIndex">Центральная и Южная Азия</HighlightByIndex> перешла от крайнего отставания в образовании девочек в 1990 г. к достижению гендерного паритета на первом и втором этапах среднего образования и более высокие показатели зачисления женщин в высшие учебные заведения, главным образом благодаря резкому прогрессу в Индии. В результате этот регион опередил <HighlightByIndex index="0" mdxType="HighlightByIndex">страны Африки к югу от Сахары</HighlightByIndex> по уровню прогресса в достижении гендерного паритета.
        </ScrollySection>
      </ScrollyArea>
      <p>{`В целом в мире с 2000 г. был достигнут значительный прогресс. Вместе с тем анализ ситуации на страновом уровне позволяет выявить сохраняющиеся пробелы: лишь две трети стран достигли гендерного паритета при зачислении в начальную школу, половина на первом этапе среднего образования и четверть на втором этапе среднего образования.`}</p>
      <p>{`Доля стран с большим `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`неравенством в отношении девочек `}</HighlightByIndex>{` сократилась по меньшей мере вдвое на всех уровнях образования по сравнению с 2000 г., но остается в начальной школе на уровне 4%, 8% на первой ступени среднего образования и 14% на второй ступени среднего образования.`}</p>
      <p>{`Когда говорим о гендерном равенстве, мы не должны забывать о мальчиках. Хотя для стран характерно меньшее `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`неравенство в отношении мальчиков`}</HighlightByIndex>{` в начальной школе, оно гораздо более распространено в старших классах средней школы и на следующих этапах образования, причем эта тенденция либо не меняется, либо ухудшается.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`На этой диаграмме чем больше и темнее синяя часть столбика, тем больше стран, в которых `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`мальчики чаще не посещают школу`}</HighlightByIndex>{`, чем девочки. Чем больше и темнее зеленая часть столбика, тем больше стран, в которых`}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`девочки не посещают школу чаще`}</HighlightByIndex>{`, чем мальчики.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
Например, в сфере высшего образования более чем в двух третях стран существуют <HighlightByIndex index="0" mdxType="HighlightByIndex">крайние диспропорции в ущерб мальчикам</HighlightByIndex>, и с течением времени эта ситуация становится все хуже.
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "благосостояние"
      }}>{`Благосостояние`}</h2>
      <p>{`В целом разрыв в уровне образования между девочками и мальчиками не столь велик, как между сельскими и городскими районами и между богатыми и бедными, о чем свидетельствуют обследования домохозяйств.`}</p>
      <p>{`В Пакистане, например, в начальную школу ходят 92% детей из `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`наиболее богатых `}</HighlightByIndex>{` семей, но только 28% из `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`наиболее бедных`}</HighlightByIndex>{`. В Нигерии среднюю школу первой ступени посещают 97% детей из наиболее богатых семей, но только 27% из наиболее бедных.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Красные точки показывают процент посещающих школу детей или молодежи из `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`наиболее бедных `}</HighlightByIndex>{` семей по сравнению с детьми из `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`наиболее богатых`}</HighlightByIndex>{` семей – показанных голубым цветом. В верхней части диаграммы вы можете изменить уровень образования с начального на первый этап среднего образования и выше.`}</p>
        </ScrollySection>
        <ScrollySection id="ALB" mdxType="ScrollySection">
          <p>{`В области высшего образования наибольший разрыв наблюдается в странах со средним уровнем дохода. Например, в `}<strong parentName="p">{`Албании`}</strong>{` 55% детей из самых богатых семей, но менее 1% детей из самых бедных семей учились в том или ином заведении послесреднего образования.`}</p>
        </ScrollySection>
        <ScrollySection id="MNG" mdxType="ScrollySection">
          <p>{`B `}<strong parentName="p">{`Монголии`}</strong>{` соответствующие доли составляли 70% и 10%.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`Еще более показательно то, как меняется разрыв в образовании детей по мере того, как они переходят с одной ступени образования на другую.`}</p>
      <p>{`Приведенный ниже анализ `}<strong parentName="p">{`гендерного`}</strong>{` неравенства в `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Мали`}</HighlightByIndex>{` показывает, в какой степени девочки бросают школу на более высоком уровне образования.`}</p>
      <p>{`Выбор уровня `}<strong parentName="p">{`благосостояния`}</strong>{` в качестве параметра показывает, как неравенство накапливается на протяжении образовательной траектории в ущерб беднейшим слоям населения, при этом почти никто из них не получает послесреднего образования.`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Ребенок редко попадает в одну единственную категорию, что означает, что крайне важно также рассматривать то, как индивидуальные характеристики, такие как пол, взаимодействуют с другими, такими как благосостояние, как показано на следующей диаграмме.`}</p>
    <p>{`В странах Африки к югу от Сахары разрыв между `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`самыми бедными`}</HighlightByIndex>{` и `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`самыми богатыми`}</HighlightByIndex>{` возрастает на протяжении всей образовательной траектории: на каждые 100 сверстников мужского пола высшие учебные заведения посещают почти 80 девочек из самых богатых семей по сравнению с менее чем 45 девочками из самых бедных семей.`}</p>
    <p>{`С другой стороны, в восточной и юго-восточной Азии эти точки уходят вверх от серой линии, поскольку гендерные диспропорции наносят ущерб беднейшим мальчикам. В области высшего образования вероятность посещения школы девочками из самых богатых семей лишь примерно на четверть выше, чем у их сверстников мужского пола с таким же уровнем доходов, тогда как для девочек из самых бедных семей такая вероятность выше на две трети.`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "заключение"
    }}>{`Заключение`}</h2>
    <p>{`Неравенство в области образования между странами и между группами населения внутри стран находится на неприемлемом уровне. Высококачественные дезагрегированные данные являются необходимым условием для разработки соответствующих стратегий сокращения такого неравенства и выполнения в конечном итоге обязательства никого не оставить без внимания в нашем стремлении к достижению ЦУР 4.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`Всемирная база данных о неравенстве в области образования (ВДНО)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Все еще за бортом – Спасите детей`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://ru.unesco.org/gem-report/2019genderreport"
          }}>{`• Наведение мостов для обеспечения гендерного равенства – доклад по гендерным вопросам за 2019 г. ВДМО`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;