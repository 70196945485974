import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "GPIBYWEALTH",
  title: /*i18n*/ {
    id: "fig.equity.GPIBYWEALTH.title",
    defaults: `Gender parity index for selected education indicators, interaction with wealth`,
  },
  caption: /*i18n*/ {
    id: "fig.equity.GPIBYWEALTH.caption",
    defaults: `Disparity accumulates for poor girls in low-income-countries and for poor boys in middle- and high-income-countries.`,
  },
  source: /*i18n*/ {
    id: "fig.equity.GPIBYWEALTH.source",
    defaults: `GEM Report team analysis based on household surveys`,
  },
};
