import React from "react";
import { motion } from "framer-motion";

import { springConfigs } from "../motion";
import * as M from "../../../materials";

const HoverLines = React.memo(
  ({
    showTooltip,
    xPos,
    yPos,
    x1,
    x2,
    y1,
    y2,
  }: {
    xPos: number;
    y1: number;
    y2: number;
    yPos?: number;
    x1?: number;
    x2?: number;
    showTooltip?: "crosshair";
  }): React.ReactElement => (
    <>
      {showTooltip === "crosshair" && (
        <motion.line
          key="scrubberH"
          x1={x1}
          x2={x2}
          y1={yPos}
          y2={yPos}
          stroke={M.divider}
          strokeWidth={1}
          pointerEvents={"none"}
          animate={{
            y1: yPos,
            y2: yPos,
          }}
          transition={springConfigs.gentle}
        />
      )}
      <motion.line
        key="scrubberV"
        x1={xPos}
        x2={xPos}
        y1={y1}
        y2={y2}
        stroke={M.divider}
        strokeWidth={1}
        pointerEvents={"none"}
        animate={{
          x1: xPos,
          x2: xPos,
        }}
        transition={springConfigs.gentle}
      />
    </>
  )
);

export default HoverLines;
