import { Context, Decoder, Mixed, Validation, ValidationError } from "io-ts";
import { reporter } from "io-ts-reporters";
import { E, io, pipe } from "../prelude";
export class DecoderError {
  public readonly type: "DecoderError" = "DecoderError";
  constructor(
    readonly errors: ValidationError[],
    readonly errorMessages: string[]
  ) {}
}

export const mkDecoderError =
  <A>(validation: Validation<A>) =>
  (errors: ValidationError[]): DecoderError => {
    return new DecoderError(errors, reporter(validation));
  };

export const applyDecoder = <I, O>(decoder: io.Decoder<I, O>, data: I) => {
  const validation = decoder.decode(data);
  return pipe(validation, E.mapLeft(mkDecoderError(validation)));
};

export function contramap<IA extends Mixed, IB extends Mixed, O>(
  decoder: Decoder<IA, O>,
  f: (i: IB) => IA
): Decoder<IB, O> {
  return {
    ...decoder,
    validate: (i: IB, c: Context) => decoder.validate(f(i), c),
    decode: (i: IB) => decoder.decode(f(i)),
  };
}

export function keyObject<T extends readonly string[]>(
  arr: T
): { [K in T[number]]: null } {
  return Object.fromEntries(arr.map((v) => [v, null])) as $IntentionalAny;
}
