
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Описание";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">Описание</Title>
    <h2 {...{
      "id": "scope"
    }}>{`SCOPE`}</h2>
    <p>{`В «SCOPE» собраны воедино административные данные, опросы домохозяйств, оценки результатов обучения и информация о финансировании образования, полученные от различных поставщиков данных, в частности Статистического института ЮНЕСКО, для изучения прогресса в достижении ЦУР 4 – глобальной цели в области образования.`}</p>
    <p>{`Данный сайт дополняет `}<a parentName="p" {...{
        "href": "https://ru.unesco.org/gem-report/allreports"
      }}>{`печатное издание`}</a>{` Всемирного доклада по мониторингу образования, позволяя пользователям интерпретировать данные для понимания достижений и проблем стран и регионов в решении поставленных задач. Данные доступны для совместного использования и загрузки, и заинтересованные лица могут создавать изображения и файлы данных для дальнейшего изучения, печати, использования в интернете или в презентациях.`}</p>
    <h2 {...{
      "id": "всемирный-доклад-по-мониторингу-образования"
    }}>{`Всемирный доклад по мониторингу образования`}</h2>
    <p>{`Всемирный доклад по мониторингу образования является независимым в редакционном отношении ежегодным докладом, который готовится и публикуется ЮНЕСКО. В соответствии с `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656_rus"
      }}>{`рамочной программой действий «Образование-2030»`}</a>{`, доклад предназначен для мониторинга прогресса в области образования в рамках ЦУР и осуществления национальных и международных стратегий в области образования, с тем чтобы обеспечить подотчетность всех соответствующих партнеров за взятые обязательства.`}</p>
    <LinkBlock title="Посетите сайт" url="https://ru.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "цель-4-в-области-устойчивого-развития"
    }}>{`Цель 4 в области устойчивого развития`}</h2>
    <p>{`В сентябре 2015 года на Саммите Организации Объединенных Наций по устойчивому развитию в Нью-Йорке государства-члены официально приняли `}<a parentName="p" {...{
        "href": "https://sustainabledevelopment.un.org/post2015/transformingourworld"
      }}>{`Повестку дня в области устойчивого развития на период до 2030 г`}</a>{`. Повестка включает 17 целей, одна из которых – ЦУР 4 – заключается в «обеспечении инклюзивного и справедливого качественного образования и содействии расширению возможностей обучения на протяжении всей жизни для всех». ЦУР 4 включает семь задач и три средства их достижения.`}</p>
    <LinkBlock title="Посмотреть задачи и связанные с ними механизмы мониторинга" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="en" mdxType="FederalMinistryGermanyLogo" />
    <p>{`Особая благодарность выражается Федеральному министерству образования и научных исследований Германии за партнерство и щедрый вклад в разработку сайта «Прогресс в области образования».`}</p>
    <Aside mdxType="Aside">
      <h2 {...{
        "id": "контакт"
      }}>{`Контакт`}</h2>
      <hr></hr>
      <p>{`Общий электронный адрес`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`Группа`}</p>
      <p>{`Группа по подготовке доклада ВДМО, ЮНЕСКО`}</p>
      <hr></hr>
      <p>{`Наш адрес:`}</p>
      <p>{`Global Education Monitoring Report Team (GEM Report),
UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;