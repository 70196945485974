import css from "@emotion/css";
import React from "react";
import { mkStyles, useTheme } from "../../hooks";
import * as M from "../../materials";
import { foldMarkStyle, MarkStyle } from "./types";

const useStyles = mkStyles(({ client }) => {
  const start = client.isRTL ? "right" : "left";
  const end = client.isRTL ? "left" : "right";
  return {
    container: css`
      margin-bottom: 10px;
      direction: ${client.isRTL ? "rtl" : "ltr"};
    `,
    inlineContainer: css`
      margin-bottom: 0px;
      line-height: 12px;
    `,
    title: css`
      ${M.fontChartGroupTitle};
      color: ${M.blackText};
    `,
    label: css`
      ${M.fontChartLabel};
      color: ${M.lightText};
    `,
    labelWithColor: css`
        padding-${start}: 12px;
        position: relative;
      `,
    inlineLabel: css`
        display: inline-block;
        margin-${end}: 12px;
      `,
    color: css`
      position: absolute;
      ${start}: 0px;
      top: 4px;
      width: 8px;
      height: 8px;
    `,
    colorSmall: css`
      position: absolute;
      ${start}: 0px;
      top: 6px;
      width: 4px;
      height: 4px;
    `,
    circle: css`
      border-radius: 50%;
    `,
  };
});

export interface ColorLegendEntry {
  color: string;
  label: React.ReactNode;
  style?: MarkStyle;
}

export interface ColorLegendProps {
  values: Array<ColorLegendEntry>;
  maxWidth: number | string;
  inline?: boolean;
  title?: React.ReactNode;
}

const ColorLegend_ = ({
  title,
  values,
  maxWidth,
  inline,
}: ColorLegendProps) => {
  const { client } = useTheme();
  const styles = useStyles();

  if (!values.length && !title) {
    return null;
  }
  return (
    <div
      css={[styles.container, inline ? styles.inlineContainer : ""]}
      style={{ maxWidth }}
    >
      {!!title && <div css={styles.title}>{title}</div>}
      {values.map(({ label, color, style = "normal" }, i) => {
        let text = label;

        return (
          <div
            key={i}
            css={[
              styles.label,
              inline ? styles.inlineLabel : "",
              !!color ? styles.labelWithColor : "",
            ]}
          >
            {client.isRTL && text}
            {!!color && (
              <div
                css={foldMarkStyle(style, {
                  normal: () => [styles.color, styles.circle],
                  muted: () => [styles.colorSmall, styles.circle],
                  reference: () => [styles.colorSmall, styles.circle],
                })}
                style={{ backgroundColor: color }}
              />
            )}
            {!client.isRTL && text}
          </div>
        );
      })}
    </div>
  );
};
const ColorLegend = React.memo(ColorLegend_);

export default ColorLegend;
