import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_finance_aid_fig_GPE";
import { ChartRenderer, mkGemDataDecoder } from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io } from "../prelude";

export * from "../data/data_finance_aid_fig_GPE";

const Type = io.keyof({
  approved: null,
  disbursed: null,
});
type Type = io.TypeOf<typeof Type>;

const typeLookup: Record<Type, MessageDescriptor> = {
  approved: t("fig.finance.aid.GPE.approved")`Approved`,
  disbursed: t("fig.finance.aid.GPE.disbursed")`Disbursed`,
};

export const Data = mkGemDataDecoder(["value", "ind_id", "year"], {
  type: Type,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const entityData = React.useMemo(
    () =>
      data.map((x) => ({
        ...x,
        type: i18n._(typeLookup[x.type]),
      })),
    [i18n, data]
  );

  return (
    <LineChart
      tLabel={(s) => s}
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      x="year"
      yTicks={[0, 0.2, 0.4, 0.6, 0.8, 1, 1.2]}
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".2f"
      category="datum.type"
      yAxisLabel={i18n._(t("USD billion")`USD billion`)}
      tooltipFormatX={(x) => x.type}
      colorLegend
      values={entityData}
      showTooltip
      yNice={0}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_GPE.json"),
  csv: require("../data/data_finance_aid_fig_GPE.zip"),
  xlsx: require("../data/data_finance_aid_fig_GPE.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
