
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Acceso";
export const lead = "En el plano mundial, el 87% de los niños finaliza la escuela primaria, el 77% de los adolescentes el primer ciclo de secundaria, y el 58% de los jóvenes el segundo ciclo de secundaria.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const PageLink = makeShortcode("PageLink");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`El mundo no cumplió su promesa de que todos los niños y niñas asistieran a la escuela primaria en 2015. El nuevo objetivo en materia de educación, el ODS 4, ha subido aún más el listón, exigiendo que todos los jóvenes terminen la escuela secundaria antes de la fecha límite de 2030. Para lograrlo, todos los niños en edad escolar deberían haber comenzado a asistir a la escuela en 2018, pero en realidad, este era el caso solamente del 70% en los países de bajos ingresos, por ejemplo.`}</p>
    </blockquote>
    <p>{`Alrededor de uno de cada 60 niños, la mayoría de países de bajos ingresos, `}<strong parentName="p">{`nunca irá a la escuela`}</strong>{`. Las niñas siguen teniendo más probabilidades que los niños de no asistir nunca a la escuela, ya que la discriminación que sufren hace que se espere de ellas que se ocupen del cuidado de los niños y de otras tareas domésticas.`}</p>
    <h2 {...{
      "id": "asistencia-a-la-educación-preescolar"
    }}>{`Asistencia a la educación preescolar`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`La asistencia a la educación preescolar`}</strong>{` es un punto de partida decisivo del camino de los niños a lo largo de la educación. No obstante, en los países de bajos ingresos, solo el 43% de los niños está matriculado en la educación preescolar el año anterior a la educación primaria.`}</p>
    </IndicatorReference>
    <p>{`Desde el año 2000, el progreso en relación con la asistencia a la escuela preescolar ha sido lento pero constante en la mayoría de los países, incluso en muchos de los más pobres, pero un gran número aún se queda atrás. En todo el mundo, el 75% de los niños recibe actualmente una educación preescolar, en comparación con el 65% en 2002.`}</p>
    <p>{`En algunos países se han observado mejoras considerables. En Kirguistán, por ejemplo, la tasa de participación aumentó del 42% en 2000 al 84% en 2021. En la República Democrática Popular Lao, se incrementó del 10% al 71% en el mismo período.`}</p>
    <p>
  <HighlightByIndex index="0" mdxType="HighlightByIndex">Guatemala</HighlightByIndex> y
  <HighlightByIndex index="1" mdxType="HighlightByIndex">el Níger</HighlightByIndex>, que se presentan más
  adelante, son dos ejemplos de países que han experimentado un aumento
  constante de la asistencia a la educación preescolar, a pesar de que hoy en
  día hay enormes disparidades entre ambos. Seleccione su país para ver sus
  avances.
    </p>
    <p>{`Tres de cada cuatro niños asisten a la educación preescolar el año antes de ingresar en la educación primaria `}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "participación-a-una-edad-superior-a-la-normal"
    }}>{`Participación a una edad superior a la normal`}</h2>
    <IndicatorReference id="4.1.5" mdxType="IndicatorReference">
      <p>{`No evocamos suficientemente la cuestión de que en algunos países más de una cuarta parte de los alumnos de primaria `}<strong parentName="p">{`supera la edad para el grado`}</strong>{` al matricularse, lo que significa que su edad excede considerablemente a la que sería normal para su grado. Esto repercute en gran medida en su futuro, aumentando la probabilidad de repetir grados, suspender los exámenes y finalmente abandonar la escuela.`}</p>
    </IndicatorReference>
    <p>{`En este gráfico sobre la `}<strong parentName="p">{`participación en la educación`}</strong>{`, se señala la edad de los niños, adolescentes y jóvenes cuando cursan la educación preescolar, primaria, de primer y segundo ciclo de secundaria, y terciaria. Los distintos colores indican cuántos niños han superado la edad para su nivel educativo. La zona sombreada en gris muestra el porcentaje de niños y adolescentes sin escolarizar por edad.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OVERAGE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="PAK" mdxType="ScrollySection">
    En el <Highlight mdxType="Highlight">Pakistán</Highlight>, la mayoría de los niños de 10 años
    debería ir a la escuela, pero el 21% sigue fuera de ella a esa edad. Muchos
    son demasiado mayores para su grado. Hay por ejemplo alumnos de 14 años aún
    en la escuela primaria.
  </ScrollySection>
  <ScrollySection id="LBR" mdxType="ScrollySection">
    La tasa de edad superior al grado es incluso mayor en países como{" "}
    <Highlight mdxType="Highlight">Liberia</Highlight>, donde el 28% de los niños de 10 años está en
    preescolar, y el 20% de los jóvenes de 19 años está aún en la escuela
    primaria.
  </ScrollySection>
  <ScrollySection id="HTI" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Haiti</Highlight> se enfrenta al mismo problema
  </ScrollySection>
  <ScrollySection id="MWI" mdxType="ScrollySection">
    al igual que <Highlight mdxType="Highlight">Malawi</Highlight>.
  </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "niños-sin-escolarizar"
    }}>{`Niños sin escolarizar`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`En el plano mundial, 244 millones de niños y jóvenes `}<strong parentName="p">{`no están escolarizados`}</strong>{`. Un nuevo modelo desarrollado por el Instituto de Estadística de la UNESCO y el Informe de seguimiento de la educación en el mundo indica que la tasa de no escolarización ha descendido más rápidamente de lo previsto, aunque la tasa de descenso se ha ralentizado en los últimos años. El número de niños sin escolarizar en el primer ciclo de la educación secundaria ha permanecido relativamente estable desde 2014.`}</p>
      <p>{`Los conflictos son una de las causas principales de este estancamiento. Lamentablemente, éstos también producen un deterioro de la disponibilidad y la calidad de los datos, aunque algunos países siguen comunicando estimaciones. Se necesita más información, y una recepción constante de ésta a lo largo del tiempo, para evaluar correctamente los efectos de la COVID-19 en la matriculación.`}</p>
      <p>{`Las tasas de no escolarización han  descendido, pero a un ritmo más lento`}</p>
      <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OOSRATE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="NIC,PAN" mdxType="ScrollySection">
Los progresos de <Highlight mdxType="Highlight">Nicaragua</Highlight> y <Highlight mdxType="Highlight">Panamá</Highlight> han seguido
vías muy diferentes. Dentro de la educación primaria y secundaria, la tasa de no
escolarización en Nicaragua ha caído en picado, mientras que en Panamá ha aumentado.
  </ScrollySection>
  <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
          <Highlight mdxType="Highlight">Eritrea</Highlight> y <Highlight mdxType="Highlight">Etiopía </Highlight> también muestran tasas de
progreso muy diferentes. Las secuelas del conflicto en Eritrea afectaron visiblemente el acceso
a la educación, mientras que Etiopía ha dado prioridad a la educación en su presupuesto, lo que
ha conducido a una rápida reducción del número de niños sin escolarizar en un corto período de
tiempo.
  </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Otra razón de que no se avance en la reducción del número de niños sin escolarizar es el crecimiento de la población.`}</p>
    <p>{`En África Subsahariana, `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`la población en edad de cursar educación`}</HighlightByIndex>{` primaria prácticamente se ha duplicado entre 2000 y 2022. En consecuencia, aunque `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`la tasa de niños sin escolarizar`}</HighlightByIndex>{` se ha reducido casi a la mitad durante este período, `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`el número de niños sin escolarizar `}</HighlightByIndex>{` apenas ha cambiado.`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "finalización"
    }}>{`Finalización`}</h2>
    <IndicatorReference id="4.1.2" mdxType="IndicatorReference">
      <p>{`El objetivo final del ODS 4 es la `}<strong parentName="p">{`finalización`}</strong>{` universal del segundo ciclo de la educación secundaria, y no solo la matriculación, a pesar de que, en muchos países, la educación únicamente es gratuita y obligatoria hasta el primer ciclo de la educación secundaria, como mucho.`}</p>
      <p>{`El mundo no está ni siquiera bien encaminado para lograr la finalización universal de la educación primaria en el plazo previsto, y por tanto mucho menos la finalización del segundo ciclo de la secundaria.`}</p>
      <p>{`El indicador se refiere a los niños que pudieran finalizar los ciclos de educación hasta 3 o 5 años después de la edad oficial de graduación, lo que puede definirse como `}<Tooltip title="Finalización a tiempo" content="Niños que finalizan los niveles de educación hasta 3 o 5 años después de la edad normal del último curso" mdxType="Tooltip">{`finalización a tiempo`}</Tooltip>{`. Sin embargo, muchos niños finalizan sus estudios incluso más tarde, lo que puede denominarse `}<Tooltip title="finalización posterior" content="Niños que finalizan los niveles de educación hasta 8 años después de la edad normal del último curso" mdxType="Tooltip">{`finalización posterior`}</Tooltip>{`. `}</p>
      <p>{`En el gráfico a continuación se aprecia que en Kenya, por ejemplo, existe una diferencia de 20 puntos porcentuales entre la `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`finalización a tiempo `}</HighlightByIndex>{` (74%) y la `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`finalización posterior`}</HighlightByIndex>{` (93%) en la educación primaria. La brecha en Mozambique es similar.`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`El momento de matriculación de los niños, su edad y si finalizan o no sus estudios son preocupaciones políticas clave.`}</p>
    <p>{`Los siguientes temas de este sitio examinan qué tan cerca estamos de lograr la `}<PageLink name="equidad" mdxType="PageLink">{`equidad`}</PageLink>{` en la educación, qué están `}<PageLink name="aprendiendo" mdxType="PageLink">{`aprendiendo`}</PageLink>{` los alumnos, qué tan buena es la `}<PageLink name="calidad " mdxType="PageLink">{`calidad `}</PageLink>{` de su educación, y quién la `}<PageLink name="financia" mdxType="PageLink">{`financia`}</PageLink>{`.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-estimates.org"
          }}>{`Visualizing Indicators of Education for the World (VIEW)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000382577"
          }}>{`New estimation confirms out-of-school population is growing in sub-Saharan Africa`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
          }}>{`Education above the odds – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining data on out-of-school children, Completion and learning to offer a more comprehensive view on SDG 4`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;