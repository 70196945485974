import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "GPE",
  title: /*i18n*/ {
    id: "fig.finance.aid.GPE.title",
    defaults: ``,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.GPE.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.GPE.source",
    defaults: ``,
  },
};
