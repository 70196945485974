
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "التقدم المحرز في مجال التعليم";
export const lead = "ينير التعليم كل مرحلة من مراحل السعي إلى مستقبل أفضل. ويحتاج جميع الأطفال إلى الانتفاع من تعليم نوعي يتيح لهم  اكتشاف امكاناتهم في التعلّم والإستفادة من المنافع الأوسع للتعليم. ولا بد من بذل جهود خاصة بغية ضمان استفادة جميع الأطفال والشباب بشكل متكافئ من القوة التحويلية للتعليم. ويتطلب ذلك موارد كافية، ولا سيما من أجل الوصول إلى من هم في أمس الحاجة إلى الدعم.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scope"
      }}>{`SCOPE`}</h1>
      <p>{`ينير التعليم كل مرحلة من مراحل السعي إلى مستقبل أفضل. ويحتاج جميع الأطفال إلى `}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`الانتفاع`}</HighlightByTheme>{` من تعليم `}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`نوعي`}</HighlightByTheme>{` يتيح لهم اكتشاف امكاناتهم في ال`}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`تعلّم`}</HighlightByTheme>{` والإستفادة من المنافع الأوسع للتعليم. ولا بد من بذل جهود خاصة بغية ضمان استفادة جميع الأطفال والشباب بشكل `}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{`متكافئ`}</HighlightByTheme>{` من القوة التحويلية للتعليم. ويتطلب ذلك `}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`موارد`}</HighlightByTheme>{` كافية، ولا سيما من أجل الوصول إلى من هم في أمس الحاجة إلى الدعم.`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`في عام 2015، اعتمد المجتمع الدولي 17 هدفاً للتنمية المستدامة يجب تحقيقها بحلول عام 2030، بما فيها رابع أهداف التنمية المستدامة الخاص بالتعليم. ومنذ ذلك الحين، وُضع `}<a parentName="p" {...{
          "href": "https://unesco-gem-report.interactivethings.io/ar/indicator"
        }}>{`إطار مفصل`}</a>{` لرصد التقدّم المحرز على صعيد تحقيق هذا الهدف. ويقدّم هذا الموقع ملخَّصاً لأهم الوقائع والاتجاهات السائدة في مجال التعليم في جميع أرجاء العالم بالتركيز على خمسة مواضيع:`}</p>
 </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`ويستكمل المعلومات الواردة في النسخة المطبوعة من `}{`[التقرير العالمي لرصد التعليم]`}{`، وهو أداة المجتمع الدولي لمتابعة التقدّم المحرز على صعيد تحقيق رابع أهداف التنمية المستدامة الخاص بالتعليم واستعراض ذلك التقدّم.`}</p>
 </LandingText>
    <LandingButton mdxType="LandingButton">ابدأ القراءة</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;