import { MessageDescriptor } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { format } from "d3-format";
import React from "react";
import { StackedBarAuto } from "../charts-motion";
import { removeTrailingZero } from "../charts/lib/utils";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_finance_fig_AID";
import { ChartRenderer, mkGemDataDecoder } from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, io, O, pipe } from "../prelude";

export * from "../data/data_finance_fig_AID";

const levelOrder = {
  "Basic education": 0,
  "Secondary education": 1,
  "Post secondary": 2,
};

const Level = io.keyof(levelOrder);
type Level = io.TypeOf<typeof Level>;

const levelLookup: Record<Level, MessageDescriptor> = {
  "Basic education": t(
    "fig.finance.AID_TARGETING.basic-education"
  )`Basic education`,
  "Secondary education": t(
    "fig.finance.AID_TARGETING.secondary-education"
  )`Secondary education`,
  "Post secondary": t(
    "fig.finance.AID_TARGETING.post-secondary-education"
  )`Post-secondary education`,
};

export const Data = mkGemDataDecoder(["value", "ind_id", "year"], {
  level: Level,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();

  const chartData = React.useMemo(() => {
    return data.map((x) => ({
      ...x,
      level_name: i18n._(levelLookup[x.level]),
    }));
  }, [i18n, data]);
  type Datum = typeof chartData[number];

  const getStack = React.useCallback((x: Datum) => `${x.year}`, []);
  const getLayer = React.useCallback((x: Datum) => x.level_name, []);
  const getLayerLabel = React.useCallback((x: string) => x, []);
  const getValue = React.useCallback((x: Datum) => x.value || 0, []);
  const formatX = React.useCallback(
    (stack) => {
      return client.screenSDown ? `’${stack.substr(2)}` : stack;
    },
    [client]
  );
  const formatY = React.useMemo(() => removeTrailingZero(format(".1f")), []);
  const getColorPalette = React.useCallback(
    (count) => M.fromCount(M.colorRanges.sequential[0], count),
    []
  );

  return pipe(
    An.fromArray(chartData),
    O.map((xs) => (
      <StackedBarAuto
        key={"linter-map"}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        data={xs}
        getStack={getStack}
        getLayer={getLayer}
        getLayerLabel={getLayerLabel}
        getValue={getValue}
        formatX={formatX}
        formatY={formatY}
        colorPalette={getColorPalette}
        yUnitLabel={<Trans>USD billion</Trans>}
        showTooltip
      />
    )),
    O.toNullable
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_AID.json"),
  csv: require("../data/data_finance_fig_AID.zip"),
  xlsx: require("../data/data_finance_fig_AID.xlsx"),
  metadata,
  Data,
  Chart,
});
