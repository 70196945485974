
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "资金";
export const lead = "One in four countries did not reach either of the finance benchmarks set in the Education 2030 Framework for Action.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`没有额外的资源，宏大的教育目标将无法及时实现，特别是在最为落后的国家。`}</p>
    </blockquote>
    <p>{`为了实现 2030 年普遍完成中学教育的具体目标，低收入和中低收入国家的年支出需要从 2012 年的 1 490 亿美元增加到 2030 年的 3400 亿美元，亦即国内生产总值（GDP）的 6.3%。然而，仅有更多资源可能还不够；在教育支出占人均收入比例相同的国家，其教育结果也可因其支出是否公平和高效而大不相同。`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`在全世界每年 4.7 万亿美元的教育支出中，低收入国家的支出仅占 0.5%，高收入国家则为 65%，尽管这两个群体的学龄儿童数量大致相等。`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`从全球看，`}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`家庭`}</HighlightByPalette>{`承担了教育费用的五分之一（20%）。但是，低收入国家的家庭教育支出（29%）高于高收入国家的家庭教育支出（18%）。`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`尽管低收入国家支出的 12%是由`}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`捐助方所捐助`}</HighlightByPalette>{`...`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`……但是`}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`捐助方的捐`}</HighlightByPalette>{`款仅占全球总支出额的 0.3%，其绝对值几乎微不足道`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "公共支出"
    }}>{`公共支出`}</h2>
    <p><a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
      }}>{`2030 年教育行动框架`}</a>{`为各国政府规定了两个重要的财务基准，呼吁各国：`}</p>
    <ul>
      <li parentName="ul">{`将至少 4%到 6%的国内生产总值拨给教育；及/或`}</li>
      <li parentName="ul">{`将至少 15%到 20%的公共支出拨给教育。`}</li>
    </ul>
    <p>{`从全球平均情况看，各国政府距离实现这些标准并不遥远。各国政府的平均支出占国内生产总值的 4.4%，从东亚和东南亚的 3.4%到拉丁美洲和加勒比的 5.1%不等。拨款占公共总支出的 14.1%，地区平均水平从欧洲和北美的 11.6%到拉丁美洲和加勒比的 18%不等。`}</p>
    <p>{`但是总体而言，四分之一的国家未达到上述两项基准中的任何一项。选择查看不同时间的数据，可以看到这些年来支出的波动情况。`}</p>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`两个关键特征可以决定政府的教育支出：`}<strong parentName="p">{`税收`}</strong>{`和`}<strong parentName="p">{`学龄人口`}</strong>{`。举例来说，即使所有国家的预算都同样优先考虑教育，税收较高和其他国内资源较多的国家在教育上投入的资金也会更多。`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`此外，即使各国税收额相同，人口中儿童和青少年人口比例更大的国家对每个学龄儿童的支出也会更少。`}</p>
    </IndicatorReference>
    <p>{`尽管如此，在其他方面情况相似的国家之间，教育支出仍存在巨大差异，这表明在这些关联当中没有任何因素可以预先确定。一些国家决定在教育上支出更多，以实现其目标。但是，我们应重点关注那些没有这样做的国家。`}</p>
    <h2 {...{
      "id": "援助支出"
    }}>{`援助支出`}</h2>
    <p>{`尽管需要更多资金，但是资金并不能仅靠困难最大的国家自行筹集，而是需要各国团结一致。《全球教育监测报告》估计，2015—2030 年期间，低收入和中低收入国家`}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232197_chi"
      }}>{`每年至少有 390 亿美元的资金缺口`}</a>{`：这将意味着要将向这些国家提供的援助额提高到 2012 年的 6 倍。这一支出并非不切实际：如果经合组织发展援助委员会（DAC）所有捐助方和特定的非发展援助委员会捐助方从国民总收入（GNI）中拨出 0.7%用于援助，并将其援助组合的 10%用于基础教育和中等教育，上述目标便可以实现。`}</p>
    <p>{`然而，教育援助在 2002–2009 年间翻了一番多，之后便停滞不前了。`}</p>
    <Figure id="AID" mdxType="Figure" />
    <p>{`不仅援助水平停滞不前，而且其分配流向也不公平。要做到分配公平，就必须优先支助最贫困国家，优先支助包括学前教育至初中教育在内的基础教育。但是如下图所示，实际情况并非如此。`}</p>
    <IndicatorReference id="4.5.5" mdxType="IndicatorReference">
      <p>{`向低收入国家提`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`供的教育援助总额所占份`}</HighlightByIndex>{`额从 2002 年的 25%下降到了 2016 年的 22%。`}</p>
      <p>{`同一时期，尽管小学适龄失学儿童有 35%在最贫困国家，`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`但是向低收入国家提供的援助总额中基础教育所占份`}</HighlightByIndex>{`额仍然从 37%下降到了 27%。`}</p>
      <Figure id="AID_LICS" mdxType="Figure" />
    </IndicatorReference>
    <p>{`在教育发展水平相同的国家，捐助方为每名儿童支出的数额也大不相同。例如，虽然利比里亚和毛里塔尼亚都有大约一半的儿童完成小学教育，但是利比里亚平均每个学龄儿童得到的基础教育援助额却相当于毛里塔尼亚的十倍。`}</p>
    <p>{`从下图所显示的 2002—2017 年双边和多边捐助方信息即可看出哪些捐助方支出较为公平。例如，英国`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`英国`}</HighlightByIndex>{`基础教育的教育援助总额百分比从 2006 年 81%的峰值下降到了 2017 年的 55%。同一时期，瑞典向低收入国家分配的教育援助总额份额从 58%的峰值下降到了 39%。`}</p>
    <p>{`经合组织发展援助委员会的捐助方`}<a parentName="p" {...{
        "href": "https://www.oecd.org/dac/stats/the07odagnitarget-ahistory.htm"
      }}>{`普遍同意`}</a>{`将其国民总收入的 0.7%用于官方发展援助，至少作为一项长期目标，但是也有一些值得注意的例外：如瑞士和美国。截至 2017 年，只有 7 个捐助方——丹麦、`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`德国`}</HighlightByIndex>{`德国、卢森堡、挪威、土耳其、阿联酋和英国——实现了目标。`}</p>
    <Figure id="AID_TARGETING" mdxType="Figure" />
    <p>{`下表按教育层级分析了全球十个最大的捐助方和十个最大的受援方之间的援助流动情况。`}</p>
    <p>{`例如，在这些国家当中，德国看上去是最大的单一教育捐助方。然而，其援助的很大部分流向了高等教育，表明这些资源主要用到了在德国的外国学生（主要是中国学生）身上，而不一定是用于提高基础教育的公平性和质量。`}</p>
    <Figure id="AID_FLOWS" mdxType="Figure" />
    <p>{`总体而言，捐助方的基础教育援助约有四分之一（24%）分配到了失学儿童占全世界一半（54%）的撒哈拉以南非洲。本图显示最大的教育捐助方优先考虑的是哪些国家、哪些层级。`}</p>
    <p>{`例如，法国 2017 年向该地区最大的十个受援国中的四个提供了 1.3 亿美元的援助，但是其中绝大部分用于高等教育，可能是以奖学金的形式支助非洲学生赴法学习。`}</p>
    <p>{`捐助方的全球协调一致对教育领域非常重要。然而，本图显示大量捐助方是向任意一个国家提供捐赠，尽管这样做可能会给相关国家的政府在国家层面管理这些关系带来额外压力。`}</p>
    <Figure id="AID_FLOWS_SSA" mdxType="Figure" />
    <p>{`##家庭支出`}</p>
    <p>{`几乎每个国家的国家统计局都会在收入和支出调查中衡量家庭教育支出情况。`}</p>
    <p>{`这项支出可以是学费和其他费用，也可以用于购买教育产品和服务，既包括必需品（如校服、教科书），也包括非必需品（如私人课程）。`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`各国的此类数据虽拥有统一标准，但家庭对教育总支出的贡献却十分惊人。这可能会改变人们对国家教育投入的理解。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,UGA,ARG,FRA" mdxType="ScrollySection">
          <p>{`例如，在 2013-2015 年，`}<strong parentName="p">{`乌干达`}</strong>{`是政府支出占国内生产总值百分比最低的国家之一。然而，若将家庭的贡献考虑在内，乌干达的全国教育总支出占国内生产总值的百分比与`}<strong parentName="p">{`阿根廷`}</strong>{`相近，高于`}<strong parentName="p">{`法国`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,SLV,IDN,FRA,NLD" mdxType="ScrollySection">
          <p><strong parentName="p">{`萨尔瓦多`}</strong>{`和`}<strong parentName="p">{`印度尼西亚`}</strong>{`政府的教育支出占国内生产总值的比例比`}<strong parentName="p">{`法国`}</strong>{`和`}<strong parentName="p">{`荷兰`}</strong>{`（蓝条）低两个百分点，但是两国的总支出额更多，因为家庭的教育支出超过了国内生产总值的 3 个百分点（红条）。`}</p>
        </ScrollySection>
        <ScrollySection id="relative,KHM,TJK,UGA" mdxType="ScrollySection">
          <p>{`在三分之一的低收入和中等收入国家，家庭支出至少占教育总支出额的三分之一，而在`}<strong parentName="p">{`柬埔寨`}</strong>{`, `}<strong parentName="p">{`塔吉克斯坦`}</strong>{`和`}<strong parentName="p">{`乌干达`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "结论"
    }}>{`结论`}</h2>
    <p>{`仅有很少的国家长期及时且一贯地提供数据，介绍教育支出的数额和来源。`}</p>
    <p>{`除了需要增加可用于教育的资金以外，还需要加强教育部门的融资诊断工具。实现可持续发展目标 4 这一全球教育目标，需要让各国、各捐助方对其承诺负起责任。`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://report.educationcommission.org/report/"
          }}>{`The Learning Generation – Investing in education for a changing world`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/14/aid-to-education-falls-slightly-in-2017-shifts-away-from-primary-education/"
          }}>{`Aid to education falls slightly in 2017 – GEM Report press release`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232197_chi"
          }}>{`Pricing the right to education – GEM Report policy paper 2015`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;