import { useNextClientRouteParams } from "../hooks";
import { Locale } from "../locales";
import { getRoute, REnv } from "../routes";
import { useQueryParamsWithDefault } from "./useQueryParams";

export const useLocaleLink = (locale: Locale) => {
  const { query } = useNextClientRouteParams();
  const { alias } = useQueryParamsWithDefault(REnv);

  const mkRoute = getRoute(alias);
  return [
    mkRoute({ ...query, alias, locale }),
    { isActive: locale === query.locale },
  ] as const;
};
