import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "ODA_GDP",
  title: /*i18n*/ {
    id: "fig.finance.aid.ODA_GDP.title",
    defaults: `Only a few countries spend more than 0.7% of their income on aid`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.ODA_GDP.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.ODA_GDP.source",
    defaults: `GEM Report team analysis based on OECD CRS`,
  },
};
