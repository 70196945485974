
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "نبذة عنا";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">نبذة عنا</Title>
    <h2 {...{
      "id": "scope"
    }}>{`SCOPE`}</h2>
    <p>{`يعمل SCOPE على جمع ما ورد من مختلف منتجي البيانات، ولا سيما معهد اليونسكو للإحصاء، بما في ذلك البيانات الإدارية والدراسات الاستقصائية الخاصة بالأسر المعيشية وعمليات تقييم التعلّم وتمويل التعليم، وذلك بغية الكشف عن التقدّم المحرز على صعيد تحقيق رابع أهداف التنمية المستدامة الخاص بالتعليم.`}</p>
    <p>{`يعد هذا الموقع مكملاً للنسخة المطبوعة من التقرير العالمي لرصد التعليم، إذ يتيح للمستخدمين التفاعل مع البيانات لفهم ما حقّقته البلدان والمناطق من إنجازات وما تواجهه من تحديات في سعيها إلى تحقيق الأهداف. وتتيح هذه البيانات التي يمكن تبادلها وتنزيلها للمستخدمين استحداث ملفات الصور وملفات البيانات لمعرفة المزيد، وطباعتها أو استخدامها على الإنترنت أو في عروض.`}</p>
    <h2 {...{
      "id": "معلومات-عن-التقرير-العالمي-لرصد-التعليم"
    }}>{`معلومات عن التقرير العالمي لرصد التعليم`}</h2>
    <p>{`التقرير العالمي لرصد التعليم هو تقرير سنوي يعدّه فريق مستقل وتتولى اليونسكو نشره. ووفقاً لإطار العمل الخاص بالتعليم حتى عام 2030، الغرض منه هو متابعة التقدّم المحرز في تحقيق هدف التنمية المستدامة الخاص بالتعليم وفي تنفيذ الاستراتيجيات التعليمية الوطنية والدولية بغية المساعدة على مساءلة جميع الشركاء المعنيين عن الوفاء بتعهداتهم.`}</p>
    <LinkBlock title="قُم بزيارة الموقع الإلكتروني" url="https://en.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "معلومات-عن-الهدف-4-للتنمية-المستدامة"
    }}>{`معلومات عن الهدف 4 للتنمية المستدامة`}</h2>
    <p>{`خلال مؤتمر القمة المعني بالتنمية المستدامة الذي عقد في أيلول/سبتمبر 2015 في نيويورك، قامت الدول الأعضاء بتبني خطة التنمية المستدامة لعام 2030. وتتضمّن هذه الخطة 17 هدفاً للتنمية المستدامة، ومنها الهدف 4 المتمثل في "ضمان التعليم الجيد المنصف والشامل للجميع وتعزيز فرص التعلّم مدى الحياة للجميع". ويتضمّن الهدف 4 للتنمية المستدامة سبع غايات وثلاث وسائل تنفيذ.`}</p>
    <LinkBlock title="اطلع على الغايات وأطر المتابعة ذات الصلة" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="en" mdxType="FederalMinistryGermanyLogo" />
    <p>{`نتوجّه بالشكر الخاص إلى الوزارة الاتحادية الألمانية للتعليم والبحوث على شراكتها والمساهمة السخية التي قدّمتها من أجل إنشاء الموقع الإلكتروني الخاص بالتقدّم المحرز في مجال التعليم.`}</p>
    <Aside mdxType="Aside">
  
## معلومات الاتصال
      <hr></hr>
      <p>{`البريد الإلكتروني العام`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`الفريق`}</p>
      <p>{`فريق التقرير العالمي لرصد التعليم، اليونسكو`}</p>
      <hr></hr>
      <p>{`عنواننا
فريق التقرير العالمي لرصد التعليم، (GEM Report) UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;