
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "SDG 4指标";
export const lead = "确保包容和公平的优质教育，让全民终身享有学习机会";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const TargetFourLogo = makeShortcode("TargetFourLogo");
const Title = makeShortcode("Title");
const IndicatorSection = makeShortcode("IndicatorSection");
const IndicatorTable = makeShortcode("IndicatorTable");
const IndicatorRow = makeShortcode("IndicatorRow");
const ThemeLabel = makeShortcode("ThemeLabel");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TargetFourLogo mdxType="TargetFourLogo" />
    <Title mdxType="Title">SDG 4指标</Title>
    <p>{`确保包容和公平的优质教育，让全民终身享有学习机会`}</p>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-41"
      }}>{`目标 4.1`}</h2>
      <p>{`到 2030 年，确保所有女孩和男孩完成免费、公平和优质的小学和中学教育，获得有用和有效的学习成果 1 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=236"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.1.0" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`为未来做好准备的儿童/青少年比例，按性别分列`}</p>
          <ThemeLabel theme="learning" id="4.1.0" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`儿童和青年人的比例：（a）在 2/3 年级；（b） 在小学结束时；以及（c）在初中结束时（按性 别）至少达到（i）阅读和（ii）数学的最低熟练 程度，按性别划分`}</p>
          <ThemeLabel theme="learning" id="4.1.1a" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`完成率(初等教育, 初中教育, 高中教育)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`最后一年级总吸纳率（小学教育、初中教育）`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`失学率 （小学教育的前一年、小学教育、初中教育、高中教育）`}</p>
          <ThemeLabel theme="access" id="4.1.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`年级 超龄儿童百分比 （小学教育、 初中教育）`}</p>
          <ThemeLabel theme="access" id="4.1.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`实施具有全国代表性的学习评估：（a）2或3年级；（b）小学教育结束；（c）初中教育结束 `}</p>
          <ThemeLabel theme="access" id="4.1.6" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`法律框架保障的教育年数： (a) 免费和 (b) 义务小学和中学教育`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-42"
      }}>{`目标 4.2`}</h2>
      <p>{`到 2030 年，确保所有女童和男童都能获得优质的儿童早期发展、保育和学前教育，使其为小学教育做好准备. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=253"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.2.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`在保健、学习和社会心理健康方面发育正常的、24至59个月大的儿童比例，按性别分列`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`有组织学习(小学入学正规年龄的一年前)的参与率，按性别分列`}</p>
          <ThemeLabel theme="access" id="4.2.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.2.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{` 5 岁以下儿童有积极和鼓励的家庭学习环境体验的百分比`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`学前毛入学率 儿童早期教育的净入学率：（a）学前教育 和（b）早期儿童教育开发`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`法律框架保障的教育年数：(a) 免费和(b)义务学前教育`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-43"
      }}>{`目标 4.3`}</h2>
      <p>{`到 2030 年，确保所有妇女和男子平等地获得可负担和优质的技术、职业和高等教育，包括大学 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=263"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.3.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`过去12个月青年和成年人正规和非正规教育和培训的参与率，按性别分列`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`高等教育的总入学率，按性别分列 `}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{` 技术和职业方案的参与率（15 至 24 岁），按性别分列`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-44"
      }}>{`目标 4.4`}</h2>
      <p>{`到 2030 年，大幅增加具有相关技能（包括技术和职业技能）、就业、体面工作和创业精神的青年和成年人的人数 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=271"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.4.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{` 掌握信通技术技能的青年和成年人的比例，按技能类型分列`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.2" mdxType="IndicatorRow">
          <p>{`至少达到数字扫盲技能最低熟练水平的青年/成年人百分比`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`青年/成年人受教育程度的百分比，按年龄组和教育级别分列`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-45"
      }}>{`目标 4.5`}</h2>
      <p>{`到 2030 年，消除教育中的性别差距，确保弱势群体（包括残疾人，土着人民和处于脆弱境况的儿童）在各级教育和职业培训中的平等机会 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=281"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.5.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`该清单上可以分解的所有教育指标的平等指数 （女性/男性、农村/城市、底部/最高财富五分位 数以及其他残疾状况、原住民和受冲突影响的人 群，数据可用时）`}</p>
          <ThemeLabel theme="equity" id="4.5.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.2" mdxType="IndicatorRow">
          <p>{`以第一或家用语言为授课语言的学生百分比：a）早期年级； b）小学教育结束；和 c）初中教育结束 `}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.3" mdxType="IndicatorRow">
          <p>{`是否存在将教育资源重新分配给弱势群体的供资机制`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`每个学生的教育支出, 按教育级别和经费来源划分`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`分配给最不发达国家的教育援助总额百分比`}</p>
          <ThemeLabel theme="finance" id="4.5.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`教育支出占国内生产总值的百分比, 按资金来源（公共, 私人, 国际）划分`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-46"
      }}>{`目标 4.6`}</h2>
      <p>{`到 2030 年，确保所有青年和大部分成年人，无论男女，都能实现识字和算术 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=292"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.6.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`某一年龄组中获得既定水平的实用(a) 识字和(b) 识数能力的人口比例，按性别分列`}</p>
          <ThemeLabel theme="learning" id="4.6.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.6.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`青年/成年文盲率（识字率）`}</p>
          <ThemeLabel theme="learning" id="4.6.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-47"
      }}>{`目标 4.7`}</h2>
      <p>{`到 2030 年，确保所有学习者获得促进可持续发展所需的知识和技能，包括通过可持续发展教育和可持续生活方式、人权、性别平等，促进和平文化和非暴力、全球公民意识 和文化多样性的欣赏以及文化对可持续发展的贡献 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=300"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.7.1" global mdxType="IndicatorRow">
          <p>{`(一)全球公民教育和(二)可持续发展教育，大多程度上在(a)国家教育政策(b)课程(c)教师培训和(d)学生评估方面进入主流`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`提供基于生活技能的艾滋病毒和性教育的学校百分比`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.3" mdxType="IndicatorRow">
          <p>{`绿色政策意向在教程材料中的主流化程度`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.4" mdxType="IndicatorRow">
          <p>{`对全球公民和可持续性相关的问题表现出充分理解的初中学生百分比`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.5" mdxType="IndicatorRow">
          <p>{`对环境科学和地球科学知识表现出足够认知的初中学生百分比`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-4a"
      }}>{`目标 4.a`}</h2>
      <p>{`建立和提升儿童、残疾和性别敏感的教育设施，为所有人提供安全、非暴力、包容和有效的学习环境 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=308"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.a.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`提供基本服务的学校所占比例，按服务类型划分`}</p>
          <ThemeLabel theme="quality" id="4.a.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`在过去 12 个月中遭受欺凌的学生百分比:  a) 小学和 b) 初中教育`}</p>
          <ThemeLabel theme="quality" id="4.a.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`对学生、人员和机构的攻击次数`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.a.4" mdxType="IndicatorRow">
          <p>{`到校学生享受校餐的百分比`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-4b"
      }}>{`目标 4.b`}</h2>
      <p>{`到 2030 年，向发展中国家，特别是最不发达国家，小岛屿发展中国家和非洲国家提供的奖学金数目大幅扩大，包括发达国家和其他发展中国家的职业培训和信息和通信技 术、技术、工程和科学项目 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=321"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.b.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{` 按部门和学习类型分列的奖学金官方发展援助数额`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "目标-4c"
      }}>{`目标 4.c`}</h2>
      <p>{`到 2030 年，大幅增加合格教师的供应，包括通过发展中国家，特别是最不发达国家和小岛屿发展中国家的教师培训国际合作 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=331"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.c.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`按教育程度分列的、具备所要求的起码资格的教师比例`}</p>
          <ThemeLabel theme="quality" id="4.c.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`学生-受过训练教师比例，按教育级别划分`}</p>
          <ThemeLabel theme="quality" id="4.c.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{` 符合按教育水平和机构类型分列的国家标准的教师比例`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`学生-合格教师比例，按教育级别划分`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.5" mdxType="IndicatorRow">
          <p>{` 相对于可比资格水平的其他职业的平均教师工资`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{` 教师流失率，按教育级别划分`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.7" mdxType="IndicatorRow">
          <p>{` 过去 12 个月内接受在职培训的教师百分比，按培训类型划分，`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "finance"
      }}>{`Finance`}</h2>
      <p><a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=340"
        }}>{`请参阅“全球教育监测报告”中的最新章节`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="F.1" mdxType="IndicatorRow">
          <p>{`公共教育支出初始值占国内生产总值的百分比`}</p>
          <ThemeLabel theme="finance" id="F.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="F.2" mdxType="IndicatorRow">
          <p>{`教育支出占公共支出总额的百分比`}</p>
          <ThemeLabel theme="finance" id="F.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;