import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "LITCOHORT",
  title: /*i18n*/ {
    id: "fig.learning.LITCOHORT.title",
    defaults: `Female literacy rate over time and for matched birth cohorts`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.LITCOHORT.caption",
    defaults: `Adult literacy increases mostly because young literates become adults, not because illiterate adults gain literacy.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.LITCOHORT.source",
    defaults: `GEM Report team analysis based on household surveys`,
  },
};
