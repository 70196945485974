import React from "react";
import { motion } from "framer-motion";
import css from "@emotion/css";
import { springConfigs } from "charts/lib/motion";
import { getContrastText } from "../../../../lib/colorManipulator";
import * as M from "../../../../materials";
import { useTheme } from "../../../../hooks";

export default function Tooltip({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color: string;
}) {
  const { client } = useTheme();
  return (
    <motion.div
      css={css`
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        ${M.fontMeta};
        padding: ${M.spacing.base1(2)} ${M.spacing.base1(4)};
        border-radius: ${M.spacing.base1(2)};
        direction: ${client.isRTL ? "rtl" : "ltr"};
        color: ${getContrastText(color, {
          light: M.whiteText,
          dark: M.blackText,
        })};

        background: ${color};
        & > * + * {
          margin-left: ${client.screenSDown
            ? M.spacing.base8(0.5)
            : M.spacing.base8(1.5)};
          html[dir="rtl"] & {
            margin-left: 0;
            margin-right: ${client.screenSDown
              ? M.spacing.base8(0.5)
              : M.spacing.base8(1.5)};
          }
        }
      `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={springConfigs.gentle}
    >
      <span>{label}</span>
      <span>{value}</span>
    </motion.div>
  );
}
