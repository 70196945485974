
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Condiciones";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title"> Condiciones </Title>
    <p>{`El Informe de Seguimiento de la Educación en el Mundo alienta el uso del sitio web del Scoping Progress in Education (SCOPE) únicamente con fines informativos. Todos los contenidos de este sitio web están protegidos por derechos de autor. Al acceder al sitio web y utilizar su contenido, usted (el "usuario") reconoce que ha leído y comprendido cabalmente las presentes Condiciones y las acepta.`}</p>
    <p>{`La información disponible en este sitio web ha sido publicada con la intención de que esté fácilmente disponible para un uso personal o público con fines no comerciales, y puede ser reproducida, parcial o totalmente, y por cualquier medio, sin costo o sin necesidad de un permiso adicional, a menos que se especifique lo contrario. Todas las solicitudes de uso comercial y derechos de traducción deberán enviarse a `}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "los-usuarios-deberán"
    }}>{`Los usuarios deberán:`}</h3>
    <ul>
      <li parentName="ul">{`actuar con la diligencia debida para asegurar la exactitud de los elementos reproducidos;`}</li>
      <li parentName="ul">{`indicar la fuente de manera bien visible junto con los datos empleados de acuerdo con el siguiente formato:`}</li>
    </ul>
    <p>{`Source: Fuente: (si procede, "adaptado de") Scoping Progress in Education (SCOPE) - Informe GEM, `}<a parentName="p" {...{
        "href": "https://www.education-progress.org"
      }}>{`https://www.education-progress.org`}</a>{`, fecha de consulta.`}</p>
    <p>{`Cabe señalar que la base de datos se actualiza y revisa periódicamente. Por lo tanto, es importante incluir la fecha de consulta al citar los datos.`}</p>
    <h3 {...{
      "id": "licencias"
    }}>{`Licencias`}</h3>
    <p>{`Le recomendamos utilizar los datos en beneficio propio y ajeno de manera creativa. Puede extraer, descargar y copiar la información contenida en los conjuntos de datos, y compartirla con terceros.`}</p>
    <h3 {...{
      "id": "ningún-tipo-de-afiliación-ni-respaldo"
    }}>{`Ningún tipo de afiliación ni respaldo`}</h3>
    <p>{`El usuario no podrá representar el Informe de Seguimiento de la Educación en el Mundo, ni dar a entender que este último ha participado en el uso o reproducción del material por parte del usuario, o los ha aprobado, respaldado o apoyado de cualquier otra manera. El usuario no podrá atribuirse ninguna afiliación con el Progreso de la educación (o con el Informe de Seguimiento de la Educación en el Mundo).`}</p>
    <h3 {...{
      "id": "enlaces-web"
    }}>{`Enlaces web`}</h3>
    <p>{`Un sitio web externo puede incluir un enlace a cualquier página del sitio web del SCOPE sin solicitar la autorización. Sin embargo, esto no deberá menoscabar los derechos de propiedad intelectual del Informe de Seguimiento de la Educación en el Mundo, en particular en cuanto a los nombres, logotipos o derechos de autor.`}</p>
    <h3 {...{
      "id": "descargo-de-responsabilidad"
    }}>{`Descargo de responsabilidad`}</h3>
    <p>{`El Informe de Seguimiento de la Educación en el Mundo actualiza este sitio web para mejorar el acceso del público a los datos y la información conexa. Hace todo lo posible por velar por la exactitud o exhaustividad de la información de este sitio, aunque no puede garantizarlas. Nuestra meta es presentar información actualizada y precisa. Trataremos de corregir los errores que se nos señalen.`}</p>
    <p>{`Los términos empleados en este sitio web y la presentación del material que figura en él no implican toma alguna de posición de parte de la UNESCO en cuanto al estatuto jurídico de los países, territorios, ciudades o regiones ni respecto de sus autoridades, fronteras o límites.`}</p>
    <p>{`El Equipo del Informe de Seguimiento de la Educación en el Mundo se encarga de elegir y exponer los datos recogidos en este sitio web, que no reflejan necesariamente los puntos de vista de la UNESCO y no comprometen a la Organización.`}</p>
    <p>{`Hacemos todo lo posible por minimizar los fallos causados por errores técnicos. Sin embargo, algunos datos o información del sitio web pueden haber sido creados o estructurados en archivos o formatos que no están exentos de errores, por lo que no podemos garantizar que nuestro servicio no se vea interrumpido o afectado por tales problemas. El Informe de Seguimiento de la Educación en el Mundo no se responsabiliza de estos problemas (fallos de funcionamiento, virus informáticos, cortes de las líneas de comunicación, alteraciones del contenido, etc.) ocasionados por el uso del sitio web del Progreso de la educación o de cualquier enlace a sitios externos.`}</p>
    <h3 {...{
      "id": "protección-de-la-vida-privada"
    }}>{`Protección de la vida privada`}</h3>
    <p>{`Al acceder al sitio web del Progreso de la educación, cierta información, como las direcciones de protocolo Internet (IP), el comportamiento de navegación en el sitio web, el software utilizado y el tiempo de consulta, además de otra información similar, se almacenará en nuestros servidores. Esta información no permitirá que se le identifique, sino que se utilizará para analizar el tráfico web.`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;