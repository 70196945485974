import * as array from "d3-array";
import { pipe } from "fp-ts/lib/pipeable";
import { An, Ar, O, Ord } from "../prelude";
export function fromNonEmptyArray<A>(as: An.NonEmptyArray<A>): Array<A> {
  return as;
}

export function unsafeFromArray<A>(as: Array<A>) {
  return as as unknown as An.NonEmptyArray<A>;
}

export function minNumber<A>(as: An.NonEmptyArray<A>, acc: (a: A) => number) {
  const ordA = Ord.contramap((a: A) => acc(a))(Ord.ordNumber);
  return acc(An.min(ordA)(as));
}

export function maxNumber<A>(as: An.NonEmptyArray<A>, acc: (a: A) => number) {
  const ordA = Ord.contramap((a: A) => acc(a))(Ord.ordNumber);
  return acc(An.max(ordA)(as));
}

export function extentNumber<A>(
  as: An.NonEmptyArray<A>,
  acc: (a: A) => number
) {
  return [minNumber(as, acc), maxNumber(as, acc)];
}

export function insertionOrderSet<A>(
  data: An.NonEmptyArray<A>,
  accessor: (a: A) => string
): An.NonEmptyArray<string> {
  return Array.from(new Set(data.map(accessor)).values()) as $IntentionalAny;
}

export function alphabeticalSet<A>(
  data: An.NonEmptyArray<A>,
  accessor: (a: A) => string
): An.NonEmptyArray<string> {
  return Ar.sort(Ord.ordString)(
    Array.from(new Set(data.map(accessor)).values())
  ) as $IntentionalAny;
}

/**
 * keepMax
 *
 * Keep the maximum element in an array per group `key`. Compare using `by`.
 */
export function keepMax<A, B, K>(
  data: Array<A>,
  by: (x: A) => B,
  key: (x: A) => K
) {
  return Array.from(
    array
      .rollup(
        data,
        (xs) => xs.reduce((max, x) => (by(max) > by(x) ? max : x), xs[0]),
        key
      )
      .values()
  );
}

/**
 * sparse
 *
 * Turn a dense into a sparse array, i.e. an array where not every element has
 * a defined value.
 *
 * TODO: This is not very pretty code …
 *
 * @example
 *
 * // Input
 * sparse(
 *   [ { id: "A", level: 1, year: 2015, value: 7 },
 *     { id: "B", level: 1, year: 2016, value: 4 },
 *     { id: "B", level: 2, year: 2013, value: 5 },
 *     { id: "B", level: 2, year: 2015, value: 2 } ],
 *   x => `${x.id}-${x.level}`,
 *   "year",
 *   "value"
 * )
 *
 * // Output
 * [ { id: 'A', level: 1, year: 2013, value: null },
 *   { id: 'A', level: 1, year: 2014, value: null },
 *   { id: 'A', level: 1, year: 2015, value: 7 },
 *   { id: 'A', level: 1, year: 2016, value: null },
 *   { id: 'B', level: 1, year: 2013, value: null },
 *   { id: 'B', level: 1, year: 2014, value: null },
 *   { id: 'B', level: 1, year: 2015, value: null },
 *   { id: 'B', level: 1, year: 2016, value: 4 },
 *   { id: 'B', level: 2, year: 2013, value: 5 },
 *   { id: 'B', level: 2, year: 2014, value: null },
 *   { id: 'B', level: 2, year: 2015, value: 2 },
 *   { id: 'B', level: 2, year: 2016, value: null } ]
 *
 * @param data An array of records
 * @param groupBy A sparse array will be created for each group
 * @param stepKey The record's key on which the step is stored
 * @param valueKey The record's key on which the value is stored
 */
export function sparse<
  SK extends string,
  VK extends string,
  A extends { [k in SK]: number } & { [k in VK]: number | null } & {
    [k: string]: unknown;
  },
  B
>(data: Array<A>, groupBy: (x: A) => B, stepKey: SK, valueKey: VK) {
  const ord = Ord.fromCompare((a: A, b: A) =>
    a[stepKey] < b[stepKey] ? -1 : a[stepKey] > b[stepKey] ? 1 : 0
  );
  const dataOrd = Ar.sort(ord)(data);
  const range = array.range(
    dataOrd[0][stepKey],
    dataOrd[dataOrd.length - 1][stepKey] + 1,
    1
  );

  return Ar.flatten(
    Array.from(
      array
        .rollup(
          data,
          (xs) => {
            const template = { ...xs[0], [valueKey]: null };
            let xsPointer = 0;
            const result: Array<A> = [];
            range.forEach((step) => {
              const current = xs[xsPointer];
              if (current && step === current[stepKey]) {
                result.push(current);
                xsPointer++;
              } else {
                result.push({ ...template, [stepKey]: step });
              }
            });
            return result;
          },
          groupBy
        )
        .values()
    )
  );
}

/**
 * invertSparseArray
 * Used to create the "inverse" of a sparse array for rendering lines where there
 * aren't any in a line chart.
 *
 * Very specific to line charts at the moment, also with the "value" field requirement,
 * but could be made more generic if useful.
 *
 * NOTE: If this turns out to be too performance intensive (due to the heavy use
 * of Option), we could consider using flatMap – which I tried, but the compiler
 * didn't accept it for some reason.
 *
 * Normal line:  –   ––– – –––
 * Inverse line: ----- -----
 *
 * Example:
 *
 *   const input = [
 *     { value: 1 },
 *     { value: null },
 *     { value: null },
 *     { value: null },
 *     { value: 2 },
 *     { value: 3 },
 *     { value: 4 },
 *     { value: null },
 *     { value: 5 },
 *     { value: null },
 *     { value: 6 },
 *     { value: 7 },
 *     { value: 8 }
 *   ];
 *
 *   const output = [
 *     { value: 1 },
 *     { value: 2 },
 *     { value: null },
 *     { value: 4 },
 *     { value: 5 },
 *     { value: 6 },
 *     { value: null }
 *   ];
 */
export function invertSparseArray<A extends { value: number | null }>(
  xs: Array<A>
) {
  const defined = (x: A) => x.value != null;
  return pipe(
    xs,
    Ar.filterMapWithIndex((i, curr) => {
      const hasPrev = pipe(Ar.lookup(i - 1, xs), O.exists(defined));
      const hasCurr = defined(curr);
      const hasNext = pipe(Ar.lookup(i + 1, xs), O.exists(defined));
      const isLast = i === xs.length - 1;

      return isLast
        ? !hasPrev && hasCurr
          ? O.some(curr)
          : O.none
        : !hasPrev && hasCurr
        ? O.some(curr)
        : hasPrev && hasCurr && !hasNext
        ? O.some(curr)
        : hasPrev && hasCurr && hasNext
        ? O.some({ ...curr, value: null })
        : O.none;
    })
  );
}
