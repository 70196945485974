
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "Education Progress";
export const lead = "Education lights every stage of the journey to a better life. To unlock the wider benefits of education, all children need access to education of good quality. Special efforts are needed to ensure that all children and young people – regardless of their family income, where they live, their gender, their ethnicity, whether they are disabled – can benefit equally from its transformative power. This requires sufficient resources, especially to reach those who need more support.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const PageLink = makeShortcode("PageLink");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scope"
      }}>{`SCOPE`}</h1>
      <p>{`Bildung bringt Licht in jede Etappe auf dem Weg zu einem besseren Leben. Alle Kinder brauchen `}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`Zugang`}</HighlightByTheme>{` zu Bildung in guter `}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`Qualität`}</HighlightByTheme>{`, um ihr `}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`Lernpotenzial`}</HighlightByTheme>{` und die weiterreichenden Vorteile von Bildung voll auszuschöpfen. Besondere Bemühungen sind notwendig um zu gewährleisten, dass alle Kinder und Jugendlichen `}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{`chancengerecht`}</HighlightByTheme>{` von der transformativen Kraft von Bildung profitieren können. Dazu bedarf es ausreichender `}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`Ressourcen`}</HighlightByTheme>{`, um besonders jene zu erreichen, die mehr Unterstützung benötigen.`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`2015 beschloss die internationale Gemeinschaft 17 Ziele für nachhaltige Entwicklung (Sustainable Development Goals, SDGs), die bis 2030 erreicht werden sollen – darunter `}<a parentName="p" {...{
          "href": "https://sustainabledevelopment.un.org/sdg4"
        }}>{`SDG 4`}</a>{`, das sich ausschließlich dem Thema Bildung widmet. Seitdem wurde ein `}<PageLink name="indicators" mdxType="PageLink">{`sorgfältiger Rahmen`}</PageLink>{` zur Überwachung und Messung von Fortschritten auf dem Weg zur Erreichung der Unterziele von SDG 4 entwickelt und eingeführt. Auf dieser Website werden wichtige Fakten und Trends in der Bildung weltweit zu fünf Themenbereichen zusammengefasst:`}</p>
    </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`Sie ergänzt die Angaben und Informationen der Druckfassung des `}<a parentName="p" {...{
          "href": "https://en.unesco.org/gem-report/allreports"
        }}>{`Weltbildungsberichts`}</a>{`, dem Werkzeug der internationalen Gemeinschaft zur Überwachung und Messung von Fortschritten auf dem Weg zur Erreichung des Bildungsziels SDG 4.`}</p>
    </LandingText>
    <LandingButton mdxType="LandingButton">Jetzt lesen</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;