import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import * as M from "../materials";
import { ReactComponent as FilterIcon } from "./tooltip-triggers/ic_filter_24dp.svg";
import { foldFigureControl, useFigureState } from "domain/figure-state";
import { pipe, O, R } from "../prelude";
import { FigureControls } from "./figure-controls";
import { mkStyles } from "hooks";

const useStyles = mkStyles((theme) => ({
  button: css`
    ${theme.typography.link}
    border: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      background-color: transparent;
    }
  `,
  root: css`
    background-color: ${M.grayscalePalette[2]};
    border: 1px solid ${theme.palette.grayscale[4]};
    border-radius: ${theme.spacing.base8(0.5)};
    margin-top: 0;
    margin-bottom: ${theme.spacing.base8(4)};
    padding: ${theme.spacing.base8(2)};
  `,
  title: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  panel: css`
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
  `,
  filters: css`
    width: 100%;
    display: flex;
    padding: ${theme.spacing.base8(2)};

    &:not(:empty) {
      margin-bottom: ${theme.spacing.base8(2)};
    }
    @media ${M.bpDown("l")} {
      flex-direction: column;
    }
  `,
  header: css`
    background-color: ${M.grayscalePalette[2]};
    border-bottom: 1px ${theme.palette.grayscale[4]} solid;
    width: 100%;
    padding: ${theme.spacing.base8(3)} ${theme.spacing.base8(3)};
    display: flex;
    justify-content: space-between;
  `,
}));

export default function MobileControlPanel() {
  const [state] = useFigureState();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const styles = useStyles();

  return (
    <div css={styles.root}>
      <div css={styles.title}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FilterIcon />
          <h1
            css={css`
              ${M.fontHeading3}
            `}
          >
            Filters
          </h1>
        </div>
        <button css={styles.button} onClick={() => setIsPanelOpen(true)}>
          Edit
        </button>
      </div>
      <div
        css={css`
          ${M.fontChartGroupTitle};
          color: ${M.lightText};
        `}
      >
        {pipe(
          state.controls,
          O.map((controls) =>
            R.toArray(controls)
              .filter((c) => c[1].title)
              .sort(([idA, controlA], [idB, controlB]) => {
                return controlA.position === "LEFT"
                  ? controlB.position === "LEFT"
                    ? idA > idB
                      ? 1
                      : -1
                    : -1
                  : 1;
              })
              .map(([id, control]) => {
                return foldFigureControl(control, {
                  ButtonGroup: (control) => (
                    <p>{`${control.title}: ${
                      state.controlItems[id].find(
                        (d) => d.value === control.selected
                      )?.label
                    }`}</p>
                  ),
                  Checkbox: (control) => (
                    <p>
                      {`${control.title}: ${
                        control.selected.toString() === "true"
                          ? control.label
                          : undefined
                      }
                    `}
                    </p>
                  ),
                  SingleSelect: (control) => (
                    <p>
                      {`${control.title}: ${
                        state.controlItems[id].find(
                          (d) => d.value === O.toUndefined(control.selected)
                        )?.label ?? "No selection"
                      }`}
                    </p>
                  ),
                  MultiSelect: (control) => (
                    <p>
                      {`${control.title}: ${pipe(
                        control.selected,
                        O.map((selectedValues) =>
                          selectedValues
                            .map(
                              (value) =>
                                `${
                                  state.controlItems[id].find(
                                    (d) => d.value === value
                                  )?.label
                                }`
                            )
                            .join(", ")
                        ),
                        O.getOrElse(() => "No selection")
                      )}
                    `}
                    </p>
                  ),
                  Timeline: (control) => (
                    <p>{`${control.title}: ${
                      state.controlItems[id].find(
                        (d) => d.value === control.selected.toString()
                      )?.label
                    }`}</p>
                  ),
                  Radio: (control) => (
                    <p>
                      {`${control.title}: ${
                        state.controlItems[id].find(
                          (d) => d.value === O.toUndefined(control.selected)
                        )?.label ?? "No selection"
                      }`}
                    </p>
                  ),
                  MultiCheckbox: (control) => (
                    <p>
                      {`${control.title}: ${pipe(
                        control.selected,
                        O.map((selectedValues) =>
                          selectedValues
                            .map(
                              (value) =>
                                `${
                                  state.controlItems[id].find(
                                    (d) => d.value === value
                                  )?.label
                                }`
                            )
                            .join(", ")
                        ),
                        O.getOrElse(() => "No selection")
                      )}
                    `}
                    </p>
                  ),
                });
              })
          ),
          O.toNullable
        )}
      </div>
      {isPanelOpen && <FilterPanel onClose={() => setIsPanelOpen(false)} />}
    </div>
  );
}

const FilterPanel = ({ onClose }: { onClose: () => void }) => {
  const styles = useStyles();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div css={styles.panel}>
      <div css={styles.header}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FilterIcon />
          <h1
            css={css`
              ${M.fontHeading3}
            `}
          >
            Filters
          </h1>
        </div>
        <button css={styles.button} onClick={onClose}>
          Close
        </button>
      </div>
      <div css={styles.filters}>
        <FigureControls position="LEFT" />
        <FigureControls position="TOP" />
        <FigureControls position="RIGHT" />
      </div>
    </div>
  );
};
