import css from "@emotion/css";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { MDXProvider } from "@mdx-js/react";
import { motion } from "framer-motion";
import React from "react";
import { mkStyles, mkStylesWithProps, useTheme } from "../../hooks";
import * as M from "../../materials";
import linkArrow from "./files/ic_link_24dp.svg?raw";
import { useRowStyles, useSectionStyles } from "./shared/table";

// -----------------------------------------------------------------------------
// IndicatorSection

const useSectionComponents = mkStyles(({ spacing: { base8 }, breakpoints }) => {
  return {
    h2: styled.h2`
    ${M.fontHeading2};
    margin-bottom: ${base8(3)};
    padding: 0 ${base8(3)};
    margin-top: ${base8(2)};

    @media ${breakpoints.down("l")} {
      padding: 0 ${base8(1)};
    }

    & + p {
      padding: 0 ${base8(3)};
      width: 80%;

      @media ${breakpoints.down("l")} {
        width: 100%;
        padding: 0 ${base8(1)};
      }
    }
  }
    `,
  };
});

export const IndicatorSection = ({ children }: $PropsWithChildren) => {
  const styles = useSectionStyles();

  return (
    <MDXProvider components={useSectionComponents()}>
      <div css={styles.root}>
        <div css={styles.table}>
          <div css={styles.content}>{children}</div>
        </div>
      </div>
    </MDXProvider>
  );
};

// -----------------------------------------------------------------------------
// IndicatorTable

const useTableStyles = mkStyles(
  ({ spacing: { base8 }, typography, breakpoints }) => {
    return {
      root: css`
        display: grid;
        margin-top: ${base8(3)};
      `,
      columntitles: css`
        display: flex;
        margin: 0 ${base8(3)} ${base8(2)} ${base8(3)};
        text-align: left;
        justify-content: flex-start;

        @media ${breakpoints.down("l")} {
          margin: 0;
          margin-left: ${base8(1)};
        }

        small {
          ${typography.preset.fontMeta};
          color: rgb(88, 88, 88);
          margin-right: ${base8(3)};

          html[dir="rtl"] & {
            margin-right: unset;
            margin-left: ${base8(3)};
          }

          @media ${breakpoints.down("l")} {
            display: none;
          }

          &:last-child {
            justify-self: flex-end;
            margin-left: auto;

            html[dir="rtl"] & {
              margin-right: auto;
              margin-left: 0;
            }
          }
        }
      `,
    };
  }
);

export const IndicatorTable = ({ children }: $PropsWithChildren) => {
  const styles = useTableStyles();

  return (
    <div css={styles.root}>
      <div css={styles.columntitles}>
        <small>
          <Trans>Indicator</Trans>
        </small>
        <small>
          <Trans>Description</Trans>
        </small>
        <small>
          <Trans>Link to UIS data</Trans>
        </small>
      </div>
      {children}
    </div>
  );
};

// -----------------------------------------------------------------------------
// IndicatorRow

export const IndicatorRow = ({
  children,
  id,
  global,
  link,
}: $PropsWithChildren<{ id: string; global?: boolean; link?: string }>) => {
  const [isTarget, setTarget] = React.useState(false);
  const { client } = useTheme();
  const styles = useRowStyles();

  React.useEffect(() => {
    const locationHash = typeof window != null ? window.location.hash : null;
    if (locationHash != null && locationHash.length > 1) {
      setTarget(locationHash.substr(1) === id);
    }
  }, [id]);

  const parentVariants = {
    hover: {
      x: 0,
    },
    default: {
      x: 0,
    },
  };

  const childVariants = {
    hover: {
      x: client.isRTL ? -5 : 5,
      rotate: client.isRTL ? 180 : 0,
    },
    default: {
      x: 0,
      rotate: client.isRTL ? 180 : 0,
    },
  };

  const content = (
    <>
      <div css={styles.mainContent}>
        <IndicatorTag id={id} global={global} />
        {children}
      </div>
      <div css={styles.linkArrow}>
        {link && (
          <>
            <a css={styles.link}>
              <Trans>Link to UIS data</Trans>
            </a>
            <motion.img
              css={styles.linkImg}
              initial="default"
              variants={childVariants}
              src={linkArrow}
            />
          </>
        )}
      </div>
    </>
  );

  return (
    <div id={id} css={isTarget && styles.rootHighlighted}>
      {link ? (
        <motion.a
          href={link}
          css={[styles.row, styles.rowLink]}
          variants={parentVariants}
          whileHover="hover"
        >
          {content}
        </motion.a>
      ) : (
        <div css={styles.row}>{content}</div>
      )}
    </div>
  );
};

// -----------------------------------------------------------------------------
// IndicatorTag

const useTagStyles = mkStylesWithProps(
  ({ palette, breakpoints, spacing }, props: TagProps) => {
    return {
      root: css`
        ${M.fontBody2};
        display: flex;
        background-color: ${props.global
          ? palette.brand.teal.dark
          : palette.brand.teal.main};
        border-radius: ${props.global ? spacing.base8(0.5) : spacing.base8(2)};
        font-weight: bold;
        color: ${palette.text.white};
        padding: ${spacing.base1(5)} 0;
        margin-right: ${spacing.base8(2)};
        height: ${spacing.base8(4)};
        min-width: ${spacing.base8(7)};
        justify-content: center;
        align-items: center;

        @media ${breakpoints.down("l")} {
          height: ${spacing.base8(3)};
          margin-bottom: ${spacing.base8(1)};
        }

        html[dir="rtl"] & {
          margin-right: 0;
          margin-left: ${spacing.base8(2)};
        }
      `,
    };
  }
);

export interface TagProps {
  id: string;
  global?: boolean;
}

export const IndicatorTag = (props: TagProps) => {
  const styles = useTagStyles(props);
  return <span css={styles.root}>{props.id}</span>;
};
