
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Many teachers are trained and qualified but not for the subject they teach";
export const lead = "General measures of teacher qualification and training overlook the phenomenon of out-of-field teaching, in which the subjects are outside teachers’ area of expertise or they lack necessary specialist requirements. Often the result of teacher shortages or personnel mismanagement, this understudied issue may have important implications for the quality of teaching and learning.";
export const layout = "focus";
export const article = "quality";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Many teachers are trained and qualified but not for the subject they teach" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=371" mdxType="FocusDetails">
      <h1 {...{
        "id": "many-teachers-are-trained-and-qualified-but-not-for-the-subject-they-teach"
      }}>{`Many teachers are trained and qualified but not for the subject they teach`}</h1>
      <p>{`General measures of teacher qualification and training overlook the phenomenon of out-of-field teaching, in which the subjects are outside teachers’ area of expertise or they lack necessary specialist requirements. Often the result of teacher shortages or personnel mismanagement, this understudied issue may have important implications for the quality of teaching and learning.`}</p>
      <p>{`Teaching out of field is prevalent in much of the world. In at least 40 education systems that participated in the2018 TALIS, over 10% of lower secondary school science teachers had received no formal education or training in the subject. The same is true for mathematics teachers. In Georgia and Saudi Arabia, less than 60% of science and mathematics teachers have received training in their subjects as part of their formal education.`}</p>
      <p>{`In at least 16 countries that participated in the 2019 Trends in International Mathematics and Science Study (TIMSS), over 10% of grade 8 students were taught mathematics by teachers without a major in mathematics and/or in mathematics education. Taking the stricter definition of having both, this is true for over half the students in most participating countries. In Australia, there is a 76% chance of a student having at least one out-of-field mathematics teacher between grades 7 and 10, and a 35% chance of having at least two (Prince and O'Connor, 2018).`}</p>
      <FocusImage size="medium" src={"2-Teaching_outof_field_1.png"} alt="In most countries, less than half of grade 8 students are taught mathematics by teachers with a major in mathematics and mathematics education" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=371" mdxType="FocusImage" />
      <p>{`The prevalence of out-of-field teaching may be even greater in more specialized subjects. In Brazil, over half the sociology, arts, philosophy and physics upper secondary classes are taught by teachers without subject-matter qualification, compared with less than30% of mathematics, Portuguese, biology and physical education classes (INEP, 2019). In Australia, the subject areas with the greatest share of out-of-field teachers include languages, geography and information and communication technology (Weldon, 2016).`}</p>
      <p>{`Out-of-field teaching may have detrimental consequences on teaching and learning. Some studies have found that it negatively influences teachers’ self-esteem, confidence and relationships within the school community (du Plessis et al., 2014), as well as job satisfaction and likelihood of staying in the profession (Sharplin, 2014). Instructional practices may also differ. Out-of-field teachers tend to rely more on textbooks and pre-prepared material and be less likely to make connections across topics and engage in creative classroom activities (Napier et al., 2020; Van Overschelde and Piatt, 2020). Across all education systems participating in TALIS, teachers whose formal education or training included content of some or all the subjects they teach were more likely to feel well prepared for that element of teaching.`}</p>
      <FocusImage size="medium" src={"2-Teaching_outof_field_2.png"} alt="Teachers who receive content training as part of their formal education are more likely to feel well prepared to teach that content" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=372" mdxType="FocusImage" />
      <p>{`All this can influence student performance. In the 16 TIMSS countries where at least 10% of students had out-of-field teachers, the association between out-of-field teaching and student scores is generally inconclusive, but in all cases where it is statistically significant, it is negative. The fact that the TIMSS assessment takes place in grades 4 and 8 may mask a stronger impact of out-of-field teaching in later grades, when subject-matter specialization increases.`}</p>
      <p>{`Several studies in the United States point in that direction, though more research in various contexts is needed before conclusive results can be reached (Porsch and Whannell, 2019). Longitudinal data from the state of North Carolina examined secondary students’ academic growth and found that those who took classes taught by in-field teachers performed significantly better (Clotfelter et al., 2010). Using New York state census secondary school data, researchers found that chemistry and physics students of out-of-field teachers performed considerably worse than their peers on standardized state examinations, even after controlling for socioeconomic background (Sheppard et al., 2020).`}</p>
      <p>{`The phenomenon of out-of-field teaching also raises equity concerns, as not everyone is equally likely to be an out-of-field teacher or taught by one. In Brazil, students in rural and poorer regions are more likely to be in a class taught by an out-of-field teacher (INEP, 2019). Similarly, the prevalence of out-of-field teaching is considerably higher in rural and remote locations in Australia, as well as in schools that serve communities of lower socioeconomic status (Weldon, 2016). In Ireland, out-of-field teachers were predominantly deployed to teach less academically able students. In Germany, teaching out of field is more prevalent in schools that do not directly lead to post-secondary education, as opposed to Gymnasien or Gesamtschulen (Price et al., 2019). In the US state of Texas, students who are Black, of lower socioeconomic status, in special education and in rural schools are significantly more likely to be taught by an out-of-field teacher, while Black teachers and teachers in rural schools are more likely to teach out of field (Van Overschelde and Piatt, 2020).`}</p>
      <p>{`Some countries offer certificates or professional development programmes to support out-of-field teachers. In Ireland, a national government-sponsored programme, the Professional Diploma in Mathematics for Teaching, was founded in 2012 to help out-of-field mathematics teachers improve their content and pedagogy skills. It has been credited with reducing the incidence of out-of-field teaching from 48% in 2009 to 25% a decade later (Goos et al., 2019). Australia, Germany and the United Kingdom, which have similar programmes for mathematics or science out-of-field teachers, report success in increasing participants’ content knowledge (Kenny et al., 2020). Out-of-field teachers may also acquire necessary knowledge through experience. In Australia, mathematics teachers without a related academic degree have been teaching the subject for over seven years, on average (Weldon, 2016).`}</p>
      <p>{`Not all out-of-field teachers are unhappy or uncomfortable with their positions. Those who are well supported may see it as an important career development step (The Guardian, 2017). Statistics may count teachers as out of field when they teach the subject they are trained in but at a different level of schooling. Moreover, measures of out-of-field teaching may overestimate the number of out-of-field teachers, as the measures tend to focus on matching the subject with teachers’ academic degree without considering the continuum in fields of study – e.g. mathematics teachers with a degree in physics are not as out of field as those with a degree in sociology. Positive experiences with out-of-field teaching usually combine a variety of factors, including teachers’ interests and a strong support system offered by the school community and school head (Campanini, 2019; du Plessis et al., 2014). These require acknowledging the issue and working towards better understanding of its prevalence and impact and the best strategies to manage it.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=577"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;