
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "公平";
export const lead = "全球在实现教育性别均等方面取得了进展，但农村和城市地区之间以及富人和穷人之间的差距仍然很大。";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const PageLink = makeShortcode("PageLink");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`不让任何一个人掉队的愿望横贯各项可持续发展目标，明确体现在关于教育的可持续发展目标4第五项具体目标中。这种侧重确保了政策制定者优先帮助落在最后的人。`}</p>
    </blockquote>
    <p>{`为了能够监测教育差距是否正在缩小，我们需要掌握由住户调查提供的按`}<strong parentName="p">{`收入、性别、年龄、种族、民族、移徙状况、残疾情况、地理位置`}</strong>{`和与国情相关的`}<strong parentName="p">{`其他特征`}</strong>{`细分的数据。`}</p>
    <p>{`自2010年以来，`}<a parentName="p" {...{
        "href": "https://www.education-inequalities.org/"
      }}>{`世界教育不平等数据库`}</a>{`一直利用住户调查来显示不同国家间存在的巨大教育差距。本文总结了教育不平等方面的一些关键事实和趋势，有关`}<PageLink name="finance" mdxType="PageLink">{`资金`}</PageLink>{`.`}</p>
    <h2 {...{
      "id": "性别"
    }}>{`性别`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`下列图表显示了不同群体之间的教育差距，比如女孩和男孩之间，或最贫困和最富有者之间。表中采用了`}<Tooltip title="均等指数" content="In a parity index, if the value is close to 0, it shows the disadvantaged group is very far behind; values close to 1 mean there is parity; values close to 2 mean that the advantaged group is the furthest behind. A parity index of 0.5, for instance, could show that there are 30% of girls but 60% of boys enrolled. A parity index of 1.5 would indicate there were 30% of boys but 60% of girls enrolled." mdxType="Tooltip">{`均等指数`}</Tooltip>{`衡量教育差距。`}</p>
      <p>{`尽管很少有人意识到，但在过去的几十年中，在实现`}<Tooltip title="性别均等" content="Where there are equal numbers of boys to girls" mdxType="Tooltip">{`性别均等`}</Tooltip>{`方面不断取得进展。在世界许多地方（大多数是较富裕地区），中小学和大学里女童和女青年的数量超过了男童和男青年。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`任何位于`}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`灰色阴影区域`}</HighlightByIndex>{`下方的线都表明入学的男童或男青年多于女孩。`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`在`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`撒哈拉以南非洲`}</HighlightByIndex>{`，接受高等教育的女青年与男青年的比例仍然不到四比五。`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <HighlightByIndex index="2" mdxType="HighlightByIndex">中亚和南亚地区</HighlightByIndex>能够经历从1990年女孩数量远远落后到在初中和高中教育中实现性别均等，以及高等教育中女性入学率上升这一变化，主要得益于印度的巨大进步。因此，该地区在实现性别均等方面的进步已经超过了<HighlightByIndex index="0" mdxType="HighlightByIndex">撒哈拉以南非洲</HighlightByIndex>。
        </ScrollySection>
      </ScrollyArea>
      <p>{`全球自2000年来在性别均等方面取得了显著进展。然而，深入到国家层面就会发现，差距依然存在：实现了小学教育入学率性别均等的国家只占三分之二，初中为二分之一，高中仅为四分之一。`}</p>
      <p>{`自2000年以来，在所有教育层次中，因`}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`女孩处于不利地位而造成性别差距很大`}</HighlightByIndex>{`的国家所占比例已经减少了至少一半，但小学仍为4%，初中8%，高中14%。`}</p>
      <p>{`在谈论性别平等时，一定不能忘记男孩。虽然因`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`男孩处于较大不利地位而造成`}</HighlightByIndex>{`小学`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`性别差距巨大`}</HighlightByIndex>{`的国家并不多见，但这种情况在高中及高中后教育中十分常见。这一趋势或处于停滞状态，或进一步加剧。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`图中柱形的蓝色部分越多、颜色越深，说明`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`男孩辍学人数多于女孩`}</HighlightByIndex>{`的国家越多。柱形的绿色部分越多、颜色越深，说明`}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`女孩辍学人数多于男孩`}</HighlightByIndex>{`的国家越多。`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
例如，在高等教育方面，三分之二的国家存在<HighlightByIndex index="0" mdxType="HighlightByIndex">男孩处于不利地位所造成的悬殊差距</HighlightByIndex>，这种情况正不断恶化。
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "财富"
      }}>{`财富`}</h2>
      <p>{`总体而言，住户调查显示，女孩和男孩之间的教育差距并不像农村和城市地区以及富人和穷人之间的差距那样大。`}</p>
      <p>{`例如，在巴基斯坦，最富有者有92%接受了小学教育，但`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`最贫困者`}</HighlightByIndex>{`的这一比例只有28%。在尼日利亚，`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`最富有者`}</HighlightByIndex>{`有97%接受了初中教育，而最贫困者的这一比例只有27%。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`红点表示`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`最富有者`}</HighlightByIndex>{`儿童或青年接受教育的比例，与之对应的是用蓝色表示的`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`最贫困者`}</HighlightByIndex>{`儿童或青年的这一比例 。您可在图表顶部将教育层级从小学更改为初中及初中后。`}</p>
        </ScrollySection>
        <ScrollySection id="ALB" mdxType="ScrollySection">
          <p>{`在高等教育方面，中等收入国家存在的差距最大。例如，在`}<strong parentName="p">{`阿尔巴尼亚`}</strong>{`，55%的最富有者接受过某种形式的高等教育，而最贫困者中的这一比例则低于1%。`}</p>
        </ScrollySection>
        <ScrollySection id="MNG" mdxType="ScrollySection">
          <p>{`在`}<strong parentName="p">{`蒙古`}</strong>{`，相应的比例分别为 70%和 10%。`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`孩子接受教育过程中的差距变化更能说明问题。`}</p>
      <p>{`通过下面`}<strong parentName="p">{`马里`}</strong>{`的`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`性别`}</HighlightByIndex>{`差距，可以看出随着教育层次提高女孩辍学情况的变化。`}</p>
      <p>{`或者选择`}<strong parentName="p">{`财富`}</strong>{`，可以看出随着教育轨迹的延伸，差距也因最贫困者处于不利地位而累积，这些人中几乎没有人上过高中。`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`一个孩子很少只被归入某一单一类别，这意味着必须同时考虑个别特征（如性别）与其他特征（如财富）的相互作用，下面的数字即是一例。`}</p>
    <p>{`在撒哈拉以南非洲，随着教育轨迹的延伸，`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`最贫困`}</HighlightByIndex>{`和`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`最富有`}</HighlightByIndex>{`者之间的差距不断扩大：与每100名男孩接受高等教育相比，只有不到45名最贫穷的女孩接受高等教育，而接受高等教育的最富有女孩则有近80名。`}</p>
    <p>{`另一方面，在东亚和东南亚，这些点位于灰线上方，因为性别差距显示最贫困男孩处于不利地位。到高等教育阶段，最富有女孩上大学的可能性只比最富有男孩高出四分之一左右，但最贫困女孩上大学的可能性却比最贫困男孩高出三分之二。`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "结论"
    }}>{`结论`}</h2>
    <p>{`各国之间和各国内部群体之间存在教育不平等，其程度之深令人无法接受。在我们努力实现可持续发展目标4的过程中，高质量的分类数据是设计适当战略以减少这些不平等现象并最终不让任何人掉队的一个先决条件。`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`Worldwide Inequalities Database on Education (WIDE)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Still left behind – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/2019genderreport"
          }}>{`Building Bridges for Gender Equality – GEM Gender Report 2019`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;