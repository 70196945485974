import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { identity, tuple } from "fp-ts/lib/function";
import useFormat from "hooks/useFormat";
import React from "react";
import {
  useColorScaleVDP,
  VerticalDotPlotAuto,
} from "../charts-motion/vertical-dot-plot";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { useConfig } from "../config";
import { metadata } from "../data/data_equity_fig_PSECWGAP";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  getLevelACName,
  LevelAC,
  mkGemEntityDecoder,
  ordLevelAC,
  useCountryEntities,
  useFigureControlItems,
  useNamedEntities,
  useSingleSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_equity_fig_PSECWGAP";

const DEFAULT_LEVEL = "postsec_attendance";

const Wealth = io.keyof({
  POOREST: null,
  RICHEST: null,
});
type Wealth = io.TypeOf<typeof Wealth>;

const wealthLookup: Record<Wealth, MessageDescriptor> = {
  POOREST: t("fig.equity.PSECWGAP.POOREST")`Poorest`,
  RICHEST: t("fig.equity.PSECWGAP.RICHEST")`Richest`,
};

export const Data = mkGemEntityDecoder(["ind_id"], {
  level: LevelAC,
  value: io.number,
  wealth: Wealth,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const { activeSection } = useReadScrolly();

  const [selectedLevelO, actions] = useSingleSelectState("level");
  const selectedLevel = pipe(
    selectedLevelO,
    O.getOrElse(() => DEFAULT_LEVEL)
  );

  const entityData_ = useNamedEntities(data);
  const entityData = React.useMemo(
    () =>
      entityData_.map((x) => ({
        ...x,
        wealth_name: i18n._(wealthLookup[x.wealth]),
      })),
    [entityData_, i18n]
  );
  const countryData = useCountryEntities(entityData);

  type Datum = typeof countryData[number];

  const levelControlItems = useFigureControlItems(
    entityData,
    (x) => x.level,
    (x) => getLevelACName(i18n, x.level),
    { ord: ordLevelAC }
  );

  const highlightItems = pipe(
    activeSection,
    O.map((x) => x.split(",")),
    O.chain((strIds) =>
      Ar.array.sequence(O.option)(
        strIds.map((strId) =>
          getCountryMeta(env.countries, countryIdIso.wrap(strId))
        )
      )
    ),
    O.map((xs) => xs.map((meta) => meta.country)),
    O.chain((xs) => An.fromArray(xs))
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "level",
      {
        type: "SingleSelect",
        selected: O.some(DEFAULT_LEVEL),
      },
      levelControlItems
    );
  }, [actions, levelControlItems]);

  React.useEffect(() => {
    actions.updateSelectionControl("level", O.some(selectedLevel));
  }, [actions, selectedLevel]);

  const sortedData = React.useMemo(() => {
    const ordDimensions = Ord.getTupleOrd(
      Ord.ordString,
      Ord.ordNumber,
      Ord.ordString
    );
    const ord = Ord.contramap((x: Datum) =>
      tuple(x.level, x.wealth === "RICHEST" ? Infinity : x.value, x.entity_name)
    )(ordDimensions);
    return Ar.sort(ord)(countryData);
  }, [countryData]);

  const chartData = React.useMemo(() => {
    return unsafeFromArray(sortedData.filter((x) => x.level === selectedLevel));
  }, [sortedData, selectedLevel]);

  const getX = React.useCallback((x: Datum) => x.entity_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.wealth_name, []);
  const getColorPalette = React.useCallback(
    () =>
      Ar.reverse(
        M.colorRanges.discrete.slice(0, Object.keys(Wealth.keys).length)
      ),
    []
  );
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.l}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      highlightItems={highlightItems}
      showTooltip
      showLegend
      showYExtent
      dotStyle="small"
      labelStyleX="none"
    />
  );
};

export default withFigureIO({
  url: require("../data/data_equity_fig_PSECWGAP.json"),
  csv: require("../data/data_equity_fig_PSECWGAP.zip"),
  xlsx: require("../data/data_equity_fig_PSECWGAP.xlsx"),
  metadata,
  Data,
  Chart,
  size: "wide",
});
