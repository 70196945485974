import css from "@emotion/css";
import { t } from "@lingui/macro";
import useLocale from "hooks/useLocale";
import React from "react";
import { mkStyles, useTheme } from "../hooks";
import { Locale, useI18n } from "../locales";

const logoSrc = {
  UNESCO: {
    positive: {
      LTR: require("./logo/bg_brandmark-positive_unesco-UNESCO.svg?raw"),
      RTL: require("./logo/bg_brandmark-positive_unesco-rtl-UNESCO.svg?raw"),
    },
    negative: {
      LTR: require("./logo/bg_brandmark-negative_unesco-UNESCO.svg?raw"),
      RTL: require("./logo/bg_brandmark-negative_unesco-rtl-UNESCO.svg?raw"),
    },
  },
  GEM: {
    positive: {
      LTR: require("./logo/bg_brandmark-positive_unesco-GEM.svg?raw"),
      RTL: require("./logo/bg_brandmark-positive_unesco-rtl-GEM.svg?raw"),
    },
    negative: {
      LTR: require("./logo/bg_brandmark-negative_unesco-GEM.svg?raw"),
      RTL: require("./logo/bg_brandmark-negative_unesco-rtl-GEM.svg?raw"),
    },
  },
  SCOPE: {
    positive: {
      LTR: require("./logo/bg_brandmark-positive_unesco-SCOPE.svg?raw"),
      RTL: require("./logo/bg_brandmark-positive_unesco-rtl-SCOPE.svg?raw"),
    },
    negative: {
      LTR: require("./logo/bg_brandmark-negative_unesco-SCOPE.svg?raw"),
      RTL: require("./logo/bg_brandmark-negative_unesco-rtl-SCOPE.svg?raw"),
    },
  },
};

const logoMetadata = {
  UNESCO: {
    getHref: (_: Locale) => "https://www.unesco.org",
    i18nId: "UNESCO logo",
  },
  GEM: {
    getHref: (d: Locale) => {
      // GEM Report page is not available in German
      return `https://www.unesco.org/gem-report/${d === "de" ? "en" : d}`;
    },
    i18nId: "GEM Report logo",
  },
  SCOPE: {
    getHref: (d: Locale) => `https://www.education-progress.org/${d}`,
    i18nId: "Education Progress logo",
  },
};

const useStyles = mkStyles((activeTheme) => ({
  large: css`
    height: ${activeTheme.spacing.base8(7)};
  `,
  small: css`
    height: ${activeTheme.spacing.base8(6)};
  `,
  extrasmall: css`
    height: ${activeTheme.spacing.base8(5)};
  `,
}));

type Size = "large" | "small" | "extrasmall";
type Variant = "positive" | "negative";
type LogoProps = { size: Size };

export function Logo({ size }: LogoProps) {
  const i18n = useI18n();
  const styles = useStyles();
  return (
    <img
      css={styles[size]}
      src={require("./logo/logo.svg?raw")}
      alt={i18n._(t`Education Progress logo`)}
    />
  );
}

export function LogoIO(props: LogoProps) {
  return <Logo {...props} />;
}

type MultiLogoProps = LogoProps & { variant?: Variant };

export function MultiLogo({ size, variant = "negative" }: MultiLogoProps) {
  const locale = useLocale();
  const i18n = useI18n();
  const styles = useStyles();
  const { client } = useTheme();
  const logos: (keyof typeof logoSrc)[] = client.isRTL
    ? ["SCOPE", "GEM"]
    : ["GEM", "SCOPE"];

  return (
    <div
      css={{
        display: "flex",
        flexDirection: client.isRTL ? "row-reverse" : "row",
      }}
    >
      {logos.map((logo) => {
        const src = logoSrc[logo][variant][client.isRTL ? "RTL" : "LTR"];
        const { getHref, i18nId } = logoMetadata[logo];
        const href = getHref(locale);

        return (
          <a key={src} href={href} target="_blank" rel="noreferrer">
            <img
              src={src}
              css={[styles[size], { "&:hover": { opacity: 0.75 } }]}
              // Using t`${i18nId}` doesn't work, so we pass its result here
              alt={i18n._(i18nId)}
            />
          </a>
        );
      })}
    </div>
  );
}

export function MultiLogoIO(props: MultiLogoProps) {
  return <MultiLogo {...props} />;
}
