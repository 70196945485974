
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Trajectoires d’apprentissage";
export const lead = "While most children now spend many years in school, they often emerge without even the most basic reading and math skills. Learning trajectories show how much children learn as they progress through school. They can help us understand the process that leads to low learning outcomes, when and how to intervene during that process, and what the simulated impact of different policies might be on the urgent challenge of low learning.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyStep = makeShortcode("ScrollyStep");
const Explorer = makeShortcode("Explorer");
const TrajectoriesTab = makeShortcode("TrajectoriesTab");
const SimulationsTab = makeShortcode("SimulationsTab");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Les trajectoires d’apprentissage indiquent le nombre d’années nécessaires pour que les enfants acquièrent les compétences de base. Étant donné que ces trajectoires suivent le rythme de l’apprentissage au niveau du système, elles peuvent nous aider à comprendre combien d’enfants éprouvent des difficultés pour acquérir ces compétences, quand et comment intervenir, ainsi que la façon dont différentes politiques pourraient influer sur le faible niveau d’apprentissage, qui représente un défi urgent.`}</p>
    </blockquote>
    <h2 {...{
      "id": "que-révèlent-les-trajectoires-dapprentissage-"
    }}>{`Que révèlent les trajectoires d’apprentissage ?`}</h2>
    <p>{`Dans l’idéal, les trajectoires d’apprentissage devraient prendre la forme d’une ligne ascendante raide. Cependant, trop souvent, on observe des trajectoires plates, qui signalent un rythme d’apprentissage lent. Un apprentissage lent pendant les premières années peut avoir des conséquences à long terme. En effet, lorsque les enfants ont pris du retard et que le programme d’enseignement passe à un contenu plus avancé, ils ne peuvent pas l’appréhender car ils ne maîtrisent pas les compétences prérequises.`}</p>
    <p><strong parentName="p">{`Les trajectoires de nombreux pays révèlent que le rythme de l’apprentissage est bien trop lent, et qu’il en va ainsi dès le début de la scolarité.`}</strong>{` Lors des deux premières années d’enseignement, alors que les objectifs mondiaux et de nombreux programmes d’enseignement nationaux partent du principe que les enfants apprennent à lire, seuls une petite minorité d’enfants le font réellement.`}</p>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Ces compétences sont des prérequis pour l’apprentissage futur et sont mesurées ici par le fait que les enfants puissent lire à voix haute une histoire simple de 70 mots (par exemple, « Manh est dans la classe deux. ») et répondre à cinq questions simples s’y rapportant (par exemple, « Dans quelle classe est Manh ? »). Ces compétences de base sont généralement inférieures au niveau de compétence minimal défini par `}<a parentName="p" {...{
          "href": "https://sdgs.un.org/goals/goal4"
        }}>{`l’indicateur mondial 4.1.1 a des objectifs de développement durable (ODD)`}</a>{`. Les trajectoires calculées surestiment donc les progrès accomplis par rapport à l’indicateur 4.1.1 a, mais elles constituent néanmoins des estimations utiles pour illustrer le rythme de l’apprentissage.`}</p>
    </IndicatorReference>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_CURRENT" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="HIGHLIGHT_VIETNAM" mdxType="ScrollySection">
    Le <HighlightByIndex index="2" mdxType="HighlightByIndex">Viet Nam</HighlightByIndex> est un bon exemple de pays aux performances relativement élevées. Pendant les premières années d’école, la plupart des enfants vietnamiens apprennent les compétences de base en lecture.
  </ScrollySection>
  <ScrollySection id="LMIC_GRADE_3" mdxType="ScrollySection">
    Dans de nombreux autres pays{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">à revenu faible et à revenu intermédiaire de la tranche
    inférieure,</HighlightByIndex>{" "} la trajectoire d’apprentissage est beaucoup
    plus horizontale. En moyenne, seul un enfant sur quatre maîtrise actuellement les
    compétences de base en lecture en troisième année.
  </ScrollySection>
  <ScrollySection id="LMIC_GRADE_6" mdxType="ScrollySection">
    Le faible niveau d’apprentissage persiste à mesure que les enfants progressent dans
    le système scolaire. Dans les pays à{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">revenu faible et à revenu intermédiaire de la tranche inférieure</HighlightByIndex>{" "}
    inclus dans l’ensemble de données, à peine plus de la moitié des élèves de sixième année maîtrisent ce que l’on considère généralement comme des compétences en lecture de deuxième ou troisième année.
  </ScrollySection>
  <ScrollySection id="DIFFERENCE_TRAJECTORIES" mdxType="ScrollySection">
Néanmoins, la trajectoire moyenne cache des variations d’un pays à l’autre. Dans les
pays à revenu faible et à revenu intermédiaire de la tranche inférieure inclus dans
l’ensemble de données, entre 3 et 76 pour cent des enfants savent lire en troisième
année. Cet écart montre que les pays qui s’engagent à améliorer l’apprentissage de base
peuvent y parvenir, même à des niveaux relativement faibles de revenu national.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <p>{`Les pays souhaitant remédier au faible niveau d’apprentissage doivent se concentrer sur les politiques suivantes :`}</p>
    <ul>
      <li parentName="ul">{`Mesurer les compétences de base et leur donner la priorité, dès les premières années.`}</li>
      <li parentName="ul">{`Aligner l’instruction sur le rythme d’apprentissage réel des enfants. On peut procéder de plusieurs manières, notamment en définissant des objectifs d’apprentissage clairs axés sur l’apprentissage de base, en ajustant le rythme du programme d’enseignement et en aidant les enseignants à comprendre les niveaux d’apprentissage actuels des enfants ainsi qu’à ajuster leur enseignement en conséquence.`}</li>
    </ul>
    <h2 {...{
      "id": "simulations-de-politiques--accès-et-apprentissage"
    }}>{`Simulations de politiques : accès et apprentissage`}</h2>
    <p>{`Nous pouvons utiliser les trajectoires d’apprentissage pour comparer l’incidence possible de différentes politiques. Ici, nous simulons l’incidence de politiques visant à améliorer l’accès et l’apprentissage. Les politiques axées sur l’accès visent à augmenter le nombre d’années que les enfants passent à l’école. Elles comprennent des politiques telles que l’enseignement primaire et secondaire gratuit, la construction d’écoles, le passage automatique au niveau supérieur, et d’autres politiques axées principalement sur la scolarisation ou l’achèvement scolaire. Les politiques axées sur l’apprentissage visent à faire en sorte que les enfants en apprennent davantage pendant chaque année d’enseignement. Par exemple, on peut définir et mesurer les progrès par rapport à des objectifs d’apprentissage clairs, réaligner l’instruction sur le rythme d’apprentissage des enfants, et favoriser un enseignement efficace.`}</p>
    <p>{`Chaque enfant doit avoir accès à l’école, et la scolarisation des enfants constitue une première étape indispensable vers l’apprentissage universel. Néanmoins, ces simulations montrent que la seule `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`amélioration de l’accès`}</HighlightByIndex>{` ne suffira pas à résoudre les problèmes d’apprentissage. À l’inverse, si l’on `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`améliorait l’apprentissage à chaque niveau d’enseignement`}</HighlightByIndex>{` en s’alignant sur le rythme des pays à revenu faible et à revenu intermédiaire de la tranche inférieure affichant les meilleures performances, on pourrait en tirer de grands bénéfices.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_SIMULATION_LMIC" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="COMPARISON" mdxType="ScrollySection">
    La différence de performance entre les pays affichant les performances les plus
    faibles et les plus élevées tient à la fois à la fourniture d’un meilleur accès
    à l’école et, en particulier, au fait de permettre aux enfants d’en apprendre
    davantage pendant chaque année d’enseignement.
  </ScrollySection>
  <ScrollySection id="FADED" mdxType="ScrollySection">
    Ces simulations montrent les trajectoires par âge. Elles englobent les enfants
    aussi bien scolarisés que non scolarisés. Étant donné que les enfants non scolarisés
    présentent généralement un niveau d’apprentissage plus faible, ces trajectoires sont
    plus plates que celles par année d’enseignement.
  </ScrollySection>
  <ScrollySection id="CURRENT" mdxType="ScrollySection">
    Si l’on fait la moyenne des cinq pays à{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">revenu faible et à revenu intermédiaire de la tranche
    inférieure</HighlightByIndex>{" "}
    affichant les performances les plus faibles et des cinq pays des mêmes catégories
    affichant les performances les plus élevées, seuls 8 pour cent des enfants de 9 ans
    savent lire à un niveau approprié pour leur âge.
  </ScrollySection>
  <ScrollySection id="SIMULATION_ACCESS" mdxType="ScrollySection">
    La <HighlightByIndex index="2" mdxType="HighlightByIndex">simulation de l’accès</HighlightByIndex>{" "}
    simule ce qui pourrait se passer si tous les enfants étaient scolarisés. Pour cela,
    elle estime ce qui se passerait si tous les enfants qui n’avaient jamais fréquenté
    l’école ou qui l’avaient abandonnée avaient atteint les mêmes niveau d’enseignement
    et niveau d’apprentissage moyens que leurs pairs scolarisés du même âge. Dans les
    cinq pays affichant les plus faibles performances, les simulations suggèrent que le
    nombre d’enfants de 9 ans sachant lire augmenterait alors de 1 point de pourcentage.
  </ScrollySection>
  <ScrollySection id="SIMULATION_LEARNING" mdxType="ScrollySection">
    Dans ces pays, si les enfants de 9 ans continuaient d’atteindre le même niveau
    d’enseignement qu’à l’heure actuelle, mais qu’ils{" "}
    <HighlightByIndex index="3" mdxType="HighlightByIndex">apprenaient autant par année d’enseignement</HighlightByIndex>
    que les enfants des pays à revenu faible et à revenu intermédiaire de la tranche
    inférieure affichant les meilleures performances, alors 43 pour cent d’entre eux
    sauraient peut-être lire, soit une augmentation de 35 points de pourcentage.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <h2 {...{
      "id": "simulations-de-politiques--égalité"
    }}>{`Simulations de politiques : égalité`}</h2>
    <p>{`Les trajectoires d’apprentissage constituent également un outil utile pour comprendre les différences d’apprentissage entre certains groupes au sein des pays. Ici, nous comparons l’apprentissage des riches et des pauvres, et nous simulons l’incidence de politiques visant à réduire les inégalités entre eux.`}</p>
    <p>{`Si l’on fait la moyenne de tous les pays à revenu faible et à revenu intermédiaire de la tranche inférieure inclus dans l’ensemble de données, les enfants issus de familles riches (quintile des familles les plus riches) vont davantage à l’école et en apprennent davantage à chaque niveau d’enseignement par rapport aux enfants issus de familles pauvres (quintile des familles les plus pauvres). La `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`simulation de l’égalité d’accès`}</HighlightByIndex>{` montre ce qui pourrait se passer si, à la place, les pauvres étaient autant scolarisés que les riches, tandis que la `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`simulation de l’égalité d’apprentissage`}</HighlightByIndex>{` montre ce qui pourrait se passer si, à la place, les pauvres en apprenaient autant que les riches à chaque niveau d’enseignement. Dans les deux scénarios, et en particulier dans la simulation de l’égalité d’apprentissage, les inégalités diminueraient.`}</p>
    <p><strong parentName="p">{`Néanmoins, même les enfants issus de familles riches ne reçoivent pas une éducation de très bonne qualité.`}</strong>{` Près de la moitié des enfants issus de familles riches ne maîtrisent pas les compétences de base en lecture, ce qui limite l’incidence possible des politiques axées sur l’équité. Les simulations de l’égalité entre les garçons et les filles, ou entre les enfants issus des milieux urbains et ruraux, montrent des bénéfices encore plus limités.`}</p>
    <p>{`Les pays souhaitant remédier au faible niveau d’apprentissage doivent retenir que la réduction des inégalités entre les groupes au sein d’un pays ne constitue qu’une partie de la solution. `}<strong parentName="p">{`Il est tout aussi urgent de procéder à des changements systémiques pour améliorer l’apprentissage de tous les enfants, riches et pauvres, garçons et filles, en milieu urbain et rural, pour passer d’un système faiblement performant à un système très performant.`}</strong></p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_SIMULATION_EQUALITY" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="CURRENT" mdxType="ScrollySection">
    Les enfants issus de familles <HighlightByIndex index="4" mdxType="HighlightByIndex">pauvres</HighlightByIndex>
    présentent une trajectoire d’apprentissage inférieure à celle des enfants issus de
    familles riches. Si l’on fait la moyenne de 23 pays à revenu faible et à revenu
    intermédiaire de la tranche inférieure, seuls 19 pour cent des enfants issus de
    familles pauvres maîtrisent les compétences de base en lecture à l’âge de 9 ans.
  </ScrollySection>
  <ScrollySection id="SIMULATION_ACCESS" mdxType="ScrollySection">
    La <HighlightByIndex index="2" mdxType="HighlightByIndex">première simulation</HighlightByIndex> montre 
    ce qui pourrait se passer si les enfants issus de familles pauvres étaient autant
    scolarisés que les enfants issus de familles riches (s’agissant de l’année
    d’enseignement atteinte par âge). Si l’on fait la moyenne des 23 pays, une telle
    égalité ne ferait augmenter les compétences de base en lecture que de 4 points de
    pourcentage à l’âge de 9 ans.
  </ScrollySection>
  <ScrollySection id="SIMULATION_LEARNING" mdxType="ScrollySection">
    La <HighlightByIndex index="3" mdxType="HighlightByIndex">deuxième simulation</HighlightByIndex> montre ce qui
    pourrait se passer si les enfants issus de familles pauvres maintenaient leur niveau
    de scolarisation actuel (c’est-à-dire s’ils atteignaient la même année
    d’enseignement qu’à l’heure actuelle) mais qu’ils en apprenaient autant par année
    d’enseignement que les enfants issus de familles riches. Une telle égalité ferait
    potentiellement augmenter les compétences de base en lecture de 17 points de
    pourcentage à l’âge de 9 ans.
  </ScrollySection>
  <ScrollySection id="COMPARISON" mdxType="ScrollySection">
    Si les enfants issus de familles pauvres étaient autant scolarisés et en
    apprenaient autant que les enfants issus de familles riches (c’est-à-dire si les
    pauvres avaient la même trajectoire que les riches), les compétences de base en
    lecture des enfants issus de familles pauvres pourraient augmenter de 29 points
    de pourcentage à l’âge de 9 ans.
  </ScrollySection>
  <ScrollySection id="COMPARISON_GROUP" mdxType="ScrollySection">
    Néanmoins, près de la moitié des enfants ne maîtriseraient toujours pas les
    compétences de base en lecture. Même les enfants issus de familles riches ne
    reçoivent pas une éducation de bonne qualité dans de nombreux pays.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <h2 {...{
      "id": "créez-vos-propres-trajectoires-dapprentissage-et-simulations-de-politiques"
    }}>{`Créez vos propres trajectoires d’apprentissage et simulations de politiques`}</h2>
    <p>{`Vous trouverez ci-dessous un outil vous permettant de créer vos propres trajectoires d’apprentissage et simulations de politiques. Vous pouvez examiner les données de multiples pays concernant à la fois les compétences de base en lecture et celles en mathématiques.`}</p>
    <p>{`Pour rappel, les compétences en lecture sont mesurées par le fait que les enfants puissent lire à voix haute une histoire simple de 70 mots et répondre à cinq questions s’y rapportant. Les compétences en mathématiques sont mesurées dans quatre domaines (identification des nombres, différentiation des nombres, addition simple et suites de nombres) à l’aide de 21 questions simples. On considère qu’un enfant maîtrise ces compétences de base en lecture et en mathématiques s’il répond correctement à toutes les questions. Ces compétences correspondent généralement au niveau de la deuxième ou de la troisième année, et sont inférieures au niveau de compétence minimal défini par `}<a parentName="p" {...{
        "href": "https://sdgs.un.org/goals/goal4"
      }}>{`l’indicateur mondial 4.1.1 a des ODD`}</a>{`.`}</p>
    <Explorer mdxType="Explorer">
  <TrajectoriesTab mdxType="TrajectoriesTab">
    Les tendances d’apprentissage varient grandement d’un contexte à l’autre. Cet outil
    vous permet d’élaborer, de comparer et d’exporter les trajectoires qui sont les plus
    pertinentes pour vous. Vous pouvez visualiser et comparer les trajectoires de
    différents pays, ou bien générer et comparer les trajectoires de certains groupes
    au sein d’un pays.
  </TrajectoriesTab>
  <SimulationsTab mdxType="SimulationsTab">
    Ces outils vous permettent de simuler des politiques et d’analyser les résultats
    pour éclairer les priorités en la matière. Vous pouvez faire des simulations au
    niveau national, en estimant ce qui se passerait si l’on réalisait la scolarisation
    universelle et que l’on atteignait le niveau d’apprentissage par année d’un pays de
    comparaison. Vous pouvez également faire des simulations concernant des groupes
    spécifiques au sein d’un pays, en simulant ce qui pourrait se passer si deux groupes
    de population avaient les mêmes taux de scolarisation et d’apprentissage.
  </SimulationsTab>
    </Explorer>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Les trajectoires d’apprentissage révèlent la vitesse à laquelle les enfants acquièrent les compétences d’apprentissage de base. Elles constituent un outil important pour sensibiliser le public aux faibles résultats d’apprentissage et aider les décideurs politiques à planifier des mesures correctives.`}</p>
    <h3 {...{
      "id": "remerciements"
    }}>{`Remerciements`}</h3>
    <p>{`Le contenu de cette page web a été créé par le `}<a parentName="p" {...{
        "href": "http://riseprogramme.org/"
      }}>{`programme RISE (Research on Improving Systems of Education)`}</a>{`, qui est un programme de recherche à grande échelle sur les systèmes d’éducation soutenu par des financements du `}<a parentName="p" {...{
        "href": "https://www.gov.uk/government/organisations/foreign-commonwealth-development-office"
      }}>{`ministère des Affaires étrangères, du Commonwealth et du Développement (FCDO) du Royaume-Uni`}</a>{`, the `}<a parentName="p" {...{
        "href": "http://dfat.gov.au"
      }}>{`ministère des Affaires étrangères et du Commerce (DFAT) de l’Australie`}</a>{`, et de la `}<a parentName="p" {...{
        "href": "https://www.gatesfoundation.org"
      }}>{`Fondation Bill et Melinda Gates`}</a>{`. Ce programme est géré et mis en œuvre dans le cadre d’un partenariat entre `}<a parentName="p" {...{
        "href": "https://www.opml.co.uk"
      }}>{`Oxford Policy Management`}</a>{` and the `}<a parentName="p" {...{
        "href": "http://bsg.ox.ac.uk"
      }}>{`Blavatnik School of Government à la Université d'Oxford`}</a>{`.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://riseprogramme.org/tools/learning-trajectories"
          }}>{`Learning trajectories resources – RISE`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.sciencedirect.com/journal/international-journal-of-educational-development/special-issue/1035CNWP9N3"
          }}>{`Special issue on learning profiles (open source) – International Journal of Educational Development (IJED)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://mics.unicef.org/files?job=W1siZiIsIjIwMjAvMDUvMTIvMTgvMjUvNDUvNzAxLzIwXzA1XzA4X01JQ1NfR3VpZGVib29rX2NvbXByZXNzZWQucGRmIl1d&sha=6d386818d588d05c"
          }}>{`How to analyze MICS6 education data - UNICEF`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://riseprogramme.org/publications/descriptive-learning-trajectories-and-policy-simulations-using-mics6-data"
          }}>{`Simulations methodology – RISE`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;