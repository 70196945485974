
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Apprentissage";
export const lead = "Dans la plupart des pays d’Afrique subsaharienne, plus de la moitié des élèves des petites classes n’atteignent pas le niveau minimum de compétences en lecture.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Scolariser les enfants ne suffit pas. Sans négliger les nombreux autres buts légitimes poursuivis par les écoles ou qui devraient l’être, il est indispensable que les élèves soient capables de lire et d’effectuer des calculs de base. Ces compétences sont le fondement des autres apprentissages, de l’autonomisation et du bien être des individus, ainsi que des réalisations sociales, politiques et économiques.`}</p>
    </blockquote>
    <h2 {...{
      "id": "enfants-et-jeunes"
    }}>{`Enfants et jeunes`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Dans certains des pays les plus pauvres du monde, de nombreux enfants ne possèdent pas les compétences élémentaires en lecture et en mathématiques. Selon des évaluations de la lecture dans les petites classes – réalisées dans des États du Nord du Nigéria, comme le Kaduna et le Sokoto, ainsi que dans des provinces de la République démocratique du Congo, comme celles de l’Équateur et du Katanga, par exemple – plus de 80 % des élèves de 3e année d’école primaire ne savaient même pas déchiffrer un `}<strong parentName="p">{`mot isolé`}</strong>{` dans un texte suivi.`}</p>
      <Figure id="EGRA" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Ces données ne prennent pas en compte les enfants non scolarisés. Les enquêtes menées auprès des ménages permettent de mettre en évidence les compétences des enfants qu’ils soient scolarisés ou non.`}</p>
    <p>{`Ainsi, les graphiques ci-après, qui portent sur plusieurs régions d’Inde et du Pakistan, ont été établis à partir d’évaluations menées par des citoyens.`}</p>
    <p>{`Les `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`lignes rouges`}</HighlightByIndex>{` représentent le pourcentage d’enfants `}<strong parentName="p">{`scolarisés`}</strong>{` qui possèdent des compétences de base en lecture par année d’étude, tandis que les lignes bleues représentent le pourcentage de l’ensemble des enfants possédant des compétences de base en lecture, `}<strong parentName="p">{`qu’ils soient scolarisés ou non, selon l’âge qui correspond à leur année d’étude`}</strong>{`.`}</p>
    <p>{`Les écarts qui séparent les deux lignes rappellent que dans les pays où le taux de déscolarisation est élevé, les responsables politiques qui se contentent de surveiller l’apprentissage des enfants scolarisés n’ont qu’une vision partielle de la situation. Il convient de noter que le niveau de compétences examiné est très bas. Même dans les régions affichant les meilleurs résultats, certains adolescents qui suivent des études secondaires sont incapables de lire un texte de deuxième année.`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`On estime que près de 40 % des enfants en âge de fréquenter l’école primaire ne possèdent pas les compétences de base en matière de lecture. Ce chiffre s’élève à 43 % en Asie centrale et en Asie du Sud, à 84 % en Afrique subsaharienne, mais ne représente que 3 % en Europe et en Amérique du Nord.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.1.0" label="4.1.0" mdxType="IndicatorReference">
      <p>{`Dans de nombreux pays, il n'y a aucune garantie que les enfants pourront lire, et ce même en ayant terminé l'école primaire. Au niveau mondial, parmi ceux qui ont terminé l’éducation primaire, quatre enfants sur dix ne possèdent pas les compétences de base en lecture. Ce chiffre passe à huit sur dix en Afrique subsaharienne, comme le montre le graphique ci-dessous.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Les `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`parties grises`}</HighlightByIndex>{` indiquent le pourcentage d’enfants qui ont achevé l’école primaire sans avoir atteint le niveau minimum de compétences en lecture. Les `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`parties rouges`}</HighlightByIndex>{` représentent les enfants qui n’achèvent pas l’enseignement primaire, et les `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`parties bleues`}</HighlightByIndex>{` les élèves qui achèvent l’enseignement primaire en ayant atteint un niveau minimum de compétences.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Si l’on se place à l’échelle des pays, au Tchad et au Nigéria, environ 90 % des jeunes ayant arrêté l’école après cinq ou six années d’études sont analphabètes. Certains pays affichent toutefois de meilleurs résultats. Au Burundi, au Costa Rica et au Honduras, par exemple, la quasi-totalité des élèves qui ont arrêté l’école après la cinquième ou la sixième année savaient lire et écrire.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="LITBYYRS" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="KEN" mdxType="ScrollySection">
        <p>{`Au `}<strong parentName="p">{`Kenya`}</strong>{`, par exemple, un sur quatre des élèves savent lire et écrire `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`avec jusqu’à quatre ans de scolarité`}</HighlightByIndex>{`, mais ce chiffre grimpe à plus de trois sur quatre parmi ceux qui vont à l’école pendant `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`cinq ou six ans`}</HighlightByIndex>{`, soit l’équivalent de l’enseignement primaire.`}</p>
      </ScrollySection>
      <ScrollySection id="NER" mdxType="ScrollySection">
        <p>{`Au `}<strong parentName="p">{`Niger`}</strong>{`, très peu d’enfants savent lire `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`avec jusqu’à quatre ans de scolarité`}</HighlightByIndex>{`, mais un tiers d’entre eux acquièrent les compétences de base en lecture et en écriture s’ils suivent `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`jusqu’à son terme le cycle de l’enseignement primaire`}</HighlightByIndex>{`.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <p>{`Pour que l’apprentissage puisse être comparé d’un pays à l’autre, les évaluations ou les tests réalisés doivent être comparés à l’aide de normes internationales utilisant une même définition. Des normes de ce type ont été récemment mises en place aux fins des évaluations transnationales, dans le cadre desquelles des étudiants de pays différents passent le même test. Mais les mises en correspondance de ce genre sont bien plus rares en ce qui concerne les évaluations nationales.`}</p>
    <p>{`L’Étude internationale sur les tendances de l'enseignement des sciences et des mathématiques (TIMSS), évaluation qui a lieu tous les quatre ans, permet de suivre les progrès des élèves de quatrième et de huitième années dans le temps. Elle permet d’observer dans quels domaines les progrès sont particulièrement lents. En Égypte, par exemple, le pourcentage d’élèves de huitième année atteignant le niveau minimum en matière de mathématiques est passé de 24 % en 2003 à 27 % seulement en 2019.`}</p>
    <p>{`Certains pays, comme `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`la Malaisie`}</HighlightByIndex>{`, ont même vu diminuer dans le temps la proportion d’élèves atteignant les normes minimales d’apprentissage.`}</p>
    <p>{`Dans d’autres pays, comme `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Barheïn`}</HighlightByIndex>{`, les progrès ont été rapides : la proportion d’élèves atteignant le niveau minimum de compétences a plus que triplé entre 2003 et 2019.`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Contrairement à ce que l’on pourrait penser, les écarts d’apprentissage entre les sexes ne sont pas si profonds : ils ne dépassent pas dix points de pourcentage dans presque tous les pays dans l’enseignement primaire, par exemple.`}</p>
      <p>{`Mais les écarts d’apprentissage entre pays sont immenses. Dans les pays d’Afrique subsaharienne, qui se situent généralement en bas de l’échelle pour ce qui est des compétences en lecture dans l’enseignement primaire, la proportion d’élèves atteignant le niveau minimum de compétences s’étend de seulement 4 % au Burundi à 76 % au Gabon (ces deux exemples sont tirés du PASEC 2019).`}</p>
      <p>{`En ce qui concerne les mathématiques dans l’enseignement primaire, dans les pays d’Amérique latine, les taux de réussite s’étendent de seulement 2 % en République dominicaine à 39 % au Pérou (ces deux exemples sont tirés du LLECE 2019).`}</p>
      <Figure id="ASSESS" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "adultes"
    }}>{`Adultes`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`Entre 2000 et 2020, le pourcentage d’adultes sachant lire et écrire est passé de 81 % à 87 % au niveau mondial, tandis que le pourcentage de jeunes sachant lire et écrire passait de 87 % à 92 %.`}</p>
      <p>{`Malgré ces progrès, l’analphabétisme demeure très répandu dans de nombreux pays, en particulier chez les femmes. En Amérique latine et dans les Caraïbes, le ralentissement des progrès à des niveaux relativement élevés témoigne de la persistance d’îlots constitués de groupes extrêmement défavorisés et « difficiles à atteindre ». Au Paraguay, par exemple, le taux d’alphabétisation des femmes âgées, établi à environ 73 %, a très peu évolué depuis 10 ans.`}</p>
      <p>{`Dans 18 pays d’Afrique subsaharienne, plus de la moitié des femmes adultes (25 à 64 ans) ne savent pas lire. Les progrès ont soit marqué le pas à de très bas niveaux – comme au Mali, où moins de 17 % d’entre elles savent lire – soit été très lents – comme au Congo, où le taux est passé de 72 % à 74 % en 10 ans.`}</p>
      <p>{`Dans l’ensemble, si le nombre d’adultes sachant lire et écrire est plus élevé aujourd’hui qu’il ne l’a été, c’est avant tout parce que davantage d’adultes ont été scolarisés dans leur jeunesse, et non parce que des adultes n’ayant jamais été à l’école ont eu une deuxième chance en matière d’apprentissage. Pour vérifier cette affirmation, le graphique ci-dessous présente des données qui permettent de suivre les compétences en lecture et en écriture d’une cohorte donnée – ou groupe – à mesure qu’elle vieillit.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LITCOHORT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Dans la plupart des pays, le taux d’alphabétisation au sein de certaines `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`cohortes d’adultes`}</HighlightByIndex>{` a stagné voire reculé au fil du temps lorsque ces compétences n’étaient pas suffisamment utilisées.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`À `}<strong parentName="p">{`Haïti`}</strong>{`, la ligne bleue indique que le taux d’alphabétisation des `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`femmes âgées de 20 à 34 ans en 2000`}</HighlightByIndex>{` >, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`de 26 à 40 ans en 2006`}</HighlightByIndex>{` et `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`de 37 à 61 ans en 2017`}</HighlightByIndex>{` a légèrement diminué. Et cependant, étant donné que davantage de jeunes femmes savent lire et écrire, la ligne rouge montre une augmentation du `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`taux d’alphabétisation global chez les femmes âgées de 20 à 34 ans`}</HighlightByIndex>{` au fil du temps.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`Les compétences en lecture et en écriture mesurées par ces évaluations sont élémentaires. Il s’agit d’indiquer si une personne est capable de lire une phrase simple. L’alphabétisation fonctionnelle, qui permet de lire et d’interpréter des instructions écrites, des courriers officiels ou des contrats, est une notion différente.`}</p>
      <p>{`Peu d’enquêtes internationales recueillent des données en matière d’alphabétisation fonctionnelle, encore moins de manière régulière.`}</p>
      <p>{`D’après les données dont nous disposons, nous observons que plus d’un adulte urbain sur 10 en Colombie et au Viet Nam, et un sur quatre en Bolivie ne sont pas alphabétisés d’un point de vue fonctionnel (autrement dit, n’atteignent pas le niveau 1 de compétences).`}</p>
      <p>{`Au `}<strong parentName="p">{`Ghana`}</strong>{`, même parmi les adultes de milieu urbain, seuls 39 % possèdent des compétences fonctionnelles en lecture et en écriture.`}</p>
      <Figure id="LITPROF" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Bien que l’ODD 4 mette désormais l’accent sur le suivi de l’apprentissage, bon nombre de pays ne transmettent toujours pas de données concernant les indicateurs relatifs aux résultats d’apprentissage en lecture et en mathématiques. Même les pays qui souhaitent commencer à évaluer l’apprentissage et à en rendre compte rencontrent souvent des obstacles qui les empêchent d’adopter des solutions efficaces et économiques. Mais les données dont nous disposons suggèrent que la plupart des élèves des pays les plus pauvres du monde n’atteignent pas le niveau minimum d’aptitude s’agissant des compétences les plus élémentaires.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/32553?locale-attribute=fr"
          }}>{`Ending learning poverty – what will it take? – World Bank`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000226157"
          }}>{`EFA GMR 2013/4: Teaching and learning: Achieving quality for all`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/20/the-learning-assessment-market-pointers-for-countries-part-2/"
          }}>{`The learning assessment market - pointers for countries`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;