import {
  RAliasArticle,
  findArticleThemeAlias,
  findThemeAliasIndex,
  themeAliases,
} from "routes";

export const useThemeNavigation = (alias?: RAliasArticle) => {
  if (!alias) return [undefined, undefined];
  const themeAlias = findArticleThemeAlias(alias);
  const amount = themeAliases.length;
  const currentTheme = themeAliases[findThemeAliasIndex(themeAlias)];
  const currentIndex = findThemeAliasIndex(currentTheme);
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : amount - 1;
  const nextIndex = currentIndex >= amount - 1 ? 0 : currentIndex + 1;

  return [themeAliases[prevIndex], themeAliases[nextIndex]];
};
