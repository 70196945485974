export type BreadCrumbData = {
  position: number;
  name: string;
  item: string;
}[];

export function getBreadcrumbSchema(data: BreadCrumbData) {
  const schema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: data.map((x, i) => ({
      "@type": "ListItem",
      position: x.position ?? i,
      name: x.name,
      item: x.item,
    })),
  };
  return JSON.stringify(schema, undefined, 2);
}
