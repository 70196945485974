import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";
import { spacing } from "../../materials";
import { O, pipe } from "../../prelude";
import { findArticleAliasRelaxed, foldRAliasArticle_ } from "../../routes";
import { PageLinkWithAnchor } from "../links";

const useStyles = mkStyles(({ palette, typography, breakpoints }) => {
  return {
    root: css`
      justify-self: flex-end;
      margin-left: auto;

      html[dir="rtl"] & {
        margin-right: auto;
        margin-left: 0;
      }

      @media ${breakpoints.down("l")} {
        order: 2;
      }
    `,
    mark: css`
      ${typography.preset.fontTable};
      display: flex;
      border-radius: ${spacing.base8(2)};
      color: ${palette.text.white};
      height: ${spacing.base8(3)};
      min-width: ${spacing.base8(12)};
      justify-content: center;
      padding-left: ${spacing.base8(1)};
      padding-right: ${spacing.base8(1)};
      margin-left: ${spacing.base8(15)};
      transition: height 0.15s;
      align-items: center;
      white-space: nowrap;

      &:hover {
        height: ${spacing.base8(4)};
        cursor: pointer;
      }

      html[dir="rtl"] & {
        margin-left: 0;
        margin-right: ${spacing.base8(15)};
      }

      html[lang="ar"] & {
        padding-bottom: ${spacing.base8(0.5)};
      }

      @media ${breakpoints.down("l")} {
        margin-left: 0;
        height: ${spacing.base8(3)};

        html[dir="rtl"] & {
          margin-right: 0;
        }
      }
    `,
    color: (highlightColor: string) => css`
      background: ${highlightColor};
    `,
  };
});

export const ThemeLabel = ({
  theme,
  id,
}: {
  theme?: string;
  id?: string;
  color?: string;
}) => {
  const styles = useStyles();

  return pipe(
    findArticleAliasRelaxed(theme),
    O.fold(
      () => null,
      (alias) => {
        const { color, label } = foldRAliasArticle_({
          access: () => ({
            label: <Trans>Access</Trans>,
            color: M.q02Palette[6],
          }),
          equity: () => ({
            label: <Trans>Equity</Trans>,
            color: M.q04Palette[7],
          }),
          learning: () => ({
            label: <Trans>Learning</Trans>,
            color: M.q06Palette[6],
          }),
          trajectories: () => ({
            label: <Trans>Trajectories</Trans>,
            color: M.q06Palette[6],
          }),
          quality: () => ({
            label: <Trans>Quality</Trans>,
            color: M.q08Palette[6],
          }),
          finance: () => ({
            label: <Trans>Finance – Governments and households</Trans>,
            color: M.q09Palette[7],
          }),
          financeAid: () => ({
            label: <Trans>Finance – Aid</Trans>,
            color: M.q09Palette[7],
          }),
        })(alias);
        return (
          <PageLinkWithAnchor name={alias} query={{}} anchor={id || ""}>
            <a css={styles.root}>
              <mark css={[styles.mark, styles.color(color)]} color={color}>
                {label}
              </mark>
            </a>
          </PageLinkWithAnchor>
        );
      }
    )
  );
};
