import css from "@emotion/css";
import styled from "@emotion/styled";
import { TOCContext } from "components/hero-vis/TOC";
import { GEMTheme } from "materials/theme";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import * as M from "..";
import * as F from "./fonts";
import * as L from "./links";

export function baseElements(theme: GEMTheme) {
  return {
    h1: styled.h1`
      ${F.fontHeading1};
    `,
    h2: (props: React.ComponentPropsWithoutRef<"h2">) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { addId, removeId } = React.useContext(TOCContext);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [ref, inView] = useInView({
        rootMargin: "30% 0px 10% 0px",
        // initialInView: false,
      });
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (props.id) {
          inView ? addId(props.id) : removeId(props.id);
        }
      }, [inView, addId, removeId, props.id]);
      return (
        <h2
          ref={ref}
          css={css`
            ${F.fontHeading2}

            *:not(h1) + & {
              margin-top: 2em !important;
            }

            & + *:not(div) {
              margin-top: 1em !important;
            }

            & + div {
              margin-top: -0.5rem !important;
            }
          `}
          {...props}
        >
          {props.children}
        </h2>
      );
    },
    h3: styled.h3`
      ${F.fontHeading3};
    `,
    p: styled.p`
      ${F.fontBody2};
    `,
    a: styled.a`
      ${L.linkBody};
    `,
    em: styled.strong`
      font-style: normal;
      font-weight: bold;
    `,
    strong: styled.strong`
      font-style: normal;
      font-weight: bold;
    `,
    blockquote: ({ children }: $PropsWithChildren) => {
      return (
        <div
          css={css`
            ${F.fontBody1};
            & > p {
              ${F.fontBody1};
            }
            & > p::first-letter {
              float: left;
              padding-right: 0.1em;
              padding-top: 0.2em;
              font-size: 3em;
              font-weight: bold;
              color: ${theme.activeTheme.textColor};
            }
          `}
        >
          {children}
        </div>
      );
    },
    hr: styled.hr`
      border: 0;
      height: 1px;
      background: ${M.divider};
      margin-top: ${M.spacing.base8(2)};
      margin-bottom: ${M.spacing.base8(-1)};
    `,
    img: styled.img`
      max-width: 100%;
    `,
    ul: styled.ul`
      list-style: none;

      li {
        ${F.fontBody2}
        position: relative;
        padding-left: 1.5em;

        &::before {
          content: "•";
          position: absolute;
          left: 0.4em;
          font-size: 1.2em;
        }
      }
    `,
    ol: styled.ol`
      list-style: decimal;
      li {
        ${F.fontBody2}
        margin-left: 1.2em;
      }
    `,
    table: ({ children }: $PropsWithChildren) => {
      return (
        <div
          css={css`
            grid-column: ${M.layoutAreas.main};
            width: 100%;

            @media ${M.bpDown("m")} {
              grid-column: ${M.layoutAreas.figure};
              overflow-x: scroll;
            }
          `}
        >
          <table
            css={css`
              color: ${M.blackText};
              border-collapse: collapse;
              width: 100%;
              & thead th {
                white-space: nowrap;
              }
              & thead th,
              & tbody td {
                ${F.fontTable};
                border-bottom: 1px solid ${M.divider};
                font-weight: normal;
                padding: 1em 0 1em 1em;
                text-align: right;
                &:first-child {
                  min-width: 10em;
                  padding-left: 0;
                  text-align: left;
                }
              }
            `}
          >
            {children}
          </table>
        </div>
      );
    },
  };
}
