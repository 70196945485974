import css from "@emotion/css";
import { pipe } from "fp-ts/lib/function";
import { mkStyles, useQueryParamsWithDefault } from "hooks";
import { E } from "prelude";
import {
  RAlias,
  RAliasTheme,
  REnv,
  getFocusMetadata,
  subthemesIndex,
} from "routes";
import { focusArticles } from "../../focus";
import { ReactComponent as ArticleIcon } from "../../icons/ic_article_16dp.svg";
import * as M from "../../materials";
import { PageLink } from "./links";
import React from "react";

const useStyles = mkStyles(({ palette }) => {
  return {
    linkButton: css`
      width: 100%;
      display: flex;
      gap: ${M.spacing.base8(1)};
      align-items: start;
      cursor: pointer;
      padding: ${M.spacing.base8(1.5)} ${M.spacing.base8(1)};
      user-select: none;

      p {
        ${M.fontTable}
        flex: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &:hover {
        color: ${palette.brand.marine.main};
        background: ${palette.grayscale[3]};
      }
    `,

    linkIcon: css`
      color: ${palette.brand.marine.main};
      margin-top: ${M.spacing.base1(2)};
      width: 16px;
      height: 16px;
    `,
  };
});

export function FocusThemeList({ theme }: { theme: string }) {
  const styles = useStyles();
  const { locale } = useQueryParamsWithDefault(REnv);

  const focusArticlesTheme = pipe(
    theme,
    RAliasTheme.decode,
    E.fold(
      () => [],
      (themeAlias) => {
        const subthemes = subthemesIndex[themeAlias];
        return focusArticles.published
          .map((focus) => getFocusMetadata(focus as RAlias, locale))
          .filter((focus) => subthemes.includes(focus.article))
          .sort((a, b) => a.year - b.year);
      }
    )
  );

  return (
    <div>
      {focusArticlesTheme.map((article) => {
        return (
          <PageLink name={article.alias} key={article.alias}>
            <a css={styles.linkButton}>
              <ArticleIcon css={styles.linkIcon} />
              <p>{article.title}</p>
            </a>
          </PageLink>
        );
      })}
    </div>
  );
}
