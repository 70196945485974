
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Financement";
export const lead = "Un pays sur quatre ne respecte aucun des grands critères en matière de financement énoncés par le Cadre d’action Éducation 2030 à l’intention des pouvoirs publics.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <p>{`Les cibles relatives à l’éducation, ambitieuses, ne seront pas réalisées à temps sans ressources supplémentaires, notamment dans les pays qui accusent le plus grand retard.`}</p>
    <p>{`Le rapport GEM calcule que pour parvenir à l’objectif 2030 pour l’éducation de base universelle, c’est-à-dire l’enseignement préprimaire, primaire et secondaire, les pays à revenu faible et à revenu intermédiaire de la tranche inférieure doivent dépenser 504 milliards de dollars É.-U., soit 6,3 % de leur produit intérieur brut (PIB) chaque année.`}</p>
    <p>{`Les trois sources principales de dépenses d'éducation sont les gouvernements, les ménages et les donateurs. Cette page se concentrera sur les deux premiers. Une page distincte est consacrée à `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/fr/articles/finance-aid"
      }}>{`l’aide à l’éducation`}</a>{`.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`Sur 5 000 milliards de dollars É.-U. dépensés dans l’éducation chaque année dans le monde entier, seuls 0,5 % correspondent à des pays à faible revenu, contre 66 % pour les pays à revenu élevé. Dans les deux groupes, pourtant, le nombre d’enfants en âge d’être scolarisés est globalement le même.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
  
Dans le pays médian, <HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">les ménages</HighlightByPalette>  prennent en charge 30 % du coût des études. Les ménages participent en fait bien plus aux frais liés aux études dans les pays à faible revenu (39 %) que dans les pays à revenu élevé (15 %).
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
  
Bien que les <HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">donateurs</HighlightByPalette> contribuent à hauteur de 13 % aux dépenses dans les pays à faible revenu [(voir sous-thème sur l'aide à l’éducation)](https://www.education-progress.org/fr/articles/finance-aid)…
  
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">  
  
… les contributions <HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">des donateurs</HighlightByPalette> représentent moins de 1 % des dépenses à l’échelle mondiale, un effort à peine visible en termes absolus.
  
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "dépense-publique"
    }}>{`Dépense publique`}</h2>
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`Le `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656_fre"
        }}>{`Cadre d’action Éducation 2030`}</a>{` a établi deux grands critères de financement à l’intention des pouvoirs publics. Il leur recommande:`}</p>
      <ul>
        <li parentName="ul">{`de consacrer au moins 4 à 6 % du PIB à l’éducation et/ou`}</li>
        <li parentName="ul">{`de consacrer au moins 15 à 20 % des dépenses publiques à l’éducation.`}</li>
      </ul>
      <p>{`Ces niveaux ont été globalement observés dans le monde entier. Les États des pays les plus pauvres avec peu de capacités à générer des ressources nationales mais une grande population d’enfants ont tendance à moins dépenser en matière d’éducation par rapport au PIB, mais plus par rapport au total des dépenses publiques.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`En Afrique subsaharienne, les dépenses publiques en matière d’éducation se situent à 15,5 % du total des dépenses publiques, mais à 4,3 % du PIB. Inversement, les pays d’Europe et d’Amérique du Nord remplissent l’objectif intermédiaire par rapport au PIB (5,0 %), mais affectent une faible part du total des dépenses publiques à l’éducation (12,2 %).`}</p>
      <p>{`Mais, dans l’ensemble, environ un pays sur trois ne remplit pas l’un ou l’autre de ces objectifs intermédiaires. Affichez l’historique de ces données pour observer comment ces dépenses fluctuent selon les années.`}</p>
    </IndicatorReference>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <p>{`Les principales recettes publiques proviennent de la fiscalité, même dans les pays à faible revenu qui tirent une grande part de leurs revenus de subventions (c’est-à-dire sous forme d’aide). Dans l’ensemble, les recettes fiscales moyennes par rapport au PIB s’établissaient à 14 % dans les pays à faible revenu, à 18 % dans les pays à revenu intermédiaire de la tranche inférieure, à 22 % dans les pays à revenu intermédiaire de la tranche supérieure et à 33 % dans les pays à revenu élevé. Même si tous les pays ont donné à l’éducation la même priorité dans leur budget, par exemple, les pays qui lèvent plus d’impôts et d’autres ressources nationales dépenseront davantage dans l’éducation.`}</p>
    <p>{`Cela étant, la variation importante du montant dépensé dans le domaine éducatif entre pays affichant des caractéristiques par ailleurs similaires indique que ces relations ne sont en rien prédéterminées. Certains pays font le choix de dépenser plus dans l’éducation pour atteindre leurs objectifs. Or il nous faudrait insister sur ceux qui ne le font pas.`}</p>
    <h2 {...{
      "id": "dépenses-des-ménages"
    }}>{`Dépenses des ménages`}</h2>
    <p>{`Les ménages consacrent un montant important de leurs revenus à l’éducation de leurs enfants et c’est d’autant plus le cas dans les pays les plus pauvres.`}</p>
    <p>{`Ces dépenses peuvent prendre la forme des frais de scolarité et autres, mais aussi de biens et services éducatifs, dont certains sont obligatoires (par exemple, les manuels scolaires) et d’autres facultatifs (comme les cours particuliers).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Lorsque ces données sont standardisées d’un pays à un autre, la contribution des ménages aux dépenses totale en matière d’éducation ressort nettement. Voilà qui peut apporter une nouvelle compréhension des efforts des différents pays en matière d’éducation.`}</p>
      <p>{`En moyenne, les ménages consacrent 1,9 % du PIB à l’éducation ; les États, 4,5 % du PIB. Ainsi, les ménages représentent environ 30 % des dépenses totales en matière d’éducation. Les parts médianes vont de 15 % dans les pays à revenu élevé à 39 % dans les pays à revenu faible et à revenu intermédiaire de la tranche inférieure. Elles s’élèvent à 38 % en Asie centrale et en Asie du Sud et à 37 % en Afrique subsaharienne, mais seulement à 12 % en Europe et en Amérique du Nord.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,NGA,FRA" mdxType="ScrollySection">
          <p>{`Par exemple, en 2019, `}<strong parentName="p">{`le Nigeria`}</strong>{` affichait l’un des plus faibles niveaux de `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`dépenses publiques`}</HighlightByIndex>{` en pourcentage du PIB. Néanmoins, après prise en compte des `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`contributions des ménages`}</HighlightByIndex>{`, les dépenses nationales totales du Nigeria dans l’éducation en pourcentage du PIB étaient très similaires à celles de `}<strong parentName="p">{`la France`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,PAK,DEU" mdxType="ScrollySection">
          <p>{`L’État `}<strong parentName="p">{`du Pakistan`}</strong>{` a consacré environ 2,5 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`points de pourcentage du PIB`}</HighlightByIndex>{` de moins à l’éducation que `}<strong parentName="p">{`l’Allemagne`}</strong>{` (barre bleue). Cependant, le Pakistan a dépensé globalement plus que l’Allemagne, car ses `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`ménages`}</HighlightByIndex>{` ont dépensé plus de trois points de pourcentage du PIB en matière d’éducation (barre rouge).`}</p>
        </ScrollySection>
        <ScrollySection id="relative,BGD,LBN,HTI" mdxType="ScrollySection">
          <p>{`La `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`part des ménages`}</HighlightByIndex>{` dans les dépenses totales d’éducation grimpe à plus de 70 % dans certains pays, dont `}<strong parentName="p">{`le Bangladesh`}</strong>{`, `}<strong parentName="p">{`Haïti`}</strong>{` et `}<strong parentName="p">{`le Nigeria`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`L’équipe du Rapport mondial de suivi sur l'éducation soutient depuis longtemps que comprendre les dépenses mondiales dans l’éducation exige d’examiner conjointement ses trois sources principales de financement : le financement national public et privé - discutés dans cette page - et les donateurs externes, discutés dans la page sur `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/fr/articles/finance-aid"
      }}>{`l'aide à l'éducation`}</a>{`. Il est donc nécessaire de disposer de données à jour et cohérentes des pays à revenu faible et à revenu intermédiaire sur le montant des dépenses dans l’éducation et leurs sources.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://report.educationcommission.org/report/"
          }}>{`The Learning Generation – Investing in education for a changing world`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/14/aid-to-education-falls-slightly-in-2017-shifts-away-from-primary-education/"
          }}>{`Aid to education falls slightly in 2017 – GEM Report press release`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232197_fre"
          }}>{`Pricing the right to education – GEM Report policy paper 2015`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;