
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "Focus Articles";
export const lead = "In each edition of the GEM Report, the Monitoring Section provides focus articles highlighting key issues that are shaping progress towards SDG 4. Here we provide a selection of these articles related to the themes of the SCOPE website.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const FocusLogo = makeShortcode("FocusLogo");
const Title = makeShortcode("Title");
const FocusIndex = makeShortcode("FocusIndex");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <FocusLogo mdxType="FocusLogo" />
    <Title mdxType="Title">Focus Articles</Title>
    <p>{`In each edition of the GEM Report, the Monitoring Section provides focus articles highlighting key issues that are shaping progress towards SDG 4. Here we provide a selection of these articles related to the themes of the SCOPE website.`}</p>
    <FocusIndex mdxType="FocusIndex" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;