export type Dir = "start" | "end";

const rtlMapping: Record<Dir, Dir> = {
  start: "end",
  end: "start",
};

export function rtlDir(isRTL: boolean, dir: Dir) {
  return isRTL ? rtlMapping[dir] : dir;
}
