import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import { motion } from "framer-motion";
import React from "react";
import { mkStyles, useTheme } from "../../hooks";
import * as M from "../../materials";
import { foldRAliasTheme_, themeAliases } from "../../routes";
import { PageLink } from "../links";

const useStyles = mkStyles(
  ({ palette, typography, spacing: { base1, base8 } }) => {
    return {
      root: css`
        grid-column: ${M.layoutAreas.wide};
      `,
      section: css`
        display: flex;
        justify-content: space-between;
        padding: ${base8(2)} 0;

        @media ${M.bpDown("m")} {
          padding: 0;
          max-width: 280px;
          margin: ${base8(-3)} auto 0 auto;
          justify-content: center;
          flex-wrap: wrap;
          & > * {
            margin-top: ${base8(3)};
          }
        }
      `,
      listItem: css`
        ${typography.preset.fontBody2};
        display: block;
        border-radius: ${base1(1)};
        width: 100%;
        @media ${M.bpDown("m")} {
          width: 50%;
        }
      `,
      listItemLink: css`
        ${typography.regular};
        display: block;
        text-align: center;
        color: ${palette.text.light};

        @media ${M.bpDown("s")} {
          margin-top: ${base8(3)};
        }
      `,
      illustration: css`
        position: relative;
        width: ${base8(16)};
        height: ${base8(21)};
        margin: 0 auto ${base8(1)} auto;
        border-radius: ${base1(4)};

        img {
          position: absolute;
          top: ${base8(-2)};
          left: -15%;
          width: 130%;
        }
      `,
    };
  }
);

export interface ThemeLinksProps {
  label?: string;
}

export const getLabel = foldRAliasTheme_({
  access: () => <Trans>Access</Trans>,
  equity: () => <Trans>Equity</Trans>,
  learning: () => <Trans>Learning</Trans>,
  quality: () => <Trans>Quality</Trans>,
  finance: () => <Trans>Finance</Trans>,
});

const parentVariants = (color: string) => ({
  default: {
    color: "inherit",
    y: 0,
    zIndex: 0,
  },
  hover: {
    color,
    y: 0,
    zIndex: 1,
  },
});

const childVariants = () => ({
  default: {
    y: 0,
    scale: 1,
  },
  hover: {
    y: 0,
    scale: 1.05,
  },
});

const illuVariants = {
  default: {
    y: 0,
    scale: 1,
  },
  hover: {
    y: -5.5,
    scale: 1.1,
  },
};

const textVariants = {
  default: {
    y: 0,
    scale: 1,
  },
  hover: {
    y: 2,
    scale: 1,
  },
};

function setHomeGridBackground(color: string) {
  if (typeof document !== "undefined") {
    document.documentElement.style.setProperty("--homegrid-background", color);
  }
}

export const ThemeLinks = ({}: ThemeLinksProps) => {
  const styles = useStyles();
  const { palette, utils } = useTheme();

  return (
    <div css={styles.root}>
      <ul css={styles.section}>
        {themeAliases.map((alias) => {
          const theme = palette.theme[alias];

          return (
            <li css={styles.listItem} key={alias}>
              <PageLink name={alias} query={{}}>
                <motion.a
                  css={styles.listItemLink}
                  variants={parentVariants(theme.textColor)}
                  whileHover="hover"
                  onMouseEnter={() => {
                    setHomeGridBackground(
                      utils.lighten(theme.background.light, 0.6)
                    );
                  }}
                  onMouseLeave={() => {
                    setHomeGridBackground("white");
                  }}
                >
                  {theme.kind === "article" && (
                    <motion.div
                      css={styles.illustration}
                      initial="default"
                      variants={childVariants()}
                    >
                      <motion.img
                        src={theme.illustration}
                        role="presentation"
                        initial="default"
                        variants={illuVariants}
                      />
                    </motion.div>
                  )}
                  <motion.div variants={textVariants}>
                    {getLabel(alias)}
                  </motion.div>
                </motion.a>
              </PageLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
