import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import { springConfigs } from "charts/lib/motion";
import { AnimatePresence, motion, MotionProps, Variants } from "framer-motion";
import React, { forwardRef, useState } from "react";
import { mkStyles, useTheme } from "../hooks";
import { useAppReducer } from "../hooks/useAppState";
import * as M from "../materials";
import { O, pipe } from "../prelude";
import {
  articleAliases,
  findArticleThemeAlias,
  foldRAliasArticle_,
  foldRAliasTheme_,
  matchesArticleAlias,
  RAliasArticle,
  RAliasTheme,
  subthemesIndex,
  themeAliases,
} from "../routes";
import { gridCenteredMaxWidth } from "./app-layout";
import { PageLink } from "./links";

const useStyles = mkStyles(
  ({ typography, spacing: { base8 }, palette, utils, zIndex }) => {
    const { grid, areas } = gridCenteredMaxWidth();
    return {
      root: css`
        ${grid};
        background: ${palette.brand.blue.main};
        position: absolute;
        width: 100%;
        z-index: ${zIndex.overlapContext};
        box-shadow: 0 ${base8(5)} ${base8(10)} ${base8(-3)}
          ${utils.fade(palette.brand.blue.dark, 0.75)};
      `,
      section: css`
        grid-area: ${areas.main};
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        overflow: hidden;

        li {
          flex-basis: 100%;
        }
      `,
      listItem: css`
        ${typography.preset.fontHeading3};
        cursor: pointer;
        border-radius: ${base8(0.5)};
        margin: 0 ${base8(1)};
        min-height: 0;
        display: flex;
        height: ${base8(46)};
        img {
          max-width: 90%;
          transition: 0.3s transform;
        }
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: ${base8(1)};
          height: 100%;
          width: 100%;
          color: inherit;
        }
        &:hover img {
          transform: translateY(${base8(-2)});
        }
      `,
      subListItem: css`
        ${typography.preset.fontHeading4};
        text-align: center;

        height: ${base8(22)};
        padding: ${base8(1)};
        margin-bottom: ${base8(2)};
        img {
          max-width: 40%;
          transition: 0.3s transform;
        }
        &:hover img {
          transform: translateY(${base8(-0.5)});
        }
      `,
      subListItemSkipMargin: css`
        margin-bottom: 0;
      `,
      listItemFocused: css`
        outline: 2px solid ${palette.brand.marine.dark};
        outline-offset: 2px;
      `,
      listItemFallback: M.onlyIE(css`
        height: auto;
        a {
          display: block;
        }
      `),
    };
  }
);

export interface ArticleSwitcherProps {
  selectedArticle: O.Option<RAliasArticle>;
}

const ulVariants: Variants = {
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      staggerChildren: 0.06,
    },
  },
  hidden: {
    opacity: 1,
    height: 0,
    transition: {
      delay: 0.2,
      staggerChildren: 0.03,
      staggerDirection: -1,
    },
  },
};

export const getThemeLabel = foldRAliasTheme_({
  access: () => <Trans>Access</Trans>,
  equity: () => <Trans>Equity</Trans>,
  learning: () => <Trans>Learning</Trans>,
  quality: () => <Trans>Quality</Trans>,
  finance: () => <Trans>Finance</Trans>,
});

export const getLabel = foldRAliasArticle_({
  access: () => <Trans>Access</Trans>,
  equity: () => <Trans>Equity</Trans>,
  learning: () => <Trans>Learning outcomes</Trans>,
  trajectories: () => <Trans>Learning trajectories</Trans>,
  quality: () => <Trans>Quality</Trans>,
  finance: () => <Trans>Finance – Governments and households</Trans>,
  financeAid: () => <Trans>Finance – Aid</Trans>,
});

export const ArticleSwitcherLegacy = ({
  selectedArticle,
}: ArticleSwitcherProps) => {
  const styles = useStyles();
  const [state, dispatch] = useAppReducer();
  const [hoveredTheme, setHoveredTheme] = useState<RAliasTheme | null>(null);

  const selectedTheme = pipe(
    selectedArticle,
    O.fold(
      () => null,
      (a) => findArticleThemeAlias(a)
    )
  );

  const listRef = React.useRef<HTMLAnchorElement>(null);
  const lastIndex = articleAliases.length - 1;

  React.useEffect(() => {
    if (typeof document !== "undefined") {
      const el = listRef.current;
      const origin = document ? document.activeElement : null;

      if (state.themeDrawer.isOpen && el) {
        el.focus();
      }
      return () => {
        /** return focus to origin */
        origin &&
          origin.id === "article-switcher-toggle" &&
          (origin as HTMLButtonElement).focus();
      };
    } else return;
  }, [listRef, state.themeDrawer.isOpen]);

  return (
    <AnimatePresence initial={false} key="themeDrawer">
      {state.themeDrawer.isOpen && (
        <motion.div
          css={styles.root}
          initial="hidden"
          animate="show"
          exit="hidden"
          variants={ulVariants}
        >
          <ul css={styles.section} role="menu" id="article-switcher">
            {themeAliases.map((alias, index) => {
              const subthemes = subthemesIndex[alias];
              const hasSubthemes = subthemes.length > 1;
              const shouldRenderSubthemes = [
                alias === hoveredTheme || alias === selectedTheme,
                hasSubthemes,
              ].every(Boolean);

              return (
                <motion.li
                  key={alias}
                  onMouseEnter={() => setHoveredTheme(alias)}
                  onMouseLeave={() => setHoveredTheme(null)}
                  variants={{
                    show: { opacity: 1, y: "0%" },
                    hidden: { opacity: 0, y: "-55%" },
                  }}
                  transition={springConfigs.gentle}
                >
                  {shouldRenderSubthemes ? (
                    subthemes.map((article, articleIndex) => (
                      <ArticleCard
                        key={`subtheme-${article}`}
                        label={getLabel(article)}
                        alias={article}
                        isSubtheme={true}
                        selectedArticle={selectedArticle}
                        onClick={() => dispatch({ type: "themeDrawer.close" })}
                        onKeyUp={() => {
                          lastIndex === index &&
                            dispatch({ type: "themeDrawer.close" });
                        }}
                        ref={index === 0 ? listRef : null}
                        skipMargin={articleIndex === subthemes.length - 1}
                        variants={{
                          show: { y: 0 },
                          hidden: articleIndex > 0 ? { y: 10 } : { y: -10 },
                        }}
                      />
                    ))
                  ) : (
                    <ArticleCard
                      key={alias}
                      label={getThemeLabel(alias)}
                      alias={alias}
                      isSubtheme={false}
                      selectedArticle={selectedArticle}
                      onClick={() => dispatch({ type: "themeDrawer.close" })}
                      onKeyUp={() => {
                        lastIndex === index &&
                          dispatch({ type: "themeDrawer.close" });
                      }}
                      ref={index === 0 ? listRef : null}
                    />
                  )}
                </motion.li>
              );
            })}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const ArticleCard = forwardRef(
  (
    {
      label,
      onClick,
      onKeyUp,
      alias,
      isSubtheme,
      skipMargin,
      selectedArticle,
      ...props
    }: {
      label: JSX.Element;
      onClick: () => void;
      onKeyUp: () => void;
      alias: RAliasArticle;
      isSubtheme: boolean;
      skipMargin?: boolean;
      selectedArticle: O.Option<RAliasArticle>;
    } & MotionProps,
    ref: React.ForwardedRef<HTMLAnchorElement>
  ) => {
    const { palette, utils } = useTheme();
    const styles = useStyles();

    const isSelectedArticle = matchesArticleAlias(selectedArticle, alias);

    const highlighted = O.isNone(selectedArticle) || isSelectedArticle;
    const theme = palette.theme[alias];

    const baseStyle = {
      color: highlighted ? theme.textColor : palette.text.white,
      backgroundColor: highlighted
        ? theme.background.light
        : utils.fade(theme.background.light, 0.5),
    };

    const imageUrl = theme.kind === "article" ? theme.illustration : null;

    return (
      <motion.div
        css={[
          styles.listItem,
          isSubtheme ? styles.subListItem : null,
          styles.listItemFallback,
          skipMargin ? styles.subListItemSkipMargin : null,
          baseStyle,
        ]}
        whileHover={{
          scale: 1.025,
          y: "-4%",
          color: theme.textColor,
          backgroundColor: theme.background.light,
          zIndex: 1,
        }}
        {...props}
      >
        <PageLink name={alias} query={{}}>
          <a
            onClick={onClick}
            onKeyUp={onKeyUp}
            role="menuitem"
            id={`themedrawer-${isSubtheme ? "subtheme" : ""}-${alias}`}
            tabIndex={0}
            ref={ref}
          >
            {imageUrl && <img src={imageUrl} alt="" />}
            {label}
          </a>
        </PageLink>
      </motion.div>
    );
  }
);
