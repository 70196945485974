
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "关于";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">关于</Title>
    <h2 {...{
      "id": "scope"
    }}>{`SCOPE`}</h2>
    <p>{`SCOPE 汇集了来自教科文组织统计研究所等数据生产者的有关行政数据、住户调查、学习评估和教育资金方面的信息，以探讨在实现可持续发展目标 4 这项全球教育目标方面所取得的进展。`}</p>
    <p>{`网站是对《全球教育监测报告》`}<a parentName="p" {...{
        "href": "https://zh.unesco.org/gem-report/allreports"
      }}>{`印刷版`}</a>{`的补充，使用户能够与数据互动，了解各国和各地区在实现具体目标时取得的成就和面临的挑战。内容既可共享也可下载，用户可以创建图像和数据文件，以便进一步探索、打印或在线使用，或在演示中使用。`}</p>
    <h2 {...{
      "id": "关于全球教育监测报告"
    }}>{`关于《全球教育监测报告》`}</h2>
    <p>{`《全球教育监测报告》是由独立团队编写并由教科文组织主办和发布的年度报告。根据《`}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
      }}>{`2030 年教育行动框架`}</a>{`》，《报告》的任务是监测关于教育的可持续发展目标的进展情况以及国家和国际教育战略的实施情况，以便于要求所有相关合作伙伴对其承诺负起责任。`}</p>
    <LinkBlock title=" 访问网站" url="https://zh.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "关于可持续发展目标-4"
    }}>{`关于可持续发展目标 4`}</h2>
    <p>{`2015 年 9 月，在纽约举行的联合国可持续发展问题首脑会议上，会员国正式通过了《`}<a parentName="p" {...{
        "href": "https://sustainabledevelopment.un.org/post2015/transformingourworld"
      }}>{`2030 年可持续发展议程`}</a>{`》。该议程包含了 17 项可持续发展目标，其中包括可持续发展目标 4：“确保包容和公平的优质教育，让全民终身享有学习机会”。可持续发展目标 4 有七项具体目标和三种执行手段。`}</p>
    <LinkBlock title="查看具体目标和相关的监测框架" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="en" mdxType="FederalMinistryGermanyLogo" />
    <p>{`特别感谢德国联邦教育和研究部在开发教育进展网站方面的合作和慷慨捐助。`}</p>
    <Aside mdxType="Aside">
      <h2 {...{
        "id": "联系方式"
      }}>{`联系方式`}</h2>
      <hr></hr>
      <p>{`一般性电子邮件`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`《报告》小组`}</p>
      <p>{`《全球教育监测报告》小组，教科文组织`}</p>
      <hr></hr>
      <p>{`地址`}</p>
      <p>{`Global Education Monitoring Report Team (GEM Report),
UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;