import css from "@emotion/css";
import { motion } from "framer-motion";
import { Placement } from "popper.js";
import React from "react";
import { Popper } from "react-popper";
import { mkStyles } from "../hooks";
import * as M from "../materials";
import { Portal } from "./portal";

// -----------------------------------------------------------------------------
// TooltipFlag

const useTooltipFlagStyles = mkStyles(
  ({ activeTheme, utils, spacing: { base1, base8 } }) => {
    return {
      root: css`
        ${M.fontMeta};
        background: ${activeTheme.textColor};
        padding: ${base1(2)} ${base1(4)};
        margin: ${base1(4)};
        border-radius: ${base1(2)};
        color: ${utils.getContrastText(activeTheme.textColor, {
          light: M.whiteText,
          dark: activeTheme.background.dark,
        })};
        white-space: nowrap;
      `,
      title: css`
        ${M.fontMeta};
        font-weight: bold;
      `,
      datatable: css`
        display: flex;
        flex-direction: column;
      `,
      datarow: css`
        display: flex;
        justify-content: space-between;
        & > * + * {
          margin-left: ${base8(1.5)};
          html[dir="rtl"] & {
            margin-left: 0;
            margin-right: ${base8(1.5)};
          }
        }
      `,
    };
  }
);

export interface TooltipFlagProps {
  cx: number;
  cy: number;
  distance?: number;
  position?: Placement;
  disableInteraction?: boolean;
  title?: React.ReactNode;
  rows?: Array<[React.ReactNode, React.ReactNode]>;
}

export const TooltipFlag = ({
  title,
  rows,
  cx,
  cy,
  distance = 0,
  position = "top",
  disableInteraction = false,
}: TooltipFlagProps) => {
  const styles = useTooltipFlagStyles();
  const el = new VirtualElement(cx, cy, distance);

  return (
    <Portal>
      <Popper referenceElement={el} placement={position}>
        {({ ref, style, placement, arrowProps }) => (
          <motion.div
            key="tooltip"
            exit="hidden"
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            transition={M.defaultTransition}
            style={{ pointerEvents: disableInteraction ? "none" : "all" }}
          >
            <div
              ref={ref}
              style={{ ...style, zIndex: M.zIndex.tooltip }}
              data-placement={placement}
            >
              <div css={styles.root}>
                {title && <div css={styles.title}>{title}</div>}
                {rows && (
                  <div css={styles.datatable}>
                    {rows.map(([left, right], i) => (
                      <div key={i} css={styles.datarow}>
                        <span>{left}</span>
                        <span>{right}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div ref={arrowProps.ref} style={arrowProps.style} />
            </div>
          </motion.div>
        )}
      </Popper>
    </Portal>
  );
};

// -----------------------------------------------------------------------------
// VirtualElement

class VirtualElement {
  public bounds: ClientRect;
  constructor(cx: number, cy: number, distance: number) {
    this.bounds = {
      top: cy - distance,
      right: cx + distance,
      bottom: cy + distance,
      left: cx - distance,
      width: 2 * distance,
      height: 2 * distance,
      x: cx,
      y: cy,
      toJSON: () => this.bounds,
    };
  }
  getBoundingClientRect() {
    return this.bounds;
  }

  get clientWidth() {
    return this.getBoundingClientRect().width;
  }

  get clientHeight() {
    return this.getBoundingClientRect().height;
  }
}
