
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "Scoping Progress in Education";
export const lead = "Education lights every stage of the journey to a better life. To unlock the wider benefits of education, all children need access to education of good quality. Special efforts are needed to ensure that all children and young people – regardless of their family income, where they live, their gender, their ethnicity, whether they are disabled – can benefit equally from its transformative power. This requires sufficient resources, especially to reach those who need more support.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const PageLink = makeShortcode("PageLink");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scoping-progress-in-education"
      }}>{`Scoping Progress in Education`}</h1>
      <p>{`Education lights every stage of the journey to a better life. All children need `}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`access`}</HighlightByTheme>{` to education of good `}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`quality`}</HighlightByTheme>{` to unlock their `}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`learning`}</HighlightByTheme>{` potential and the wider benefits of education. Special efforts are needed to ensure that all children and young people can benefit `}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{`equally`}</HighlightByTheme>{` from its transformative power. This requires sufficient `}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`resources`}</HighlightByTheme>{`, especially to reach those who need more support.`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`In 2015, the international community included a goal on education, `}<a parentName="p" {...{
          "href": "https://sustainabledevelopment.un.org/sdg4"
        }}>{`SDG 4`}</a>{`, among the 17 Sustainable Development Goals to be achieved by 2030. Since then, an `}<PageLink name="indicators" mdxType="PageLink">{`elaborate framework`}</PageLink>{` has been put in place to monitor progress towards the targets in the goal. Scoping Progress in Education (SCOPE) summarizes the key facts and trends in education around the world through five themes:`}</p>
    </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`It complements the information that is provided in the printed version of the `}<a parentName="p" {...{
          "href": "https://en.unesco.org/gem-report/allreports"
        }}>{`Global Education Monitoring Report`}</a>{`, the tool of the international community for the follow up and review of progress towards our education goal, SDG 4.`}</p>
    </LandingText>
    <LandingButton mdxType="LandingButton">Start reading</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;