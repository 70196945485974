
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Trayectorias de Aprendizaje";
export const lead = "While most children now spend many years in school, they often emerge without even the most basic reading and math skills. Learning trajectories show how much children learn as they progress through school. They can help us understand the process that leads to low learning outcomes, when and how to intervene during that process, and what the simulated impact of different policies might be on the urgent challenge of low learning.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyStep = makeShortcode("ScrollyStep");
const Explorer = makeShortcode("Explorer");
const TrajectoriesTab = makeShortcode("TrajectoriesTab");
const SimulationsTab = makeShortcode("SimulationsTab");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Las trayectorias de aprendizaje indican cuántos años o grados debe completar un niño para adquirir competencias básicas. Dado que examinan el ritmo de aprendizaje en un sistema, ayudan a comprender cuántos niños tienen dificultades para adquirir dichas competencias, cuándo y de qué modo se puede actuar, y qué efectos tendrán las distintas políticas a la hora de atajar el reto urgente que plantean los niveles bajos de aprendizaje.`}</p>
    </blockquote>
    <h2 {...{
      "id": "qué-información-aportan-las-trayectorias-de-aprendizaje"
    }}>{`¿Qué información aportan las trayectorias de aprendizaje?`}</h2>
    <p>{`Lo ideal es que las trayectorias de aprendizaje se correspondan con una línea ascendente y muy inclinada. No obstante, con demasiada frecuencia se corresponden con líneas planas que indican un ritmo de aprendizaje lento. Un aprendizaje lento al principio puede tener consecuencias a largo plazo, pues, cuando un niño se queda atrás, el plan de estudios sigue progresando hacia contenidos más avanzados y el niño carece de las competencias necesarias para seguirlo.`}</p>
    <p><strong parentName="p">{`Las trayectorias de numerosos países demuestran que el ritmo de aprendizaje es demasiado lento desde los primeros niveles escolares.`}</strong>{`  A pesar de que las metas mundiales y muchos de los planes de estudios nacionales asumen que los niños aprenderán a leer en los grados 1 y 2, solo una minoría de los niños lo consigue.`}</p>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Dichas competencias son prerrequisitos básicos para el aprendizaje futuro, y se miden por la capacidad de un niño de leer en voz alta una historia sencilla de 70 palabras (por ejemplo,  “Manh está en la clase número dos.”) y responder a cinco preguntas sencillas al respecto (por ejemplo,  “¿En qué clase está Manh?”). Estas competencias básicas suelen estar por debajo del nivel mínimo de competencias establecido en `}<a parentName="p" {...{
          "href": "https://sdgs.un.org/goals/goal4"
        }}>{`el indicador mundial 4.1.1a para los Objetivos de Desarrollo Sostenible (ODS)`}</a>{`. Las trayectorias calculadas, por tanto, sobrestiman los avances hacia la consecución del ODS 4.1.1a, pero siguen siendo útiles para representar el ritmo de aprendizaje.`}</p>
    </IndicatorReference>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_CURRENT" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="HIGHLIGHT_VIETNAM" mdxType="ScrollySection">
    La trayectoria de <HighlightByIndex index="2" mdxType="HighlightByIndex">Viet Nam</HighlightByIndex> es un buen ejemplo de un país con un desempeño relativamente alto. Durante los primeros   grados escolares, la mayoría de los niños de Viet Nam adquieren competencias de lectura básicas.
  </ScrollySection>
  <ScrollySection id="LMIC_GRADE_3" mdxType="ScrollySection">
    La trayectoria de aprendizaje en muchos de los países de{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">ingreso bajo y mediano bajo</HighlightByIndex>{" "}
    es mucho más plana. Actualmente, solo una media de uno de cada cuatro niños del grado 3 ha adquirido competencias de lectura básicas.
  </ScrollySection>
  <ScrollySection id="LMIC_GRADE_6" mdxType="ScrollySection">
    Los niveles bajos de aprendizaje persisten a medida que los niños avanzan en el colegio. De media, en los países de{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">ingreso bajo y mediano bajo</HighlightByIndex>{" "}
    del conjunto de datos solo algo más de la mitad del alumnado del grado 6 ha adquirido las competencias de lectura que habitualmente corresponden a los grados 2-3.
  </ScrollySection>
  <ScrollySection id="DIFFERENCE_TRAJECTORIES" mdxType="ScrollySection">
    No obstante, la trayectoria media esconde diferencias entre países. En los países de ingreso bajo y mediano bajo del conjunto de datos, el porcentaje de niños que saben leer en el grado 3 puede estar entre un 3 y un 76 por ciento. Ello demuestra que los países que se comprometen a mejorar el aprendizaje básico pueden lograrlo, incluso aunque sus ingresos nacionales sean relativamente bajos.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <p>{`Los países que deseen atajar los bajos niveles de aprendizaje deben tener en cuenta principalmente las siguientes políticas:`}</p>
    <ul>
      <li parentName="ul">{`cuantificar y priorizar las competencias básicas desde los primeros grados;`}</li>
      <li parentName="ul">{`adecuar la enseñanza al ritmo de aprendizaje real de los niños. Esto se puede llevar a cabo de diversas formas, como definiendo objetivos de aprendizaje claros que se centren en el aprendizaje de competencias básicas, ajustando el ritmo del plan de estudios, o brindando apoyo a los docentes para que comprendan el nivel de aprendizaje actual de los niños y adapten su enseñanza a este.`}</li>
    </ul>
    <h2 {...{
      "id": "simulaciones-de-políticas-acceso-y-aprendizaje"
    }}>{`Simulaciones de políticas: acceso y aprendizaje`}</h2>
    <p>{`Las trayectorias de aprendizaje pueden servir para comparar los potenciales efectos de las distintas políticas. De lo que se trata es de simular los efectos de las políticas que tienen por objetivo ampliar el acceso y mejorar el aprendizaje. Las políticas orientadas al acceso tienen por fin aumentar el número de años que los niños están escolarizados. Entre ellas se incluyen políticas como la gratuidad de la educación primaria y secundaria, la construcción de escuelas, el paso automático de un curso al siguiente y otras políticas que se centran principalmente en la matriculación o en el nivel de instrucción. Las políticas orientadas al aprendizaje tienen por objetivo aumentar la cantidad de conocimientos que los niños adquieren en cada grado escolar. Entre ellas se incluyen políticas como, por ejemplo, definir y cuantificar los avances hacia la consecución de unos objetivos de aprendizaje claros, ajustar la enseñanza al ritmo de aprendizaje de los niños, o respaldar el aprendizaje efectivo.`}</p>
    <p>{`Todos los niños deben tener acceso a la escuela, y conseguir que los niños vayan a la escuela es un primer paso imprescindible para el aprendizaje universal. Sin embargo, las simulaciones ponen de manifiesto que seguir `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`ampliando el acceso`}</HighlightByIndex>{`, por sí solo, apenas mejora el aprendizaje. De hecho, puede resultar más provechoso `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`aumentar los conocimientos adquiridos en cada grado`}</HighlightByIndex>{` para acercarse al ritmo de los países de ingreso bajo y mediano bajo que presentan un desempeño más alto.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_SIMULATION_LMIC" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="COMPARISON" mdxType="ScrollySection">
    Las diferencias de desempeño entre los países con un desempeño más bajo y aquellos con un desempeño más alto se deben tanto a la mejora del acceso a la escuela como, en particular, al aumento de los conocimientos que los niños adquieren en cada grado.
  </ScrollySection>
  <ScrollySection id="FADED" mdxType="ScrollySection">
    Estas simulaciones muestran trayectorias por edad que incluyen tanto a los niños escolarizados como a los niños sin escolarizar. Dado que los niños sin escolarizar suelen presentar niveles de aprendizaje más bajos, estas trayectorias de aprendizaje son más planas que las trayectorias por grado.
  </ScrollySection>
  <ScrollySection id="CURRENT" mdxType="ScrollySection">
    De media, en cinco países de{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">ingreso bajo y mediano bajo</HighlightByIndex>{" "}
    que presentan un desempeño bajo solo el 8 por ciento de los niños de 9 años de edad sabe leer con un nivel adecuado para su edad.
  </ScrollySection>
  <ScrollySection id="SIMULATION_ACCESS" mdxType="ScrollySection">
    Las <HighlightByIndex index="2" mdxType="HighlightByIndex">simulaciones de acceso</HighlightByIndex>{" "}
    se proponen estimar qué ocurriría si todos los niños estuvieran escolarizados. Para ello, se estima qué ocurriría si todos los niños que nunca han estado escolarizados, o que han abandonado la escuela, hubiesen alcanzado el mismo grado y nivel de aprendizaje que la media de niños escolarizados con su misma edad. En los cinco países con un desempeño bajo, la simulación revela que el número de niños de 9 años de edad que sabe leer aumentaría un punto porcentual.
  </ScrollySection>
  <ScrollySection id="SIMULATION_LEARNING" mdxType="ScrollySection">
    Si los niños de 9 años de dichos países siguieran alcanzando el mismo grado escolar que alcanzan actualmente, solo que{" "}
    <HighlightByIndex index="3" mdxType="HighlightByIndex">adquiriendo tantos conocimientos por grado</HighlightByIndex> como los niños de los cinco países de ingreso bajo y mediano bajo que presentan un desempeño más alto, el 43 por ciento sabría leer, lo que supondría un aumento de 35 puntos porcentuales.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <h2 {...{
      "id": "simulaciones-de-políticas-igualdad"
    }}>{`Simulaciones de políticas: igualdad`}</h2>
    <p>{`Las trayectorias de aprendizaje son una herramienta que también permite comprender las diferencias de aprendizaje entre los distintos grupos de un mismo país. De lo que se trata es de comparar el aprendizaje de los ricos con el de los pobres y simular los efectos que tendrían las políticas de reducción de la desigualdad entre ellos.`}</p>
    <p>{`De media en todos los países de ingreso bajo y mediano bajo del conjunto de datos, los niños de familias ricas (familias que pertenecen al 20 por ciento más rico) van más a la escuela y aprenden más en cada grado que los niños de familias pobres (familias que pertenecen al 20 por ciento más pobre). La `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`simulación de la igualdad de acceso`}</HighlightByIndex>{` muestra qué ocurriría si los niños pobres presentaran los mismo niveles de escolarización que los ricos, y la `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`simulación de la igualdad de aprendizaje`}</HighlightByIndex>{` muestra qué ocurriría si los niños pobres adquirieran tantos conocimientos por grado como los niños ricos. En ambos casos descendería la desigualdad, sobre todo en la simulación de la igualdad de aprendizaje.`}</p>
    <p><strong parentName="p">{`No obstante, tampoco los niños de familias ricas reciben una educación muy buena`}</strong>{`. Casi la mitad de los niños de familias ricas no consiguen adquirir competencias de lectura básicas, lo que limita los posibles efectos de las políticas orientadas a la igualdad. Las simulaciones de igualdad entre niños y niñas, o entre niños de zonas urbanas y rurales, reportan mejoras aún menores.`}</p>
    <p>{`Los países que deseen atajar los bajos niveles de aprendizaje deben entender que reducir las desigualdades entre los grupos de un mismo país es solo una parte de la solución. `}<strong parentName="p">{`Aplicar cambios sistémicos que mejoren el conocimiento de todos los niños, ya sean ricos o pobres, niños o niñas, de zonas urbanas o de zonas rurales, supone un reto igualmente acuciante para conseguir transformar un sistema con un rendimiento bajo en uno con un rendimiento alto.`}</strong></p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_SIMULATION_EQUALITY" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="CURRENT" mdxType="ScrollySection">
    Los niños de familia <HighlightByIndex index="4" mdxType="HighlightByIndex">pobres</HighlightByIndex> presentan una trayectoria de aprendizaje más baja que los niños de familias ricas. En 23 países de ingreso bajo y mediano bajo, solo una media del 19 por ciento de los niños de familias pobres han adquirido competencias de lectura básicas a los 9 años de edad.
  </ScrollySection>
  <ScrollySection id="SIMULATION_ACCESS" mdxType="ScrollySection">
    La <HighlightByIndex index="2" mdxType="HighlightByIndex">primera simulación</HighlightByIndex> muestra qué ocurriría si los niños de familias pobres presentaran los mismos niveles de escolarización que los niños de familias ricas (en lo que respecta al nivel de instrucción por edad). De media en 23 países, las competencias de lectura básicas a los 9 años de edad aumentarían solo cuatro puntos porcentuales.
  </ScrollySection>
  <ScrollySection id="SIMULATION_LEARNING" mdxType="ScrollySection">
    La <HighlightByIndex index="3" mdxType="HighlightByIndex">segunda simulación</HighlightByIndex> muestra qué ocurriría si los niños de familias pobres siguieran presentando los niveles de escolarización actuales (es decir, si llegaran al mismo grado al que llegan ahora) pero adquirieran tantos conocimientos por grado como los niños de familias ricas. Esto haría que las competencias de lectura básicas a los 9 años de edad aumentaran, potencialmente, 17 puntos porcentuales.
  </ScrollySection>
  <ScrollySection id="COMPARISON" mdxType="ScrollySection">
    Si los niños de familias pobres presentaran los mismos niveles de escolarización y, además, adquirieran tantos conocimientos como los niños ricos (es decir, si los pobres tuvieran la misma trayectoria que los ricos), las competencias de lectura básicas de los niños de familias pobres a los 9 años de edad podrían crecer hasta 29 puntos porcentuales.
  </ScrollySection>
  <ScrollySection id="COMPARISON_GROUP" mdxType="ScrollySection">
    No obstante, casi la mitad de los niños seguirían sin adquirir competencias de lectura básicas. En muchos países, ni siquiera los niños de familias ricas reciben una buena educación.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <h2 {...{
      "id": "realice-sus-propias-trayectorias-de-aprendizaje-y-simulaciones-de-políticas"
    }}>{`Realice sus propias trayectorias de aprendizaje y simulaciones de políticas`}</h2>
    <p>{`A continuación encontrará una herramienta con la que podrá crear sus propias trayectorias de aprendizaje y realizar simulaciones de políticas. Puede consultar los datos de diferentes países sobre competencias básicas, tanto matemáticas como de lectura.`}</p>
    <p>{`Le recordamos que las competencias de lectura se miden por la capacidad de los niños de leer en voz alta una historia sencilla de 70 palabras y responder cinco preguntas sencillas sobre la historia. Las competencias matemáticas se miden mediante 21 preguntas sencillas en torno a cuatro ámbitos (la identificación de los números, la diferenciación de los números, la suma simple y las series numéricas). Se considera que un niño ha adquirido las competencias básicas matemáticas y de lectura si responde a todas las preguntas correctamente. Dichas habilidades suelen corresponder al nivel del grado 2 o 3 y están por debajo del nivel mínimo de competencias establecido en `}<a parentName="p" {...{
        "href": "https://sdgs.un.org/goals/goal4"
      }}>{`el indicador mundial 4.1.1a para los ODS`}</a>{`.`}</p>
    <Explorer mdxType="Explorer">
  <TrajectoriesTab mdxType="TrajectoriesTab">
    Las trayectorias de aprendizaje varían enormemente según el contexto. Esta herramienta le permite generar, comparar y exportar las trayectorias que sean más relevantes para usted. Puede consultar y comparar las trayectorias de los diferentes países, o generar y comparar las trayectorias de los distintos grupos dentro de un país.
  </TrajectoriesTab>
  <SimulationsTab mdxType="SimulationsTab">
    Estas herramientas permiten generar una simulación de políticas y analizar los resultados a fin de orientar las prioridades políticas. Se pueden realizar simulaciones de políticas nacionales, en las que se estiman los efectos de la escolarización universal y de conseguir el mismo nivel de aprendizaje por grado que otro país. Asimismo, se pueden realizar simulaciones de políticas para grupos de un mismo país, en las que se simulan los efectos de que dos grupos de población distintos presenten un nivel de instrucción y un aprendizaje similares.
  </SimulationsTab>
    </Explorer>
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`Las trayectorias de aprendizaje representan el ritmo al que los niños adquieren competencias básicas. Son una herramienta de relevancia que permiten concienciar a la opinión pública acerca del bajo nivel de los resultados del aprendizaje y ayudar a los encargados de la formulación de políticas a ponerse manos a la obra.`}</p>
    <h3 {...{
      "id": "agradecimientos"
    }}>{`Agradecimientos`}</h3>
    <p>{`El contenido de la presente página web han sido elaborados por el `}<a parentName="p" {...{
        "href": "http://riseprogramme.org/"
      }}>{`Programa RISE (siglas en inglés de Research on Improving Systems of Education, Investigación para la Mejora de los Sistemas Educativos)`}</a>{`, un programa de investigación a gran escala sobre sistemas educativos que cuenta con financiación del `}<a parentName="p" {...{
        "href": "https://www.gov.uk/government/organisations/foreign-commonwealth-development-office"
      }}>{`Ministerio de Relaciones Exteriores, del Commonwealth y de Desarrollo del Reino Unido`}</a>{`, el `}<a parentName="p" {...{
        "href": "http://dfat.gov.au"
      }}>{`Ministerio de Asuntos Exteriores y Comercio del Gobierno de Australia`}</a>{` y la `}<a parentName="p" {...{
        "href": "https://www.gatesfoundation.org"
      }}>{`Fundación Bill y Melinda Gates`}</a>{`. Una alianza entre `}<a parentName="p" {...{
        "href": "https://www.opml.co.uk"
      }}>{`Oxford Policy Management`}</a>{` y la `}<a parentName="p" {...{
        "href": "http://bsg.ox.ac.uk"
      }}>{`Escuela de Gobierno Blavatnik de la Universidad de Oxford`}</a>{` se encarga de la gestión e implementación del programa.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://riseprogramme.org/tools/learning-trajectories"
          }}>{`Learning trajectories resources – RISE`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.sciencedirect.com/journal/international-journal-of-educational-development/special-issue/1035CNWP9N3"
          }}>{`Special issue on learning profiles (open source) – International Journal of Educational Development (IJED)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://mics.unicef.org/files?job=W1siZiIsIjIwMjAvMDUvMTIvMTgvMjUvNDUvNzAxLzIwXzA1XzA4X01JQ1NfR3VpZGVib29rX2NvbXByZXNzZWQucGRmIl1d&sha=6d386818d588d05c"
          }}>{`How to analyze MICS6 education data - UNICEF`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://riseprogramme.org/publications/descriptive-learning-trajectories-and-policy-simulations-using-mics6-data"
          }}>{`Simulations methodology – RISE`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;