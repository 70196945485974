export const defaultTransition = {
  duration: 0.2,
  ease: "easeOut",
};

export const opacityTransitionMotionProps = {
  transition: defaultTransition,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
