
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "Показатели ЦУР 4";
export const lead = "Обеспечение всеохватного и справедливого качественного образования и поощрение возможностей обучения на протяжении всей жизни для всех";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const TargetFourLogo = makeShortcode("TargetFourLogo");
const Title = makeShortcode("Title");
const IndicatorSection = makeShortcode("IndicatorSection");
const IndicatorTable = makeShortcode("IndicatorTable");
const IndicatorRow = makeShortcode("IndicatorRow");
const ThemeLabel = makeShortcode("ThemeLabel");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TargetFourLogo mdxType="TargetFourLogo" />
    <Title mdxType="Title">Показатели ЦУР 4</Title>
    <p>{`Обеспечение всеохватного и справедливого качественного образования и поощрение возможностей обучения на протяжении всей жизни для всех`}</p>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-41"
      }}>{`Задача 4.1`}</h2>
      <p>{`К 2030 году обеспечить, чтобы все девочки и мальчики завершали получение бесплатного, равноправного и качественного начального и среднего образования, позволяющего добиться востребованных и эффективных результатов обучения. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=236"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.1.0" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля детей и молодежи, подготовленных к будущему, в разбивке по полу `}</p>
          <ThemeLabel theme="learning" id="4.1.0" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Процент детей и молодежи: (a) в 2-3 классах; (б) в конце начальной школы и (в) в конце неполного среднего образования, которые достигли хотя бы минимального уровня профессиональной подготовки в (i) чтении и (ii) математике, с распределением по полу`}</p>
          <ThemeLabel theme="learning" id="4.1.1a" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Коэффициент завершения (начальное образование, неполное и полное среднее образование)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
  
Общий коэффициент приема в последний класс (начальное образование, неполное среднее образование)
        </IndicatorRow>
        <IndicatorRow id="4.1.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля детей, не посещающих школу (1 год до начала обучения, начальное образование, неполное среднее образование, полное среднее образование)`}</p>
          <ThemeLabel theme="access" id="4.1.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля детей старше установленного для данного класса возраста (начальное образование, неполное среднее образование)`}</p>
          <ThemeLabel theme="access" id="4.1.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Проведение национальной репрезентативной оценки результатов обучения (a) во 2 или 3 классе; (b) в конце начальной школы; и (c) в конце неполной среднее школы.`}</p>
          <ThemeLabel theme="access" id="4.1.6" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Количество лет i) бесплатного и ii) обязательного начального и среднего образования, гарантированных законодательством`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-42"
      }}>{`Задача 4.2`}</h2>
      <p>{`К 2030 году обеспечить, чтобы все девочки и мальчики имели доступ к качественным системам развития, ухода и дошкольного обучения детей младшего возраста, с тем чтобы они были готовы к получению начального образования. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=253"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.2.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Доля детей в возрасте 24–59 месяцев, которые развиваются без отклонений в плане здоровья, обучения и психосоциального благополучия, в разбивке по полу`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Уровень участия в организованных видах обучения (за один год до достижения официального возраста поступления в школу) в разбивке по полу`}</p>
          <ThemeLabel theme="access" id="4.2.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.2.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля детей в возрасте до 5 лет, находящихся в благоприятной домашней обстановке, стимулирующей развитие`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Чистый коэффициент охвата в a) дошкольном образовании и b) программах развития детей младшего возраста`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Количество лет i) бесплатного и ii) обязательного дошкольного образования, гарантированных законодательством`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-43"
      }}>{`Задача 4.3`}</h2>
      <p>{`К 2030 году обеспечить для всех женщин и мужчин равный доступ к недорогому и качественному профессионально-техническому и высшему образованию, в том числе университетскому образованию. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=263"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.3.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{` Уровень участия молодых и взрослых людей в формальных и неформальных видах обучения и профессиональной подготовки в последние 12 месяцев в разбивке по полу `}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Общий коэффициент охвата высшим образованием, в разбивке по полу`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Уровень участия молодежи в профессионально-техническом образовании (в возрасте 15-24 лет), в разбивке по полу`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-44"
      }}>{`Задача 4.4`}</h2>
      <p>{`К 2030 году существенно увеличить число молодых и взрослых людей, обладающих востребованными навыками, в том числе профессионально-техническими навыками, для трудоустройства, получения достойной работы и занятий предпринимательской деятельностью. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=271"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.4.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Доля молодых и взрослых людей, обладающих навыками в области информационно-коммуникационных технологий, в разбивке по видам навыков `}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля молодежи и взрослых, достигших, по крайней мере, минимального уровня цифровой грамотности`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Уровень образования молодежи/взрослых в разбивке по возрастным группам и уровню образования`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-45"
      }}>{`Задача 4.5`}</h2>
      <p>{`К 2030 году ликвидировать гендерное неравенство в сфере образования и обеспечить равный доступ к образованию и профессионально-технической подготовке всех уровней для уязвимых групп населения, в том числе инвалидов, представителей коренных народов и детей, находящихся в уязвимом положении. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=281"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.5.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Индекс равенства (женщины/мужчины, сельское/ городское население, нижний/верхний квантиль по благосостоянию и другие показатели, такие как статус инвалида, принадлежность к коренному и затронутому конфликтом населению в момент поступления данных) по всем показателям в этом списке, которые можно разукрупнить`}</p>
          <ThemeLabel theme="equity" id="4.5.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.2" mdxType="IndicatorRow">
          <p>{`Доля учащихся в а) младших классах, б) в конце начального и в) в конце неполного среднего образования, у которых первый язык или язык, на котором говорят дома, является языком обучения`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.3" mdxType="IndicatorRow">
          <p>{`Степень, в которой политикой, основанной на четких расчетах, предусмотрено распределение ресурсов в области образования для населения из неблагополучных групп`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Расходы на образование одного учащегося, в разбивке по уровням образования и источникам финансирования`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля общей помощи, выделяемая странам с низким уровнем дохода на образование`}</p>
          <ThemeLabel theme="finance" id="4.5.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Расходы на образование по источникам финансирования (государственные, частные, международные) в процентах от ВВП`}</p>
          <ThemeLabel theme="finance" id="4.5.6" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-46"
      }}>{`Задача 4.6`}</h2>
      <p>{`К 2030 году обеспечить, чтобы все молодые люди и значительная доля взрослого населения, как мужчин, так и женщин, умели читать, писать и считать. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=292"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.6.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Доля населения  в данной возрастной группе, достигшая, по меньшей мере, установленного уровня функциональной способности a) писать и читать и b) считать, в разбивке по полу`}</p>
          <ThemeLabel theme="learning" id="4.6.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.6.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Уровень грамотности среди молодежи и взрослых`}</p>
          <ThemeLabel theme="learning" id="4.6.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-47"
      }}>{`Задача 4.7`}</h2>
      <p>{`К 2030 году обеспечить, чтобы все учащиеся приобретали знания и навыки, необходимые для содействия устойчивому развитию, в том числе посредством обучения по вопросам устойчивого развития и устойчивого образа жизни, прав человека, гендерного равенства, пропаганды культуры мира и ненасилия, гражданства мира и осознания ценности культурного разнообразия и вклада культуры в устойчивое развитие. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=300"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.7.1" global mdxType="IndicatorRow">
          <p>{`Статус i) воспитания в духе всемирной гражданственности и ii) пропаганды устойчивого развития, включая гендерное равенство и права человека, на всех уровнях в a) национальной политике в сфере образования; b) учебных программах; c) программах подготовки учителей; и d) системе аттестации учащихся`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля школ, предоставляющих жизненно важные навыки по вопросам ВИЧ и сексуального образования`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.3" mdxType="IndicatorRow">
          <p>{`Степень использования намерений "зеленой" политики в учебных планах`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.4" mdxType="IndicatorRow">
          <p>{`Доля учащихся неполной средней школы, демонстрирующих достаточное понимание проблем, связанных с глобальной гражданственностью и устойчивым развитием`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.5" mdxType="IndicatorRow">
          <p>{`Доля учащихся неполной средней школы, демонстрирующих достаточный уровень знаний в науке об окружающей среде и науке о Земле`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-4a"
      }}>{`Задача 4.a`}</h2>
      <p>{`Создавать и совершенствовать учебные заведения, учитывающие интересы детей, особые нужды инвалидов и гендерные аспекты, и обеспечить безопасную, свободную от насилия и социальных барьеров и эффективную среду обучения для всех. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=308"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.a.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Доля школ, предлагающих основные услуги, в разбивке по типу уcлуг`}</p>
          <ThemeLabel theme="quality" id="4.a.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля учащихся, подвергающихся буллингу в течение последних 12 месяцев в а) начальной и б) неполной средней школе`}</p>
          <ThemeLabel theme="quality" id="4.a.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Количество нападений на учащихся, работников образования и учебные учреждения`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.a.4" mdxType="IndicatorRow">
          <p>{`Доля детей, посещающих школу, получающих школьное питание`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-4b"
      }}>{`Задача 4.b`}</h2>
      <p>{`К 2020 году значительно увеличить во всем мире количество стипендий, предоставляемых развивающимся странам, особенно наименее развитым странам, малым островным развивающимся государствам и африканским странам, для получения высшего образования, включая профессионально- техническое образование и обучение по вопросам информационно- коммуникационных технологий, технические, инженерные и научные программы, в развитых странах и других развивающихся странах. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=321"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.b.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Объем официальной помощи в целях развития, направляемой на выплату стипендий, в разбивке по отраслям и видам обучения`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "задача-4c"
      }}>{`Задача 4.c`}</h2>
      <p>{`К 2030 году значительно увеличить число квалифицированных учителей, в том числе посредством международного сотрудничества в подготовке учителей в развивающихся странах, особенно в наименее развитых странах и малых островных развивающихся государствах. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=331"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.c.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Доля учителей, обладающих минимальным уровнем организованной профессиональной подготовкой, в разбивке по уровням образования`}</p>
          <ThemeLabel theme="quality" id="4.c.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Соотношение учащийся/подготовленный учитель, в разбивке по уровням образования`}</p>
          <ThemeLabel theme="quality" id="4.c.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля учителей, обладающих квалификацией согласно национальным стандартам, в разбивке по уровням образования и типам образовательных учреждений`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Соотношение учащийся/квалифицированный учитель, в разбивке по уровням образования`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Средняя зарплата учителя по отношению к другим профессиям, требующим сопоставимого уровня квалификации`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Коэффициент текучести кадров среди учителей, в разбивке по уровням образования`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Доля учителей, прошедших курс профессиональной подготовки без отрыва от работы в течение последних 12 месяцев, в разбивке по типам подготовки`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "финансирование"
      }}>{`Финансирование`}</h2>
      <p><a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=340"
        }}>{`См. последнюю версию главы Доклада ВДМО`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="F.1" mdxType="IndicatorRow">
          <p>{`Государственные расходы на образование. % от ВВП`}</p>
          <ThemeLabel theme="finance" id="F.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="F.2" mdxType="IndicatorRow">
          <p>{`Расходы на образование в виде % от общей суммы государственных расходов`}</p>
          <ThemeLabel theme="finance" id="F.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;