
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Доступ";
export const lead = "Во всем мире 87% детей заканчивают начальную школу, 77% подростков заканчивают среднюю школу первой ступени и 58% подростков заканчивают среднюю школу второй ступени.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const PageLink = makeShortcode("PageLink");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Мир не выполнил своего обещания обеспечить к 2015 г. обучение всех детей в начальной школе. Новая цель в области образования – ЦУР 4 – предусматривает повышение уровня масштабности, призывая обеспечить к 2030 г. обучение всех молодых людей в средней школе. Для достижения этой цели все дети начального школьного возраста должны были начать обучение в школе в 2018 г., однако в действительности в странах с низким уровнем дохода, например, только 70% из них ходят в школу.`}</p>
    </blockquote>
    <p>{`Примерно каждый шестидесятый ребенок, большинство из которых проживают в странах с низким уровнем дохода, `}<strong parentName="p">{`никогда не пойдет в школу`}</strong>{`. Девочки по-прежнему чаще, чем мальчики, не ходят в школу, поскольку дискриминация в отношении них приводит к тому, что они должны заниматься уходом за детьми и выполнять другие обязанности по дому.`}</p>
    <h2 {...{
      "id": "посещение-дошкольных-учреждений"
    }}>{`Посещение дошкольных учреждений`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Посещение дошкольных учреждений`}</strong>{` является важнейшей отправной точкой на пути детей к получению образования. Однако в странах с низким уровнем дохода только 43% детей поступают в дошкольные учреждения за один год до достижения официального возраста поступления в начальную школу.`}</p>
    </IndicatorReference>
    <p>{`С 2000 г. в большинстве стран, в том числе во многих беднейших, наблюдается медленный, но устойчивый прогресс в области дошкольного образования, однако многие дети все еще остаются в стороне. Во всем мире 75% детей в настоящее время посещают дошкольные учреждения по сравнению с 65% в 2002 г.`}</p>
    <p>{`В некоторых странах наблюдается значительный рост. В Кыргызстане, например, уровень посещаемости увеличился с 42% в 2000 году до 84% в 2021 году. В Лаосской НДР за тот же период он увеличился с 10% до 71%.`}</p>
    <p>{`Как `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Гватемала`}</HighlightByIndex>{`, так и `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Нигер`}</HighlightByIndex>{`, рассмотренные ниже, являются примерами стран, в которых наблюдается неуклонный рост посещаемости дошкольных учреждений, хотя сегодня между ними существует огромный разрыв. Выберите свою страну, чтобы увидеть ее прогресс.`}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "число-детей-старше-нормального-школьного-возраста"
    }}>{`Число детей старше нормального школьного возраста`}</h2>
    <IndicatorReference id="4.1.5" mdxType="IndicatorReference">
      <p>{`Мы недостаточно привлекаем внимание к тому, что в ряде стран более четверти учащихся начальных школ поступают в школу `}<strong parentName="p">{`старше установленного возраста`}</strong>{`, что значит, что они значительно старше нормального для их класса школьного возраста. Это имеет серьезные последствия для их будущего, повышая вероятность второгодничества, неуспеха на экзаменах и в конечном счете отсева из школы.`}</p>
    </IndicatorReference>
    <p>{`На этой диаграмме показана `}<strong parentName="p">{`охваченность`}</strong>{` детей, подростков и молодежи `}<strong parentName="p">{`образованием`}</strong>{`: на ней указывается, в каком возрасте они начинают ходить в дошкольные учреждения, начальную школу, обучаются на низшей и высшей ступенях среднего образования, а также поступают в высшее учебное заведение. Различие в цвете указывает на то, сколько детей старше нормального для их класса школьного возраста. Серым цветом отмечена доля не посещающих школу детей и подростков в разбивке по возрасту.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="OVERAGE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="PAK" mdxType="ScrollySection">
B <Highlight mdxType="Highlight">Пакистане</Highlight>, большинство детей должны посещать школу в возрасте 10 лет, однако 21% все еще не посещают школу в этом возрасте. Многие дети слишком взрослые для своего класса. Например, есть 14-летние дети, которые все еще учатся в начальной школе.
      </ScrollySection>
      <ScrollySection id="LBR" mdxType="ScrollySection">
Уровень слишком взрослых детей еще выше в таких странах, как <Highlight mdxType="Highlight">Либерия</Highlight>, где 28% детей в возрасте 10 лет посещают дошкольные учреждения и 20% молодых людей в возрасте 18 лет все еще учатся в начальной школе.
      </ScrollySection>
      <ScrollySection id="HTI" mdxType="ScrollySection">
        <Highlight mdxType="Highlight">Гаити </Highlight> сталкивается с такой же проблемой
      </ScrollySection>
      <ScrollySection id="MWI" mdxType="ScrollySection">
равно как и <Highlight mdxType="Highlight">Малави</Highlight>.
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "дети-не-посещающие-школу"
    }}>{`Дети, не посещающие школу`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`В мире насчитывается 244 млн детей и молодых людей, `}<strong parentName="p">{`не посещающих школу`}</strong>{`. Новая модель , разработанная Статистическим институтом ЮНЕСКО и Группой по подготовке Всемирного доклада по мониторингу образования, дает основания предположить, что доля детей, не посещающих начальную школу, сокращается быстрее, чем считалось ране е, хотя в последние годы темпы снижения этого показателя замедлились. С 2014 года доля детей, не посещающих среднюю школу первой ступени, остается относительно стабильной.`}</p>
      <p>{`Главной причиной застоя являются конфликты. К сожалению, наличие конфликтов также означает, что доступность и качество данных ухудшаются, несмотря на то что некоторые страны продолжают предоставлять статистические оценки. Для надлежащей оценки недавнего влияния пандемии COVID-19 на уровень зачисления в школу также необходима более полная и последовательная информация за определенный период времени.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="OOSRATE" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="NIC,PAN" mdxType="ScrollySection">
Положение дел значительно разнится в <Highlight mdxType="Highlight">Никарагуа </Highlight> и <Highlight mdxType="Highlight">Панаме</Highlight>. В Никарагуа число не посещающих школу детей в начальной и средней школе резко сократилось, в то время как в Панаме оно увеличилось.
        </ScrollySection>
        <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
Темпы прогресса также крайне различаются в <Highlight mdxType="Highlight">Эритрее </Highlight> и <Highlight mdxType="Highlight">Эфиопии</Highlight>. Конфликт в Эритрее явно имел серьезные последствия для доступа к образованию, в то время как Эфиопия сделала образование приоритетом своего бюджета, что привело к быстрому сокращению числа не посещающих школу детей в течение короткого периода времени.
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Еще одной причиной отсутствия прогресса в сокращении числа не посещающих школу детей является рост численности населения.`}</p>
    <p>{`В странах Африки к югу от Сахары в период 2000-2022 гг. численность `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`населения начального школьного возраста`}</HighlightByIndex>{` практически удвоилась. В результате даже несмотря на то, что `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`доля детей, не посещающих школу`}</HighlightByIndex>{`, за этот период сократилась почти наполовину, `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`число детей, не посещающих школу`}</HighlightByIndex>{`, практически не изменилось.`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "завершение-образования"
    }}>{`Завершение образования`}</h2>
    <IndicatorReference id="4.1.2" mdxType="IndicatorReference">
      <p>{`Конечной целью ЦУР 4 является всеобщее `}<strong parentName="p">{`завершение`}</strong>{` второго этапа среднего образования, а не только охват образованием. И это несмотря на то, что во многих странах образование является бесплатным и обязательным только до первого этапа среднего образования.`}</p>
      <p>{`Мир не в состоянии обеспечить к установленному сроку даже всеобщее начальное образование, не говоря уже о полном среднем образовании.`}</p>
      <p>{`Этот показатель относится к детям, которые могут завершить обучение на всех уровнях образования на 3-5 лет позже официального возраста окончания школы, что можно определить как `}<Tooltip title="своевременное завершение " content="Children who complete education levels up to 3 or 5 years beyond the final grade" mdxType="Tooltip">{`своевременное завершение`}</Tooltip>{`. Но многие дети заканчивают школу даже позже, что можно определить как `}<Tooltip title="итоговое завершение" content=" Children who complete education levels up to 8 years beyond the final grade" mdxType="Tooltip">{`итоговое завершение`}</Tooltip>{`. `}</p>
      <p>{`Если посмотреть на диаграмму ниже, то в Кении, например, разрыв в уровне начального образования между `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`своевременным `}</HighlightByIndex>{` (74%) и `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`итоговым `}</HighlightByIndex>{` (93%) завершением составляет 20 процентов. Аналогичный разрыв наблюдается и в Мозамбике.`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "заключение"
    }}>{`Заключение`}</h2>
    <p>{`Возраст поступления детей в школу, возраст ее окончания и само завершение образования – все это ключевые политические вопросы.`}</p>
    <p>{`Следующие темы на этом сайте посвящены тому, насколько близко мы подходим к достижению `}<PageLink name="equity" mdxType="PageLink">{`равенства `}</PageLink>{` в образовании, что учащиеся `}<PageLink name="learning" mdxType="PageLink">{`изучают`}</PageLink>{`,насколько высоко `}<PageLink name="quality" mdxType="PageLink">{`качество `}</PageLink>{` образования и кто его `}<PageLink name="finance" mdxType="PageLink">{`финансирует`}</PageLink>{`.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
            }}>{`Образование за пределами шансов – Спасите детей`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.unicef.org/reports/a-world-ready-to-learn-2019"
            }}>{`Мир готов учиться: акцент на качественном образовании детей младшего возраста – ЮНИСЕФ`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
            }}>{`Объединение данных о не посещающих школу детях, завершении образования и обучении в целях получения более полного представления о достижении ЦУР 4`}</a></p>
        </li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;