import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "OOSRATE",
  title: /*i18n*/ {
    id: "fig.access.OOSRATE.title",
    defaults: "Out-of-school rate by level",
  },
  caption: /*i18n*/ {
    id: "fig.access.OOSRATE.caption",
    defaults: "The out-of-school rates have stagnated for many years",
  },
  source: /*i18n*/ {
    id: "fig.access.OOSRATE.source",
    defaults: "UIS database",
  },
};
