
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Learning";
export const lead = "Getting children into school is not enough. Without taking away from the many other worthwhile purposes that schools do and must pursue, students need to achieve read and carry out basic mathematics. These are the foundation for other learning, for individual empowerment and wellbeing, as well as for social, political and economic outcomes.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Getting children into school is not enough. Without taking away from the many other worthwhile purposes that schools do and must pursue, students need to be able to read and carry out basic mathematics. These are the foundation for other learning, for individual empowerment and wellbeing, as well as for social, political and economic outcomes.`}</p>
    </blockquote>
    <h2 {...{
      "id": "children-and-youth"
    }}>{`Children and youth`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Many children in some of the world’s poorest countries lack basic reading and mathematics skills.
Early grade reading assessments in northern states of Nigeria, such as Kaduna and Sokoto, and in provinces of the Democratic Republic of Congo, such as Equateur and Katanga, for instance, suggest that 80% of grade 3 students in primary school could not even read a `}<strong parentName="p">{`single word`}</strong>{` of connected text.`}</p>
      <Figure id="EGRA" mdxType="Figure" />
    </IndicatorReference>
    <p>{`This data does not cover the children who do not make it to school. Surveys that are carried out at people's homes can capture the skills of both children who attend school and those who do not.`}</p>
    <p>{`For instance, the below graphs look at different regions in India and Pakistan, based on citizen-led assessments.`}</p>
    <p>{`The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`red lines`}</HighlightByIndex>{` show the percentage of children `}<strong parentName="p">{`in school`}</strong>{` that have basic reading skills by grade, while the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`blue lines`}</HighlightByIndex>{` shows the percentage of all children who have basic reading skills, `}<strong parentName="p">{`whether in school or not by the age that corresponds to their grade`}</strong>{`.`}</p>
    <p>{`The gaps between the two lines are a reminder that in countries with high out-of-school rates, policy makers get only a partial picture if they only monitor learning of those in school. It is important to note that the monitored level of skill is at a very low level. Even in the best performing regions, at least some adolescents in secondary school cannot read a grade 2 text.`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`The UNESCO Institute for Statistics has `}<a parentName="p" {...{
          "href": "http://uis.unesco.org/sites/default/files/documents/fs46-more-than-half-children-not-learning-en-2017.pdf"
        }}>{`estimated`}</a>{` that 387 million or 56% of children of primary school age do not have basic reading skills. This was true for 81% of children in Central and Southern Asia and 87% of children in sub-Saharan Africa but only 7% of children in Europe and Northern America.`}</p>
      <p>{`In many countries, there is no guarantee that children will be able to read even if they have completed primary school. Globally, of those who completed primary education, one in two did not have basic reading skills. This rises to eight out of ten in Central and Southern Asia and in sub-Saharan Africa, as the graph below shows.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`The `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`grey sections`}</HighlightByIndex>{` show the percentage of children who complete primary school but do not reach a minimum level of proficiency in reading. The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`pink sections`}</HighlightByIndex>{` show those children who are dropping out of school before the end of primary, and the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`blue sections`}</HighlightByIndex>{` those studentswho complete primary and reach a minimum proficiency.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Looking at the country level, in Ghana, Niger and Nigeria, about 75% of young people who left school after 5 or 6 years were not literate. Some countries have much better rates, however. Almost all those who left school after grades 5 or 6 in Bolivia, Burundi and Rwanda were literate, for instance.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="LITBYYRS" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="NPL" mdxType="ScrollySection">
        <p>{`In `}<strong parentName="p">{`Nepal`}</strong>{`, for instance, fewer than one in three are literate `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`after 4 years of schooling`}</HighlightByIndex>{`, but this jumps to over nine out of ten for those who have `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`5-6 years of schooling`}</HighlightByIndex>{` – the equivalent of a primary education.`}</p>
      </ScrollySection>
      <ScrollySection id="MLI" mdxType="ScrollySection">
        <p>{`In `}<strong parentName="p">{`Mali`}</strong>{`, barely any children emerge `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`after 4 years of schooling`}</HighlightByIndex>{` able to read, but one-third develop basic literacy skills if they make it through the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`full course of primary education`}</HighlightByIndex>{`.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <p>{`In order to be able to compare learning across countries, assessments or tests need to be matched with international standards using one common definition. Such standards have recently been put in place for cross-national assessments where students from several countries take the same test. But fewer such mappings are available yet for national assessments.`}</p>
    <p>{`One assessment, the Progress in International Reading Literacy Study (PIRLS), takes place every five years and allows us to look at progress over time among grade 4 students. It shows where progress has been particularly slow. In the Islamic Republic of Iran, for example, the percentage of grade 4 students who met the low benchmark for reading increased from 56% in 2001 to only 65% in 2016, a growth of less than a percentage point per year.`}</p>
    <p>{`Some countries, such as Saudi Arabia, have even seen the share of students achieving minimum learning levels stagnate over time.`}</p>
    <p>{`In other countries, such as `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Morocco`}</HighlightByIndex>{`, rates increased five times faster between 2011 and 2016 than in the Islamic Republic of Iran, which could bring them within reach of the SDG 4 education target by 2030.`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1c" mdxType="IndicatorReference">
      <p>{`Contrary to most people's expectations, the gender learning gaps are not wide – spanning no more than eight percentage points for any country in primary education, for instance.`}</p>
      <p>{`But learning gaps between countries are huge. In sub-Saharan African countries, which all fall towards the lower end of reading skills in primary education, levels span from just 8% in Niger to 61% in Senegal.`}</p>
      <p>{`In mathematics in lower secondary education for Latin American countries, rates range from just 9% in the Dominican Republic to 48% in Uruguay (both results from PISA 2015).`}</p>
      <Figure id="ASSESS" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "adults"
    }}>{`Adults`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`Between 2000 and 2018, the percentage of adults who are literate increased from 81% to 86%, while the percentage of youth who are literate increased from 87% to 91%.`}</p>
      <p>{`Despite these improvements, illiteracy remains widespread in many countries, especially among women. In Latin America and the Caribbean, stalled progress at relatively high levels reflects pockets of deeply disadvantaged “hard to reach” groups. In Ecuador, for instance, the female adult literacy rate has not changed for the past 17 years, at 69%.`}</p>
      <p>{`In 37 countries in Sub-Saharan Africa, over half of women cannot read. Progress has either stalled at very low levels as in Chad and Burkina Faso, where less than 5% can read, or been slow as in Mozambique, which has improved from 5% to 15% over fifteen years.`}</p>
      <p>{`Globally, if more adults are literate today than they were, it is mostly because more adults went to school when they were young rather than because adults who did not go to school had a second chance to learn. To verify this claim, the below graph shows data that follows the literacy skills of a given cohort – or group – as it grows older.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LITCOHORT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In most countries, literacy among a particular `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`cohort of adults`}</HighlightByIndex>{` stagnated or even declined over time as skills were underutilized. This is because literate young women are growing up.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In `}<strong parentName="p">{`Malawi`}</strong>{`, the red line is flat, showing that the literacy rate of `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`women aged 20–34 years in 2000`}</HighlightByIndex>{` and `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`30–44 years in 2010`}</HighlightByIndex>{` did not change. And yet, because literate young women have grown up, the blue line shows the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`overall female adult literacy rate`}</HighlightByIndex>{` increased.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p><strong parentName="p">{`Nepal`}</strong>{` is an outlier. Not only did it post the most rapid improvement in the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`adult literacy of women aged 20-34`}</HighlightByIndex>{` among the countries analysed but is also the only country where a sustained improvement is confirmed at the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`cohort level`}</HighlightByIndex>{` over time.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`The literacy skills that these assessments are measuring are very basic. They show if people are able to read a simple sentence. Functional literacy, whereby people can read and interpret written instructions, formal letters, or contracts is a different matter.`}</p>
      <p>{`Few international surveys collect functional literacy data, much less on a regular basis.`}</p>
      <p>{`From the data we do have, we can see that more than 1 in 10 urban adults in Colombia and Viet Nam, 1 in 4 in Bolivia and 6 in 10 in Ghana are not functionally literate (i.e. they fall below Level 1 of proficiency) in functional literacy.`}</p>
      <p>{`In `}<strong parentName="p">{`Ghana`}</strong>{`, even among urban adults, only 39% have functional literacy skills.`}</p>
      <Figure id="LITPROF" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Although SDG 4 has shifted emphasis on monitoring learning, more than half of countries still do not report towards the learning outcome indicators on reading and mathematics. Even countries that want to start assessing and reporting on learning often face obstacles preventing them from adopting effective and cost efficient solutions. But the evidence we do have suggests that most students in the world’s poorest countries do not achieve minimum proficiency in the most basic skills.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/32553"
          }}>{`Ending learning poverty – what will it take? – World Bank`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000225660"
          }}>{`EFA GMR 2013/4: Teaching and learning: Achieving quality for all`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/20/the-learning-assessment-market-pointers-for-countries-part-2/"
          }}>{`The learning assessment market - pointers for countries`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;