import css from "@emotion/css";
import { ScaleLinear } from "d3-scale";
import React from "react";
import * as M from "../materials";

const styles = {
  annotationLine: css`
    fill: ${M.unescoDarkBlue};
    stroke: white;
    stroke-width: 1;
  `,
  annotationBand: css`
    background: black;
    opacity: 0.06;
  `,
  annotationBandLabel: css`
    ${M.fontAxisLabel};
    fill: ${M.lightText};
    paint-order: stroke;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  `,
  annotationText: css`
    ${M.fontChartLabel};
    font-weight: bold;
    fill: ${M.unescoDarkBlue};
    paint-order: stroke;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  `,
};

export type YAnnotation =
  | {
      type: "line";
      value: number;
      label?: string;
      x?: string;
      dy?: string;
    }
  | {
      type: "band";
      label: string;
      range: [number, number];
      dy?: string;
    };

interface YAnnotationsProps {
  width: number;
  yScale: ScaleLinear<number, number>;
  yFormat(value: number, isLast: boolean): string;
  annotations: Array<YAnnotation>;
}

function YAnnotations_({
  annotations,
  width,
  yScale,
  yFormat,
}: YAnnotationsProps) {
  return (
    <>
      {annotations.map((annotation, i) => {
        switch (annotation.type) {
          case "line": {
            return (
              <g
                key={`annotation-${i}`}
                transform={`translate(0,${yScale(annotation.value)})`}
              >
                <rect
                  x={0}
                  y={-1.5}
                  height={3}
                  width={width}
                  css={styles.annotationLine}
                />
                {annotation.label && (
                  <>
                    <text
                      x={0}
                      textAnchor="start"
                      dy={annotation.dy || "-0.4em"}
                      css={styles.annotationText}
                    >
                      {yFormat(annotation.value, false)}
                    </text>
                    <text
                      x={width}
                      textAnchor="end"
                      dy={annotation.dy || "-0.4em"}
                      css={styles.annotationText}
                    >
                      {annotation.label}
                    </text>
                  </>
                )}
              </g>
            );
          }
          case "band": {
            const [y1 = 0, y2 = 0] = annotation.range.map(yScale);
            return (
              <g key={`annotation-${i}`} transform={`translate(0,${y2})`}>
                <rect
                  x={0}
                  y={0}
                  height={y1 - y2}
                  width={width}
                  css={styles.annotationBand}
                />
                <text
                  x={0}
                  textAnchor="start"
                  dy={annotation.dy || "-0.4em"}
                  css={styles.annotationBandLabel}
                >
                  {annotation.label}
                </text>
              </g>
            );
          }
        }
      })}
    </>
  );
}

export const YAnnotations = React.memo(YAnnotations_);
