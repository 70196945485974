import css from "@emotion/css";

export const spacing = {
  base1: (value: number, unit = "px") => {
    return `${value}${unit}`;
  },
  base8: (value: number, unit = "px") => {
    return `${value * 8}${unit}`;
  },
  intersperseCSS,
};

/**
 * Vertical margin between items. Suppports LTR and gets around the Emotion & + & selector
 * https://github.com/emotion-js/emotion/issues/743
 * @param value spacing between items (base8)
 */
export function intersperseCSS(value: number) {
  return css`
    &:not(:nth-of-type(1))::before {
      content: "";
      display: inline-block;
      min-width: ${spacing.base8(value)};
      pointer-events: none;
      user-select: none;
      visibility: hidden;
      outline: none;
    }
  `;
}
