import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID_BASIC",
  title: /*i18n*/ {
    id: "fig.finance.aid.AID_BASIC.title",
    defaults: ``,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.AID_BASIC.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.AID_BASIC.source",
    defaults: ``,
  },
};
