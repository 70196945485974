import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID_FLOWS_SSA",
  title: /*i18n*/ {
    id: "fig.finance.AID_FLOWS_SSA.title",
    defaults: `Aid flows between major donors, recipients, and education sectors`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.AID_FLOWS_SSA.caption",
    defaults: `Donors vary a lot in how they direct their aid to different areas and regions.`,
  },
  source: /*i18n*/ {
    id: "fig.finance.AID_FLOWS_SSA.source",
    defaults: `GEM Report team analysis based on OECD CRS`,
  },
};
