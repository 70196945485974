import css from "@emotion/css";
import useLocale from "hooks/useLocale";
import React from "react";
import { mkStyles } from "../../hooks";
import { foldLocaleL, Locale } from "../../locales";
import * as M from "../../materials";

const TargetFourLogoImageSrc = foldLocaleL<string>({
  ar: () => require("./files/bg_sdg-wheel_ar.svg?raw"),
  de: () => require("./files/bg_sdg-wheel_de.svg?raw"),
  en: () => require("./files/bg_sdg-wheel_en.svg?raw"),
  es: () => require("./files/bg_sdg-wheel_es.svg?raw"),
  fr: () => require("./files/bg_sdg-wheel_fr.svg?raw"),
  ru: () => require("./files/bg_sdg-wheel_ru.svg?raw"),
  zh: () => require("./files/bg_sdg-wheel_cn.svg?raw"),
});

const useStyles = mkStyles(({ spacing: { base8 }, breakpoints }) => {
  return {
    root: css`
      grid-column: ${M.layoutAreas.full};

      & > img {
        margin: 0 auto;
        max-height: ${base8(25)};
      }

      @media ${breakpoints.down("l")} {
        & > img {
          max-height: ${base8(15)};
        }
      }
    `,
  };
});

interface TargetFourLogoProps {
  locale: Locale;
}

export function TargetFourLogoBase({ locale }: TargetFourLogoProps) {
  const styles = useStyles();
  return (
    <div css={styles.root}>
      <img alt="" src={TargetFourLogoImageSrc(locale)}></img>
    </div>
  );
}

export function TargetFourLogo(props: Omit<TargetFourLogoProps, "locale">) {
  const locale = useLocale();
  return <TargetFourLogoBase locale={locale} {...props} />;
}
