import { foldLocale, Locale } from "locales";
import dynamic from "next/dynamic";
import React from "react";
import { SearchComponent, SearchInput } from "./dynamicComponent";

interface SearchProps {
  locale: Locale;
}

export const Search = ({ locale }: SearchProps) => {
  const [query, setQuery] = React.useState("");

  const IDX = query ? localizedIndex(locale) : () => null;

  return (
    <div
      style={{ position: "relative" }}
      onBlur={(e) => {
        const currentTarget = e.currentTarget;

        setTimeout(() => {
          if (!currentTarget.contains(document.activeElement)) {
            setQuery("");
          }
        }, 200);
      }}
    >
      <SearchComponent>
        <SearchInput
          value={query}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
          }}
        />
      </SearchComponent>
      {query && <IDX query={query} />}
    </div>
  );
};

const Loading = () => {
  return <div style={{ position: "absolute" }}>...</div>;
};

const localizedIndex = foldLocale<React.ComponentType<{ query: string }>>({
  ar: dynamic(() => import("../../search/output/index.ar"), {
    ssr: false,
    loading: Loading,
  }),
  de: dynamic(() => import("../../search/output/index.de"), {
    ssr: false,
    loading: Loading,
  }),
  en: dynamic(() => import("../../search/output/index.en"), {
    ssr: false,
    loading: Loading,
  }),
  es: dynamic(() => import("../../search/output/index.es"), {
    ssr: false,
    loading: Loading,
  }),
  fr: dynamic(() => import("../../search/output/index.fr"), {
    ssr: false,
    loading: Loading,
  }),
  ru: dynamic(() => import("../../search/output/index.ru"), {
    ssr: false,
    loading: Loading,
  }),
  zh: dynamic(() => import("../../search/output/index.zh"), {
    ssr: false,
    loading: Loading,
  }),
});
