import React from "react";
import { css } from "@emotion/core";
import { Trans } from "@lingui/macro";

import { mkStyles, useQueryParamsWithDefault, useTheme } from "hooks";
import { lighten } from "lib/colorManipulator";
import { useI18n } from "locales";
import {
  findArticleAlias,
  findArticleThemeAlias,
  getArticleName,
  REnv,
  subthemesIndex,
} from "routes";
import * as M from "../../materials";
import { O, pipe } from "../../prelude";
import { PageLink } from "components/links";

const useStyles = mkStyles(({ activeTheme, spacing, client }) => ({
  root: css`
    border-top: 1px ${activeTheme.background.light} solid;
    margin-top: ${spacing.base8(6)} !important;
    text-align: center;
  `,
  title: css`
    ${M.fontHeading2};
    color: ${activeTheme.textColor};
    padding-top: ${spacing.base8(4)};
    padding-bottom: ${spacing.base8(3)};
    @media ${M.bpDown("m")} {
      padding-bottom: 0;
      ${M.fontHeading1};
    }
  `,
  linkCard: css`
    cursor: pointer;
    width: 80%;
    margin: auto;
    color: ${activeTheme.textColor};
    padding: ${spacing.base8(4)} ${spacing.base8(6)};
    border: ${M.grayscalePalette[3]} solid 1px;
    border-radius: ${spacing.base8(0.5)};
    direction: ${client.isRTL ? "rtl" : "ltr"};
    background-color: ${lighten(activeTheme.background.light, 0.7)};
    @media ${M.bpDown("m")} {
      padding: ${spacing.base8(1)} ${spacing.base8(1)}};
    }
    &:hover {
      background-color: ${lighten(activeTheme.background.light, 0.8)};
    }
  `,
  cardContent: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  illustration: css`
    width: ${spacing.base8(8)};
    margin-left: ${spacing.base8(2)};
    margin-right: ${spacing.base8(2)};
    transition: 0.4s transform;
    &:hover {
      transform: translateY(${spacing.base8(-1)});
    }
    @media ${M.bpDown("m")} {
      margin-left: ${client.isRTL ? spacing.base8(0.5) : 0};
      margin-right: ${client.isRTL ? 0 : spacing.base8(0.5)};
      padding: ${spacing.base8(2)} ${spacing.base8(2)};
    }
  `,
  cardText: css`
    margin-left: ${client.isRTL ? spacing.base8(2) : 0};
    margin-right: ${client.isRTL ? 0 : spacing.base8(2)};

    text-align: ${client.isRTL ? "right" : "left"};
  `,
}));

export const RelatedSubthemeArticle = () => {
  const { alias } = useQueryParamsWithDefault(REnv);
  const { palette } = useTheme();
  const styles = useStyles();
  const i18n = useI18n();

  const aliasRouteO = findArticleAlias(alias);
  return pipe(
    aliasRouteO,
    O.fold(
      () => null,
      (a) => {
        const articleTheme = findArticleThemeAlias(a);
        const subthemes = subthemesIndex[articleTheme];
        const currentIndex = subthemes.indexOf(a);
        const relatedSubthemeIndex =
          currentIndex === subthemes.length - 1 ? 0 : currentIndex + 1;
        const relatedArticle = subthemes[relatedSubthemeIndex];
        const styleTheme = palette.theme[relatedArticle];
        const imageUrl =
          styleTheme.kind === "article" ? styleTheme.illustration : null;

        return (
          <M.Grid layout={"home"} styles={styles.root}>
            <h2 css={styles.title}>
              <Trans>Related article</Trans>
            </h2>
            <PageLink name={relatedArticle} query={{}}>
              <div css={styles.linkCard}>
                <div css={styles.cardContent}>
                  {imageUrl && (
                    <img css={styles.illustration} src={imageUrl} alt="" />
                  )}

                  <div css={styles.cardText}>
                    <span
                      css={css`
                        ${M.fontBody2}
                      `}
                    >
                      <Trans>Sub-theme</Trans>
                    </span>
                    <h3
                      css={css`
                        ${M.fontBody1}
                      `}
                    >
                      {getArticleName(i18n, relatedArticle)}
                    </h3>
                  </div>
                </div>
              </div>
            </PageLink>
          </M.Grid>
        );
      }
    )
  );
};
