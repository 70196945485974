export const pick = <O extends object, K extends keyof O>(
  object: O,
  keys: Array<K>
): Pick<O, K> => {
  const result: $Unexpressable = {};
  keys.forEach((key) => {
    result[key] = object[key];
  });
  return result;
};

export const omit = <O extends object, K extends keyof O>(
  object: O,
  keys: Array<K>
): Omit<O, K> => {
  const result: $Unexpressable = {};
  (Object.keys(object) as Array<K>).forEach((key) => {
    if (!keys.includes(key)) {
      result[key] = object[key];
    }
  });
  return result;
};
