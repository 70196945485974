
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Información diversa";
export const lead = "Lead text";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">Información diversa</Title>
    <h2 {...{
      "id": "scope"
    }}>{`SCOPE`}</h2>
    <p>{`La herramienta del Progreso de la educación (SCOPE) reúne datos administrativos, encuestas de hogares, evaluaciones del aprendizaje y datos de la financiación de la educación de diversos productores de datos, en particular el Instituto de Estadística de la UNESCO, a fin de analizar los progresos realizados para lograr el ODS 4, el objetivo mundial relativo a la educación.`}</p>
    <p>{`Completa la `}<a parentName="p" {...{
        "href": "https://es.unesco.org/gem-report/allreports"
      }}>{`edición impresa`}</a>{` del Informe de Seguimiento de la Educación en el Mundo, permitiendo a los usuarios interactuar con los datos para comprender los logros y los desafíos de los países y las regiones en su empeño por alcanzar las metas. Puede compartirse y descargarse, y permite a sus usuarios crear imágenes y archivos de datos para profundizar sus conocimientos, que podrán imprimir o utilizar en línea o en exposiciones.`}</p>
    <h2 {...{
      "id": "informe-de-seguimiento-de-la-educación-en-el-mundo"
    }}>{`Informe de Seguimiento de la Educación en el Mundo`}</h2>
    <p>{`El Informe de Seguimiento de la Educación en el Mundo es una publicación anual independiente editorialmente, acogida y publicada por la UNESCO. De acuerdo con el `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656_spa"
      }}>{`Marco de Acción Educación 2030`}</a>{`, tiene el mandato de supervisar los progresos de la educación en pro de los ODS, y los de la aplicación de estrategias educativas nacionales e internacionales para ayudar a todos los asociados pertinentes a dar cuenta acerca de sus compromisos.`}</p>
    <LinkBlock title="Visite el sitio web" url="https://es.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "el-objetivo-de-desarrollo-sostenible-4"
    }}>{`El Objetivo de Desarrollo Sostenible 4`}</h2>
    <p>{`En septiembre de 2015, en la Cumbre de Desarrollo Sostenible de las Naciones Unidas celebrada en Nueva York, los Estados Miembros adoptaron oficialmente la `}<a parentName="p" {...{
        "href": "https://sustainabledevelopment.un.org/post2015/transformingourworld"
      }}>{`Agenda 2030 para el Desarrollo Sostenible`}</a>{`. La Agenda tiene 17 objetivos, uno de los cuales, el ODS 4, consiste en "garantizar una educación inclusiva y equitativa de calidad y promover oportunidades de aprendizaje permanente para todos". El ODS 4 tiene siete metas y tres medios de aplicación.`}</p>
    <LinkBlock title="Vea las metas y los marcos de seguimiento conexos" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="en" mdxType="FederalMinistryGermanyLogo" />
    <p>{`Agradecemos especialmente al Ministerio Federal de Educación e Investigación de Alemania su colaboración y su generosa contribución para elaborar el sitio web sobre el Progreso de la educación.`}</p>
    <Aside mdxType="Aside">
      <h2 {...{
        "id": "correo-electrónico-general"
      }}>{`Correo electrónico general`}</h2>
      <hr></hr>
      <p>{`Correo electrónico general`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`El Equipo`}</p>
      <p>{`Equipo del Informe GEM, UNESCO`}</p>
      <hr></hr>
      <p>{`Dirección`}</p>
      <p>{`Global Education Monitoring Report Team (GEM Report),
UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;