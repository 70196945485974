/**
 * Note: lot's of hard-coded things in this chart, because it's the only one.
 * Please adjust, if it turns out we need it more generically.
 */
import css from "@emotion/css";
import * as d3_scale from "d3-scale";
import { constant, Lazy } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { ChartTooltip, TooltipGridBodyWithLegend } from "../components/tooltip";
import { useContentRect, useTheme } from "../hooks";
import { insertionOrderSet } from "../lib";
import * as M from "../materials";
import { An, R } from "../prelude";

type Side = "left" | "right";

const groupLabelStyle = css`
  ${M.fontMeta};
  font-weight: bold;
  fill: ${M.lightText};
`;

const yearLabelStyle = css`
  ${M.fontMeta};
  fill: ${M.lightText};
`;

const axisLineStyle = css`
  stroke-width: 1;
  stroke: ${M.divider};
  shape-rendering: crispEdges;
`;

const tickLabel = css`
  ${M.fontAxisLabel};
  fill: ${M.lightText};
  text-anchor: middle;
`;

const tickLabelStart = css`
  text-anchor: start;
`;

export interface ParitycountProps<A> {
  data: An.NonEmptyArray<A>;
  width: number;
  getValue(x: A): number;
  getSide(x: A): Side;
  getColor(x: A): string;
  getYear(x: A): number;
  getGroup(x: A): string;
  formatGroup(x: string): string;
  formatValue(x: number): string;
}

export const Paritycount = React.memo(Paritycount_);
function Paritycount_<A>({
  data,
  width,
  getValue,
  getColor,
  getGroup,
  getYear,
  getSide,
  formatGroup,
  formatValue,
}: ParitycountProps<A>) {
  const { client } = useTheme();
  const margin = React.useMemo(
    () => ({
      top: 20,
      right: 0,
      bottom: client.screenMDown ? 24 : 38,
      left: client.screenSDown ? 30 : 100,
    }),
    [client.screenSDown, client.screenMDown]
  );
  const dimensions = React.useMemo(
    () => ({
      groupTitleHeight: client.screenSDown ? 16 : client.screenMDown ? 24 : 40,
      groupTitleDy: client.screenSDown ? 12 : client.screenMDown ? 20 : 28,
      groupYearDy: client.screenSDown ? 8 : client.screenMDown ? 10 : 12,
      dataRowHeight: client.screenSDown ? 12 : client.screenMDown ? 16 : 24,
      barHeight: client.screenSDown ? 8 : client.screenMDown ? 12 : 16,
      endPadding: client.screenSDown ? 4 : client.screenMDown ? 4 : 8,
    }),
    [client.screenSDown, client.screenMDown]
  );

  const groups = insertionOrderSet(data, getGroup);
  const years = insertionOrderSet(data, (x) => `${getYear(x)}`);
  const sides = insertionOrderSet(
    data,
    getSide
  ) as unknown as An.NonEmptyArray<Side>;

  const computedHeight = groups.length * groupHeight(dimensions, years.length);
  const outerHeight = computedHeight + margin.top + margin.bottom;
  const innerHeight = computedHeight;
  const outerWidth = width;
  const innerWidth = width - margin.left - margin.right;
  const center = innerWidth / 2;

  const groupedData = pipe(
    data,
    An.groupBy(getGroup),
    R.map((xs) =>
      pipe(
        An.groupBy((x: A) => `${getYear(x)}`)(xs),
        R.map((ys) => An.groupBy(getSide)(ys))
      )
    )
  );

  // I want to use a monoid, but brain is low on math fu ...
  const maxStackSize = groups.reduce((gMax, gKey) => {
    const group = groupedData[gKey];
    const ySum = years.reduce((yMax, yKey) => {
      const year = group[yKey];
      const sSum = sides.reduce((sMax, sKey) => {
        const side = year[sKey];
        const sum = stackSum(side, getValue);
        return sum > sMax ? sum : sMax;
      }, -Infinity);
      return sSum > yMax ? sSum : yMax;
    }, -Infinity);
    return ySum > gMax ? ySum : gMax;
  }, -Infinity);

  const extent = [0, maxStackSize];
  const xTicks = client.screenSDown ? [0, 0.25, 0.5] : [0, 0.2, 0.4, 0.6];

  const scaleGroup = d3_scale
    .scaleLinear()
    .domain([0, groups.length - 1])
    .range([
      0,
      innerHeight -
        groupHeight(dimensions, years.length) +
        dimensions.endPadding,
    ]);

  const scaleYear = d3_scale
    .scaleLinear()
    .domain([0, years.length - 1])
    .range([
      dimensions.groupTitleHeight,
      groupHeight(dimensions, years.length - 1),
    ]);

  const scaleXStart: Record<
    Side,
    d3_scale.ScaleLinear<number, number> | Lazy<number>
  > = {
    left: d3_scale.scaleLinear().domain(extent).range([center, 0]),
    right: constant(center),
  };

  const scaleWidth = d3_scale.scaleLinear().domain(extent).range([0, center]);

  const [colorScale, colorLegendValues] = useColorScale({ data, getColor });

  return (
    <div>
      <svg height={outerHeight} width={outerWidth}>
        <g key={"plot"} transform={`translate(${margin.left}, ${margin.top})`}>
          <text css={[tickLabel, tickLabelStart]} x={-margin.left} dy="-0.4em">
            Countries %
          </text>
          {xTicks.map((tick, i) => {
            return (["left", "right"] as Array<Side>).map((side) => {
              const scale = side === "left" ? scaleXStart.left : scaleWidth;
              const x =
                side === "left"
                  ? scale(tick)
                  : (scaleXStart.right(0) as $FixMe) + (scale(tick) || 0);
              return (
                i !== 0 && (
                  <React.Fragment key={`${side}-${tick}`}>
                    <text css={tickLabel} x={x} dy="-0.4em">
                      {formatValue(tick)}
                    </text>
                    <line
                      x1={x}
                      x2={x}
                      y1={0}
                      y2={innerHeight}
                      css={axisLineStyle}
                    />
                  </React.Fragment>
                )
              );
            });
          })}

          {groups.map((gKey, gIdx) => {
            const group = groupedData[gKey];
            return (
              <g key={gKey} transform={`translate(0, ${scaleGroup(gIdx)})`}>
                <text
                  css={groupLabelStyle}
                  x={-margin.left}
                  dy={dimensions.groupTitleDy}
                >
                  {formatGroup(gKey)}
                </text>
                {years.map((yKey, yIdx) => {
                  const row = group[yKey];
                  return (
                    <g
                      key={yKey}
                      transform={`translate(0, ${scaleYear(yIdx)})`}
                    >
                      <text
                        css={yearLabelStyle}
                        x={-margin.left}
                        dy={dimensions.groupYearDy}
                      >
                        {yKey}
                      </text>
                      {sides.map((sKey) => {
                        const side = row[sKey];
                        const scale = scaleXStart[sKey];
                        const total = stackSum(side, getValue);

                        const empty: {
                          x0: number;
                          elements: Array<React.ReactElement>;
                        } = {
                          x0: scale(total) as $FixMe,
                          elements: [],
                        };
                        return side.reduce((acc, x) => {
                          const barWidth = scaleWidth(getValue(x));
                          return {
                            x0: acc.x0 + (barWidth as $FixMe),
                            elements: acc.elements.concat(
                              <rect
                                key={`${sKey}-${getColor(x)}`}
                                data-side={sKey}
                                x={acc.x0}
                                height={dimensions.barHeight}
                                width={barWidth}
                                fill={colorScale(getColor(x))}
                              />
                            ),
                          };
                        }, empty).elements;
                      })}
                    </g>
                  );
                })}
              </g>
            );
          })}

          <React.Fragment>
            <text
              css={tickLabel}
              x={scaleXStart.right(0)}
              dy="-0.4em"
              fill={M.unescoDarkBlue}
            >
              0
            </text>
            <line
              x1={scaleXStart.right(0)}
              x2={scaleXStart.right(0)}
              y1={0}
              y2={innerHeight}
              css={[
                axisLineStyle,
                css`
                  stroke: ${M.unescoDarkBlue};
                  stroke-width: 2;
                `,
              ]}
            />
          </React.Fragment>

          {groups.map((gKey, gIdx) => {
            const group = groupedData[gKey];
            return (
              <g key={gKey} transform={`translate(0, ${scaleGroup(gIdx)})`}>
                {years.map((yKey, yIdx) => {
                  const row = group[yKey];
                  const first = row["left"][0];

                  // FIXME: Big hack ...
                  const parity_ = row.left[row.left.length - 1];
                  const parity = {
                    ...parity_,
                    value: getValue(parity_) * 2,
                  } as unknown as A;

                  const legendRows = row.left
                    .slice(0, row.left.length - 1)
                    .concat(parity)
                    .concat(row.right.slice(1))
                    .map((x) => [
                      colorScale(getColor(x)),
                      getColor(x),
                      formatValue(getValue(x)),
                    ]);

                  return (
                    <g
                      key={yKey}
                      transform={`translate(0, ${scaleYear(yIdx)})`}
                    >
                      <ChartTooltip
                        title={`${formatGroup(getGroup(first))} (${getYear(
                          first
                        )})`}
                        content={
                          <TooltipGridBodyWithLegend rows={legendRows} />
                        }
                        placement="top"
                        flipBehavior={["top"]}
                      >
                        <rect
                          x={0}
                          height={dimensions.barHeight}
                          width={innerWidth}
                          fill={"transparent"}
                        />
                      </ChartTooltip>
                    </g>
                  );
                })}
              </g>
            );
          })}
        </g>
      </svg>
      <ColorLegend maxWidth={innerWidth} values={colorLegendValues} />
    </div>
  );
}

export function ParitycountAuto<A>(props: Omit<ParitycountProps<A>, "width">) {
  const [ref, contentRect] = useContentRect();
  return (
    <div ref={ref} style={{ width: "100%" }}>
      {contentRect.width > 0 && (
        <Paritycount {...props} width={contentRect.width} />
      )}
    </div>
  );
}

// -----------------------------------------------------------------------------

function groupHeight(
  dimensions: { groupTitleHeight: number; dataRowHeight: number },
  rows: number
) {
  return dimensions.groupTitleHeight + rows * dimensions.dataRowHeight;
}

function stackSum<A>(xs: An.NonEmptyArray<A>, acc: (x: A) => number) {
  return xs.reduce((sum, x) => sum + acc(x), 0);
}

export function useColorScale<A>({
  ...p
}: Pick<ParitycountProps<A>, "data" | "getColor">) {
  const domainColor = React.useMemo(
    () => insertionOrderSet(p.data, p.getColor),
    [p.data, p.getColor]
  );

  const colorScale = React.useMemo(
    () =>
      d3_scale
        .scaleOrdinal(M.fromCount(M.colorRanges.divergingAlt, 7))
        .domain(domainColor),
    [domainColor]
  );

  const colorLegendValues = React.useMemo(() => {
    return domainColor.map((key) => ({
      color: colorScale(key),
      label: key,
    }));
  }, [colorScale, domainColor]);

  return [colorScale, colorLegendValues] as const;
}
