import { t } from "@lingui/macro";
import { css } from "@emotion/core";
import React from "react";
import ReactSelect, {
  ActionMeta,
  components,
  IndicatorProps,
  OptionTypeBase,
  StylesConfig,
} from "react-select";
import { FigureControlItem } from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";

interface MultiSelectProps {
  items: Array<FigureControlItem>;
  selectedItems: Array<string>;
  label: React.ReactNode;
  onChange: (
    x: Array<FigureControlItem> | null,
    actionMeta: ActionMeta<OptionTypeBase>
  ) => void;
  minItems?: number;
  maxItems?: number;
  title?: string;
  isInline?: boolean;
}

export const MultiSelect = ({
  items: itemsRaw,
  selectedItems: selectedItemsRaw,
  label,
  onChange,
  minItems = 1,
  maxItems = Infinity,
  isInline = true,
  title,
}: MultiSelectProps) => {
  const i18n = useI18n();
  const { client } = useTheme();

  const items = React.useMemo(() => {
    return itemsRaw.map((item) => ({ ...item, isFixed: false }));
  }, [itemsRaw]);

  const selectedItems = React.useMemo(() => {
    return selectedItemsRaw.map(
      (x) => items.find((y) => y.value === x) as unknown as typeof items[number]
    );
  }, [items, selectedItemsRaw]);

  // Using mutation internally, to work around overly aggressive ReactSelect caching
  for (const item of items) {
    item.isFixed =
      selectedItems.length <= minItems && selectedItemsRaw.includes(item.value);
  }

  return (
    <>
      {title && (
        <h4
          css={css`
            ${M.fontHeading4}
            margin-bottom: ${M.spacing.base8(0.5)};
          `}
        >
          {title}
        </h4>
      )}
      <ReactSelect
        styles={customStyles(isInline)}
        value={selectedItems}
        options={
          selectedItems.length >= maxItems
            ? maxItemsMessage(
                i18n._(t`Remove an item before adding another one`)
              )
            : items
        }
        onChange={React.useCallback(
          (value, actionMeta) => {
            switch (actionMeta.action) {
              case "remove-value":
              case "pop-value":
                if (actionMeta.removedValue?.isFixed) {
                  return;
                }
                break;
            }
            onChange(value, actionMeta);
          },
          [onChange]
        )}
        isMulti
        isRtl={client.isRTL}
        isClearable={false}
        placeholder={label}
        noOptionsMessage={() => i18n._(t`No matching items found`)}
        components={{ DropdownIndicator }}
        data-control-type="select"
      />
    </>
  );
};

// -----------------------------------------------------------------------------

const maxItemsMessage = (label: string) => [
  { label, value: "", isDisabled: true },
];

// -----------------------------------------------------------------------------

const customStyles = (
  isInline: boolean
): StylesConfig<FigureControlItem & { isFixed?: boolean }, true> => ({
  control: (provided, state) => {
    return {
      ...provided,
      width: "100%",
      background: M.whiteText,
      border: `1px solid ${M.grayscalePalette[4]}`,
      borderRadius: 4,
      minHeight: M.spacing.base8(4),
      minWidth: 160,
      justifyContent: "space-between",
      marginBottom: 8,
      // This is the valueContainer; we need to style it here to get access to
      // the "isFocused" property, which unfortunately is not passed down.
      // Only used for show/hide styles, other styles are in "valueContainer".
      "& > *": {
        maxHeight:
          state.isFocused || !isInline
            ? "auto"
            : 30 /* M.spacing.base8(4) - 2px border */,
        "& > *": {
          fontSize: state.isFocused ? "1em" : "0.8em",
          marginTop: state.isFocused || !isInline ? "" : "0!important",
          marginBottom: state.isFocused || !isInline ? "" : "0!important",
        },
      },
    };
  },
  input: (provided) => ({
    ...provided,
    ...M.fontTable[0],
    marginTop: 0,
    marginBottom: 0,
    color: M.blackText,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 2,
    paddingRight: 0,
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: M.spacing.base1(2),
    background: M.grayscalePalette[3],
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: M.unescoMarineBlue,
    padding: M.spacing.base1(2),
  }),
  multiValueRemove: (provided, state) => {
    return state.data.isFixed
      ? { ...provided, display: "none" }
      : {
          ...provided,
          color: M.unescoMarineBlue,
          "&:hover": {
            color: M.q03Palette[7],
            background: M.grayscalePalette[3],
            cursor: "pointer",
          },
        };
  },
  clearIndicator: (provided) => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
    color: M.grayscalePalette[5],
    "&:hover": {
      color: M.grayscalePalette[6],
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 12px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    ...M.fontTable[0],
    color: M.unescoMarineBlue,
  }),
  menu: (provided) => ({
    ...provided,
    background: M.grayscalePalette[2],
    marginTop: 2,
    boxShadow: "none",
  }),
  menuList: (provided) => ({
    ...provided,
    border: `1px solid ${M.grayscalePalette[4]}`,
    borderRadius: 4,
  }),
  option: (provided, state) => ({
    ...provided,
    ...M.fontTable[0],
    padding: "6px 8px",
    background: state.isFocused ? M.grayscalePalette[3] : M.grayscalePalette[2],
    color: state.isFocused ? M.unescoMarineBlue : M.blackText,
    margin: "0 4px",
    width: "calc(100% - 8px)",
    borderRadius: 3,
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    ...M.fontTable[0],
    color: M.lightText,
  }),
});

// -----------------------------------------------------------------------------

function DropdownIndicator(
  props: $PropsWithChildren<IndicatorProps<FigureControlItem, true>>
) {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="10px" height="6px" viewBox="0 0 10 6">
        <polygon points="0 0 5 6 10 0" fill={M.unescoMarineBlue} />
      </svg>
    </components.DropdownIndicator>
  );
}
