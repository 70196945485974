import { scaleOrdinal } from "d3-scale";
import React from "react";
import * as M from "../materials";
import { O, pipe } from "../prelude";
export function useOrderedColorScale(itemsO: O.Option<Array<string>>) {
  const items = pipe(
    itemsO,
    O.getOrElse<Array<string>>(() => [])
  );
  const colorScale = React.useMemo(
    () => scaleOrdinal(M.discretePalette).domain(items),
    [items]
  );
  const colorLegendValues = React.useMemo(() => {
    return colorScale.domain().map((label) => ({
      label,
      color: colorScale(label),
    }));
  }, [colorScale]);

  return { colorScale, colorLegendValues };
}
