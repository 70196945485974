import {
  failure,
  initial,
  isFailure,
  isInitial,
  pending,
  RemoteData,
  success,
} from "@devexperts/remote-data-ts";
import * as mitt from "mitt";
import * as React from "react";
import { Exception } from "../domain";
import { applyDecoder, FetchException } from "../lib";
import { E, io, pipe, Te } from "../prelude";
const emitter: mitt.Emitter = new (mitt.default as $FixMe)();

const cache = new Map<string, $Unexpressable>();
const updateCache = <A>(key: string, value: $Unexpressable) => {
  cache.set(key, value);
  emitter.emit("change");
  return value as A;
};

const fetchData = <A>(url: string) =>
  Te.tryCatch<FetchException, A>(
    () => fetch(url, { credentials: "same-origin" }).then((x) => x.json()),
    (reason) => new FetchException(String(reason))
  )();

export function useFetchRemote<I, O>(
  url: string,
  decoder: io.Decoder<I, O>,
  retry?: boolean
) {
  const [state, setState] = React.useState<RemoteData<Exception, O>>(
    cache.get(url) || updateCache(url, initial)
  );
  const syncState = React.useCallback(
    () => setState(cache.get(url)),
    [setState, url]
  );

  // Register to cache events and sync state, if necessary
  React.useEffect(() => {
    emitter.on("change", syncState);

    // If we have a stale cache, we missed an event
    if (state !== cache.get(url)) syncState();

    return () => emitter.off("change", syncState);
  }, [state, syncState, url]);

  // Fetch data, if necessary
  React.useEffect(() => {
    const freshState = cache.get(url);
    if (isInitial(freshState) || (retry && isFailure(freshState))) {
      updateCache(url, pending);
      fetchData<I>(url).then((res) => {
        pipe(
          res,
          E.chain<Exception, I, O>((data: I) => applyDecoder(decoder, data)),
          E.fold(
            (error) => {
              updateCache(url, failure(error));
            },
            (value) => {
              updateCache(url, success(value));
            }
          )
        );
      });
    }
  }, [decoder, retry, url]);

  return state;
}
