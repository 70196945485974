import css from "@emotion/css";
import Link from "next/link";
import React from "react";
import {
  mkStylesWithProps,
  useFocusVisible,
  useLocaleLink,
  usePageLink,
} from "../hooks";
import { Locale } from "../locales";
import { RAlias, RQuery } from "../routes";

type PageLinkChildFn = (
  x: ReturnType<typeof usePageLink>[1]
) => React.ReactNode;

export interface PageLinkParams<R extends RAlias> {
  children: PageLinkChildFn | React.ReactNode;
  name: R;
  query: RQuery<R>;
}

export const PageLink = <R extends RAlias>({
  name,
  query,
  children,
}: PageLinkParams<R>) => {
  const [linkProps, childProps] = usePageLink(name, query);
  return (
    <Link {...linkProps} passHref>
      {typeof children === "function" ? children(childProps) : children}
    </Link>
  );
};

export const PageLinkWithAnchor = <R extends RAlias>({
  name,
  query,
  anchor,
  children,
}: PageLinkParams<R> & { anchor: string }) => {
  const [linkProps, childProps] = usePageLink(name, query);
  return (
    <Link href={linkProps.href} as={`${linkProps.as}#${anchor}`} passHref>
      {typeof children === "function" ? children(childProps) : children}
    </Link>
  );
};

const linkStyles = mkStylesWithProps(
  ({ palette, spacing, breakpoints }, props: { isActive: boolean }) => ({
    root: css`
      color: ${palette.text.white};
      transition: 0.3s opacity;
      opacity: ${props.isActive ? 1 : 0.5};
      padding: 1em 0;
      @media ${breakpoints.up("l")} {
        ${spacing.intersperseCSS(2)};
        padding: 0;
      }
      &:hover {
        opacity: 1;
      }
    `,
    focus: css`
      opacity: 1;
      outline: 2px solid currentColor;
      outline-offset: 2px;
    `,
  })
);

export const LocaleLink = ({
  children,
  locale,
}: $PropsWithChildren<{ locale: Locale }>) => {
  const [linkProps, { isActive }] = useLocaleLink(locale);
  const styles = linkStyles({ isActive });
  const { focusVisible, ...handleFocus } = useFocusVisible();
  return (
    <a
      css={[styles.root, focusVisible && styles.focus]}
      href={linkProps.as}
      {...handleFocus}
    >
      {children}
    </a>
  );
};
