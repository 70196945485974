import React from "react";
import { FocusVisibleContext } from "../lib/focusVisibleProvider";

// A React hook based on: https://github.com/WICG/focus-visible

export function useFocusVisible() {
  const [isFocused, setIsFocused] = React.useState(false);
  const { hadKeyboardEvent, isInitialized } =
    React.useContext(FocusVisibleContext);

  const onFocus = React.useCallback(() => {
    if (!isFocused) setIsFocused(true);
  }, [isFocused]);

  const onBlur = React.useCallback(() => {
    if (isFocused) setIsFocused(false);
  }, [isFocused]);

  let focusVisible: boolean;
  if (isInitialized) {
    focusVisible = hadKeyboardEvent && isFocused;
  } else {
    // Fallback to focused when the `FocusVisibleManager` is not used.
    focusVisible = isFocused;
  }

  return React.useMemo(
    () => ({
      focusVisible,
      onFocus,
      onBlur,
    }),
    [focusVisible, onBlur, onFocus]
  );
}
