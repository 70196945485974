import css from "@emotion/css";
import { t, Trans } from "@lingui/macro";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { getLabel } from "components/article-switcher";
import { motion, Variants } from "framer-motion";
import { lighten } from "lib/colorManipulator";
import { useI18n } from "locales";
import React from "react";
import {
  findArticleAlias,
  findArticleThemeAlias,
  getRoute,
  RAlias,
  RAliasArticle,
  REnv,
  subthemesIndex,
} from "routes";
import useFitText from "use-fit-text";
import { foldChartType, useChartType } from "../../domain/hero-state";
import {
  mkStyles,
  useQueryParamsWithDefault,
  useTheme,
  useThemeNavigation,
} from "../../hooks";
import { ReactComponent as ArrowLeftIcon } from "../../icons/ic_arrow-left.svg";
import * as M from "../../materials";
import { O, pipe } from "../../prelude";
import { gridCenteredMaxWidth } from "../app-layout";
import { PageLink, PageLinkWithAnchor } from "../links";
import { HeroTooltip } from "./hero-tooltip";
import SubThemesNavigation from "./subthemes-nav";
import { TOCStatic } from "./TOC";
import { useRouter } from "next/router";
import { useSwipeable } from "react-swipeable";

export const Hero = ({
  children,
  title,
  caption,
  targetTitle,
  targetLabel,
  tooltipOverride,
}: $PropsWithChildren<{
  title: React.ReactNode;
  caption: React.ReactNode;
  targetTitle: string;
  targetLabel: string;
  tooltipOverride?: "finance";
}>) => {
  const [chartType] = useChartType();
  const styles = useStyles();
  const { activeTheme, client } = useTheme();
  const { alias, locale } = useQueryParamsWithDefault(REnv);
  const router = useRouter();

  const imageUrl =
    activeTheme.kind === "article" ? activeTheme.illustration : null;

  const tooltipPosition = foldChartType(chartType, {
    today: () => (tooltipOverride === "finance" ? "todayFinance" : "today"),
    timeseries: () =>
      tooltipOverride === "finance" ? "timeseriesFinance" : "timeseries",
  });

  const aliasArticle = findArticleAlias(alias);

  const [prevTheme, nextTheme] = useThemeNavigation(
    pipe(
      findArticleAlias(alias),
      O.getOrElseW(() => undefined)
    )
  );

  const swipeLink = (alias?: RAlias) => {
    if (alias) {
      const mkRoute = getRoute(alias);
      const route = mkRoute({ alias, locale });

      router.push(route.href, route.as);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      client.isRTL ? swipeLink(nextTheme) : swipeLink(prevTheme);
    },
    onSwipedLeft: () => {
      client.isRTL ? swipeLink(prevTheme) : swipeLink(nextTheme);
    },
  });

  return (
    <>
      <motion.section key={title?.toString() || "hero"} css={styles.root}>
        <div
          css={css`
            grid-area: main;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: ${M.spacing.base8(2)};
            `}
          >
            {client.screenLUp && prevTheme && (
              <NavButton direction="previous" page={prevTheme} />
            )}
            <motion.div
              {...swipeHandlers}
              css={[styles.card, styles.cardFallback]}
              variants={cardVariants}
              custom={client.screenSDown}
              transition={{ type: "spring", damping: 20, stiffness: 100 }}
            >
              <div
                css={[
                  styles.descriptions,
                  tooltipOverride === "finance" && styles.decriptionsFinance,
                ]}
              >
                {imageUrl && (
                  <img src={imageUrl} css={styles.illustration} alt="" />
                )}
                <h1 css={styles.title}>
                  <span>
                    <Trans>Theme</Trans>
                  </span>
                  <FitText>{title}</FitText>
                </h1>
                <p css={styles.leadText}>{caption}</p>
              </div>
              <div css={[styles.chart, styles.chartFallback]}>
                {children}
                <HeroTooltip title={targetTitle} position={tooltipPosition}>
                  {targetLabel}
                </HeroTooltip>
              </div>
            </motion.div>

            {client.screenLUp && nextTheme && (
              <NavButton direction="next" page={nextTheme} />
            )}
          </div>
          {client.screenLUp &&
            pipe(
              aliasArticle,
              O.fold(
                () => null,
                (a) =>
                  subthemesIndex[findArticleThemeAlias(a)].length > 1 && (
                    <SubThemesNavigation />
                  )
              )
            )}
        </div>
      </motion.section>
      {pipe(
        aliasArticle,
        O.fold(
          () => null,
          (a) =>
            subthemesIndex[findArticleThemeAlias(a)].length > 1 && (
              <SubthemeTitle article={a} />
            )
        )
      )}
      <div css={styles.toc}>
        <TOCStatic />
      </div>
    </>
  );
};

const NavButton = ({
  direction,
  page,
}: {
  direction: "previous" | "next";
  page: RAliasArticle;
}) => {
  const { palette } = useTheme();
  const i18n = useI18n();
  return (
    <PageLink name={page} query={{}}>
      <button
        aria-label={
          direction === "next"
            ? i18n._(t`Go to next theme`)
            : i18n._(t`Go to previous theme`)
        }
        css={css`
          background: none;
          border: none;
          height: fit-content;
          color: ${palette.action.main};
          cursor: pointer;
          &:hover {
            color: ${palette.action.dark};
          }
          svg {
            width: ${M.spacing.base8(3)};
            height: ${M.spacing.base8(3)};
          }
          ${direction === "next" ? "svg { transform: rotate(180deg); }" : ""}
        `}
      >
        <ArrowLeftIcon />
      </button>
    </PageLink>
  );
};

const SubthemeTitle = ({ article }: { article: RAliasArticle }) => {
  const styles = useStyles();
  const { client, palette } = useTheme();

  const subthemes = subthemesIndex[findArticleThemeAlias(article)];

  return (
    <div id="subtheme-title">
      {client.screenLUp ? (
        <h1 css={styles.titleSubtheme}>{getLabel(article)}</h1>
      ) : (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger css={styles.mobileSubthemeTrigger}>
            {getLabel(article)}
            <ArrowLeftIcon />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
              }}
              sideOffset={5}
              css={styles.mobileSubthemeContent}
            >
              {subthemes.map((subtheme) => (
                <PageLinkWithAnchor
                  anchor="subtheme-title"
                  key={subtheme}
                  name={subtheme}
                  query={{}}
                >
                  <DropdownMenu.Item
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.stopPropagation();
                    }}
                    asChild={true}
                    css={css`
                      color: ${subtheme === article
                        ? palette.action.dark
                        : "black"};

                      :hover {
                        background-color: ${palette.grayscale[3]};
                      }
                      padding: ${M.spacing.base8(1)} ${M.spacing.base8(2)};
                    `}
                  >
                    <a>{getLabel(subtheme)}</a>
                  </DropdownMenu.Item>
                </PageLinkWithAnchor>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )}
    </div>
  );
};

const FitText = ({ children }: $PropsWithChildren) => {
  const { fontSize, ref } = useFitText();
  return (
    <div
      ref={ref}
      style={{
        fontSize,
      }}
      css={css`
        height: 104px;

        @media ${M.bpDown("l")} {
          height: 88px;
        }

        @media ${M.bpDown("m")} {
          height: 56px;
        }
      `}
    >
      {children}
    </div>
  );
};

const useStyles = mkStyles(
  ({ typography, palette, activeTheme, spacing: { base8 } }) => {
    const { grid } = gridCenteredMaxWidth();
    return {
      root: css`
        grid-column: ${M.layoutAreas.full} !important;
        ${grid};
        background: ${activeTheme.background.light};
        color: ${activeTheme.textColor};
        transition: 2s background-color;
        padding-top: ${base8(18)};
        margin-bottom: ${base8(20)};

        @media ${M.bpDown("m")} {
          margin-bottom: ${base8(15)};
        }

        @media ${M.bpDown("s")} {
          margin-bottom: ${base8(10)};
        }
      `,
      card: css`
        padding: ${base8(1.5)} ${base8(4)} ${base8(1.5)} ${base8(1.5)};
        border-radius: ${base8(0.5)};
        box-shadow: 0 ${base8(5)} ${base8(10)} ${base8(-3)};
        background: ${activeTheme.background.light};
        background: white;
        display: flex;

        min-width: 90%;

        @media ${M.bpDown("l")} {
          flex-direction: column;
        }

        @media ${M.bpDown("s")} {
          padding: ${base8(1.5)} 0 ${base8(1.5)} 0;
        }
      `,
      cardFallback: M.onlyIE(css`
        display: block;
      `),
      descriptions: css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        max-width: 41.5%;
        margin: ${base8(1.5)};
        margin-bottom: ${base8(3)};

        @media ${M.bpDown("l")} {
          width: auto;
          max-width: 100%;
          margin-bottom: ${base8(13)};
        }

        @media ${M.bpDown("m")} {
          margin-bottom: ${base8(9)};
        }

        @media ${M.bpDown("s")} {
          margin-bottom: ${base8(7)};
        }
      `,
      decriptionsFinance: css`
        margin-bottom: ${base8(1.5)};
      `,
      titleSubtheme: css`
        ${M.fontTitle}
        color: ${activeTheme.textColor};
      `,
      mobileSubthemeTrigger: css`
        background-color: transparent;
        outline: none;
        border: none;
        display: flex;
        text-align: left;
        gap: ${M.spacing.base8(3)};
        color: ${activeTheme.textColor};
        ${M.fontHeading1Bold}
        align-items: center;
        padding: ${M.spacing.base8(1)};
        :hover {
          background-color: ${lighten(palette.brand.marine.light, 0.9)};
        }

        &[data-state="open"] {
          background-color: ${lighten(palette.brand.marine.light, 0.9)};
          svg {
            transform: rotate(90deg);
          }
        }

        svg {
          transform: rotate(270deg);
          color: ${palette.action.main};
        }
      `,
      mobileSubthemeContent: css`
        display: flex;
        flex-direction: column;
        background-color: ${palette.grayscale[2]};
        box-shadow: 0 7px 20px -8px rgba(0, 0, 0, 0.28);
        width: 100%;
        margin: 0 ${M.spacing.base8(1)};
      `,
      title: css`
        ${typography.preset.fontTera};
        margin: ${base8(-1)} 0 ${base8(3)} 0;
        color: ${activeTheme.textColor};

        @media ${M.bpDown("l")} {
          margin-right: 160px; /* Room for the icon */
          html[dir="rtl"] & {
            margin-right: unset;
            margin-left: 160px;
          }
        }

        @media ${M.bpDown("m")} {
          margin-right: 120px; /* Room for the icon */
          html[dir="rtl"] & {
            margin-right: unset;
            margin-left: 120px;
          }
        }

        @media ${M.bpDown("s")} {
          margin-bottom: ${base8(1)};
        }

        & > span {
          ${typography.preset.fontLead1};
          display: block;
        }
      `,
      leadText: css`
        ${M.fontLead1};
        margin-right: ${base8(3)};
        max-width: 40em;

        html[dir="rtl"] & {
          margin-right: 0;
          margin-left: ${base8(3)};
        }

        @media ${M.bpDown("l")} {
          margin-right: 30%;
          html[dir="rtl"] & {
            margin-right: 0;
            margin-left: 30%;
          }
        }

        @media ${M.bpDown("m")} {
          margin-right: ${base8(3)};
          html[dir="rtl"] & {
            margin-right: 0;
            margin-left: ${base8(3)};
          }
        }

        @media ${M.bpDown("s")} {
          margin-right: 0;
        }
      `,
      chart: css`
        position: relative;
        width: 100%;
        max-width: 58.5%;
        flex-shrink: 0;
        margin: ${base8(6)} 0 ${base8(1.5)} ${base8(1.5)};

        @media ${M.bpDown("l")} {
          width: auto;
          max-width: 100%;
        }
      `,
      chartFallback: M.onlyIE(css`
        width: 100%;
        margin-top: ${base8(
          16
        )}; /* To prevent the tooltip overflowing the text */
      `),
      illustration: css`
        width: 248px;
        align-self: center;
        position: relative;
        margin-top: -58px;
        margin-left: -15%;

        @media ${M.bpDown("l")} {
          position: absolute;
          right: ${base8(-7)};
          width: 30%;

          html[lang="ar"] & {
            right: unset;
            left: ${base8(7)};
          }
        }

        @media ${M.bpDown("m")} {
          width: 22%;
          min-width: 128px;
          right: ${base8(-3)};

          html[lang="ar"] & {
            right: unset;
            left: ${base8(3)};
          }
        }

        @media ${M.bpDown("s")} {
          position: absolute;
          width: 108px;

          html[lang="ar"] & {
            right: unset;
            left: ${base8(4)};
          }
        }
      `,
      toc: css`
        margin: ${base8(4)} 0;
      `,
    };
  }
);

const cardVariants: Variants = {
  show: (small: boolean) => ({
    opacity: 1,
    y: small ? 40 : 80,
  }),
  hidden: (small: boolean) => ({
    opacity: 0,
    y: small ? 250 : -150,
  }),
};
