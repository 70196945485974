
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Education spending should focus on equity";
export const lead = "SDG thematic indicator 4.5.3 aims to capture countries’ efforts to redistribute public resources to support disadvantaged groups.";
export const layout = "focus";
export const article = "equity";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Education spending should focus on equity" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=395" mdxType="FocusDetails">
      <h1 {...{
        "id": "education-spending-should-focus-on-equity"
      }}>{`Education spending should focus on equity`}</h1>
      <p>{`SDG thematic indicator 4.5.3 aims to capture countries’ efforts to redistribute public resources to support disadvantaged groups. While the indicator was initially conceived to measure formula-based funding mechanisms for disadvantaged groups, its definition has been expanded to encompass all funding mechanisms that redistribute education resources to disadvantaged groups. This requires monitoring of quantitative data (e.g. how many pupils receive how much) and qualitative judgements (e.g. what the mechanism is trying to achieve and how). The indicator recognizes that while inequality in education has been monitored closely over the past 15 years, relatively little attention has been paid to monitoring equity-oriented policies and programmes.`}</p>
      <p>{`Countries vary in their approaches to mitigating the impact on education of factors such as poverty, gender, ethnicity, disability and remoteness. Drawing on the PEER website, the GEM Report team collected information from 78 low- and middle-income countries covering all SDG regions except Europe and Northern America. The team identified four mechanisms to assess the equity focus of financing policies and programmes and provide a definition for indicator 4.5.3: overall education financing mechanisms, notably from central to local governments; allocations to schools, which take into account their characteristics; education resources to students, such as scholarships; and social policies and programmes targeting students and their families, such as social transfers. For each mechanism, three dimensions – comprehensiveness, coverage and volume  – helped characterize the country’s efforts to promote equity in education. The analysis concluded that 17 of the 78 countries, or only 1 in 5, maintained a strong equity focus through financing policies; they were mostly upper-middle-income and Latin American countries (UNESCO, 2021).`}</p>
      <p>{`More than half of low- and middle-income countries’ financing mechanisms do not consider sufficiently  that some local authorities are more disadvantaged  than others. A common mechanism to allocate funds  to districts or schools is through capitation grants,  i.e. tied to the number of students enrolled. In Myanmar, a formula has been used since 2009 to transfer resources to states and regions based on the number of students and teachers and the budget execution rate in the previous three years (UNICEF, 2018). The government developed and shared operational guidelines with local education officers and head teachers. Grants to schools ranged from US$400 to US$15,000 in 2017/18, depending on size. But the formula is not sensitive to the higher needs of remote schools, for instance, to cover high transport costs. Schools have had limited autonomy in use of the grant (World Bank, 2018).`}</p>
      <p>{`Tajikistan strengthened its education management information system and introduced a per capita financing system in 2010. Schools receive a grant consisting of a fixed component related to type (‘minimum standard’, which takes into account recurrent expenditure) and a variable component related to number of students, adjusted by type of school. The formula is further adjusted by location (accounting for the district budget) and available school facilities. The grant covers teacher salaries, maintenance and other operational costs. Although schools have autonomy in budget execution, no component promoting equity other than school location has been incorporated in the formula. Making funding proportional to need has resulted in more equitable allocation in terms of pupil/teacher ratios. But the mechanism has not fully addressed inequality related to districts’ inability to raise enough resources, even if they benefit from central government transfers (GPE, 2019; IsDB, 2019).`}</p>
      <p>{`While donors have introduced various types of school grants in low- and middle-income countries, focusing on school improvement or school-based management, such grants have not become part of the public budget and sustained after the programmes end. For example, donor-funded programmes in Cambodia supported additional grants in 2009–12 (Marshall and Bunly, 2017) and 2013–16 (UNESCO Bangkok, 2017) but were not sustained. Nor were supplementary school-based management grants in the Philippines, which were piloted in selected districts, taking school locations into account (Philippines Department of Education, 2015; World Bank, 2020).`}</p>
      <p>{`Scholarships to students tend to be awarded on the basis of academic performance, which usually exacerbates inequality. Some countries take socioeconomic status into account. In Nepal, where it is estimated that households contribute 49% of total education expenditure, equity is one of five foundations of the School Sector Development Programme. There are scholarship programmes for vulnerable groups, notably girls and Dalits. However, with the number of recipients in 2016 reaching 2.8 million, or 37% of total primary and secondary school enrolment, scholarship amounts are tiny, ranging from US$3.60 to US$4.90 per student per year (Nepal Department of Education, 2016; Vertex Consult, 2016).`}</p>
      <p>{`Other government expenditure besides the education budget can affect equity in education. Conditional and unconditional cash transfer programmes targeting the poor exist in much of the world, often inspired by the experience of Latin American countries. In Indonesia, Program Keluarga Harapan (Family Hope Programme) began providing quarterly cash transfers to very poor households in 2008. Initially equivalent to 15% to 20% of income, their real value fell to 7% within six years. Eligible households’ demographic characteristics include having children under age 15 or children aged 16 to 18 who have not completed nine years of education. Conditions for payment include an 85% school attendance rate. `}</p>
      <p>{`A six-year follow-up evaluation showed enrolment rates among 13- to 15-year-olds rose by up to nine percentage points, equivalent to halving the share of those out of school. Increases of between four and seven percentage points were observed in the secondary school completion rate among 18- to 21-year-olds, with the effect concentrated among young men (Cahyadi et al., 2018). The government scaled up the programme from 3.5 million to 10 million households between 2016 and 2018 (World Bank, 2017), reaching about 14% of the population.The module on social transfers added to the UNICEF Multiple Indicator Cluster Surveys (MICS) in 2017 enables analysis of education programmes’ coverage and their relative effectiveness in reaching disadvantaged households. Observations can be made about three groups of countries in terms of the equity focus of their education financing policies.`}</p>
      <p>{`First, there is a marked absence of policies and programmes in sub-Saharan African countries, including the Democratic Republic of the Congo and Zimbabwe, likely due to lack of resources. Second, some countries are quite effective in rolling out programmes that reach the most disadvantaged. In Algeria, a large-scale programme offers an annual education allowance, equivalent to US$23, to 3 million primary and secondary school students from families earning less than US$60 per month. The allowance is paid at the beginning of the school year to help with school supply purchases. The total outlay of US$68 million corresponds to 6% of public education expenditure (Algeria Ministry of National Education, 2015). While the programme has relatively low coverage, it is reasonably well targeted (38% of the poorest but 10% of the richest receive support). In Nepal, scholarships have been found to have a progressive impact, although the MICS evidence refers only to the incidence of support, not its volume.`}</p>
      <p>{`The third group comprises countries with programmes that are large but relatively untargeted. Bangladesh has administered a stipend to primary school students for 20 years, with some limited targeting to poorer subdistricts, and there is a blanket stipend to female secondary school students. In Thailand, a large part of support goes to richer households (59% of the poorest but 44% of the richest receive school tuition support). The same is true for Georgia, whose limited tuition support programme is regressive, favouring the richest.`}</p>
      <FocusImage size="medium" src={"13-spending_1.png"} alt="Countries’ efforts and success in supporting the education of the poorest households vary considerably" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=397" mdxType="FocusImage" />
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-equity"
      }}>{`Focuses published in equity`}</h4>
      <hr></hr>
      <FocusThemeList theme="equity" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=583"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;