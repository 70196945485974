
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "Español";
export const lead = "Lead text";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const TargetFourLogo = makeShortcode("TargetFourLogo");
const Title = makeShortcode("Title");
const IndicatorSection = makeShortcode("IndicatorSection");
const IndicatorTable = makeShortcode("IndicatorTable");
const IndicatorRow = makeShortcode("IndicatorRow");
const ThemeLabel = makeShortcode("ThemeLabel");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TargetFourLogo mdxType="TargetFourLogo" />
    <Title mdxType="Title">ODS 4 indicadores</Title>
    <p>{`Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos`}</p>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-41"
      }}>{`Meta 4.1`}</h2>
      <p>{`Para 2030, velar por que todas las niñas y todos los niños terminen los ciclos de la enseñanza primaria y secundaria, que ha de ser gratuita, equitativa y de calidad y producir resultados escolares pertinentes y eficaces. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=236"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.1.0" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de niños y adolescentes preparados para el futuro, desglosada por sexo`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de niños y adolescentes a) en grado 2 o 3, b) al final de la educación primaria y c) al final de la educación secundaria baja que han alcanzado niveles mínimos de competencia en i) lectura y ii) matemáticas, desglosada por sexo `}</p>
          <ThemeLabel theme="learning" id="4.1.1a" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
  
Tasa de finalización (educación primaria, educación secundaria baja, educación secundaria alta)
        </IndicatorRow>
        <IndicatorRow id="4.1.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa bruta de ingreso al último grado (educación primaria, educación secundaria baja)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa de niños fuera de la escuela (educación primaria, educación secundaria baja, educación secundaria alta)`}</p>
          <ThemeLabel theme="acceso" id="4.1.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de niños de edad superior a la correspondiente al grado que cursan (educación primaria, educación secundaria baja)`}</p>
          <ThemeLabel theme="access" id="4.1.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Administración de una evaluación representativa del aprendizaje a nivel nacional: a) en el grado 2 o 3; b) al final de la educación primaria; y c) al final de la educación secundaria baja`}</p>
          <ThemeLabel theme="access" id="4.1.6" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Número de años de educación primaria y secundaria (a) gratuita y (b) obligatoria garantizados en el marco legal`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-42"
      }}>{`Meta 4.2`}</h2>
      <p>{`Para 2030, velar por que todas las niñas y todos los niños tengan acceso a servicios de atención y desarrollo en la primera infancia y a una enseñanza preescolar de calidad, a fin de que estén preparados para la enseñanza primaria. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=253"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.2.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de niños de 24 a 59 meses cuyo desarrollo es adecuado en cuanto a la salud, el aprendizaje y el bienestar psicosocial, desglosada por sexo`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Tasa de participación en el aprendizaje organizado (un año antes de la edad oficial de ingreso en la educación primaria), desglosada por sexo`}</p>
          <ThemeLabel theme="access" id="4.2.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.2.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de niños menores de 5 años que experimentan entornos de aprendizaje positivos y estimulantes en el hogar`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa neta de matrícula en la educación de la primera infancia en a) la educación preprimaria y b) el desarrollo educativo de la primera infancia`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Número de años de educación preprimaria (a) gratuita y (b) obligatoria garantizados en el marco legal`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-43"
      }}>{`Meta 4.3`}</h2>
      <p>{`Para 2030, asegurar el acceso en condiciones de igualdad para todos los hombres y las mujeres a una formación técnica, profesional y superior de calidad, incluida la enseñanza universitaria. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=263"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.3.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Tasa de participación de jóvenes y adultos en la educación y formación académica y no académica en los últimos 12 meses, desglosada por sexo`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa bruta de matrícula en educación terciaria, desglosada por sexo`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa de participación en programas de educación técnica y profesional (de 15 a 24 años), desglosada por sexo`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-44"
      }}>{`Meta 4.4`}</h2>
      <p>{`Para 2030, aumentar sustancialmente el número de jóvenes y adultos que tienen las competencias necesarias, en particular técnicas y profesionales, para acceder al empleo, el trabajo decente y el emprendimiento. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=271"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.4.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de jóvenes y adultos con competencias en tecnología de la información y las comunicaciones (TIC), desglosada por tipo de competencia`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de jóvenes y adultos que han alcanzado al menos un nivel mínimo de competencia en alfabetización digital`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasas de logro educativo de jóvenes y adultos por grupo de edad y nivel de educación`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-45"
      }}>{`Meta 4.5`}</h2>
      <p>{`Para 2030, eliminar las disparidades de género en la educación y garantizar el acceso en condiciones de igualdad de las personas vulnerables, incluidas las personas con discapacidad, los pueblos indígenas y los niños en situaciones de vulnerabilidad, a todos los niveles de la enseñanza y la formación profesional. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=281"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.5.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Índices de paridad (entre mujeres y hombres, zonas rurales y urbanas, quintiles de riqueza superior e inferior y grupos como los discapacitados, los pueblos indígenas y los afectados por los conflictos, a medida que se disponga de datos) para todos los indicadores de educación de esta lista que puedan desglosarse`}</p>
          <ThemeLabel theme="equity" id="4.5.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.2" mdxType="IndicatorRow">
          <p>{` Porcentaje de alumnos a) los primeros grados, b) al final de la educación primaria y c) al final de la educación secundaria baja  cuya primera lengua o la que utilizan en el hogar corresponde a la lengua de instrucción`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.3" mdxType="IndicatorRow">
          <p>{`Medida en que unas políticas explícitas basadas en fórmulas reasignan los recursos de educación a los segmentos desfavorecidos de la población`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Gasto en educación por alumno por nivel de educación y fuente de financiación`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de ayuda total a la educación destinada a países menos desarrollados`}</p>
          <ThemeLabel theme="Finanzas" id="4.5.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Gasto en educación por fuente de financiamiento (público, privado, internacional) como porcentaje del PIB`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-46"
      }}>{`Meta 4.6`}</h2>
      <p>{`Para 2030, garantizar que todos los jóvenes y al menos una proporción sustancial de los adultos, tanto hombres como mujeres, tengan competencias de lectura, escritura y aritmética. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=292"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.6.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de la población en un grupo de edad determinado que ha alcanzado al menos un nivel fijo de competencia funcional en a) alfabetización y b) aritmética, desglosada por sexo`}</p>
          <ThemeLabel theme="learning" id="4.6.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.6.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa de alfabetización de jóvenes y adultos`}</p>
          <ThemeLabel theme="learning" id="4.6.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-47"
      }}>{`Meta 4.7`}</h2>
      <p>{`Para 2030, garantizar que todos los alumnos adquieran los conocimientos teóricos y prácticos necesarios para promover el desarrollo sostenible, entre otras cosas mediante la educación para el desarrollo sostenible y la adopción de estilos de vida sostenibles, los derechos humanos, la igualdad entre los géneros, la promoción de una cultura de paz y no violencia, la ciudadanía mundial y la valoración de la diversidad cultural y de la contribución de la cultura al desarrollo sostenible, entre otros medios. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=300"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.7.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Grado en que i) la educación para la ciudadanía mundial y ii) la educación para el desarrollo sostenible se incorporan en a) las políticas nacionales de educación, b) los planes de estudio, c) la formación de docentes y d) la evaluación de los estudiantes`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de escuelas en las que se imparte educación sobre el VIH y educación sexual basadas en competencias para la vida`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.3" mdxType="IndicatorRow">
          <p>{`Grado en que las intenciones de políticas verdes se incorporan en los documentos curriculares`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de alumnos por grupo de edad (o nivel de educación) que demuestran una comprensión adecuada de las cuestiones relacionadas con la ciudadanía mundial y la sostenibilidad`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de alumnos en el último grado de la educación secundaria baja que demuestran dominio del conocimiento de ciencias de la Tierra y ciencias ambientales`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-4a"
      }}>{`Meta 4.a`}</h2>
      <p>{`Construir y adecuar instalaciones escolares que respondan a las necesidades de los niños y las personas discapacitadas y tengan en cuenta las cuestiones de género, y que ofrezcan entornos de aprendizaje seguros, no violentos, inclusivos y eficaces para todos. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=308"
        }}>{`Ver el último capítulo del Informe GEM `}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.a.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de escuelas que ofrecen servicios básicos, desglosada por tipo de servicio`}</p>
          <ThemeLabel theme="quality" id="4.a.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de alumnos que han sido objeto de acoso en los últimos 12 meses a) al final de la educación primaria y b) al final de la educación secundaria baja`}</p>
          <ThemeLabel theme="quality" id="4.a.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Número de agresiones contra los alumnos, el personal docente y las instituciones`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.a.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Proporción de niños escolarizados que reciben comidas escolares`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-4b"
      }}>{`Meta 4.b`}</h2>
      <p>{`Para 2020, aumentar sustancialmente a nivel mundial el número de becas disponibles para los países en desarrollo, en particular los países menos adelantados, los pequeños Estados insulares en desarrollo y los países de África, para que sus estudiantes puedan matricularse en programas de estudios superiores, incluidos programas de formación profesional y programas técnicos, científicos, de ingeniería y de tecnología de la información y las comunicaciones, en países desarrollados y otros países en desarrollo. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=321"
        }}>{`Ver el último capítulo del Informe GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.b.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Volumen de la asistencia oficial para el desarrollo destinada a becas, desglosado por sector y tipo de estudio`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "meta-4c"
      }}>{`Meta 4.c`}</h2>
      <p>{`Para 2030, aumentar sustancialmente la oferta de maestros calificados, entre otras cosas mediante la cooperación internacional para la formación de docentes en los países en desarrollo, especialmente los países menos adelantados y los pequeños Estados insulares en desarrollo. `}<a parentName="p" {...{
          "href": "hhttps://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=331"
        }}>{`Ver el último capítulo del Informe GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.c.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proporción de docentes con las calificaciones mínimas requeridas, desglosada por nivel educativo`}</p>
          <ThemeLabel theme="quality" id="4.c.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de alumnos por docente capacitado, desglosado por nivel de educación`}</p>
          <ThemeLabel theme="quality" id="4.c.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de docentes calificados conforme a normas nacionales por nivel de educación y tipo de institución`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Proporción de alumnos por docente calificado, desglosada por nivel de educación`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Salario medio de los docentes en relación con otras profesiones que exigen un nivel de calificación comparable`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Tasa de abandono de docentes, desglosada por nivel de educación`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Porcentaje de docentes que recibieron formación en el empleo en los últimos 12 meses, desglosado por tipo de formación`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "finanzas"
      }}>{`Finanzas`}</h2>
      <p><a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=340"
        }}>{`Ver el último capítulo del Informe GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="F.1" mdxType="IndicatorRow">
          <p>{`Educación en la ODA total`}</p>
          <ThemeLabel theme="finanzas" id="F.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="F.2" mdxType="IndicatorRow">
          <p>{`Educación básica en la ayuda total a la educación`}</p>
          <ThemeLabel theme="finanzas" id="F.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;