
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "条款和条件";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title"> 条款和条件 </Title>
    <p>{`《全球教育监测报告》谨请将教育进展网站仅作参考之用。本网站所有内容均受版权保护。您（“用户”）访问本网站并使用其中的任何内容，即表明您承认已经阅读并理解这些使用条款的全部内容，并同意受其约束。`}</p>
    <p>{`本网站提供的信息旨在方便个人或公共机构非商业性使用，且除非另有说明，可以任何方式部分或全部转载，无需任何费用或许可。所有商业使用和翻译权申请均应提交至`}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a>{`。`}</p>
    <h3 {...{
      "id": "用户须"
    }}>{`用户须：`}</h3>
    <ul>
      <li parentName="ul">{`恪尽义务，确保转载材料的准确性；`}</li>
      <li parentName="ul">{`使用以下格式，在数据旁以醒目的方式注明来源：`}</li>
    </ul>
    <p>{`资料来源：（如适用，“改编自”）《全球教育监测报告》教育进展网站，`}<a parentName="p" {...{
        "href": "https://www.education-progress.org"
      }}>{`https://www.education-progress.org`}</a>{`，摘录日期。`}</p>
    <p>{`需要指出，数据库定期进行更新和修订，因此引用数据时须注明摘录日期。`}</p>
    <h3 {...{
      "id": "许可"
    }}>{`许可`}</h3>
    <p>{`您可以为自己和他人的利益，创造性地使用这些数据。您可以摘录、下载和复制数据库所包含的信息，并且可与第三方共享。`}</p>
    <h3 {...{
      "id": "无关联或认可"
    }}>{`无关联或认可`}</h3>
    <p>{`用户不得明示或暗示《全球教育监测报告》曾参与、批准、认可或以其他方式支持其使用或复制材料。用户不得声称与教育进展网站（或《全球教育监测报告》）有任何隶属关系。`}</p>
    <h3 {...{
      "id": "网站链接"
    }}>{`网站链接`}</h3>
    <p>{`外部网站无需许可即可添加跳转至教育进展网站任何页面的超链接。然而，不得侵犯《全球教育监测报告》的知识产权，特别是与名称、标识、版权或作者权利有关的知识产权。`}</p>
    <h3 {...{
      "id": "免责声明"
    }}>{`免责声明`}</h3>
    <p>{`《全球教育监测报告》负责维护本网站，以便于公众获取数据和相关信息。《报告》尽一切努力确保（但不保证）本网站上的信息准确完整。我们的目标是保持这些信息的及时性和准确性。如发现错误，我们会设法予以纠正。`}</p>
    <p>{`本网站所使用的名称及其材料编制方式并不代表教科文组织对任何国家、领土、城市或地区，或其当局的法律地位，或其边界或界线划分表示任何看法。`}</p>
    <p>{`本网站中资料的选择与编排以及表述的观点均由《全球教育监测报告》负责，并不一定反映教科文组织的观点，因此教科文组织不承担责任。`}</p>
    <p>{`我们尽一切努力减少因技术失误造成的服务中断。然而，网站上的某些数据或信息可能是以并非完全正确的文件或格式创建或组织的，我们无法保证我们的服务不因此类问题暂停或以其他方式受到影响。对由于使用教育进展网站或任何外部网站链接而产生的问题（性能故障、计算机病毒、通信线路故障、内容更改等），《全球教育监测报告》不承担任何责任。`}</p>
    <h3 {...{
      "id": "隐私"
    }}>{`隐私`}</h3>
    <p>{`访问教育进展网站时，诸如互联网协议（IP）地址、在网站的浏览行为、使用的软件和花费的时间等特定信息以及其他类似信息将存储在我们的服务器上。此信息不会明确识别您的身份，将用于网站流量分析。`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;