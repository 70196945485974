import { Data } from "figures/TRAJECTORIES_EXPLORER_SIMULATION";
import { Draft } from "immer";
import { createClient } from "lib/explorer-gql/generated";
import { createContext } from "react";
export type { CountryId } from "./country";
export type { RegionId } from "./region";

export interface ExplorerContext {
  status: "idle" | "fetching" | "data" | "error";
  errorMessage?: string;
  controls: {
    countries: Array<{ value: string; label: string }>;
  };
  data: {
    trajectories: Data;
  };
}
interface ContextState {
  immer: [
    ExplorerContext,
    (f: (draft: Draft<ExplorerContext>) => void | ExplorerContext) => void
  ];
}

export const explorerDefaultState: ExplorerContext = {
  status: "idle",
  controls: {
    countries: [],
  },
  data: { trajectories: [] },
};

export const ExplorerContext = createContext<ContextState>({
  immer: [explorerDefaultState, () => explorerDefaultState],
});

const VIEWS = ["countries", "disparities"] as const;
export type View = typeof VIEWS[number];

export const explorerAPI = createClient({
  url: "https://unesco-learning-trajectories-data.vercel.app/api/trajectories",
});

export type Series =
  | "SIMULATION_ACCESS"
  | "SIMULATION_LEARNING"
  | "CURRENT"
  | "COMPARISON";
