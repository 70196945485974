import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "LITPROF",
  title: /*i18n*/ {
    id: "fig.learning.LITPROF.title",
    defaults: `Adult education by literacy proficiency level`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.LITPROF.caption",
    defaults: `Many literates have low levels of proficiency.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.LITPROF.source",
    defaults: `PIAAC and STEP surveys`,
  },
};
