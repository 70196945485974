import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "BULLY_FIGHT",
  title: /*i18n*/ {
    id: "fig.quality.BULLY_FIGHT.title",
    defaults: `Percentage of students who experience bullying`,
  },
  caption: /*i18n*/ {
    id: "fig.quality.BULLY_FIGHT.caption",
    defaults: `Being bullied at school is a common experience in all countries.`,
  },
  source: /*i18n*/ {
    id: "fig.quality.BULLY_FIGHT.source",
    defaults: `UIS database`,
  },
};
