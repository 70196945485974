import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "OVERAGE",
  title: /*i18n*/ {
    id: "fig.access.OVERAGE.title",
    defaults: `Over-age attendance is an important challenge in many countries`,
  },
  caption: /*i18n*/ {
    id: "fig.access.OVERAGE.caption",
    defaults: `School participation by age and level`,
  },
  source: /*i18n*/ {
    id: "fig.access.OVERAGE.source",
    defaults: `GEM Report team analysis using DHS and MICS data`,
  },
};
