import React from "react";
import { DEFAULT_LOCALE } from "../locales";
import { E, io, pipe } from "../prelude";
import { DEFAULT_ROUTE, REnv } from "../routes";
import { useNextClientRouteParams } from "./useNextClientRouteParams";

export function useQueryParams<I, O extends REnv>(decoder: io.Decoder<I, O>) {
  const router = useNextClientRouteParams() || { query: null }; // "router" is undefined in SSR
  return React.useMemo(() => {
    const validation = decoder.decode(router.query as $FixMe);
    return pipe(
      validation,
      // FIXME: proper error handling,
      E.mapLeft((_) => {
        return "Could not parse query params";
      })
    );
  }, [decoder, router.query]);
}

const defaultParams: REnv = {
  alias: DEFAULT_ROUTE,
  locale: DEFAULT_LOCALE,
};

export function useQueryParamsWithDefault<I, O extends REnv>(
  decoder: io.Decoder<I, O>
) {
  return pipe(
    useQueryParams(decoder),
    E.getOrElse(() => defaultParams)
  );
}
