import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import React from "react";
import { mkStyles, useFocusVisible } from "../hooks";
import { visuallyHidden } from "../materials";

const useStyles = mkStyles((theme) => ({
  hidden: css`
    ${visuallyHidden};
  `,
  visible: css`
    padding: 1em;
    display: block;
    width: 100%;
    background: ${theme.palette.brand.blue.dark};
    color: white;
  `,
}));

export const SkipNavigation = ({ id }: { id: string }) => {
  const styles = useStyles();
  const { focusVisible, ...handleFocus } = useFocusVisible();

  return (
    <a
      href={`#${id}`}
      data-skip-search
      css={[focusVisible ? styles.visible : styles.hidden]}
      {...handleFocus}
    >
      <Trans>Skip to main content</Trans>
    </a>
  );
};
