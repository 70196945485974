
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "SDG 4 Indicators";
export const lead = "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const TargetFourLogo = makeShortcode("TargetFourLogo");
const Title = makeShortcode("Title");
const IndicatorSection = makeShortcode("IndicatorSection");
const IndicatorTable = makeShortcode("IndicatorTable");
const IndicatorRow = makeShortcode("IndicatorRow");
const ThemeLabel = makeShortcode("ThemeLabel");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TargetFourLogo mdxType="TargetFourLogo" />
    <Title mdxType="Title">SDG 4 Indicators</Title>
    <p>{`Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all`}</p>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-41"
      }}>{`Target 4.1`}</h2>
      <p>{`By 2030, ensure that all girls and boys complete free, equitable and quality primary and secondary education leading to relevant and effective learning outcomes. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=381"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.1.0" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Proportion of children/young people prepared for the future, by sex`}</p>
        </IndicatorRow>
  
        <IndicatorRow id="4.1.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion of children and young people (a) in Grade 2 or 3; (b) at the end of primary
education; and (c) at the end of lower secondary education achieving at least a minimum
proficiency level in (i) reading and (ii) mathematics, by sex`}</p>
          <ThemeLabel theme="learning" id="4.1.1a" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Completion rate (primary education, lower secondary education, upper secondary education)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Gross intake ratio to the last grade (primary education, lower secondary education)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Out-of-school rate (1 year before primary, primary education, lower secondary education, upper secondary education)`}</p>
          <ThemeLabel theme="access" id="4.1.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of children over-age for grade (primary education, lower secondary education)`}</p>
          <ThemeLabel theme="access" id="4.1.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Administration of a nationally-representative learning assessment (a) in Grade 2 or 3; (b) at the end of primary education; and (c) at the end of lower secondary education`}</p>
          <ThemeLabel theme="access" id="4.1.6" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Number of years of (a) free and (b) compulsory primary and secondary education guaranteed in legal frameworks`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-42"
      }}>{`Target 4.2`}</h2>
      <p>{`By 2030, ensure that all girls and boys have access to quality early childhood development, care and pre-primary education so that they are ready for primary education. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=391"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.2.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion of children aged 24-59 months who are developmentally on track in health, learning and psychosocial well-being, by sex`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Participation rate in organized learning (one year before the official primary entry age), by sex`}</p>
          <ThemeLabel theme="access" id="4.2.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.2.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of children under 5 years experiencing positive and stimulating home learning environments`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Net early childhood education enrolment ratio in (a) pre-primary education and (b) early childhood educational development`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Number of years of (a) free and (b) compulsory pre-primary education guaranteed in legal frameworks`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-43"
      }}>{`Target 4.3`}</h2>
      <p>{`By 2030, ensure equal access for all women and men to affordable and quality technical, vocational and tertiary education, including university. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=395"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.3.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Participation rate of youth and adults in formal and non-formal education and training in the previous 12 months, by sex`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Gross enrolment ratio for tertiary education by sex`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Participation rate in technical-vocational programmes (15- to 24-year-olds) by sex`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-44"
      }}>{`Target 4.4`}</h2>
      <p>{`By 2030, substantially increase the number of youth and adults who have relevant skills, including technical and vocational skills, for employment, decent jobs and entrepreneurship. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=395"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.4.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion of youth and adults with information and communications technology (ICT) skills, by type of skill`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of youth/adults who have achieved at least a minimum level of proficiency in digital literacy skills`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Youth/adult educational attainment rates by age group and level of education`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-45"
      }}>{`Target 4.5`}</h2>
      <p>{`By 2030, eliminate gender disparities in education and ensure equal access to all levels of education and vocational training for the vulnerable, including persons with disabilities, indigenous peoples and children in vulnerable situations. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=405"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.5.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Parity indices (female/male, rural/urban, bottom/top wealth quintile and others such as disability status, indigenous peoples and conflict-affected, as data become available) for all education indicators on this list that can be disaggregated`}</p>
          <ThemeLabel theme="equity" id="4.5.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of students in a) early grades, b) primary education, and c) at the end of lower secondary education who have their first or home language as the language of instruction`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Existence of funding mechanisms to reallocate education resources to disadvantaged populations`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Education expenditure per student by level of education and source of funding`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of total aid to education allocated to least developed countries`}</p>
          <ThemeLabel theme="finance" id="4.5.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of total aid to education allocated to least developed countries`}</p>
          <ThemeLabel theme="finance" id="4.5.6" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-46"
      }}>{`Target 4.6`}</h2>
      <p>{`By 2030, ensure that all youth and a substantial proportion of adults, both men and women, achieve literacy and numeracy. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=396"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.6.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion of population in a given age group achieving at least a fixed level of proficiency in functional (a) literacy and (b) numeracy skills, by sex`}</p>
          <ThemeLabel theme="learning" id="4.6.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.6.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Youth/adult literacy rate`}</p>
          <ThemeLabel theme="learning" id="4.6.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-47"
      }}>{`Target 4.7`}</h2>
      <p>{`By 2030, ensure that all learners acquire the knowledge and skills needed to promote sustainable development, including, among others, through education for sustainable development and sustainable lifestyles, human rights, gender equality, promotion of a culture of peace and nonviolence, global citizenship and appreciation of cultural diversity and of culture’s contribution to sustainable development. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=326"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.7.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Extent to which (i) global citizenship education and (ii) education for sustainable development are mainstreamed in (a) national education policies; (b) curricula; (c) teacher education and (d) student assessment`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of schools that provide life skills-based HIV and sexuality education`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.3" mdxType="IndicatorRow">
          <p>{`Extent to which green policy intentions are mainstreamed in curriculum documents`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of students in lower secondary showing adequate understanding of issues relating to global citizenship and sustainability`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of students in lower secondary showing proficiency in knowledge of environmental science and geoscience`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-4a"
      }}>{`Target 4.a`}</h2>
      <p>{`Build and upgrade education facilities that are child, disability and gender sensitive and provide safe, non-violent, inclusive and effective learning environments for all. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=422"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.a.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion of schools offering basic services, by type of service`}</p>
          <ThemeLabel theme="quality" id="4.a.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of students experiencing bullying in the last 12 months in a) primary, and b) lower secondary education`}</p>
          <ThemeLabel theme="quality" id="4.a.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Number of attacks on students, personnel and institutions`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.a.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Proportion of school attending children receiving school meals`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-4b"
      }}>{`Target 4.b`}</h2>
      <p>{`By 2020, substantially expand globally the number of scholarships available to developing countries, in particular least developed countries, small island developing States and African countries, for enrolment in higher education, including vocational training and information and communications technology, technical, engineering and scientific programmes, in developed countries and other developing countries. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=422"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.b.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Volume of official development assistance flows for scholarships by sector and type of study`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-4c"
      }}>{`Target 4.c`}</h2>
      <p>{`By 2030, substantially increase the supply of qualified teachers, including through international cooperation for teacher training in developing countries, especially least developed countries and small island developing States. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=415"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.c.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion of teachers with the minimum required qualifications, by education level`}</p>
          <ThemeLabel theme="quality" id="4.c.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pupil-trained teacher ratio by education level`}</p>
          <ThemeLabel theme="quality" id="4.c.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of teachers qualified according to national standards, by education level and type of institution`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pupil-qualified teacher ratio by education level`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Average teacher salary relative to other professions requiring a comparable level of qualification`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Teacher attrition rate by education level`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Percentage of teachers who received in-service training in the last 12 months by type of training`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "finance"
      }}>{`Finance`}</h2>
      <p><a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=420"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="F.1" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Government expenditure on education as a percentage of GDP`}</p>
          <ThemeLabel theme="finance" id="F.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="F.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Government expenditure on education as a percentage of total government expenditure`}</p>
          <ThemeLabel theme="finance" id="F.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;