
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Finanzierung";
export const lead = "Ein Viertel der Länder erfüllt keinen der im Aktionsrahmen Bildung 2030 für Regierungen festgelegten Mindestwerte im Bereich Bildungsfinanzierung.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Die ehrgeizigen Bildungsziele werden ohne zusätzliche Ressourcen insbesondere in den Ländern, die am weitesten zurückliegen, nicht rechtzeitig erreicht.`}</p>
    </blockquote>
    <p>{`Um das Ziel des universellen Sekundarschulabschlusses bis 2030 zu erreichen, müssten die Länder mit niedrigem und niedrigem mittleren Einkommen den jährlich von ihnen für Bildung ausgegebenen Betrag von 149 Milliarden US-Dollar 2012 auf 340 Milliarden US-Dollar 2030 beziehungsweise auf 6,3% ihres Bruttoinlandsprodukts (BIP) erhöhen. Über mehr Ressourcen zu verfügen, ist jedoch nicht unbedingt ausreichend. Länder, die denselben Betrag in Bezug auf das Pro-Kopf-Einkommen ausgeben, können damit extrem unterschiedliche Bildungsergebnisse erreichen, je nachdem ob sie die Mittel chancengerecht und effizient einsetzen oder nicht.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`Jedes Jahr kommen nur 0,5% der weltweit für Bildung ausgegebenen 4,7 Billionen US-Dollar Ländern mit niedrigem Einkommen zugute, während 65% davon in Ländern mit hohem Einkommen ausgegeben werden, obwohl die Zahl der Kinder im Schulalter in beiden Gruppen annähernd gleich ist.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`Weltweit decken `}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`Privathaushalte`}</HighlightByPalette>{` ein Fünftel (20%) der Bildungskosten. Tatsächlich übernehmen Haushalte in Ländern mit niedrigem Einkommen einen größeren Anteil an den Bildungsausgaben (29%) als in Ländern mit hohem Einkommen (18%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
  
Obwohl <HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">Geber</HighlightByPalette> für 12% der Ausgaben in Ländern mit niedrigem Einkommen aufkommen …
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`… macht der Beitrag der `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`Geber`}</HighlightByPalette>{` gerade einmal 0,3% der Gesamtausgaben weltweit aus, was in absoluten Beträgen kaum ins Auge fällt.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "öffentliche-ausgaben"
    }}>{`Öffentliche Ausgaben`}</h2>
    <p>{`Der `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
      }}>{`Aktionsrahmen Bildung 2030`}</a>{` hat zwei wesentliche Mindestwerte für Regierungen festgelegt. Er fordert sie auf:`}</p>
    <p>{`• mindestens 4% bis 6% des BIP für Bildung aufzuwenden und/oder
• mindestens 15% bis 20% der öffentlichen Ausgaben für Bildung bereitzustellen.`}</p>
    <p>{`Weltweit sind die Regierungen im Durchschnitt nicht weit von diesen Vorgaben entfernt. Sie geben im Schnitt 4,4% des BIP aus, von 3,4% in Ost- und Südostasien bis zu 5,1% in Lateinamerika und der Karibik. Und sie wenden 14,1% der gesamten öffentlichen Ausgaben auf, wobei der Durchschnitt nach Regionen von 11,6% in Europa und Nordamerika bis 18% in Lateinamerika und der Karibik reicht.`}</p>
    <p>{`Dennoch erreichte insgesamt jedes vierte Land keinen der Mindestwerte. Schauen Sie sich diese Daten im Lauf der Zeit an, um einen Eindruck davon zu bekommen, wie die Ausgaben über die Jahre schwanken.`}</p>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`Zwei grundlegende Merkmale könnten bestimmen, wieviel Regierungen für Bildung aufwenden: `}<strong parentName="p">{`Steuereinnahmen`}</strong>{` und die `}<strong parentName="p">{`Bevölkerung im Schulalter`}</strong>{`. Selbst wenn alle Länder Bildung in ihrem Haushalt dieselbe Priorität verliehen, würden beispielsweise Länder, die höhere Steuern erheben und andere nationale Ressourcen erhöhen, mehr für Bildung ausgeben.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`Zudem würden, selbst wenn die Länder gleiche Steuern erhöben, diejenigen mit einem höheren Anteil an Kindern und Jugendlichen in ihrer Bevölkerung weniger Mittel pro Kind im schulpflichtigen Alter zur Verfügung haben.`}</p>
    </IndicatorReference>
    <p>{`Gleichwohl sind die großen Unterschiede des für Bildung ausgegebenen Betrags zwischen Ländern mit ansonsten ähnlichen Gegebenheiten ein Anzeichen dafür, dass in diesem Zusammenhang nichts vorgegeben ist. Einige Länder entscheiden, mehr Mittel für Bildung aufzuwenden, um ihre Ziele zu erreichen. Und wir sollten besonders jene hervorheben, die dies nicht tun.`}</p>
    <h2 {...{
      "id": "mittel-der-entwicklungszusammenarbeit-zur-bildungsfinanzierung"
    }}>{`Mittel der Entwicklungszusammenarbeit zur Bildungsfinanzierung`}</h2>
    <p>{`Wenngleich mehr Finanzmittel benötigt werden, können diese nicht allein von den Ländern aufgebracht werden, die vor den größten Herausforderungen stehen. Internationale Solidarität ist gefragt. Der Weltbildungsbericht Global Education Monitoring Report berechnete eine `}<a parentName="p" {...{
        "href": "https://en.unesco.org/gem-report/node/819"
      }}>{`Finanzierungslücke von mindestens 39 Milliarden US-Dollar pro Jahr`}</a>{` für den Zeitraum von 2015 bis 2030 in Ländern mit niedrigem und niedrigem mittleren Einkommen: dies würde einer Erhöhung der Hilfen, die diese Länder 2012 erhielten, um das Sechsfache bedeuten. Derlei Ausgaben sind nicht unrealistisch: sie könnten erreicht werden, wenn alle Geber des Entwicklungshilfeausschusses der Organisation für wirtschaftliche Zusammenarbeit und Entwicklung (OECD/DAC) sowie ausgewählte Geber, die nicht dem DAC angehören, 0,7% ihres Bruttonationaleinkommens (BNE) für Hilfen aufwendeten und 10% ihrer Hilfsportfolios der Grund- und Sekundarschulbildung zukommen ließen.`}</p>
    <p>{`Allerdings sind die Entwicklungsausgaben für Bildung – nach einer Verdopplung von 2002 bis 2009 – nicht mehr gestiegen.`}</p>
    <Figure id="AID" mdxType="Figure" />
    <p>{`Besagte Ausgaben stagnieren nicht nur, sie werden auch nicht gerecht verteilt. Für eine gerechte Verteilung müsste der Unterstützung von den ärmsten Ländern und von Grundbildung – von der vorschulischen Bildung bis zu unteren Sekundarstufe – Priorität verliehen werden. Dies ist allerdings nicht der Fall, wie folgende Abbildung verdeutlicht.`}</p>
    <IndicatorReference id="4.5.5" mdxType="IndicatorReference">
      <p>{`Der Anteil der Gesamtentwicklungsausgaben für Bildung, der Ländern mit niedrigem Einkommen zugutekommt, ist von 25% im Jahr 2002 auf 22% im Jahr 2016 gesunken.`}</p>
      <p>{`Im selben Zeitraum sank, obwohl 35% der Kinder im Grundschulalter in den ärmsten Ländern keine Schule besuchten, der Anteil der Gesamtentwicklungsausgaben für Grundbildung, der in Länder mit niedrigem Einkommen ging, von 37% auf 27%.`}</p>
      <Figure id="AID_LICS" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Geber wenden auch sehr unterschiedliche Beträge pro Kind in Ländern mit demselben Bildungsentwicklungsstand auf. Während beispielsweise sowohl in Liberia als auch in Mauretanien etwa die Hälfte der Kinder die Grundschule abschließen, erhält Liberia pro Kind im Schulalter das Zehnfache an Mitteln der Entwicklungszusammenarbeit für Grundbildung.`}</p>
    <p>{`Ein Blick auf bilaterale und multilaterale Geber im Zeitraum von 2002 bis 2017 in der Abbildung unten zeigt, welche ihre Ausgaben gerecht verteilen. Der Anteil der Gesamtentwicklungsausgaben für Bildung, den das `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{` Vereinigte Königreich `}</HighlightByIndex>{` für Grundbildung aufwendet, ist von einem hohen Wert von 81% im Jahr 2006 bis 2017 auf 55% gesunken. Im selben Zeitraum sank der Anteil an gesamten Entwicklungsausgaben für Bildung, den Schweden Ländern mit niedrigem Einkommen widmet, von 58% auf 39%.`}</p>
    <p>{`Die Geberländer von OECD/DAC haben zumindest als langfristiges Ziel `}<a parentName="p" {...{
        "href": "https://www.oecd.org/dac/stats/the07odagnitarget-ahistory.htm"
      }}>{`allgemein akzeptiert`}</a>{`, 0,7% ihres Bruttonationaleinkommens (BNE) für offizielle Entwicklungszusammenarbeit aufzuwenden –. mit bemerkenswerten Ausnahmen: Schweiz und USA. Ab 2017 haben nur sieben Geberländer – Dänemark, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{` Deutschland `}</HighlightByIndex>{`, Luxemburg, Norwegen, Türkei, Vereinigte Arabische Emirate und Vereinigtes Königreich – dieses Ziel erreicht.`}</p>
    <Figure id="AID_TARGETING" mdxType="Figure" />
    <p>{`Folgende Tabelle untersucht den Fluss der Entwicklungsausgaben zwischen den führenden 10 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Gebern`}</HighlightByIndex>{` und den 10 größten `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Empfängern`}</HighlightByIndex>{` weltweit nach Bildungsstufe.`}</p>
    <p>{`Deutschland zum Beispiel könnte als größter Einzelgeber für Bildung in diesen Ländern erscheinen. Dass jedoch der Großteil dieser Mittel in die postsekundäre Bildung fließt, deutet darauf hin, dass er in großem Umfang ausländischen Lernenden – vorranging aus China – zugutekommt, die in Deutschland lernen oder studieren, statt notwendigerweise mehr Chancengerechtigkeit und Qualität auf Ebene der Grundbildung zu bewirken.`}</p>
    <Figure id="AID_FLOWS" mdxType="Figure" />
    <p>{`Insgesamt kommt etwa ein Viertel (24%) der Entwicklungsausgaben für Grundbildung Subsahara-Afrika zugute, wo die Hälfte (54%) der Kinder der Welt leben, die keine Schule besuchen. Diese Abbildung zeigt, welchen Ländern und welchen Bildungsstufen die Hauptgeberländer Priorität verleihen.`}</p>
    <p>{`2017 stellte Frankreich zum Beispiel 130 Millionen US-Dollar für vier der zehn größten Empfängerländer in der Region bereit, doch der größte Teil davon floss in postsekundäre Bildung, vermutlich in Form von Stipendien für Studierende aus Afrika, die in Frankreich studieren.`}</p>
    <p>{`Im Bildungsbereich ist die enge Abstimmung zwischen den Gebern wichtig. Dennoch zeigt die Abbildung die große Zahl der Geber, die Hilfen einem Land zukommen lassen, trotz des zusätzlichen Drucks, der dadurch für eine Regierung entstehen kann, um mit diesen Beziehungen auf Landesebene umzugehen.`}</p>
    <Figure id="AID_FLOWS_SSA" mdxType="Figure" />
    <h2 {...{
      "id": "finanzierung-durch-privathaushalte"
    }}>{`Finanzierung durch Privathaushalte`}</h2>
    <p>{`Das nationale Statistikamt praktisch jedes Landes erfasst die Bildungsausgaben von privaten Haushalten in Erhebungen zu Einkommen und Verbrauch.`}</p>
    <p>{`Diese Ausgaben können in Form von Schulgeld und anderen Gebühren erfolgen, aber auch für Bildungsgüter und -leistungen aufgebracht werden, von denen einige gefordert sind (z. B. Schuluniformen, Lehrbücher) und andere nicht (z. B. Privatunterricht).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Wenn diese Angaben länderübergreifend standardisiert sind, belegen sie eindrücklich, welchen Anteil private Haushalte zu den Gesamtbildungsausgaben beitragen. Dies kann dazu führen, dass sich das Verständnis der Bildungsanstrengungen der Länder ändert.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,UGA,ARG,FRA" mdxType="ScrollySection">
          <p>{`Beispielsweise gehörten in `}<strong parentName="p">{`Uganda`}</strong>{` 2013-2015 die `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Regierungsausgaben`}</HighlightByIndex>{` in Prozent des BIP zu den niedrigsten überhaupt. Sobald jedoch die `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Beiträge aus Privathaushalten`}</HighlightByIndex>{` berücksichtigt werden, lagen die nationalen Gesamtausgaben von Uganda in Prozent des BIP auf demselben Niveau wie jene von `}<strong parentName="p">{`Argentinien`}</strong>{` und über denen von `}<strong parentName="p">{`Frankreich`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,SLV,IDN,FRA,NLD" mdxType="ScrollySection">
          <p>{`Die Regierungen von `}<strong parentName="p">{`El Salvador`}</strong>{` und `}<strong parentName="p">{`Indonesien`}</strong>{` gaben zwei `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Prozentpunkte des BIP`}</HighlightByIndex>{` weniger für Bildung aus als `}<strong parentName="p">{`Frankreich`}</strong>{` und die `}<strong parentName="p">{`Niederlande`}</strong>{` – der blaue Balken –, wendeten aber insgesamt mehr auf, da `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Haushalte`}</HighlightByIndex>{` über drei Prozentpunkte des BIP für Bildung ausgaben – der rote Balken.`}</p>
        </ScrollySection>
        <ScrollySection id="relative,KHM,TJK,UGA" mdxType="ScrollySection">
          <p>{`Der `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Beitrag von Privathaushalten`}</HighlightByIndex>{` zu den Gesamtbildungsausgaben belief sich in einem Drittel der Länder mit niedrigem und mittlerem Einkommen auf mindestens ein Drittel und erreichte in `}<strong parentName="p">{`Kambodscha`}</strong>{`, `}<strong parentName="p">{`Tadschikistan`}</strong>{` und `}<strong parentName="p">{`Uganda`}</strong>{` etwa 60% oder mehr.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "schlussbemerkung"
    }}>{`Schlussbemerkung`}</h2>
    <p>{`Sehr wenige Länder liefern zeitnahe und einheitliche Daten über einen langen Zeitraum dazu, wieviel für Bildung ausgegeben wird und aus welcher Quelle diese Mittel stammen.`}</p>
    <p>{`Nicht allein die für Bildung verfügbare Finanzierung muss verbessert werden, auch die diagnostischen Mittel zur Finanzierung für diesen Bereich bedürfen des Ausbaus. Die Erreichung unseres globalen Bildungsziels SDG 4 hängt davon ab, die Länder und die Geber hinsichtlich ihrer Verpflichtungen zur Verantwortung zu ziehen.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://report.educationcommission.org/report/"
          }}>{`The Learning Generation – Investing in education for a changing world`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/14/aid-to-education-falls-slightly-in-2017-shifts-away-from-primary-education/"
          }}>{`Aid to education falls slightly in 2017 – GEM Report press release`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/819"
          }}>{`Pricing the right to education – GEM Report policy paper 2015`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;