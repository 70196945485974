import { flow } from "fp-ts/lib/function";
import { O } from "../prelude";
export function catOptions<A>(xs: Array<O.Option<A>>) {
  return xs.flatMap(
    flow(
      O.fold(
        () => [],
        (x) => [x]
      )
    )
  );
}
