import qs from "qs";

const FLAG_PREFIX = "flag__";
const isRunningInBrowser = () => typeof window !== "undefined";

export const flag = (name: string, value?: string | boolean) => {
  if (typeof localStorage === "undefined") {
    return;
  }
  const flagName = `${FLAG_PREFIX}${name}`;
  if (value !== undefined) {
    try {
      localStorage.setItem(flagName, JSON.stringify(value));
    } catch (e) {
      console.error(`Could not set flag: ${e instanceof Error ? e.message : e}`)
    }
  } else {
    const stored = localStorage.getItem(flagName);
    return stored ? JSON.parse(stored) : null;
  }
};

const initFromSearchParams = (locationSearch: string) => {
  locationSearch = locationSearch.startsWith("?")
    ? locationSearch.substr(1)
    : locationSearch;
  const params = qs.parse(locationSearch);
  for (const [param, value] of Object.entries(params)) {
    if (param.startsWith(FLAG_PREFIX) && typeof value === "string") {
      try {
        flag(param.substr(FLAG_PREFIX.length), JSON.parse(value));
      } catch (e) {
        console.error(e);
      }
    }
  }
};

if (isRunningInBrowser()) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.flag = flag;
  initFromSearchParams(window.location.search);
}

flag("trajectories-interpretation", true);
flag("improved-themes-drawer", true);
flag("focus-articles", true);
