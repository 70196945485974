import css from "@emotion/css";
import { t } from "@lingui/macro";
import useEmblaCarousel from "embla-carousel-react";
import { mkStyles, useQueryParamsWithDefault, useTheme } from "hooks";
import { useI18n } from "locales";
import { O, pipe } from "prelude";
import React, { useCallback, useContext, useEffect } from "react";
import {
  RAlias,
  REnv,
  findArticleAlias,
  findArticleThemeAlias,
  getFocusMetadata,
  subthemesIndex,
} from "routes";
import { focusArticles } from "../../focus";
import { ReactComponent as ArrowLeftIcon } from "../../icons/ic_arrow-left.svg";
import * as M from "../../materials";
import { PageLink } from "./links";
import { TOCContext } from "components/hero-vis/TOC";

const useStyles = mkStyles(({ palette }) => {
  return {
    root: css`
      align-items: center;
      display: flex;
      row-gap: ${M.spacing.base8(2)};
      padding: ${M.spacing.base8(3)} 0;
    `,
    card: css`
      border-radius: ${M.spacing.base8(1)};
      box-shadow: 0 2px 8p rgba(0, 0, 0, 0.2);
      margin: ${M.spacing.base8(1)};
      padding: ${M.spacing.base8(3)};
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
          0 4px 10px -1px rgba(0, 0, 0, 0.14);
        transition: box-shadow 0.3s ease-in-out;
      }
      max-width: 330px;
      min-width: 280px;
      width: 100%;

      h3 {
        ${M.fontHeading3};
      }

      hr {
        width: 100px;
        border: 0;
        height: 1px;
        margin: ${M.spacing.base8(2)} 0 ${M.spacing.base8(3)} 0;
        background-color: ${palette.grayscale[4]};
      }

      p {
        ${M.fontTable}
        width:100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      a {
        ${M.fontButton}
        color: ${palette.brand.marine.main};

        &:hover {
          color: ${palette.brand.marine.dark};
          text-decoration: underline;
        }
      }
    `,
  };
});

export function RelatedFocus() {
  const { alias, locale } = useQueryParamsWithDefault(REnv);
  const { client } = useTheme();
  const styles = useStyles();
  const i18n = useI18n();
  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: "start",
    containScroll: "trimSnaps",
    direction: client.isRTL ? "rtl" : "ltr",
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const article = pipe(alias, findArticleAlias);
  const theme = pipe(
    article,
    O.map((article) => findArticleThemeAlias(article))
  );

  const posts = pipe(
    theme,
    O.map((themeAlias) => {
      const subthemes = subthemesIndex[themeAlias];
      return focusArticles.published
        .map((focus) => getFocusMetadata(focus as RAlias, locale))
        .filter((focus) => subthemes.includes(focus.article))
        .sort((a, b) => a.year - b.year);
    }),
    O.getOrElseW(() => [])
  );

  const slideCount = client.screenMDown
    ? Math.min(posts.length, 1)
    : client.screenLDown
    ? Math.min(posts.length, 2)
    : Math.min(posts.length, 3);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect);
    onSelect();
    return () => {
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi, onSelect]);

  const headerId = "related-focus-title";
  const { addId } = useContext(TOCContext);
  addId(headerId);

  if (posts.length === 0) return null;

  return (
    <>
      <h2
        id="headerId"
        css={css`
          ${M.fontHeading2}
          margin-top: 2em;
        `}
      >
        {i18n._(t`Related Focus Articles`)}
      </h2>
      <div
        css={css`
          ${styles.root}
          grid-column: ${client.screenLUp && slideCount < 3
            ? `${M.layoutAreas.main} `
            : `${M.layoutAreas.wide}`};
        `}
      >
        <Carousel>
          {posts.length > slideCount && (
            <CarouselButton
              onClick={scrollPrev}
              direction="previous"
              disabled={!canScrollPrev}
            />
          )}
          <CarouselViewport ref={emblaRef}>
            <CarouselContainer>
              {posts.map((article, i) => {
                return (
                  <div
                    key={i}
                    className="embla__slide"
                    css={[
                      styles.card,
                      css`
                        flex: 0 0 ${95 / slideCount}%;
                      `,
                    ]}
                  >
                    <div>
                      <h3>{article.title}</h3>
                      <hr />
                      <p>{article.lead}</p>
                    </div>

                    <div
                      css={css`
                        text-align: right;
                        margin-top: ${M.spacing.base8(3)};
                      `}
                    >
                      <PageLink name={article.alias}>
                        {i18n._(t`Read`)}
                      </PageLink>
                    </div>
                  </div>
                );
              })}
            </CarouselContainer>
          </CarouselViewport>
          {posts.length > slideCount && (
            <CarouselButton
              onClick={scrollNext}
              direction="next"
              disabled={!canScrollNext}
            />
          )}
        </Carousel>
      </div>
    </>
  );
}

const CarouselViewport = React.forwardRef(
  (
    { children }: $PropsWithChildren,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className="embla__viewport"
        ref={ref}
        css={css`
          overflow: hidden;
          order: 1;

          @media (min-width: ${M.bp.s}px) {
            order: 2;
          }
        `}
      >
        {children}
      </div>
    );
  }
);

const CarouselContainer = React.forwardRef(
  (
    { children }: $PropsWithChildren,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className="embla__container"
        ref={ref}
        css={css`
          display: flex;
        `}
      >
        {children}
      </div>
    );
  }
);

const Carousel = React.forwardRef(
  (
    { children }: $PropsWithChildren,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className="embla"
        css={css`
          display: flex;
          flex-wrap: wrap;

          justify-content: center;
          gap: ${M.spacing.base8(2)};
          width: 100%;

          @media (min-width: ${M.bp.s}px) {
            flex-wrap: nowrap;
            justify-content: flex-start;
          }
        `}
      >
        {children}
      </div>
    );
  }
);

const CarouselButton = ({
  direction,
  onClick,
  disabled = false,
}: {
  direction: "previous" | "next";
  onClick: () => void;
  disabled: boolean;
}) => {
  const i18n = useI18n();
  const { palette, client } = useTheme();

  return (
    <button
      onClick={onClick}
      aria-label={
        direction === "next"
          ? i18n._(t`Next related focus articles`)
          : i18n._(t`Previous related focus articles`)
      }
      css={css`
        background: none;
        border: none;
        color: ${palette.action.main};
        cursor: pointer;
        :hover {
          color: ${palette.action.dark};
        }
        svg {
          width: ${M.spacing.base8(3)};
          height: ${M.spacing.base8(3)};
        }
        transition: opacity 0.3s linear;
        ${(direction === "next" && !client.isRTL) ||
        (direction === "previous" && client.isRTL)
          ? "svg { transform: rotate(180deg); }"
          : ""}
        opacity: ${disabled ? 0.5 : 1};
        pointer-events: ${disabled ? "none" : "auto"};
        order: ${direction === "previous" ? 2 : 3};

        @media (min-width: ${M.bp.s}px) {
          order: ${direction === "previous" ? 1 : 3};
        }
      `}
    >
      <ArrowLeftIcon />
    </button>
  );
};
