
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Poor countries need to increase tax revenue to fund education";
export const lead = "SDG thematic indicator 4.5.3 aims to capture countries’ efforts to redistribute public resources to support disadvantaged groups.";
export const layout = "focus";
export const article = "finance";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Poor countries need to increase tax revenue to fund education" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=391" mdxType="FocusDetails">
      <h1 {...{
        "id": "poor-countries-need-to-increase-tax-revenue-to-fund-education"
      }}>{`Poor countries need to increase tax revenue to fund education`}</h1>
      <p>{`Even in low-income countries receiving a high share of income from grants (i.e. aid), the main source of government revenue is taxation. In a sample of 70 countries in 2018, the three main total government revenue sources were taxes (77%), non-tax revenue (19%) (which includes loans, royalties, fees and sales) and grants (4%). The share of aid can be large in poorer countries: for example, 19% in Rwanda, 25% in Haiti and 30% in Bhutan (UNU-WIDER, 2021).`}</p>
      <FocusImage size="medium" src={"15-taxrevenue_1.png"} alt="Taxes account for more than three quarters of government revenue" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=392" mdxType="FocusImage" />
      <p>{`Countries differ greatly in their amount and sources of tax revenue. In a sample of 100 countries, Chad, Congo, the Democratic Republic of the Congo and Nigeria raised less than 10% of GDP in taxes. Even if they were to spend 20% of the budget on education, it would be far too little to cover their education development needs (Archer, 2016; Lewin, 2020). At the opposite end, Cuba and some European countries, including Austria, Denmark and Italy, raised more than 40%, and France 46%, of GDP in tax. Overall, average tax revenue as a share of GDP was 14% in low-income, 18% in lower-middle-, 22% in upper-middle- and 33% in high-income countries.`}</p>
      <FocusImage size="large" src={"15-taxrevenue_2_1.png"} alt="Countries differ in both the size and the composition of their tax revenue" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=393" mdxType="FocusImage" />
In the same country sample, individual and corporate income taxes accounted for just one sixth of tax revenue in some countries, including Argentina, Brazil and Costa Rica, but more than 50% in others, including Australia, Lesotho, Namibia, New Zealand, Papua New Guinea and South Africa. Corporate income taxes alone provided about half of tax revenue in Malaysia and Nigeria, but no more than 5% in France, Italy and the United States. More than three quarters of tax revenue in the Lao People’s Democratic Republic, Samoa and Togo came from taxes on goods and services.
      <FocusImage size="large" src={"15-taxrevenue_2_2.png"} alt="Countries differ in both the size and the composition of their tax revenue" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=394" mdxType="FocusImage" />
      <p>{`Most large differences between individual countries disappear when comparing groups of countries. For instance, low-, middle- and high-income countries all raised one third of their tax revenue from individual and corporate income taxes, the only difference being that, within this category, the share from individual income taxes was 43% in low- and middle-income countries and 69% in high-income countries. Otherwise, the main difference in tax structures was that the richer the country, the higher the share of revenue from social security contributions and the lower the share from taxes on goods and services (e.g. consumption or trade taxes).`}</p>
      <FocusImage size="auto" src={"15-taxrevenue_3.png"} alt="Poor countries depend more than rich countries on taxes on goods and services" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=395" mdxType="FocusImage" />
      <p>{`The Addis Tax Initiative, a result of the third International Conference on Financing for Development in 2015, is the spearhead of implementation of the 2015 Addis Ababa Action Agenda on domestic resource mobilization (ATI, 2021; United Nations, 2015). With more than 60 countries participating, the initiative aims to strengthen tax systems by broadening the domestic tax base, improving domestic tax compliance and enhancing tax collection capacity through improved tools and procedures to stop cross-border tax evasion and domestic tax avoidance (ATI, 2015). The Addis Ababa conference also led to the Integrated National Financing Frameworks platform for sharing best practices to strengthen alignment of national development plans with financing needs (INFF, 2021).`}</p>
      <p>{`In addition to initiatives to strengthen domestic tax regimes, recent years have seen concerted efforts to improve international tax policy. As they try to generate revenue, low- and middle-income countries need to overcome structural obstacles. They need to formalize the economy to be able to collect income taxes more effectively and they need to build their institutions to introduce modern value-added tax systems. In the meantime, taxing corporate income is an area in which the Global South can collaborate with the Global North to close loopholes. Multinational companies use various strategies to move profits to lower-tax jurisdictions (Kleinbard, 2011). Developing countries lose much of their tax base through such practices as revenue shifting, debt shifting, transfer pricing and tax deferral. Collectively, these tax avoidance activities, while not illegal, violate the good taxation principle of fairness, as tax should be paid where income is generated and where local people’s skills are used.`}</p>
      <p>{`There are serious methodological and data challenges to investigating tax avoidance losses in low- and middle-income countries (Johannesen and Pirttilä, 2016; UNCTAD, 2015). Yet recent studies have expanded understanding of the issue. Research on German multinational firms’ found that internal debt shifting became more widespread the larger the gap between the host-country tax rate and the lowest tax rate the firm’s affiliates faced, but that the effects were small (Buettner and Wamser, 2013). A study of Danish multinationals found that transfer pricing reduced tax revenue by 3.2% (Cristea and Nguyen, 2016). `}</p>
      <p>{`Research by International Monetary Fund economists on transfers from high-tax to low-tax countries estimated they reduced total corporate income tax revenue by 2.6%, or 0.07% of global GDP. However, the report recognized that the effect might be more substantial in developing countries, where avoidance practices are so complex that available data cannot demonstrate the impact (Beer et al., 2019). A more recent study with data from 210,000 corporations confirmed that the propensity to report zero profit was correlated with incentives to shift profit to countries with lower tax rates; this would partly explain why many developing countries reduce their corporate income tax rates despite the urgent need to improve their tax base (Johannesen et al., 2020).`}</p>
      <p>{`A review of 79 countries using 2016 data found that tax revenue losses from profit shifting amounted to 0.17% of GDP in lower-middle-income countries, although effects of around 1% of GDP were found in countries including Honduras, India and Zambia – and as high as 3.5% of GDP in Mozambique. El Salvador and Nigeria may be losing around one quarter of their corporate income tax revenue – and the Bolivarian Republic of Venezuela as much as 100% (Janský and Palanský, 2019). The 2021 State of Tax Justice report estimates that US$483 billion is lost to cross-border corporate tax abuse by multinational enterprises and offshore tax evasion by wealthy individuals. Of that, US$40 billion is lost to poorer countries (Global Alliance for Tax Justice et al., 2021). For reference, governments in low- and lower-middle-income countries allocate US$250 billion to education and receive US$9 billion in aid. These studies confirm that unfair practices are the rule and that restructuring and improving the international tax system for better disclosure, information sharing and transparency are urgently needed.`}</p>
      <p>{`Prompted by research findings and civil society campaigns as well as the pressure of continuing transformation towards digital globalized economies, 130 countries signed a statement on a two-pillar solution for international tax reform in July 2021 (OECD, 2021b). The first pillar introduces new rights to tax multinational enterprises, whether or not they are physically present in countries, with 25% of profit over a certain margin, estimated at US$125 billion of profit, reallocated to the countries where the enterprises’ customers are located. The second pillar sets a minimum 15% tax on corporate profit to prevent harmful tax competition between countries; it is expected to generate around US$150 billion in new tax revenue globally (OECD, 2021d). It remains to be seen how these measures will benefit low- and middle-income countries. And education campaigners need to work with countries to ensure  that much of the new revenue is used on education.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=583"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;