import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "HERO_EQUITY",
  title: /*i18n*/ { id: "fig.equity.HERO.title", defaults: `Equity` },
  caption: /*i18n*/ {
    id: "fig.equity.HERO.caption",
    defaults: `For every 100 of the richest young people who complete upper secondary school, only 11 of the poorest do so in low-income countries.`,
  },
  source: /*i18n*/ { id: "fig.equity.HERO.source", defaults: `UIS database` },
};
