import css from "@emotion/css";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { MDXProvider } from "@mdx-js/react";
import { TOC } from "components/hero-vis/TOC";
import React from "react";
import { mkStyles, useQueryParamsWithDefault, useTheme } from "../../hooks";
import * as M from "../../materials";
import { O, pipe } from "../../prelude";
import {
  findArticleAlias,
  findArticleThemeAlias,
  RAliasArticle,
  REnv,
  subthemesIndex,
} from "../../routes";
import arrowUrl from "./files/link-external.svg?raw";
import { RelatedSubthemeArticle } from "./related-subtheme";
import { RelatedFocus } from "./related-focus";

const useStyles = mkStyles(
  ({ activeTheme, zIndex, spacing: { base8 }, breakpoints }) => ({
    root: css`
      background: ${activeTheme.background.light};
      color: black;
      margin-top: ${base8(6)} !important;
    `,
    contentBox: css`
      grid-column: ${M.layoutAreas.main};
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: ${base8(7)};

      @media ${breakpoints.down("m")} {
        margin: 0;
        grid-column: ${M.layoutAreas.main};
      }
    `,
    title: css`
      ${M.fontHeading2};
      color: ${activeTheme.textColor};
      padding-top: ${base8(3)};
      padding-bottom: ${base8(8)};
    `,
    illustration: css`
      height: ${base8(12)};
    `,
    links: css`
      width: 100%;
      padding-bottom: ${base8(7)};
    `,
    toc: css`
      position: sticky;
      bottom: ${base8(2)};
      grid-column: side-start / side-end;
      z-index: ${zIndex.overlapContext};
    `,
  })
);

const useComponents = mkStyles(({ palette, utils }) => {
  return {
    li: styled.li`
      width: 100%;
      display: grid;
      padding-left: ${M.spacing.base8(1)} !important;
      border-bottom: 1px solid ${utils.fade(palette.text.black, 0.1)};

      &:first-of-type {
        border-top: 1px solid ${utils.fade(palette.text.black, 0.1)};
      }

      &::before {
        display: none;
      }
    `,
    a: styled.a`
      ${M.fontBody2};
      display: block;
      color: ${palette.action.main};
      font-weight: 700;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      box-sizing: border-box;
      margin: 0;
      padding-right: ${M.spacing.base8(5)};

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 11px);
        right: 0;
        display: block;
        background: url(${arrowUrl});
        width: ${M.spacing.base8(3)};
        height: ${M.spacing.base8(3)};
        color: ${palette.action.main};
      }
    `,
  };
});

export interface SummaryProps {
  title?: React.ReactNode;
}

export function RelatedMaterial({
  children,
}: $PropsWithChildren<SummaryProps>) {
  const { alias } = useQueryParamsWithDefault(REnv);
  const aliasRouteO = findArticleAlias(alias);

  const styles = useStyles();
  const theme = useTheme();

  const isInSubtheme = pipe(
    aliasRouteO,
    O.fold(
      () => false,
      (a) => subthemesIndex[findArticleThemeAlias(a)].length > 1
    )
  );

  return (
    <>
      <div css={styles.toc}>
        <TOC />
      </div>
      <RelatedFocus />
      {isInSubtheme && <RelatedSubthemeArticle />}
      <MDXProvider components={useComponents()}>
        <M.Grid layout={"home"} styles={styles.root}>
          <div css={styles.contentBox}>
            {pipe(
              aliasRouteO,
              O.map((articleAlias: RAliasArticle) => (
                <img
                  key="a"
                  alt=""
                  css={styles.illustration}
                  src={
                    (theme.palette.theme[articleAlias] as M.DocumentArticle)
                      .illustration
                  }
                />
              )),
              O.toNullable
            )}
            <h2 css={styles.title}>
              <Trans>Related content</Trans>
            </h2>
            <div css={styles.links}>{children}</div>
          </div>
        </M.Grid>
      </MDXProvider>
    </>
  );
}
