
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Financiación";
export const lead = "Uno de cada cuatro países no cumple ninguno de los principales objetivos de referencia sobre financiación para los gobiernos esbozados en el Marco de Acción de Educación 2030.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Las ambiciosas metas que marcan los ODS en materia de educación no se alcanzarán a menos que se inviertan recursos adicionales, especialmente en los países más rezagados.`}</p>
    </blockquote>
    <p>{`El informe GEM calcula que, para conseguir de aquí a 2030 la meta de la educación básica universal (es decir, educación preescolar, primaria y secundaria) los países de ingreso bajo y mediano bajo deben invertir 504 mil millones de dólares estadounidenses o el 6,3% de su producto interior bruto (PIB) anual.`}</p>
    <p>{`Pero un incremento de los recursos no tiene por qué ser suficiente, pues países con niveles de gasto similares en términos de renta per cápita pueden obtener resultados educativos diametralmente opuestos dependiendo de la eficacia, la eficiencia y la equidad del gasto. Por ejemplo, de los 78 países de ingreso mediano y bajo sobre los que se dispone de información, solo 17 (es decir, 1 de cada 5) prestaron una atención significativa a la equidad en sus políticas de financiación, en gran medida países de ingreso mediano alto y de América Latina.`}</p>
    <p>{`Las tres principales fuentes de gasto en educación son los gobiernos, los hogares y los donantes. Esta página se centrará en los dos primeros. Se dedica una página aparte a `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/es/articles/finance-aid"
      }}>{`la ayuda a la educación`}</a>{`.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`Cada año, de los 5 billones de dólares estadounidenses de gasto en educación en todo el mundo, solo el 0,5% pertenece al gasto de los países de ingreso bajo, mientras que el 66% pertenece al de los países de ingreso alto. Los dos grupos, sin embargo, presentan un número aproximadamente similar de niños en edad escolar.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`De media en todo el mundo, `}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`los hogares`}</HighlightByPalette>{` sufragan el 30% del costo de la educación. De hecho, la factura educativa que recae sobre los hogares es más elevada en los países de ingreso bajo (39%) que en los países de ingreso alto (15%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`Aunque `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`los donantes`}</HighlightByPalette>{` aportan el 13% del gasto en los países de ingreso bajo `}<a parentName="p" {...{
            "href": "https://www.education-progress.org/es/articles/finance-aid"
          }}>{`(ver subtema sobre Ayuda a la Educación)`}</a>{`...`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`...las contribuciones de `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`los donantes`}</HighlightByPalette>{` representan menos del 1% del gasto total en todo el mundo, lo que, en importes absolutos, apenas es perceptible.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "gasto-público"
    }}>{`Gasto público`}</h2>
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`El `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
        }}>{`Marco de Acción Educación 2030 `}</a>{` ha establecido dos objetivos de referencia financieros clave para los gobiernos, que les exhortan a:`}</p>
      <ul>
        <li parentName="ul">{`destinar al menos de un 4% a un 6% del PIB a la educación, y/o`}</li>
        <li parentName="ul">{`destinar al menos de un 15% a un 20% del gasto público a la educación.`}</li>
      </ul>
      <p>{`En términos generales, los gobiernos no están muy lejos de dichos objetivos. Los gobiernos de los países más pobres con poca capacidad para generar recursos internos pero con grandes cohortes de niños tienden a gastar menos en educación como porcentaje del PIB pero más como porcentaje del gasto público total.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`Dos características fundamentales pueden determinar cuánto gastan los gobiernos en educación: los `}<strong parentName="p">{`impuestos`}</strong>{` y la `}<strong parentName="p">{`población en edad escolar`}</strong>{`. Incluso si todos los países confirieran la misma prioridad a la educación en su presupuesto, los países que recaudan más impuestos y otros recursos internos gastarán más en educación, por ejemplo.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`En África Subsahariana, el gasto público en educación supone el 15,5% del gasto público total, pero solo el 4,3% del PIB. Por el contrario, en Europa y América del Norte se cumple el objetivo financiero de referencia relativo al porcentaje del PIB (5,0%), pero el porcentaje del gasto público destinado a educación es bajo (12,2%).`}</p>
      <p>{`En términos generales, uno de cada tres países no cumple ninguno de los dos objetivos de referencia. Inicie la animación de la evolución de los datos a lo largo de los años y vea cómo ha ido fluctuando el gasto.`}</p>
    </IndicatorReference>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <p>{`La principal fuente de ingresos de los gobiernos son los impuestos, también en los países de ingreso bajo que reciben un alto porcentaje de sus ingresos a través de subvenciones (es decir, ayuda). En general, la media de ingresos tributarios expresada como porcentaje del PIB es del 14% en los países de ingreso bajo, el 18% en los países de ingreso mediano bajo, el 22% en los países de ingreso mediano alto, y el 33% en los países de ingreso alto. Incluso si, por ejemplo, se diera la misma prioridad a la educación en los presupuestos de todos los países, los países con más impuestos y otros recursos nacionales gastarían más en educación.`}</p>
    <p>{`Ahora bien, la gran diferencia del gasto en educación entre países que, por lo demás, presentan características similares indica que no existe ninguna relación predeterminada al respecto. Algunos países deciden gastar más en educación para conseguir sus objetivos, y debe llamarse la atención sobre los que no lo hacen.`}</p>
    <h2 {...{
      "id": "gasto-de-los-hogares"
    }}>{`Gasto de los hogares`}</h2>
    <p>{`Los hogares destinan una cuantía significativa a la educación de sus hijos; en los países más pobres dicha cuantía es mayor que en los países más ricos.`}</p>
    <p>{`El gasto puede adquirir la forma de tasas de matrícula o de otro tipo, pero también puede ser para bienes y servicios educativos, algunos de los cuales son obligatorios (como los libros de texto) y otros no (como las clases particulares).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Cuando se uniformizan dichos datos entre países, surgen pruebas inequívocas de la contribución de los hogares al gasto total en educación, lo que puede modificar la forma en la que se interpretan los esfuerzos de los países en materia educativa.`}</p>
      <p>{`De media, los hogares destinan a la educación el 1,9% del PIB y los gobiernos, el 4,5%, lo que quiere decir que los hogares aportan alrededor del 30% del gasto total en educación. El porcentaje medio oscila entre el 15% en los países de ingreso alto y el 39% en los países de ingreso bajo y mediano bajo. En Asia Central y Meridional alcanza el 38% y en África Subsahariana, el 37%, pero en Europa y América del Norte, sin embargo, se queda en el 12%.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,NGA,FRA" mdxType="ScrollySection">
          <p>{`Por ejemplo, en 2019 `}<strong parentName="p">{`Nigeria`}</strong>{` presentaba uno de los niveles más bajos de `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`gasto público`}</HighlightByIndex>{` en cuanto a porcentaje del PIB. Sin embargo, si se tenía en cuenta la `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`contribución de los hogares`}</HighlightByIndex>{`, el gasto nacional total de Nigeria en educación en cuanto a porcentaje del PIB era similar al de `}<strong parentName="p">{`Francia`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,PAK,DEU" mdxType="ScrollySection">
          <p>{`El Gobierno del `}<strong parentName="p">{`Pakistán`}</strong>{` destinó a la educación casi 2,5 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`puntos porcentuales del PIB`}</HighlightByIndex>{` menos que `}<strong parentName="p">{`Alemania`}</strong>{` (la barra azul), pero en términos generales el gasto del Pakistán fue superior al de Alemania porque sus `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`hogares`}</HighlightByIndex>{` destinaron más de 3 puntos porcentuales del PIB a la educación (la barra roja).`}</p>
        </ScrollySection>
        <ScrollySection id="relative,BGD,LBN,HTI" mdxType="ScrollySection">
          <p>{`El `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`porcentaje del gasto total en educación que aportan los hogares`}</HighlightByIndex>{` asciende a más del 70% en algunos países, incluidos `}<strong parentName="p">{`Bangladesh`}</strong>{`, `}<strong parentName="p">{`Haití`}</strong>{` y `}<strong parentName="p">{`Nigeria`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`El equipo del Informe de seguimiento de la educación en el mundo lleva tiempo defendiendo que, para comprender el gasto mundial en educación, se deben examinar conjuntamente las tres fuentes principales de financiación educativa: la financiación pública y privada nacional - discutidas en esta página - y donantes externos, discutidos en la página de `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/es/articles/finance-aid"
      }}>{`ayuda a la educación`}</a>{`. Por tanto, se necesitan datos oportunos y coherentes de los países de ingreso bajo y mediano sobre cuánto se está invirtiendo en educación y de qué fuentes procede dicha financiación.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/financing-for-equity"
          }}>{`Unlocking Financing for Equity in Education`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://education-profiles.org/"
          }}>{`Profiles Enhancing Education Reviews (PEER)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2022-efw"
          }}>{`Education Finance Watch 2022`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;