import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "SECTOR_ALLOCABLE",
  title: /*i18n*/ {
    id: "fig.finance.aid.SECTOR_ALLOCABLE.title",
    defaults: ``,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.SECTOR_ALLOCABLE.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.SECTOR_ALLOCABLE.source",
    defaults: ``,
  },
};
