import React from "react";
import { useContentRect } from "../hooks";
import { BarChart as FixedWidthBarChart, BarChartProps } from "./lib/Bars";

export function BarChart<A>(props: Omit<BarChartProps<A>, "width">) {
  const [ref, contentRect] = useContentRect();

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {contentRect.width > 0 && (
        <FixedWidthBarChart {...props} width={contentRect.width} />
      )}
    </div>
  );
}
