import css from "@emotion/css";
import styled from "@emotion/styled";
import { MDXProvider } from "@mdx-js/react";
import SecondaryBrandsBanner from "components/secondary-brands-banner";
import { motion, useAnimation, useCycle } from "framer-motion";
import React from "react";
import { useInView } from "react-intersection-observer";
import { AspectRatioContainer } from "../../components/aspect-ratio";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";
import { gridCenteredMaxWidth } from "../app-layout";
import brandmark from "../logo/ic_brandmark_40dp.svg?raw";

const useStyles = mkStyles(
  ({ typography, spacing: { base8 }, breakpoints }) => {
    const { grid, areas } = gridCenteredMaxWidth();
    return {
      root: css`
      background: ${M.unescoDarkBlue};
      grid-column: ${M.layoutAreas.full};
      ${grid};
      grid-template-rows: 1fr 0;
      grid-template-areas:
        ". ${areas.main} ."
        "${areas.banner} ${areas.banner} ${areas.banner}";

      padding-top: ${base8(7)};
      padding-bottom: ${base8(12)};
      color: ${M.whiteText};
      transition: 2s background-color;
      min-height: 70vh;
      margin-top: 0px;

      /* This targets the iPad mini: Until exactly 1024 add padding. */
      @media screen and (max-width: ${breakpoints.values.l}px) {
        padding-bottom: ${base8(12)};
        min-height: 50vh;
      }

      @media ${breakpoints.down("s")} {
        padding: 0 0 ${base8(12)} 0;
      }

      p {
        ${typography.preset.fontBody1};
        max-width: 56ch;
        flex-shrink: 0;

        @media ${breakpoints.down("l")} {
          & {
            margin-top: ${base8(4)}};
            max-width: 100%;
          }
        }
      }

      span[data-theme="access"] {
        background: #f00;
        color: #fff;
      }
    `,

      content: css`
        grid-area: ${areas.main};
        display: flex;
        justify-content: space-between;

        @media ${breakpoints.down("l")} {
          flex-direction: column-reverse;
          width: 100%;
          align-items: center;
          padding: ${base8(4)};
        }

        @media ${breakpoints.down("m")} {
          align-items: flex-start;
        }
      `,

      banner: css`
        grid-area: ${areas.banner};
        margin-top: 0px;
        position: relative;
      `,

      highlighted: css`
        background-color: none;
      `,

      text: css`
        display: flex;
        justify-content: center;
        flex-direction: column;
      `,

      spinner: css`
        width: 100%;
        max-width: ${base8(62)};
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${M.bpDown("l")} {
          max-width: ${base8(48)};
        }

        @media ${M.bpDown("m")} {
          max-width: 60vw;
          margin-top: ${base8(-3)};
          margin-bottom: ${base8(-2)};
          margin-left: -5vw;
        }

        @media ${M.bpDown("s")} {
          margin-top: ${base8(3)};
        }
      `,

      img: css`
        width: 100%;
        user-select: none;
      `,
    };
  }
);

const useComponents = mkStyles(
  ({ typography, spacing: { base8 }, breakpoints }) => {
    return {
      h1: styled.h1`
        ${typography.preset.fontTera};
        font-weight: 400;
        margin: ${base8(4)} 0;
        color: ${M.whiteText};
        max-width: 16ch;

        @media ${breakpoints.down("l")} {
          max-width: 100%;
          margin: 0.4em 0 0 0;
        }
      `,
    };
  }
);

export interface HeroProps {
  title?: React.ReactNode;
}

export const IndexHero = ({ children }: $PropsWithChildren<HeroProps>) => {
  const styles = useStyles();
  const [rotate, cycleRotate] = useCycle(360, 0, 720);
  const [firstRender, setFirstRender] = React.useState(true);
  const [ref, inView] = useInView();

  const controls = useAnimation();
  const transition = {
    type: "inertia",
    velocity: 200,
    power: 1.5,
    restDelta: 10,
  };

  React.useEffect(() => {
    controls.start({
      rotate,
      transition: { ...transition, ...(firstRender && { delay: 1 }) },
    });
  }, [controls, firstRender, rotate, transition]);

  return (
    <MDXProvider components={useComponents()}>
      <div css={styles.root}>
        <div css={styles.content}>
          <div css={styles.text}>{children}</div>

          <div role="presentation" css={styles.spinner} ref={ref}>
            <AspectRatioContainer>
              <motion.img
                src={brandmark}
                css={styles.img}
                animate={controls}
                onMouseMove={() => {
                  firstRender && setFirstRender(false);
                  cycleRotate();
                }}
                onAnimationComplete={() => {
                  firstRender && setFirstRender(false);
                  inView && cycleRotate();
                }}
                alt=""
              ></motion.img>
            </AspectRatioContainer>
          </div>
        </div>
        <div css={styles.banner}>
          <SecondaryBrandsBanner flipped />
        </div>
      </div>
    </MDXProvider>
  );
};
