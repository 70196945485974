
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Terms and Conditions";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title"> Terms and Conditions </Title>
    <p>{`The Global Education Monitoring Report encourages the use of the Scoping Progress in Education (SCOPE) website for informational purposes only. All contents on this website are protected by copyright. By accessing the website and using any content found therein, you (the “User”) acknowledge that you have fully read and understood, and agree to be bound by, these Terms of Use.`}</p>
    <p>{`The information available on this website has been posted with the intent that it be readily available for personal or public non-commercial use and may be reproduced, in part or in whole, and by any means, without charge or further permission unless otherwise specified. All requests for commercial use and translation rights should be submitted to `}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "users-are-required-to"
    }}>{`Users are required to:`}</h3>
    <ul>
      <li parentName="ul">{`Exercise due diligence in ensuring the accuracy of the materials reproduced;`}</li>
      <li parentName="ul">{`Acknowledge the source prominently alongside the data using the following format:`}</li>
    </ul>
    <p>{`Source: (If appropriate "Adapted from") GEM Report Scoping Progress in Education (SCOPE) website, `}<a parentName="p" {...{
        "href": "https://www.education-progress.org"
      }}>{`https://www.education-progress.org`}</a>{`, date of extraction.`}</p>
    <p>{`It should be noted that the database is subject to updates and revisions on a regular basis. Therefore, it is important to include the date of extraction when citing data.`}</p>
    <h3 {...{
      "id": "licenses"
    }}>{`Licenses`}</h3>
    <p>{`You are encouraged to use the data to benefit yourself and others in creative ways. You may extract, download and make copies of the information contained in the datasets, and you may share that information with third parties.`}</p>
    <h3 {...{
      "id": "no-association-or-endorsement"
    }}>{`No association or endorsement`}</h3>
    <p>{`The User may neither represent nor imply that the Global Education Monitoring Report has participated in, approved, endorsed or otherwise supported his or her use or reproduction of the Material. The User may not claim any affiliation with Education Progress (or the Global Education Monitoring Report).`}</p>
    <h3 {...{
      "id": "website-links"
    }}>{`Website links`}</h3>
    <p>{`An external website may include a hyperlink to any page of the SCOPE website without requesting permission. However, this must not infringe on the intellectual property rights of the Global Education Monitoring Report, in particular relating to names, logos, copyright or author’s rights.`}</p>
    <h3 {...{
      "id": "disclaimer"
    }}>{`Disclaimer`}</h3>
    <p>{`The Global Education Monitoring Report maintains this website to enhance public access to data and related information. It makes every effort to ensure, but do not guarantee, the accuracy or completeness of the information on this site. Our goal is to keep this information timely and accurate. If errors are brought to our attention, we seek to correct them.`}</p>
    <p>{`The designations employed and the presentation of the material on this website do not imply the expression of any opinion whatsoever on the part of UNESCO concerning the legal status of any country, territory, city or area, or of its authorities, or concerning the delimitation of its frontiers or boundaries.`}</p>
    <p>{`The Global Education Monitoring Report is responsible for the choice and the presentation of the facts contained on this website, which are not necessarily those of UNESCO and do not commit the Organization.`}</p>
    <p>{`We make every effort to minimise disruption caused by technical errors. However, some data or information on the website may have been created or structured in files or formats which are not error-free and we cannot guarantee that our service will not be interrupted or otherwise affected by such problems. The Global Education Monitoring Report accepts no responsibility with regard to such problems (failure of performance, computer virus, communication line failure, alteration of content, etc.) incurred as a result of using the Education Progress website or any link to external sites.`}</p>
    <h3 {...{
      "id": "privacy"
    }}>{`Privacy`}</h3>
    <p>{`By accessing the SCOPE website, certain information, such as Internet protocol (IP) addresses, navigation behaviour through the website, the software used and the time spent, along with other similar information, will be stored on our servers. This information will not specifically identify you and will be used for website traffic analysis.`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;