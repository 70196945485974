import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "PSECWGAP",
  title: /*i18n*/ {
    id: "fig.equity.PSECWGAP.title",
    defaults: `Attendance rate by level and wealth`,
  },
  caption: /*i18n*/ {
    id: "fig.equity.PSECWGAP.caption",
    defaults: `The poorest have hardly any post-secondary education opportunities in low and middle income countries`,
  },
  source: /*i18n*/ {
    id: "fig.equity.PSECWGAP.source",
    defaults: `GEM Report team analysis based on household surveys`,
  },
};
