import * as React from "react";

function SvgIcQuestionMark16Dp(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(3.5 .5)" fill="currentColor" fillRule="nonzero">
        <path d="M5.465 10.5h-2.02v-1a4.114 4.114 0 012.19-3.563C6.545 5.294 6.98 4.943 6.98 4c0-1.74-1.582-2-2.525-2-1.39.14-2.162.577-3.036 1.657L0 2.25C1.236.777 2.525.123 4.455 0 7.215 0 9 1.57 9 4a4.114 4.114 0 01-2.19 3.563c-.909.643-1.345.994-1.345 1.937v1z" />
        <ellipse cx={4.455} cy={13.5} rx={1.515} ry={1.5} />
      </g>
    </svg>
  );
}

export default SvgIcQuestionMark16Dp;
