import { Type } from "io-ts";
import { AnyNewtype, CarrierOf } from "newtype-ts";

/**
 * This has been removed from io-ts-types without notice, so we copy it here
 * to work with fp-ts@2. Ideally, we'll use an "official" solution in the
 * future.
 */
export const fromNewtype: <N extends AnyNewtype = never>(
  codec: Type<CarrierOf<N>, CarrierOf<N>, unknown>
) => Type<N, CarrierOf<N>, unknown> = (type) => type as $IntentionalAny;
