import { Transition } from "framer-motion";

export const springConfigs: Record<"slow" | "gentle" | "lively", Transition> = {
  slow: {
    type: "spring",
    stiffness: 180,
    damping: 25,
  },
  gentle: {
    type: "spring",
    stiffness: 200,
    damping: 20,
  },
  lively: {
    type: "spring",
    damping: 15,
    stiffness: 100,
  },
};
