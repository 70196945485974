
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Adults face multiple barriers in pursuing education opportunities";
export const lead = "Analysing barriers to adult education and learning requires a clear framework.";
export const layout = "focus";
export const article = "access";
export const year = 2020;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Adults face multiple barriers in pursuing education opportunities" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=261" mdxType="FocusDetails">
      <h1 {...{
        "id": "adults-face-multiple-barriers-in-pursuing-education-opportunities"
      }}>{`Adults face multiple barriers in pursuing education opportunities`}</h1>
      <p>{`Analysing barriers to adult education and learning requires a clear framework. The PIAAC survey refers to barriers as factors preventing adults from participating in formal or non-formal education. Only non-participants in education are asked these questions; those already participating are not asked what prevented them from further increasing their participation. By contrast, the AES distinguishes between those not interested in education or training and those willing to participate, and investigates barriers to participation among the latter. `}</p>
      <p>{`How barriers are categorized matters. A long-standing categorization (Cross, 1981) describes factors preventing participation as situational (e.g. life circumstances, such as family responsibilities or lack of time), dispositional (e.g. determined by previous learning experiences and personal disposition towards learning) and institutional (e.g. structural conditions hampering access, such as cost, lack of support, rigid schedules or limited provision) (UIL, 2019). `}</p>
      <p>{`Dispositional barriers are generally less investigated in surveys and thus underestimated (Rubenson, 2011). Yet, when measured, they are the strongest factor hindering adult learning in most countries. On average, across EU countries, almost 60% of respondents do not participate in adult learning mainly because they see no need for it. Cost and inconvenient training schedules or locations are the most pressing institutional barriers. Lack of time and family responsibilities are the most common situational barriers, according to both PIAAC and AES data. `}</p>
      <FocusImage size="medium" src={"11-Adult_education_1.png"} alt="Negative previous learning experiences discourage many adults from participating in adult education" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=262" mdxType="FocusImage" />
      <p>{`Analysis of complementary PIAAC evidence for this report supports or extends these findings. Respondents in Greece and Turkey are most affected by situational barriers. Lack of time was a recurring concern in Japan, the Republic of Korea and Singapore, where respondents reported being too busy at work. In terms of institutional barriers, training cost prevented between 25% and 30% of adults in Greece, Israel and Slovenia from participating; in Finland, around one in five cited inconvenient locations or schedules. `}</p>
      <p>{`France stands out in terms of lack of employer or public service support, with only slight improvement between the 2011 and 2016 AES rounds. This is despite the Compte Personnel de Formation (Personal Training Account), a programme introduced in 2014 that allows employees to convert accumulated time credits into grants, as well as training leave, part-time work or early retirement. Financial incentives only partly address the barriers. They need to be combined with non-financial instruments, such as counselling services and information awareness campaigns (OECD, 2019b). `}</p>
      <p>{`While men were slightly more likely to mention scheduling as a barrier, women in all countries except Denmark were far more likely to mention family responsibilities. The tendency is higher in southern Europe, with up to two-thirds of female respondents in some countries unable to participate for this reason.`}</p>
      <FocusImage size="medium" src={"11-Adult_education_2.png"} alt="Women in European countries were almost twice as likely as men not to participate in adult education for family-related reasons" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=263" mdxType="FocusImage" />
      <p>{`A comparative study based on 14 time-use surveys and 5 household surveys in 19 countries found that men allocated slightly more time to learning, leisure and social activities. Albania, Ghana, Pakistan and the Republic of Moldova reported the highest gender imbalances: Ghanaian women spent almost two hours per day less than men on such activities (Rubiano-Matulevich and Viollaz, 2019). Women were more likely to see cost as an obstacle but less likely to have scheduling conflicts, probably reflecting their lower labour force participation and higher part-time employment rates.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-access"
      }}>{`Focuses published in Access`}</h4>
      <hr></hr>
      <FocusThemeList theme="access" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=505"} mdxType="ReferenceButton">
  2020 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;