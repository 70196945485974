import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "EDU_SCHOL_TT",
  title: /*i18n*/ {
    id: "fig.finance.aid.EDU_SCHOL_TT.title",
    defaults: ``,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.EDU_SCHOL_TT.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.EDU_SCHOL_TT.source",
    defaults: ``,
  },
};
