import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import { AnimatePresence, motion } from "framer-motion";
import useLocale from "hooks/useLocale";
import { Locale, locales } from "locales";
import React, { useState } from "react";
import { mkStyles, useTheme } from "../hooks";
import { useAppReducer } from "../hooks/useAppState";
import { E, O, pipe } from "../prelude";
import {
  findArticleAlias,
  RAlias,
  RAliasArticle,
  RAliasTheme,
  subthemesIndex,
  themeAliases,
} from "../routes";
import { gridCenteredMaxWidth } from "./app-layout";
import {
  expandHeightMotionProps,
  getLabel,
  getThemeLabel,
} from "./article-switcher";
import { LocaleLink, PageLink } from "./links";
import { LogoIO } from "./logo";
import { Search } from "./search/search";
import { useRouter } from "next/router";
import { ReactComponent as ArrowTopIcon } from "../icons/ic_arrow-top_16dp.svg";
import { flag } from "flags";
import SecondaryBrandsBanner from "./secondary-brands-banner";

const themesDrawerFlag = flag("improved-themes-drawer");

const useStyles = mkStyles(({ palette, spacing, typography, zIndex }) => {
  const { grid, areas } = gridCenteredMaxWidth();
  return {
    root: css`
      ${grid};
      background: ${palette.brand.blue.main};
      color: ${palette.text.white};
      padding: ${spacing.base8(2)};
      position: relative;

      z-index: ${zIndex.header};
    `,
    top: css`
      display: flex;
      width: 100%;
      justify-content: space-between;
    `,
    content: css`
      grid-area: ${areas.main};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    `,
    banner: css`
      grid-area: ${areas.banner};
    `,
    drawer: css`
      background: ${palette.brand.blue.main};
      position: absolute;
      overflow: hidden;
      width: 100%;
      left: 0;
      top: ${spacing.base8(8)};
      box-shadow: 0 ${spacing.base8(5)} ${spacing.base8(10)}
        ${spacing.base8(-3)} ${palette.brand.blue.dark};
    `,
    drawerContent: css`
      padding: ${spacing.base8(3)};
      display: flex;
      flex-direction: column;
    `,
    link: css`
      position: relative;
      padding: ${spacing.base8(1)} 0;
      color: inherit;
      ${typography.preset.fontBody1};
      text-transform: uppercase;
      font-weight: bold;
      opacity: 0.8;
    `,
    linkActive: css`
      opacity: 1;
    `,
    themeAccordion: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,
    themeAccordionArrow: css`
      transition: transform 0.2s ease;
    `,
    articleLink: css`
      display: flex;
      align-items: center;
      text-transform: none;
    `,
    articleLabel: css`
      padding: 0 ${spacing.base8(2)};
    `,
    subArticleLabel: css`
      ${typography.preset.fontBody2};
      font-weight: normal;
      margin-left: ${spacing.base8(6)};
      padding: 0 ${spacing.base8(2)};
    `,
    articleIllustration: css`
      width: ${spacing.base8(6)};
      min-width: ${spacing.base8(6)};
      img {
        width: 100%;
        display: block;
      }
    `,
    divider: css`
      margin: ${spacing.base8(1)} 0;
      border: none;
      opacity: 0.5;
      border-bottom: 1px solid ${palette.text.white};
    `,
  };
});

export function HeaderSmall() {
  const [{ themeDrawer }, dispatch] = useAppReducer();
  const [isLocaleDrawerOpen, setIsLocaleDrawerOpen] = useState(false);
  const locale = useLocale();
  const styles = useStyles();
  const { palette, utils } = useTheme();
  const {
    pathname,
    query: { alias: routeAlias },
  } = useRouter();

  const handleLocaleDrawerAction = (action: "toggle" | "close") => {
    if (themeDrawer.isOpen) {
      dispatch({ type: "themeDrawer.close" });
    }

    switch (action) {
      case "toggle":
        return setIsLocaleDrawerOpen(!isLocaleDrawerOpen);
      case "close":
        return setIsLocaleDrawerOpen(false);
    }
  };

  const handleThemeDrawerAction = (action: "toggle" | "close") => {
    if (isLocaleDrawerOpen) {
      setIsLocaleDrawerOpen(false);
    }

    switch (action) {
      case "toggle":
        return dispatch({ type: "themeDrawer.toggle" });
      case "close":
        return dispatch({ type: "themeDrawer.close" });
    }
  };

  const handleKeyDown = (
    ev: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    if (ev.keyCode == 13) {
      handleLocaleDrawerAction("close");
      handleThemeDrawerAction("close");
    }
  };

  const drawerMotionVariants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  const alias = pipe(
    routeAlias,
    RAlias.decode,
    E.fold(
      () => (pathname.startsWith("/focus/") ? "focusPosts" : "home"),
      (alias) => alias
    )
  );

  return (
    <>
      <div css={styles.root}>
        <div css={styles.content}>
          <div css={styles.top}>
            <PageLink name="home" query={{}}>
              <a style={{ display: "block" }}>
                <LogoIO size="extrasmall" />
              </a>
            </PageLink>

            <LocaleMenuToggle
              locale={locale}
              toggle={() => handleLocaleDrawerAction("toggle")}
              open={isLocaleDrawerOpen}
            />
            <MenuToggle
              toggle={() => handleThemeDrawerAction("toggle")}
              open={themeDrawer.isOpen}
            ></MenuToggle>
          </div>

          <AnimatePresence initial={false} key="localeDrawer">
            {isLocaleDrawerOpen && (
              <motion.div
                css={styles.drawer}
                initial="closed"
                animate="open"
                exit="closed"
                variants={drawerMotionVariants}
              >
                <nav css={styles.drawerContent}>
                  {locales.map(({ name, lang }) => (
                    <span
                      key={lang}
                      // LocaleLink already handles opacity on its own
                      css={[styles.link, { opacity: 1 }]}
                      onClick={() => handleLocaleDrawerAction("close")}
                      onKeyDown={handleKeyDown}
                      role="button"
                      // We want to only select LocaleLink with a tab
                      tabIndex={-1}
                    >
                      <LocaleLink locale={lang}>{name}</LocaleLink>
                    </span>
                  ))}
                </nav>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence initial={false} key="themeDrawer">
            {themeDrawer.isOpen && (
              <motion.div
                css={styles.drawer}
                initial="closed"
                animate="open"
                exit="closed"
                variants={drawerMotionVariants}
              >
                <nav css={styles.drawerContent}>
                  <PageLink name="home" query={{}}>
                    <a
                      css={[styles.link, alias === "home" && styles.linkActive]}
                      onClick={() => handleThemeDrawerAction("close")}
                      onKeyDown={handleKeyDown}
                      role="button"
                      tabIndex={0}
                    >
                      <Trans>Home</Trans>
                    </a>
                  </PageLink>

                  <hr css={styles.divider} />

                  <div
                    css={[
                      styles.link,
                      O.isSome(findArticleAlias(alias)) && styles.linkActive,
                    ]}
                  >
                    <Trans>Themes</Trans>
                  </div>

                  {themeAliases.map((d) => {
                    if (themesDrawerFlag) {
                      return (
                        <ThemeAccordion
                          key={d}
                          alias={d}
                          onClick={() => handleThemeDrawerAction("close")}
                          onKeyDown={handleKeyDown}
                        />
                      );
                    } else {
                      const theme = palette.theme[d];
                      const highlighted = alias === d;
                      return (
                        <div key={d}>
                          <PageLink name={d} query={{}}>
                            <a
                              css={[
                                styles.link,
                                alias === d && styles.linkActive,
                                styles.articleLink,
                              ]}
                              onClick={() => handleThemeDrawerAction("close")}
                              onKeyDown={handleKeyDown}
                              role="button"
                              tabIndex={0}
                            >
                              {theme.kind === "article" && (
                                <div
                                  css={styles.articleIllustration}
                                  style={{
                                    color: highlighted
                                      ? theme.textColor
                                      : palette.text.white,
                                    backgroundColor: highlighted
                                      ? theme.background.light
                                      : utils.fade(theme.background.light, 0.5),
                                  }}
                                >
                                  <img src={theme.illustration} alt="" />
                                </div>
                              )}
                              <div css={styles.articleLabel}>
                                {getThemeLabel(d)}
                              </div>
                            </a>
                          </PageLink>
                        </div>
                      );
                    }
                  })}

                  <hr css={styles.divider} />

                  <PageLink name="focusPosts" query={{}}>
                    <a
                      css={[
                        styles.link,
                        alias === "focusPosts" && styles.linkActive,
                      ]}
                      onClick={() => handleThemeDrawerAction("close")}
                      onKeyDown={handleKeyDown}
                      role="button"
                      tabIndex={0}
                    >
                      <Trans>Focuses</Trans>
                    </a>
                  </PageLink>
                  <hr css={styles.divider} />

                  <PageLink name="indicators" query={{}}>
                    <a
                      css={[
                        styles.link,
                        alias === "indicators" && styles.linkActive,
                      ]}
                      onClick={() => handleThemeDrawerAction("close")}
                      onKeyDown={handleKeyDown}
                      role="button"
                      tabIndex={0}
                    >
                      <Trans>SDG 4 indicators</Trans>
                    </a>
                  </PageLink>

                  <hr css={styles.divider} />

                  <PageLink name="about" query={{}}>
                    <a
                      css={[
                        styles.link,
                        alias === "about" && styles.linkActive,
                      ]}
                      onClick={() => handleThemeDrawerAction("close")}
                      onKeyDown={handleKeyDown}
                      role="button"
                      tabIndex={0}
                    >
                      <Trans>About</Trans>
                    </a>
                  </PageLink>

                  <hr css={styles.divider} />

                  <Search locale={locale} />
                </nav>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div css={styles.banner}>
        {routeAlias !== "home" ? (
          <SecondaryBrandsBanner overContent={true} />
        ) : null}
      </div>
    </>
  );
}

const ThemeAccordion = ({
  alias,
  onClick,
  onKeyDown,
}: {
  alias: RAliasTheme;
  onClick: () => void;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const styles = useStyles();
  const subthemes = subthemesIndex[alias];
  const hasSubthemes = subthemes && subthemes.length > 1;
  const handleClickOrKeyDown = () => setOpen(!open);

  return hasSubthemes ? (
    <>
      <div
        css={styles.themeAccordion}
        role="button"
        tabIndex={0}
        onClick={handleClickOrKeyDown}
        onKeyDown={handleClickOrKeyDown}
      >
        <ArticleLink
          alias={alias}
          label={getThemeLabel(alias)}
          isSubtheme={false}
        />
        <div
          css={[
            styles.themeAccordionArrow,
            { transform: `rotate(${open ? 0 : 180}deg)` },
          ]}
        >
          <ArrowTopIcon />
        </div>
      </div>

      {open && (
        <motion.div {...expandHeightMotionProps}>
          {subthemes.map((d) => (
            <PageLink key={d} name={d} query={{}}>
              <ArticleLink
                alias={d}
                label={getLabel(d)}
                isSubtheme
                onClick={onClick}
                onKeyDown={onKeyDown}
              />
            </PageLink>
          ))}
        </motion.div>
      )}
    </>
  ) : (
    <PageLink name={alias} query={{}}>
      <ArticleLink
        alias={alias}
        label={getThemeLabel(alias)}
        isSubtheme={false}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
    </PageLink>
  );
};

const ArticleLink = ({
  alias,
  label,
  isSubtheme,
  onClick,
  onKeyDown,
}: {
  alias: RAliasArticle;
  label: JSX.Element;
  isSubtheme: boolean;
  onClick?: () => void;
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
}) => {
  const styles = useStyles();
  const { palette } = useTheme();
  const theme = palette.theme[alias];

  return (
    <a
      css={[styles.link, styles.articleLink, styles.linkActive]}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {!isSubtheme && theme.kind === "article" && (
        <div css={styles.articleIllustration}>
          <img src={theme.illustration} alt="" />
        </div>
      )}
      <div css={isSubtheme ? styles.subArticleLabel : styles.articleLabel}>
        {label}
      </div>
    </a>
  );
};

const useMenuToggleStyles = mkStyles(() => ({
  root: css`
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  `,
}));

const MENU_TOGGLE_MOTION_VARIANTS = [
  { closed: { d: "M 0 6 L 24 6" }, open: { d: "M 2 22 L 22 2" } },
  { closed: { d: "M 0 12 L 24 12" }, open: { d: "M 12 12 L 12 12" } },
  { closed: { d: "M 0 18 L 24 18" }, open: { d: "M 2 2 L 22 22" } },
];

export const MenuToggle = ({
  toggle,
  open,
}: {
  toggle: () => void;
  open: boolean;
}) => {
  const styles = useMenuToggleStyles();

  return (
    <button onClick={toggle} css={styles.root}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        {MENU_TOGGLE_MOTION_VARIANTS.map((d, i) => (
          <motion.path
            key={i}
            initial={false}
            fill="transparent"
            strokeWidth={2}
            stroke="white"
            animate={open ? "open" : "closed"}
            variants={d}
          />
        ))}
      </svg>
    </button>
  );
};

const useLocaleMenuToggleStyles = mkStyles(({ spacing, typography }) => ({
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    padding: 0 ${spacing.base8(1)};
    background: none;
    border: none;
    color: white;

    &:focus {
      outline: none;
    }

    html[dir="rtl"] & {
      margin-left: 0;
      margin-right: auto;
    }
  `,
  locale: css`
    position: relative;
    padding: ${spacing.base8(1)} 0;
    color: inherit;
    ${typography.preset.fontBody1};
    text-transform: uppercase;
    opacity: 0.8;
    transition: opacity 0.3s;
  `,
  localeActive: css`
    opacity: 1;
  `,
}));

const LocaleMenuToggle = ({
  locale,
  toggle,
  open,
}: {
  locale: Locale;
  toggle: () => void;
  open: boolean;
}) => {
  const styles = useLocaleMenuToggleStyles();

  return (
    <button onClick={toggle} css={styles.root}>
      <span css={[styles.locale, open && styles.localeActive]}>{locale}</span>
    </button>
  );
};
