import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { useColorScaleVDP, VerticalDotPlotAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { useConfig } from "../config";
import { metadata } from "../data/data_learning_fig_LITBYYRS";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  mkGemDataDecoder,
  useNamedCountries,
} from "../domain";
import { useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, identity, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_learning_fig_LITBYYRS";

const statusOrder = {
  "With up to 4 years of schooling": 1,
  "With 5-6 years of schooling": 2,
};

const Status = io.keyof(statusOrder);
type Status = io.TypeOf<typeof Status>;

const ordStatus = Ord.contramap(
  (x: keyof typeof statusOrder) => statusOrder[x]
)(Ord.ordNumber);

const statusLookup: Record<Status, MessageDescriptor> = {
  "With up to 4 years of schooling": t(
    "fig.learning.LITBYYRS.schooling0to4"
  )`With up to 4 years of schooling`,
  "With 5-6 years of schooling": t(
    "fig.learning.LITBYYRS.schooling5to6"
  )`With 5–6 years of schooling`,
};

export const Data = mkGemDataDecoder(["iso3c", "ind_id"], {
  value: io.number,
  status: Status,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: rawData }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const { activeSection } = useReadScrolly();

  const highlightItems = pipe(
    activeSection,
    O.map((x) => x.split(",")),
    O.chain((strIds) =>
      Ar.array.sequence(O.option)(
        strIds.map((strId) =>
          getCountryMeta(env.countries, countryIdIso.wrap(strId))
        )
      )
    ),
    O.map((xs) => xs.map((meta) => meta.country)),
    O.chain((xs) => An.fromArray(xs))
  );

  const data_ = useNamedCountries(rawData);
  const data = React.useMemo(() => {
    return data_.map((x) => ({
      ...x,
      status_name: i18n._(statusLookup[x.status]),
    }));
  }, [i18n, data_]);

  const chartData = React.useMemo(() => {
    type Dtm = typeof data[number];

    return unsafeFromArray(
      Ar.sortBy([
        Ord.contramap((x: Dtm) => x.status)(ordStatus),
        Ord.contramap((x: Dtm) => x.value)(Ord.ordNumber),
      ])(data)
    );
  }, [data]);
  type Datum = typeof chartData[number];

  const getX = React.useCallback((x: Datum) => x.country_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.status_name, []);
  const getColorPalette = React.useCallback(
    () => M.fromCount(M.colorRanges.diverging, 2),
    []
  );
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      showTooltip
      showLegend
      showYExtent={O.isNone(highlightItems)}
      dotStyle={"small"}
      labelStyleX="none"
      highlightItems={highlightItems}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_LITBYYRS.json"),
  csv: require("../data/data_learning_fig_LITBYYRS.zip"),
  xlsx: require("../data/data_learning_fig_LITBYYRS.xlsx"),
  metadata,
  Data,
  Chart,
});
