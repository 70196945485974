
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Calidad";
export const lead = "La educación de buena calidad no puede ser equiparada, o reducida, a los resultados del aprendizaje. Demasiados niños no disponen de maestros suficientemente capacitados, una buena infraestructura escolar o un entorno de aprendizaje seguro y no violento.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const QualityFramework = makeShortcode("QualityFramework");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_QUALITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`La educación de buena calidad no puede ser equiparada, o reducida, a los resultados del aprendizaje. Demasiados niños no disponen de maestros suficientemente capacitados, una buena infraestructura escolar o un entorno de aprendizaje seguro y no violento.`}</p>
    </blockquote>
    <p>{`Es difícil realizar un seguimiento de la educación de buena calidad porque cada país la entiende y la define de manera diferente. Aparte de los resultados del aprendizaje, no hay muchos indicadores para evaluar la calidad en el objetivo mundial de educación, el ODS 4.`}</p>
    <h2 {...{
      "id": "docentes"
    }}>{`Docentes`}</h2>
    <IndicatorReference id="4.c.2" mdxType="IndicatorReference">
      <p>{`Los docentes son los factores determinantes de la calidad de la educación. Sin embargo, después del año 2000, en el marco del esfuerzo por lograr la educación primaria universal, muchos más alumnos ingresaron a las aulas, pero la contratación de maestros no fue correlativa. Como resultado de ello, algunas aulas están abarrotadas, y la calidad de la educación se ve afectada.`}</p>
      <QualityFramework highlighted="teachers" description="Más información sobre el marco para la calidad de la educación" mdxType="QualityFramework" />
      <p>{`Los siguientes gráficos muestran la proporción de alumnos por docente en la escuela. En algunos países de África Subsahariana, si bien la proporción ha mejorado desde 2000, sigue siendo peor que en la década de 1980.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PTR" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`África Subsahariana`}</HighlightByIndex>{`, tras la campaña emprendida entre 1990 y 2000 para hacer gratuita la escuela primaria, la llegada de los niños a las aulas fue más rápida que la contratación de docentes por parte de los países. Esto condujo a un incremento de la proporción de alumnos por docente, a menudo bastante drástico.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En `}<HighlightByIndex index="6" mdxType="HighlightByIndex">{`América del Norte y Europa`}</HighlightByIndex>{`, mientras tanto, la proporción se ha mantenido constante en unos 15 alumnos por docente desde 2005.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`La enseñanza es una tarea difícil, y los docentes que no están bien preparados entran en el aula con un grave déficit. Los países miden su grado de preparación de dos formas principales, a saber:`}</p>
    <ul>
      <li parentName="ul">{`En primer lugar, si están `}<strong parentName="li">{`cualificados`}</strong>{`, lo que indica si poseen las credenciales académicas esperadas de acuerdo con las normas nacionales.`}</li>
      <li parentName="ul">{`En segundo lugar, si están `}<strong parentName="li">{`capacitados`}</strong>{`, es decir que se evalúa si, independientemente de su cualificación, recibieron una formación de docentes adecuada.`}</li>
    </ul>
    <IndicatorReference id="4.c.1" mdxType="IndicatorReference">
      <p>{`Estar capacitado supone que el docente ha asistido a una formación en la que ha obtenido `}<Tooltip title="conocimientos pedagógicos" content="Knowledge on approaches, methods and techniques of teaching." mdxType="Tooltip">{`conocimientos pedagógicos`}</Tooltip>{` y `}<Tooltip title="conocimientos profesionales" content="Knowledge on, for example, the legal frameworks governing the teaching profession." mdxType="Tooltip">{`conocimientos profesionales`}</Tooltip>{`. Algunos programas también pueden abarcar el `}<Tooltip title="conocimiento del contenido" content="Knowledge of the curriculum, subject matter and use of relevant materials." mdxType="Tooltip">{`conocimiento del contenido`}</Tooltip>{`. No obstante, el tipo de formación requerida difiere según el país y la información pertinente es escasa. Debido a estas diferencias, a pesar de ser un elemento crucial de la calidad de la educación, el indicador sobre docentes capacitados `}{`[4.c.1]`}{` es uno de los menos comparables en el marco de seguimiento del ODS 4.`}</p>
      <p>{`En el plano mundial, como muestra el gráfico siguiente, la mayoría de los docentes están cualificados, capacitados o ambas cosas. Por ejemplo, en `}<HighlightByIndex index="4" mdxType="HighlightByIndex">{`América Latina y el Caribe`}</HighlightByIndex>{`más del 80% de los docentes están capacitados.`}</p>
      <p>{`En cambio, en `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`África Subsahariana`}</HighlightByIndex>{`el porcentaje de docentes capacitados disminuyó del 84% en 2000 al 69% en 2019.`}</p>
      <Figure id="TRAINED" mdxType="Figure" />
      <p>{`No hay una solución fácil para los países que carecen de docentes cualificados y capacitados. Muchos de ellos no tienen suficientes docentes que reúnan los requisitos necesarios para satisfacer la demanda, dado el escaso número de diplomados de la educación terciaria en general. Esto demuestra el grado de interconexión que pueden alcanzar los problemas dentro de un sistema educativo; así pues, los problemas en un nivel, como la baja tasa de finalización de la escuela secundaria, pueden fácilmente ejercer repercusiones en otros ámbitos, como la escasez de docentes capacitados para los estudiantes en el futuro.`}</p>
    </IndicatorReference>
    <h2 {...{
      "id": "infraestructura"
    }}>{`Infraestructura`}</h2>
    <IndicatorReference id="4.a.1e" mdxType="IndicatorReference">
      <p>{`Un entorno de aprendizaje apropiado debe ofrecer instalaciones adecuadas de `}<strong parentName="p">{`agua`}</strong>{` y saneamiento, especialmente para las niñas. Sin embargo, solo el 42% de las escuelas primarias de África Subsahariana, el 78% de las de Asia oriental y sudoriental y el 83% de las de Asia central y meridional disponían de servicios básicos de agua.`}</p>
      <QualityFramework highlighted="material" description="Más información sobre el marco para la calidad de la educación" mdxType="QualityFramework" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1f" mdxType="IndicatorReference">
      <p>{`En particular, las instalaciones de `}<strong parentName="p">{`saneamiento`}</strong>{` que no ofrecen privacidad no pueden considerarse seguras, mientras que las instalaciones que no aseguran una higiene adecuada durante el período menstrual pueden impedir a las chicas asistir a la escuela. Por lo tanto, disponer de instalaciones de saneamiento separadas por sexo es una medida normativa fundamental para evitar que la matriculación tardía en la educación primaria y el abandono escolar de las chicas se generalicen con el tiempo.`}</p>
      <p>{`El siguiente gráfico muestra que menos de la mitad de las escuelas primarias dispone de instalaciones de saneamiento separadas por sexo, no solo en África Subsahariana, sino también en países asiáticos como Bangladesh, la República Democrática Popular Lao y Nepal. En el Afganistán, únicamente el 26% de las escuelas primarias, el 56% de las escuelas de primer ciclo de secundaria, y el 75% de las escuelas de segundo ciclo de secundaria tienen instalaciones de saneamiento separadas por sexo.`}</p>
      <Figure id="SANITATION" mdxType="Figure" />
      <p>{`Conviene ser prudente al interpretar estas estimaciones, ya que las definiciones varían. Algunos suponen que un saneamiento adecuado significa tener retretes extremadamente básicos, como letrinas de pozo, otros solo cuentan los retretes funcionales, mientras que otros únicamente tendrían en cuenta los retretes que cumplen con las normas nacionales.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1a" mdxType="IndicatorReference">
      <p>{`La `}<strong parentName="p">{`electricidad`}</strong>{` es una necesidad básica para un entorno de aprendizaje de calidad y, a pesar de esto, muchos países carecen de ella. En los países de bajos ingresos, el 72% de las escuelas primarias y el 53% de las escuelas de segundo ciclo de secundaria no tienen electricidad.`}</p>
      <p>{`Como muestra el gráfico siguiente, en el Afganistán, Kiribati y Nepal menos de la mitad de las escuelas primarias tiene electricidad. En las escuelas de algunos países de África Subsahariana, el acceso a ese servicio es extremadamente reducido: el 8% de las escuelas tiene electricidad en Madagascar, y tan solo el 4% en la República Centroafricana y el Chad. La falta de electricidad puede hacer que la iluminación sea inadecuada, e impedir a los docentes usar ordenadores para la administración o la capacitación. No tener suministro eléctrico significa que no hay ventilación, refrigeración o calefacción, lo que puede tener como consecuencia que las aulas sean inapropiadas para la enseñanza y el aprendizaje.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1b" mdxType="IndicatorReference">
      <p>{`Ello también imposibilita el uso de tecnología e `}<strong parentName="p">{`Internet`}</strong>{`. Sin embargo, la electricidad no es el único factor que frena la ampliación de Internet. En los países de ingresos medianos, el 43% de las escuelas primarias y el 30% de las escuelas de segundo ciclo de secundaria disponían de electricidad, pero no de Internet. Aun cuando las escuelas están conectadas a una fuente de energía, las subidas y bajas de tensión son comunes. La conectividad a Internet depende de la infraestructura nacional de telecomunicaciones, la geografía y la capacidad de las escuelas para pagar por el servicio.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="ELECTRIC" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="EGY" mdxType="ScrollySection">
          <p>{`En `}<strong parentName="p">{`Egipto`}</strong>{`, a pesar de que todas las escuelas primarias tienen electricidad, solo el 71% tiene acceso a Internet. Lo mismo ocurre con el 64% de las escuelas en Azerbaiyán y el 34% en Jordania.`}</p>
        </ScrollySection>
        <ScrollySection id="ECU,URY" mdxType="ScrollySection">
          <p>{`En las escuelas de muchos países de América Latina no hay ni electricidad ni Internet. Únicamente alrededor del 41% de las escuelas del `}<strong parentName="p">{`Ecuador`}</strong>{` y 5% del Paraguay disponen de acceso a ambos. Una excepción es el `}<strong parentName="p">{`Uruguay`}</strong>{`, donde el 100% de las escuelas primarias tiene acceso a los dos servicios.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "violencia"
    }}>{`Violencia`}</h2>
    <p>{`Los actos de violencia, o las amenazas, relacionados con la escuela pueden ser de tipo psicológico, físico o sexual, y producirse tanto en locales escolares, como en el camino de la escuela, en el hogar o en el ciberespacio. A menudo se producen debido a una desigualdad de las dinámicas de poder, y son en muchos casos el resultado de normas negativas y estereotipos de género.`}</p>
    <p>{`Si bien la atención suele centrarse en los casos extremos, como los tiroteos, las formas de violencia más comunes y con frecuencia inadvertidas, como el matonismo, son las que tienen las mayores repercusiones negativas en la educación de los niños y adolescentes. Los datos sobre estas cuestiones siguen siendo insuficientes, porque las preguntas que se formulan para la recopilación de datos emplean distintas definiciones de comportamiento violento, los calendarios para la presentación de información no son uniformes, y falta coherencia en cuanto a las opciones de respuesta, las disposiciones en materia de confidencialidad o los protocolos de deontología. Esto se suma a que no se denuncien ciertas formas de violencia, como el acoso homofóbico, aún rodeadas de tabúes.`}</p>
    <QualityFramework highlighted="school" description="Más información sobre el marco para la calidad de la educación" mdxType="QualityFramework" />
    <IndicatorReference id="4.a.2" mdxType="IndicatorReference">
      <p>{`El `}<strong parentName="p">{`matonismo`}</strong>{` es la forma de violencia más ampliamente documentada en las escuelas. Puede consistir en violencia física, abusos verbales o la intención de provocar daños psicológicos mediante la humillación o la exclusión. El siguiente gráfico muestra que en la mayoría de países al menos uno de cada tres adolescentes, con frecuencia más los chicos que las chicas, son víctimas del matonismo.`}</p>
      <Figure id="BULLY_FIGHT" mdxType="Figure" />
    </IndicatorReference>
    <p>{`La `}<strong parentName="p">{`violencia física`}</strong>{` comprende la intervención en peleas físicas en la escuela, portar un arma o ser amenazado con alguna, como una pistola, un cuchillo o un palo, en el recinto escolar, pertenecer o estar vinculado a una pandilla, o sufrir violencia física a manos de un miembro del personal.`}</p>
    <p>{`Más del 30% de los adolescentes de 26 países informaron haber estado involucrados en peleas físicas entre 2012 y 2017, una prevalencia que superó el 70% en Tuvalu. La violencia escolar es también un problema considerable en los Estados del Golfo, en países como Bahrein, Kuwait y Omán.`}</p>
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`Los datos comparables en el plano internacional sobre los indicadores relativos a los docentes son sorprendentemente escasos. Relativamente pocos países proporcionan siquiera información sobre un recuento básico de los docentes, y eso no tiene en cuenta las horas de enseñanza, los docentes que ocupan puestos administrativos, y otras cuestiones complejas. Se necesita una tipología de las normas de capacitación de los docentes, para que así podamos detectar adecuadamente las lagunas, y llevar esta cuestión al plano mundial.`}</p>
    <p>{`Contar con instalaciones educativas “que tengan en cuenta las necesidades de los niños y las personas con discapacidad y las diferencias de género” y que ofrezcan “entornos de aprendizaje seguros, no violentos, inclusivos y eficaces para todos” es problemático en numerosos países, tal como demuestra este artículo. Al mismo tiempo, resulta difícil medir el progreso referente a esta cuestión, porque el indicador mundial pertinente no es una sola medida, sino un conjunto de varias dimensiones.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.oecd-ilibrary.org/education/education-at-a-glance_19991487"
          }}>{`Education at a glance – OECD`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theirworld.org/news/safe-schools-report-reveals-crises-challenges-action-needed-to-educate-children"
          }}>{`Safe Schools Report – Their World`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/fs46-more-than-half-children-not-learning-en-2017.pdf"
          }}>{`More Than One-Half of Children and Adolescents Are Not Learning Worldwide – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232107"
          }}>{`School-related gender-based violence is preventing the achievement of quality education for all`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;