import { Mixed } from "io-ts";
import React from "react";
import { ConfigContext, useConfig } from "../config";
import { fromNewtype, pick } from "../lib";
import { useI18n } from "../locales";
import { Ar, io, O, Ord, pipe } from "../prelude";
import { CountryId, getCountryName } from "./country";
import { IndicatorId } from "./indicator";
import { getLevelName, Level, ordLevel } from "./level";
import {
  getRegionCategoryName,
  getRegionIndex,
  getRegionMeta,
  getRegionName,
  ordRegionCategory,
  RegionId,
  sortRegions,
} from "./region";
import { Year } from "./types";

// -----------------------------------------------------------------------------
// RiseData

export const RiseData = io.type({
  year: io.number,
  skill: io.union([io.literal("LIT"), io.literal("NUM")]),
  series: io.string,
  value: io.union([io.number, io.null]),
  group: io.string,
  country: io.union([
    fromNewtype<CountryId>(io.string),
    fromNewtype<RegionId>(io.string),
  ]),
  facet: io.union([io.literal("AGE"), io.literal("GRADE")]),
  id: io.string,
});
export type RiseData = io.TypeOf<typeof RiseData>;

// -----------------------------------------------------------------------------
// RiseDataDecoder

export const RiseDataDecoder = <
  K extends keyof RiseData,
  P extends io.Props = Record<string, Mixed>
>(
  keys: Array<K>,
  props: P
) => {
  const baseProps = pick(RiseData.props, keys);
  return io.array(
    io.type({
      ...baseProps,
      ...props,
    })
  );
};

// -----------------------------------------------------------------------------
// BaseGemData

export const BaseGemData = io.type({
  ind_id: fromNewtype<IndicatorId>(io.string),
  iso3c: fromNewtype<CountryId>(io.string),
  level: Level,
  region: fromNewtype<RegionId>(io.string),
  value: io.union([io.number, io.null]),
  year: Year,
});
export type BaseGemData = io.TypeOf<typeof BaseGemData>;

// -----------------------------------------------------------------------------
// GemDataDecoder

export const mkGemDataDecoder = <
  K extends keyof BaseGemData,
  P extends io.Props = Record<string, Mixed>
>(
  keys: Array<K>,
  props: P
) => {
  const baseProps = pick(BaseGemData.props, keys);
  return io.array(
    io.type({
      ...baseProps,
      ...props,
    })
  );
};

// -----------------------------------------------------------------------------
// GemEntityDecoder
//
// This is useful to work with country/region data, it's a specialization of
// the default GemDataDecoder.

export const mkGemEntityDecoder = <
  K extends keyof Omit<BaseGemData, "iso3c" | "region">,
  P extends io.Props = Record<string, Mixed>
>(
  keys: Array<K>,
  props: P
) => {
  const baseProps = pick(BaseGemData.props, keys);
  return io.array(
    io.union([
      io.intersection([
        io.type({
          ...baseProps,
          ...props,
        }),
        CountryEntity,
      ]),
      io.intersection([
        io.type({
          ...baseProps,
          ...props,
        }),
        RegionEntity,
      ]),
    ])
  );
};

export const CountryEntity = io.type({
  geo_entity: io.literal("country"),
  id: fromNewtype<CountryId>(io.string),
});
export type CountryEntity<A> = io.TypeOf<typeof CountryEntity> & A;

export const RegionEntity = io.type({
  geo_entity: io.literal("region"),
  id: fromNewtype<RegionId>(io.string),
});
export type RegionEntity<A> = io.TypeOf<typeof RegionEntity> & A;

export const GeoEntity = io.union([CountryEntity, RegionEntity]);
export type GeoEntity<A> = CountryEntity<A> | RegionEntity<A>;

export const foldGeoEntity =
  <A extends object, B>(pattern: {
    country: (e: CountryEntity<Omit<A, "id" | "entity">>) => B;
    region: (e: RegionEntity<Omit<A, "id" | "entity">>) => B;
  }) =>
  (entity: GeoEntity<A>) => {
    switch (entity.geo_entity) {
      case "country":
        return pattern.country(entity);
      case "region":
        return pattern.region(entity);
    }
  };

export function filterCountryEntities<A>(xs: Array<GeoEntity<A>>) {
  return xs.filter((x) => x.geo_entity === "country") as unknown as Array<
    CountryEntity<Omit<A, "id" | "entity">>
  >;
}

export function filterRegionEntities<A>(xs: Array<GeoEntity<A>>) {
  return xs.filter((x) => x.geo_entity === "region") as unknown as Array<
    RegionEntity<Omit<A, "id" | "entity">>
  >;
}

// -----------------------------------------------------------------------------
// Hooks

const entityType = (env: ConfigContext) =>
  foldGeoEntity({
    country: () => "country" as const,
    region: ({ id }) =>
      pipe(
        getRegionMeta(env.regions, id),
        O.fold(
          () => "unknown_region" as const,
          (x) => x.category
        )
      ),
  });

const entityName = (env: ConfigContext) =>
  foldGeoEntity({
    country: ({ id }) => getCountryName(env.countries, id),
    region: ({ id }) => getRegionName(env.regions, id),
  });

export function useNamedEntities<A>(data: Array<GeoEntity<A>>) {
  const i18n = useI18n();
  const env = useConfig();

  return React.useMemo(() => {
    const withNames = data.map((row) => {
      const entity_type = entityType(env)(row);
      return {
        ...row,
        entity_name: entityName(env)(row),
        entity_type,
        entity_type_name: getRegionCategoryName(i18n, entity_type),
      };
    });
    type WithNames = ArrayElement<typeof withNames>;

    const sorted: WithNames[] = Ar.sortBy([
      Ord.contramap((x: WithNames) => x.entity_type)(ordRegionCategory),
      Ord.contramap((x: WithNames) =>
        foldGeoEntity({
          country: () => x.entity_name,
          region: () => "",
        })(x)
      )(Ord.ordString),
      Ord.contramap(
        foldGeoEntity({
          country: () => 0,
          region: (x) => getRegionIndex(env.regions, x.id),
        })
      )(Ord.ordNumber),
    ])(withNames as $FixMe);

    return sorted;
  }, [i18n, data, env]);
}

export function useNamedEntitiesWithLevel<A>(
  data: Array<GeoEntity<A> & { level: Level }>
) {
  const i18n = useI18n();
  const env = useConfig();

  return React.useMemo(() => {
    const withNames = data.map((row) => {
      const entity_type = entityType(env)(row);
      return {
        ...row,
        entity_name: entityName(env)(row),
        entity_type,
        entity_type_name: getRegionCategoryName(i18n, entity_type),
        level_name: getLevelName(i18n, row.level),
      };
    });
    type WithNames = ArrayElement<typeof withNames>;

    const sorted: WithNames[] = Ar.sortBy([
      Ord.contramap((x: WithNames) => x.entity_type)(ordRegionCategory),
      Ord.contramap((x: WithNames) =>
        foldGeoEntity({
          country: () => x.entity_name,
          region: () => "",
        })(x)
      )(Ord.ordString),
      Ord.contramap(
        foldGeoEntity({
          country: () => 0,
          region: (x) => getRegionIndex(env.regions, x.id),
        })
      )(Ord.ordNumber),
      Ord.contramap((x: WithNames) => x.level)(ordLevel),
    ])(withNames as $FixMe);

    return sorted;
  }, [i18n, data, env]);
}

export function useNamedCountries<A>(data: Array<A & { iso3c: CountryId }>) {
  const env = useConfig();

  return React.useMemo(() => {
    const countries = data.map((row) => ({
      ...row,
      country_name: getCountryName(env.countries, row.iso3c),
    }));

    return Ar.sort(
      Ord.contramap((x: typeof countries[number]) => x.country_name)(
        Ord.ordString
      )
    )(countries);
  }, [data, env.countries]);
}

export function useNamedRegions<A>(data: Array<A & { region: RegionId }>) {
  const env = useConfig();

  return React.useMemo(() => {
    const regions = data.map((row) => ({
      ...row,
      region_name: getRegionName(env.regions, row.region),
    }));

    return sortRegions(env.regions)(regions, (x) => x.region);
  }, [data, env.regions]);
}

export function useCountryEntities<A>(data: Array<GeoEntity<A>>) {
  return React.useMemo(() => filterCountryEntities(data), [data]);
}

export function useRegionEntities<A>(data: Array<GeoEntity<A>>) {
  return React.useMemo(() => filterRegionEntities(data), [data]);
}
