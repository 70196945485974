import { t } from "@lingui/macro";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_aid_fig_ODA_GDP";
import {
  ChartRenderer,
  getAidFlowName,
  mkGemDataDecoder,
  useCheckboxState,
  useFigureState,
  useMultiSelectState,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, Eq, io, O, Ord, pipe } from "../prelude";
import { unsafeFromArray } from "lib";

export * from "../data/data_finance_aid_fig_ODA_GDP";

const DEFAULT_DONORS = ["Norway", "United Kingdom"];

export const Data = mkGemDataDecoder(["value", "year", "ind_id"], {
  donor: io.string,
  type: io.union([io.literal("average"), io.literal("entity")]),
});

export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const { aidflows } = useConfig();

  const [, actions] = useFigureState();
  const [selectedDonors] = useMultiSelectState("donors");
  const [showComparison] = useCheckboxState("toggleComparison");

  const { entityData, averageData } = React.useMemo(() => {
    const data = dataRaw.map((x) => ({
      ...x,
      donorId: x.donor,
      donor: getAidFlowName(aidflows, "donor", x.donor),
    }));

    const entityData = data.filter((x) => x.type === "entity");
    const averageData = data.filter((x) => x.type === "average");

    return { entityData, averageData };
  }, [dataRaw, aidflows]);

  const donorKeys = React.useMemo(
    () =>
      pipe(
        entityData,
        Ar.uniq(Eq.fromEquals((a, b) => a.donorId === b.donorId)),
        unsafeFromArray,
        An.sort(
          Ord.contramap((x: typeof entityData[number]) => x.donor)(
            Ord.ordString
          )
        )
      ),
    [entityData]
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "donors",
      {
        type: "MultiSelect",
        label: i18n._(t`Donors`),
        selected: pipe(
          donorKeys,
          An.map((x) => x.donorId),
          An.filter((x) => DEFAULT_DONORS.includes(x)),
          O.chain(An.fromArray)
        ),
      },
      donorKeys.map((x) => ({ value: x.donorId, label: x.donor }))
    );

    actions.setToggleControl("toggleComparison", {
      type: "Checkbox",
      label: i18n._(t`Show donor groups`),
      selected: true,
    });
  }, [i18n, actions, env.countries, donorKeys]);

  const chartData = React.useMemo(() => {
    const entities = pipe(
      selectedDonors,
      O.fold(
        () => [],
        (selected) =>
          entityData
            .filter((x) => selected.includes(x.donorId))
            .concat(showComparison ? averageData : [])
      )
    );
    return entities;
  }, [entityData, selectedDonors, showComparison, averageData]);

  const selectedCountryLabels = React.useMemo(() => {
    return unsafeFromArray(
      pipe(
        selectedDonors,
        O.map((selected) =>
          selected.map(
            (id) =>
              entityData.find(
                (x) => x.donorId === id
              ) as unknown as typeof entityData[number]
          )
        ),
        O.fold(
          () => [],
          (entities) => entities.map((x) => x.donor)
        )
      )
    );
  }, [entityData, selectedDonors]);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    O.some(selectedCountryLabels)
  );

  return (
    <>
      <LineChart
        tLabel={(s) => s}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        x="year"
        yAnnotations={[
          {
            type: "line",
            value: 0.007,
            label: "Benchmark",
          },
        ]}
        timeParse="%Y"
        timeFormat="%Y"
        numberFormat=".1%"
        yTicks={[0, 0.002, 0.004, 0.006, 0.008, 0.01, 0.012, 0.014]}
        category="datum.donor"
        colorLegend={false}
        colorScale={colorScale}
        values={chartData}
        markStyle={(x) => (x.type === "average" ? "muted" : "normal")}
        connectLines
        showTooltip
      />
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_ODA_GDP.json"),
  csv: require("../data/data_finance_aid_fig_ODA_GDP.zip"),
  xlsx: require("../data/data_finance_aid_fig_ODA_GDP.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
