import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "GPITREND",
  title: /*i18n*/ {
    id: "fig.equity.GPITREND.title",
    defaults: `Adjusted gender parity index for gross enrolment ratios by level`,
  },
  caption: /*i18n*/ {
    id: "fig.equity.GPITREND.caption",
    defaults: `Progress towards gender parity varied by country`,
  },
  source: /*i18n*/ {
    id: "fig.equity.GPITREND.source",
    defaults: `UIS database`,
  },
};
