import css from "@emotion/css";
import React, { PropsWithChildren } from "react";
import * as M from "../materials";

/**
 * This component can be used to force content to display in a specific ratio.
 * This is useful in cases where 'vw' is not really what is needed. This fits
 * into it's parents width and scales accordingly while clipping the children to
 * fit. As it's filling the width, the `yOffset` can be used to adjust the content
 * vertically.
 *
 * Based on https://codesandbox.io/s/aspectratiodiv-mnbys
 */
export const AspectRatioContainer = ({
  ratio = [1, 1],
  offsetY = 0,
  children,
}: PropsWithChildren<{ ratio?: [number, number]; offsetY?: number }>) => {
  const [w, h] = ratio;
  return (
    <div
      css={[
        css`
          position: relative;
          overflow: hidden;
          width: 100%;
          height: auto;
        `,
        M.onlyIE(
          css`
            height: 100%;
          `
        ),
      ]}
    >
      <svg
        role="presentation"
        style={{ width: "100%" }}
        viewBox={`0 0 ${w} ${h}`}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          transform: `translateY(${offsetY}%)`,
        }}
      >
        {children}
      </div>
    </div>
  );
};
