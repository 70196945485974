import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { format } from "d3-format";
import { tuple } from "fp-ts/lib/function";
import React from "react";
import { ChartGrid, StackedBar } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_learning_fig_LITPROF";
import { ChartRenderer, mkGemDataDecoder, useNamedCountries } from "../domain";
import { useTheme } from "../hooks";
import { insertionOrderSet, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, identity, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_learning_fig_LITPROF";

const Survey = io.keyof({
  "STEP (urban areas)": null,
  PIAAC: null,
});
type Survey = io.TypeOf<typeof Survey>;

const surveyLookup: Record<Survey, MessageDescriptor> = {
  "STEP (urban areas)": t(
    "fig.learning.LITPROF.STEP"
  )`Standardized Test For English Proficiency`,
  PIAAC: t("fig.learning.LITPROF.PIAAC")`The Survey of Adult Skills`,
};

const TargetLevel = io.keyof({
  "Below level 1": null,
  "Level 1": null,
  "Level 2": null,
  "Level 3 and above": null,
});
type TargetLevel = io.TypeOf<typeof TargetLevel>;

const targetLevelLookup: Record<TargetLevel, MessageDescriptor> = {
  "Below level 1": t("fig.learning.LITPROF.LevelBelow1")`Below level 1`,
  "Level 1": t("fig.learning.LITPROF.Level1")`Level 1`,
  "Level 2": t("fig.learning.LITPROF.Level2")`Level 2`,
  "Level 3 and above": t("fig.learning.LITPROF.Level3")`Level 3 and above`,
};

export const Data = mkGemDataDecoder(["iso3c", "ind_id"], {
  value: io.number,
  level: TargetLevel,
  survey: Survey,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: rawData }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();

  const data_ = useNamedCountries(rawData);
  const data = React.useMemo(() => {
    return data_.map((x) => ({
      ...x,
      survey_name: i18n._(surveyLookup[x.survey]),
      level_name: i18n._(targetLevelLookup[x.level]),
    }));
  }, [i18n, data_]);

  const chartData = React.useMemo(() => {
    type Datum = typeof data[number];

    return pipe(
      data,
      Ar.sort(
        Ord.contramap((x: Datum) =>
          tuple(
            x.survey === "PIAAC" ? -1 : 1,
            x.level === "Below level 1" ? -x.value : Infinity,
            x.level === "Level 1" ? -x.value : Infinity,
            x.level === "Level 2" ? -x.value : Infinity,
            x.level === "Level 3 and above" ? -x.value : Infinity
          )
        )(
          Ord.getTupleOrd(
            Ord.ordNumber,
            Ord.ordNumber,
            Ord.ordNumber,
            Ord.ordNumber,
            Ord.ordNumber
          )
        )
      )
    );
  }, [data]);

  type Datum = typeof chartData[number];

  const getColumn = React.useCallback((x: Datum) => x.survey_name, []);
  const getStack = React.useCallback(
    (x: Datum) =>
      x.survey === "PIAAC"
        ? x.country_name
        : `${x.country_name}, ${i18n._(t`urban`)}`,
    [i18n]
  );
  const getLayer = React.useCallback((x: Datum) => x.level_name, []);
  const getValue = React.useCallback((x: Datum) => x.value || 0, []);
  const formatY = React.useMemo(() => format(".0%"), []);
  const getColorPalette = React.useMemo(() => {
    const stati = insertionOrderSet(unsafeFromArray(data), (x) => x.level_name);
    return () => M.fromCount(M.colorRanges.diverging, stati.length);
  }, [data]);

  return pipe(
    An.fromArray(chartData),
    O.map((xs) => (
      <ChartGrid
        key={`col-${getColumn(An.head(xs))}`}
        data={xs}
        getCell={getColumn}
        columnCount={2}
        columnFractions={client.screenSDown ? [2, 1] : [4, 1]}
        minWidth={0}
      >
        {({ data, width, first }) => (
          <StackedBar
            key={"linter-map"}
            width={width}
            height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
            data={data}
            getStack={getStack}
            getLayer={getLayer}
            getLayerLabel={identity}
            getValue={getValue}
            formatX={identity}
            formatY={formatY}
            colorPalette={getColorPalette}
            showTooltip
            hideXAxisLabel
            hideYAxis={!first}
            hideLegend={!first}
          />
        )}
      </ChartGrid>
    )),
    O.toNullable
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_LITPROF.json"),
  csv: require("../data/data_learning_fig_LITPROF.zip"),
  xlsx: require("../data/data_learning_fig_LITPROF.xlsx"),
  metadata,
  Data,
  Chart,
});
