import React from "react";
import css from "@emotion/css";
import { mkStyles } from "../../../../hooks";
import * as M from "../../../../materials";
import { TrajectoriesChartProps } from "../Trajectories";
import { Orientation } from "../types";

const useStyles = mkStyles(({ client }) => {
  return {
    container: css`
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: ${M.spacing.base8(1)};
    `,
    containerHorizontal: css`
      flex-direction: row;
    `,
    containerVertical: css`
      flex-direction: column;
    `,
    label: css`
      ${M.fontChartLabel};
      color: ${M.lightText};
      display: flex;
      align-items: baseline;
      gap: ${M.spacing.base8(0.5)};
      flex-direction: ${client.isRTL ? "row-reverse" : "row"};
      justify-content: ${client.isRTL ? "flex-end" : "flex-start"};
    `,
    color: css`
      width: 8px;
      height: 8px;
    `,
    circle: css`
      border-radius: 50%;
      margin: ${M.spacing.base8(0.5)};
    `,
  };
});

interface LegendProps<A> {
  series: Array<string>;
  lineStyle: TrajectoriesChartProps<A>["lineStyle"];
  formatLegendLabel?: TrajectoriesChartProps<A>["formatLegendLabel"];
  orientation: Orientation;
}

function Legend<A>({
  series,
  lineStyle,
  orientation = "vertical",
  formatLegendLabel = (value) => value.toString(),
}: LegendProps<A>) {
  const styles = useStyles();

  return (
    <div
      css={[
        styles.container,
        orientation === "horizontal"
          ? styles.containerHorizontal
          : styles.containerVertical,
      ]}
    >
      {series.map((value: string) => {
        return (
          <div key={value} css={[styles.label]}>
            <div
              css={[
                styles.color,
                styles.circle,
                css`
                  background-color: ${lineStyle(value).stroke};
                `,
              ]}
            />
            {formatLegendLabel(value)}
          </div>
        );
      })}
    </div>
  );
}

export default Legend;
