import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "SANITATION",
  title: /*i18n*/ {
    id: "fig.quality.SANITATION.title",
    defaults: `Availability of single-sex sanitation facilities in schools`,
  },
  caption: /*i18n*/ {
    id: "fig.quality.SANITATION.caption",
    defaults: `In poorer countries, a majority of primary schools do not have single-sex (or even any) sanitation facilities.`,
  },
  source: /*i18n*/ {
    id: "fig.quality.SANITATION.source",
    defaults: `UIS database`,
  },
};
