import React from "react";
import Head from "next/head";
import { BreadCrumbData, getBreadcrumbSchema } from "lib/schema";

interface Props {
  key?: string;
  html: string;
}

export function JSONLd(props: Props) {
  const { key, html } = props;

  return (
    <Head>
      {/* Check https://nextjs.org/learn/seo/rendering-and-ranking/metadata */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: html }}
        key={key}
      />
    </Head>
  );
}

interface BreadCrumbProps {
  data: BreadCrumbData;
}

export function JSONLdBreadcrumb(props: BreadCrumbProps) {
  const { data } = props;

  return <JSONLd html={getBreadcrumbSchema(data)} />;
}
