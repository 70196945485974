import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "HERO_LEARNING",
  title: /*i18n*/ { id: "fig.learning.HERO.title", defaults: `Learning` },
  caption: /*i18n*/ {
    id: "fig.learning.HERO.caption",
    defaults: `In one out of four countries in sub-Saharan Africa, more than half of students at the end of primary school achieve below the minimum proficiency level in reading.`,
  },
  source: /*i18n*/ { id: "fig.learning.HERO.source", defaults: `UIS database` },
};
