import css from "@emotion/css";
import styled from "@emotion/styled";
import { MDXProvider } from "@mdx-js/react";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";

const useStyles = mkStyles(({ spacing: { base8 } }) => {
  return {
    root: css`
      grid-column: ${M.layoutAreas.main};
      padding: ${base8(8)} 0;
    `,
  };
});

const useComponents = mkStyles(() => {
  return {
    p: styled.p`
      ${M.fontBody1};
    `,
    a: styled.a`
      ${M.linkBody};
      font-weight: 600;
    `,
  };
});

export interface LandingProps {
  title?: React.ReactNode;
}

export const LandingText = ({ children }: $PropsWithChildren<LandingProps>) => {
  const styles = useStyles();

  return (
    <MDXProvider components={useComponents()}>
      <div css={styles.root}>{children}</div>
    </MDXProvider>
  );
};
