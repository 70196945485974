import css from "@emotion/css";
import styled from "@emotion/styled";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";

export const HeroTooltipContainer = styled.div`
  position: relative;
  border: 1px solid red;
`;

export const HeroTooltip = ({
  children,
  title,
  position,
}: $PropsWithChildren<{
  title: React.ReactNode;
  position: "today" | "timeseries" | "todayFinance" | "timeseriesFinance";
}>) => {
  const styles = useStyles();
  return (
    <div css={[styles.root.base, styles.root[position]]}>
      <div css={[styles.content.base, styles.content[position]]}>
        <h3 css={styles.title}>{title}</h3>
        <p>{children}</p>
      </div>
    </div>
  );
};

const useStyles = mkStyles(({ activeTheme, utils, spacing: { base8 } }) => {
  return {
    root: {
      base: css`
        position: absolute;
        width: 230px;
        padding-bottom: ${base8(1.5)};
      `,
      today: css`
        top: ${base8(1.5)};
        left: 20px;
        transform: translate(-50%, -100%);

        html[dir="rtl"] & {
          transform: translate(${base8(-7)}, -100%);
        }

        @media ${M.bpDown("l")} {
          transform: translate(${base8(-7)}, -100%);
        }

        @media ${M.bpDown("s")} {
          transform: translate(${base8(-5)}, -100%);

          html[dir="rtl"] & {
            transform: translate(${base8(-5)}, -100%);
          }
        }
      `,
      todayFinance: css`
        top: 41.5%;
        left: 20px;
        transform: translate(${base8(-5)}, -100%);

        @media ${M.bpDown("l")} {
          top: 40%;
          transform: translate(${base8(-7)}, -100%);
        }

        @media ${M.bpDown("m")} {
          top: 40%;
        }

        @media ${M.bpDown("s")} {
          top: ${base8(3)};
          transform: translate(${base8(-5)}, ${base8(1)});
        }
      `,
      timeseries: css`
        top: ${base8(1.5)};
        right: 0;
        transform: translate(${base8(6)}, -100%);

        @media ${M.bpDown("s")} {
          transform: translate(${base8(1)}, -100%);
        }
      `,
      timeseriesFinance: css`
        top: 6%;
        right: 0;
        transform: translate(${base8(1)}, 0);

        @media ${M.bpDown("s")} {
          top: ${base8(2)};
          transform: translate(${base8(1)}, -100%);
        }
      `,
    },
    content: {
      base: css`
        ${M.fontTable};

        position: relative;
        background: ${activeTheme.textColor};
        border-radius: ${base8(0.5)};
        padding: ${base8(1)} ${base8(1.5)};
        color: ${utils.getContrastText(activeTheme.textColor, {
          light: M.whiteText,
          dark: activeTheme.background.dark,
        })};

        &::after {
          position: absolute;
          top: 100%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          pointer-events: none;
          border-top-color: ${activeTheme.textColor};
          border-width: ${base8(1)};
          margin-left: ${base8(-1)};
        }
      `,
      today: css`
        &::after {
          left: 50%;

          html[dir="rtl"] & {
            left: ${base8(7)};
          }

          @media ${M.bpDown("l")} {
            left: ${base8(7)};
          }

          @media ${M.bpDown("s")} {
            left: ${base8(5)};

            html[dir="rtl"] & {
              left: ${base8(5)};
            }
          }
        }
      `,
      todayFinance: css`
        &::after {
          left: ${base8(5)};

          @media ${M.bpDown("l")} {
            left: ${base8(7)};
          }

          @media ${M.bpDown("s")} {
            left: ${base8(5)};
          }
        }
      `,
      timeseries: css`
        &::after {
          right: ${base8(9)};

          @media ${M.bpDown("s")} {
            right: ${base8(4)};
          }
        }
      `,
      timeseriesFinance: css`
        &::after {
          right: ${base8(4)};
        }
      `,
    },
    title: css`
      ${M.fontTable};
      font-weight: bold;
    `,
  };
});
