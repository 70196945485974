import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "EGRA",
  title: /*i18n*/ {
    id: "fig.learning.EGRA.title",
    defaults: `Ability to read at least one word of connected text in early grades`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.EGRA.caption",
    defaults: `In some countries, many children cannot read even a single word after several years of schooling.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.EGRA.source",
    defaults: `USAID Early Grade Reading Barometer`,
  },
};
