import { getRoute, RAlias, REnv, RQuery } from "../routes";
import { useQueryParamsWithDefault } from "./useQueryParams";

export const usePageLink = <R extends RAlias>(
  nextAlias: R,
  query: RQuery<R>
) => {
  const { alias, locale } = useQueryParamsWithDefault(REnv);

  const mkRoute = getRoute(nextAlias);

  return [
    mkRoute({ alias: nextAlias, locale, ...query }),
    { isActive: alias === nextAlias },
  ] as const;
};
