import { jsx } from "@emotion/core";
import css, { SerializedStyles } from "@emotion/css";
import React from "react";
import * as IE from "./ie";
import * as L from "./layout";
import * as S from "./spacing";
import { bp } from "./responsive/breakpoints";

export const Grid = ({
  children,
  layout,
  styles,
  as = "div",
}: $PropsWithChildren<{
  layout: L.Layout;
  styles?: SerializedStyles;
  as?: React.ElementType;
}>) => {
  const wrapperProps = React.useMemo(() => {
    return {
      css: css([
        baseStyles,
        ...L.foldLayout(layout, {
          home: () => [homeGrid],
          simple: () => [simpleGrid, IE.onlyIE(simpleGridFallback)],
          article: () => [
            getArticleGrid({ narrow: false }),
            IE.onlyIE(articleGridFallback),
          ],
          articleNarrow: () => [
            getArticleGrid({ narrow: true }),
            IE.onlyIE(articleGridFallback),
          ],
          full: () => [fullGrid],
          explorer: () => [explorerGrid],
          focus: () => [focusGrid, IE.onlyIE(simpleGridFallback)],
          none: () => [css``],
        }),
        styles,
      ]),
    };
  }, [layout, styles]);

  return jsx(as, wrapperProps, children);
};

const baseStyles = css`
  grid-column: ${L.layoutAreas.full} !important;
  margin-top: 0 !important; /* This prevents nested grids from getting a top margin */

  & > * + * {
    margin-top: 1.5rem;
  }

  & > * {
    grid-column: ${L.layoutAreas.main};
  }
`;

// ----------------------------------------------------------------------------
// Grid styles

const xsBase = css`
  grid-column-gap: 16px;
  grid-template-columns:
    [ ${L.layoutAreas.fullStart} ]
    0
    [ ${L.layoutAreas.mainStart} ${L.layoutAreas.wideStart} ${L.layoutAreas
      .figureStart} ${L.layoutAreas.sideStart} ]
    1fr
    1fr
    [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.wideEnd} ${L.layoutAreas
      .figureEnd} ${L.layoutAreas.sideEnd} ]
    0
    [ ${L.layoutAreas.fullEnd} ];
`;

const sBase = css`
  grid-column-gap: 24px;
  grid-template-columns:
    [ ${L.layoutAreas.fullStart} ]
    0
    [ ${L.layoutAreas.wideStart} ${L.layoutAreas.figureStart} ]
    1fr
    [ ${L.layoutAreas.mainStart} ${L.layoutAreas.sideStart} ]
    repeat(5, minmax(0, 96px))
    [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.sideEnd} ]
    1fr
    [ ${L.layoutAreas.wideEnd} ${L.layoutAreas.figureEnd} ]
    0
    [ ${L.layoutAreas.fullEnd} ];
`;

const mBase = css`
  grid-column-gap: 24px;
  grid-template-columns:
    [ ${L.layoutAreas.fullStart} ]
    0
    [ ${L.layoutAreas.wideStart} ${L.layoutAreas.figureStart} ]
    1fr
    [ ${L.layoutAreas.mainStart} ]
    10fr
    [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.figureEnd} ${L.layoutAreas
      .sideStart} ]
    repeat(2, 80px)
    [ ${L.layoutAreas.sideEnd} ${L.layoutAreas.wideEnd} ]
    0
    [ ${L.layoutAreas.fullEnd} ];
`;

const mCenteredBase = css`
  grid-column-gap: 24px;
  grid-template-columns:
    [ ${L.layoutAreas.fullStart} ]
    0
    [ ${L.layoutAreas.wideStart} ${L.layoutAreas.figureStart} ]
    1fr
    [ ${L.layoutAreas.mainStart} ]
    repeat(6, 1fr)
    [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.figureEnd} ${L.layoutAreas
      .sideStart} ]
    repeat(1, 1fr)
    [ ${L.layoutAreas.sideEnd} ${L.layoutAreas.wideEnd} ]
    0
    [ ${L.layoutAreas.fullEnd} ];
`;

const simpleGrid = css`
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  display: grid;
  align-items: start;

  ${xsBase};

  @media (min-width: ${bp.s}px) {
    ${sBase};
  }

  @media (min-width: ${bp.m}px) {
    ${mBase};
  }

  @media (min-width: ${bp.l}px) {
    grid-column-gap: 24px;
    grid-template-columns:
      [ ${L.layoutAreas.fullStart} ]
      1fr
      minmax(0, 96px)
      [ ${L.layoutAreas.mainStart} ${L.layoutAreas.wideStart} ${L.layoutAreas
        .figureStart} ]
      repeat(6, minmax(0, 96px))
      [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.wideEnd} ${L.layoutAreas
        .figureEnd} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.sideStart} ]
      repeat(3, minmax(0, 96px))
      [ ${L.layoutAreas.sideEnd} ]
      minmax(0, 96px)
      1fr
      [ ${L.layoutAreas.fullEnd} ];
  }
`;

const simpleGridFallback = css`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`;

const focusGrid = css`
  margin-bottom: ${S.spacing.base8(12)};
  margin-left: 0;
  margin-right: 0;
  display: grid;
  align-items: start;
  margin-top: ${S.spacing.base8(12)} !important;

  ${xsBase};

  @media (min-width: ${bp.l}px) {
    margin-top: ${S.spacing.base8(18)} !important;
    grid-column-gap: 24px;
    grid-template-columns:
      [ ${L.layoutAreas.fullStart} ]
      1fr
      minmax(0, 96px)
      [ ${L.layoutAreas.mainStart} ${L.layoutAreas.wideStart} ${L.layoutAreas
        .figureStart} ]
      repeat(6, minmax(0, 96px))
      [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.figureEnd} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.sideStart} ]
      repeat(3, minmax(0, 96px))
      [ ${L.layoutAreas.sideEnd} ${L.layoutAreas.wideEnd} ]
      minmax(0, 96px)
      1fr
      [ ${L.layoutAreas.fullEnd} ];
  }
`;

const fullGrid = css`
  display: grid;
  align-items: start;

  ${xsBase};

  @media (min-width: ${bp.s}px) {
    ${sBase};
  }

  @media (min-width: ${bp.m}px) {
    ${mCenteredBase};
  }

  @media (min-width: ${bp.l}px) {
    grid-column-gap: 24px;
    grid-template-columns:
      [ ${L.layoutAreas.fullStart} ]
      1fr
      [ ${L.layoutAreas.wideStart} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.mainStart} ${L.layoutAreas.figureStart} ]
      repeat(9, minmax(0, 96px))
      [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.figureEnd} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.wideEnd} ]
      1fr
      [ ${L.layoutAreas.fullEnd} ];
  }
`;

const explorerGrid = css`
  display: grid;
  align-items: start;

  ${xsBase};

  @media (min-width: ${bp.s}px) {
    ${sBase};
  }

  @media (min-width: ${bp.m}px) {
    ${mCenteredBase};
  }

  @media (min-width: ${bp.l}px) {
    grid-column-gap: 24px;
    grid-template-columns:
      [ ${L.layoutAreas.fullStart} ]
      1fr
      [ ${L.layoutAreas.wideStart} ]
      repeat(2, minmax(80px, 96px))
      [ ${L.layoutAreas.mainStart} ${L.layoutAreas.figureStart}]
      repeat(6, minmax(80px, 96px))
      [ ${L.layoutAreas.mainEnd} ${L.layoutAreas.figureEnd} ]
      repeat(2, minmax(80px, 96px))
      [ ${L.layoutAreas.wideEnd} ]
      1fr
      [ ${L.layoutAreas.fullEnd} ];
  }
`;

const getArticleGrid = ({ narrow }: { narrow: boolean }) => css`
  display: grid;
  align-items: start;

  ${xsBase};

  @media (min-width: ${bp.s}px) {
    ${sBase};
  }

  @media (min-width: ${bp.m}px) {
    ${mBase};
  }

  @media (min-width: ${bp.l}px) {
    grid-column-gap: 24px;
    grid-template-columns:
      [ ${L.layoutAreas.fullStart} ]
      1fr
      minmax(0, 96px)
      [ ${L.layoutAreas.wideStart} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.figureStart} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.mainStart} ]
      ${narrow ? "" : "repeat(6, minmax(80px, 96px))"}
      [ ${L.layoutAreas.mainEnd} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.figureEnd} ${L.layoutAreas.sideStart} ]
      96px
      [ ${L.layoutAreas.wideEnd} ]
      96px
      [ ${L.layoutAreas.sideEnd} ]
      1fr
      [ ${L.layoutAreas.fullEnd} ];
  }
`;

const articleGridFallback = css`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`;

const homeGrid = css`
  display: grid;
  align-items: start;

  transition: 0.8s background-color;

  background: var(--homegrid-background, white);

  ${xsBase};

  @media (min-width: ${bp.s}px) {
    ${sBase};
  }

  @media (min-width: ${bp.m}px) {
    ${mCenteredBase};
  }

  @media (min-width: ${bp.l}px) {
    grid-column-gap: 24px;
    grid-template-columns:
      [ ${L.layoutAreas.fullStart} ]
      1fr
      repeat(2, minmax(0, 96px))
      [ ${L.layoutAreas.wideStart} ${L.layoutAreas.figureStart} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.mainStart} ]
      44em
      [ ${L.layoutAreas.mainEnd} ]
      minmax(0, 96px)
      [ ${L.layoutAreas.figureEnd} ${L.layoutAreas.wideEnd} ${L.layoutAreas
        .sideStart} ]
      repeat(2, minmax(0, 96px))
      [ ${L.layoutAreas.sideEnd} ]
      1fr
      [ ${L.layoutAreas.fullEnd} ];
  }
`;
