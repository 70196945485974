import css from "@emotion/css";

import * as M from "../../../materials";

export const lineChartDefaultStyles = {
  columnTitle: css`
    ${M.fontChartGroupTitle};
    fill: ${M.blackText};
  `,
  axisLabel: css`
    ${M.fontAxisLabel};
    fill: ${M.lightText};
  `,
  axisYLine: css`
    stroke: ${M.divider};
    stroke-width: 1px;
    shape-rendering: crispEdges;
  `,
  axisXLine: css`
    stroke: ${M.divider};
    stroke-width: 1px;
    shape-rendering: crispEdges;
  `,
  annotationLine: css`
    fill: ${M.unescoDarkBlue};
    stroke: white;
    stroke-width: 1;
  `,
  annotationBand: css`
    background: black;
    opacity: 0.06;
  `,
  annotationBandLabel: css`
    ${M.fontAxisLabel};
    fill: ${M.lightText};
    paint-order: stroke;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  `,
  annotationText: css`
    ${M.fontChartLabel};
    font-weight: bold;
    fill: ${M.unescoDarkBlue};
    paint-order: stroke;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  `,
  value: css`
    ${M.fontChartLabel};
    fill: ${M.lightText};
  `,
  valueMini: css`
    ${M.fontChartGroupTitle};
  `,
  label: css`
    ${M.fontChartLabel};
    fill: ${M.lightText};
    paint-order: stroke;
    stroke: white;
    stroke-width: ${M.spacing.base8(0.5)};
    stroke-linecap: butt;
    stroke-linejoin: miter;
  `,
  title: css`
    ${M.fontChartLabel};
    font-weight: bold;
    fill: ${M.blackText};
    paint-order: stroke;
    stroke: white;
    stroke-width: ${M.spacing.base8(0.5)};
    stroke-linecap: butt;
    stroke-linejoin: miter;
  `,
  bandLegend: css`
    ${M.fontChartLabel};
    fill: ${M.lightText};
    white-space: nowrap;
  `,
  bandBar: css`
    display: inline-block;
    width: 24px;
    height: 11px;
    margin-bottom: ${-1};
    background-color: ${M.lightText};
    border-top: ${`4px solid ${M.divider}`};
    border-bottom: ${`4px solid ${M.divider}`};
  `,
};
