import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { useColorScaleVDP, VerticalDotPlotAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_quality_fig_SANITATION";
import {
  ChartRenderer,
  mkGemEntityDecoder,
  ordLevel,
  useNamedEntitiesWithLevel,
} from "../domain";
import { useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, identity, io, Ord } from "../prelude";

export * from "../data/data_quality_fig_SANITATION";

const yUnitLabel = t(
  "fig.quality.SANITATION.yUnitLabel"
)`Schools with sanitation facilities`;

export const Data = mkGemEntityDecoder(["ind_id", "level", "year"], {
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const entityData = useNamedEntitiesWithLevel(data);

  const chartData = React.useMemo(() => {
    type Dtm = typeof entityData[number];

    return unsafeFromArray(
      Ar.sortBy([
        Ord.contramap((x: Dtm) => x.level)(ordLevel),
        Ord.contramap((x: Dtm) => x.value)(Ord.ordNumber),
      ])(entityData)
    );
  }, [entityData]);
  type Datum = typeof chartData[number];

  const getX = React.useCallback((x: Datum) => x.entity_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.level_name, []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      showTooltip
      showLegend
      dotStyle={"small"}
      labelStyleX="none"
      yUnitLabel={i18n._(yUnitLabel)}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_quality_fig_SANITATION.json"),
  csv: require("../data/data_quality_fig_SANITATION.zip"),
  xlsx: require("../data/data_quality_fig_SANITATION.xlsx"),
  metadata,
  Data,
  Chart,
});
