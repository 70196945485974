import React from "react";
import { useContentRect } from "../hooks";
import { Trajectories } from "./lib/Trajectories/";
import { TrajectoriesChartProps } from "./lib/Trajectories/Trajectories";

export function TrajectoriesChart<A extends Record<string, unknown>>(
  props: Omit<TrajectoriesChartProps<A>, "width">
) {
  const [ref, contentRect] = useContentRect();

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {contentRect.width > 0 && (
        <Trajectories {...props} width={contentRect.width} />
      )}
    </div>
  );
}
