import { I18n, MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { format } from "d3-format";
import React from "react";
import { ParitycountAuto } from "../charts-motion/paritycount";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_equity_fig_PARITYCOUNT";
import {
  ChartRenderer,
  getLevelName,
  Level,
  mkGemDataDecoder,
  ordLevel,
} from "../domain";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import { Ar, io, Ord } from "../prelude";

export * from "../data/data_equity_fig_PARITYCOUNT";

const formatValue = format(".0%");

// -----------------------------------------------------------------------------
// Parity

const parityOrder = {
  "< 80 girls/100 boys": 0,
  "80-90 girls/100 boys": 1,
  "90-97 girls/100 boys": 2,
  "gender parity": 3,
  "103-110 girs/100 boys": 4,
  "110-120 girls/100 boys": 5,
  ">120 girls/100 boys": 6,
};

const Parity = io.keyof(parityOrder);
type Parity = io.TypeOf<typeof Parity>;

function foldParity<A>(parity: Parity, pattern: { [K in Parity]: () => A }) {
  return pattern[parity]();
}

const getParityIndex = (k: Parity) => parityOrder[k];
const ordParity = Ord.contramap((parity: Parity) => getParityIndex(parity))(
  Ord.ordNumber
);

const parityLookup: Record<Parity, MessageDescriptor> = {
  "< 80 girls/100 boys": t(
    "fig.equity.PARITYCOUNT.<80/100"
  )`Fewer than 80 girls per 100 boys`,
  "80-90 girls/100 boys": t(
    "fig.equity.PARITYCOUNT.80-90/100"
  )`80–90 girls per 100 boys`,
  "90-97 girls/100 boys": t(
    "fig.equity.PARITYCOUNT.90-97/100"
  )`90–97 girls per 100 boys`,
  "gender parity": t("fig.equity.PARITYCOUNT.100/100")`Gender parity`,
  "103-110 girs/100 boys": t(
    "fig.equity.PARITYCOUNT.103-110/100"
  )`103–110 girls per 100 boys`,
  "110-120 girls/100 boys": t(
    "fig.equity.PARITYCOUNT.110-120/100"
  )`110–120 girls per 100 boys`,
  ">120 girls/100 boys": t(
    "fig.equity.PARITYCOUNT.>120/100"
  )`More than 120 girls per 100 boys`,
};
const getParityName = (i18n: I18n, parity: Parity) =>
  i18n._(parityLookup[parity]);

type ParitySide = "girl" | "boy" | "parity";
const ordParitySide = Ord.contramap((side: ParitySide) =>
  side === "boy" ? 0 : side === "parity" ? 1 : 2
)(Ord.ordNumber);

function getParitySide(parity: Parity) {
  return foldParity<ParitySide>(parity, {
    "< 80 girls/100 boys": () => "boy",
    "80-90 girls/100 boys": () => "boy",
    "90-97 girls/100 boys": () => "boy",
    "gender parity": () => "parity",
    "103-110 girs/100 boys": () => "girl",
    "110-120 girls/100 boys": () => "girl",
    ">120 girls/100 boys": () => "girl",
  });
}

function foldParitySide<A>(
  side: ParitySide,
  pattern: { [K in ParitySide]: () => A }
) {
  return pattern[side]();
}

// -----------------------------------------------------------------------------

export const Data = mkGemDataDecoder(["year", "level"], {
  value: io.number,
  parity: Parity,
  measure: io.string,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const data = React.useMemo(() => {
    const arr = dataRaw.reduce((acc, x) => {
      const level_name = getLevelName(i18n, x.level);
      const parity_name = getParityName(i18n, x.parity);
      const parity_side = getParitySide(x.parity);
      return acc.concat(
        foldParitySide(parity_side, {
          girl: () => [{ ...x, level_name, parity_name, parity_side }],
          boy: () => [{ ...x, level_name, parity_name, parity_side }],
          parity: () => [
            {
              ...x,
              level_name,
              parity_name,
              parity_side: "girl",
              value: x.value / 2,
            },
            {
              ...x,
              level_name,
              parity_name,
              parity_side: "boy",
              value: x.value / 2,
            },
          ],
        })
      );
    }, [] as Array<Data[number] & { parity_name: string; parity_side: ParitySide }>);

    type D = typeof arr[number];

    return Ar.sortBy([
      Ord.contramap((x: D) => x.level)(ordLevel),
      Ord.contramap((x: D) => x.year)(Ord.ordNumber),
      Ord.contramap((x: D) => x.parity_side)(ordParitySide),
      Ord.contramap((x: D) => x.parity)(ordParity),
    ])(arr);
  }, [i18n, dataRaw]);

  const chartData = unsafeFromArray(data);
  type Datum = typeof chartData[number];

  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getSide = React.useCallback(
    (x: Datum) => (x.parity_side === "boy" ? "left" : "right"),
    []
  );
  const getColor = React.useCallback((x: Datum) => x.parity_name, []);
  const getYear = React.useCallback((x: Datum) => x.year, []);
  const getGroup = React.useCallback((x: Datum) => x.level, []);
  const formatGroup = React.useCallback(
    (x: string) => getLevelName(i18n, x as Level),
    [i18n]
  );

  return (
    <ParitycountAuto
      data={chartData}
      getValue={getValue}
      getSide={getSide}
      getColor={getColor}
      getYear={getYear}
      getGroup={getGroup}
      formatGroup={formatGroup}
      formatValue={formatValue}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_equity_fig_PARITYCOUNT.json"),
  csv: require("../data/data_equity_fig_PARITYCOUNT.zip"),
  xlsx: require("../data/data_equity_fig_PARITYCOUNT.xlsx"),
  metadata,
  Data,
  Chart,
});
