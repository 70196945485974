import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID_LICS",
  title: /*i18n*/ {
    id: "fig.finance.AID_LICS.title",
    defaults: `Share of aid to education going to low income countries`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.AID_LICS.caption",
    defaults: `Less and less education aid is going to low income countries.`,
  },
  source: /*i18n*/ {
    id: "fig.finance.AID_LICS.source",
    defaults: `GEM Report team analysis based on OECD CRS`,
  },
};
