import css from "@emotion/css";
import React from "react";
import { mkStylesWithProps } from "../../hooks";
import * as M from "../../materials";

const useTitleStyles = mkStylesWithProps((theme, props: TitleProps) => {
  return {
    root: css`
      ${theme.typography.preset.fontTera};
      grid-column: ${M.layoutAreas.main};
      padding-top: ${props.padded
        ? theme.spacing.base8(12)
        : theme.spacing.base8(4)};
      padding-bottom: ${props.padded
        ? theme.spacing.base8(15)
        : theme.spacing.base8(2)};

      @media ${theme.breakpoints.down("l")} {
        padding: ${props.padded
            ? theme.spacing.base8(4)
            : theme.spacing.base8(0)}
          0px;
      }
    `,
  };
});

export interface TitleProps extends $PropsWithChildren {
  padded?: boolean;
}

export const Title = (props: TitleProps) => {
  const styles = useTitleStyles(props);
  return <h1 css={styles.root}>{props.children}</h1>;
};
