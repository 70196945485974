
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Правила и условия";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">Правила и условия</Title>
    <p>{`Группа Всемирного доклада по мониторингу образования поощряет использование сайта «Scoping Progress in Education (SCOPE)» только в информационных целях. Все содержание данного сайта защищено авторским правом. Получая доступ к сайту и используя любой размещенный на нем контент, вы («Пользователь») признаете, что полностью прочитали и поняли настоящие Условия пользования и согласны с ними.`}</p>
    <p>{`Размещенная на этом сайте информация предназначена для личного или общественного некоммерческого использования и может воспроизводиться частично или полностью любым способом без взимания платы или дополнительного разрешения, если не указано иное. Все заявки на коммерческое использование и права на перевод следует направлять по адресу `}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a></p>
    <h2 {...{
      "id": "пользователи-обязаны"
    }}>{`Пользователи обязаны:`}</h2>
    <ul>
      <li parentName="ul">{`Проявлять должную осмотрительность для обеспечения точности воспроизведенных материалов;`}</li>
      <li parentName="ul">{`Указывать источник на видном месте рядом с данными, используя следующий формат:
Источник: (При необходимости «Адаптировано на основе») доклад ВДМО Scoping Progress in Education (SCOPE), `}<a parentName="li" {...{
          "href": "https://www.education-progress.org"
        }}>{`https://www.education-progress.org`}</a>{`, дата отбора цитаты.`}</li>
    </ul>
    <p>{`Следует отметить, что база данных регулярно обновляется и пересматривается. Поэтому при цитировании данных важно указывать дату их отбора.`}</p>
    <h2 {...{
      "id": "лицензии"
    }}>{`Лицензии`}</h2>
    <p>{`Мы призываем вас творчески использовать эти данные в своих и общественных интересах. Вы можете извлекать, загружать и копировать содержащуюся в наборах данных информацию, а также передавать ее третьим лицам.`}</p>
    <h2 {...{
      "id": "отсутствие-ассоциирования-или-одобрения"
    }}>{`Отсутствие ассоциирования или одобрения`}</h2>
    <p>{`Пользователь не может утверждать или подразумевать, что группа Всемирного доклада по мониторингу образования участвовала в подготовке, утверждении, одобрении или иной поддержке использования или воспроизведения им материалов. Пользователь не может претендовать на какую-либо связь с сайтом «Прогресс в области образования» (или Всемирным докладом по мониторингу образования).`}</p>
    <h2 {...{
      "id": "ссылки-на-сайт"
    }}>{`Ссылки на сайт`}</h2>
    <p>{`Внешний сайт может включать гиперссылку на любую страницу сайта «SCOPE» без получения разрешения. Однако это не должно нарушать прав интеллектуальной собственности, предусмотренных во Всемирном докладе по мониторингу образования, в частности в том, что касается названий, логотипов, авторского права или авторских прав.`}</p>
    <h2 {...{
      "id": "ограничение-ответственности"
    }}>{`Ограничение ответственности`}</h2>
    <p>{`Группа Всемирного доклада по мониторингу образования поддерживает этот сайт в целях расширения доступа общественности к данным и соответствующей информации. Она прилагает все усилия для обеспечения точности и полноты информации на этом сайте, но не гарантирует этого. Наша цель заключается в обеспечении своевременности и точности этой информации. Если до нас дойдет информация об ошибках, мы постараемся их исправить.`}</p>
    <p>{`Использованные на настоящем сайте названия и представление материала не являются выражением со стороны ЮНЕСКО какого-либо мнения относительно правового статуса какой-либо страны, территории, города или района или их соответствующих органов управления, равно как и линий их разграничения или границ.`}</p>
    <p>{`Группа Всемирного доклада по мониторингу образования несет ответственность за выбор и изложение на настоящем сайте фактов, которые могут не совпадать с позицией ЮНЕСКО и ни к чему ее не обязывают.`}</p>
    <p>{`Мы стремимся свести к минимуму последствия, вызванные техническими сбоями. Тем не менее некоторые данные или информация на сайте могут быть созданы или структурированы в файлах или форматах, которые не обеспечивают абсолютной технической надежности, и мы не можем гарантировать, что наши услуги не будут прерваны или иным образом затронуты такими проблемами. Группа Всемирного доклада по мониторингу образования не несет ответственности в связи с такими проблемами (сбой в работе, компьютерный вирус, сбой линии связи, изменение содержания и т.д.), возникшими в результате использования сайта «SCOPE» или какой-либо ссылки на внешние сайты.`}</p>
    <h2 {...{
      "id": "неприкосновенность-личной-жизни"
    }}>{`Неприкосновенность личной жизни`}</h2>
    <p>{`При посещении сайта «SCOPE» на наших серверах будет сохраняться определенная информация, такая как адреса интернет-протокола (IP), поведение при навигации по сайту, используемое программное обеспечение и проведенное на сайте время, а также другая аналогичная информация. Эта информация не идентифицирует Вас лично и будет использоваться для анализа посещаемости сайта.`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;