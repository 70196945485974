import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "OOSREL",
  title: /*i18n*/ {
    id: "fig.access.OOSREL.title",
    defaults: `School-age population, out-of-school rate and out-of-school number, 1990-2020`,
  },
  caption: /*i18n*/ {
    id: "fig.access.OOSREL.caption",
    defaults: `The number of out-of-school children in sub-Saharan Africa has barely fallen since 1990`,
  },
  source: /*i18n*/ {
    id: "fig.access.OOSREL.source",
    defaults: `GEM Report team analysis based on UIS data`,
  },
};
