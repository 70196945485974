import css from "@emotion/css";
import React, { forwardRef } from "react";
import { mkStyles, mkStylesWithProps } from "../hooks";

export const buttonReset = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize "line-height". Cannot be changed from "normal" in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable "input" types in iOS */
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

const useStylesButtonGroup = mkStyles((theme) => ({
  root: css`
    display: flex;
    flex-grow: 1;
    height: ${theme.spacing.base8(4)};
    max-width: 100%;
    margin-bottom: ${theme.spacing.base8(1)};

    & > button + button {
      border-left: 0;
    }

    & > button:first-of-type {
      border-top-left-radius: ${theme.spacing.base1(4)};
      border-bottom-left-radius: ${theme.spacing.base1(4)};
    }

    & > button:last-of-type {
      border-top-right-radius: ${theme.spacing.base1(4)};
      border-bottom-right-radius: ${theme.spacing.base1(4)};
    }

    html[dir="rtl"] & {
      & > button + button {
        border-right: 0;
        border-left: 1px solid ${theme.palette.grayscale[4]};
      }

      & > button:first-of-type {
        border-top-right-radius: ${theme.spacing.base1(4)};
        border-bottom-right-radius: ${theme.spacing.base1(4)};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > button:last-of-type {
        border-top-left-radius: ${theme.spacing.base1(4)};
        border-bottom-left-radius: ${theme.spacing.base1(4)};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  `,
}));

const useStylesButton = mkStylesWithProps((theme, props: ButtonProps) => ({
  root: css`
    ${buttonReset};
    background: ${props.inverted
      ? theme.palette.text.white
      : theme.palette.brand.marine.main};
    color: ${!props.inverted
      ? theme.palette.text.white
      : theme.palette.brand.marine.main};
    border: 1px solid
      ${props.inverted
        ? theme.palette.grayscale[4]
        : theme.palette.brand.marine.main};
    padding: ${theme.spacing.base1(6)} ${theme.spacing.base1(12)};
    border-radius: ${props.rounded ? theme.spacing.base1(4) : "none"};
    outline: none;
    cursor: pointer;
    width: ${props.inline ? "auto" : "100%"};
    flex-grow: ${props.inline ? "0" : "1"};
    justify-content: center;
    font-size: ${theme.typography.preset.fontButton};
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:focus,
    &:hover,
    &[data-focus] {
      background: ${props.inverted
        ? theme.palette.grayscale[3]
        : theme.palette.brand.marine.dark};
      color: ${props.inverted
        ? theme.palette.brand.marine.main
        : theme.palette.text.white};
      border-right: 1px solid
        ${props.inverted
          ? theme.palette.grayscale[4]
          : theme.palette.brand.marine.dark};
      border-top: 1px solid
        ${props.inverted
          ? theme.palette.grayscale[4]
          : theme.palette.brand.marine.dark};
      border-bottom: 1px solid
        ${props.inverted
          ? theme.palette.grayscale[4]
          : theme.palette.brand.marine.dark};
    }

    @media ${theme.breakpoints.up("s")} {
      min-width: ${theme.spacing.base8(15)};
    }
  `,
}));

export interface ButtonProps {
  children: $Children;
  onClick: () => void;
  inverted?: boolean;
  rounded?: boolean;
  inline?: boolean;
  className?: string;
}

export const ButtonGroup = ({ children }: { children: $Children }) => {
  const styles = useStylesButtonGroup();
  return <div css={styles.root}>{children}</div>;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const styles = useStylesButton(props);
    const title =
      typeof props.children === "string" ? props.children : undefined;
    return (
      <button
        ref={ref}
        css={styles.root}
        className={props.className}
        onClick={props.onClick}
        title={title}
      >
        {props.children}
      </button>
    );
  }
);

export interface Choice<T> {
  value: string;
  label?: React.ReactNode;
  data?: T;
}

export interface ButtonGroupAutoProps<A> {
  items: Array<Choice<A>>;
  selectedItem: string;
  onChange: (selectedItem: Choice<unknown>) => void;
}
export function ButtonGroupAuto<A>({
  items,
  selectedItem,
  onChange,
}: ButtonGroupAutoProps<A>) {
  return (
    <ButtonGroup>
      {items.map((item) => (
        <Button
          key={item.value}
          inverted={selectedItem !== item.value}
          onClick={() => onChange(item)}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
