import css from "@emotion/css";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";

const useStyles = mkStyles(
  ({ spacing: { base1, base8 }, palette, typography }) => {
    return {
      root: css`
        grid-column: ${M.layoutAreas.main};
        display: flex;
        align-items: center;
        max-width: 22em;
        padding: ${base8(1)} ${base8(2)};
        background-color: white;
        border: 1px solid ${M.divider};
        border-radius: ${base1(2)};

        ${typography.preset.fontBody2};
        color: ${palette.action.main};

        &:hover {
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.18);
          color: ${palette.action.dark};
        }

        /* Clearfix */
        &::before,
        &::after {
          content: " ";
          display: table;
        }
        &::after {
          clear: both;
        }
      `,
      image: css`
        width: ${base8(5)};
        height: ${base8(5)};
        margin: ${base8(1)} ${base8(2)} ${base8(1)} 0;
      `,
      label: css`
        display: block;
      `,
    };
  }
);

type Variant = "gem" | "sdg4";

export interface LinkBlockProps {
  title: string;
  url: string;
  variant: Variant;
}

const foldVariant = (variant: Variant) =>
  ({
    gem: require("./files/bg_logo_gem.svg?raw"),
    sdg4: require("./files/bg_logo_sdg-4.svg?raw"),
  }[variant]);

export const LinkBlock = ({ title, url, variant }: LinkBlockProps) => {
  const styles = useStyles();

  return (
    <a href={url} css={styles.root}>
      <img alt="" css={styles.image} src={foldVariant(variant)} />
      <span css={styles.label}>{title}</span>
    </a>
  );
};
