import { t } from "@lingui/macro";
import { MessageDescriptor } from "@lingui/core";
import { SKILL, FACET } from "lib/explorer-gql/generated";
import { FigureControlItem } from "./figure-state";
import * as M from "../materials";

// -----------------------------------------------------------------------------
// Goal Annotation

export const goalRule = (
  i18n: $IntentionalAny,
  facet: FACET,
  skill: SKILL,
  allGrades = false
) => ({
  start: {
    datum: {
      x: facet === "GRADE" ? 3 : 9,
      y: 1,
    },
    symbol: true,
    annotation: {
      text: i18n._(goalAnnotation[skill][facet]),
      isAlwaysVisible: true,
    },
  },
  end: {
    datum: {
      x: facet === "GRADE" ? (allGrades ? 10 : 6) : 14,
      y: 1,
    },
    symbol: false,
  },
  style: {
    stroke: M.q06Palette[6],
    strokeDasharray: "6 8",
    strokeWidth: 3,
  },
});

// -----------------------------------------------------------------------------
// Translations Lookups

export const goalAnnotation: Record<SKILL, Record<FACET, MessageDescriptor>> = {
  LIT: {
    AGE: t(
      "fig.learning.trajectories.goalAnnotation.lit.age"
    )`SDG 4.1.1a calls for all students to have basic reading skills by Grade 3 (typically age 9)`,
    GRADE: t(
      "fig.learning.trajectories.goalAnnotation.lit.grade"
    )`SDG 4.1.1a calls for all students to have basic reading skills by Grade 3`,
  },
  NUM: {
    AGE: t(
      "fig.learning.trajectories.goalAnnotation.num.age"
    )`SDG 4.1.1a calls for all students to have basic mathematics skills by Grade 3 (typically age 9)`,
    GRADE: t(
      "fig.learning.trajectories.goalAnnotation.num.grade"
    )`SDG 4.1.1a calls for all students to have basic mathematics skills by Grade 3`,
  },
};

export const yAxisTitleLookup: Record<SKILL, MessageDescriptor> = {
  LIT: t(
    "fig.learning.trajectories.yAxisTitle.lit"
  )`reading at typical Grade 2-3 level`,
  NUM: t(
    "fig.learning.trajectories.yAxisTitle.num"
  )`math skills at typical Grade 2-3 level`,
};

export const xAxisTitleLookup: Record<FACET, MessageDescriptor> = {
  AGE: t("fig.learning.trajectories.xAxisTitle.age")`Age`,
  GRADE: t("fig.learning.trajectories.xAxisTitle.grade")`Grade`,
};

export const xAxisSubtitleLookup: Record<FACET, MessageDescriptor> = {
  AGE: "",
  GRADE: t(
    "fig.learning.trajectories.xAxisSubtitle.grade"
  )`Highest Grade Attained`,
};

// Data Explorer Controls Options

export const verticalAxisControlLabel = t(
  "fig.learning.trajectories.explorer.controls.simulations.verticalAxis.title"
)`Vertical Axis`;

export const countryControlTitle = t(
  "fig.learning.trajectories.explorer.controls.country.title"
)`Country`;

export const comparisonCountryControlTitle = t(
  "fig.learning.trajectories.explorer.controls.comparisonCountry.title"
)`Comparison country`;

export const countryControlPlaceholder = t(
  "fig.learning.trajectories.explorer.controls.country.placeholder"
)`Select country`;

export const simulationControlsLabels = (i18n: $IntentionalAny) => ({
  label: i18n._(
    t(
      "fig.learning.trajectories.explorer.controls.simulation.label"
    )`Simulate policies`
  ),
  title: i18n._(
    t("fig.learning.trajectories.explorer.controls.simulation.title")`Simulate`
  ),
});

export const verticalAxisControlItems = (
  i18n: $IntentionalAny
): Array<FigureControlItem> => [
  {
    value: "LIT",
    label: i18n._(
      t("fig.learning.trajectories.explorer.controls.verticalAxis.lit")`Reading`
    ),
  },
  {
    value: "NUM",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.verticalAxis.num"
      )`Mathematics`
    ),
  },
];

export const horizontalAxisControlItems = (
  i18n: $IntentionalAny
): Array<FigureControlItem> => [
  {
    value: "GRADE",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.horizontalAxis.grade"
      )`Grade`
    ),
  },
  {
    value: "AGE",
    label: i18n._(
      t("fig.learning.trajectories.explorer.controls.horizontalAxis.age")`Age`
    ),
  },
];

export const viewControlItems = (
  i18n: $IntentionalAny
): Array<FigureControlItem> => [
  {
    value: "countries",
    label: i18n._(
      t("fig.learning.trajectories.explorer.controls.view.countries")`Countries`
    ),
  },
  {
    value: "disparities",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.view.disparities"
      )`Groups inside country`
    ),
  },
];

export const countriesSimulationControlItems = (
  i18n: $IntentionalAny
): Array<FigureControlItem> => [
  {
    value: "SIMULATION_ACCESS",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.simulation.countries.access"
      )`Access at 100%`
    ),
  },
  {
    value: "SIMULATION_LEARNING",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.simulation.countries.learning"
      )`Learning of comparison country`
    ),
  },
];

export const disparitiesSimulationControlItems = (
  i18n: $IntentionalAny
): Array<FigureControlItem> => [
  {
    value: "SIMULATION_ACCESS",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.simulation.disparities.access"
      )`Equal access`
    ),
  },
  {
    value: "SIMULATION_LEARNING",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.simulation.disparities.learning"
      )`Equal learning`
    ),
  },
];

export const disparityGroupsControlItems = (
  i18n: $IntentionalAny
): Array<FigureControlItem> => [
  {
    value: "WEALTH",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.groups.wealth"
      )`Rich / Poor`
    ),
  },
  {
    value: "GENDER",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.groups.gender"
      )`Boys / Girls`
    ),
  },
  {
    value: "GEOGRAPHY",
    label: i18n._(
      t(
        "fig.learning.trajectories.explorer.controls.groups.geography"
      )`Urban / Rural`
    ),
  },
];

/* 
  FIXME: Once the API is able to support this logic, this should be removed and there should be 
  a new query to get list of countries available per simulation.
*/
export const COUNTRIES_EXCLUDED_DISPARITY_SIM = [
  "KIR",
  "MKD",
  "TCA",
  "TON",
  "TUV",
];
