
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Equity";
export const lead = "There has been global progress towards gender parity in education, but gaps remain wide between those in rural and urban areas, and between the rich and the poor.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const PageLink = makeShortcode("PageLink");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`The desire to leave no one behind cuts across the Sustainable Development Goals (SDGs) and is explicit in the fifth target in the education goal, SDG 4. This focus ensures that policy makers prioritise helping the furthest behind first.`}</p>
    </blockquote>
    <p>{`To be able to monitor whether education gaps are being closed, we need data that is broken down `}<strong parentName="p">{`by income, gender, age, race, ethnicity, migration status, disability, geographic location, and other characteristics`}</strong>{` relevant to national contexts, which household surveys provide.`}</p>
    <p>{`Since 2010, the `}<a parentName="p" {...{
        "href": "https://www.education-inequalities.org/"
      }}>{`World Inequality Database on Education`}</a>{` has been using household surveys to show how wide the education gaps are in different countries. This article summarizes some of the key facts and trends in inequality in education, while some additional aspects are discussed in the article on `}<PageLink name="finance" mdxType="PageLink">{`finance`}</PageLink>{`.`}</p>
    <h2 {...{
      "id": "gender"
    }}>{`Gender`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`The graphs below look at the education gaps between groups, such as girls and boys, or the poorest and the richest. It measures that education gap with a `}<Tooltip title="Parity index" content="In a parity index, if the value is close to 0, it shows the disadvantaged group is very far behind; values close to 1 mean there is parity; values close to 2 mean that the advantaged group is the furthest behind. A parity index of 0.5, for instance, could show that there are 30% of girls but 60% of boys enrolled. A parity index of 1.5 would indicate there were 30% of boys but 60% of girls enrolled." mdxType="Tooltip">{`parity index`}</Tooltip>{`.`}</p>
      <p>{`Although rarely appreciated, there has been a continuous move towards `}<Tooltip title="Gender parity" content="Where there are equal numbers of boys to girls" mdxType="Tooltip">{`gender parity`}</Tooltip>{` in the past generation. Girls and young women in many, mostly richer, parts of the world outnumber boys and young men in schools and universities.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`Any line going below the `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`grey shaded`}</HighlightByIndex>{` area shows that there are more boys or young men enrolled than girls.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`In `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`sub-Saharan Africa`}</HighlightByIndex>{` there are still fewer than four young women to every five young men attending tertiary education.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <HighlightByIndex index="2" mdxType="HighlightByIndex">Central and Southern Asia</HighlightByIndex> has gone
from having girls extremely far behind in 1990, to achieving gender parity in lower
and upper secondary education and a higher female enrolment rate in tertiary education,
mostly thanks to dramatic progress in India. As a result, the region has overtaken <HighlightByIndex index="0" mdxType="HighlightByIndex">
  sub-Saharan Africa
          </HighlightByIndex> in progress in achieving gender parity.
        </ScrollySection>
      </ScrollyArea>
      <p>{`Globally, there has been much progress since 2000. However, drilling down to the country level uncovers persistent gaps: just two in three countries have achieved gender parity in enrolment in primary education, one in two in lower secondary and one in four in upper secondary education.`}</p>
      <p>{`The share of countries with a `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`large disparity at the expense of girls`}</HighlightByIndex>{` has at least halved in all education levels since 2000, but remains 4% in primary, 8% in lower secondary and 14% in upper secondary education.`}</p>
      <p>{`We must not forget the boys when we talk about gender equality. While it is less common for countries to have a `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`large disparity at the expense of boys`}</HighlightByIndex>{` in primary education, it is far more common in upper secondary education and above, with the trend either stagnating or deteriorating.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In this graph, the greater and darker the blue part of the bar, the more countries there are with `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`more boys out of school`}</HighlightByIndex>{` than girls. The greater and darker the green part of the bar, the more countries there are with `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`more girls out of school`}</HighlightByIndex>{` than boys.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
In tertiary education, for instance, over two-thirds of countries have <HighlightByIndex index="0" mdxType="HighlightByIndex">extreme disparity at the expense of boys</HighlightByIndex>, a situation that is becoming worse over time.
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "wealth"
      }}>{`Wealth`}</h2>
      <p>{`Overall, education gaps between girls and boys are not as wide as between those in rural and urban areas, and between the rich and the poor, which household surveys help reveal.`}</p>
      <p>{`In Pakistan, for instance, while 92% of the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`richest`}</HighlightByIndex>{` attend primary education, only 28% of the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`poorest`}</HighlightByIndex>{` do. In Nigeria, while 97% of the richest attend lower secondary education, only 27% of the poorest do.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`The red dots show what percentage of the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`poorest`}</HighlightByIndex>{` children or youth are attending education in comparison to the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`richest`}</HighlightByIndex>{` – shown in blue. You can change education level from primary to lower secondary education and above at the top of the chart.`}</p>
        </ScrollySection>
        <ScrollySection id="ALB" mdxType="ScrollySection">
          <p>{`In tertiary education, gaps are widest in middle-income countries. For example, in `}<strong parentName="p">{`Albania`}</strong>{`, 55% of the richest but less than 1% of the poorest attended any form of post-secondary education.`}</p>
        </ScrollySection>
        <ScrollySection id="MNG" mdxType="ScrollySection">
          <p>{`In `}<strong parentName="p">{`Mongolia`}</strong>{`, the respective shares were 70% and 10%.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`It is even more revealing to look at the way gaps change as children go through their education.`}</p>
      <p>{`Looking at `}<strong parentName="p">{`gender`}</strong>{` gaps in `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Mali`}</HighlightByIndex>{` below shows the extent to which girls drop out the higher the level of education.`}</p>
      <p>{`Alternatively, selecting `}<strong parentName="p">{`wealth`}</strong>{` shows how much gaps accumulate over the course of the education trajectory at the expense of the poorest, with barely any of them attending past upper secondary.`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Rarely does a child fall into one single category, meaning that it is crucial to also look at the way that individual characteristics, such as gender, interact with others such as wealth as the next figure does.`}</p>
    <p>{`In sub-Saharan Africa, the gaps between the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`poorest`}</HighlightByIndex>{` and `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`richest`}</HighlightByIndex>{` grow over the course of the education trajectory, with fewer than 45 of the poorest girls, compared to nearly 80 of the richest for every 100 boys attending tertiary education.`}</p>
    <p>{`In Eastern and South-eastern Asia on the other hand, the dots are heading upwards from the grey line because gender gaps are at the expense of the poorest boys. By tertiary education level, the richest girls are only around a quarter more likely to attend than their male peers, but the poorest girls are two-thirds more likely.`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`There are unacceptable levels of education inequality across countries and between groups within countries. High-quality disaggregated data is a pre-requisite to designing appropriate strategies to reduce these inequalities and ultimately leave no-one behind in our quest to achieve SDG 4.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`Worldwide Inequalities Database on Education (WIDE)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Still left behind – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/2019genderreport"
          }}>{`Building Bridges for Gender Equality – GEM Gender Report 2019`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;