import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "PTR",
  title: /*i18n*/ {
    id: "fig.quality.PTR.title",
    defaults: `The number of pupils per teacher is reducing in most countries over time`,
  },
  caption: /*i18n*/ {
    id: "fig.quality.PTR.caption",
    defaults: `The number of pupils per teacher has generally been declining outside of phases of rapid school expansion.`,
  },
  source: /*i18n*/ { id: "fig.quality.PTR.source", defaults: `UIS database` },
};
