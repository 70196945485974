import React from "react";
import { Exception, foldException } from "../domain";

export const RenderException = ({ e }: { e: Exception }) =>
  foldException(e, {
    InvariantViolation: (e) => <div>{JSON.stringify(e, null, 2)}</div>,
    FetchException: (e) => <div>{JSON.stringify(e, null, 2)}</div>,
    FileReaderAbort: (e) => <div>{JSON.stringify(e, null, 2)}</div>,
    FileReaderError: (e) => <div>{JSON.stringify(e, null, 2)}</div>,
    FileReaderReject: (e) => <div>{JSON.stringify(e, null, 2)}</div>,
    DecoderError: (e) => (
      <div>{JSON.stringify(e.errorMessages.slice(0, 5), null, 2)}</div>
    ),
  });
