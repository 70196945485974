import { Draft } from "immer";
import React from "react";
import { useImmerReducer } from "use-immer";

export interface State {
  themeDrawer: { isOpen: boolean };
}

export const initialState: State = {
  themeDrawer: {
    isOpen: false,
  },
};

export type Action =
  | { type: "themeDrawer.close" }
  | { type: "themeDrawer.toggle" }
  | { type: "themeDrawer.open" };

export type Dispatcher = React.Dispatch<Action>;

export function reducer(state: Draft<State>, action: Action): State {
  switch (action.type) {
    case "themeDrawer.toggle":
      const { isOpen } = state.themeDrawer;
      state.themeDrawer.isOpen = !isOpen;
      return state;
    case "themeDrawer.close":
      state.themeDrawer.isOpen = false;
      return state;
    case "themeDrawer.open":
      state.themeDrawer.isOpen = true;
      return state;
    default:
      throw new Error(`Unhandled action`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const AppStateContext = React.createContext([initialState, () => {}] as [
  State,
  React.Dispatch<Action>
]);

export const AppStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ctxReducer = useImmerReducer(reducer, initialState);
  return (
    <AppStateContext.Provider value={ctxReducer}>
      {children}
    </AppStateContext.Provider>
  );
};
