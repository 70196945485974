export interface Responsive<T> {
  xs: T;
  s: T;
  m: T;
  l: T;
}

export const responsiveConstant = <T>(v: T): Responsive<T> => ({
  xs: v,
  s: v,
  m: v,
  l: v,
});

export const toResponsive = <T extends number>(v: T | Responsive<T>) => {
  if (typeof v === "number") {
    return responsiveConstant(v);
  } else {
    return v;
  }
};

export const mapResponsive = <T, R>(
  v: Responsive<T>,
  f: (v: T) => R
): Responsive<R> => {
  return { xs: f(v.xs), s: f(v.s), m: f(v.m), l: f(v.l) };
};

export const responsiveToArray = <T>(v: Responsive<T>): T[] => {
  return [v.xs, v.s, v.m, v.l];
};
