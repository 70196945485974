
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Informations";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">Informations</Title>
    <h2 {...{
      "id": "scope"
    }}>{`SCOPE`}</h2>
    <p>{`SCOPE compile des données administratives, des enquêtes auprès des ménages, des évaluations de l’apprentissage et des données sur le financement de l’éducation émanant de différents producteurs de données, en particulier de l’Institut de statistique de l’UNESCO, en vue d’examiner les progrès réalisés en faveur de l’ODD 4, l’objectif mondial relatif à l’éducation.`}</p>
    <p>{`Il complète l’ `}<a parentName="p" {...{
        "href": "https://fr.unesco.org/gem-report/allreports"
      }}>{`édition imprimée`}</a>{` du Rapport mondial de suivi sur l’éducation, en permettant aux utilisateurs d’interagir avec les données pour comprendre les réussites et les défis des pays et des régions qui s’efforcent d’atteindre les cibles. Il peut être partagé et téléchargé, et ses utilisateurs peuvent créer des images et des fichiers de données afin d’approfondir leurs connaissances, de les imprimer ou encore de les utiliser en ligne ou dans le cadre d’exposés.`}</p>
    <h2 {...{
      "id": "le-rapport-mondial-de-suivi-sur-léducation"
    }}>{`Le Rapport mondial de suivi sur l’éducation`}</h2>
    <p>{`Le Rapport mondial de suivi sur l’éducation est une parution indépendante sur le plan éditorial, hébergée et publiée chaque année par l’UNESCO. Selon le `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656_fre"
      }}>{`Cadre d’action Éducation 2030`}</a>{`, il a pour mission de suivre les progrès de l’éducation dans le cadre des ODD ainsi que ceux de l’application des stratégies nationales et internationales en matière d’éducation qui visent à aider les partenaires concernés à tenir leurs engagements.`}</p>
    <LinkBlock title="Consultez le site Web" url="https://fr.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "lobjectif-de-développement-durable-4"
    }}>{`L’Objectif de développement durable 4`}</h2>
    <p>{`En septembre 2015, au Sommet des Nations Unies sur le développement durable, à New York, les États membres ont officiellement adopté le `}<a parentName="p" {...{
        "href": "https://sustainabledevelopment.un.org/post2015/transformingourworld"
      }}>{`Programme de développement durable à l’horizon 2030`}</a>{`. Ce Programme comporte 17 objectifs, dont l’un – l’ODD 4 – vise à « assurer à tous une éducation équitable, inclusive et de qualité et des possibilités d'apprentissage tout au long de la vie ». L’ODD 4 est assorti de sept cibles et de trois modalités de mise en œuvre.`}</p>
    <LinkBlock title="Voir les cibles et les cadres de suivi associés" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="en" mdxType="FederalMinistryGermanyLogo" />
    <p>{`Nous remercions particulièrement le Ministère fédéral allemand de l’éducation et de la recherche pour son partenariat et sa généreuse contribution à l’élaboration du site Web sur les Progrès de l’éducation.`}</p>
    <Aside mdxType="Aside">
      <h2 {...{
        "id": "contactez-nous"
      }}>{`Contactez-nous`}</h2>
      <hr></hr>
      <p>{`Courriel général`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`L’équipe`}</p>
      <p>{`Équipe du rapport GEM, UNESCO`}</p>
      <hr></hr>
      <p>{`Adresse postale`}</p>
      <p>{`Global Education Monitoring Report Team (GEM Report),
UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;