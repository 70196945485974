
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Обучение";
export const lead = "Простого посещения детьми школы недостаточно. Не забывая о многих других важных целях, которые ставят перед собой школы и которые они должны реализовывать, они должны научить учащихся читать и решать элементарные математические задачи. Эти навыки являются основой для продолжения обучения, расширения прав и возможностей и благополучия человека, а также для достижения социальных, политических и экономических результатов.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />{" "}
    <blockquote>
      <p parentName="blockquote">{`Простого посещения детьми школы недостаточно. Не забывая о многих других важных целях, которые ставят перед собой школы и которые они должны реализовывать, они должны научить учащихся читать и решать элементарные математические задачи. Эти навыки являются основой для продолжения обучения, расширения прав и возможностей и повышения благополучия человека, а также для достижения социальных, политических и экономических результатов.`}</p>
    </blockquote>
    <h2 {...{
      "id": "дети-и-молодежь"
    }}>{`Дети и молодежь`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Множество детей в некоторых беднейших странах мира не владеют базовыми навыками чтения и счета. Оценки навыков чтения в начальных классах в таких северных штатах Нигерии, как Кадуна и Сокото, и в некоторых провинциях Демократической Республики Конго, например, в Экваториальной провинции и в Катанге, показывают, что более 80% учащихся третьего класса начальной школы не могут прочесть ни `}<strong parentName="p">{`одного слова`}</strong>{` связного текста.`}</p>
      <Figure id="EGRA" mdxType="Figure" /> 
    </IndicatorReference>
    <p>{`Эти данные не охватывают детей, которые не ходят в школу. Исследования, проводимые у людей дома, могут дать представление как о навыках детей, посещающих школу, так и не посещающих ее.`}</p>
    <p>{`Например, на приведенных ниже графиках, составленных на основе проведенных гражданами оценок, показана ситуация в различных регионах Индии и Пакистана.`}</p>
    <p>
  <HighlightByIndex index="1" mdxType="HighlightByIndex">Красными линиями</HighlightByIndex> показана доля
  обладающих базовыми навыками чтения детей, <strong>посещающих школу</strong>,
  в разбивке по классам, а{" "}
  <HighlightByIndex index="0" mdxType="HighlightByIndex">синими линиями</HighlightByIndex> – доля всех
  детей, обладающих базовыми навыками чтения,{" "}
  <strong>
    вне зависимости от того, посещают они школу или нет, в разбивке по возрасту,
    соответствующему каждому классу
  </strong>
  .
    </p>{" "}
    <p>{`Разрыв между этими двумя линиями напоминает о том, что в странах с высокой долей детей, не посещающих школу, ответственные за разработку политики лица не имеют полного представления о ситуации, если они контролируют лишь обучение тех, кто посещает школу. Важно отметить, что уровень контролируемых навыков является очень низким. Даже в регионах с наиболее высокими показателями успеваемости в средней школе имеется ряд подростков, которые не могут прочитать текст для второго класса.`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" /> 
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Согласно оценкам, почти 40% детей начального школьного возраста не владеют базовыми навыками чтения. В Центральной и Южной Азии таких детей 43%, в странах Африки к югу от Сахары – 84%, а в Европе и Северной Америке – только 3%.`}</p>
    </IndicatorReference> 
    <IndicatorReference id="4.1.0" label="4.1.0" mdxType="IndicatorReference">
      <p>{`Во многих странах нет никаких гарантий того, что дети научились читать, даже если они окончили начальную школу. Во всем мире каждый четвертый из десяти завершивших начальное обучение, не владел базовыми навыками чтения. Как показано на приведенном ниже графике, в странах Африки к югу от Сахары, этот показатель увеличивается до 8/10.`}</p>
      <ScrollyArea mdxType="ScrollyArea"> 
        <ScrollySticky mdxType="ScrollySticky"> 
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" /> 
        </ScrollySticky> 
        <ScrollySection mdxType="ScrollySection">
          <HighlightByIndex index="-1" mdxType="HighlightByIndex">Серым цветом </HighlightByIndex> показана процентная
доля детей,{" "}
          <p>{`закончивших начальную школу, но не достигших минимального уровня владения навыками чтения.{" "}`}</p>
          <HighlightByIndex index="1" mdxType="HighlightByIndex">Красным цветом</HighlightByIndex> показаны дети, которые{" "}
          <p>{`не завершили получение начального образования, а{" "}`}</p>
          <HighlightByIndex index="0" mdxType="HighlightByIndex">голубым</HighlightByIndex> – учащиеся, окончившие начальную
школу и{" "}
          <p>{`достигшие минимального уровня владения навыками.`}</p>
        </ScrollySection> 
      </ScrollyArea> 
    </IndicatorReference>
    <p>{`Если рассматривать страновой уровень, то в Чаде и Нигерии около 90% молодых людей, переставших посещать школу по окончании пяти-шести классов, не умеют читать и писать. Однако в некоторых странах результаты значительно лучше. Например, в Бурунди, Коста-Рике и Гондурасе практически все дети, переставшие посещать школу по окончании пятого или шестого класса, были грамотными.`}</p>
    <ScrollyArea mdxType="ScrollyArea"> 
      <ScrollySticky mdxType="ScrollySticky"> 
        <Figure id="LITBYYRS" mdxType="Figure" /> 
      </ScrollySticky> 
      <ScrollySection id="KEN" mdxType="ScrollySection">
        <p>{`В `}<strong parentName="p">{`Кении`}</strong>{`, например, `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`после четырех лет обучения в школе`}</HighlightByIndex>{` грамотными можно назвать 1/4 детей, однако для тех, кто закончил `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`пять-шесть классов`}</HighlightByIndex>{`, этот показатель составляет более 3/4,`}</p>
        <p>{`что эквивалентно начальному образованию.`}</p>
      </ScrollySection> 
      <ScrollySection id="NER" mdxType="ScrollySection">
        <p>{`В `}<strong parentName="p">{`Нигере`}</strong>{` почти никто из детей `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`после четырех лет обучения в школе`}</HighlightByIndex>{` не умеет читать, однако 1/3 детей овладевает базовыми навыками грамотности по окончании `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`полного курса начального образования`}</HighlightByIndex>{`.`}</p>
      </ScrollySection> 
    </ScrollyArea>
    <p>{`Для того чтобы иметь возможность сравнивать обучение в разных странах, оценки или тесты должны соответствовать международным стандартам, использующим одно общее определение. Такие стандарты недавно были разработаны для международных оценок, в ходе которых учащиеся из нескольких стран проходят одинаковое тестирование. Однако для проведения национальных оценок таких тестов пока гораздо меньше.`}</p>
    <p>{`Один раз в четыре года проводится Международное мониторинговое исследование качества школьного математического и естественнонаучного образования (ТИМСС), которое позволяет изучить изменение результатов учащихся четвертого и восьмого классов с течением времени. Оно показывает, в каких странах прогресс был особенно медленным. В Египте, например, процентная доля учащихся четвертого и восьмого классов, которые достигли минимального уровня владения навыками математики, увеличилась с 24% в 2003 г. только до 27% в 2019 г.`}</p>
    <p>{`В некоторых странах, таких как `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Бахрейн`}</HighlightByIndex>{`, прогресс шел быстрыми темпами, поскольку доля учащихся, достигших минимального уровня успеваемости, за 2003–2019 гг. более чем утроилась.`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" /> 
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Вопреки мнению большинства людей, разрыв в обучении мужчин и женщин невелик и составляет, например, не более десяти процентов на уровне начального образования практически во всех странах.`}</p>
      <p>{`Однако разрыв в уровне обучения между странами огромен. Во странах Африки к югу от Сахары уровень владения навыками чтения в начальной школе характеризуется тенденцией к снижению, а доля учащихся, достигающих минимального уровня владения навыками, находится в диапазоне от всего 2% в Бурунди до 76% в Габоне (в обоих случаях согласно результатам оценки ПАСЕК, проведенной в 2019 году).`}</p>
      <p>{`В области математики в начальной школе в странах Латинской Америки этот показатель колеблется от всего 2% в Доминиканской Республике до 30% в Перу (оба показателя получены по итогам оценки успеваемости, проведенной ЛЛОКО в 2015 г.).`}</p>
      <Figure id="ASSESS" mdxType="Figure" /> 
    </IndicatorReference>
    <h2 {...{
      "id": "взрослые"
    }}>{`Взрослые`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`В период 2000-2020 гг. доля грамотных взрослых во всем мире увеличилась с 81% до 87%, а доля грамотной молодежи выросла с 87% до 92%.`}</p>
      <p>{`Несмотря на эти улучшения, неграмотность по-прежнему широко распространена во многих странах, особенно среди женщин. В Латинской Америке и Карибском бассейне прогресс застопорился на относительно высоком уровне, что свидетельствует о наличии отдельных групп населения, которые находятся в крайне неблагоприятном положении и которые сложно охватить образованием. В Парагвае, например, за последние 10 лет уровень грамотности среди пожилых женщин практически не меняется и составляет около 73%.`}</p>
      <p>{`В 18 странах Африки к югу от Сахары более половины взрослых женщин (в возрасте 25-64 лет) не умеют читать. Прогресс либо застопорился на очень низком уровне, как в Мали, где читать умеют менее 17% женщин, либо замедлился, как в Конго, где за 10 лет этот показатель вырос с 72% до 74%.`}</p>
      <p>{`Хотя в масштабе всего мира сейчас стало больше грамотных взрослых, чем раньше, это происходит потому, что увеличилось число взрослых, которые посещали школу, когда были маленькими, а не потому, что взрослые, не посещавшие школу, получили второй шанс на обучение. Для проверки этого утверждения на приведенном ниже графике показаны данные, отражающие изменение уровня грамотности определенного контингента – или группы населения – по мере взросления.`}</p>
      <ScrollyArea mdxType="ScrollyArea"> 
        <ScrollySticky mdxType="ScrollySticky"> 
          <Figure id="LITCOHORT" mdxType="Figure" /> 
        </ScrollySticky> 
        <ScrollySection mdxType="ScrollySection">
          <p>{`В большинстве стран уровень грамотности определенной `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`группы взрослых`}</HighlightByIndex>{` со временем не изменялся или даже снижался, поскольку эти навыки не использовались в полной мере.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  Ситуацию на **Гаити** отражает голубая линия, которая свидетельствует о том,
  что уровень грамотности{" "}
  <HighlightByIndex index="0" mdxType="HighlightByIndex">
    женщин в возрасте 20-34 лет в 2000 г.
  </HighlightByIndex>
  , <HighlightByIndex index="0" mdxType="HighlightByIndex">26-40 лет в 2006 г.</HighlightByIndex> и <HighlightByIndex index="0" mdxType="HighlightByIndex">
    44 дет–61 года в 2017 г.
  </HighlightByIndex> незначительно снизился. И все же, поскольку грамотными стали
  более молодые женщины, красная линия показывает, что <HighlightByIndex index="1" mdxType="HighlightByIndex">
    уровень грамотности среди женщин в возрасте 20-34 лет
  </HighlightByIndex> с течением времени повышается.
        </ScrollySection>{" "}
      </ScrollyArea> 
    </IndicatorReference>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`В ходе таких оценок измеряются самые базовые навыки грамотности. Они показывают, умеют ли люди читать простые предложения. Навыки функциональной грамотности, благодаря которым люди могут читать и понимать письменные инструкции, официальные письма или контракты – это другая тема.`}</p>
      <p>{`Международных исследований, в ходе которых собираются данные о функциональной грамотности, немного, и лишь малая часть из них проводится на регулярной основе.`}</p>
      <p>{`Имеющиеся у нас данные показывают, что более 1/10 взрослых в городах Колумбии и Вьетнама и 1/4 в Боливии не владеют навыками функциональной грамотности (т.е. уровень их функциональной грамотности ниже первого).`}</p>
      <p>{`В `}<strong parentName="p">{`Гане`}</strong>{` даже среди взрослого городского населения только 39% обладают навыками функциональной грамотности.`}</p>
      <Figure id="LITPROF" mdxType="Figure" /> 
    </IndicatorReference>
    <h2 {...{
      "id": "заключение"
    }}>{`Заключение`}</h2>
    <p>{`Несмотря на то, что в рамках ЦУР 4 акцент сместился на мониторинг обучения, многие страны по-прежнему не сообщают о показателях результатов обучения чтению и математике. Даже те страны, которые хотят приступить к оценке и представлять отчетность по результатам обучения, часто сталкиваются с препятствиями, не позволяющими им принять эффективные и экономные решения. Вместе с тем имеющиеся у нас данные свидетельствуют о том, что большинство учащихся в беднейших странах мира не достигает минимального уровня владения самыми базовыми навыками.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/32553?locale-attribute=fr"
          }}>{`Ending learning poverty – what will it take? – World Bank`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000226157"
          }}>{`EFA GMR 2013/4: Teaching and learning: Achieving quality for all`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/20/the-learning-assessment-market-pointers-for-countries-part-2/"
          }}>{`The learning assessment market - pointers for countries`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;