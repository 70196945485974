import { t } from "@lingui/macro";
import { curveLinear } from "d3-shape";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_learning_fig_PIRLSGAIN";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  getCountryName,
  mkGemEntityDecoder,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_learning_fig_PIRLSGAIN";

const DEFAULT_COUNTRIES = ["MYS", "BHR"];

const yUnitLabel = t(
  "fig.learning.PIRLSGAIN.yUnitLabel"
)`Grade 4 students with minimum proficiency in reading`;

export const Data = mkGemEntityDecoder(["ind_id", "year", "value"], {});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedCountries] = useMultiSelectState("countries");

  const entityData = useNamedEntities(data);
  const countryData = useCountryEntities(entityData);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        selected: O.some(DEFAULT_COUNTRIES),
      },
      countryControlItems
    );
  }, [i18n, actions, env.countries, countryControlItems]);

  const chartData = React.useMemo(() => {
    const hasCountry = (country: CountryId) =>
      pipe(
        selectedCountries,
        O.fold(
          () => false,
          (xs) => xs.includes(countryIdIso.unwrap(country))
        )
      );

    return countryData.filter(({ id }) => hasCountry(id));
  }, [countryData, selectedCountries]);

  const selectedCountryLabels = React.useMemo(() => {
    return pipe(
      selectedCountries,
      O.map((xs) =>
        xs.map((x) => getCountryName(env.countries, countryIdIso.wrap(x)))
      )
    );
  }, [env.countries, selectedCountries]);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    selectedCountryLabels
  );

  return (
    <>
      <LineChart
        tLabel={(s) => s}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        x="year"
        timeParse="%Y"
        timeFormat="%Y"
        numberFormat=".0%"
        yTicks={[0, 0.2, 0.4, 0.6, 0.8, 1]}
        category="datum.entity_name"
        colorLegend={false}
        colorScale={colorScale}
        connectLines
        values={chartData}
        markStyle={(x) =>
          x.entity_type === "world"
            ? "reference"
            : x.entity_type === "country"
            ? "normal"
            : "muted"
        }
        showTooltip
        curve={curveLinear}
        yAxisLabel={i18n._(yUnitLabel)}
      />
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_PIRLSGAIN.json"),
  csv: require("../data/data_learning_fig_PIRLSGAIN.zip"),
  xlsx: require("../data/data_learning_fig_PIRLSGAIN.xlsx"),
  metadata,
  Data,
  Chart,
  size: "narrow",
});
