
var Observation_possibleTypes = ['Observation']
module.exports.isObservation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isObservation"')
  return Observation_possibleTypes.includes(obj.__typename)
}



var Query_possibleTypes = ['Query']
module.exports.isQuery = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuery"')
  return Query_possibleTypes.includes(obj.__typename)
}
