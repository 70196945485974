import css from "@emotion/css";
import { sum } from "d3-array";
import { replicate } from "fp-ts/lib/Array";
import React from "react";
import { useContentRect, useTheme } from "../hooks";
import * as M from "../materials";
import { An, Ar } from "../prelude";

const COLUMN_PADDING = 20;
const ROW_PADDING = 32;

export interface ChartGridProps<A> {
  children(x: {
    data: An.NonEmptyArray<A>;
    width: number;
    first: boolean;
    firstInRow: boolean;
    last: boolean;
    cell: string;
    index: number;
  }): React.ReactNode;
  data: An.NonEmptyArray<A>;
  getCell(x: A): string;
  columnCount?: number;
  columnFractions?: An.NonEmptyArray<number>;
  minWidth?: number;
}

/**
 * ChartGrid
 *
 * Lays out children in a grid where each cell can not shrink below the given
 * min width.
 *
 * In the following example, a desired column count of 2 has been specified, but
 * three cells were given.
 *
 * +-- Row 1 ------------+
 * | +--------+--------+ |
 * | | Cell 1 | Cell 2 | |
 * | +--------+--------+ |
 * +---------------------+
 * +-- Row 2 ------------+
 * | +--------+          |
 * | | Cell 3 |          |
 * | +--------+          |
 * +---------------------+
 */
export function ChartGrid<A>({
  children,
  data,
  getCell,
  minWidth = 140,
  columnCount = 1,
  columnFractions,
}: ChartGridProps<A>) {
  const { client } = useTheme();
  const [ref, contentRect] = useContentRect();
  const isReady = contentRect.width > 0;
  const fullWidth = contentRect.width > 0 ? contentRect.width : minWidth;

  const possibleColumns = Math.floor(fullWidth / (minWidth + COLUMN_PADDING));
  const cols =
    possibleColumns >= columnCount ? columnCount : Math.max(possibleColumns, 1);
  const columnWidth =
    Math.floor((fullWidth - COLUMN_PADDING * (cols - 1)) / cols) - 1;
  const getCellWidth = React.useMemo(() => {
    const fractions = columnFractions
      ? columnFractions.length === cols
        ? columnFractions
        : columnFractions.concat(replicate(cols, 1)).slice(0, cols)
      : replicate(cols, 1);
    const fr = sum(fractions) / fractions.length;
    return (stdWidth: number, idx: number) =>
      (stdWidth / fr) * (fractions[idx] || 1);
  }, [cols, columnFractions]);

  const cells = An.groupBy(getCell)(data);
  const rows = Ar.chunksOf(cols)(Object.keys(cells));

  return (
    <div ref={ref}>
      {isReady &&
        rows.map((row, ir) => {
          const last = ir === rows.length - 1;

          return (
            <div
              key={`row-${ir}`}
              css={css`
                direction: ${client.isRTL ? "rtl" : "ltr"};
                display: grid;
                grid-template-areas: ${genGridTemplateArea(cols)};
                grid-template-columns: ${Array.from(
                  { length: cols },
                  (_, i) => `${getCellWidth(columnWidth, i)}px`
                ).join(" ")};
                grid-column-gap: ${COLUMN_PADDING}px;
                margin-bottom: ${ROW_PADDING}px;
                &:last-child {
                  margin-bottom: 0;
                }

                @media ${M.bpDown("m")} {
                  grid-column-gap: ${COLUMN_PADDING / 2}px;
                  margin-bottom: ${ROW_PADDING / 3}px;
                }
              `}
            >
              {row.map((cell, ic) => {
                const i = ir * cols + ic;
                const xs = cells[cell];
                const first = i === 0;
                const firstInRow = i % cols === 0;

                const width = getCellWidth(columnWidth, ic);

                const colName = colNameTemplate[i % cols];
                let j = 0;
                const gridArea = () => ({
                  style: { gridArea: `${colName}${j++}` },
                });

                return (
                  <React.Fragment key={cell}>
                    <h2
                      {...gridArea()}
                      css={[
                        css`
                          ${M.fontChartGroupTitle};
                          fill: ${M.blackText};
                        `,
                      ]}
                    >
                      {cell}
                    </h2>
                    <div {...gridArea()}>
                      {children({
                        data: xs,
                        width,
                        first,
                        firstInRow,
                        last,
                        cell,
                        index: ic,
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

// -----------------------------------------------------------------------------

/**
 * colNameTemplate
 *
 * We support a maximum of 10 columns; only the necessary columns are picked
 */
const colNameTemplate = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

/**
 * genGridTemplateArea
 *
 * A helper function to generate named and enumerated grid template areas. The
 * maximum row count is 10.
 *
 * Example:
 * grid-template-areas: "a0 b0" "a1 b1" "a3 b3" ... "a9 b9";
 */
function genGridTemplateArea(colCount: number) {
  const colNames = colNameTemplate.slice(0, colCount);
  return Array.from({ length: 10 }, (_, i) =>
    colNames.map((col) => `${col}${i}`).join(" ")
  )
    .map((row) => `"${row}"`)
    .join(" ");
}
