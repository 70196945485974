
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Finance";
export const lead = "One in four countries did not reach either of the finance benchmarks set in the Education 2030 Framework for Action.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`The ambitious education targets will not be achieved in time without additional resources, especially in countries that are the furthest behind.`}</p>
    </blockquote>
    <p>{`In order to reach the 2030 target of universal secondary completion, low- and lower-middle-income countries would need to increase the amount they spend each year from US$149 billion in 2012 to US$340 billion in 2030, or 6.3% of their gross domestic product (GDP). Having more resources is not necessarily enough, though; countries spending the same amount in terms of income per capita can have wildly different education results depending on whether they are spending equitably and efficiently or not.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`Every year, of the US\\$4.7 trillion spent on education worldwide, only 0.5% is spent in low income countries, while 65% is spent in high income countries, even though the two groups have a roughly equal number of school-age children.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`Globally, `}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`households`}</HighlightByPalette>{` are covering a fifth (20%) of the cost of education. In fact, households pick up a larger education bill in low-income countries (29%) than in high-income countries (18%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`Although `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`donors`}</HighlightByPalette>{` contribute 12% of spending in low income countries …`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`… `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`donors'`}</HighlightByPalette>{` contributions account for just 0.3% of total spending globally, which is barely visible in absolute amounts.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "public-expenditure"
    }}>{`Public expenditure`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
      }}>{`Education 2030 Framework for Action`}</a>{` has set two key finance benchmarks for governments. It calls for them to:`}</p>
    <ul>
      <li parentName="ul">{`allocate at least 4% to 6% of GDP to education, and/or`}</li>
      <li parentName="ul">{`allocate at least 15% to 20% of public expenditure to education.`}</li>
    </ul>
    <p>{`Globally, on average, governments are not far from these norms. Governments spend on average 4.4% of GDP, ranging from 3.4% in Eastern and South-eastern Asia to 5.1% in Latin America and the Caribbean. And they allocate 14.1% of total public expenditure, with regional averages ranging from 11.6% in Europe and Northern America to 18% in Latin America and the Caribbean.`}</p>
    <p>{`But, altogether, one in four countries did not meet either benchmark. Play these data over time to see how spending fluctuates over the years.`}</p>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`Two key characteristics may determine how much governments spend on education: `}<strong parentName="p">{`tax`}</strong>{` and the `}<strong parentName="p">{`school-aged population`}</strong>{`. Even if all countries assigned the same priority to education in their budget, for instance, countries that raise more taxes and other domestic resources will spend more on education.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`Moreover, even if countries raised the same amount of taxes, countries with a larger share of children and adolescents in their population would have less to spend per school-aged child.`}</p>
    </IndicatorReference>
    <p>{`Having said that, the large variation in the amount spent on education between countries with otherwise similar characteristics is an indication that there is nothing pre-determined in these relationships. Some countries choose to spend more on education to achieve their objectives. And we should highlight those that do not.`}</p>
    <h2 {...{
      "id": "aid-expenditure"
    }}>{`Aid expenditure`}</h2>
    <p>{`While more financing is needed, it cannot come only from the most challenged countries themselves. International solidarity is called for. The Global Education Monitoring Report estimated an `}<a parentName="p" {...{
        "href": "https://en.unesco.org/gem-report/node/819"
      }}>{`annual funding gap of at least US\\$39 billion per year`}</a>{` between 2015 and 2030 in low and lower middle income countries: this would mean increasing the amount of aid disbursed to these countries in 2012 six-fold. Such spending is not unrealistic: it could be achieved if all OECD Development Assistance Committee (DAC) donors and selected non‑DAC donors allocated 0.7% of gross national income (GNI) to aid and 10% of their aid portfolios to basic and secondary education.`}</p>
    <p>{`However, after more than doubling between 2002 and 2009, aid to education has stagnated since.`}</p>
    <Figure id="AID" mdxType="Figure" />
    <p>{`Not only have aid levels stagnated but their distribution is not directed equitably. Doing so would necessitate prioritising support to the poorest countries, and basic education, which covers pre-primary through to lower-secondary levels. But this is not happening as the below graph shows.`}</p>
    <IndicatorReference id="4.5.5" mdxType="IndicatorReference">
      <p>{`The `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`share of total aid to education`}</HighlightByIndex>{` going to low-income countries has fallen from 25% in 2002 to 22% in 2016.`}</p>
      <p>{`Over the same period, despite 35% of out-of-school children of primary school age being in the poorest countries, the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`share of total aid to basic education`}</HighlightByIndex>{` going to low-income countries fell from 37% to 27%.`}</p>
      <Figure id="AID_LICS" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Donors also spend very different amounts per child in countries at the same level of education development. For instance, while about half of children complete primary school in both Liberia and Mauritania, Liberia receives 10 times the amount of aid to basic education per school age child.`}</p>
    <p>{`Looking at bilateral and multilateral donors from 2002 until 2017 in the below graph shows which are spending equitably. The percentage of overall aid to education that the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`United Kingdom`}</HighlightByIndex>{` is spending on basic education, for instance, has fallen from a peak of 81% in 2006 to 55% in 2017. The share of total aid to education that Sweden is allocating to low-income countries has fallen from a peak of 58% to 39% over the same period.`}</p>
    <p>{`OECD DAC Donors have `}<a parentName="p" {...{
        "href": "https://www.oecd.org/dac/stats/the07odagnitarget-ahistory.htm"
      }}>{`generally accepted`}</a>{` to spend 0.7% of their gross national income (GNI) on official development assistance, at least as a long-term objective, with some notable exceptions: Switzerland and the United States. As of 2017, only seven donors – Denmark, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Germany`}</HighlightByIndex>{`, Luxembourg, Norway, Turkey, the UAE and the United Kingdom – have met the target.`}</p>
    <Figure id="AID_TARGETING" mdxType="Figure" />
    <p>{`The following table looks at the aid flows between the top 10 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`donors`}</HighlightByIndex>{` and the top 10 `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`recipients`}</HighlightByIndex>{` globally, by education level.`}</p>
    <p>{`Germany may appear to be the largest single donor to education in these countries, for example. Yet the large share of this aid going to post-secondary education is an indication that these resources are largely spent on foreign students – mostly Chinese – who study in Germany, rather than necessarily working towards equity and quality at the basic education level.`}</p>
    <Figure id="AID_FLOWS" mdxType="Figure" />
    <p>{`In total, around a quarter (24%) of donors' aid to basic education is allocated to sub-Saharan Africa, home to half (54%) of the world's out of school children. This graph shows which countries the top donors to education are prioritising and at what level.`}</p>
    <p>{`France gave USD\\$130 million to four of the top ten recipient countries for aid in the region in 2017, for instance, but the vast majority of this went to post-secondary education, likely in the form of scholarships for African students to study in France.`}</p>
    <p>{`Strong global coordination by donors is important in education. And yet this graph shows the large number of donors giving to any one country, despite the added pressure this might place on a government to manage these relationships at the country level.`}</p>
    <Figure id="AID_FLOWS_SSA" mdxType="Figure" />
    <h2 {...{
      "id": "household-expenditure"
    }}>{`Household expenditure`}</h2>
    <p>{`Practically every country’s national statistics office measures household education spending in income and expenditure surveys.`}</p>
    <p>{`This spending can be in the form of tuition and other fees, but can also be for education goods and services, of which some are required (e.g. uniforms, textbooks) and others are not (e.g. private lessons).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Where such data is standardized across countries, spectacular evidence emerges of the contribution that households are making to total education spending. This can change the way countries’ efforts on education are understood.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,UGA,ARG,FRA" mdxType="ScrollySection">
          <p>{`For example, in 2013–2015, `}<strong parentName="p">{`Uganda`}</strong>{` had some of the lowest `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`government expenditure`}</HighlightByIndex>{` as a percentage of GDP. Yet, once `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`household contributions`}</HighlightByIndex>{` are taken into account, Uganda’s total national expenditure on education as a percentage of GDP was similar to that of `}<strong parentName="p">{`Argentina`}</strong>{` and more than that of `}<strong parentName="p">{`France`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,SLV,IDN,FRA,NLD" mdxType="ScrollySection">
          <p>{`The governments of `}<strong parentName="p">{`El Salvador`}</strong>{` and `}<strong parentName="p">{`Indonesia`}</strong>{` spent two `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`percentage points of GDP`}</HighlightByIndex>{` less on education than did `}<strong parentName="p">{`France`}</strong>{` and the `}<strong parentName="p">{`Netherlands`}</strong>{` – the blue bar –, but they spent more overall because `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`households`}</HighlightByIndex>{` spent more than three percentage points of GDP on education – the red bar.`}</p>
        </ScrollySection>
        <ScrollySection id="relative,KHM,TJK,UGA" mdxType="ScrollySection">
          <p>{`The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`share of households`}</HighlightByIndex>{` in total education expenditure was at least one-third in one-third of low and middle income countries, rising to around 60% or over in `}<strong parentName="p">{`Cambodia`}</strong>{`, `}<strong parentName="p">{`Tajikistan`}</strong>{` and `}<strong parentName="p">{`Uganda`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Very few countries provide timely and consistent data over a long period of time on how much is being spent on education and by what source.`}</p>
    <p>{`Aside from the need to improve the financing available for education, diagnostic tools on financing for the sector also need to be strengthened. Achieving our global education goal, SDG 4, relies upon holding countries and donors to account for their commitments.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://report.educationcommission.org/report/"
          }}>{`The Learning Generation – Investing in education for a changing world`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/14/aid-to-education-falls-slightly-in-2017-shifts-away-from-primary-education/"
          }}>{`Aid to education falls slightly in 2017 – GEM Report press release`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/819"
          }}>{`Pricing the right to education – GEM Report policy paper 2015`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;