
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "Progreso de la educación";
export const lead = "La educación ilumina cada etapa del camino hacia una vida mejor. Todos los niños necesitan tener acceso a una educación de buena calidad para desarrollar su potencial de aprendizaje y aprovechar los beneficios generales de la educación. Es necesario esforzarse principalmente para que todos los niños y jóvenes puedan beneficiarse de manera equitativa de su poder transformador. Esto requiere recursos suficientes, en especial para llegar a quienes necesitan más apoyo.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const PageLink = makeShortcode("PageLink");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scope"
      }}>{`SCOPE`}</h1>
      <p>{`La educación ilumina cada etapa del camino hacia una vida mejor. Todos los niños necesitan tener `}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`acceso`}</HighlightByTheme>{` a una educación de buena `}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`calidad`}</HighlightByTheme>{` para desarrollar su potencial de `}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`aprendizaje`}</HighlightByTheme>{` y aprovechar los beneficios generales de la educación. Es necesario esforzarse principalmente para que todos los niños y jóvenes puedan beneficiarse de manera `}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{`equitativa`}</HighlightByTheme>{` de su poder transformador. Esto requiere`}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`recursos`}</HighlightByTheme>{` suficientes, en especial para llegar a quienes necesitan más apoyo.`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`En 2015, la comunidad internacional incluyó un objetivo sobre educación, `}<a parentName="p" {...{
          "href": "https://sustainabledevelopment.un.org/sdg4"
        }}>{`ODS 4`}</a>{`, entre los 17 Objetivos de Desarrollo Sostenible que deberían alcanzarse antes de 2030. Desde entonces, se estableció un `}<PageLink name="indicadores" mdxType="PageLink">{`marco detallado`}</PageLink>{` para efectuar un seguimiento del progreso hacia la consecución de las metas del Objetivo. En este sitio, SCOPE, se resumen los datos y tendencias clave de la educación en todo el mundo basándose en cinco temas:`}</p>
    </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`El sitio complementa la información que se proporciona en la versión impresa del `}<a parentName="p" {...{
          "href": "https://es.unesco.org/gem-report/allreports"
        }}>{`Informe de Seguimiento de la Educación en el Mundo`}</a>{`, una herramienta que permite a la comunidad internacional realizar un seguimiento y un examen de los progresos alcanzados en favor de nuestro objetivo sobre educación, el ODS 4.`}</p>
    </LandingText>
    <LandingButton mdxType="LandingButton">Empiece a leer</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;