
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "الأحكام والشروط";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title"> الأحكام والشروط </Title>
    <p>{`يشجّع التقرير العالمي لرصد التعليم على استخدام الموقع الإلكتروني الخاص بالتقدّم المحرز في مجال التعليم لأغراض الإعلام فقط. وجميع محتويات هذا الموقع محمية بحقوق المؤلف. ويشكل دخولك للموقع واستخدام أي محتوى ورد فيه، إقراراً منك ("المستخدم") بأنك قرأت شروط الاستخدام وفهمتها قراءةً وفهماً تامين، ووافقت على الالتزام بها.`}</p>
    <p>{`نُشرت المعلومات الواردة في هذا الموقع الإلكتروني لكي يسهل استخدامها الشخصي أو العمومي غير التجاري ويجوز إعادة نشرها، جزئياً أو كلياً، وبأية وسيلة، بدون دفع أي رسم أو الحصول على إذن إضافي باستثناء الحالات التي يذكر فيها خلاف ذلك. وينبغي أن تُحال جميع الطلبات المتعلقة بالاستخدام التجاري وحقوق الترجمة إلى `}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a>{`.`}</p>
    <h2 {...{
      "id": "المستخدمون-ملزمون-بما-يلي"
    }}>{`المستخدمون ملزمون بما يلي:`}</h2>
    <ul>
      <li parentName="ul">{`بذل العناية الواجبة في ضمان دقة المضامين المعاد نشرها؛`}</li>
      <li parentName="ul">{`الإشارة إلى المصدر إشارةً واضحةً إلى جانب البيانات باستخدام النسق التالي:`}</li>
    </ul>
    <p>{`المصدر: (إن لزم الأمر "مكيف من") التقرير العالمي لرصد التعليم، التقدّم المحرز في مجال التعليم،
`}<a parentName="p" {...{
        "href": ""
      }}>{`https://www.education-progress.org`}</a>{`، تاريخ استخراج المضامين.`}</p>
    <p>{`وينبغي العلم بأن قاعدة البيانات تُحدَّث وتُراجع بانتظام. لذا، من المهم ذكر تاريخ استخراج المضامين عند اقتباس بيانات وردت في الموقع.`}</p>
    <h2 {...{
      "id": "التراخيص"
    }}>{`التراخيص`}</h2>
    <p>{`نشجعك على استخدام البيانات من أجل إفادة نفسك وغيرك بطرائق ابتكارية. ويجوز لك استخراج المعلومات الواردة في مجموعات البيانات وتنزيلها واستنساخها، ويجوز لك تبادل تلك المعلومات مع جهات أخرى.`}</p>
    <h2 {...{
      "id": "لا-شراكة-ولا-تأييد"
    }}>{`لا شراكة ولا تأييد`}</h2>
    <p>{`لا يجوز للمستخدم الإشارة صراحة أو ضمناً إلى أن التقرير العالمي لرصد التعليم شارك في استخدامه أو إعادة نشره للمضامين أو وافق على ذلك أو أيّده أو دعمه بأية طريقة. ولا يجوز للمستخدم أن يدعي أنه ينتسب إلى الموقع الإلكتروني الخاص بالتقدّم المحرز في مجال التعليم (أو إلى التقرير العالمي لرصد التعليم).`}</p>
    <h2 {...{
      "id": "الروابط-المؤدية-إلى-الموقع-الإلكتروني"
    }}>{`الروابط المؤدية إلى الموقع الإلكتروني`}</h2>
    <p>{`يجوز لموقع إلكتروني خارجي أن يضم رابطاً إلكترونياً مؤدياً إلى أية صفحة من صفحات الموقع الخاص بالتقدّم المحرز في مجال التعليم من غير استئذان. غير أنه يجب ألا يفضي ذلك إلى خرق حقوق الملكية الفكرية للتقرير العالمي لرصد التعليم، ولا سيما فيما يخص الأسماء أو الشعارات أو حقوق التأليف والنشر أو حقوق المؤلِّف.`}</p>
    <h2 {...{
      "id": "إخلاء-المسؤولية"
    }}>{`إخلاء المسؤولية`}</h2>
    <p>{`يُشرف التقرير العالمي لرصد التعليم على هذا الموقع الإلكتروني بغية الارتقاء باطلاع الجمهور على البيانات والمعلومات ذات الصلة بالموضوع. ويبذُل قصارى جهده لتكون المعلومات الواردة في هذا الموقع دقيقة وكاملة، ولكن ليس بوسعه ضمان ذلك. وهدفنا هو أن تظل تلك المعلومات محدَّثة ودقيقة. وإذا استرعي انتباهنا إلى أخطاء، فسنسعى إلى تصحيحها.`}</p>
    <p>{`ولا تعبّر التسميات المستخدمة في مضامين الموقع وطريقة عرض تلك المضامين على الموقع عن أي رأي لليونسكو بشأن الحالة القانونية لأي بلد أو إقليم أو مدينة أو منطقة، ولا بشأن سلطات هذه الأماكن أو بشأن رسم حدودها أو تخومها.`}</p>
    <p>{`ويتحمل التقرير العالمي لرصد التعليم المسؤولية عن اختيار المضامين الوقائعية الواردة في هذا الموقع وطريقة عرضها، ولا تمثل تلك المضامين بالضرورة وجهات نظر اليونسكو ولا تلزم المنظمة بأي شيء.`}</p>
    <p>{`ونبذل قصارى جهدنا بغية التقليل إلى أدنى حد من الاختلال الناجم عن الأخطاء التقنية. لكن بعض البيانات أو المعلومات الواردة في الموقع قد تكون أُنشئت أو نُظِّمت في ملفات أو أنساق مختلة، لذا لا نستطيع ضمان ألا تنقطع الخدمة أو ألا تتأثّر بمثل هذه المشكلات بطريقة أخرى. ولا يتحمّل التقرير العالمي لرصد التعليم أية مسؤولية فيما يخص مثل هذه المشكلات (من قبيل الإخفاق في الأداء و الفيروراسات الحاسوبية وإخفاق خط الاتصال و تعديل المضامين وما إلى ذلك) الناتجة عن استخدام الموقع الخاص بالتقدّم المحرز في مجال التعليم أو أي رابط مؤدٍ إلى مواقع خارجية.`}</p>
    <h2 {...{
      "id": "حرمة-الشؤون-الشخصية"
    }}>{`حرمة الشؤون الشخصية`}</h2>
    <p>{`بمجرّد دخول الموقع الإلكتروني الخاص بالتقدّم المحرز في مجال التعليم، ستُحفظ في خوادمنا معلومات من قبيل عناوين بروتوكول الإنترنت وسلوك تصفّح الموقع والبرمجية المستخدمة والوقت المقضي، إضافةً إلى معلومات مماثلة أخرى. ولن تشير تلك المعلومات إلى أسماء المستخدمين وستسخَّر لتحليل حركة زيارة الموقع.`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;