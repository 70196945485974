import css from "@emotion/css";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";
import { PageLink } from "../links";

const useStyles = mkStyles(({ spacing: { base8 }, palette }) => {
  return {
    root: css`
      grid-column: ${M.layoutAreas.main};
      text-align: center;
      padding: ${base8(3)} 0;
      margin-bottom: ${base8(8)};

      & a {
        ${M.fontHeading3};
        color: ${palette.text.white};
        background-color: ${palette.action.main};
        padding: 12px 11px;
        border: 0;
        border-radius: 4px;

        &:hover {
          background-color: ${palette.action.dark};
        }
      }
    `,
  };
});

export const LandingButton = ({ children }: $PropsWithChildren) => {
  const styles = useStyles();

  return (
    <div css={styles.root}>
      <PageLink name="access" query={{}}>
        <a>{children}</a>
      </PageLink>
    </div>
  );
};
