import css from "@emotion/css";
import { ScaleOrdinal } from "d3-scale";
import nanoid from "nanoid";
import React from "react";
import { mapChildrenRec } from "../../lib/react.extended";
import * as M from "../../materials";
import { mkScrollyColorScale } from "../../materials";
import { O } from "../../prelude";
import { ScrollyProvider, useScrolly } from "../scrolly";

// -----------------------------------------------------------------------------
// ScrollyArea

const scrollyAreaRoot = css`
  position: relative;
  grid-column: ${M.layoutAreas.full};
  margin: 0 0 25vh 0;
`;

const scrollyAreaRootFallback = M.onlyIE(css`
  margin-bottom: ${M.spacing.base8(5)};
`);

export const ScrollyArea = React.memo(({ children }: $PropsWithChildren) => {
  const sections = React.useMemo(() => {
    const set = new Set<string>();
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.props && child.props.mdxType === "ScrollySection") {
          set.add(child.props.id);
        }
      }
    });
    return set;
  }, [children]);

  return (
    <ScrollyProvider sections={sections}>
      <div css={[scrollyAreaRoot, scrollyAreaRootFallback]}>{children}</div>
    </ScrollyProvider>
  );
});

// -----------------------------------------------------------------------------
// ScrollySticky

const scrollyStickyRoot = css`
  position: sticky;
  top: 0;
  z-index: 0;
  margin-bottom: -25vh;

  /* Clearfix */
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
`;

const scrollyStickyRootFallback = M.onlyIE(css`
  margin-bottom: 0;
`);

export const ScrollySticky = React.memo(({ children }: $PropsWithChildren) => {
  return (
    <div css={[scrollyStickyRoot, scrollyStickyRootFallback]}>{children}</div>
  );
});

// -----------------------------------------------------------------------------
// ScrollySection

const scrollySectionRoot = css`
  position: relative;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${M.zIndex.overlapContext};
  margin: 60vh 0 80vh 0;

  &:last-child {
    margin-bottom: 55vh;
  }
`;

const scrollySectionRootFallback = M.onlyIE(css`
  margin: ${M.spacing.base8(3)} 0;
  &:last-child {
    margin-bottom: 0;
  }
`);

export const ScrollySection = React.memo(
  ({ id: mbId, children }: { id?: string; children: React.ReactNode }) => {
    const [id] = React.useState(() => mbId || nanoid());
    const { ref, sections } = useScrolly(id);

    const colorScale = React.useMemo(() => {
      return mkScrollyColorScale(Array.from(sections.values()), O.some(id));
    }, [id, sections]);

    return (
      <div ref={ref} css={[scrollySectionRoot, scrollySectionRootFallback]}>
        <ScrollySectionInner colorScale={colorScale}>
          {children}
        </ScrollySectionInner>
      </div>
    );
  }
);

export const ScrollyStep = React.memo(({ id: mbId }: { id?: string }) => {
  const [id] = React.useState(() => mbId || nanoid());
  const { ref } = useScrolly(id);

  return (
    <div ref={ref} css={[scrollySectionRoot, scrollySectionRootFallback]} />
  );
});

const scrollySectionInner = css`
  position: relative;
  transform: translateY(-50%);
  padding: ${M.spacing.base8(2)} ${M.spacing.base8(3)};
  margin: 0 ${M.spacing.base8(2)};
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* FIXME: define global translucency style */
  border-radius: ${M.spacing.base8(0.5)};
  box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.28); /* FIXME: define global shadow styles */
  width: 90%;

  @media ${M.bpUp("s")} {
    width: ${M.spacing.base8(56)};
  }
`;

const scrollySectionInnerFallback = M.onlyIE(css`
  transform: none;
`);

const ScrollySectionInner = React.memo(
  ({
    children,
    colorScale,
  }: $PropsWithChildren<{ colorScale: ScaleOrdinal<string, string> }>) => {
    const enhancedChildren = mapChildrenRec(children, (child) => {
      // This mdxType needs to be an exact match to the component's name (see highlight.tsx)
      return React.isValidElement(child) &&
        child.props.mdxType === "Highlight" && // <Highlight>
        Array.isArray(child.props.children) &&
        typeof child.props.children[0] === "string"
        ? React.cloneElement<$FixMe>(child, {
            color: colorScale(child.props.children[0]),
          })
        : child;
    });

    return (
      <div css={[scrollySectionInner, scrollySectionInnerFallback]}>
        {enhancedChildren}
      </div>
    );
  }
);
