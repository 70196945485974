import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID_TO_EDU",
  title: /*i18n*/ {
    id: "fig.finance.aid.AID_TO_EDU.title",
    defaults: ``,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.AID_TO_EDU.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.AID_TO_EDU.source",
    defaults: ``,
  },
};
