
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Accès";
export const lead = "À l’échelle mondiale, 87 % des enfants achèvent le cycle primaire, 77 % des adolescents achèvent le premier cycle du secondaire et 58 % des jeunes achèvent le deuxième cycle du secondaire.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Le monde n’a pas tenu sa promesse qui était d’envoyer tous les enfants à l’école primaire avant 2015. Le nouvel objectif en matière d’éducation, l’ODD 4, a fixé un niveau d’ambition encore plus élevé, préconisant que tous les jeunes achèvent leurs études secondaires avant l’horizon 2030. Pour y parvenir, tous les enfants en âge de commencer l’école auraient dû y entrer en 2018 mais, en réalité, seuls 70 % d’entre eux l’avaient fait dans les pays à faible revenu, par exemple.`}</p>
    </blockquote>
    <p>{`Un enfant sur 60 environ, pour la plupart issus de pays à faible revenu, `}<strong parentName="p">{`n’ira jamais à l’école`}</strong>{`. Les filles risquent encore davantage que les garçons de ne jamais être scolarisées : en effet, selon les discriminations à leur encontre, elles sont censées s’occuper des enfants et assumer les tâches domestiques.`}</p>
    <h2 {...{
      "id": "scolarité-préprimaire"
    }}>{`Scolarité préprimaire`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p>{`La `}<strong parentName="p">{`fréquentation d’un établissement préprimaire`}</strong>{` est un point de départ décisif dans le parcours éducatif des enfants. Pourtant, dans les pays à faible revenu, seuls 43 % des enfants sont scolarisés dans le préprimaire un an avant d’entrer à l’école primaire.`}</p>
    </IndicatorReference>
    <p>{`Depuis 2000, les progrès en matière de scolarité préprimaire ont été lents mais constants dans la plupart des pays, y compris dans de nombreux pays comptant parmi les plus pauvres, mais beaucoup d’autres sont restés à la traîne. À l’échelle mondiale, 75 % des enfants reçoivent actuellement une éducation préprimaire, contre 65 % en 2002.`}</p>
    <p>{`Certains pays ont enregistré des progrès spectaculaires. Au Kirghizstan, par exemple, le taux de participation est passé de 42 % en 2000 à 84 % en 2021. En RDP lao, il est passé de 10 % à 71 % au cours de la même période.`}</p>
    <p>
  <HighlightByIndex index="0" mdxType="HighlightByIndex">Le Guatemala</HighlightByIndex> et le 
  <HighlightByIndex index="1" mdxType="HighlightByIndex">Niger</HighlightByIndex>, 
  présentés ci-après, sont deux exemples de pays ayant connu une augmentation constante de la scolarité préprimaire, 
  bien qu’il existe aujourd’hui d’immenses disparités entre les deux pays. 
  Sélectionnez votre pays pour connaître ses progrès.
    </p>
    <p>{`Trois enfants sur quatre fréquentent l’enseignement préprimaire un an avant d’entrer à l’école primaire.`}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "participation-au-delà-de-lâge-normal"
    }}>{`Participation au-delà de l’âge normal`}</h2>
    <IndicatorReference id="4.1.65" mdxType="IndicatorReference">
      <p>{`Nous n’évoquons pas assez le fait que, dans un certain nombre de pays, plus d’un quart des enfants qui s’inscrivent à l’école primaire ont `}<strong parentName="p">{`dépassé l’âge normal`}</strong>{`, autrement dit qu’ils sont nettement plus âgés que les élèves de leur niveau d’étude ne sont censés l’être. Cela a d’importantes répercussions sur leur avenir, et fait augmenter par la suite la probabilité de redoubler, d’échouer aux examens et finalement d’abandonner l’école.`}</p>
    </IndicatorReference>
    <p>{`Ce graphique sur la `}<strong parentName="p">{`participation à l’éducation`}</strong>{` indique l’âge des enfants, des adolescents et des jeunes lorsqu’ils fréquentent l’enseignement préprimaire, primaire, des premier et deuxième cycles du secondaire et l’enseignement post-secondaire. Les différentes couleurs indiquent combien d’enfants ont dépassé l’âge normal par rapport à leur niveau d’étude. La zone grisée montre le pourcentage d’enfants et d’adolescents déscolarisés par âge.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="OVERAGE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="PAK" mdxType="ScrollySection">
Au <Highlight mdxType="Highlight">Pakistan</Highlight>, la plupart des enfants de 10 ans devraient aller à l’école,
pourtant 21 % ne sont toujours pas scolarisés à cet âge. Beaucoup d’entre eux sont trop âgés par
rapport à leur niveau d’étude. Certains élèves âgés de 14 ans sont encore à l’école primaire,
par exemple.
      </ScrollySection>
      <ScrollySection id="LBR" mdxType="ScrollySection">
Le taux d’élèves en retard pour leur âge est encore plus élevé dans les pays comme le{" "}
        <Highlight mdxType="Highlight">Libéria</Highlight>, où 28 % des enfants de 10 ans sont scolarisés dans le préprimaire
et où 20 % des jeunes de 18 ans fréquentent encore l’école primaire.
      </ScrollySection>
      <ScrollySection id="HTI" mdxType="ScrollySection">
        <Highlight mdxType="Highlight">Haïti</Highlight> est confronté au même problème
      </ScrollySection>
      <ScrollySection id="MWI" mdxType="ScrollySection">
de même que le <Highlight mdxType="Highlight">Malawi</Highlight>.
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "enfants-non-scolarisés"
    }}>{`Enfants non scolarisés`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`À l’échelle mondiale, 244 millions d’enfants et de jeunes ne sont `}<strong parentName="p">{`pas scolarisés`}</strong>{`. Un nouveau modèle élaboré par l’Institut de statistique de l’UNESCO et le Rapport mondial de suivi sur l’éducation suggère que le taux d’enfants non scolarisés à l’école primaire baisse plus vite qu’on ne l’aurait pensait, même si le rythme de cette diminution a ralenti ces dernières années. Le nombre d’enfants non scolarisés au premier cycle du secondaire est resté relativement stable depuis 2014.`}</p>
      <p>{`Les conflits sont l’une des causes principales de stagnation. Malheureusement, les conflits signifient également que la disponibilité et la qualité des données se détériorent, même si certains pays continuent de communiquer des estimations. Il est également nécessaire de communiquer davantage d’informations de manière durable au fil du temps pour évaluer correctement les effets récents de la COVID-19 sur la scolarisation.`}</p>
      <p>{`  Les taux d’enfants non scolarisés continuent de diminuer, mais plus lentement.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="OOSRATE" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="NIC,PAN" mdxType="ScrollySection">
          <Highlight mdxType="Highlight">Le Nicaragua</Highlight> et <Highlight mdxType="Highlight">le Panama </Highlight> ont progressé selon
des voies très différentes. Au primaire et au secondaire, le taux d’enfants
non scolarisés a chuté au Nicaragua, tandis qu’il a augmenté au
Panama.
        </ScrollySection>
        <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
          <Highlight mdxType="Highlight">L’Érythrée</Highlight> et <Highlight mdxType="Highlight">l’Éthiopie </Highlight> présentent aussi des
taux de progression très éloignés. Les séquelles du conflit en Érythrée ont eu un impact visible
sur l’accès à l’éducation, tandis que l’Éthiopie a donné la priorité à l’éducation dans son
budget, ce qui a permis de réduire le nombre d’enfants non scolarisés en un court laps de temps.
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`La croissance démographique explique également que le nombre d’enfants non scolarisés ne recule pas.`}</p>
    <p>{`En Afrique subsaharienne, la `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`population en âge de fréquenter l’école`}</HighlightByIndex>{` primaire a quasiment doublé entre 2000 et 2022. Par conséquent, bien que le `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`taux d’enfants non scolarisés `}</HighlightByIndex>{` ait diminué de près de moitié au cours de cette période, le `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`nombre d’enfants non scolarisés `}</HighlightByIndex>{` n’a pratiquement pas évolué.`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "achèvement"
    }}>{`Achèvement`}</h2>
    <IndicatorReference id="4.1.2" mdxType="IndicatorReference">
      <p>{`L’ODD 4 a pour finalité absolue y `}<strong parentName="p">{`l’achèvement`}</strong>{` universel du second cycle de l’enseignement secondaire, et non la simple scolarisation. Et cela en dépit du fait que, dans de nombreux pays, l’éducation n’est gratuite et obligatoire que jusqu’au premier cycle du secondaire tout au plus.`}</p>
      <p>{`Le monde n’est pas près de parvenir à l’achèvement universel du cycle primaire avant l’échéance fixée, ni à plus forte raison à l’achèvement du second cycle du secondaire. Seuls six jeunes sur 10 termineront l’enseignement secondaire en 2030. `}</p>
      <p>{`L’indicateur porte sur des enfants pouvant achever des cycles d’enseignement jusqu’à 3 à 5 ans après l’âge officiel de fin d’études – ce qui peut être défini comme `}<Tooltip title="l’achèvement des études à l’âge voulu" content="Les enfants qui achèvent des cycles d’enseignement jusqu’à 3 à 5 ans après l’âge normal de la dernière année " mdxType="Tooltip">{`l’achèvement des études à l’âge voulu`}</Tooltip>{`. Mais de nombreux enfants achèvent leurs études avec un plus grand retard, ce qui peut être défini comme `}<Tooltip title="l’achèvement ultérieur" content="Les enfants qui achèvent des cycles d’enseignement jusqu’à 8 ans après l’âge normal de la dernière année" mdxType="Tooltip">{`ultimate completion`}</Tooltip>{`. `}</p>
      <p>{`Sur le graphique ci-après, au Kenya, par exemple, un écart de 20 points de pourcentage sépare `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`l’achèvement à l’âge voulu `}</HighlightByIndex>{` (74%) de `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`l’achèvement ultérieur `}</HighlightByIndex>{` completion (93%) au niveau primaire. Le Mozambique affiche un écart similaire.`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Savoir quand sont scolarisés les enfants, à quel âge et s’ils vont au bout de leurs études sont autant de préoccupations politiques essentielles.`}</p>
    <p>{`Les prochains thèmes abordés sur ce site nous permettent de comprendre si nous sommes près de réaliser l’équité dans l’éducation, ce que les élèves apprennent, quelle est la qualité de leur éducation et qui la finance.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-estimates.org"
          }}>{`Visualizing Indicators of Education for the World (VIEW)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000382577"
          }}>{`New estimation confirms out-of-school population is growing in sub-Saharan Africa`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
          }}>{`Education above the odds – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining data on out-of-school children, Completion and learning to offer a more comprehensive view on SDG 4`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;