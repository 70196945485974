import { AnimatePresence, motion, Variant, Variants } from "framer-motion";
import React, { useState } from "react";
import { css } from "@emotion/core";
import { Button } from "./button";
import { mkStylesWithProps } from "hooks";
import { unescoMarineBlue } from "materials";
import { lighten } from "lib/colorManipulator";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      position: "absolute",
      left: 0,
    } as Variant;
  },
  center: {
    zIndex: 1,
    x: 0,
    position: "static",
  } as Variant,
  exit: (direction: number) => {
    return {
      position: "absolute",
      left: 0,
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
    };
  },
} as Variants;

const useStyles = mkStylesWithProps((theme) => ({
  indicator: css`
    display: inline-block;
    background-color: ${lighten(unescoMarineBlue, 0.75)};
    width: 120px;
    margin-right: 0.5rem;
    height: 5px;
    border-radius: 2px;
    font-size: 0;
    cursor: pointer;
    position: relative;

    &:before {
      height: 2rem;
      position: absolute;
      top: -1rem;
      z-index: 0;
      content: " ";
      width: 100%;
      padding-right: 0.5rem;
      display: inline-block;
      box-sizing: content-box;
    }
  `,
  indicatorSelected: css`
    background-color: ${theme.palette.brand.marine.main};
    width: 120px;
  `,
}));

type FooterProps = {
  paginate: (d: number) => void;
  page: number;
  count: number;
};

const DefaultFooterComponent = ({ paginate }: FooterProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <Button rounded inverted inline onClick={() => paginate(-1)}>
        Previous
      </Button>
      <Button rounded inline onClick={() => paginate(+1)}>
        Next
      </Button>
    </div>
  );
};

export default function Carousel({
  children: rawChildren,
  onSlideChange,
  FooterComponent = DefaultFooterComponent,
  debug,
}: {
  children: React.ReactChild | React.ReactChild[];
  onSlideChange?: (n: number) => void;
  FooterComponent?: typeof DefaultFooterComponent;
  debug?: boolean;
}) {
  const children = rawChildren instanceof Array ? rawChildren : [rawChildren];
  const [[page, direction], setPage] = useState([0, 0]);
  const count = children.length;
  const paginate = (newDirection: number) => {
    const newPageNumber = (page + count + newDirection) % count;
    setPage([newPageNumber, newDirection]);
    onSlideChange?.(newPageNumber);
  };

  const styles = useStyles({});

  if (debug) {
    return (
      <div
        css={css`
          display: flex;
          width: 1000px;
          background: white;
          gap: 1rem;

          & > * {
            width: 250px;
          }
        `}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      css={css`
        line-height: 24px;
        position: relative;
        top: 4px;
      `}
    >
      {count > 1 && (
        <div
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          {Array(count)
            .fill(null)
            .map((_, i) => {
              return (
                <motion.div
                  key={i}
                  onClick={() => setPage((p) => [i, p[0] < i ? -1 : 1])}
                  initial={false}
                  animate={{
                    width: i === page ? 32 : 12,
                  }}
                  css={[
                    styles.indicator,
                    i === page ? styles.indicatorSelected : null,
                  ]}
                >
                  {i}
                </motion.div>
              );
            })}
        </div>
      )}
      <motion.div
        css={css`
          display: flex;
          overflow: hidden;
          position: relative;
          margin: 0;
        `}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            css={css`
              width: 100%;
              flex-shrink: 0;
              overflow: hidden;
            `}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: {
                type: "spring",
                stiffness: 300,
                damping: 30,
                friction: 1000,
              },
            }}
          >
            {children[page]}
          </motion.div>
        </AnimatePresence>
      </motion.div>
      <div
        css={css`
          margin-top: 1.5rem;
        `}
      >
        <FooterComponent paginate={paginate} count={count} page={page} />
      </div>
    </div>
  );
}
