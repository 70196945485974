import css from "@emotion/css";
import styled from "@emotion/styled";
import { MDXProvider } from "@mdx-js/react";
import React from "react";
import { mkStyles } from "../../hooks";
import { layoutAreas, lightText, fontHeading4 } from "../../materials";

const rootStyles = css`
  margin: 1.5em 0;
  grid-column: ${layoutAreas.side};

  color: ${lightText};

  h4 {
    ${fontHeading4}
  }

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const useComponents = mkStyles(({}) => {
  return {
    p: styled.p`
      & + p {
        margin-top: 0;
      }
    `,
  };
});

export const Aside = ({
  children,
  mobilePlacement = "top",
}: $PropsWithChildren & { mobilePlacement?: "top" | "bottom" }) => {
  return (
    <div
      css={[
        rootStyles,
        css`
          ${mobilePlacement === "top" ? "grid-row: 2 / span 99" : undefined};
        `,
      ]}
    >
      <MDXProvider components={useComponents()}>{children}</MDXProvider>
    </div>
  );
};
