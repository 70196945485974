import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_finance_fig_AID_LICS";
import { ChartRenderer, mkGemDataDecoder } from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io } from "../prelude";

export * from "../data/data_finance_fig_AID_LICS";

const TargetLevel = io.keyof({
  TOTAL: null,
  BASIC: null,
});
type TargetLevel = io.TypeOf<typeof TargetLevel>;

const targetLevelLookup: Record<TargetLevel, MessageDescriptor> = {
  TOTAL: t("fig.finance.AID_LICS.total")`Total`,
  BASIC: t("fig.finance.AID_LICS.basic")`Basic`,
};

const targetLevelDetailedLookup: Record<TargetLevel, MessageDescriptor> = {
  TOTAL: t("fig.finance.AID_LICS.total_detail")`Out of total aid to education`,
  BASIC: t("fig.finance.AID_LICS.basic_detail")`Out of aid to basic education`,
};

export const Data = mkGemDataDecoder(["value", "ind_id", "year"], {
  target_level: TargetLevel,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const entityData = React.useMemo(
    () =>
      data.map((x) => ({
        ...x,
        target_level_name: i18n._(targetLevelLookup[x.target_level]),
        target_level_detailed: i18n._(
          targetLevelDetailedLookup[x.target_level]
        ),
      })),
    [i18n, data]
  );

  return (
    <LineChart
      tLabel={(s) => s}
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      x="year"
      yTicks={[0, 0.1, 0.2, 0.3, 0.4]}
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".0%"
      category="datum.target_level_detailed"
      yAxisLabel={i18n._(t`going to low-income countries`)}
      tooltipFormatX={(x) => x.target_level_name}
      colorLegend
      values={entityData}
      showTooltip
    />
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_AID_LICS.json"),
  csv: require("../data/data_finance_fig_AID_LICS.zip"),
  xlsx: require("../data/data_finance_fig_AID_LICS.xlsx"),
  metadata,
  Data,
  Chart,
  size: "narrow",
});
