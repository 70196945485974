import {
  failure,
  initial,
  pending,
  RemoteData,
  success,
} from "@devexperts/remote-data-ts";
import { Decoder } from "io-ts";
import { useCallback, useState } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { FileReaderReject, readFile, ReadFileException } from "../lib";
import { E } from "../prelude";
export type DropzoneException = ReadFileException | FileReaderReject;

export function useDropzone<I, O>(decoder: Decoder<I, O>) {
  const [dropState, setDropState] =
    useState<RemoteData<DropzoneException, O>>(initial);
  const onDrop = useCallback(
    (acceptedFiles: Array<File>, rejectedFiles: Array<File>) => {
      if (acceptedFiles.length === 1) {
        setDropState(pending);
        readFile(decoder)(acceptedFiles[0])().then(
          E.fold(
            (error) => setDropState(failure(error)),
            (value) => setDropState(success(value))
          )
        );
      } else if (rejectedFiles.length > 0) {
        setDropState(failure(new FileReaderReject(rejectedFiles)));
      } else {
        setDropState(initial);
      }
    },
    [decoder, setDropState]
  );

  const { getRootProps, getInputProps, isDragActive } = useReactDropzone({
    accept: "application/json",
    multiple: false,
    onDrop,
  });

  return { dropState, getRootProps, getInputProps, isDragActive };
}
