import { ForwardedRef } from "react";
import { useEffect, useRef } from "react";

/**
 * Makes it easier to work with React.forwardRef so you do not have
 * to handle the two cases callback ref and object ref.
 *
 * @see https://github.com/facebook/react/issues/24722
 *
 * @example
 * ```
 * const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
 * const forwardedRef = useForwardRef<HTMLInputElement>(ref);
 *   // Cool code....
 * });
 */
export default function useForwardRef<T>(
  ref: ForwardedRef<T>,
  initialValue: T | null = null
) {
  const targetRef = useRef<T>(initialValue);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === "function") {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);

  return targetRef;
}
