export const PADDING = {
  top: 10,
  bottom: 50,
  left: 0,
  right: 0,
}; // in px

export const OPACITY_FADED = 0.5;
export const LIGHTEN_COEFFICIENT = 0.6;
export const SERIES_STROKE_WIDTH = 3;
export const ANNOTATION_WIDTH = 230;
