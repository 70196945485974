import React from "react";
import { css } from "@emotion/core";
import { motion } from "framer-motion";

import { mkStyles, useQueryParamsWithDefault, useTheme } from "hooks";
import * as M from "../../materials";
import {
  REnv,
  getArticleName,
  findArticleAlias,
  findArticleThemeAlias,
  subthemesIndex,
} from "routes";
import { useI18n } from "locales";
import { PageLinkWithAnchor } from "components/links";
import { pipe, O } from "../../prelude";
import { Trans } from "@lingui/macro";

const useStyles = mkStyles(({ activeTheme, spacing, breakpoints }) => ({
  subnav: css`
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  navlink: css`
    padding: ${spacing.base8(2)} ${spacing.base8(4)};
    cursor: pointer;
    transform: translateY(${spacing.base8(0.5)});
    background: #fcfcfe;
    border: 0;
    width: 340px;
    color: ${activeTheme.textColor};
    text-align: left;
    transition: 0.4s transform;
    &:hover {
      transform: translateY(${spacing.base8(0)});
    }
    @media ${breakpoints.down("s")} {
      padding: ${spacing.base8(2)} ${spacing.base8(2)};
    }
  `,
  contentRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing.base8(4)};
    @media ${breakpoints.down("s")} {
      gap: ${spacing.base8(1)};
    }
  `,
}));

export default function SubThemesNavigation() {
  const { alias } = useQueryParamsWithDefault(REnv);
  const styles = useStyles();
  const { client } = useTheme();
  const i18n = useI18n();

  const aliasArticle = findArticleAlias(alias);

  const subthemes = pipe(
    aliasArticle,
    O.fold(
      () => [],
      (a) => subthemesIndex[findArticleThemeAlias(a)]
    )
  );

  return (
    <motion.ul id="subnav" css={styles.subnav}>
      {subthemes.map((subtheme, index) => {
        return (
          <PageLinkWithAnchor
            name={subtheme}
            key={subtheme}
            query={{}}
            anchor="subnav"
          >
            <motion.li
              css={[
                styles.navlink,
                css`
                  opacity: ${subtheme === alias ? 1 : 0.5};
                  border-top-left-radius: ${index === 0
                    ? M.spacing.base8(0.5)
                    : 0};
                  border-top-right-radius: ${index === subthemes.length - 1
                    ? M.spacing.base8(0.5)
                    : 0};
                `,
              ]}
            >
              <a
                css={css`
                  ${styles.contentRow};
                  direction: ${client.isRTL ? "rtl" : "ltr"};
                `}
              >
                <div
                  css={css`
                    text-align: ${client.isRTL ? "right" : "left"};
                  `}
                >
                  <p
                    css={css`
                      ${M.fontMeta}
                    `}
                  >
                    <Trans>Sub-theme</Trans>
                  </p>
                  <h3
                    css={css`
                      ${M.fontHeading4}

                      @media ${M.bpDown("s")} {
                        ${M.fontBody2}
                      }
                    `}
                  >
                    {getArticleName(i18n, subtheme)}
                  </h3>
                </div>
              </a>
            </motion.li>
          </PageLinkWithAnchor>
        );
      })}
    </motion.ul>
  );
}
