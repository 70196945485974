import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { scaleLinear } from "d3-scale";
import { constant } from "fp-ts/lib/function";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_learning_fig_LEARN_AGE_GRADE";
import {
  ChartRenderer,
  CountryId,
  getCountryName,
  mkGemDataDecoder,
  useFigureControlItems,
  useMultiSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { extentNumber, fromNewtype, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, io, O, pipe, R } from "../prelude";

export * from "../data/data_learning_fig_LEARN_AGE_GRADE";

const DEFAULT_REGIONS = ["IND:Punjab", "PAK:Punjab", "PAK:Sindh"];

const Index = io.keyof({
  age: null,
  grade: null,
});
type Index = io.TypeOf<typeof Index>;

const indexLookup: Record<Index, MessageDescriptor> = {
  age: t("fig.learning.LEARN_AGE_GRADE.age")`Age`,
  grade: t("fig.learning.LEARN_AGE_GRADE.grade")`Grade`,
};

export const Data = mkGemDataDecoder(["value"], {
  index: Index,
  index_value: io.number,
  iso3c: fromNewtype<CountryId>(io.string),
  region: io.string,
});
export type Data = io.TypeOf<typeof Data>;

const mkKey = (x: Data[number]) => `${x.iso3c}:${x.region}`;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [selectedRegions, actions] = useMultiSelectState("regions");

  const data = React.useMemo(() => {
    return dataRaw.map((x) => {
      const country_name = getCountryName(env.countries, x.iso3c);
      return {
        ...x,
        key: mkKey(x),
        country_name,
        full_name: `${country_name} – ${x.region}`,
        index_name: i18n._(indexLookup[x.index]),
      };
    });
  }, [i18n, env.countries, dataRaw]);

  const regionControlItems = useFigureControlItems(
    data,
    (x) => x.key,
    (x) => x.full_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "regions",
      {
        type: "MultiSelect",
        label: i18n._(t`Regions`),
        selected: O.some(DEFAULT_REGIONS),
        maxItems: 3,
      },
      regionControlItems
    );
  }, [i18n, actions, regionControlItems]);

  const { chartData, lookupExtent } = React.useMemo(() => {
    const lookupExtent_ = pipe(
      unsafeFromArray(data),
      An.groupBy((x) => x.index),
      R.map((xs) => extentNumber(xs, (x) => x.index_value)),
      R.map((xs) => scaleLinear().domain(xs).range([0, 1]))
    );

    return {
      chartData: data
        .filter((x) =>
          pipe(
            selectedRegions,
            O.fold(
              () => false,
              (xs) => xs.includes(x.key)
            )
          )
        )
        .map((x) => ({
          ...x,
          normalized_value: lookupExtent_[x.index](x.index_value),
        })),
      lookupExtent: (x: Index) => lookupExtent_[x],
    };
  }, [data, selectedRegions]);

  const columnCount = pipe(
    selectedRegions,
    O.fold(
      () => 1,
      (xs) => xs.length
    )
  );

  const isNarrow = client.screenMDown || columnCount > 3;

  return (
    <LineChart
      tLabel={(s) => s}
      height={
        client.screenSDown
          ? M.chartHeight.xs
          : client.screenMDown
          ? M.chartHeight.s
          : M.chartHeight.m
      }
      endLabel={!client.screenMDown}
      x="normalized_value"
      numberFormat=".0%"
      category="datum.index_name"
      colorLegend
      column="full_name"
      columns={columnCount}
      values={chartData}
      xAxisFormat={(x) => {
        if (typeof x === "number") {
          const ageValue = lookupExtent("age").invert(x);
          const gradeValue = lookupExtent("grade").invert(x);
          return x > 0 && isNarrow
            ? `${ageValue} / ${gradeValue}`
            : `${i18n._(indexLookup["age"])} ${ageValue} / ${i18n._(
                indexLookup["grade"]
              )} ${gradeValue}`;
        }
        return "";
      }}
      tooltipFormatX={constant("")}
      tooltipFormatTitle={(x) =>
        `${i18n._(indexLookup[x.index])} ${x.index_value}`
      }
      xScale="linear"
      showTooltip={"crosshair"}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_LEARN_AGE_GRADE.json"),
  csv: require("../data/data_learning_fig_LEARN_AGE_GRADE.zip"),
  xlsx: require("../data/data_learning_fig_LEARN_AGE_GRADE.xlsx"),
  metadata,
  Data,
  Chart,
  size: "wide",
});
