/**
 * Note: lot's of hard-coded things in this chart, because it's the only one.
 * Please adjust, if it turns out we need it more generically.
 */
import css from "@emotion/css";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Delaunay, Voronoi } from "d3-delaunay";
import { format } from "d3-format";
import * as d3_sankey from "d3-sankey";
import * as d3_shape from "d3-shape";
import { option } from "fp-ts";
import { flatten } from "fp-ts/lib/ReadonlyArray";
import React, { SVGProps, useCallback } from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { useContentRect, useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, O, pipe, R } from "../prelude";
import { ChartBasis } from "./stacked-bar";

const formatCurrency = (i18n: I18n, x: number) => {
  const amount = format(".0f")(x);
  return i18n._(t`USD ${amount} million`);
};

const NODE_PADDING = 8;

const columnTitleS = css`
  ${M.fontChartGroupTitle};
  fill: ${M.blackText};
`;

interface Node {
  id: string;
}

interface Link {
  // Can't type it correctly to { id: string } 🤷
  source: $FixMe;
  // Can't type it correctly to { id: string } 🤷
  target: $FixMe;
  value: number;
  linkId: number;
}

interface ColorPalette {
  id: string;
  palette: Array<string>;
  label: string;
}
export interface SankeyProps extends ChartBasis {
  data: {
    nodes: Array<Node>;
    links: Array<Link>;
  };
  height: number;
  width: number;
  colorPalettes: Array<ColorPalette>;
  nodeGroups: Array<any>;
}

const VoronoiInteractionLayer_ = ({
  voronoi,
  onMouseOverCell,
  onMouseLeaveCell,
  onTouchMoveCell,
  onTouchEndCell,
  debug,
}: {
  voronoi: Voronoi<unknown>;
  debug?: boolean;
  onMouseOverCell: (ev: React.MouseEvent<SVGPathElement>) => void;
  onTouchMoveCell: (ev: React.TouchEvent<SVGPathElement>) => void;
  onTouchEndCell: (ev: React.TouchEvent<SVGPathElement>) => void;
  onMouseLeaveCell: (ev: React.MouseEvent<SVGPathElement>) => void;
} & SVGProps<SVGSVGElement>) => {
  return (
    <>
      {Array(voronoi.delaunay.points.length / 2)
        .fill(null)
        .map((_, i) => {
          return (
            <path
              stroke={debug ? "rgba(0, 0, 0, 0.25)" : "transparent"}
              strokeWidth={1}
              fill="transparent"
              key={i}
              d={voronoi.renderCell(i)}
              data-cellindex={i}
              onMouseOver={onMouseOverCell}
              onTouchStart={onTouchMoveCell}
              onTouchMove={onTouchMoveCell}
              onTouchEnd={onTouchEndCell}
              onMouseLeave={onMouseLeaveCell}
            />
          );
        })}
    </>
  );
};

const VoronoiInteractionLayer = React.memo(VoronoiInteractionLayer_);

const useSankeyVoronoi = ({
  width,
  height,
  nodes,
}: {
  nodes: d3_sankey.SankeyNode<Node, Link>[];
  width: number;
  height: number;
}) => {
  return React.useMemo(() => {
    const nodeVertices = flatten(
      nodes.map((node) => {
        return [
          [node.x0, node.y0],
          [node.x1, node.y0],
          [node.x0, node.y1],
          [node.x1, node.y1],
        ];
      })
    ) as [number, number][];

    const delaunay = Delaunay.from(nodeVertices);
    return delaunay.voronoi([0, 0, width, height]);
  }, [nodes, width, height]);
};

export const Sankey = React.memo(Sankey_);
function Sankey_({
  data,
  height,
  width,
  colorPalettes,
  nodeGroups,
}: SankeyProps) {
  const { client } = useTheme();

  const dimensions = React.useMemo(() => {
    const margin = { top: 30, right: 1, bottom: 38, left: 1 };
    const outerHeight = height + margin.top + margin.bottom;
    const innerHeight = height;
    const outerWidth = width;
    const innerWidth = width - margin.left - margin.right;
    return { margin, outerHeight, innerHeight, outerWidth, innerWidth };
  }, [height, width]);

  const { nodes, links } = React.useMemo(() => {
    const layout = d3_sankey
      .sankey<Node, Link>()
      .nodeId((x: typeof data.nodes[number]) => x.id)
      .nodeWidth(client.screenSDown ? 4 : 16)
      .nodePadding(NODE_PADDING)
      .size([dimensions.innerWidth, dimensions.innerHeight]);

    const dataWithLayout = layout(data);
    justifyVerticalPositionsMut(dataWithLayout.nodes);

    return dataWithLayout;
  }, [client.screenSDown, data, dimensions.innerWidth, dimensions.innerHeight]);

  return (
    <SankeyRenderer
      nodes={nodes}
      links={links}
      dimensions={dimensions}
      width={width}
      height={height}
      nodeGroups={nodeGroups}
      colorPalettes={colorPalettes}
    />
  );
}

export function SankeyAuto(props: Omit<SankeyProps, "width">) {
  const [ref, contentRect] = useContentRect();
  return (
    <div ref={ref} style={{ width: "100%" }}>
      {contentRect.width > 0 && <Sankey {...props} width={contentRect.width} />}
    </div>
  );
}

interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
interface SankeyRendererProps {
  nodes: d3_sankey.SankeyNode<Node, Link>[];
  links: d3_sankey.SankeyLink<Node, Link>[];
  width: number;
  height: number;
  dimensions: {
    margin: Margin;
    outerHeight: number;
    innerHeight: number;
    outerWidth: number;
    innerWidth: number;
  };
  colorPalettes: Array<ColorPalette>;
  nodeGroups: Array<any>;
}

const SankeyRenderer_ = React.forwardRef<SVGRectElement, SankeyRendererProps>(
  (
    { nodes, links, dimensions, width, height, nodeGroups, colorPalettes },
    ref
  ) => {
    const i18n = useI18n();
    const { client } = useTheme();
    const { margin, outerHeight, innerHeight, outerWidth, innerWidth } =
      dimensions;

    const [selectedState, setSelected] = React.useState<Node>();
    const voronoi = useSankeyVoronoi({ width, height, nodes });

    const handleMouseOverVoronoiCell = (
      ev: React.MouseEvent<SVGPathElement> | React.TouchEvent<SVGPathElement>
    ) => {
      const node = ev.currentTarget;
      if (!node.dataset.cellindex) {
        return;
      }
      const nodeI = Math.floor(Number(node.dataset.cellindex) / 4);
      if (nodeI !== undefined) {
        setSelected(nodes[nodeI]);
      }
    };
    const handleTouchMoveVoronoiCell = handleMouseOverVoronoiCell;
    const handleMouseLeaveVoronoiCell = useCallback(
      () => setSelected(undefined),
      [setSelected]
    );
    const handleTouchEndVoronoiCell = handleMouseLeaveVoronoiCell;

    const selected = option.fromNullable(selectedState);

    const linkIdToNodes = React.useMemo(() => {
      const res: Record<string, Set<string>> = {};
      for (const link of links) {
        res[link.linkId] = res[link.linkId] || new Set();
        res[link.linkId].add(link.source.id);
        res[link.linkId].add(link.target.id);
      }
      return res;
    }, [links]);

    const isLinkHighlighted = useCallback(
      (link) => {
        const nodeId = option.toUndefined(selected);
        if (!nodeId) {
          return false;
        }
        return linkIdToNodes[link.linkId].has(nodeId.id);
      },
      [selected, linkIdToNodes]
    );

    return (
      <div>
        <svg height={outerHeight} width={outerWidth}>
          <g
            key={"inner"}
            transform={`translate(${margin.left}, ${margin.top})`}
          >
            {nodes.map((node, i) => {
              const textAnchorLeft = (node.x0 || 0) < innerWidth / 3;
              const width = (node.x1 || 0) - (node.x0 || 0);
              const height = (node.y1 || 0) - (node.y0 || 0);
              const textPadding = client.screenSDown ? 2 : 8;
              const isHighlighted = pipe(
                selected,
                O.exists((x) => x.id === node.id)
              );

              const nodeGroup = nodeGroups.find((g) => g.depth === node.depth);
              const palette =
                colorPalettes.find((p) => p.id === nodeGroup?.category)
                  ?.palette || colorPalettes[0].palette;

              return (
                <React.Fragment key={i}>
                  <rect
                    x={node.x0}
                    y={node.y0}
                    width={width}
                    height={height}
                    fill={isHighlighted ? palette[6] : palette[4]}
                  />
                  <text
                    css={css`
                      ${M.fontChartLabel};
                    `}
                    x={textAnchorLeft ? node.x1 : node.x0}
                    y={(node.y0 || 0) + height / 2}
                    textAnchor={textAnchorLeft ? "start" : "end"}
                    dominantBaseline={"middle"}
                    fill={isHighlighted ? M.blackText : M.lightText}
                  >
                    <tspan
                      x={textAnchorLeft ? node.x1 : node.x0}
                      dx={
                        textAnchorLeft
                          ? `${textPadding}px`
                          : `-${textPadding}px`
                      }
                      dy={"-0.4em"}
                    >
                      {node.id}
                    </tspan>
                    {node.value != null && (
                      <tspan
                        x={textAnchorLeft ? node.x1 : node.x0}
                        dx={
                          textAnchorLeft
                            ? `${textPadding}px`
                            : `-${textPadding}px`
                        }
                        dy={"1.2em"}
                        fill={
                          isHighlighted ? M.blackText : M.grayscalePalette[5]
                        }
                      >
                        {formatCurrency(i18n, node.value)}
                      </tspan>
                    )}
                  </text>
                </React.Fragment>
              );
            })}
            {links.map((link: $FixMe, i) => {
              const isHighlighted = isLinkHighlighted(link);
              return (
                <path
                  key={i}
                  d={mkLinkPath(link) as string}
                  fill={"transparent"}
                  stroke={
                    isHighlighted
                      ? colorPalettes[colorPalettes.length - 1].palette[7]
                      : "#000"
                  }
                  strokeWidth={link.width}
                  opacity={isHighlighted ? 0.25 : 0.05}
                />
              );
            })}
            {nodeGroups.map((nodeGroup) => {
              return (
                <g key={nodeGroup.nodes}>
                  <text
                    css={columnTitleS}
                    x={(innerWidth / (nodeGroups.length - 1)) * nodeGroup.depth}
                    textAnchor={
                      nodeGroup.depth === 0
                        ? "start"
                        : nodeGroup.depth === nodeGroups.length - 1
                        ? "end"
                        : "middle"
                    }
                    y={0}
                    dy={"-1em"}
                  >
                    {nodeGroup.label}
                  </text>
                </g>
              );
            })}
            <rect
              ref={ref}
              x={0}
              y={0}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
            />
            <VoronoiInteractionLayer
              voronoi={voronoi}
              onMouseOverCell={handleMouseOverVoronoiCell}
              onTouchMoveCell={handleTouchMoveVoronoiCell}
              onTouchEndCell={handleTouchEndVoronoiCell}
              onMouseLeaveCell={handleMouseLeaveVoronoiCell}
            />
          </g>
        </svg>
        <ColorLegend
          maxWidth={innerWidth}
          inline
          values={colorPalettes.map((p) => ({
            color: p.palette[4],
            label: p.label,
          }))}
        />
      </div>
    );
  }
);
const SankeyRenderer = React.memo(SankeyRenderer_);

// -----------------------------------------------------------------------------

const mkLinkPath = d3_shape
  .linkHorizontal<$FixMe, $FixMe>()
  .source((d) => [d.source.x1, d.y0])
  .target((d) => [d.target.x0, d.y1]);

/**
 * This function **mutates** an array of nodes to adjust the y-positions of both
 * nodes and links. The goal is to justify all nodes vertically so that the
 * first node in a stack is at yMin and the last one is at yMax.
 *
 * This is needed because there are different numbers of items in each stack,
 * which means that, with a constant padding applies, the stacks will not be
 * the same height when they should.
 */
function justifyVerticalPositionsMut(nodes: Array<$FixMe>): void {
  const stacks = pipe(
    nodes,
    An.groupBy((x: $FixMe) => x.depth)
  );

  const maxStackItems = pipe(
    stacks,
    R.reduce(0, (max, xs) => (xs.length > max ? xs.length : max))
  );

  pipe(
    stacks,
    R.map((xs: Array<$FixMe>) => {
      if (xs.length < maxStackItems) {
        const padding = ((maxStackItems - 1) * NODE_PADDING) / (xs.length - 1);
        let y0 = 0;
        xs.forEach((node) => {
          const height = node.y1 - node.y0;
          node.y0 = y0;
          node.y1 = y0 + height;

          let y0link = y0;
          let y1link = y0;
          for (const link of node.sourceLinks) {
            link.y0 = y0link + link.width / 2;
            y0link += link.width;
          }
          for (const link of node.targetLinks) {
            link.y1 = y1link + link.width / 2;
            y1link += link.width;
          }

          y0 = node.y1 + padding;
        });
      }
    })
  );
}
