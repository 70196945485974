
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Will micro-credentials challenge traditional higher education degrees?";
export const lead = "Despite being part of the ‘three Rs’ of basic skills taught in schools, writing – unlike reading and mathematics – is rarely included in standardized learning assessments.";
export const layout = "focus";
export const article = "access";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Will micro-credentials challenge traditional higher education degrees?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=255" mdxType="FocusDetails">
      <h1 {...{
        "id": "will-micro-credentials-challenge-traditional-higher-education-degrees"
      }}>{`Will micro-credentials challenge traditional higher education degrees?`}</h1>
      <p>{`Opportunities for skills development, including sophisticated skills for highly paid technical positions, are increasingly sought outside of traditional higher education. Multiyear degrees are less attractive to those who lack time, money or the inclination. Mutually reinforcing trends in education and in employment, such as fully online instruction, open-access learning materials and skills-based hiring, have coalesced around the concept of micro-credentials. Micro-credentials are the ‘digital certification of assessed knowledge, skills and competencies which is additional, alternate or complementary to or a component of formal qualifications’ (Oliver, 2019). Micro-credentials can be issued by various providers who may or may not be registered as tertiary education institutions in a given country or may not even be in the same country as the student. They are not taken into account in international tertiary education statistics. `}</p>
      <p>{`Micro-credentials are only ‘micro’ relative to traditional degrees and not necessarily as short as the name may suggest. A 2018 review of 450 micro-credentials offered by some of the major online providers found the average time to complete a course was 3 to 12 months, although some courses took over 50 months to complete. Minimum completion time ranged from 1 to 15 months. While some courses only require a few hours per week and can easily be completed alongside other commitments, others call for 10, 20 or even up to 40 hours per week, essentially the equivalent of a part-time or even full-time job. Costs similarly varied by orders of magnitude, from almost or actually free to thousands of US dollars.`}</p>
      <FocusImage size="auto" src={"22-microcredentials.png"} alt="Micro-credentials offerings vary tremendously in duration and cost" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=257" mdxType="FocusImage" />
      <p>{`Micro-credentials allow specific skills to be acquired on demand at any time and in any place. This meets the needs of learners for flexibility and personalization of learning opportunities that, ideally, can add up to a higher-value qualification over time (Resei et al., 2019). Students can learn vertically (i.e. a sequence of increasingly advanced courses that build on each other), horizontally (e.g. combinations that broaden information and communication technology `}{`[ICT]`}{` professionals’ skills in a set of different programming tools) or in other domains (e.g. engineers or other professionals acquiring project management skills) (Cedefop, 2023). For instance, while there is significant demand for job-specific science, technology, engineering and mathematics (STEM) skills in non-STEM occupations (Grinis, 2019), these skills often do not require a full STEM degree and could be more efficiently acquired by teaching them to those who already possess the required non-STEM skills for the job.`}</p>
      <p>{`The relatively low cost and short course duration lower entry barriers. Flexibility of timing and sequencing is also an advantage: it is possible to leave arbitrarily long gaps between individual credentials, unlike traditional degrees, where interruptions are typically subject to strict rules. In the best case, micro-credentials support self-regulated learning, whereby learners are active agents who set their own goals and monitor and regulate their progress. Working towards micro-credentials can remind them of their progress to date and what gaps remain (Gish-Lieberman et al., 2021). New technological skills can be incorporated and certified long before they are included in traditional degree curricula. Micro-credentials therefore potentially serve not only credentialing but also motivational and pedagogical functions (Richard et al., 2020).`}</p>
      <p>{`But critics say that micro-credentials may be harmful or go against the very idea of university education. Some are concerned by the fragmentation of knowledge (Chakroun and Keevy, 2018). Short courses leading to a narrow prespecified skill reduces the scope of curriculum by packaging knowledge in small pieces (Cliff et al., 2022). Further, micro-credentials may be only serving professionals to receive visible certification for skills they already have rather than to actually aid in their acquisition (Kässi and Lehdonvirta, 2018).`}</p>
      <p>{`The benefits of micro-credentials as part of the overall tertiary education system are yet to be proven outside of specific niches (Oliver, 2021). Large gaps remain in academic research; most publications on the topic are white papers and reports (Selvaratnam and Sankey, 2021). While there was tremendous growth in enrolment in online massive open online courses in 2020, many of which led to micro-credentials, their sustainability remains unclear (Cowie and Sakui, 2022).`}</p>
      <p>{`Greater confidence and trust in micro-credentials are undermined by the lack of common definitions, standards and regulations. University and industry stakeholders consider this the biggest barrier to the greater adoption of micro-credentials (HolonIQ, 2021). Part of the problem is that employers can struggle to assess the credibility of credentials due to a lack of structured information. The non-profit Credential Engine identified 1,076,000 unique credentials in the United States alone, of which there were some 430,000 digital badges (Credential Engine, 2022).`}</p>
      <p>{`This situation is beginning to change, with major governance stakeholders adopting explicit frameworks for the integration of micro-credentials into national and international education, qualifications and training frameworks. Australia has already incorporated micro-credentials into its national qualifications framework, paving the way for their official recognition (Pollard and Vincent, 2022). Following a consultation process, the Malaysian Qualifications Authority in 2020 released a good practice guide for micro-credential providers. Adherence to its quality, design and delivery principles is meant to result in accredited and portable courses that align with other qualifications in the national framework (Brown et al., 2021; Cowie and Sakui, 2022).`}</p>
      <p>{`Some researchers have argued that micro-credentials can promote equity. They encourage access and participation because they have low stakes. This makes the risk of non-completion less daunting, perhaps especially for those who feel out of place in a traditional university environment. A recent summary of policy advice on flexible learning pathways lists micro-credentials as an opportunity to overcome persistent inequality in adult education (Hijden and Martin, 2023). The European Consortium of Innovative Universities expects micro-credentials to make education more accessible (ECIU, 2020). In 2022, the Council of the European Union adopted a Recommendation, which identifies micro-credentials as a means to cater to the needs of learners from disadvantaged groups (Council of the European Union, 2022). In the United States, micro-credentials can signal extracurricular achievements that are prized in college admissions that disadvantaged youth are less likely to demonstrate (Gutierrez and Martin, 2021).`}</p>
      <p>{`However, a recent systematic literature review showed that, whether from the perspective of learners, higher education institutions or governments, the majority of studies are sceptical about the ability of micro-credentials to make such a contribution (Varadarajan et al., 2023). The promise of micro-credentials contributing to education equity is also undermined by their concentration in STEM disciplines. As socially disadvantaged groups are heavily underrepresented in these subjects, they are less likely to benefit from such micro-credentials. In practice, micro-credentials tend to serve as a convenient opportunity for lifelong learning for those already active in these fields, who are disproportionately already privileged, rather than as entry ramps for those who are not.`}</p>
      <p>{`It has also been shown that even for those who obtain micro-credentials, the potential benefits may be outweighed by lower returns. Micro-credentials have not yet conferred the same prestige as traditional degrees. They are also not generally rewarded in the labour market in the same way. Employers see micro-credentials as complementary to, rather than substitutes for, formal degrees (Kato et al., 2020). However, while degree requirements have measurably declined in job advertisements for middle- and high-skill positions, this trend has not been clearly replaced by a demand for micro-credentials. Moreover, many technology companies continue requiring degrees despite their public pronouncements of moving to skills-based hiring (Fuller et al., 2022).`}</p>
      <p>{`Ironically, one bottleneck to the greater recognition of micro-credentials is that most digital recruitment and human resource management technology does not accept and process non-degree credentials (Gallagher et al., 2023). There is a risk, therefore, that even if micro-credentials were to be taken up by disadvantaged groups, they would not be recognized, reinforcing educational stratification. In the worst case, those unprepared to navigate the maze of offered credentials may end up with an incoherent, fragmented education based on micro-credentials, which are poorly recognized by employers and where it is uncertain what credit value they receive for formal education (Kift, 2021). `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-access"
      }}>{`Focuses published in Access`}</h4>
      <hr></hr>
      <FocusThemeList theme="access" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;