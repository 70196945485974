
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Early entry is more common than believed";
export const lead = "Target 4.2 aims to ensure that all children enjoy age-appropriate education opportunities before primary school, but assessment is hampered by the significant number who attend primary school early.";
export const layout = "focus";
export const article = "access";
export const year = 2020;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Early entry is more common than believed" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=253" mdxType="FocusDetails">
      <h1 {...{
        "id": "early-entry-is-more-common-than-believed"
      }}>{`Early entry is more common than believed`}</h1>
      <p>{`Target 4.2 aims to ensure that all children enjoy age-appropriate education opportunities before primary school, but assessment is hampered by the significant number who attend primary school early. Across 10 sub-Saharan African countries with age 7 entry, a significant number of 5-year-olds attend primary school. `}</p>
      <FocusImage size="medium" src={"10-Early_entry_1.png"} alt="Many children attend primary school early in sub Saharan Africa" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=253" mdxType="FocusImage" />
      <p>{`Early school entry can be harmful. In Viet Nam, disadvantaged girls who start school early are more likely to experience teenage marriage and/or childbirth (Nguyen and Lewis, 2019). Under-age children are as likely to underperform as those over age, if not more so (Dyer et al., 2019). As with the problem of over-age enrolment, the standard of surveying attendance from age 5 may truncate the actual age distribution of enrolment. Surveys without this limitation show that primary school attendance may begin as much as three years early (Barakat and Bengtsson, 2017). `}</p>
      <p>{`Childcare needs are one explanation for premature primary school entry or presence at school of 5-, 4- and 3-year-olds in some low- and middle-income settings. Older schoolgoing siblings may mind them out of necessity while parents work. Household survey data are inconclusive: Across 17 recent MICS, the probability of children one year younger than the primary school entry age attending primary rather than pre-primary education is higher in some countries for children with a sibling in primary school, but lower in others. Selection effects operate in two directions. Children with no primary school-age siblings are more likely to come from smaller and likely wealthier families and to be the first born. Children with siblings in the relevant age range who are out of school are more likely to live in disadvantaged areas with no pre-primary provision.`}</p>
      <p>{`Early school entry statistics reveal little about the transition between levels. As a matter of policy, whether primary school-age children without pre-primary education should attend preschool first is open to question. `}</p>
      <p>{`Children one year younger than the primary school entry age count towards global indicator 4.2.2, regardless of whether they attend pre-primary or primary education. The indicator does not distinguish between children who attend both in sequence at the expected ages and those who skip pre-primary and enter primary education a year early. Conversely, children of primary school entry age who are in pre-primary education count as being in school for the purpose of calculating out-of-school rates. The out-of-school rate does not distinguish between those who enter primary school on time and those who postpone pre-primary and primary attendance by a year. `}</p>
      <p>{`Some surveys, including the MICS, collect attendance information in the current and previous school year. In 9 of 17 countries with MICS results since 2015, more children one year younger than the primary school entry age are in primary school than preschool. In Senegal, 64% of that cohort are in organized education, but only 19% of children start primary school on time after having attended preschool. Corresponding estimates in Mali are 90% and 5%. `}</p>
      <p>{`To illustrate, early and late primary school entry coexist in Nigeria. Basically all those attending pre-primary education enter primary school, although not necessarily immediately: Two-thirds at primary school entry age remain in pre-primary education. A majority of primary school-aged children without pre-primary education enter primary school. Thus, although a large majority attend organized learning one year before primary school entry age, a small minority represent the ideal of on-time primary school entry with pre-primary education. `}</p>
      <FocusImage size="medium" src={"10-Early_entry_2.png"} alt="In Nigeria, 12% of children start school on time with pre-primary education" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=254" mdxType="FocusImage" />
      <p>{`One approach to improving the situation of primary school-aged children who have not previously attended pre-primary education is accelerated school readiness: Ethiopia’s programme offers 150 hours over the summer months prior to entry (UNICEF, 2019). `}</p>
      <p>{`System-wide recommendations depend on analysis of differences in average outcomes between the two pathways. This requires information on learning outcomes at a higher grade, age upon entering primary school, and pre-primary education attendance. Case-by-case considerations determine which pathway is more appropriate. Some systems are moving away from age-based entry and progression in early grades towards pooled reception grades, which offer flexibility in entrance age and time at a stage before progressing. The approach is practiced in some German states and was recently proposed in Canada’s New Brunswick province (Sweet, 2019).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-access"
      }}>{`Focuses published in Access`}</h4>
      <hr></hr>
      <FocusThemeList theme="access" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=505"} mdxType="ReferenceButton">
  2020 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;