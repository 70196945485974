import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID_EDU_SSA",
  title: /*i18n*/ {
    id: "fig.finance.aid.AID_EDU_SSA.title",
    defaults: ``,
  },
  caption: /*i18n*/ {
    id: "fig.finance.aid.AID_EDU_SSA.caption",
    defaults: ``,
  },
  source: /*i18n*/ {
    id: "fig.finance.aid.AID_EDU_SSA.source",
    defaults: ``,
  },
};
