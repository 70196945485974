import { FontProps } from "@valde/typography";
import { mq } from "../responsive/breakpoints";
import { mkFontStack, notoBold, notoRegular } from "./font-faces";

const mkFont = (fontProps: FontProps, cssProperties?: $FixMe) => {
  const style: $FixMe & { fontProps: FontProps } = mq({
    fontFamily: mkFontStack(fontProps.fontFace),
    ...fontProps.fontFace.cssProperties,
    ...fontProps.cssProperties,
    ...cssProperties,
  }) as $Unexpressable;

  // This is for Catalog.
  style.fontProps = fontProps;

  return style;
};

/**
 *  [xs, s, m, l]
 */

export const fontTera = mkFont(
  {
    name: "Tera",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "88px",
      lineHeight: "88px",
    },
  },
  {
    fontSize: ["48px", "8.4vw", "9vw", "88px"],
    lineHeight: ["0.9", "0.9", "1", "1"],
  }
);

export const fontGiga = mkFont(
  {
    name: "Giga",
    fontFace: notoBold,
    cssProperties: {
      fontSize: "72px",
      lineHeight: "72px",
    },
  },
  {
    fontSize: ["58px", "58px", "72px", "72px"],
    lineHeight: ["58px", "58px", "72px", "72px"],
  }
);

export const fontTitle = mkFont(
  {
    name: "Title",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "48px",
      lineHeight: "56px",
    },
  },
  {
    fontSize: ["32px", "32px", "48px", "48px"],
    lineHeight: ["48px", "48px", "56px", "56px"],
  }
);

export const fontHeading1 = mkFont(
  {
    name: "Heading 1",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  {
    fontSize: ["24px", "24px", "32px", "32px"],
    lineHeight: ["32px", "32px", "40px", "40px"],
  }
);

export const fontHeading1Bold = mkFont(
  {
    name: "Heading 1",
    fontFace: notoBold,
    cssProperties: {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  {
    fontSize: ["24px", "24px", "32px", "32px"],
    lineHeight: ["32px", "32px", "40px", "40px"],
  }
);

export const fontHeading2 = mkFont(
  {
    name: "Heading 2",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  {
    fontSize: ["18px", "18px", "24px", "24px"],
    lineHeight: ["24px", "24px", "32px", "32px"],
  }
);

export const fontHeading2Bold = mkFont(
  {
    name: "Heading 2",
    fontFace: notoBold,
    cssProperties: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  {
    fontSize: ["18px", "18px", "24px", "24px"],
    lineHeight: ["24px", "24px", "32px", "32px"],
  }
);

export const fontHeading3 = mkFont(
  {
    name: "Heading 3",
    fontFace: notoBold,
    cssProperties: {
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  {
    fontSize: ["16px", "16px", "18px", "18px"],
    lineHeight: ["22px", "22px", "24px", "24px"],
  }
);

export const fontHeading4 = mkFont(
  {
    name: "Heading 4",
    fontFace: notoBold,
    cssProperties: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  {
    fontSize: ["14px", "14px", "16px", "16px"],
    lineHeight: ["22px", "22px", "24px", "24px"],
  }
);

export const fontLead1 = mkFont(
  {
    name: "Lead",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  {
    fontSize: ["16px", "16px", "18px", "18px"],
    lineHeight: ["25px", "25px", "28px", "28px"],
  }
);

export const fontLead2 = mkFont(
  {
    name: "Lead",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "16px",
      lineHeight: "28px",
    },
  },
  {
    fontSize: ["14px", "14px", "16px", "16px"],
    lineHeight: ["25px", "25px", "28px", "28px"],
  }
);

export const fontBody1 = mkFont(
  {
    name: "Body 1",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "24px",
      lineHeight: "36px",
    },
  },
  {
    fontSize: ["18px", "18px", "24px", "24px"],
    lineHeight: ["28px", "28px", "36px", "36px"],
  }
);

export const fontBody2 = mkFont(
  {
    name: "Body 2",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  {
    fontSize: ["14px", "14px", "16px", "16px"],
    lineHeight: ["22px", "22px", "24px", "24px"],
  }
);

export const fontBody2Alt = mkFont(
  {
    name: "Body 2",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  {
    fontSize: ["14px", "14px", "14px", "16px"],
    lineHeight: ["22px", "22px", "22px", "24px"],
  }
);

export const fontBody2Hero = mkFont(
  {
    name: "Body 2",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "16px",
      lineHeight: "28px",
    },
  },
  {
    fontSize: ["14px", "14px", "16px", "16px"],
    lineHeight: ["24px", "24px", "28px", "28px"],
  }
);

export const fontTable = mkFont(
  {
    name: "Table",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  {
    fontSize: ["12px", "12px", "14px", "14px"],
    lineHeight: ["16px", "16px", "20px", "20px"],
  }
);

export const fontButton = mkFont({
  name: "Table",
  fontFace: notoRegular,
  cssProperties: {
    fontSize: "14px",
    lineHeight: "20px",
  },
});

export const fontMeta = mkFont(
  {
    name: "Meta",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  {
    fontSize: ["10px ", "10px ", "12px ", "12px "],
    lineHeight: ["14px", "14px", "16px", "16px"],
  }
);

export const fontFooter = mkFont(
  {
    name: "Footer",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "1rem",
      lineHeight: "16px",
    },
  },
  {
    fontSize: ["1rem"],
    lineHeight: ["1rem"],
  }
);

export const fontAxis = mkFont(
  {
    name: "Axis",
    fontFace: notoRegular,
    cssProperties: {
      fontSize: "10px",
      lineHeight: "14px",
    },
  },
  {
    fontSize: ["10px ", "10px ", "10px ", "10px "],
    lineHeight: ["14px", "14px", "14px", "14px"],
  }
);

// Aliased typefaces for charts

export const fontAxisLabel = fontAxis;
export const fontChartLabel = fontMeta;
export const fontChartGroupTitle = fontTable;
export const fontInterpretation = mkFont({
  name: "Interpretation",
  fontFace: notoRegular,
  cssProperties: {
    fontSize: "14px",
    lineHeight: "20px",
  },
});
