import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import React from "react";
import { mkStylesWithProps, useFocusVisible } from "../../hooks";
import * as M from "../../materials";
import { Grid, layoutAreas, spacing } from "../../materials";
import { PageLinkWithAnchor } from "../links";

const useTagStyles = mkStylesWithProps(({ palette, zIndex }) => {
  return {
    aside: css`
      grid-column: ${layoutAreas.side};
      grid-row: -1;
      position: sticky;
      top: 1.5rem;
      margin-top: 1.5rem;
      z-index: ${zIndex.aside};
      background-color: white;
      min-height: 1em; /* To counteract absolutely positioned children */

      @media ${M.bpDown("m")} {
        position: static;
      }
    `,
    asideFallback: M.onlyIE(css`
      position: static;
      float: right;
      width: ${M.spacing.base8(26)};
      margin: 0 0 1.5rem 1.5rem;
    `),
    anchor: css`
      ${M.fontBody2Alt};
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      border-radius: ${spacing.base1(2)};
      box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.18);
      padding: 0.8em;
      color: ${palette.brand.teal.main};

      background-color: white;
      width: 100%;

      outline: none;

      &:hover {
        color: ${palette.brand.teal.dark};
        cursor: pointer;
      }

      @media ${M.bpDown("m")} {
        position: static;
        padding: 0.5em;
        box-shadow: none;
        border: 1px solid ${M.divider};
      }
    `,
    anchorFocus: css`
      outline: 2px solid ${palette.brand.teal.main};
      outline-offset: 2px;
    `,
    anchorFallback: M.onlyIE(css`
      position: static;
    `),
    label: css`
      ${M.fontBody2Alt};
      color: currentColor;
      display: inline-block;
      padding: 0.3em 0.3em 0.3em 0;
      white-space: nowrap;
    `,
    mark: css`
      ${M.fontBody2Alt};
      white-space: nowrap;
      text-align: center;
      display: inline-block;
      background-color: currentColor;
      border-radius: ${spacing.base8(2)};
      padding: 0.2em 0;
      min-width: ${spacing.base8(7)};
      font-weight: bold; /* FIXME: we should define a separate font style for this */
      & > span {
        color: ${palette.text.white};
      }
    `,
  };
});

export const IndicatorReference = (
  props: $PropsWithChildren<{ id: string; label?: string }>
) => {
  const styles = useTagStyles(props);
  const { focusVisible, ...handleFocus } = useFocusVisible();

  return (
    <Grid layout={"article"}>
      <aside
        id={props.label || props.id}
        css={[styles.aside, styles.asideFallback]}
      >
        <PageLinkWithAnchor name="indicators" query={{}} anchor={props.id}>
          <a
            css={[
              styles.anchor,
              styles.anchorFallback,
              focusVisible && styles.anchorFocus,
            ]}
            {...handleFocus}
          >
            <span css={styles.label}>
              <Trans>SDG indicator</Trans>
            </span>
            <span css={styles.mark}>
              <span>{props.label || props.id}</span>
            </span>
          </a>
        </PageLinkWithAnchor>
      </aside>
      {props.children}
    </Grid>
  );
};
