import { MDXProvider } from "@mdx-js/react";
import React from "react";
import { applyDecoder, DecoderError } from ".";
import { useTheme } from "../hooks";
import { baseElements } from "../materials";
import { E, io, pipe } from "../prelude";

type BaseProps<IO> = {
  decoder: io.Decoder<IO, IO>;
  handleError: (e: DecoderError) => React.ReactElement;
  wrap: (children: $Children) => (fm: IO) => React.ReactElement;
  components: Record<string, React.ComponentType<$IntentionalAny>>;
};

/**
 * Create an MDX Provider to wrap the application. This accomplishes two things:
 *   1. Components are made available as globals to MDX documents
 *   2. Layout can be applied to the document (wrapper)
 *
 * @param decoder The decoder to use for parsing the frontmatter
 * @param wrap A function to build a component that wraps around the MDX elements
 * @param components The components that will be available (without import) in all MDX pages
 */
export function mkMDXProvider<IO>(
  baseProps:
    | (BaseProps<IO> & { renderChildrenAsMDX: true })
    | (BaseProps<IO> & { renderChildrenAsMDX: false; customWrapProps: IO })
) {
  const { components, decoder, handleError, wrap, renderChildrenAsMDX } =
    baseProps;
  return (props: $PropsWithChildren) => {
    const theme = useTheme();
    const defaultMDXComponents = {
      ...baseElements(theme),
      ...components,
    };

    if (renderChildrenAsMDX) {
      return (
        <MDXProvider
          components={{
            ...defaultMDXComponents,
            wrapper: ({ children, ...props }: $PropsWithChildren<IO>) =>
              pipe(
                applyDecoder(decoder, props as $Unexpressable),
                E.fold(handleError, wrap(children))
              ),
          }}
          {...props}
        />
      );
    } else {
      const { customWrapProps } = baseProps;
      const children = pipe(
        applyDecoder(decoder, customWrapProps),
        E.fold(handleError, wrap(props.children))
      );
      return (
        <MDXProvider components={defaultMDXComponents} {...props}>
          {children}
        </MDXProvider>
      );
    }
  };
}
