import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "HERO_FINANCE",
  title: /*i18n*/ { id: "fig.finance.HERO.title", defaults: `Finance` },
  caption: /*i18n*/ {
    id: "fig.finance.HERO.caption",
    defaults: `Globally, more than one in four countries spend less than 4% of GDP and less than 15% of total government expenditure on education, the two minimum financing benchmarks.`,
  },
  source: /*i18n*/ { id: "fig.finance.HERO.source", defaults: `UIS database` },
};
