
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Zugang";
export const lead = "Weltweit schließen 87% der Kinder die Grundschule, 77% der Heranwachsenden die untere Sekundarstufe und 58% der Jugendlichen die obere Sekundarstufe ab";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const PageLink = makeShortcode("PageLink");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Ihr Ziel, dass bis 2015 alle Kinder die Grundschule besuchen, hat die Welt nicht erreicht. Das neue Bildungsziel SDG 4 ist noch ehrgeiziger: Bis 2030 sollen alle jungen Menschen die Sekundarschule abschließen. Um dies zu erreichen, hätten 2018 alle Kinder im Schuleintrittsalter eingeschult werden müssen, allerdings war dies beispielsweise in Ländern mit niedrigem Einkommen nur bei 70% der Fall.`}</p>
    </blockquote>
    <p>{`Etwa jedes sechzigste Kind wird `}<strong parentName="p">{`nie eine Schule besuchen`}</strong>{`, die meisten davon in Ländern mit niedrigem Einkommen. Dies trifft nach wie vor auf Mädchen häufiger zu als auf Jungen, da sie insofern diskriminiert werden, dass von ihnen erwartet wird, sich um Geschwister und andere Aufgaben im Haushalt zu kümmern.`}</p>
    <h2 {...{
      "id": "teilnahme-an-frühkindlicher-bildung"
    }}>{`Teilnahme an frühkindlicher Bildung`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Der Besuch einer Vorschule`}</strong>{` ist ein entscheidender Anfangspunkt für den Bildungsweg von Kindern. In Ländern mit niedrigem Einkommen besuchen jedoch nur 43% der Kinder in dem Jahr vor Schulbeginn eine vorschulische Bildungseinrichtung.`}</p>
    </IndicatorReference>
    <p>{`Seit 2000 nimmt die Teilnahme an frühkindlicher Bildung in den meisten Ländern, auch in vielen der ärmsten, langsam aber stetig zu, dennoch bleiben viele zurück. Weltweit nehmen heute 75% der Kinder an frühkindlicher Bildung teil, 2002 lag ihr Anteil noch bei 65%.`}</p>
    <p>{`Einige Länder verzeichneten enorme Zuwächse. In Kirgisistan zum Beispiel stieg die Beteiligungsrate von 42% im Jahr 2000 bis 2021 auf 84%. In der Demokratischen Volksrepublik Laos erhöhte sie sich im gleichen Zeitraum von 10% auf 71%.`}</p>
    <p>{`Sowohl `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Guatemala`}</HighlightByIndex>{` als auch `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Niger`}</HighlightByIndex>{` – beide unten hervorgehoben – sind Beispiele für Länder, die einen ständigen Anstieg der Teilnahme an frühkindlicher Bildung verzeichnen, obwohl die Unterschiede zwischen ihnen heute enorm sind. Wählen Sie Ihr Land und sehen Sie, welche Fortschritte erzielt wurden.`}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "schülerinnen-und-schüler-über-dem-altersdurchschnitt"
    }}>{`Schülerinnen und Schüler über dem Altersdurchschnitt`}</h2>
    <IndicatorReference id="4.1.5" mdxType="IndicatorReference">
      <p>{`Über die Tatsache, dass in einigen Ländern über ein Viertel der Grundschülerinnen und -schüler bei ihrer Einschulung `}<strong parentName="p">{`über dem Altersdurchschnitt`}</strong>{` liegen, also bedeutend älter als ihre Mitschülerinnen und -schüler sind, wird nicht ausreichend gesprochen. Dies hat beträchtliche Auswirkungen auf ihre Zukunft: Die Wahrscheinlichkeit, dass sie eine Klassenstufe wiederholen, Prüfungen nicht bestehen und letztendlich die Schule abbrechen, ist dadurch höher.`}</p>
    </IndicatorReference>
    <p>{`Diese Abbildung untersucht die `}<strong parentName="p">{`Bildungsbeteiligung`}</strong>{` und zeigt, wie alt Kinder, Heranwachsende und Jugendliche sind, wenn sie an vorschulischer, Grundschul-, unterer und oberer Sekundarschul- sowie postsekundärer Bildung teilnehmen. Der farbig hervorgehobene Teil zeigt an, wie viele von ihnen über dem Altersdurchschnitt für ihre Bildungsstufe liegen. Der grau markierte Bereich weist den Anteil an Kindern und Jugendlichen nach Alter aus, die keine Schule besuchen.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OVERAGE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="PAK" mdxType="ScrollySection">
    In <Highlight mdxType="Highlight">Pakistan</Highlight>sollten die meisten 10-jährigen Kinder zur
    Schule gehen, dennoch besuchen 21% in diesem Alter noch keine Schule. Viele
    sind zu alt für ihre Klassenstufe. Beispielweise besuchen 14-Jährige noch
    die Grundschule.
  </ScrollySection>
  <ScrollySection id="LBR" mdxType="ScrollySection">
    In Ländern wie <Highlight mdxType="Highlight">Liberia</Highlight> ist der Anteil der älteren
    Schülerinnen und Schüler noch höher: Hier besuchen 28% der 10-Jährigen noch
    die Vorschule und 20% der 18-Jährigen noch die Grundschule.
  </ScrollySection>
  <ScrollySection id="HTI" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Haiti</Highlight> steht vor derselben Herausforderung,
  </ScrollySection>
  <ScrollySection id="MWI" mdxType="ScrollySection">
    ebenso <Highlight mdxType="Highlight">Malawi</Highlight>.
  </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "kinder-die-keine-schule-besuchen"
    }}>{`Kinder, die keine Schule besuchen`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`Weltweit besuchen 244 Millionen Kinder und Jugendliche `}<strong parentName="p">{`keine Schule`}</strong>{`. Eine neues durch das UNESCO-Institut für Statistik und den Weltbildungsbericht entwickeltes Modell deutet darauf hin, dass die Zahl derer, die keine Grundschule besuchen, schneller sinkt als zunächst angenommen, wenngleich sich die Geschwindigkeit der Abnahme in den letzten Jahren verlangsamt hat. In der unteren Sekundarstufe ist die Zahl der Kinder, die keine Schule besuchen, seit 2014 relativ konstant.`}</p>
      <p>{`Konflikte sind ein wesentlicher Grund für Stagnation. Leider bringen es Konflikte auch mit sich, dass sich die Verfügbarkeit und Qualität von Daten verschlechtert, wenngleich einige Länder nach wie vor Schätzwerte vorlegen. Auch um die Auswirkungen von COVID-19 auf die Schulbeteiligung richtig einschätzen zu können, bedarf es mehr und kontinuierlicher Angaben über einen längeren Zeitraum.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OOSRATE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="NIC, PAN" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Nikaragua</Highlight> und <Highlight mdxType="Highlight">Panama</Highlight> weisen
    sehr unterschiedliche Entwicklungsrichtungen auf. In der Grund- und
    Sekundarstufe ist die Quote derjenigen, die keine Schule besuchen, in
    Nikaragua enorm gesunken, während sie in Panama gestiegen ist.
  </ScrollySection>
  <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
    Auch <Highlight mdxType="Highlight">Eritrea</Highlight> und <Highlight mdxType="Highlight">Äthiopien </Highlight>{" "}
    zeigen sehr unterschiedliche Fortschrittsraten. Die Nachwirkungen des
    Konflikts in Eritrea wirkten sich sichtbar negativ auf den Zugang zu Bildung
    aus, während Äthiopien Bildung in seinem Haushalt Priorität verliehen hat,
    was in kurzer Zeit zu einer schnellen Verminderung der Zahl an Kindern, die
    keine Schule besuchen, geführt hat.
  </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Ein weiterer Grund für mangelnde Fortschritte bei der Senkung der Zahl derjenigen, die keine Schule besuchen, ist das Bevölkerungswachstum.`}</p>
    <p>{`In Subsahara-Afrika hat sich die `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`Bevölkerung im Grundschulalter`}</HighlightByIndex>{` von 2000 bis 2022 beinah verdoppelt. In der Folge hat sich zwar der `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Anteil der Kinder, die keine Schule besuchen`}</HighlightByIndex>{` in diesem Zeitraum fast halbiert, die `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Anzahl der Kinder, die keine Schule besuchen`}</HighlightByIndex>{` jedoch kaum verändert.`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "schulabschluss"
    }}>{`Schulabschluss`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`Das letztendliche Ziel von SDG 4 ist der `}<strong parentName="p">{`Abschluss`}</strong>{` der oberen Sekundarstufe für alle, nicht nur deren Teilnahme daran. Dennoch ist in vielen Ländern Bildung höchstens bis zur unteren Sekundarschule kostenlos und schulpflichtig.`}</p>
      <p>{`Die Welt ist noch nicht auf dem richtigen Weg, um bis zum festgesetzten Termin den universellen Abschluss der Primarstufe zu erreichen, von dem der oberen Sekundarstufe ganz zu schweigen. Nach aktuellen Trends werden 2030 nur sechs von zehn jungen Menschen die Sekundarschule abschließen.`}</p>
      <p>{`Der Indikator bezieht sich auf Kinder, die Bildungsstufen bis zu drei oder fünf Jahre nach dem offiziellen Abschlussalter abschließen können – dies kann als `}<Tooltip title="Rechtzeitiger Abschluss" content="Kinder, die Bildung abschließen, egal zu welchem Zeitpunkt" mdxType="Tooltip">{`rechtzeitiger Abschluss`}</Tooltip>{` definiert werden. Doch viele Kinder schließen sogar noch später ab – was als `}<Tooltip title="Äußerster Abschluss" content="Kinder, die Bildungsstufen bis zu 8 Jahre später als regulär abschließen" mdxType="Tooltip">{`äußerster Abschluss`}</Tooltip>{` bezeichnet werden kann.`}</p>
      <p>{`Die Abbildung unten zeigt zum Beispiel, dass in Kenia die Differenz zwischen `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`rechtzeitigem`}</HighlightByIndex>{` (74%) und `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`äußerstem`}</HighlightByIndex>{` Abschluss (93%) in der Grundstufe 20 Prozentpunkte beträgt. In Mosambik ist der Unterschied ebenso hoch.`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "schlussbemerkung"
    }}>{`Schlussbemerkung`}</h2>
    <p>{`Wann Kinder eingeschult werden, in welchem Alter und ob sie die Schule dann auch abschließen, sind Fragen von entscheidendem politischen Interesse.`}</p>
    <p>{`Die folgenden Themen auf dieser Website gehen den Fragen nach, wie nah wir dem Ziel der Erreichung von `}<PageLink name="equity" mdxType="PageLink">{`Chancengerechtigkeit`}</PageLink>{` in Bildung sind, was Lernende `}<PageLink name="learning" mdxType="PageLink">{`lernen`}</PageLink>{` welche `}<PageLink name="quality" mdxType="PageLink">{`Qualität`}</PageLink>{` ihre Bildung hat und wer sie `}<PageLink name="finance" mdxType="PageLink">{`finanziert`}</PageLink>{`.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
          }}>{`Education above the odds – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unicef.org/reports/a-world-ready-to-learn-2019"
          }}>{`A world ready to learn: Prioritizing quality early childhood education – UNICEF`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining data on out-of-school children, Completion and learning to offer a more comprehensive view on SDG 4`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;