import css from "@emotion/css";
import { max, min } from "d3-array";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { motion } from "framer-motion";
import React, { Fragment } from "react";
import {
  ChartTooltip,
  TooltipGridBodyWithLegend,
} from "../../components/tooltip";
import { useTheme } from "../../hooks";
import * as M from "../../materials";
import ColorLegend from "./ColorLegend";
import {
  baseLineColor,
  calculateAxis,
  getTextColor,
  groupBy,
  isDefined,
  runSort,
  subsup,
  unsafeDatumFn,
} from "./utils";

const COLUMN_PADDING = 20;
const ROW_PADDING = 56;
const COLUMN_TITLE_HEIGHT = 30;
const BAR_LABEL_HEIGHT = 15;
const AXIS_BOTTOM_PADDING = 24;
const X_TICK_TEXT_MARGIN = 0;
const MARGINS = {
  top: 8,
  right: 2,
  bottom: 40,
  left: 1,
};

function useBarStyles(barStyle) {
  const { client } = useTheme();
  return {
    lollipop: {
      highlighted: {
        marginTop: 4,
        height: 6,
        radius: 8,
        marginBottom: 16,
        fontWeight: "bold",
      },
      normal: {
        marginTop: 4,
        height: 3,
        radius: 8,
        marginBottom: 9,
        fontWeight: "normal",
      },
    },
    dotPlot: {
      highlighted: {
        marginTop: 4,
        height: 0,
        radius: 8,
        marginBottom: 16,
        fontWeight: "bold",
      },
      normal: {
        marginTop: 2,
        height: 0,
        radius: 6,
        marginBottom: 16,
        fontWeight: "normal",
      },
    },
    small: {
      highlighted: {
        marginTop: 0,
        height: client.screenSDown ? 24 : 32,
        marginBottom: client.screenSDown ? 16 : 24,
        fontWeight: "bold",
      },
      normal: {
        marginTop: 0,
        height: client.screenSDown ? 8 : 16,
        marginBottom: client.screenSDown ? 16 : 24,
        fontWeight: "normal",
      },
    },
  }[barStyle];
}

const INLINE_BAR_STYLE = {
  highlighted: {
    marginTop: 0,
    height: 50,
    marginBottom: 9,
    fontSize: 16,
    secondaryFontSize: 12,
    inlineTop: 6,
  },
  normal: {
    marginTop: 0,
    height: 20,
    marginBottom: 9,
    fontSize: 12,
    inlineTop: 2,
  },
};

const last = (array, index) => array.length - 1 === index;

const styles = {
  groupTitle: css`
    ${M.fontChartGroupTitle};
    fill: ${M.blackText};
  `,
  barLabel: css`
    ${M.fontChartLabel};
    fill: ${M.lightText};
  `,
  barLabelLink: css`
    text-decoration: underline;
    &:hover {
      fill: ${M.lightText};
    }
  `,
  inlineLabel: css`
    ${M.fontChartLabel};
  `,
  axisLabel: css`
    ${M.fontAxisLabel};
    fill: ${M.lightText};
  `,
  axisXLine: css`
    stroke: ${M.divider};
    stroke-width: 1px;
    shape-rendering: crispEdges;
  `,
  bandLegend: css`
    white-space: nowrap;
  `,
  bandBar: css`
    display: inline-block;
    width: 24px;
    height: 8px;
    background-color: ${M.divider};
    border-radius: 4px;
  `,
};

export const BarChart = ({
  values,
  width,
  numberFormat = "s",
  minInnerWidth = 140,
  columns = 1,
  tLabel,
  mini,
  domain,
  y,
  xTicks,
  barStyle = "small",
  band,
  bandLegend,
  sort,
  column,
  columnSort,
  columnFilter,
  highlight,
  color,
  colorRange,
  colorSort,
  colorLegend,
  colorLegendValues,
  children,
  category,
  filter,
  description,
  showBarValues,
  showBarValuesHighlight,
  inlineValue,
  inlineValueUnit,
  inlineLabel,
  inlineSecondaryLabel,
  link,
  showTooltip,
}) => {
  const innerWidth = width - MARGINS.left - MARGINS.right;
  const possibleColumns = Math.floor(
    innerWidth / (minInnerWidth + COLUMN_PADDING)
  );
  const cols =
    possibleColumns >= columns ? columns : Math.max(possibleColumns, 1);
  const columnWidth =
    Math.floor((innerWidth - COLUMN_PADDING * (cols - 1)) / cols) - 1;

  const isSmall = barStyle === "small";
  const isLollipop = barStyle === "lollipop";
  const isDotPlot = barStyle === "dotPlot";

  const inlineBarStyle = !!inlineValue || !!inlineLabel;

  // filter and map data to clean objects
  let filteredValues = values;
  if (filter) {
    const filterFn = unsafeDatumFn(filter);
    filteredValues = values.filter(filterFn);
  }

  let data = filteredValues.map((d) => ({
    datum: d,
    label: d[y],
    value: d.value,
    category: undefined,
  }));

  // compute category
  if (category) {
    const categorize = unsafeDatumFn(category);
    data.forEach((d) => {
      d.category = categorize(d.datum);
    });
  }
  // sort by value (default lowest on top)
  runSort(sort, data, (d) => d.value);

  // group data into columns
  let groupedData;
  if (columnFilter) {
    groupedData = columnFilter.map(({ test, title }) => {
      const filter = unsafeDatumFn(test);
      return {
        key: title,
        values: data.filter((d) => filter(d.datum)),
      };
    });
    data = groupedData.reduce((all, group) => [...all, ...group.values], []); // eslint-disable-line
  } else {
    groupedData = groupBy(data, (d) => (column ? d.datum[column] : undefined));
  }
  runSort(columnSort, groupedData, (d) => d.key);

  // compute colors
  const colorAccessor = color ? (d) => d.datum[color] : (d) => d.category;
  let colorValues = [
    ...new Set(
      [...data.map(colorAccessor)].concat(colorLegendValues).filter(isDefined)
    ),
  ];

  runSort(colorSort, colorValues);
  let _colorRange =
    typeof colorRange === "string" ? M.colorRanges[colorRange] : colorRange;
  if (!_colorRange) {
    _colorRange = M.colorRanges.discrete;
  }
  const colorScale = scaleOrdinal(_colorRange).domain(colorValues);

  const highlightFn = highlight ? unsafeDatumFn(highlight) : () => false;

  const __barStyle = useBarStyles(barStyle);
  const _barStyle = inlineBarStyle ? INLINE_BAR_STYLE : __barStyle;

  groupedData = groupedData.map(({ values: groupData, key: title }) => {
    const topPadding = title ? COLUMN_TITLE_HEIGHT : 0;
    let gY = 0;

    let firstBarY = 0;
    let stackedBars = groupBy(groupData, (d) => d.label);
    let marginBottom = 0;
    const bars = stackedBars.map(({ values: segments }) => {
      const first = segments[0];
      const style =
        _barStyle === INLINE_BAR_STYLE
          ? _barStyle[
              first.datum[inlineSecondaryLabel] ? "highlighted" : "normal"
            ]
          : _barStyle[highlightFn(first.datum) ? "highlighted" : "normal"];

      gY += marginBottom;
      let labelY = gY;
      if (first.label) {
        gY += BAR_LABEL_HEIGHT;
      }
      gY += style.marginTop;
      let y = gY;
      if (firstBarY === undefined) {
        firstBarY = gY;
      }

      gY += style.height;
      marginBottom = style.marginBottom;

      let barSegments = segments;
      runSort(colorSort, barSegments, colorAccessor);

      return {
        labelY,
        y,
        style,
        height: style.height,
        segments: barSegments,
        first,
        max: isDotPlot
          ? max(barSegments, (d) => d.value)
          : barSegments.reduce(
              (sum, segment) => sum + Math.max(0, segment.value),
              0
            ),
        min: isDotPlot
          ? min(barSegments, (d) => d.value)
          : barSegments.reduce(
              (sum, segment) => sum + Math.min(0, segment.value),
              0
            ),
      };
    });

    return {
      title,
      bars,
      max: max(bars, (bar) => bar.max),
      min: min(bars, (bar) => bar.min),
      height: gY,
      topPadding,
      firstBarY,
    };
  });

  // setup x scale
  const xDomain = domain || [
    Math.min(0, min(groupedData.map((d) => d.min))),
    Math.max(0, max(groupedData.map((d) => d.max))),
  ];
  const x = scaleLinear()
    .domain(xDomain)
    .range(isSmall ? [0, columnWidth] : [8, columnWidth - 8]);
  if (!domain) {
    x.nice(3);
  }

  const xAxis = calculateAxis(numberFormat, tLabel, x.domain());

  // stack bars
  groupedData.forEach((group) => {
    group.bars.forEach((bar) => {
      const xZero = x(0);
      let xPosPositiv = xZero;
      let xPosNegativ = xZero;
      bar.segments.forEach((d, i) => {
        d.color = colorScale(colorAccessor(d));
        const size = x(d.value) - xZero;
        d.x =
          size > 0 ? Math.floor(xPosPositiv) : Math.ceil(xPosNegativ + size);

        d.width =
          Math.ceil(Math.abs(size)) + (size && last(bar.segments, i) ? 1 : 0);
        if (size > 0 && !isDotPlot) {
          xPosPositiv += size;
        } else {
          xPosNegativ += size;
        }
      });
    });
  });

  // rows and columns
  let yPos = 0;
  groupBy(groupedData, (d, i) => Math.floor(i / cols)).forEach(
    ({ values: groups }) => {
      const topPadding = max(groups.map((d) => d.topPadding));
      const height = max(groups.map((d) => d.height));

      yPos += topPadding;

      groups.forEach((group, column) => {
        group.groupHeight = height;
        group.y = yPos;
        group.x = column * (columnWidth + COLUMN_PADDING);
      });

      yPos += height + ROW_PADDING;
    }
  );

  yPos -= ROW_PADDING; // Remove the last row's padding

  const _xTicks = xTicks || xAxis.ticks;
  const highlightZero = _xTicks.indexOf(0) !== -1 && _xTicks[0] !== 0;

  return (
    <div>
      <svg width={width} height={yPos + MARGINS.top + MARGINS.bottom}>
        <desc>{description}</desc>
        <g transform={`translate(${MARGINS.left}, ${MARGINS.top})`}>
          {groupedData.map((group) => {
            return (
              <g
                key={`group${group.title || 1}`}
                transform={`translate(${group.x},${group.y})`}
              >
                <text x={x.range()[0]} dy="-1em" css={styles.groupTitle}>
                  {group.title}
                </text>
                {!inlineValue && (
                  <g
                    transform={`translate(0,${
                      group.groupHeight + AXIS_BOTTOM_PADDING
                    })`}
                  >
                    <line
                      css={styles.axisXLine}
                      x1={x.range()[0]}
                      x2={x.range()[1]}
                      y1={-8}
                      y2={-8}
                      style={{ stroke: baseLineColor }}
                    />
                    {_xTicks.map((tick, i) => {
                      let textAnchor = "middle";
                      const isLast = last(_xTicks, i);
                      if (isLast) {
                        textAnchor = "end";
                      }
                      if (i === 0) {
                        textAnchor = "start";
                      }
                      const highlightTick = tick === 0 && highlightZero;
                      return (
                        <g
                          key={`tick${tick}`}
                          transform={`translate(${x(tick) + 0.5},0)`}
                        >
                          <line
                            css={styles.axisXLine}
                            y1={
                              -AXIS_BOTTOM_PADDING -
                              group.groupHeight +
                              BAR_LABEL_HEIGHT
                            }
                            y2={-AXIS_BOTTOM_PADDING + 8}
                            style={{
                              stroke: highlightTick ? baseLineColor : undefined,
                            }}
                          />
                          <line
                            css={styles.axisXLine}
                            y1={-8}
                            y2={-5}
                            style={{
                              stroke: highlightTick ? baseLineColor : undefined,
                            }}
                          />
                          <text
                            css={styles.axisLabel}
                            y={X_TICK_TEXT_MARGIN}
                            dy="0.7em"
                            textAnchor={textAnchor}
                            style={{
                              fill: highlightTick ? M.blackText : undefined,
                            }}
                          >
                            {xAxis.axisFormat(tick, isLast)}
                          </text>
                        </g>
                      );
                    })}
                  </g>
                )}
                {group.bars.map((bar) => {
                  const href = bar.first.datum[link];
                  let barLabel = (
                    <text
                      css={styles.barLabel}
                      {...(href && styles.barLabelLink)}
                      style={{ fontWeight: bar.style.fontWeight }}
                      y={bar.labelY}
                      dy="0.6em"
                      x={x(0) + (highlightZero ? (bar.max <= 0 ? -2 : 2) : 0)}
                      textAnchor={bar.max <= 0 ? "end" : "start"}
                    >
                      {subsup.svg(bar.first.label)}
                    </text>
                  );
                  if (href) {
                    barLabel = <a xlinkHref={href}>{barLabel}</a>;
                  }

                  return (
                    <g key={`bar${bar.y}`}>
                      {barLabel}
                      {isDotPlot && (
                        <line
                          css={styles.axisXLine}
                          x1={x(bar.min)}
                          x2={x(bar.max)}
                          y1={bar.y}
                          y2={bar.y}
                        />
                      )}
                      {bar.segments.map((segment, i) => {
                        const isLast = last(bar.segments, i);
                        const valueTextStartAnchor =
                          (segment.value >= 0 && isLast) ||
                          (segment.value < 0 && i !== 0);
                        const inlineFill = getTextColor(segment.color);
                        const inlineEndAnchor = isLast && i !== 0;

                        return (
                          <g
                            key={`seg${i}`}
                            transform={`translate(0,${bar.y})`}
                          >
                            <motion.rect
                              fill={segment.color}
                              height={bar.height}
                              initial={false}
                              animate={{ x: segment.x, width: segment.width }}
                              transition={{ ease: "easeOut" }}
                            />
                            {isSmall && (inlineValue || inlineLabel) && (
                              <Fragment>
                                <text
                                  css={styles.inlineLabel}
                                  x={
                                    segment.x +
                                    (inlineEndAnchor ? segment.width - 5 : 5)
                                  }
                                  y={bar.style.inlineTop}
                                  dy="1em"
                                  fontSize={bar.style.fontSize}
                                  fill={inlineFill}
                                  textAnchor={inlineEndAnchor ? "end" : "start"}
                                >
                                  {subsup.svg(
                                    [
                                      inlineValue &&
                                        xAxis.format(segment.value),
                                      inlineValueUnit && inlineValueUnit,
                                      inlineLabel && segment.datum[inlineLabel],
                                    ].join(" ")
                                  )}{" "}
                                </text>
                                {inlineSecondaryLabel && (
                                  <text
                                    css={styles.inlineLabel}
                                    x={
                                      segment.x +
                                      (inlineEndAnchor ? segment.width - 5 : 5)
                                    }
                                    y={
                                      bar.style.inlineTop +
                                      bar.style.fontSize +
                                      5
                                    }
                                    dy="1em"
                                    fontSize={bar.style.secondaryFontSize}
                                    fill={inlineFill}
                                    textAnchor={
                                      inlineEndAnchor ? "end" : "start"
                                    }
                                  >
                                    {subsup.svg(
                                      segment.datum[inlineSecondaryLabel]
                                    )}
                                  </text>
                                )}
                              </Fragment>
                            )}
                            {isLollipop && band && (
                              <rect
                                rx={bar.style.radius}
                                ry={bar.style.radius}
                                x={x(+segment.datum[`${band}_lower`])}
                                y={bar.height / 2 - bar.style.radius}
                                width={
                                  x(+segment.datum[`${band}_upper`]) -
                                  x(+segment.datum[`${band}_lower`])
                                }
                                height={bar.style.radius}
                                fill={segment.color}
                                fillOpacity="0.3"
                              />
                            )}
                            {isLollipop && (
                              <circle
                                cx={segment.x + segment.width}
                                cy={bar.height / 2}
                                r={bar.style.radius}
                                fill={segment.color}
                              />
                            )}
                            {isDotPlot && (
                              <circle
                                cx={segment.x + segment.width}
                                cy={bar.height / 2}
                                r={bar.style.radius}
                                fill={segment.color}
                              />
                            )}
                            {isDotPlot && showTooltip && (
                              <ChartTooltip
                                title={subsup.svg(bar.first.label)}
                                content={
                                  <TooltipGridBodyWithLegend
                                    rows={bar.segments.map((segment) => [
                                      colorScale(colorAccessor(segment)),
                                      colorAccessor(segment),
                                      xAxis.format(segment.value),
                                    ])}
                                  />
                                }
                                placement="top"
                                distance={bar.style.radius}
                              >
                                <circle
                                  cx={segment.x + segment.width}
                                  cy={bar.height / 2}
                                  r={bar.style.radius * 2}
                                  fill={"transparent"}
                                />
                              </ChartTooltip>
                            )}
                            {showBarValues && (
                              <text
                                css={styles.barLabel}
                                x={
                                  valueTextStartAnchor
                                    ? segment.x + segment.width + 4
                                    : segment.x +
                                      (segment.value >= 0 ? segment.width : 0) -
                                      4
                                }
                                textAnchor={
                                  valueTextStartAnchor ? "start" : "end"
                                }
                                y={bar.height / 2}
                                dy=".35em"
                              >
                                {xAxis.format(segment.value)}
                              </text>
                            )}

                            {showBarValuesHighlight && segment.datum.highlight && (
                              <text
                                css={css`
                                  ${styles.barLabel};
                                  fill: ${inlineFill};
                                `}
                                x={
                                  valueTextStartAnchor
                                    ? segment.x + segment.width - 8
                                    : segment.x +
                                      (segment.value >= 0 ? segment.width : 0) -
                                      8
                                }
                                textAnchor="end"
                                y={bar.height / 2}
                                dy=".35em"
                              >
                                {xAxis.format(segment.value)}
                              </text>
                            )}
                          </g>
                        );
                      })}
                      {isSmall && showTooltip && (
                        <ChartTooltip
                          title={subsup.svg(bar.first.label)}
                          content={
                            <TooltipGridBodyWithLegend
                              rows={bar.segments.map((segment) => [
                                colorScale(colorAccessor(segment)),
                                colorAccessor(segment),
                                xAxis.format(segment.value),
                              ])}
                            />
                          }
                          placement="top"
                        >
                          <rect
                            x={x(bar.min)}
                            width={x(bar.max - bar.min)}
                            y={bar.y}
                            height={bar.height}
                            fill="transparent"
                          />
                        </ChartTooltip>
                      )}
                    </g>
                  );
                })}
              </g>
            );
          })}
        </g>
      </svg>
      <div
        css={css`
          margin-left: ${x.range()[0]}px;
        `}
      >
        <ColorLegend
          inline
          values={[]
            .concat(
              colorLegend &&
                (colorLegendValues || colorValues).map((colorValue) => ({
                  color: colorScale(colorValue),
                  label: colorValue,
                }))
            )
            .concat(
              !mini &&
                band &&
                bandLegend && {
                  label: (
                    <span css={styles.bandLegend}>
                      <span css={styles.bandBar} />
                      {` ${bandLegend}`}
                    </span>
                  ),
                }
            )
            .filter(Boolean)}
        />
        {children}
      </div>
    </div>
  );
};

export const Lollipop = (props) => <BarChart {...props} />;

Lollipop.defaultProps = {
  barStyle: "lollipop",
};

// Lollipop has additional default props
Lollipop.wrap = "Bar";
