import css from "@emotion/css";
import { mkStylesWithProps, useQueryParamsWithDefault, useTheme } from "hooks";
import React, { HTMLAttributes } from "react";
import { findArticleAlias, REnv } from "routes";
import * as M from "../materials";
import { pipe, O } from "../prelude";
import gemLogoPath from "../materials/assets/gem-report-logo.svg";
import riseLogoPath from "../materials/assets/rise-logo.svg";

const GemUnifsLogoImg = (
  props: Omit<React.HTMLProps<HTMLImageElement>, "crossOrigin">
) => {
  return <img alt="" src={gemLogoPath} {...props} />;
};

const RiseUnifsLogoImg = (
  props: Omit<React.HTMLProps<HTMLImageElement>, "crossOrigin">
) => {
  return <img alt="" src={riseLogoPath} {...props} />;
};

const useWaveShapeStyles = mkStylesWithProps(
  ({ client: { isRTL, screenMDown } }, { flipped }: { flipped: boolean }) => {
    const rotate = isRTL ? 180 : 0;
    const scaleY = (flipped ? -1 : 1) * (isRTL ? -1 : 1);

    return {
      root: css`
        width: ${screenMDown ? WAVE_WIDTH_SMALL : WAVE_WIDTH}px;
        height: ${screenMDown ? WAVE_HEIGHT_SMALL : WAVE_HEIGHT}px;
        transform: rotate(${rotate}deg) scaleY(${scaleY});
      `,
      path: css`
        fill: ${M.unescoDarkRed};
        fill-rule: evenodd;
      `,
    };
  }
);

function WaveShape(
  props: React.SVGProps<SVGSVGElement> & {
    flipped: boolean;
  }
) {
  const { flipped, ...svgProps } = props;
  const styles = useWaveShapeStyles({ flipped });
  const {
    client: { screenMDown },
  } = useTheme();

  return (
    <svg css={styles.root} {...svgProps}>
      {screenMDown ? (
        <path
          d="M0,0 L38,0 L38,48 L37.5272547,48 C31.0243351,48 25.0270245,44.492488 21.8388951,38.8247023 L0,0 Z"
          css={styles.path}
        />
      ) : (
        <path
          d="M76.329 95c-15.332 0-29.138-8.822-36.032-21.47L-.28-.008H76.33V495z"
          css={styles.path}
        />
      )}
    </svg>
  );
}

const SMALLER_LINE_HEIGHT = 25;
const SMALLER_LINE_HEIGHT_SMALL = 25;
const WAVE_WIDTH = 76;
const WAVE_WIDTH_SMALL = 38;
const WAVE_HEIGHT = 95;
const WAVE_HEIGHT_SMALL = 48;
const TOTAL_HEIGHT = WAVE_HEIGHT + SMALLER_LINE_HEIGHT; // I do not know why we need to have
const TOTAL_HEIGHT_SMALL = WAVE_HEIGHT_SMALL + SMALLER_LINE_HEIGHT_SMALL;

const useSecondaryBrandsBannerStyles = mkStylesWithProps(
  ({ client: { isRTL, screenMDown } }, { flipped }: { flipped: boolean }) => {
    const totalHeight = screenMDown ? TOTAL_HEIGHT_SMALL : TOTAL_HEIGHT;
    const waveHeight = screenMDown ? WAVE_HEIGHT_SMALL : WAVE_HEIGHT;
    const smallLineHeight = screenMDown
      ? SMALLER_LINE_HEIGHT_SMALL
      : SMALLER_LINE_HEIGHT;

    return {
      root: css`
        display: flex;
        flex-direction: column;
        height: ${totalHeight}px;
        width: 100%;
        pointer-events: none;
      `,
      divider: css`
        height: ${smallLineHeight}px;
        background-color: ${M.unescoDarkRed};
        border: 1px solid ${M.unescoDarkRed};
      `,
      waveShapeWrapper: css`
        margin-block-start: -1px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        height: ${waveHeight}px;
        color: white;
        margin-block-end: -4px;
      `,
      unifsLogoWrapper: css`
      display: flex;
      margin-inline-start: -1px;
      align-items: center;
      height: 100%;
      background-color: ${M.unescoDarkRed};
      padding-${isRTL ? "left" : "right"}: 8px;
      border: 1px solid ${M.unescoDarkRed};
  `,
      unifsLogo: css`
      height: ${screenMDown ? "60" : "50"}%;
      position: relative;
      top: ${(smallLineHeight / 2) * (flipped ? 1 : -1)}px;
      margin-${isRTL ? "left" : "right"}:  ${screenMDown ? "12" : "32"}px;
  `,
    };
  }
);

/**
 * Displays the red banner containing the secondary brands
 *
 * - By default it overlaps the content only a  wider screen since on wider screens
 *   the brands are outside the middle of the page
 * - By default, on smaller screens, it will not overlap since otherwise the brands
 *   would overlap the actual content of the page
 * - Indexes page for countries and indicators force the overlapping since they
 *   have no content to be overlapped
 *
 * This is why there is both the concept of implicit overContent (default) and
 * explicitOverContent (indexes page) along with the responsive definitions.
 *
 * It can be flipped vertically for example for the home page.
 */
const SecondaryBrandsBanner = ({
  flipped,
  overContent = true,
  ...props
}: {
  flipped?: boolean | boolean[];
  overContent?: boolean | boolean[];
} & HTMLAttributes<HTMLDivElement>) => {
  // const implicitOverContent = overContent !== false;
  // const explicitOverContent = overContent === true;
  const styles = useSecondaryBrandsBannerStyles({
    flipped: flipped as boolean,
  });

  const { alias } = useQueryParamsWithDefault(REnv);
  const aliasRouteO = findArticleAlias(alias);

  const logos = pipe(
    aliasRouteO,
    O.fold(
      () => [GemUnifsLogoImg], // default
      (route) =>
        route === "trajectories"
          ? [GemUnifsLogoImg, RiseUnifsLogoImg]
          : [GemUnifsLogoImg]
    )
  );

  return (
    <div
      data-screenshot-hide
      css={styles.root}
      style={{
        position: overContent ? "absolute" : "relative",
      }}
      {...props}
    >
      {flipped ? null : <div css={styles.divider} />}
      <div css={styles.waveShapeWrapper}>
        <WaveShape flipped={flipped as boolean} />
        <div css={styles.unifsLogoWrapper}>
          {logos.map((Logo, index) => (
            <Logo key={index} css={styles.unifsLogo} />
          ))}
        </div>
      </div>
      {flipped ? <div css={styles.divider} /> : null}
    </div>
  );
};

export default SecondaryBrandsBanner;
