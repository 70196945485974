import css from "@emotion/css";
import React, { useState } from "react";
import { useQueryParamsWithDefault, useTheme } from "../hooks";
import * as M from "../materials";
import { DEFAULT_LAYOUT, foldLayout, Layout } from "../materials";
import { Grid } from "../materials/grid";
import { O, io, pipe } from "../prelude";
import { findArticleAlias, RAliasArticle, REnv } from "../routes";
import { ArticleNavigation } from "./article-navigation";
import { ArticleSwitcher } from "./article-switcher";
import { ArticleSwitcherLegacy } from "./article-switcher-legacy";
import { Footer } from "./footer";
import { HeadMetaDynamic } from "./head-meta";
import { Header } from "./header";
import { HeaderSmall } from "./header-small";
import { TOCContext } from "./hero-vis/TOC";
import { SkipNavigation } from "./SkipNavigation";
import { flag } from "flags";

const themesDrawerFlag = flag("improved-themes-drawer");

export const Frontmatter = io.intersection([
  io.type({
    title: io.string,
    lead: io.string, // FIXME: clarify whether we really want the lead to be a variable, possibly not
  }),
  io.partial({
    layout: Layout,
    year: io.number,
    article: RAliasArticle,
  }),
]);
export type Frontmatter = io.TypeOf<typeof Frontmatter>;

export const mkAppLayout =
  (children: $Children) =>
  ({ title, lead, layout = DEFAULT_LAYOUT }: Frontmatter) => {
    const { spacing, activeTheme, utils } = useTheme();
    const [sections, setSections] = useState<string[]>([]);

    const { alias, locale } = useQueryParamsWithDefault(REnv);

    const { verticalPadding, darkBackground } = foldLayout(layout, {
      home: () => ({ verticalPadding: false, darkBackground: false }),
      simple: () => ({ verticalPadding: true, darkBackground: false }),
      article: () => ({ verticalPadding: false, darkBackground: false }),
      articleNarrow: () => ({ verticalPadding: false, darkBackground: false }),
      full: () => ({ verticalPadding: true, darkBackground: true }),
      none: () => ({ verticalPadding: false, darkBackground: false }),
      explorer: () => ({ verticalPadding: true, darkBackground: false }),
      focus: () => ({ verticalPadding: false, darkBackground: false }),
    });

    const bg =
      layout === "article"
        ? utils.lighten(activeTheme.background.light, 0.9)
        : "white";

    const aliasRouteO = findArticleAlias(alias);
    const aliasRoute = pipe(
      findArticleAlias(alias),
      O.getOrElseW(() => undefined)
    );

    return (
      <div css={rootLayout}>
        <HeadMetaDynamic
          locale={locale}
          title={title}
          description={lead}
          pageType={
            layout === "article" || layout === "focus" ? "article" : "website"
          }
        />
        <header>
          <SkipNavigation id="main" />
          <div css={hideOnNarrow}>
            <Header />
            {themesDrawerFlag ? (
              <ArticleSwitcher />
            ) : (
              <ArticleSwitcherLegacy selectedArticle={aliasRouteO} />
            )}
          </div>
          <div css={showOnNarrow}>
            <HeaderSmall />
          </div>
        </header>
        <main
          id="main"
          css={[
            verticalPadding &&
              css`
                padding: ${spacing.base8(10)} 0;
              `,
            darkBackground
              ? css`
                  background: ${M.grayscalePalette[2]};
                  text-align: center;
                `
              : css`
                  background: ${bg};
                `,
          ]}
        >
          <TOCContext.Provider
            value={{
              ids: sections,
              addId: (x) => {
                if (!sections.includes(x)) {
                  setSections([...sections, x]);
                }
              },
              removeId: (x) => {
                if (sections.includes(x)) {
                  setSections(sections.filter((d) => d !== x));
                }
              },
            }}
          >
            <Grid layout={layout}>{children}</Grid>
          </TOCContext.Provider>

          {aliasRoute && <ArticleNavigation aliasRoute={aliasRoute} />}
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  };

// -----------------------------------------------------------------------------
// Components

const rootLayout = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > * {
    flex-shrink: 0;
  }

  & > main {
    flex: 1 0 auto;
  }
`;

const showOnNarrow = css`
  display: none;
  @media ${M.bpDown("l")} {
    display: block;
  }
`;
const hideOnNarrow = css`
  display: block;
  @media ${M.bpDown("l")} {
    display: none;
  }
`;

// -----------------------------------------------------------------------------
// Grid

export const gridCenteredMaxWidth = () => {
  const areas = {
    main: "main",
    banner: "banner",
  };
  return {
    grid: css`
      display: grid;
      grid-template-columns: 1fr minmax(20px, ${M.spacing.base8(145)}) 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: ". ${areas.main} .";
      column-gap: ${M.spacing.base8(3)};

      @media ${M.bpDown("s")} {
        column-gap: ${M.spacing.base8(2)};
      }
    `,
    areas,
  };
};
