import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "PARITYCOUNT",
  title: /*i18n*/ {
    id: "fig.equity.PARITYCOUNT.title",
    defaults: `Percentage of countries that have achieved gender parity in the gross enrolment ratio, by education level`,
  },
  caption: /*i18n*/ {
    id: "fig.equity.PARITYCOUNT.caption",
    defaults: `Many countries have yet to achieve parity in secondary education`,
  },
  source: /*i18n*/ {
    id: "fig.equity.PARITYCOUNT.source",
    defaults: `GEM Report team analysis based on UIS data`,
  },
};
