// WARNING: routes.gen dependency
// We need to be careful to not use any import that references "prelude". We
// defined "prelude" to be an alias to "./prelude" for clarity and ease-of-use,
// but the alias can't be resolved by ts-node/node. This is a problem for all
// script code in genPathMap(), because it needs to be run as a node script.
// Affected entry points:
//   - /locales/index.tsx
//   - /routes.ts
import { Catalog, setupI18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import * as io from "io-ts";
import dynamic from "next/dynamic";
import React from "react";
import { InvariantViolation } from "../lib/exception";
export const DEFAULT_LOCALE: Locale = "en";

export const Locale = io.keyof({
  en: null,
  fr: null,
  es: null,
  ru: null,
  ar: null,
  zh: null,
  de: null,
});

export type Locale = io.TypeOf<typeof Locale>;

export const localeKeys = Object.keys(Locale.keys) as Array<Locale>;

export function foldLocale<A>(pattern: { [K in Locale]: A }): (
  locale: Locale
) => A {
  return (locale: Locale) => pattern[locale];
}

export function foldLocaleL<A>(pattern: { [K in Locale]: () => A }): (
  locale: Locale
) => A {
  return (locale: Locale) => pattern[locale]();
}

export type LocaleDir = "ltr" | "rtl";

export interface LocaleMeta {
  lang: Locale;
  name: string;
  dir: LocaleDir;
}

export const locales: Array<LocaleMeta> = [
  {
    lang: "en",
    name: "English",
    dir: "ltr",
  },
  {
    lang: "fr",
    name: "Français",
    dir: "ltr",
  },
  {
    lang: "es",
    name: "Español",
    dir: "ltr",
  },
  {
    lang: "ru",
    name: "Русский",
    dir: "ltr",
  },
  {
    lang: "ar",
    name: "العربية",
    dir: "rtl",
  },
  {
    lang: "zh",
    name: "中文",
    dir: "ltr",
  },
  {
    lang: "de",
    name: "Deutsch",
    dir: "ltr",
  },
];

export const lookupLocale = (locale: Locale) => {
  const result = locales.find((x) => x.lang === locale);
  if (result != null) {
    return result;
  } else {
    throw new InvariantViolation(
      `Could not find locale metadata for ID "${locale}"`
    );
  }
};

// -----------------------------------------------------------------------------
// Locale Provider

// If the language changes, we overwrite this variable with a new instance.
// This is to make sure hooks are updated (because it's a new reference).
// Attention: To function _fully_ correctly, this would need more work.
let i18n = setupI18n({
  missing: (language: string, id: string) => {
    console.warn(`[${language}] Translation missing: ${id}`);
    return id;
  },
});

export function useI18n() {
  return i18n;
}

const mkLocaleProvider = foldLocale<React.ComponentType<$PropsWithChildren>>({
  ar: dynamic(() => import("./ar/messages").then(mkI18nProvider("ar"))),
  de: dynamic(() => import("./de/messages").then(mkI18nProvider("de"))),
  en: dynamic(() => import("./en/messages").then(mkI18nProvider("en"))),
  es: dynamic(() => import("./es/messages").then(mkI18nProvider("es"))),
  fr: dynamic(() => import("./fr/messages").then(mkI18nProvider("fr"))),
  ru: dynamic(() => import("./ru/messages").then(mkI18nProvider("ru"))),
  zh: dynamic(() => import("./zh/messages").then(mkI18nProvider("zh"))),
});

export const LocaleProvider = ({
  locale,
  ...props
}: $PropsWithChildren<{ locale: Locale }>) => {
  const Provider = React.useMemo(() => mkLocaleProvider(locale), [locale]);
  return React.createElement(Provider, props);
};

const mkI18nProvider =
  (locale: Locale) =>
  (m: { default: Catalog }) =>
  ({ children }: $PropsWithChildren) => {
    i18n.load({ [locale]: m.default });
    i18n = i18n.use(locale);
    return (
      <I18nProvider i18n={i18n} language={locale}>
        {children}
      </I18nProvider>
    );
  };
