import { shallowEqual } from "fast-equals";
import { useRouter } from "next/router";
import { parse } from "query-string";
import React from "react";
/**
 * useNextClientRouteParams
 *
 * Query params that are not passed in directly through the URL (and hence are
 * never seen during SSR) are not automatically added to the router by Next.
 * This hook adds the missing params to the router's query object.
 */
export function useNextClientRouteParams() {
  const router = useRouter();
  // Run this once when the component mounts (simulate componentDidMount)
  React.useEffect(() => {
    const query = parse(window.location.search);
    const extendedQuery = { ...router.query, ...query };
    if (!shallowEqual(router.query, extendedQuery)) {
      const href = { pathname: router.pathname, query: extendedQuery };
      router.replace(href, router.asPath).catch(console.error);
    }
  }, [router]);
  return router;
}
