import css from "@emotion/css";
import React from "react";
import { ChartTooltip } from "../../components/tooltip";
import * as M from "../../materials";
import { ReactComponent as InfoIcon } from "./tooltip/ic_info_16dp.svg";

const rootStyles = css`
  display: inline;
  font-weight: bold;
  color: ${M.unescoMarineBlue};
  &:hover {
    cursor: pointer;
  }
`;

const iconStyles = css`
  position: relative;
  top: 0.1em;
  display: inline-block;
  color: ${M.unescoMarineBlue};
`;

export const Tooltip = ({
  children,
  title,
  content,
}: {
  children: React.ReactChild;
  title: string;
  content: string;
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <span
      css={rootStyles}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}&nbsp;
      <ChartTooltip
        title={title}
        content={content}
        placement="top"
        trigger={"manual"}
        visible={hovered}
        hideOnClick={false}
      >
        <span>
          <InfoIcon css={iconStyles} />
        </span>
      </ChartTooltip>
    </span>
  );
};
