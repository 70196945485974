import React from "react";
import { motion } from "framer-motion";

import { lineChartDefaultStyles } from "../shared/styles";
import { springConfigs } from "../motion";
import * as M from "../../../materials";

const HoverText = React.memo(
  ({
    x,
    y,
    textFlippedV,
    textAnchor,
    title,
    xLabel,
    yLabel,
  }: {
    x?: number;
    y?: number;
    textFlippedV: boolean;
    title: string;
    xLabel: string;
    yLabel: string;
    textAnchor: "end" | "start";
  }): React.ReactElement => (
    <motion.g
      initial={{
        translateX: x,
        y,
        opacity: 0,
        scale: 0.9,
      }}
      animate={{
        translateX: x,
        y,
        opacity: 1,
        scale: 1,
      }}
      exit={{
        translateX: x,
        y,
        opacity: 0,
        scale: 0.9,
      }}
      transition={springConfigs.gentle}
    >
      <text textAnchor={textAnchor}>
        <tspan
          css={lineChartDefaultStyles.title}
          x={0}
          dx={M.spacing.base8(textAnchor === "end" ? -2 : 2)}
          dy={textFlippedV ? "1.6em" : "0.3em"}
        >
          {title}
        </tspan>
        <tspan
          css={lineChartDefaultStyles.label}
          x={0}
          dx={M.spacing.base8(textAnchor === "end" ? -2 : 2)}
          dy={"1.2em"}
        >
          {xLabel}
        </tspan>
        <tspan
          css={lineChartDefaultStyles.label}
          x={0}
          dx={M.spacing.base8(textAnchor === "end" ? -2 : 2)}
          dy={"1.2em"}
        >
          {yLabel}
        </tspan>
      </text>
    </motion.g>
  )
);

export default HoverText;
