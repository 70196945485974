import css from "@emotion/css";
import { gridCenteredMaxWidth } from "components/app-layout";
import { mkStyles } from "hooks";
import * as M from "../../../materials";

export const useRowStyles = mkStyles(
  ({ palette, utils, typography, breakpoints, spacing }) => {
    return {
      rootHighlighted: css`
        background-color: ${utils.fade(palette.brand.marine.light, 0.1)};
      `,
      row: css`
        display: flex;
        color: ${palette.text.black};
        border-bottom: 1px solid ${utils.fade(palette.text.black, 0.1)};
        padding: ${M.spacing.base8(3)};
        justify-content: flex-start;

        @media ${breakpoints.down("l")} {
          padding: ${M.spacing.base8(1)};
          flex-direction: column-reverse;
          padding-bottom: ${M.spacing.base8(2)};
        }
      `,
      rowLink: css`
        &:hover {
          background-color: ${palette.grayscale[2]};
          text-decoration: none;
          cursor: pointer;
        }
      `,
      mainContent: css`
        ${typography.preset.fontBody2};
        max-width: 96%;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-right: ${M.spacing.base8(3)};

        & > p {
          text-align: left;

          html[dir="rtl"] & {
            text-align: right;
          }

          @media ${breakpoints.down("l")} {
            order: 3;
          }
        }

        html[dir="rtl"] & {
          padding-right: 0;
          padding-left: ${M.spacing.base8(3)};
        }

        @media ${breakpoints.down("l")} {
          flex-direction: row;
          align-items: start;
          flex-wrap: wrap;
          padding-right: 0;
          margin-top: ${M.spacing.base8(1)};
          margin-bottom: ${M.spacing.base8(1)};
          order: 4;
          max-width: 100%;

          html[dir="rtl"] & {
            padding-left: 0;
          }
        }
      `,
      linkArrow: css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;

        @media ${breakpoints.down("l")} {
          margin-top: ${spacing.base8(1)};
        }
      `,
      link: css`
        ${M.linkBody};
        font-size: 14px;
        font-weight: 700;

        @media ${breakpoints.up("l")} {
          display: none;
        }
      `,
      linkImg: css`
        display: inline-block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-left: ${spacing.base8(1)};
        margin-right: 0;

        html[dir="rtl"] & {
          margin-left: 0;
          margin-right: ${spacing.base8(1)};
        }
      `,
    };
  }
);

export const useSectionStyles = mkStyles(
  ({ spacing: { base8 }, breakpoints }) => {
    const { areas } = gridCenteredMaxWidth();

    return {
      root: css`
        grid-column: ${M.layoutAreas.main};
        color: black;
      `,
      table: css`
        grid-area: ${areas.main};
        text-align: left;
        sbackground-color: white;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        background-color: white;
        margin-top: ${base8(2)};

        @media ${breakpoints.down("m")} {
          margin: 0;
          grid-column: ${M.layoutAreas.full};
        }
      `,

      content: css`
        width: 100%;
        padding-bottom: 0;
        text-align: left;

        html[dir="rtl"] & {
          text-align: right;
        }
      `,
    };
  }
);
