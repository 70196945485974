import css from "@emotion/css";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";
import { O, pipe, R } from "../../prelude";
import { findArticleAliasRelaxed, foldRAliasArticle_ } from "../../routes";
import { PageLink } from "../links";

const useStyles = mkStyles(
  ({ palette, spacing: { base1 }, utils: { getContrastText } }) => {
    return {
      base: css`
        display: inline;
        border-radius: ${base1(2)};
        line-height: 1;
        padding: 0 0.2em;
      `,
      color: (highlightColor: string) => css`
        background: ${highlightColor};
        color: ${getContrastText(highlightColor, {
          dark: palette.text.black,
          light: palette.text.white,
        })};
      `,
      colorOnDark: (highlightColor: string) => css`
        background: ${highlightColor};
        color: ${getContrastText(highlightColor, {
          dark: M.unescoDarkBlue,
          light: palette.text.white,
        })};
      `,
    };
  }
);

/**
 * This component's exact name is used in ScrollySection to automatically
 * pass in props. Make sure that if you change the name, you also change
 * the name there.
 *
 * (It would be nice to rely on Highlight.name for this comparison, but
 * unfortunately, this does not work in production).
 */
export const Highlight = ({
  children,
  color,
  onDark,
}: $PropsWithChildren<{ color?: string; onDark?: boolean }>) => {
  const styles = useStyles();
  return color ? (
    <mark
      css={[
        styles.base,
        onDark ? styles.colorOnDark(color) : styles.color(color),
      ]}
    >
      {children}
    </mark>
  ) : (
    <span>{children}</span>
  );
};

export const HighlightByIndex = ({
  children,
  index: idx,
}: $PropsWithChildren<{ index?: number | string }>) => {
  // If no index is given or the index is "-1", return a mid grey
  const color =
    idx == null || +idx == -1
      ? M.grayscalePalette[4]
      : M.fromIndexDiscrete(M.colorRanges.discrete, +idx);
  return <Highlight color={color}>{children}</Highlight>;
};

export const HighlightByPalette = ({
  children,
  palette: paletteO,
  index: idx,
}: $PropsWithChildren<{ palette?: string; index?: number | string }>) => {
  const palette = pipe(
    O.fromNullable(paletteO),
    O.chain((id) => R.lookup(`q${id}Palette`, M)),
    O.getOrElse(() => M.grayscalePalette)
  );
  const color = M.fromIndexDiscrete(palette, idx == null ? 0 : +idx);
  return <Highlight color={color}>{children}</Highlight>;
};

export const HighlightByTheme = ({
  children,
  theme,
}: $PropsWithChildren<{ theme?: string }>) => {
  return pipe(
    findArticleAliasRelaxed(theme),
    O.fold(
      () => <span>{children}</span>,
      (alias) => {
        const color = foldRAliasArticle_({
          access: () => M.q01Palette[2],
          equity: () => M.q04Palette[7],
          learning: () => M.q06Palette[6],
          trajectories: () => M.q06Palette[6],
          quality: () => M.q08Palette[6],
          finance: () => M.q09Palette[7],
          financeAid: () => M.q09Palette[7],
        })(alias);
        return (
          <PageLink name={alias} query={{}}>
            <a style={{ fontWeight: "inherit" }}>
              <Highlight color={color} onDark>
                {children}
              </Highlight>
            </a>
          </PageLink>
        );
      }
    )
  );
};
