module.exports = {
    "scalars": [
        0,
        1,
        3,
        4,
        5,
        6,
        8
    ],
    "types": {
        "FACET": {},
        "SKILL": {},
        "Observation": {
            "id": [
                3
            ],
            "country": [
                4
            ],
            "skill": [
                4
            ],
            "group": [
                4
            ],
            "value": [
                5
            ],
            "year": [
                6
            ],
            "series": [
                4
            ],
            "facet": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "ID": {},
        "String": {},
        "Float": {},
        "Int": {},
        "Query": {
            "countries": [
                4
            ],
            "groups": [
                4
            ],
            "getObservations": [
                2,
                {
                    "countries": [
                        4,
                        "[String!]!"
                    ],
                    "groups": [
                        4,
                        "[String!]!"
                    ],
                    "facet": [
                        0
                    ],
                    "skill": [
                        1
                    ]
                }
            ],
            "getEqualitySimulations": [
                2,
                {
                    "skill": [
                        1
                    ],
                    "country": [
                        4,
                        "String!"
                    ],
                    "group": [
                        4,
                        "String!"
                    ],
                    "comparisonGroup": [
                        4,
                        "String!"
                    ]
                }
            ],
            "getCountrySimulations": [
                2,
                {
                    "skill": [
                        1
                    ],
                    "country": [
                        4,
                        "String!"
                    ],
                    "comparisonCountry": [
                        4,
                        "String!"
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Boolean": {}
    }
}