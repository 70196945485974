
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Computational thinking is an important component of digital literacy";
export const lead = "Computational thinking, generally understood as solving problems through logical and algorithmic reasoning, is an important component of digital literacy and is included in the Digital Literacy Global Framework for SDG indicator 4.4.2.";
export const layout = "focus";
export const article = "learning";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Computational thinking is an important component of digital literacy" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=290" mdxType="FocusDetails">
      <h1 {...{
        "id": "computational-thinking-is-an-important-component-of-digital-literacy"
      }}>{`Computational thinking is an important component of digital literacy`}</h1>
      <p>{`Computational thinking, generally understood as solving problems through logical and algorithmic reasoning, is an important component of digital literacy and is included in the Digital Literacy Global Framework for SDG indicator 4.4.2 (Law et al., 2018). Although often referred to in computer-related settings, it is increasingly recognized  as a cognitive process that can be developed and applied in various domains, with or without the use of digital devices.`}</p>
      <p>{`The 2018 ICILS was the first cross-national assessment of student achievement in computational thinking. It tested students’ ability to recognize which real-world problems were appropriate for computational formulation and to develop algorithmic solutions that could be used with a computer (Fraillon et al., 2019). The results highlighted digital divides between and within countries. Students who were from a privileged socioeconomic background, as represented by the number of books at home, and spoke the language of the test at home scored consistently and significantly higher than their peers.`}</p>
      <FocusImage size="medium" src={"20-computationalthinking_1.png"} alt="Richer students and boys tend to score higher in computational thinking" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=290" mdxType="FocusImage" />
      <p>{`Results indicated a gender gap in favour of boys, which contrasts with computer and information literacy, the other ICILS domain. Given the construction of the assessments, the contrasting gender patterns may reflect differences in attitudes towards ICT use. While girls are stronger users of ICT for general school-related tasks, boys tend to be better and more confident at performing specialist ICT tasks (e.g. creating programs) (Fraillon et al., 2019). Stronger male performance has also been found in national assessments with similar constructs (Román-González et al., 2017), but researchers who assessed computational skills that were applied differently, as in narrative-based computer games, found that girls outperformed boys (Howland and Good, 2015).`}</p>
      <p>{`The growing recognition of computational thinking as a necessary tool for everyone has led several countries, particularly in eastern Asia and in Europe, to include it in national education curricula (Román-González et al., 2017; Seow et al., 2019). In the Republic of Korea, computational thinking practices, such as developing technology-based products, creating algorithms, and writing and evaluating code, are explicitly included in the curriculum (Fraillon et al., 2019). Finland has made algorithmic thinking and programming compulsory from grade 1 as a cross-curricular activity (Seow et al., 2019).`}</p>
      <p>{`Integrating computational thinking in school must go beyond increasing ICT use. Across ICILS-participating countries, students who more frequently used ICT in school for school-related tasks did not necessarily score higher than their peers. Nor is it enough to teach children how to code. Although computer programming tends to be one of the main contexts and applications for computational thinking, research has shown that students with programming experience are not necessarily able to transfer those skills to non-programming environments. Instead, the focus has shifted towards teaching computational thinking as a transferable, higher-level concept applied in multiple domains (Voogt et al., 2015). Examples of non-digital applications of computational thinking in other subjects include identifying population trends in social studies and performing linguistic analysis of sentences (Yadav et al., 2014).`}</p>
      <FocusImage size="medium" src={"20-computationalthinking_2.png"} alt="Using computers is not necessary to develop computational thinking" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=291" mdxType="FocusImage" />
      <p>{`If students are expected to learn such skills in schools, teachers must be trained to teach them. Studies have shown that including computational thinking in teachers’ pre-service training helps them gain a deeper understanding of it as a cognitive tool that can be applied more widely and across several disciplines (Mouza et al., 2017; Yadav et al., 2014). Yet when computational thinking is included in teacher training, it tends to be limited to computer science teachers (Yadav et al., 2014). This is reflected in practice. Across ICILS-participating countries, teachers in ICT-related classes reported putting more emphasis on teaching skills related to computational thinking than their peers in other subject areas (Fraillon et al., 2019).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=564"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;