import * as R from "@devexperts/remote-data-ts";
import React from "react";
import {
  ChartRenderer,
  Exception,
  foldRenderMode,
  RenderMode,
} from "../domain";
import { useDropzone, useFetchRemote } from "../hooks";
import { io, pipe } from "../prelude";
import { RenderException } from "./render-exception";
import { Spinner } from "./spinner";

interface DataLoaderProps<I, O> {
  children: (x: O) => JSX.Element;
  decoder: io.Decoder<I, O>;
  url: string;
  renderLoading?: () => JSX.Element;
  renderException?: (e: Exception) => JSX.Element;
  minHeight: { narrow: number; wide: number };
}

export function withDataLoader<I, O>(
  url: string,
  decoder: io.Decoder<I, O>,
  Component: React.ComponentType<ChartRenderer<O>>,
  minHeight: { narrow: number; wide: number }
) {
  return ({ renderMode }: { renderMode: RenderMode }) => {
    const Loader = foldRenderMode({
      default: () => DataLoader,
      static: () => DataLoader,
      dropzone: () => DataLoaderWithDropzone,
      embed: () => DataLoader,
    })(renderMode);

    return (
      <Loader
        key={renderMode}
        url={url}
        decoder={decoder}
        minHeight={minHeight}
      >
        {(data) => (
          <>
            <Component data={data} renderMode={renderMode} />
            {/**
             * This selector is used by the screenshot service to determine if
             * the chart is ready to be captured.
             */}
            <div data-loader-ready style={{ display: "none" }}></div>
          </>
        )}
      </Loader>
    );
  };
}

export function DataLoader<I, O>({
  children,
  decoder,
  url,
  renderLoading = () => <Spinner minHeight={minHeight} />,
  renderException = (e) => React.createElement(RenderException, { e }),
  minHeight,
}: DataLoaderProps<I, O>) {
  const state = useFetchRemote(url, decoder);

  return pipe(
    state,
    R.fold(
      renderLoading,
      renderLoading,
      (e) => renderException(e),
      (x) => children(x)
    )
  );
}

export function DataLoaderWithDropzone<I, O>({
  children,
  decoder,
  url,
  renderLoading = () => <Spinner minHeight={{ narrow: 500, wide: 500 }} />,
  renderException = (e) => React.createElement(RenderException, { e }),
}: DataLoaderProps<I, O>) {
  const state = useFetchRemote(url, decoder);
  const { dropState, getRootProps, getInputProps, isDragActive } =
    useDropzone(decoder);

  return pipe(
    state,
    R.fold(
      renderLoading,
      renderLoading,
      (e) => renderException(e),
      (value) => {
        return (
          <>
            {pipe(
              dropState,
              R.fold(
                () => children(value),
                () => <div>Processing file …</div>,
                (e) => (
                  <ul>
                    {e.type === "InvariantViolation" ? (
                      e.message
                        .split(",")
                        .slice(0, 20)
                        .map((x, i) => <li key={i}>{x}</li>)
                    ) : (
                      <li>{JSON.stringify(e, null, 2)}</li>
                    )}
                  </ul>
                ),
                (droppedData) => children(droppedData)
              )
            )}
            <div
              style={{
                marginTop: "64px",
                background: isDragActive ? "#EBEBEB" : "#F5F5F5",
                borderRadius: "4px",
                border: "1px solid #DDDDDD",
                color: "#0075D1",
              }}
            >
              <div
                {...getRootProps()}
                style={{ position: "relative", padding: "0.5em" }}
              >
                <input {...getInputProps()} />
                <p>
                  {isDragActive ? "Drop file here …" : "Upload new .json file"}
                </p>
              </div>
            </div>
          </>
        );
      }
    )
  );
}
