import { ConfigContext, useConfig } from "config";
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { E, pipe } from "../../prelude";
import {
  splitCountriesAndRegions,
  ExplorerContext,
  explorerDefaultState,
  explorerAPI,
  RiseDataDecoder,
} from "../../domain";
import { io } from "../../prelude";

export const Data = RiseDataDecoder(["country"], {});
export type Data = io.TypeOf<typeof Data>;

type CountriesAndRegions = ReturnType<typeof splitCountriesAndRegions>;

export const useCountriesAndRegions = (
  env: ConfigContext,
  {
    onSuccess,
    onError,
  }: {
    onSuccess: (d: CountriesAndRegions) => void;
    onError: (e: Error) => void;
  }
) => {
  useEffect(() => {
    const fetchData = async () => {
      const { countries } = await explorerAPI.query({ countries: true });
      const res = Data.decode(countries.map((c) => ({ country: c })));
      return pipe(
        res,
        E.fold(
          () => {
            onError(new Error(`Invalid Data`));
          },
          (c) => {
            const data = splitCountriesAndRegions({
              input: c,
              regions: env.regions,
              countries: env.countries,
            });

            onSuccess(data);
          }
        )
      );
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env.countries, env.regions]);
};
const useExplorerImmer = () => {
  const immerInstance = useImmer<ExplorerContext>(explorerDefaultState);
  return immerInstance;
};

export const ExplorerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const immerInstance = useExplorerImmer();
  const [, mutate] = immerInstance;
  const env = useConfig();
  useCountriesAndRegions(env, {
    onSuccess: (data) => {
      mutate((draft) => {
        const { regions, countries } = data;
        draft.controls.countries = [...regions, ...countries];
        draft.status = "data";
      });
    },
    onError: () => {
      mutate((draft) => {
        draft.status = "error";
        draft.errorMessage = "Invalid Data";
        draft.controls.countries = [];
      });
    },
  });

  return (
    <ExplorerContext.Provider value={{ immer: immerInstance }}>
      {children}
    </ExplorerContext.Provider>
  );
};
