import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "TRAJECTORIES_EXPLORER_CURRENT",
  title: null,
  caption: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_EXPLORER_CURRENT.caption",
    defaults: `Descriptive learning trajectories showing the percentage of children (ages 7-14) who have mastered foundational skills typically at the Grade 2-3 level. This is below the minimum proficiency level expected by SDG 4.1.1. Trajectories by grade include all children for whom this was the highest grade attended (whether they were in that grade at the time of the survey or attended that grade in the past and then dropped out). Trajectories by age include all children (whether they were in-school at the time of the survey, had dropped out, or never attended school).    `,
  },
  source: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_EXPLORER_CURRENT.source",
    Source: `Analysis by RISE based on UNICEF MICS6 data.`,
  },
};
