
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Chancengerechtigkeit";
export const lead = "Auf dem Weg zu Geschlechterparität in der Bildung wurden weltweit Fortschritte erzielt, doch zwischen Stadt und Land sowie zwischen Arm und Reich bestehen nach wie vor enorme Unterschiede.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const PageLink = makeShortcode("PageLink");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Der Wunsch, niemanden zurückzulassen, ist in allen Zielen für nachhaltige Entwicklung (SDGs) enthalten und kommt im fünften Unterziel von SDG 4 explizit zum Ausdruck. Dieser Fokus sorgt dafür, dass politische Entscheidungsträger der Hilfe für diejenigen, die am weitesten zurückliegen, Priorität verleihen.`}</p>
    </blockquote>
    <p>{`Um überprüfen zu können, ob Bildungsunterschiede abgebaut werden, benötigen wir Daten, die `}<strong parentName="p">{`nach Einkommen, Geschlechtszugehörigkeit, Alter, Rasse, Ethnizität, Migrationsstatus, Behinderung, geografischer Lage und anderen Merkmalen`}</strong>{` aufgeschlüsselt und relevant für nationale Umfelder sind – Angaben, die Haushaltsbefragungen bieten.`}</p>
    <p>{`Seit 2010 arbeitet die Datenbank `}<a parentName="p" {...{
        "href": "https://www.education-inequalities.org/"
      }}>{`World Inequality Database on Education`}</a>{` mit Haushaltsbefragungen um aufzuzeigen, wie groß die Unterschiede in der Bildung in den verschiedenen Ländern sind. Dieser Artikel fasst einige der wesentlichen Fakten und Trends im Bereich Bildungsungleichheiten zusammen, während zusätzliche Aspekte in dem Artikel zu `}<PageLink name="finance" mdxType="PageLink">{`Finanzen`}</PageLink>{` angesprochen werden.`}</p>
    <h2 {...{
      "id": "geschlechterparität"
    }}>{`Geschlechterparität`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`Die Abbildung unten untersucht Bildungsunterschiede zwischen Gruppen, zum Beispiel zwischen Mädchen und Jungen oder den ärmsten und den reichsten Kindern. Sie misst die Bildungsungleichheit mit einem `}<Tooltip title="Paritätsindex" content="Wenn bei einem Paritätsindex der Wert gegen 0 geht, zeigt dies an, dass die benachteiligte Gruppe sehr weit abgeschlagen ist; Werte gegen 1 bedeuten, dass Parität besteht; Werte gegen 2 deuten darauf hin, dass die bevorteilte Gruppe am weitesten hinten liegt. Ein Paritätsindex von 0,5 könnte zum Beispiel anzeigen, dass 30% der Mädchen, aber 60% der Jungen eingeschult sind. Ein Paritätsindex von 1,5 würde bedeuten, dass 30% der Jungen, aber 60% der Mädchen an Bildung beteiligt sind." mdxType="Tooltip">{`Paritätsindex`}</Tooltip>{`.`}</p>
      <p>{`Kaum beachtet vollzog sich in der vergangenen Generation eine kontinuierliche Entwicklung hin zu `}<Tooltip title="Geschlechterparität" content="Wo für Jungen und Mädchen die gleichen Zahlen vorliegen" mdxType="Tooltip">{`Geschlechterparität`}</Tooltip>{`. Mädchen und junge Frauen sind in vielen – größtenteils reicheren – Teilen der Welt Jungen und jungen Männern an Schulen und Hochschulen zahlenmäßig überlegen.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`Die Linien, die unterhalb des `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`grau markierten Bereichs`}</HighlightByIndex>{` verlaufen, zeigen an, dass hier mehr Jungen oder junge Männer als Mädchen beteiligt sind.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`In `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Subsahara-Afrika`}</HighlightByIndex>{` kommen in der Hochschulbildung auf fünf junge Männer immer noch weniger als vier junge Frauen.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <HighlightByIndex index="2" mdxType="HighlightByIndex">Zentral- und Südasien</HighlightByIndex>, wo Mädchen 1990 extrem weit zurücklagen, hat vor allem dank dramatischer Fortschritte in Indien Geschlechterparität in der unteren und oberen Sekundarstufe und eine höhere Beteiligung an tertiärer Bildung von jungen Frauen erreicht. Damit hat die Region bei Fortschritten zur Erreichung von Geschlechterparität <HighlightByIndex index="0" mdxType="HighlightByIndex">Subsahara-Afrika</HighlightByIndex> überholt.
        </ScrollySection>
      </ScrollyArea>
      <p>{`Weltweit sind seit 2000 große Fortschritte erreicht worden. Der Blick auf Länderebene zeigt jedoch nach wie vor bestehende Differenzen: Nur zwei Drittel der Länder haben bei Einschulungsraten in der Grundstufe, die Hälfte in der unteren und ein Viertel in der oberen Sekundarstufe Geschlechterparität erreicht.`}</p>
      <p>{`Der Anteil der Länder mit einer `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`starken Disparität auf Kosten von Mädchen`}</HighlightByIndex>{` hat sich seit 2000 in allen Bildungsstufen mindestens halbiert, liegt jedoch in der Primarstufe immer noch bei 4%, in der unteren Sekundarstufe bei 8% und in der oberen Sekundarstufe bei 14%.`}</p>
      <p>{`Beim Thema Geschlechtergerechtigkeit dürfen wir die Jungen nicht vergessen. Wenngleich es weniger üblich ist, dass in Ländern eine `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`starke Disparität auf Kosten von Jungen`}</HighlightByIndex>{` in der Primarstufe besteht, ist eine solche in der oberen Sekundarstufe und höheren Bildungsstufen weit mehr verbreitet, wobei der Trend entweder stagniert oder sich verschlechtert.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Diese Abbildung zeigt: Je größer und dunkler der blaue Teil des Balkens ist, desto mehr Länder mit `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`mehr Jungen als Mädchen, die keine Schule besuchen`}</HighlightByIndex>{`, gibt es. Je größer und dunkler der grüne Teil des Balkens ist, desto mehr Länder mit `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`mehr Mädchen als Jungen, die keine Schule besuchen`}</HighlightByIndex>{`, gibt es.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
In der Hochschulbildung verzeichnen beispielsweise über zwei Drittel der Länder <HighlightByIndex index="0" mdxType="HighlightByIndex">extreme Disparität auf Kosten von jungen Männern</HighlightByIndex> und diese Situation verschlechtert sich im Lauf der Zeit.
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "wohlstand"
      }}>{`Wohlstand`}</h2>
      <p>{`Insgesamt sind Bildungsunterschiede zwischen Mädchen und Jungen nicht so stark ausgeprägt wie zwischen ländlichen und städtischen Gebieten sowie zwischen Reichen und Armen, wie Haushaltsbefragungen zeigen.`}</p>
      <p>{`In Pakistan beispielsweise besuchen zwar 92% der `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Reichsten`}</HighlightByIndex>{`, jedoch nur 28% der `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Ärmsten`}</HighlightByIndex>{` die Grundschule. In Nigeria sieht es ähnlich aus: Hier gehen 97% der Reichsten und nur 27% der Ärmsten in die untere Sekundarstufe.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Die roten Punkte zeigen an, welcher Anteil der `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`ärmsten`}</HighlightByIndex>{` Kinder oder Jugendlichen Bildung im Vergleich zu den `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`reichsten`}</HighlightByIndex>{` genießt – hier in Blau angezeigt. Oben auf der Abbildung können Sie die Bildungsstufe von der Primarstufe zur unteren Sekundarstufe und höher wechseln.`}</p>
        </ScrollySection>
        <ScrollySection id="ALB" mdxType="ScrollySection">
          <p>{`In der Hochschulbildung sind die Unterschiede in Ländern mit mittlerem Einkommen am größten. So besuchten zum Beispiel in `}<strong parentName="p">{`Albanien`}</strong>{` 55% der Reichsten, aber weniger als 1% der Ärmsten eine Form der postsekundären Bildung.`}</p>
        </ScrollySection>
        <ScrollySection id="MNG" mdxType="ScrollySection">
          <p>{`In der `}<strong parentName="p">{`Mongolei`}</strong>{` lagen die entsprechenden Anteile bei 70% und 10%.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`Noch aufschlussreicher ist der Blick auf die Art und Weise, in der sich Unterschiede im Lauf des Bildungsweges von Kindern verändern.`}</p>
      <p>{`Die Betrachtung der `}<strong parentName="p">{`geschlechterspezifischen`}</strong>{` Unterschiede in `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Mali`}</HighlightByIndex>{` unten zeigt das Ausmaß, in dem Mädchen ihren Bildungsweg abbrechen, je höher die Bildungsstufe ist.`}</p>
      <p>{`Alternativ wird, wenn `}<strong parentName="p">{`Wohlstand`}</strong>{` als Kriterium ausgewählt wird, angezeigt, wie stark die Kluft im Lauf des Bildungsweges auf Kosten der Ärmsten wächst, von denen nur sehr wenige die obere Sekundarstufe besucht.`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Selten fällt ein Kind in nur eine Kategorie, weshalb es enorm wichtig ist, ebenfalls zu schauen, inwiefern individuelle Merkmale – wie die Geschlechtszugehörigkeit – mit anderen – zum Beispiel Wohlstand – interagieren, wie die nächste Abbildung zeigt.`}</p>
    <p>{`In Subsahara-Afrika wächst die Kluft zwischen den `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Ärmsten`}</HighlightByIndex>{` und den `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Reichsten`}</HighlightByIndex>{` mit steigendem Bildungsgrad: In der Hochschulbildung sind nur noch weniger als 45 der ärmsten jungen Frauen vertreten im Vergleich zu knapp 80 der reichsten pro 100 männlichen Studierenden.`}</p>
    <p>{`In Ost- und Südostasien führen die Punkte wiederum oberhalb der grauen Linie, da die geschlechterspezifischen Unterschiede hier auf Kosten der ärmsten jungen Männer gehen. In der Hochschulbildung liegt die Wahrscheinlichkeit, dass sie sich daran beteiligen, bei den reichsten Mädchen nur etwa ein Viertel höher als bei den entsprechenden jungen Männern, bei den ärmsten Mädchen ist sie jedoch zwei Drittel größer.`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "schlussbemerkung"
    }}>{`Schlussbemerkung`}</h2>
    <p>{`Zwischen den Ländern sowie zwischen Gruppen innerhalb von Ländern bestehen Bildungsungleichheiten inakzeptablen Ausmaßes. Hochwertige aufgeschlüsselte Daten sind eine Grundvoraussetzung für die Erarbeitung angemessener Strategien, um diese Ungleichheiten zu reduzieren und letztendlich in unserem Bestreben, SDG 4 zu erreichen, niemanden zurückzulassen.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`Worldwide Inequalities Database on Education (WIDE)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Still left behind – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/2019genderreport"
          }}>{`Building Bridges for Gender Equality – GEM Gender Report 2019`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;