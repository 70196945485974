import { useCallback, useEffect, useRef, useState } from "react";

const useQueryState = <T,>() => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<unknown>();
  const [fetchStatus, setFetchStatus] = useState<
    "idle" | "fetching" | "loaded"
  >("idle");
  return {
    data,
    setData,
    error,
    setError,
    fetchStatus,
    setFetchStatus,
  };
};
const useEvent = <TCallback extends (...args: $IntentionalAny[]) => void>(
  cb: TCallback
) => {
  const ref = useRef(cb);
  ref.current = cb;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback<TCallback>(
    ((...args) => {
      ref.current(...args);
    }) as TCallback,
    []
  );
};
export const useFetch = <TData,>({
  fetch,
  pause,
  onFetch,
  onSuccess,
  onError,
}: {
  fetch: () => Promise<TData>;
  pause?: boolean;
  onFetch: () => void;
  onSuccess: (d: TData) => void;
  onError: (e: unknown) => void;
}) => {
  const { data, setData, error, setError, fetchStatus, setFetchStatus } =
    useQueryState<TData>();

  const onSuccessEv = useEvent(onSuccess);
  const onErrorEv = useEvent(onError);
  const onFetchEv = useEvent(onFetch);

  useEffect(() => {
    const fetchData = async () => {
      try {
        onFetchEv?.();
        setFetchStatus("fetching");
        const res = await fetch();
        setError(undefined);
        setData(res);
        onSuccessEv?.(res);
      } catch (e) {
        setError(e);
        onErrorEv?.(e);
      } finally {
        setFetchStatus("idle");
      }
    };

    if (!pause) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause, fetch]);
  return { data, error, fetchStatus };
};
