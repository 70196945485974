import css from "@emotion/css";
import { t } from "@lingui/macro";
import { MDXProvider } from "@mdx-js/react";
import { getThemeLabel } from "components/article-switcher";
import { mkStyles, useQueryParamsWithDefault, useTheme } from "hooks";
import { useI18n } from "locales";
import React from "react";
import readingTime, { ReadTimeResults } from "reading-time";
import {
  RAliasTheme,
  REnv,
  findArticleThemeAlias,
  getFocusMetadata,
} from "routes";
import * as M from "../../materials";
import { baseElements } from "../../materials";
import { PageLink as PageLink_ } from "../links";

const useStyles = mkStyles(({ palette }) => {
  return {
    root: css`
      grid-column: ${M.layoutAreas.main};
      hr {
        margin: ${M.spacing.base8(2)} 0;
        border: 0;
        border-top: 1px solid ${palette.grayscale[4]};
      }

      a {
        color: ${palette.brand.marine.main};
        font-weight: 500;
        &:hover: {
          color: ${palette.brand.marine.light};
        }
      }
    `,
    header: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: ${M.spacing.base8(1)};
      min-height: ${M.spacing.base8(4)};

      justify-content: space-between;
      ${M.fontTable}
      color: ${M.lightText};
    `,
    circle: css`
      height: 3px;
      width: 3px;
      background-color: ${M.blackText};
      border-radius: 100%;
    `,
    link: css`
      color: ${palette.brand.marine.main};
      font-weight: 500;
      &:hover: {
        color: ${palette.brand.marine.light};
      }
    `,
    badge: css`
      display: flex;
      min-width: ${M.spacing.base8(12)};
      border-radius: ${M.spacing.base8(2)};
      color: ${palette.text.white};
      height: ${M.spacing.base8(3)};
      justify-content: center;
      padding: 0 ${M.spacing.base8(1)};
      transition: height 0.15s;
      align-items: center;
      white-space: nowrap;

      &:hover {
        height: ${M.spacing.base8(4)};
      }
    `,
  };
});

export function FocusDetails({
  url,
  children,
}: $PropsWithChildren & {
  url: string;
}) {
  const i18n = useI18n();
  const { palette } = useTheme();
  const styles = useStyles();
  const focusRef = React.useRef<HTMLDivElement>(null);
  const [readingStats, setReadingStats] = React.useState<ReadTimeResults>();
  const { alias, locale } = useQueryParamsWithDefault(REnv);

  const themeStyles = useTheme();
  const BE = baseElements(themeStyles);
  const { year, article } = getFocusMetadata(alias, locale);

  const theme = findArticleThemeAlias(article);

  React.useEffect(() => {
    const text = focusRef.current?.textContent;
    if (text) {
      setReadingStats(readingTime(text));
    }
  }, []);

  return (
    <div id="focus-post" css={styles.root} ref={focusRef}>
      <div css={styles.header}>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${M.spacing.base8(1)};
          `}
        >
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {" "}
            {`${year} ${i18n._(t`GEM Report`)}`}{" "}
          </div>
          <div css={styles.circle} />
          {readingStats && (
            <>
              <div>{`${Math.ceil(readingStats.minutes)} ${i18n._(
                t`min read`
              )}`}</div>
              <div css={styles.circle} />
            </>
          )}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontWeight: "bold",
            }}
          >
            {i18n._(t`View in report`)}
          </a>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${M.spacing.base8(1)};
          `}
        >
          {i18n._(t`Published in`)}
          <PageLink_ name={theme} query={{}}>
            <a>
              <mark
                css={[
                  styles.badge,
                  css`
                    background-color: ${palette.theme[theme as RAliasTheme]
                      .palette[7]};
                  `,
                ]}
              >
                {getThemeLabel(theme)}
              </mark>
            </a>
          </PageLink_>
        </div>
      </div>
      <hr />
      <div
        css={[
          css`
            padding: ${M.spacing.base8(1)} 0;
            p {
              margin-bottom: ${M.spacing.base8(4)};
              color: ${palette.grayscale[8]};
            }

            h1 {
              ${M.fontTitle};
              margin-bottom: ${M.spacing.base8(4)};
              color: ${palette.brand.blue.dark};
            }
          `,
        ]}
      >
        <MDXProvider components={BE}>{children}</MDXProvider>
      </div>
    </div>
  );
}

export const FocusTitle = ({ children }: $PropsWithChildren) => {
  const { palette } = useTheme();
  return (
    <h1
      css={css`
        ${M.fontTitle};
        color: ${palette.brand.blue.dark};
        padding: ${M.spacing.base8(1)} 0 ${M.spacing.base8(3)} 0;
      `}
    >
      {children}
    </h1>
  );
};
