import { t, Trans } from "@lingui/macro";
import { scaleOrdinal } from "@visx/scale";
import { format } from "d3-format";
import { motion } from "framer-motion";
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { BandItem } from "charts/lib/Trajectories/types";
import { TrajectoriesChart } from "charts/TrajectoriesChart";
import { useConfig } from "config";
import { ExplorerContext, View } from "domain/explorer";
import { everything, FACET, SKILL } from "lib/explorer-gql/generated";
import { useI18n } from "locales";
import { withFigureIOExplorer } from "../components/figure";
import { metadata } from "../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER_CURRENT";
import {
  COUNTRIES_EXCLUDED_DISPARITY_SIM,
  countryControlPlaceholder,
  countryControlTitle,
  CountryId,
  countryIdIso,
  disparitiesGroupsLookup,
  DisparityCategory,
  DisparityGroup,
  disparityGroupsControlItems,
  DISPARITY_CATEGORIES,
  DISPARITY_GROUPS,
  explorerAPI,
  forceUnwrapCountryOrRegion,
  getRegionOrCountryName,
  goalRule,
  GroupLabelLookup,
  horizontalAxisControlItems,
  makeUseControl,
  RegionId,
  RenderMode,
  RiseDataDecoder,
  useFigureState,
  verticalAxisControlItems,
  verticalAxisControlLabel,
  viewControlItems,
  xAxisSubtitleLookup,
  xAxisTitleLookup,
  yAxisTitleLookup,
} from "../domain";
import * as M from "../materials";
import { E, io, O, pipe } from "../prelude";
import { useFetch } from "../hooks/useFetch";
import Carousel from "components/carousel";
import {
  ToggleInterpretationButton,
  EmptyInterpretation,
  CarouselFooter,
  Highlight,
  i18nLookup,
  InterpretationToken,
  formatPerc,
  InterpretationSlideProps,
  makeDisparityGroupLabels,
  SlideTitle,
  Slide,
  formatPercentagePoints,
} from "components/interpretation";
import { Popup } from "components/popup";
import { Button } from "components/button";
import { css } from "@emotion/core";
export * from "../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER_CURRENT";

export const Data = RiseDataDecoder(
  ["id", "value", "country", "year", "group", "facet"],
  {}
);
export type Data = io.TypeOf<typeof Data>;

enum Controls {
  "2-toggle-interpretation" = "2-toggle-interpretation",
  "0-vertical-axis" = "0-vertical-axis",
  "0-view" = "0-view",
  "1-country" = "1-country",
  "1-horizontal-axis" = "1-horizontal-axis",
  "2-disparities" = "2-disparities",
  "1-countries" = "1-countries",
}

const disparitiesColorScale = scaleOrdinal({
  domain: DISPARITY_GROUPS,
  range: [
    M.grayscalePalette[5],
    M.q08Palette[7], // dark teal
    M.q08Palette[3], // light teal
    M.q03Palette[7], // dark pink
    M.q03Palette[3], // light pink
    M.q02Palette[7], // dark orange
    M.q02Palette[3], // light orange
  ],
});

const bandColorScale = scaleOrdinal({
  domain: DISPARITY_CATEGORIES,
  range: [M.q08Palette[5], M.q03Palette[5], M.q02Palette[5]],
});

const filterTrajectoriesData = (x: Data) =>
  x.filter(
    (d: Data[number]) =>
      d.facet === "AGE" || (d.facet === "GRADE" && d.year >= 1 && d.year <= 10)
  ) as $FixMe;

const useControl = makeUseControl({
  "2-toggle-interpretation": {
    control: "MultiCheckbox",
    defaultValue: [],
  },
  "0-view": {
    defaultValue: "countries" as View,
    control: "Radio",
  },
  "0-vertical-axis": {
    defaultValue: "LIT" as SKILL,
    control: "Radio",
  },
  "1-horizontal-axis": {
    control: "Radio",
    defaultValue: "GRADE" as FACET,
  },
  "1-country": {
    control: "SingleSelect",
    defaultValue: "",
  },
  "1-countries": {
    control: "MultiSelect",
    defaultValue: [] as string[],
  },
  "2-disparities": {
    control: "MultiCheckbox",
    defaultValue: [] as DisparityCategory[],
  },
});

const InterpretationCountries = ({
  dataset,
  getCountryName,
  requestClose,
  colorScale,
  chartActions,
}: InterpretationSlideProps & {
  colorScale: (c: string) => string;
}) => {
  const countries = useControl("1-countries");
  const skill = useControl("0-vertical-axis");
  const horizontalAxis = useControl("1-horizontal-axis");
  const countryControl = countryIdIso.wrap(countries[0]);
  const i18n = useI18n();

  const findPoint = useCallback(
    (d: Data[number]) =>
      horizontalAxis === "AGE" ? d.year === 9 : d.year === 3,
    [horizontalAxis]
  );

  const grade3Values = useMemo(() => {
    return Object.fromEntries(
      dataset.filter(findPoint).map((d) => [d.country, d.value || 0])
    ) as Record<string, Exclude<Data[number]["value"], null>>;
  }, [dataset, findPoint]);

  const {
    age9CountryValue,
    age9CountryFailingValue,
    skillName,
    country,
    level,
  } = useMemo(() => {
    const value = grade3Values[countries[0]];
    const countryColor = colorScale(countries[0]);
    return {
      age9CountryValue: (
        <InterpretationToken
          chartActions={chartActions}
          color={countryColor}
          highlightPoint={dataset.find(
            (p) =>
              findPoint(p) &&
              p.country === (countries[0] as unknown as CountryId)
          )}
          highlightSeries={countries[0]}
        >
          {formatPerc(value)}
        </InterpretationToken>
      ),
      age9CountryFailingValue: <Highlight>{formatPerc(1 - value)}</Highlight>,
      country: (
        <InterpretationToken
          chartActions={chartActions}
          color={colorScale(countries[0])}
          highlightSeries={countries[0]}
        >
          {getCountryName(countryControl)}
        </InterpretationToken>
      ),
      skillName: (
        <Highlight>
          {skill === "LIT"
            ? i18n._(i18nLookup["skillName.LIT"])
            : i18n._(i18nLookup["skillName.NUM"])}
        </Highlight>
      ),
      skillVerb: (
        <Highlight>
          {skill === "LIT"
            ? i18n._(i18nLookup["skillVerb.LIT"])
            : i18n._(i18nLookup["skillVerb.NUM"])}
        </Highlight>
      ),
      level: (
        <Highlight>{horizontalAxis === "AGE" ? "Age 9" : "Grade 3"}</Highlight>
      ),
    } as const;
  }, [
    grade3Values,
    countries,
    colorScale,
    dataset,
    findPoint,
    chartActions,
    getCountryName,
    countryControl,
    skill,
    i18n,
    horizontalAxis,
  ]);

  return (
    <Carousel
      FooterComponent={(footerProps) => (
        <CarouselFooter {...footerProps} onClose={requestClose} />
      )}
    >
      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.common.intepretation-title"
            )`Interpretation`
          )}
        </SlideTitle>
        <Trans id="fig.learning.trajectories.interpretation.current.trajectories-content">
          This trajectory shows the pace at which children learn foundational
          {skillName} skills in {country}. SDG 4.1.1 expects all children to
          have acquired foundational {skillName} skills by Grade 3 (which
          typically corresponds to age 9). Currently, about {age9CountryValue}{" "}
          of children acquire foundational {skillName} skills by {level},
          meaning that {age9CountryFailingValue} do not meet the SDG goal. A
          high-performing country's trajectory will slope steeply upward in the
          early years of primary school. A flat or moderately upward sloping
          trajectory indicates a slow pace of learning, and means many children
          do not achieve the foundational {skillName} skills they need to
          succeed in later grades.
        </Trans>
      </Slide>
    </Carousel>
  );
};

const ActivateSlide = ({ onActivate }: { onActivate: () => void }) => {
  return (
    <>
      <Trans id="fig.learning.trajectories.interpretation.simulation.disparity-deactivated">
        Click the button below to activate this simulation, which is currently
        deactivated.
      </Trans>
      <Button
        css={css`
          margin: 0.5rem 0;
        `}
        rounded
        inverted
        onClick={() => onActivate()}
      >
        Activate
      </Button>
    </>
  );
};

const InterpretationDisparities = ({
  dataset,
  getCountryName,
  requestClose,
  colorScale,
  chartActions,
}: InterpretationSlideProps & {
  colorScale: (c: string) => string;
}) => {
  const countryRaw = useControl("1-country");

  const skill = useControl("0-vertical-axis");

  const disparities = useControl("2-disparities");
  const [, actions] = useFigureState();
  const handleActivate = (disparityCategory: DisparityCategory) => {
    actions.toggleMultiCheckboxItem("2-disparities", disparityCategory);
  };

  const horizontalAxis = useControl("1-horizontal-axis");

  const countryControl = countryIdIso.wrap(countryRaw);

  const i18n = useI18n();

  const findPoint = useCallback(
    (d: Data[number]) =>
      horizontalAxis === "AGE" ? d.year === 9 : d.year === 3,
    [horizontalAxis]
  );

  type Point = ComponentProps<typeof InterpretationToken>["highlightPoint"];
  const highlightPoints = useMemo(() => {
    return Object.fromEntries(
      Object.entries(disparitiesGroupsLookup).map(([category, groupValues]) => {
        const indexes = Object.fromEntries(groupValues.map((gv, i) => [gv, i]));
        const sorter = (a: Point, b: Point) =>
          indexes[a?.group ?? 10] < indexes[b?.group ?? 10]
            ? -1
            : indexes[a?.group ?? 10] > indexes[b?.group ?? 10]
            ? 1
            : 0;
        return [
          category as DisparityCategory,
          dataset
            .filter((d) => findPoint(d) && groupValues.includes(d.group))
            .sort(sorter),
        ];
      })
    ) as Record<DisparityCategory, Point[]>;
  }, [dataset, findPoint]);

  const highlightPointValues = useMemo(() => {
    return Object.fromEntries(
      dataset.filter(findPoint).map((d) => [d.group, d.value || 0])
    ) as Record<string, Exclude<Data[number]["value"], null>>;
  }, [dataset, findPoint]);

  const disparityGroupLabels = useMemo(
    () => makeDisparityGroupLabels(i18n),
    [i18n]
  );

  const { groups, differences, values, skillName, country, level } =
    useMemo(() => {
      const makeDisparityToken = (
        disparity: DisparityCategory,
        index: 0 | 1
      ) => {
        return (
          <InterpretationToken
            color={colorScale(disparitiesGroupsLookup[disparity][index])}
            chartActions={chartActions}
            highlightPoint={highlightPoints[disparity][0]}
          >
            {disparityGroupLabels[disparity][index]}
          </InterpretationToken>
        );
      };

      const makeValueToken = (disparity: DisparityCategory, index: 0 | 1) => {
        const disparityKeys = disparitiesGroupsLookup[disparity];

        return (
          <InterpretationToken
            chartActions={chartActions}
            highlightPoint={highlightPoints[disparity][index]}
            highlightSeries={disparityKeys[index]}
            color={colorScale(disparityKeys[index])}
          >
            {formatPerc(Math.abs(highlightPointValues[disparityKeys[index]]))}
          </InterpretationToken>
        );
      };

      const makeDifferenceToken = (disparity: DisparityCategory) => {
        const disparityKeys = disparitiesGroupsLookup[disparity];

        return (
          <InterpretationToken
            chartActions={chartActions}
            highlightPoint={highlightPoints[disparity][0]}
          >
            {formatPercentagePoints(
              Math.abs(
                highlightPointValues[disparityKeys[0]] -
                  highlightPointValues[disparityKeys[1]]
              ),
              i18n
            )}
          </InterpretationToken>
        );
      };

      const makeStillFailingValueToken = (disparity: DisparityCategory) => {
        const disparityKeys = disparitiesGroupsLookup[disparity];

        return (
          <InterpretationToken
            chartActions={chartActions}
            highlightPoint={highlightPoints[disparity][0]}
          >
            {formatPerc(
              1 -
                Math.max(
                  highlightPointValues[disparityKeys[0]],
                  highlightPointValues[disparityKeys[1]]
                )
            )}
          </InterpretationToken>
        );
      };

      const groups = {
        WEALTH: [
          makeDisparityToken("WEALTH", 0),
          makeDisparityToken("WEALTH", 1),
        ],
        GENDER: [
          makeDisparityToken("GENDER", 0),
          makeDisparityToken("GENDER", 1),
        ],
        GEOGRAPHY: [
          makeDisparityToken("GEOGRAPHY", 0),
          makeDisparityToken("GEOGRAPHY", 1),
        ],
      } as const;

      const differences = {
        WEALTH: makeDifferenceToken("WEALTH"),
        GENDER: makeDifferenceToken("GENDER"),
        GEOGRAPHY: makeDifferenceToken("GEOGRAPHY"),
      } as const;

      const values = {
        WEALTH: [makeValueToken("WEALTH", 0), makeValueToken("WEALTH", 1)],
        GENDER: [makeValueToken("GENDER", 0), makeValueToken("GENDER", 1)],
        GEOGRAPHY: [
          makeValueToken("GEOGRAPHY", 0),
          makeValueToken("GEOGRAPHY", 1),
        ],
      } as const;

      const stillFailing = {
        WEALTH: makeStillFailingValueToken("WEALTH"),
        GENDER: makeStillFailingValueToken("GENDER"),
        GEOGRAPHY: makeStillFailingValueToken("GEOGRAPHY"),
      } as const;

      return {
        groups,
        differences,
        values,
        stillFailing,
        country: (
          <InterpretationToken
            chartActions={chartActions}
            highlightSeries="all"
          >
            {getCountryName(countryControl)}
          </InterpretationToken>
        ),
        skillName: (
          <Highlight>
            {skill === "LIT"
              ? i18n._(i18nLookup["skillName.LIT"])
              : i18n._(i18nLookup["skillName.NUM"])}
          </Highlight>
        ),
        skillVerb: (
          <Highlight>
            {skill === "LIT"
              ? i18n._(i18nLookup["skillVerb.LIT"])
              : i18n._(i18nLookup["skillVerb.NUM"])}
          </Highlight>
        ),
        level: <Highlight>Grade 3 (typically age 9)</Highlight>,
      } as const;
    }, [
      highlightPointValues,
      colorScale,
      chartActions,
      getCountryName,
      countryControl,
      skill,
      i18n,
      disparityGroupLabels,
      highlightPoints,
    ]);

  return (
    <Carousel
      FooterComponent={(footerProps) => (
        <CarouselFooter {...footerProps} onClose={requestClose} />
      )}
    >
      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.current.disparities.interpretation-title"
            )`Interpretation`
          )}
        </SlideTitle>
        <Trans id="fig.learning.trajectories.interpretation.current.disparities.interpretation-content">
          This graph shows the average learning trajectory for all children in
          {country}, and the learning trajectories for specific groups in{" "}
          {country}.
        </Trans>
      </Slide>
      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.current.disparities.WEALTH.title"
            )`Rich / Poor`
          )}
        </SlideTitle>
        {disparities.includes("WEALTH") ? (
          <Trans id="fig.learning.trajectories.interpretation.current.disparities.WEALTH.content">
            At {level}, when SDG 4.1.1 expects all children to have gained
            foundational {skillName} skills, {values.WEALTH[0]} of{" "}
            {groups.WEALTH[0]} and {values.WEALTH[1]} of
            {groups.WEALTH[1]} have gained these skills in {country}, meaning
            there is a relative difference in learning of {differences.WEALTH}.
          </Trans>
        ) : (
          <ActivateSlide onActivate={() => handleActivate("GENDER")} />
        )}
      </Slide>
      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.disparities.GENDER.title"
            )`Boys / Girls`
          )}
        </SlideTitle>
        {disparities.includes("GENDER") ? (
          <Trans id="fig.learning.trajectories.interpretation.current.disparities.GENDER.content">
            At {level}, when SDG 4.1.1 expects all children to have gained
            foundational {skillName} skills, {values.GENDER[0]} of{" "}
            {groups.GENDER[0]} and {values.GENDER[1]} of
            {groups.GENDER[1]} have gained these skills in {country}, meaning
            there is a relative difference in learning of {differences.GENDER}.
          </Trans>
        ) : (
          <ActivateSlide onActivate={() => handleActivate("GENDER")} />
        )}
      </Slide>

      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.current.disparities.GEOGRAPHY.title"
            )`Urban / Rural`
          )}
        </SlideTitle>
        {disparities.includes("GEOGRAPHY") ? (
          <Trans id="fig.learning.trajectories.interpretation.current.disparities.GEOGRAPHY.content">
            At {level}, when SDG 4.1.1 expects all children to have gained
            foundational {skillName} skills, {values.GEOGRAPHY[0]} of{" "}
            {groups.GEOGRAPHY[0]} and {values.GEOGRAPHY[1]} of
            {groups.GEOGRAPHY[1]} have gained these skills in {country}, meaning
            there is a relative difference in learning of{" "}
            {differences.GEOGRAPHY}.
          </Trans>
        ) : (
          <ActivateSlide onActivate={() => handleActivate("GEOGRAPHY")} />
        )}
      </Slide>
    </Carousel>
  );
};

export const Chart = ({ renderMode }: { renderMode: RenderMode }) => {
  const [chartData, setChartData] = useState([]);
  const ctx = React.useContext(ExplorerContext);
  const [state, mutate] = ctx.immer;
  const [, actions] = useFigureState();
  const i18n = useI18n();
  const env = useConfig();

  // Controls selections
  const interpretation = useControl("2-toggle-interpretation");
  const interpretationToggled = interpretation.length > 0;
  const selectedCountries = useControl("1-countries");
  const view = useControl("0-view");
  const facet = useControl("1-horizontal-axis");
  const skill = useControl("0-vertical-axis");
  const groups = useControl("2-disparities");
  const country = useControl("1-country");
  const toggleInterpretationRef = useRef<HTMLButtonElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const countries = useMemo(() => {
    switch (view) {
      case "countries":
        return selectedCountries?.length ? selectedCountries : [];
      case "disparities":
        return country ? [country] : [];
      default:
        const _check: never = view;
        return _check;
    }
  }, [view, selectedCountries, country]);

  const selectedGroupsWithAll = useMemo(
    () => ["all", ...groups.flatMap((group) => disparitiesGroupsLookup[group])],
    [groups]
  );

  const getCountryName = React.useCallback(
    (country: CountryId | RegionId) =>
      getRegionOrCountryName({
        input: {
          country: country,
        },
        regions: env.regions,
        countries: env.countries,
      }),
    [env]
  );

  const getCountryInterpretationName = useCallback(
    (country: CountryId | RegionId) => {
      return country === ("lmic" as unknown as RegionId)
        ? i18n._(i18nLookup.averageLowLowerCountries)
        : getCountryName(country);
    },
    [getCountryName, i18n]
  );

  const selectedCountryName = getCountryName(countryIdIso.wrap(country));

  useEffect(() => {
    setChartData([]);
  }, [view]);

  const fetcher = useCallback(async () => {
    return await fetchObservations(
      countries,
      selectedGroupsWithAll,
      facet,
      skill
    );
  }, [countries, facet, selectedGroupsWithAll, skill]);

  useFetch({
    fetch: fetcher,
    pause: false,
    onFetch: () => {
      mutate((draft) => {
        draft.status = "fetching";
      });
    },
    onSuccess: (x) => {
      mutate((draft) => {
        draft.status = "data";
        const trajectories = filterTrajectoriesData(x);
        setChartData(trajectories);
      });
    },
    onError: () => {
      mutate((draft) => {
        if (renderMode !== "static") {
          draft.status = "error";
          draft.errorMessage = "Could not load data";
        }
      });
    },
  });

  useEffect(() => {
    actions.setSelectionControl(
      Controls["2-toggle-interpretation"],
      {
        type: "MultiCheckbox",
        label: "",
        position: "TOOLBAR",
        selected: O.none,
      },
      [
        {
          value: "toggled",
          Component: (props: $IntentionalAny) => {
            const view = useControl("0-view");
            const country = useControl("1-country");
            return (
              <ToggleInterpretationButton
                animation={
                  (view === "countries" && countries.length > 0) ||
                  (view === "disparities" && country !== "")
                }
                ref={toggleInterpretationRef}
                {...props}
              />
            );
          },
          label: i18n._(
            t(
              "fig.learning.trajectories.explorer.interpretation.toggle"
            )`Interpretation`
          ),
        },
      ]
    );
  }, [view, country, actions, i18n, countries.length]);

  // Update title
  useEffect(() => {
    if (view === "countries") {
      actions.setTitle(
        i18n._(
          t(
            "fig.learning.trajectories.explorer.trajectories.countries.title"
          )`Learning trajectories of ${countries
            .map((country) => getCountryName(countryIdIso.wrap(country)))
            .join(", ")}`
        )
      );
    } else {
      actions.setTitle(
        i18n._(
          t(
            "fig.learning.trajectories.explorer.trajectories.disparities.title"
          )`Learning trajectories in ${selectedCountryName}`
        )
      );
    }
  }, [actions, i18n, getCountryName, countries, selectedCountryName, view]);

  // Set controls
  useEffect(() => {
    actions.setSelectionControl(
      Controls["0-vertical-axis"],
      {
        type: "Radio",
        title: i18n._(verticalAxisControlLabel),
        selected: O.some("LIT"),
        position: "RIGHT",
      },
      verticalAxisControlItems(i18n)
    );
    actions.setSelectionControl(
      Controls["1-horizontal-axis"],
      {
        type: "Radio",
        title: i18n._(
          t(
            "fig.learning.trajectories.explorer.controls.horizontalAxis.title"
          )`Horizontal Axis`
        ),
        selected: O.some("GRADE"),
        position: "RIGHT",
      },
      horizontalAxisControlItems(i18n)
    );
    actions.setSelectionControl(
      Controls["0-view"],
      {
        type: "Radio",
        title: i18n._(
          t(
            "fig.learning.trajectories.explorer.controls.trajectories.view.title"
          )`View trajectories for`
        ),
        selected: O.some("countries"),
        position: "LEFT",
      },
      viewControlItems(i18n)
    );

    if (view === "countries") {
      actions.clearControl(Controls["1-country"]);
      actions.clearControl(Controls["2-disparities"]);

      actions.setSelectionControl(
        Controls["1-countries"],
        {
          type: "MultiSelect",
          title: i18n._(
            t(
              "fig.learning.trajectories.explorer.controls.countries.title"
            )`Countries`
          ),
          selected: country ? O.some([country]) : O.none,
          maxItems: 5,
          label: i18n._(
            t(
              "fig.learning.trajectories.explorer.controls.countries.placeholder"
            )`Select countries`
          ),
          position: "LEFT",
        },
        state.controls.countries
      );
    } else {
      actions.clearControl("1-countries");

      actions.setSelectionControl(
        Controls["1-country"],
        {
          type: "SingleSelect",
          title: i18n._(countryControlTitle),
          selected:
            selectedCountries.length > 0
              ? O.some(selectedCountries[0])
              : O.none,
          position: "LEFT",
          label: i18n._(countryControlPlaceholder),
        },
        state.controls.countries.filter(
          (country) =>
            !(
              view === "disparities" &&
              COUNTRIES_EXCLUDED_DISPARITY_SIM.includes(country.value)
            )
        )
      );
      actions.setSelectionControl(
        Controls["2-disparities"],
        {
          type: "MultiCheckbox",
          title: i18n._(
            t(
              "fig.learning.trajectories.explorer.trajectories.controls.groups.title"
            )`Type of inequality`
          ),
          label: i18n._(
            t(
              "fig.learning.trajectories.explorer.trajectories.controls.groups.label"
            )`Disparities`
          ),
          selected: O.some(["WEALTH"]),
          position: "LEFT",
        },
        disparityGroupsControlItems(i18n)
      );
    }
  }, [
    actions,
    state.controls.countries,
    selectedCountries,
    country,
    view,
    i18n,
  ]);

  const seriesAccessor = useMemo(() => {
    return view === "disparities"
      ? (d: Data[number]) => d.group
      : (d: Data[number]) => forceUnwrapCountryOrRegion(d.country);
  }, [view]);

  const countriesColorScale = scaleOrdinal({
    domain: countries,
    range: M.discretePalette,
  });

  const colorScale =
    view === "disparities" ? disparitiesColorScale : countriesColorScale;

  const getValueLabel = useMemo(() => {
    return view === "disparities"
      ? (d: Data[number]) => i18n._(GroupLabelLookup[d.group as DisparityGroup])
      : (d: Data[number]) => getCountryName(d.country);
  }, [getCountryName, i18n, view]);

  const handleToggleInterpretation = () => {
    actions.updateSelectionControl(
      Controls["2-toggle-interpretation"],
      O.some(interpretationToggled ? [] : ["toggled"])
    );
  };

  const [lockActivePoint, setLockActivePoint] = useState<Data[number]>();
  const [highlightedSeries, setHighlightedSeries] = useState<string>();
  const chartActions = useMemo(
    () => ({
      setHighlightedSeries: setHighlightedSeries,
      setLockActivePoint: setLockActivePoint,
    }),
    []
  );
  const lineStyler = useCallback(
    (series: string | number) => ({
      stroke: colorScale(series as $FixMe),
      strokeOpacity:
        highlightedSeries !== undefined
          ? series === highlightedSeries
            ? 1
            : 0.1
          : 1,
      strokeDasharray:
        view === "disparities" && series === "all" ? "6 8" : undefined,
      strokeWidth: 3,
    }),
    [colorScale, highlightedSeries, view]
  );

  return (
    <>
      <motion.div
        ref={chartContainerRef}
        style={{ position: "relative" }}
        initial={true}
        animate={{ opacity: state.status === "fetching" ? 0.25 : 1 }}
        transition={{ duration: 1 }}
      >
        <Popup
          open={interpretationToggled}
          container={chartContainerRef.current}
          anchor={toggleInterpretationRef.current}
          anchorPosition={{ horizontal: "end", vertical: "start" }}
          anchorOffset={{ horizontal: 20, vertical: -15 }}
          onClose={handleToggleInterpretation}
          style={{ width: 275 }}
        >
          {view === "countries" ? (
            <>
              {countries.length === 0 ? (
                <EmptyInterpretation>
                  <SlideTitle>
                    <Trans id="fig.learning.trajectories.explorer.empty-title">
                      Interpretation
                    </Trans>
                  </SlideTitle>
                  <Trans id="fig.learning.trajectories.explorer.empty-select-countries">
                    Data interpretation is not available until filters are
                    selected. Select countries to get started.
                  </Trans>
                </EmptyInterpretation>
              ) : (
                <InterpretationCountries
                  dataset={chartData}
                  colorScale={colorScale}
                  chartActions={chartActions}
                  getCountryName={getCountryInterpretationName}
                  requestClose={() => handleToggleInterpretation()}
                />
              )}
            </>
          ) : view === "disparities" ? (
            <>
              {!country ? (
                <EmptyInterpretation>
                  <SlideTitle>
                    <Trans id="fig.learning.trajectories.explorer.empty-title">
                      Interpretation
                    </Trans>
                  </SlideTitle>
                  <Trans id="fig.learning.trajectories.explorer.empty-select-country">
                    Data interpretation is not available until filters are
                    selected. Select a country to get started.
                  </Trans>
                </EmptyInterpretation>
              ) : (
                <InterpretationDisparities
                  dataset={chartData}
                  colorScale={colorScale}
                  chartActions={chartActions}
                  getCountryName={getCountryInterpretationName}
                  requestClose={() => handleToggleInterpretation()}
                />
              )}
            </>
          ) : null}
        </Popup>
        <TrajectoriesChart<Data[number]>
          emptyChartMessage={i18n._(
            t(
              "fig.learning.trajectories.explorer.empty-countries-no-comparison"
            )`Choose countries to get started`
          )}
          height={M.chartHeight.m}
          dataset={chartData}
          seriesAccessor={seriesAccessor}
          xAccessor={(d) => d.year}
          yAccessor={(d) => d.value}
          yAxisTitle={i18n._(yAxisTitleLookup[skill])}
          xAxisTitle={i18n._(xAxisTitleLookup[facet])}
          xAxisSubtitle={i18n._(xAxisSubtitleLookup[facet])}
          formatTooltipLabel={getValueLabel}
          formatTooltipValue={(d) =>
            d.value === null
              ? t("fig.label.data.not.available")`Not Available`
              : format(".0%")(d.value)
          }
          rules={
            chartData.length > 0 ? [goalRule(i18n, facet, skill, true)] : []
          }
          lineStyle={lineStyler}
          backgroundSeriesFilter={(series: string | number) =>
            highlightedSeries ? series !== highlightedSeries : false
          }
          bands={groups.map(
            (group): BandItem => ({
              upper: disparitiesGroupsLookup[group][1],
              lower: disparitiesGroupsLookup[group][0],
              style: {
                fill: bandColorScale(group),
                fillOpacity: 0.2,
              },
            })
          )}
          lockActivePoint={lockActivePoint}
          flipTooltip={true}
          showColorLegend={true}
          formatLegendLabel={(series) =>
            view === "disparities" ? (
              series === "all" ? (
                country === "lmic" ? (
                  selectedCountryName
                ) : (
                  <Trans id="fig.learning.trajectories.explorer.group.label.all">
                    {selectedCountryName} Average
                  </Trans>
                )
              ) : (
                i18n._(GroupLabelLookup[series])
              )
            ) : (
              getCountryName(countryIdIso.wrap(series))
            )
          }
          legendValues={
            view === "disparities" ? selectedGroupsWithAll : countries
          }
          legendOrientation="horizontal"
        />
      </motion.div>
    </>
  );
};

async function fetchObservations(
  countries: string[],
  selectedGroups: string[],
  selectedFacet: FACET,
  selectedSkill: SKILL
) {
  const { getObservations } = await explorerAPI.query({
    getObservations: [
      {
        countries: countries,
        groups: selectedGroups,
        facet: selectedFacet,
        skill: selectedSkill,
      },
      everything,
    ],
  });
  return pipe(
    Data.decode(getObservations),
    E.fold(
      (validationErrors) => {
        throw new Error(
          `Could not decode the data ${validationErrors
            .map((e) => `${e.value}`)
            .join(",")}`
        );
      },
      (x) => x
    )
  );
}

export default withFigureIOExplorer({
  metadata,
  Chart,
  size: "main",
  csv: require("../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER.zip"),
  xlsx: require("../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER.xlsx"),
});
