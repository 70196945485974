import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "ASSESS",
  title: /*i18n*/ {
    id: "fig.learning.ASSESS.title",
    defaults: `Percentage of students in primary education achieving at least a minimum proficiency level in reading`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.ASSESS.caption",
    defaults: `Differences in reading performance between countries are large, but the gender gap tends to be small.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.ASSESS.source",
    defaults: `UIS database`,
  },
};
