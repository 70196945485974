import { FontFace } from "@valde/typography";

// -----------------------------------------------------------------------------
// Font stacks

export type FontStack = Pick<FontFace, "fontFamily" | "fallback">;

export const mkFontStack = ({ fontFamily, fallback }: FontStack) =>
  [fontFamily, ...fallback].join(",");

export const notoStack = {
  fontFamily: "Noto Sans",
  fallback: ["Noto Sans SC", "Noto Naskh Arabic", "Verdana", "sans-serif"],
};

export const notoStackCSS = mkFontStack(notoStack);

// -----------------------------------------------------------------------------
// Font-face definitions

export const notoRegular: FontFace = {
  name: "Noto Regular",
  ...notoStack,
  cssProperties: {
    fontWeight: 400,
    fontStyle: "normal",
  },
};

export const notoBold: FontFace = {
  name: "Noto Bold",
  ...notoStack,
  cssProperties: {
    fontWeight: 700,
    fontStyle: "normal",
  },
};

export const notoFontFaces = [notoRegular, notoBold];
