
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "الإنصاف";
export const lead = "لقد أُحرز تقدّم على الصعيد العالمي نحو تحقيق التكافؤ بين الجنسين في التعليم، ولكن إلى الآن لم يحقّق ذلك التكافؤ إلا ثلثا البلدان فيما يخص المرحلة الابتدائية، ونصف البلدان فيما يخص المرحلة الدنيا من التعليم الثانوي، وربع البلدان فيما يخص المرحلة المرحلة العليا من التعليم الثانوي.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`الرغبة في عدم إهمال أحد مشمولة في جميع أهداف التنمية المستدامة، ويُعبَّر عنها تعبيراً واضحاً في الغاية الخامسة لرابع أهداف التنمية المستدامة الخاص بالتعليم. ويضمن هذا التركيز أن يولي واضعو السياسات الأولوية لمساعدة من هم الأشد تخلفاً عن الركب.`}</p>
    </blockquote>
    <p>{`نحتاج إلى الحصول على ما يرد في الدراسات الاستقصائية الخاصة بالأسر المعيشية من بيانات موزَّعة `}<strong parentName="p">{`بحسب الدخل والجنس والسن والعرق والانتماء الإثني والحالة من ناحية الهجرة والإعاقة والموقع الجغرافي وخصائص أخرى`}</strong>{` ذات صلة بالسياقات الوطنية، وذلك من أجل التمكّن من رصد ما إذا كان يجري العمل على سد الفجوات في مجال التعليم.`}</p>
    <p>{`منذ عام 2010، تستخدم قاعدة البيانات العالمية بشأن التفاوت في التعليم الدراسات الاستقصائية الخاصة بالأسر المعيشية لقياس الفجوات في مجال التعليم في مختلف البلدان. ويلخّص هذا المقال بعض أهم الوقائع والاتجاهات فيما يخص التفاوت في مجال التعليم، في حين تُناقش جوانب إضافية في المقال الخاص بالتمويل.`}</p>
    <h2 {...{
      "id": "نوع-الجنس"
    }}>{`نوع الجنس`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`يبيّن الرسم البياني أدناه الفجوات التعليمية بين الفئات، من قبيل البنات والبنين، أو أفقر الأهالي وأغناهم. ويقيس الفجوة التعليمية باستخدام `}<Tooltip title="Parity index" content="In a parity index, if the value is close to 0, it shows the disadvantaged group is very far behind; values close to 1 mean there is parity; values close to 2 mean that the advantaged group is the furthest behind. A parity index of 0.5, for instance, could show that there are 30% of girls but 60% of boys enrolled. A parity index of 1.5 would indicate there were 30% of boys but 60% of girls enrolled." mdxType="Tooltip">{` مؤشر التكافؤ `}</Tooltip>{`.`}</p>
      <p>{`كان هناك تقدّم متواصل نحو تحقيق `}<Tooltip title="Gender parity" content="Where there are equal numbers of boys to girls" mdxType="Tooltip">{`تكافؤ الجنسين `}</Tooltip>{` على مر الجيل الماضي، وإن لم يُلاحظ إلا نادراً. فعدد البنات والفتيات في المدارس والجامعات يتجاوز عدد البنين والفتيان في الكثير من مناطق العالم، ومعظمها من أغنى مناطقه.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`يبيّن أي خط يمتد أسفل `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`القسم الملوَّن بالرمادي `}</HighlightByIndex>{` أن عدد البنين أو الفتيان الملتحقين بالمدارس يتجاوز عدد البنات الملتحقات بها.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`في أفريقيا جنوب الصحراء الكبرى ما زالت سبع فتيات من كل عشرة فتيان فقط يواظبن على التعليم الجامعي.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <p>{`في آ `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`سيا الوسطى والجنوبية `}</HighlightByIndex>{`، التي كان وضع البنات فيها رديئاً جداً في عام 1990، تحقّق تكافؤ الجنسين في المرحلة الدنيا من التعليم الثانوي فصاعداً، وذلك ما يُعزى رئيسياً إلى التقدّم الكبير المحرز في الهند. ونتيجةً لذلك، تفوقت هذه المنطقة على `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`أفريقيا جنوب الصحراء الكبرى `}</HighlightByIndex>{` فيما يتعلق بالتقدّم المحرز في المرحلة الثانوية وما بعدها.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`على الصعيد العالمي، أُحرز تقدّم كبير منذ عام 2000 وأصبح هناك تكافؤ في عدد البنين والبنات الملتحقين بالمدارس. ولكن إذا انتقلنا إلى المستوى القطري فسنلاحظ استمرار الفجوات: فحقق ثلثا البلدان التكافؤ بين الجنسين فيما يخص المرحلة الابتدائية، وحقّقه نصف البلدان في المرحلة الدنيا من التعليم الثانوي، وحقّقه ربع البلدان في المرحلة العليا من التعليم الثانوي.`}</p>
      <p>{`انخفضت نسبة البلدان التي تسجل `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`تفاوتاً حاداً على حساب الفتيات`}</HighlightByIndex>{` إلى النصف منذ عام 2000، لكنها لا تزال تبلغ 7% في المرحلة الابتدائية و12% في المرحلة الدنيا من التعليم الثانوي و16% في المرحلة العليا من التعليم الثانوي.`}</p>
      <p>{`علينا ألا ننسى البنين حين نتحدّث عن المساواة بين الجنسين. ولئن كان من الأقل شيوعاً أن تشهد البلدان `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`تفاوتاً كبيراً على حساب البنين `}</HighlightByIndex>{` في المرحلة الابتدائية و المرحلة الدنيا من التعليم الثانوي، هذا أكثر شيوعاً بكثير في المرحلة الثانوية العليا وما بعدها، ومن هذه الناحية يظل الوضع على ما هو عليه أو يتدهور.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`في هذا الرسم البياني، كلما ازداد حجم القسم الأزرق من العمود ودَكَنه، ازداد عدد البلدان التي `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`يزيد فيها عدد البنين غير الملتحقين بالمدارس `}</HighlightByIndex>{` على عدد البنات غير الملتحقات بها. وكلما ازداد حجم القسم الأخضر من العمود ودَكَنه، ازداد عدد البلدان التي `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`يزيد فيها عدد البنات غير الملتحقات بالمدارس `}</HighlightByIndex>{` على عدد البنين غير الملتحقين بها.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
وفي التعليم الجامعي، على سبيل المثال، يشهد ثلثا البلدان <HighlightByIndex index="0" mdxType="HighlightByIndex">تفاوتاً كبيراً جداً على حساب البنين، </HighlightByIndex> ويتفاقم هذا الوضع على مر الزمن.
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "الثراء"
      }}>{`الثراء`}</h2>
      <p>{`وبشكل عام، فإن الفجوات التعليمية بين البنات والبنين ليست واسعة بقدر اتساع الفجوات بين المناطق الريفية والحضرية وبين الأغنياء والفقراء، وهو ما تساعد الدراسات الاستقصائية للأسر المعيشية على كشفه.`}</p>
      <p>{`في لاو على سبيل المثال، يواظب 96% من أطفال `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`أغنى `}</HighlightByIndex>{` الفئات على الدراسة الابتدائية، بينما يواظب عليها 28% فقط من أطفال `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`أفقر `}</HighlightByIndex>{` الفئات. وفي نيجيريا، يواظب 94% من أطفال أغنى الفئات على الدراسة الابتدائية، بينما يواظب عليها 22% فقط من أطفال أفقر الفئات.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`تبيّن النقاط الحمراء ما هي نسبة `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`أفقر `}</HighlightByIndex>{` الأطفال أو الشباب الذين يواظبون على الدراسة مقارنةً `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`بأغناهم `}</HighlightByIndex>{` - الذين تبيَّن نسبة مواظبتهم على الدراسة باللون البنفسجي. وبإمكانك تغيير المرحلة الدراسية من المرحلة الابتدائية إلى المرحلة الدنيا من التعليم الثانوي فصاعداً في أعلى الرسم البياني.`}</p>
        </ScrollySection>
        <ScrollySection id="SLV" mdxType="ScrollySection">
          <p>{`الفجوات أشسع في البلدان المتوسطة الدخل فيما يخص التعليم الجامعي. وفي `}<strong parentName="p">{`السلفادور`}</strong>{` على سبيل المثال، واظب 51% من أبناء أغنى الفئات على التعليم الجامعي بأحد أنواعه، بينما واظب عليه أقل من 2% من أبناء أفقر الفئات.`}</p>
        </ScrollySection>
        <ScrollySection id="MNG" mdxType="ScrollySection">
          <p>{`وفي `}<strong parentName="p">{`منغوليا`}</strong>{`، بلغت النسبتان المناظرتان 70% و10%.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`سنفهم المزيد إذا نظرنا إلى كيفية تغيّر الفجوات بمرور الأطفال عبر النظام التعليمي.`}</p>
      <p>{`بعد النظر في الفجوات بين الجنسين في اليمن أدناه، يتبين أن نسبة تسرب الفتيات من التعليم ترتفع كلما تقدمت المرحلة الدراسية.`}</p>
      <p>{`في المقابل، يبيّن `}<strong parentName="p">{`الثراء`}</strong>{` إلى أية درجة تتراكم الفجوات على مر المسار التعليمي على حساب أفقر الفئات، إذ يكاد لا يواظب أحد على المرحلة الثانوية العليا وما بعدها.`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`نادراً ما يندرج طفل في فئة واحدة فقط، فمن المهم جداً النظر في كيفية تفاعل الخصائص الفردية، من قبيل الانتماء الجنسي، مع خصائص أخرى من قبيل الثراء على النحو المبيَّن في الرسم البياني التالي.`}</p>
    <p>{`في أفريقيا جنوب الصحراء، تتسع الفجوات بين `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`أفقر `}</HighlightByIndex>{` الفئات `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`وأغنى `}</HighlightByIndex>{` الفئات على مر المسار التعليمي، إذ يواظب على التعليم الجامعي ما يقل عن 10% من أفقر البنات، مقارنةً بـــ 90% من أغنى البنين.`}</p>
    <p>{`في شرق آسيا وجنوب شرقها، تظهر النقاط فوق الخط الرمادي لأن الفجوات الجنسانية هناك، تقع على حساب أفقر البنين. وفيما يخص التعليم الجامعي، مواظبة أغنى البنات على الدراسة الجامعية أكثر احتمالاً من مواظبة أقرانهن من البنين عليها بالربع، غير أن احتمال مواظبة أفقر البنات عليها أقل بثماني أضعاف.`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "الخاتمة"
    }}>{`الخاتمة`}</h2>
    <p>{`هناك مستويات غير مقبولة من التفاوت التعليمي بين البلدان وبين الفئات المختلفة ضمنها. وتعد البيانات المصنَّفة العالية الجودة شرط مسبق لوضع استراتيجيات مناسبة لتقليص تلك الفجوات، وضمان عدم إهمال أحد في سعينا إلى تحقيق الهدف 4 للتنمية المستدامة.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`قاعدة البيانات العالمية بشأن أوجه التفاوت في مجال التعليم`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Still left behind - منظمة إنقاذ الطفولة`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000370688"
          }}>{`بناء الجسور لتحقيق المساواة بين الجنسين - التقرير العالمي لرصد التعليم لعام 2019 `}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;