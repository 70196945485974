import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "LEARN_AGE_GRADE",
  title: /*i18n*/ {
    id: "fig.learning.LEARN_AGE_GRADE.title",
    defaults: `Learning outcomes by age and grade`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.LEARN_AGE_GRADE.caption",
    defaults: `Average learning outcomes are even worse when children out of school are taken into account.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.LEARN_AGE_GRADE.source",
    defaults: `ASER Pakistan`,
  },
};
