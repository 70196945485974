import css from "@emotion/css";
import { mkStyles, useQueryParamsWithDefault, useTheme } from "hooks";

import React from "react";
import { focusArticles } from "../../focus";
import { RAlias, REnv, getFocusMetadata, RAliasTheme } from "routes";
import { themeAliases, subthemesIndex } from "routes";
import { getThemeLabel } from "components/article-switcher";
import * as M from "../../materials";
import { motion } from "framer-motion";
import { ReactComponent as FocusIcon } from "../../icons/ic_article_16dp.svg";
import linkArrow from "./files/ic_link_24dp.svg?raw";
import { PageLink } from "components/links";
import { Trans, t } from "@lingui/macro";
import { useImmer } from "use-immer";
import * as Select from "@radix-ui/react-select";
import { useI18n } from "locales";
import { ReactComponent as ArrowLeftIcon } from "../../icons/ic_arrow-left.svg";
import { useRowStyles, useSectionStyles } from "./shared/table";

const useStyles = mkStyles(({ palette, breakpoints }) => {
  return {
    root: css`
    h2 {
      ${M.fontHeading2};
      margin-bottom: ${M.spacing.base8(3)};
      padding: 0 ${M.spacing.base8(4)};
      margin-top: ${M.spacing.base8(2)};
  
      @media ${breakpoints.down("l")} {
        padding: 0 ${M.spacing.base8(1)};
      }
    } 
    }
    `,
    rowContent: css`
      display: flex;
      flex-wrap: wrap;
      padding: 0 ${M.spacing.base8(1)};
      gap: ${M.spacing.base8(2)};
      align-items: center;
      justify-content: flex-start;

      h4 {
        ${M.fontHeading4}
      }
    `,
    icon: css`
      flex-shrink: 0;
      width: 32px;
      height: 32px;
    `,
    lead: css`
      ${M.fontTable}
      color: ${palette.text.light};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    expandButton: css`
      outline: none;
      background: none;
      border: none;
      width: 100%;
      cursor: pointer;
      transition: color 0.2s ease-in-out;
      ${M.fontTable}
      font-weight: 600;
      color: ${palette.action.main};
      &:hover {
        color: ${palette.action.dark};
      }
      text-align: center;
      display: flex;
      justify-content: center;
    `,

    dot: css`
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
      border-radius: 50%;
      width: 4px;
      height: 4px;
    `,
  };
});

export function FocusIndex() {
  const { palette, client } = useTheme();
  const { locale } = useQueryParamsWithDefault(REnv);
  const sectionStyles = useSectionStyles();
  const rowStyles = useRowStyles();
  const styles = useStyles();
  const [state, setState] = useImmer<{
    expanded: Record<RAliasTheme, boolean>;
    publication: string;
  }>({
    expanded: {
      access: false,
      equity: false,
      learning: false,
      quality: false,
      finance: false,
    },
    publication: "all",
  });
  const i18n = useI18n();

  const focuses = React.useMemo(
    () =>
      focusArticles.published.map((a) => getFocusMetadata(a as RAlias, locale)),
    [locale]
  );

  const themesFocus = React.useMemo(() => {
    return themeAliases.map((theme) => {
      const subthemes = subthemesIndex[theme];
      return {
        alias: theme,
        focuses: focuses
          .filter(
            (focus) =>
              subthemes.includes(focus.article) &&
              (focus.year === state.publication || state.publication === "all")
          )
          .sort((a, b) => (a.year > b.year ? -1 : 1)),
      };
    });
  }, [focuses, state.publication]);

  const publications = React.useMemo(() => {
    const years = [
      ...new Set(
        focuses.map((focus) => focus.year).sort((a, b) => (a > b ? -1 : 1))
      ),
    ];

    const pubs = years.map((year) => ({
      label: `${i18n._(t`GEM Report`)} ${year}`,
      value: year,
    }));

    return [{ label: i18n._(t`All Publications`), value: "all" }, ...pubs];
  }, [focuses, i18n]);

  const childVariants = {
    hover: {
      x: client.isRTL ? -5 : 5,
      rotate: client.isRTL ? 180 : 0,
    },
    default: {
      x: 0,
      rotate: client.isRTL ? 180 : 0,
    },
  };

  return (
    <div css={styles.root}>
      <PublicationSelect
        options={publications}
        value={state.publication}
        onChange={(v) => {
          setState((draft) => {
            draft.publication = v;
          });
        }}
      />
      <motion.div layout="position">
        {themesFocus.map((theme) => {
          if (theme.focuses.length === 0) return null;
          const focusToDisplay = state.expanded[theme.alias]
            ? theme.focuses.length
            : 2;

          return (
            <motion.div
              key={theme.alias}
              css={[sectionStyles.root]}
              layoutId="section"
              layout="position"
            >
              <div css={[sectionStyles.table]}>
                <motion.div css={sectionStyles.content} layout="position">
                  <h2>{getThemeLabel(theme.alias)}</h2>
                  {theme.focuses.slice(0, focusToDisplay).map((focus) => {
                    return (
                      <PageLink key={focus.alias} name={focus.alias} query={{}}>
                        <a>
                          <motion.div
                            css={[rowStyles.row, rowStyles.rowLink]}
                            layoutId="row"
                          >
                            <div css={styles.rowContent}>
                              <FocusIcon
                                css={[
                                  styles.icon,
                                  css`
                                    color: ${palette.theme[theme.alias]
                                      .palette[5]};
                                  `,
                                ]}
                              />
                              <div
                                css={css`
                                  flex-basis: 80%;
                                `}
                              >
                                <h4>{focus.title}</h4>

                                <p css={styles.lead}>
                                  <span>
                                    <Trans>GEM Report</Trans> {focus.year}
                                  </span>
                                  <div
                                    css={[
                                      styles.dot,
                                      css`
                                        background-color: ${palette.theme[
                                          theme.alias
                                        ].palette[5]};
                                      `,
                                    ]}
                                  />
                                  {focus.lead}
                                </p>
                              </div>
                            </div>
                            <div css={rowStyles.linkArrow}>
                              <motion.img
                                css={rowStyles.linkImg}
                                initial="default"
                                variants={childVariants}
                                src={linkArrow}
                              />
                            </div>
                          </motion.div>
                        </a>
                      </PageLink>
                    );
                  })}

                  {theme.focuses.length > 2 && (
                    <motion.button
                      layoutId="row"
                      onClick={() => {
                        setState((draft) => {
                          draft.expanded[theme.alias] =
                            !state.expanded[theme.alias];
                        });
                      }}
                      css={[
                        rowStyles.row,
                        rowStyles.rowLink,
                        styles.expandButton,
                      ]}
                    >
                      <div
                        css={css`
                          margin-left: auto;
                          margin-right: auto;
                        `}
                      >
                        {state.expanded[theme.alias] ? (
                          <Trans>See less</Trans>
                        ) : (
                          <Trans> See all</Trans>
                        )}
                      </div>
                    </motion.button>
                  )}
                </motion.div>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
}

const PublicationSelect = ({
  options,
  value,
  onChange,
}: {
  value: string;
  onChange: (v: string) => void;
  options: {
    label: string;
    value: string;
  }[];
}) => {
  const styles = useSelectStyles();
  return (
    <div css={styles.root}>
      <Select.Root value={value} onValueChange={onChange}>
        <Select.Trigger css={styles.trigger}>
          <Select.Value>
            {options.find((o) => o.value === value)?.label}
          </Select.Value>
          <Select.Icon asChild={true}>
            <ArrowLeftIcon css={styles.icon} />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content
          asChild={true}
          position="popper"
          sideOffset={2}
          css={styles.content}
        >
          <motion.div
            animate={{
              opacity: 1,
              y: 0,
            }}
            initial={{
              opacity: 0.5,
              y: -10,
            }}
            exit={{
              opacity: 0.5,
              y: -10,
            }}
            transition={{
              duration: 0.5,
              type: "spring",
            }}
          >
            <Select.Viewport>
              {options.map((option) => (
                <Select.Item
                  key={option.value}
                  value={option.value}
                  css={styles.item}
                >
                  {option.label}
                </Select.Item>
              ))}
            </Select.Viewport>
          </motion.div>
        </Select.Content>
      </Select.Root>
    </div>
  );
};

const useSelectStyles = mkStyles(({ palette, breakpoints }) => {
  return {
    root: css`
      margin: ${M.spacing.base8(2)} 0 ${M.spacing.base8(4)} 0;
    `,
    trigger: css`
      ${M.fontTable}
      user-select: none;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      padding: ${M.spacing.base8(1.5)};
      color: ${palette.action.main};
      background-color: white;
      border: ${palette.grayscale[3]} 1px solid;
      border-radius: ${M.spacing.base8(0.5)};

      &[data-state="open"],
      &:hover {
        outline: ${palette.action.main} 2px solid;
      }

      width: 100%;

      @media ${breakpoints.up("s")} {
        min-width: 440px;
        width: 33%;
      }

      &[data-state="open"] {
        svg{
          transform: rotate(90deg);
        }
    `,
    icon: css`
      align-self: center;
      width: 16px;
      height: 16px;
      transition: transform 0.2s ease-in-out;
      transform: rotate(270deg);
    `,
    content: css`
      user-select: none;
      background-color: white;
      width: var(--radix-select-trigger-width);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
      border: ${palette.grayscale[3]} 1px solid;

      [data-state="open"] {
        animation: fadeIn 0.2s ease-in-out;
      }

      [data-state="closed"] {
        animation: fadeOut 0.2s ease-in-out;
      }
    `,
    item: css`
      ${M.fontTable}
      padding: ${M.spacing.base8(1)} ${M.spacing.base8(1.5)};
      color: ${palette.text.black};
      width: 100%;
      text-align: left;
      outline: none;
      cursor: pointer;

      &[data-highlighted] {
        background-color: ${palette.grayscale[2]};
        color: ${palette.action.main};
      }
    `,
  };
});
