import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { flow } from "fp-ts/lib/function";
import memoizeOne from "memoize-one";
import { fromNewtype } from "../lib";
import { An, Ar, Eq, io, nt, O, Ord, pipe, R } from "../prelude";
import {
  CountryId,
  countryIdIso,
  CountryMeta,
  getCountryName,
} from "./country";
import { partition } from "fp-ts/lib/Array";

export type RegionId = nt.Newtype<"RegionId", string>;
export const regionIdIso = nt.iso<RegionId>();
export const regionIdEq = nt.getEq<RegionId>(Eq.eqString);

export const RegionCategory = io.keyof({
  country: null,
  income: null,
  world: null,
  geo: null,
  unknown_region: null,
});
export type RegionCategory = io.TypeOf<typeof RegionCategory>;

export const regionCategoryOrder = {
  country: 0,
  geo: 1,
  income: 2,
  world: 3,
  unknown_region: 4,
};

export const ordRegionCategory = Ord.contramap(
  (category: RegionCategory) => regionCategoryOrder[category]
)(Ord.ordNumber);

export const RegionMeta = io.type({
  region: fromNewtype<RegionId>(io.string),
  label: io.string,
  category: RegionCategory,
});
export type RegionMeta = io.TypeOf<typeof RegionMeta>;

// -----------------------------------------------------------------------------

export const getRegionMeta = (function () {
  const regionsById = memoizeOne((regions: Array<RegionMeta>) =>
    pipe(
      An.fromArray(regions),
      O.map(flow(An.groupBy((x) => regionIdIso.unwrap(x.region))))
    )
  );

  return (regions: Array<RegionMeta>, id: RegionId) => {
    return pipe(
      regionsById(regions),
      O.chain((r) => R.lookup(regionIdIso.unwrap(id), r)),
      O.map(An.head)
    );
  };
})();

export const getRegionName = (regions: Array<RegionMeta>, id: RegionId) =>
  pipe(
    getRegionMeta(regions, id),
    O.map((x) => x.label),
    O.getOrElse(() => {
      console.warn(`No region metadata found for "${id}"`);
      return regionIdIso.unwrap(id);
    })
  );

export const getRegionCategoryName = (function () {
  const lookup = {
    country: t`Countries`,
    income: t`Income groups`,
    world: t`World`,
    geo: t`Regions`,
    unknown_region: t`Unknown regions`,
  };
  return (i18n: I18n, id: RegionCategory) => i18n._(lookup[id]);
})();

// -----------------------------------------------------------------------------

export function getRegionIndex(regions: Array<RegionMeta>, id: RegionId) {
  return pipe(
    regions,
    Ar.findIndex((x) => regionIdEq.equals(x.region, id)),
    O.getOrElse(() => Infinity)
  );
}

export function mkOrdRegion<A>(
  regions: Array<RegionMeta>,
  get: (x: A) => RegionId
) {
  return Ord.contramap((needle: A) => getRegionIndex(regions, get(needle)))(
    Ord.ordNumber
  );
}

export function mkOrdRegionWorldFirst<A>(
  regions: Array<RegionMeta>,
  get: (x: A) => RegionId
) {
  return Ord.contramap((needle: A) =>
    regionIdIso.unwrap(get(needle)) === "World"
      ? -1
      : getRegionIndex(regions, get(needle))
  )(Ord.ordNumber);
}

export function sortRegions(regions: Array<RegionMeta>, worldFirst?: boolean) {
  return <A extends object>(xs: Array<A>, get: (x: A) => RegionId) => {
    return pipe(
      xs,
      Ar.sort(
        worldFirst
          ? mkOrdRegionWorldFirst(regions, get)
          : mkOrdRegion(regions, get)
      )
    );
  };
}

/**
 * This is used to reduce the number of income regions on small screens, e.g.
 * in the hero visualizations.
 */
export function filterEssentialIncomeRegions<A>(
  regions: Array<A>,
  getRegion: (x: A) => RegionId
) {
  return regions.filter((x) =>
    ["Low income", "High income", "World"].includes(
      regionIdIso.unwrap(getRegion(x))
    )
  );
}

// -----------------------------------------------------------------------------

/* Only used in Learning Trajectories. In the trajectories datasets, the same field is used to
encode country or region. As such, we add the following function to be able to decode this field
correctly. */

const checkIsRegion = (value: string, regions: RegionMeta[]) =>
  regions.some((d) => regionIdIso.unwrap(d.region) === value);

export function splitCountriesAndRegions(props: {
  input: { country: CountryId | RegionId }[];
  regions: RegionMeta[];
  countries: CountryMeta[];
}) {
  const stringified = props.input.map((x) => ({ country: `${x.country}` }));

  const { left: countries, right: regions } = partition(
    (x: typeof stringified[number]) => checkIsRegion(x.country, props.regions)
  )(stringified);

  return {
    countries: countries.map((x) => ({
      value: x.country,
      label: getCountryName(props.countries, countryIdIso.wrap(x.country)),
    })),
    regions: regions.map((x) => ({
      value: x.country,
      label: getRegionName(props.regions, regionIdIso.wrap(x.country)),
    })),
  };
}

export const getRegionOrCountryName = (props: {
  input: { country: CountryId | RegionId };
  regions: RegionMeta[];
  countries: CountryMeta[];
}) => {
  const stringified = { country: `${props.input.country}` };
  const regionId = stringified.country;

  return checkIsRegion(regionId, props.regions)
    ? getRegionName(props.regions, regionIdIso.wrap(regionId))
    : getCountryName(props.countries, countryIdIso.wrap(stringified.country));
};

export const forceUnwrapCountryOrRegion = (value: CountryId | RegionId) =>
  `${value}`;
