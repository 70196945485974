
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "质量";
export const lead = "优质的教育不等于也不应归约为学习成果。太多儿童无法受教于经过充分培训的教师、无法获得良好的校园基础设施或者安全无暴力的学习环境。";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const QualityFramework = makeShortcode("QualityFramework");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_QUALITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`优质的教育不等于也不应归约为学习成果。太多儿童无法受教于经过充分培训的教师、无法获得良好的校园基础设施或者安全无暴力的学习环境。`}</p>
    </blockquote>
    <p>{`优质教育难以监测，因为各国对其理解和定义不同。除了学习成果，衡量可持续发展目标4这项全球教育目标的质量指标并不多。`}</p>
    <h2 {...{
      "id": "教师"
    }}>{`教师`}</h2>
    <IndicatorReference id="4.c.2" mdxType="IndicatorReference">
      <p>{`教师是教育质量的基石。然而在2000年之后，由于急于普及初等教育，更多学生坐进了教室，教师招聘工作却没有及时跟进。因此教室显得过于拥挤，教育的质量也受到了影响。`}</p>
      <QualityFramework highlighted="teachers" description="教育质量框架详情" mdxType="QualityFramework" />
      <p>{`下图显示中小学学生与教师的比率。在一些撒哈拉以南非洲国家，尽管情况自2000年起有所改善，生师比依然不及20世纪80年代的水平。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PTR" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
在<HighlightByIndex index="0" mdxType="HighlightByIndex">撒哈拉以南非洲</HighlightByIndex>，从1990年至2000年推进免费小学教育之后，各国儿童入学的速度超过了聘用新教师的速度，因此生师比常常急剧加大。
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
但是在<HighlightByIndex index="6" mdxType="HighlightByIndex">北美和欧洲</HighlightByIndex>，自2005年起这一比率一直稳定保持在每15名学生对应1名教师。
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`教学是颇具挑战性的工作，教师没有做好准备，进入课堂时便存在严重不足。各国衡量教师准备是否充分，主要通过以下两种评估方式进行：`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`首先，教师是否`}<strong parentName="p">{`具备资格`}</strong>{`，即是否具有符合国家标准规定的学历证明。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`其次，教师是否`}<strong parentName="p">{`经过培训`}</strong>{`，即不论资格如何，是否接受过适当的教师培训。`}</p>
      </li>
    </ul>
    <IndicatorReference id="4.c.1" mdxType="IndicatorReference">
      <p>{`接受培训意味着教师接受过`}<Tooltip title="Pedagogical knowledge" content="Knowledge on approaches, methods and techniques of teaching." mdxType="Tooltip">{`教学法知识`}</Tooltip>{`和`}<Tooltip title="Professional knowledge" content="Knowledge on, for example, the legal frameworks governing the teaching profession." mdxType="Tooltip">{`专业知识`}</Tooltip>{`训练。一些项目也可能包括`}<Tooltip title="Content knowledge" content="Knowledge of the curriculum, subject matter and use of relevant materials." mdxType="Tooltip">{`学科知识`}</Tooltip>{`。然而，各国需要的培训类型各不相同，相关信息也不足。由于这些差异的存在，尽管培训是衡量优质教育的关键要素，但是关于受过培训的教师的指标`}{`[4.c.1]`}{`却是可持续发展目标4监测框架里最不具可比性的指标之一。`}</p>
      <p>{`从全球看，如下图所示，大部分教师都具备资格、受过培训或二者兼备。例如，在拉丁美`}<HighlightByIndex index="4" mdxType="HighlightByIndex">{`洲和加勒比地区`}</HighlightByIndex>{`，受过培训的教师比例超过80%。`}</p>
      <p>{`相比之下，`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`在撒哈拉以南非洲`}</HighlightByIndex>{`，受过培训的教师比例从2000年的84%下降到了2019年的69%。`}</p>
      <Figure id="TRAINED" mdxType="Figure" />
      <p>{`对于缺少具备资格和受过培训教师的国家而言，解决这一问题并非易事。许多国家由于高等教育毕业生总数少，符合条件的教师数量无法满足需求。这显示了教育系统内部相互关联的问题可能带来的后果：初中完成率低等某一个层面的问题很容易对其他方面造成影响，比如会由此产生受过培训的教师数量有限、未来不能满足学生需求的现象。`}</p>
    </IndicatorReference>
    <h2 {...{
      "id": "基础设施"
    }}>{`基础设施`}</h2>
    <IndicatorReference id="4.a.1e" mdxType="IndicatorReference">
      <p>{`适宜的学习环境必须有充足的`}<strong parentName="p">{`供水`}</strong>{`和卫生设施，特别是对女生来说。但是，有基本供水条件的小学在撒哈拉以南非洲只有42%，在东亚和东南亚是78%，在中亚和南亚为83%。`}</p>
      <QualityFramework highlighted="material" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1f" mdxType="IndicatorReference">
      <p>{`重要的是，缺乏隐私的`}<strong parentName="p">{`卫生`}</strong>{`设施可能也不够安全，而无法保障经期卫生的设施则会阻碍女孩上学。因此，配备男女有别卫生设施是至关重要的政策转变，可以防止小学大范围延迟入学和女生日后辍学问题。`}</p>
      <p>{`下图显示，在撒哈拉以南非洲，以及孟加拉国、老挝和尼泊尔等亚洲国家，只有不到一半的小学配备了男女有别卫生设施。在阿富汗，只有26%的小学、56%的初中和75%的高中配备了男女有别卫生设施。`}</p>
      <Figure id="SANITATION" mdxType="Figure" />
      <p>{`由于各国对卫生设施的界定有所不同，以上估值尚需谨慎解读。有些国家认为适当的卫生设施就是坑式厕所这样最基本的厕所，另一些国家则只认可能冲水的厕所，而还有一些只承认达到国家标准的厕所。`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1a" mdxType="IndicatorReference">
      <p><strong parentName="p">{`电力`}</strong>{`是优质学习环境的一项基本要求，而许多国家达不到这一要求。在低收入国家，72%的小学和53%的高中没有通电。`}</p>
      <p>{`如下图显示，在阿富汗、基里巴斯和尼泊尔，通电的小学不到一半。在一些撒哈拉以南非洲国家，极少有中小学通电：马达加斯加学校通电的比例为8%，中非共和国和乍得仅有4%。没有电力，照明便会不足，教师也无法使用电脑进行管理或培训。缺电意味着不能通风、降温或供暖，可能导致教室不适宜教学。`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1b" mdxType="IndicatorReference">
      <p>{`缺电也意味着无法利用科技和`}<strong parentName="p">{`互联网`}</strong>{`。但是，电力并不是拖累互联网普及的唯一因素。在中等收入国家，43%的小学和30%的高中已经通电但未联网。即便学校有了电力供应，电涌和电压降低现象也比较常见。互联网接入取决于国家电信基础设施、地理条件和学校支付服务费的能力。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="ELECTRIC" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="EGY" mdxType="ScrollySection">
          <p>{`在`}<strong parentName="p">{`埃及`}</strong>{`，所有小学都已通电，但只有71%联网。同样的情况还发生在阿塞拜疆（64%）、约旦（34%）。`}</p>
        </ScrollySection>
        <ScrollySection id="ECU,URY" mdxType="ScrollySection">
          <p>{`许多拉丁美洲国家的小学既未通电也未联网。称得上两者都有的学校在`}<strong parentName="p">{`厄瓜多尔`}</strong>{`只有41%，在巴拉圭有5%。`}<strong parentName="p">{`乌拉圭`}</strong>{`是个例外，当地100%的小学既通电又联网。`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "暴力"
    }}>{`暴力`}</h2>
    <p>{`校园暴力行为或威胁可包括心理、身体或性暴力等多个层面，既可能发生在校园里，也可能发生在上学路上、家里或者网络空间。发生这种情况通常是由于存在不平等的权力结构，并且受到负面性别观念和刻板印象的影响。`}</p>
    <p>{`尽管人们对枪击等极端事件更加关注，但是对儿童和青少年教育构成最大负面影响的欺凌等暴力形式却更为常见，又通常不受关注。有关这些问题的数据依然不足，因为在收集数据时提出的问题涉及对暴力行为的定义不同，报告的时间范围不同，并且回答选项、隐私安排或道德规范方面不具有可比性。此外，对仇视同性恋的欺凌行为等涉及禁忌的暴力形式，也存在报告不足的问题。`}</p>
    <QualityFramework highlighted="school" description="教育质量框架详情" mdxType="QualityFramework" />
    <IndicatorReference id="4.a.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`欺凌`}</strong>{`是记录在案最多的校园暴力形式，可以包括身体暴力、言语辱骂，以及通过羞辱和排斥造成心理伤害的意图。下图显示，在大多数国家，青少年中至少有三分之一是欺凌的受害者，男生往往多于女生。`}</p>
      <Figure id="BULLY_FIGHT" mdxType="Figure" />
    </IndicatorReference>
    <p><strong parentName="p">{`身体暴力`}</strong>{`包括在校殴斗，携带枪支、刀具或棍棒等武器到校或受其威胁，充当帮派成员或同伙，或遭受教职员工施加的身体暴力。`}</p>
    <p>{`26个国家报告称，2012—2017年，超过30%的青少年参加过打架斗殴，在图瓦卢这一比例超过70%。校园暴力也是巴林、科威特和阿曼等海湾国家面临的一个严重问题。`}</p>
    <h2 {...{
      "id": "结论"
    }}>{`结论`}</h2>
    <p>{`相比之下，关于教师指标的国际可比数据少之又少。报告教师基本人数的国家较少，而且也不包含教学时间、教师担任行政职务和其他错综复杂的事项。需要制订关于受过培训的教师的各类标准，以利于明确找出差距并将这一问题提升到全球层面。`}</p>
    <p>{`本文表明，提供“兼顾儿童、残疾和性别平等”的教育设施和“为所有人提供安全、非暴力、包容和有效的学习环境”，在许多国家都是一道难题。同时，衡量这方面取得的进展又很困难，因为相关全球指标并非单一的衡量标准，而是一套多维度指标。`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.oecd-ilibrary.org/education/education-at-a-glance_19991487"
          }}>{`Education at a glance – OECD`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theirworld.org/news/safe-schools-report-reveals-crises-challenges-action-needed-to-educate-children"
          }}>{`Safe Schools Report – Their World`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/fs46-more-than-half-children-not-learning-en-2017.pdf"
          }}>{`More Than One-Half of Children and Adolescents Are Not Learning Worldwide – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232107"
          }}>{`School-related gender-based violence is preventing the achievement of quality education for all`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;