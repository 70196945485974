
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Finance";
export const lead = "One in three countries did not reach either of the finance benchmarks set in the Education 2030 Framework for Action.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`The ambitious SDG education targets will not be met without additional resources, especially in countries that are the furthest behind.`}</p>
    </blockquote>
    <p>{`The GEM report calculates that in order to achieve the 2030 target for universal basic education, i.e. pre-primary, primary and secondary education, low- and lower-middle-income countries need to spend US$504 billion or 6.3% of their gross domestic product (GDP) annually.`}</p>
    <p>{`Having more resources is not necessarily enough, though; countries spending the same amount in terms of income per capita can have wildly different education results depending on whether they are spending effectively, efficiently and equitably. For instance, out of 78 low- and middle-income countries with available information, only 17 - or one in five - maintained a strong equity focus through financing policies. They were mostly upper-middle-income and Latin American countries.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`Every year, of the US$ 5 trillion spent on education worldwide, only 0.5% is spent in low income countries, while 66% is spent in high income countries, even though the two groups have a roughly equal number of school-age children.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`In the median country, `}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`households`}</HighlightByPalette>{` are covering 30% of the cost of education. In fact, households pick up a much larger education bill in low-income countries (39%) than in high-income countries (15%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`Although `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`donors`}</HighlightByPalette>{` contribute 13% of spending in low income countries …`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`… `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`donors'`}</HighlightByPalette>{` contributions account for less than 1% of total spending globally, which is barely visible in absolute amounts.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "public-expenditure"
    }}>{`Public expenditure`}</h2>
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`The `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
        }}>{`Education 2030 Framework for Action`}</a>{` has set two key finance benchmarks for governments. It calls for them to:`}</p>
      <ul>
        <li parentName="ul">{`allocate at least 4% to 6% of GDP to education, and/or`}</li>
        <li parentName="ul">{`allocate at least 15% to 20% of public expenditure to education.`}</li>
      </ul>
      <p>{`Globally, governments have not strayed far from these norms. The median government spending is 4.4% of GDP and 14.1% of total public expenditure. Governments in poorer countries with low capacity to generate domestic resources but with large cohorts of children tend to spend less on education as a share of GDP but more as a share of total government spending.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`In Sub-Saharan Africa, government spending on education represents 16.8% of total public expenditure, but 3.8% of GDP. Conversely, Europe and Northern America meets the benchmark as a share of GDP (4.7%) but allocates a low share of total spending to education (11.8%).`}</p>
      <p>{`But, altogether, about one in three countries did not meet either benchmark. Play these data over time to see how spending fluctuates over the years.`}</p>
    </IndicatorReference>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <p>{`The main source of government revenue is taxation, even in low-income countries receiving a high share of income from grants (i.e. aid). Overall, average tax revenue as a share of GDP was 14% in low-income, 18% in lower-middle-, 22% in upper-middle- and 33% in high-income countries. Even if all countries assigned the same priority to education in their budget, for instance, countries that raise more taxes and other domestic resources will spend more on education.`}</p>
    <p>{`Having said that, the large variation in the amount spent on education between countries with otherwise similar characteristics is an indication that there is nothing pre-determined in these relationships. Some countries choose to spend more on education to achieve their objectives. And we should highlight those that do not.`}</p>
    <h2 {...{
      "id": "aid-expenditure"
    }}>{`Aid expenditure`}</h2>
    <p>{`While more financing is needed, it cannot come only from the most challenged countries themselves. International solidarity is called for.`}</p>
    <p>{`The trajectory of aid to education since 2002 is characterised by three stages: a considerable increase up to 2010, a slight decrease until 2014, and then an increase in the following years. Total aid to education, including imputations from aid devoted to overall budget support, reached US$18.1 billion in 2020. In real terms, it increased by 15% compared to 2019, but when aid derived from direct budget support flows are excluded, earmarked aid to education did not change in 2020.`}</p>
    <p>{`Education was not prioritized in total aid flows in 2020 in comparison to other sectors. The share of earmarked aid to education has declined over the past 10 years, reaching 9.7% in 2020. `}</p>
    <p>{`The share of aid to secondary education is increasing (from 13% in 2005 to 21% in 2020), at the expense of basic education (from 42% to 40%) and post-secondary education (from 46% to 39%).`}</p>
    <Figure id="AID" mdxType="Figure" />
    <IndicatorReference id="4.5.5" mdxType="IndicatorReference">
      <p>{`The `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`share of total aid to education`}</HighlightByIndex>{` going to low-income countries has remained relatively stable between 2002 and 2020 at 21%.`}</p>
      <p>{`Despite one-third of out-of-school children of primary school age being in the poorest countries, the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`share of total aid to basic education`}</HighlightByIndex>{` going to low-income countries fell from 28% in 2009 to 26% in 2020.`}</p>
      <Figure id="AID_LICS" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Donors also spend very different amounts per child in countries at the same level of education development. Liberia receives US$ 80 per child of primary school age, nearly 10 times the regional average (US$ 9) and five times the median (US$ 18).`}</p>
    <p>{`Looking at bilateral and multilateral donors from 2002 until 2019 in the graph below shows which are spending equitably. The percentage of overall aid to education that the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`United Kingdom`}</HighlightByIndex>{` is spending on basic education, for instance, has fallen from a peak of 81% in 2006 to 46% in 2020. The share of total aid to education that Sweden is allocating to low-income countries has fallen from a peak of 45% in 2007 to 27% in 2020.`}</p>
    <p>{`As early as 1970, a UN resolution set the target for `}<a parentName="p" {...{
        "href": "https://www.oecd.org/dac/stats/the07odagnitarget-ahistory.htm"
      }}>{`Official Development Assistance (ODA) at 0.7%`}</a>{` of Gross National Income (GNI). The GEM Report estimates that if this target had been met, an additional US$3.3 trillion in ODA would have been disbursed between 1990 and 2016. Of the 30 OECD DAC donors, only Denmark, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Germany`}</HighlightByIndex>{`, Luxembourg, Norway, Sweden and the UK were spending more than 0.7% in 2020, with the UK announcing that it will reduce its spending to 0.5% from 2021.`}</p>
    <Figure id="AID_TARGETING" mdxType="Figure" />
    <p>{`The following table shows the aid flows from the world's top ten `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`donors`}</HighlightByIndex>{` to their top ten `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`recipients`}</HighlightByIndex>{`, by level of education.`}</p>
    <p>{`Germany may appear to be the largest single donor to education in these countries, for example. Yet the large share of this aid going to post-secondary education is an indication that these resources are largely spent on foreign students – mostly from China and India – who study in Germany, rather than necessarily contributing to equity and quality at the basic education level in the poorest countries.`}</p>
    <Figure id="AID_FLOWS" mdxType="Figure" />
    <p>{`In total, around a quarter of donors' aid to basic education is allocated to sub-Saharan Africa, home to over half (55%) of the world's out of school children. This graph shows which countries the top donors to education are prioritising and at what level.`}</p>
    <p>{`France gave US$ 113 million to the top ten recipient countries for aid in the region, for instance, but the vast majority of this went to post-secondary education, likely in the form of scholarships for African students to study in France.`}</p>
    <p>{`Global coordination by donors is important in the education sector. However, in 2019 the average number of donors in low- and middle-income countries was 25, with more than 20 countries dealing with more than 30 donors. This might place added pressure on governments to manage these relationships.`}</p>
    <Figure id="AID_FLOWS_SSA" mdxType="Figure" />
    <h2 {...{
      "id": "household-expenditure"
    }}>{`Household expenditure`}</h2>
    <p>{`Households spend a significant amount to support their children’s education – and more so in poorer than in richer countries.`}</p>
    <p>{`This spending can be in the form of tuition and other fees, but can also be for education goods and services, of which some are required (e.g. textbooks) and others are not (e.g. private lessons).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Where such data is standardized across countries, spectacular evidence emerges of the contribution that households are making to total education spending. This can change the way countries’ efforts on education are understood.`}</p>
      <p>{`On average, households spend 1.9% of GDP on education, while governments spend 4.5% of GDP. This means households account for about 30% of total education spending. Median shares range from 15% in high-income countries to 39% in low- and lower-middle-income countries, reaching 38% in Central and Southern Asia and 37% in sub-Saharan Africa, but only 12% in Europe and Northern America.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,NGA,FRA" mdxType="ScrollySection">
          <p>{`For example, in 2019, `}<strong parentName="p">{`Nigeria`}</strong>{` had one of the lowest levels of `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`government expenditure`}</HighlightByIndex>{` as a percentage of GDP. Yet, once `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`household contributions`}</HighlightByIndex>{` are taken into account, Nigeria’s total national expenditure on education as a percentage of GDP was similar to that of `}<strong parentName="p">{`France`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,PAK,DEU" mdxType="ScrollySection">
          <p>{`The government of `}<strong parentName="p">{`Pakistan`}</strong>{` spent about 2.5 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`percentage points of GDP`}</HighlightByIndex>{` less on education than did `}<strong parentName="p">{`Germany`}</strong>{` – the blue bar –, but Pakistan spent overall more than Germany because its `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`households`}</HighlightByIndex>{` spent more than three percentage points of GDP on education – the red bar.`}</p>
        </ScrollySection>
        <ScrollySection id="relative,BGD,LBN,HTI" mdxType="ScrollySection">
          <p>{`The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`share of households`}</HighlightByIndex>{` in total education expenditure rises to over 70% in some countries, including `}<strong parentName="p">{`Bangladesh`}</strong>{`, `}<strong parentName="p">{`Haiti`}</strong>{` and `}<strong parentName="p">{`Nigeria`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`The Global Education Monitoring Report team has long argued that understanding global education spending requires a joint examination of the three main sources of education funding: domestic public funding, external public funding and private funding. There is therefore a need for timely and consistent data from low- and middle-income countries on how much is being spent on education and from what sources.`}</p>
    <p>{`In addition to increasing the funds available for education, we also need to strengthen equitable distribution. In order to achieve the global education goal, SDG 4, it is important that countries and donors take responsibility and fulfil their commitments.  `}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/financing-for-equity"
          }}>{`Unlocking Financing for Equity in Education`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://education-profiles.org/"
          }}>{`Profiles Enhancing Education Reviews (PEER)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2023efw"
          }}>{`Education Finance Watch 2023`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/3141"
          }}>{`COVID-19 is a Serious Threat to Aid to Education Recovery`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;