import React from "react";
import { lineChartDefaultStyles as styles } from "./styles";

import { baseLineColor } from "../utils";

const YTick = React.memo(
  ({
    width,
    label,
    transform,
  }: {
    width?: number;
    label?: string;
    transform: string;
  }) => (
    <g transform={transform}>
      <line
        css={styles.axisYLine}
        x2={width}
        style={{
          stroke: baseLineColor,
        }}
      />
      <text css={styles.axisLabel} dy="-3px">
        {label}
      </text>
    </g>
  )
);

export default YTick;
