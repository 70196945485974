import css from "@emotion/css";
import React from "react";
import { mkStyles } from "../../hooks";

const useStyles = mkStyles((theme) => {
  return {
    root: css`
      background-color: ${theme.activeTheme.background.light};
      padding: ${theme.spacing.base8(3)} ${theme.spacing.base8(2)};
      margin-left: ${theme.spacing.base8(-2)};
      margin-right: ${theme.spacing.base8(-2)};

      & > * {
        color: ${theme.activeTheme.background.dark};
        font-weight: bold;
      }
    `,
  };
});

export const InfoBox = ({ children }: $PropsWithChildren) => {
  const styles = useStyles();
  return <div css={styles.root}>{children}</div>;
};
