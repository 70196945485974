
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Learning";
export const lead = "In most countries in sub-Saharan Africa, over half the students in early grades achieve below the minimum proficiency level in reading.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Getting children into school is not enough. Without taking away from the many other worthwhile purposes that schools do and must pursue, students need to be able to read and to carry out basic mathematics. These are the foundation for other learning, for individual empowerment and wellbeing, as well as for social, political and economic outcomes.`}</p>
    </blockquote>
    <h2 {...{
      "id": "children-and-youth"
    }}>{`Children and youth`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Many children in some of the world’s poorest countries lack basic reading and mathematics skills.
Early grade reading assessments in northern states of Nigeria, such as Kaduna and Sokoto, and in provinces of the Democratic Republic of Congo, such as Equateur and Katanga, for instance, suggest that over 80% of grade 3 students in primary school could not even read a `}<strong parentName="p">{`single word`}</strong>{` of connected text.`}</p>
      <Figure id="EGRA" mdxType="Figure" />
    </IndicatorReference>
    <p>{`This data does not cover the children who are not in school. Surveys that are carried out at people's homes can capture the skills of both children who attend school and those who do not.`}</p>
    <p>{`For instance, the graphs below look at different regions in Pakistan and rural India, based on citizen-led assessments.`}</p>
    <p>{`The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`red lines`}</HighlightByIndex>{` show the percentage of children `}<strong parentName="p">{`in school`}</strong>{` that have basic reading skills by grade, while the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`blue lines`}</HighlightByIndex>{` show the percentage of all children who have basic reading skills, `}<strong parentName="p">{`whether in school or not by the age that corresponds to their grade`}</strong>{`.`}</p>
    <p>{`The gaps between the two lines are a reminder that in countries with high out-of-school rates, policy makers get only a partial picture if they only monitor learning of those in school. It is important to note that the monitored level of skill is at a very low level. Even in the best performing regions, at least some adolescents in secondary school cannot read a grade 2 text.`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`It is estimated that nearly 40% of children of primary school age do not have basic reading skills. This is true for 43% of children in Central and Southern Asia and 84% of children in sub-Saharan Africa but only 3% of children in Europe and Northern America.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.1.0" label="4.1.0" mdxType="IndicatorReference">
      <p>{`In many countries, there is no guarantee that children will be able to read even if they have completed primary school. Globally, of those who completed primary education, four out of ten did not have basic reading skills. This rises to out of ten in sub-Saharan Africa, as the graph below shows.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`The `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`grey sections`}</HighlightByIndex>{` show the percentage of children who complete primary school but do not reach a minimum level of proficiency in reading. The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`red sections`}</HighlightByIndex>{` show those children who do not complete primary education, and the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`blue sections`}</HighlightByIndex>{` those students who complete primary and reach a minimum proficiency.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Looking at the country level, in Chad and Nigeria, about 90% of young people who left school after 5 or 6 years were not literate. Some countries have much better rates, however. Almost all those who left school after grades 5 or 6 in Burundi, Costa Rica and Honduras were literate, for instance.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="LITBYYRS" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="KEN" mdxType="ScrollySection">
        <p>{`In `}<strong parentName="p">{`Kenya`}</strong>{`, for instance, one in four are literate `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`with up to 4 years of schooling`}</HighlightByIndex>{`, but this jumps to three out of four for those who have `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`5-6 years of schooling`}</HighlightByIndex>{` – the equivalent of a primary education.`}</p>
      </ScrollySection>
      <ScrollySection id="NER" mdxType="ScrollySection">
        <p>{`In `}<strong parentName="p">{`Niger`}</strong>{`, barely any children emerge `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`after 4 years of schooling`}</HighlightByIndex>{` able to read, but more than one in three develop basic literacy skills if they make it through the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`full course of primary education`}</HighlightByIndex>{`.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <p>{`In order to be able to compare learning across countries, assessments or tests need to be matched with international standards using one common definition. Such standards have recently been put in place for cross-national assessments where students from several countries take the same test. But fewer such mappings are available yet for national assessments.`}</p>
    <p>{`One assessment, the Trends in International Mathematics and Science Study (TIMSS), takes place every four years and allows us to look at progress over time among grade 4 and grade 8 students. It shows where progress has been particularly slow. In Egypt, for example, the percentage of grade 8 students who met the minimum proficiency for mathematics increased from 24% in 2003 to only 27% in 2019.`}</p>
    <p>{`Some countries, such as `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Malaysia`}</HighlightByIndex>{`, have even seen the share of students achieving minimum learning levels decrease over time.`}</p>
    <p>{`In other countries, such as `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Bahrain`}</HighlightByIndex>{`, progress has been fast – the share of students with minimum proficiency more than tripled between 2003 and 2019.`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Contrary to most people's expectations, the gender learning gaps are not wide – spanning no more than ten percentage points for nearly all countries in primary education, for instance.`}</p>
      <p>{`But learning gaps between countries are huge. In sub-Saharan African countries, which tend to fall towards the lower end of reading skills in primary education, the share of students attaining minimum proficiency spans from just 4% in Burundi to 76% in Gabon (both results from PASEC 2019).`}</p>
      <p>{`In mathematics in primary education for Latin American countries, rates range from just 2% in the Dominican Republic to 39% in Peru (both results from LLECE 2019).`}</p>
      <Figure id="ASSESS" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "adults"
    }}>{`Adults`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`Between 2000 and 2020, the percentage of adults who are literate increased from 81% to 87% globally, while the percentage of youth who are literate increased from 87% to 92%.`}</p>
      <p>{`Despite these improvements, illiteracy remains widespread in many countries, especially among women. In Latin America and the Caribbean, stalled progress at relatively high levels reflects pockets of deeply disadvantaged “hard to reach” groups. In Paraguay, for instance, the female elderly literacy rate has not changed much for the past 10 years, at around 73%.`}</p>
      <p>{`In 18 countries in Sub-Saharan Africa, over half of adult women (25-64 year-olds) cannot read. Progress has either stalled at very low levels as in Mali, where less than 17% can read, or been slow as in Congo, which has improved from 72% to 74% over ten years.`}</p>
      <p>{`Globally, if more adults are literate today than they were, it is mostly because more adults went to school when they were young rather than because adults who did not go to school had a second chance to learn. To verify this claim, the below graph shows data that follows the literacy skills of a given cohort – or group – as it grows older.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LITCOHORT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In most countries, literacy among a particular `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`cohort of adults`}</HighlightByIndex>{` stagnated or even declined over time as skills were underutilized.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In `}<strong parentName="p">{`Haiti`}</strong>{`, the blue line shows that the literacy rate of `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`women aged 20–34 years in 2000`}</HighlightByIndex>{`, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`26-40 years in 2006`}</HighlightByIndex>{` and `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`37–61 years in 2017`}</HighlightByIndex>{` slightly decreased. And yet, because more young women are becoming literate, the red line shows that `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`the literacy rate among 20-34 year-old women`}</HighlightByIndex>{` has been increasing over time.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`The literacy skills that these assessments are measuring are very basic. They show if people are able to read a simple sentence. Functional literacy, whereby people can read and interpret written instructions, formal letters, or contracts is a different matter.`}</p>
      <p>{`Few international surveys collect functional literacy data, much less on a regular basis.`}</p>
      <p>{`From the data we do have, we can see that more than 1 in 10 urban adults in Colombia and Viet Nam and 1 in 4 in Bolivia are not functionally literate (i.e. they fall below Level 1 of proficiency) in functional literacy.`}</p>
      <p>{`In `}<strong parentName="p">{`Ghana`}</strong>{`, even among urban adults, only 39% have functional literacy skills.`}</p>
      <Figure id="LITPROF" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Although SDG 4 has shifted emphasis on monitoring learning, many countries still do not report towards the learning outcome indicators on reading and mathematics. Even countries that want to start assessing and reporting on learning often face obstacles preventing them from adopting effective and cost efficient solutions. But the evidence we do have suggests that most students in the world’s poorest countries do not achieve minimum proficiency in the most basic skills.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/32553"
          }}>{`Ending learning poverty – what will it take? – World Bank`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000225660"
          }}>{`EFA GMR 2013/4: Teaching and learning: Achieving quality for all`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/20/the-learning-assessment-market-pointers-for-countries-part-2/"
          }}>{`The learning assessment market - pointers for countries`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;