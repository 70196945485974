
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Does reading speed matter?";
export const lead = "If reading comprehension is to be improved, the means to that end – the mechanics of reading – must be understood.";
export const layout = "focus";
export const article = "learning";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Does reading speed matter?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=286" mdxType="FocusDetails">
      <h1 {...{
        "id": "does-reading-speed-matter"
      }}>{`Does reading speed matter?`}</h1>
      <p>{`Learning outcomes have risen to the top of the post-2015 international education agenda with reading comprehension the measure receiving the most attention. But if reading comprehension is to be improved, the means to that end – the mechanics of reading – must be understood.`}</p>
      <p>{`Comprehension has a non-linear relationship with reading speed. Read too slowly, and you forget how a sentence started by the time you reach the end. But read too quickly, and you start skipping over information. While both effects are obvious at the extremes, the extent to which reading speed matters as a benchmark for learning is contested.`}</p>
      <p>{`Reading speed differentials among individual students mirror their test score gaps. Cognitive correlates of higher reading speed include working memory and fluid intelligence (Johann et al., 2020). A positive correlation between reading speed and reading comprehension at the individual level has been empirically found in a diverse range of languages, including Turkish (Soysal, 2022b) and German (Johann et al., 2020).`}</p>
      <p>{`Yet across countries, high levels of comprehension are achieved at different reading speeds. Some studies question the usefulness of reading speed as a metric or proxy for learning (Dowd and Bartlett, 2019; Graham and Ginkel, 2014), although the methodology of such analyses and their consistency with cognitive research are disputed (Abadzi and Centanni, 2020).`}</p>
      <p>{`There are physiological limits to the speed at which the human eye can fixate and scan each word in a text (Seidenberg, 2018). At some point, reading faster means no longer processing every word. Reading, ‘the processing of textual information so as to recover the intended meaning of each word, phrase, and sentence’, turns into skimming – ‘`}{`[moving]`}{` one’s eyes through the text to find a specific word or piece of information or to get a general idea of the text content’ (Rayner et al., 2016, p. 5), which is a distinct skill in its own right.`}</p>
      <p>{`Efforts at ‘speed reading’ have a long and controversial history. There is a lack of evidence for the most optimistic claims of fantastic speeds with full comprehension (Rayner et al., 2016; Seidenberg, 2018), The question remains whether speed reading is even desirable (Tsvetkova, 2017; Wolf and Klein, 2022). Comprehension and retention inevitably deteriorate at extreme speeds. The fastest confirmed reading speed in English with comprehension achievable under laboratory conditions, with words displayed sequentially at the same point with no need for eye movement, and at an optimized print size, exceeds no more than 800 words per minute (wpm) (He et al., 2018).`}</p>
      <p>{`The average words per minute for reading in the adult population, and what is expected of learners at different grade levels and ages, is far lower. Based on a meta-analysis of 190 studies, the silent reading speed for English non-fiction of most adults falls in the range of 175 to 300 wpm, with an average of 238 wpm (Brysbaert, 2019). Reading fiction is slightly faster, reflecting that it has, on average, shorter words. `}</p>
      <p>{`Some studies focus on individual correlates. Reading is likely to benefit from many of the same factors that confer educational advantage in general, such as the availability of books in the home. But when, for instance, positive attitudes towards reading and higher reading speed go together, the direction of causality remains unclear. Do keen readers improve their speed by reading more, or are fluent readers more motivated to use their skill? Perhaps both work to create a virtuous circle. More telling is the effect of improvements in reading speed. Grade 4 to 6 students who received 28 hours of instruction in techniques to improve their reading speed reported greater enjoyment of reading, greater motivation caused by the ability to read more books in the same time, and further reported that they were no longer intimidated by long books (Soysal, 2022a).`}</p>
      <p>{`Individual differences in reading speed tend to be larger than by age or grade. Despite this, reading speed data by age or grade reveal clear developmental patterns as children learn to read. Similar to findings from the United States (Spichtig et al. 2016), a study in Brazil found that the average reading speed continued to improve from grades 2 to 9, albeit more slowly beyond grade 7 (Alves et al., 2021). This pattern is also reflected in grade norms (Hasbrouck and Tindal, 2017).`}</p>
      <FocusImage size="auto" src={"24-readingspeed_1.png"} alt="Average reading speed continues increasing through primary school" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=287" mdxType="FocusImage" />
      <p>{`The extent to which reading speed depends on word decoding or language comprehension changes with increasing fluency and, by implication, school grade (Carretti et al., 2020). Reading speed continues to increase up to school-leaving age and starts to decline slowly around age 40, by some 10% by age 70 (Brysbaert, 2019). However, the decline is related to changing vision (Liu et al., 2017) and not necessarily cognition.`}</p>
      <p>{`Measured reading speed drops further if only counting words read correctly. Oral reading fluency, as measured by the Early Grade Reading Assessment (EGRA), an international assessment tool, indicates the number of words correctly read aloud, which is inherently slower than silent reading. The instructions for EGRA are to not try to read as fast as possible. But correctness may add little extra statistical information beyond speed (Williams et al., 2011), because faster readers also tend to be more fluent. Correctness still offers some advantages as a measure. For instance, while outlier scores may be produced in the measurement of silent reading, when readers get stuck on a difficult word, a prompt can be given if they are reading aloud. Oral fluency may also be more closely related to comprehension; testing it allows the collection of information on errors (Piper and Zuilkowski, 2015). Crucially, however, such a link with comprehension does not persist across languages, calling into question the use of English reading fluency as a proxy target for learning among non-native speakers in sub-Saharan Africa, even when the language of instruction is English (Piper et al., 2016).`}</p>
      <p>{`Even more challenging is the standardization of reading speed measurement across languages, and especially across different scripts and writing systems. Much research on reading deals specifically with reading English. This is unfortunate, not only in terms of cultural bias, but also because the linguistic properties of English are far from typical. In particular, English orthography is highly irregular. Unlike in Spanish, Korean and many other languages, it is not possible to reliably predict how an unfamiliar English word is pronounced based on written representation alone.`}</p>
      <p>{`Directly comparable or not, reading speed measures have been adapted to various languages. The International Reading Speed Texts (IReST) consist of 10 short paragraphs of around 150 words each and are available for 17 languages (Trauzettel-Klosinski et al., 2012), including languages with non-Latin scripts including Greek (Gleni et al., 2019) and Chinese (Wang et al., 2018). A review of 232 reading speed studies (Brysbaert, 2019) in various languages not limited to IReST suggests that average reading speed differs significantly in different countries and languages.`}</p>
      <FocusImage size="auto" src={"24-readingspeed_2.png"} alt="Average reading speed differs significantly in different countries and languages" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=288" mdxType="FocusImage" />
      <p>{`These differences are measured in words per minute. But this raises the question of how to account for differences in the definition of a ‘word’, for instance with respect to prefixes, suffixes and whether articles and pronouns appear as separate words. Approaches to accounting for such effects include counting each component of compound words (Abadzi, 2012). In Chinese, word boundaries are well-defined even if, in writing, spaces between words are not normally inserted or indeed required for fluent reading (Ling and Liu, 2021). In contrast to English, which uses between five and six letters per word on average, Chinese words typically consist of only one or two complex characters (Brysbaert, 2019).`}</p>
      <p>{`In summary, languages and writing systems differ in how much information is encoded in a single word, and in verbosity, that is, how many words are required to express the same information. Verbosity and average reading speed are correlated, meaning that each word tends to take longer to read in languages that encode more information per word (Brysbaert, 2019). As a result, how long it takes to read the same information differs less between languages than the reading speed measured in words per minute.`}</p>
      <p>{`A notable outlier in silent reading speed is Arabic. It is argued that Arabic is inherently slow to read because formal Arabic customarily omits the signs for short vowels in handwriting and print except in children’s books (to help early readers) and religious texts (for unambiguity). As a result, reading unvowelled Arabic requires a significant amount of simultaneous grammatical decoding. Moreover, native Arabic speakers are almost universally native speakers of various Arabic dialects that differ from standard Arabic in vocabulary, grammar and pronunciation. Accordingly, formal Arabic is de facto their second language. The fact that reading speed points to the relative difficulty of written Arabic may be one explanation of why adult literacy in many Arab countries is lower than would be expected, given the levels of income and schooling.`}</p>
      <p>{`A study of the reading speed achieved by learners of Arabic, who are not native speakers of a spoken variant of colloquial Arabic, at different proficiency levels showed interesting nuance (Midhwah and Alhawary, 2020): fully vowelled text is generally read more slowly than unvowelled text, but both variants are read faster by students learning from vowelled textbooks. The reading speed of Arabic is also slower than that of Hebrew, which uses a different script but is otherwise linguistically closely related (Eviatar et al., 2019).`}</p>
      <p>{`Measurements of reading speed are not perfectly standardized, even when some parameters are fixed – for example, reading silently or aloud, counting all words or only those read correctly, and reading text or isolated words (or even non-words). The technical properties of the material being read also matter to some extent, including font size, colour and contrast (Wallace et al., 2022; Zhu, 2022), as well as environmental factors such as distance and lighting (Jung and Choi, 2021).`}</p>
      <p>{`The effect of digital display technology on reading speed is ambiguous, however. Reading speeds on tablets and e-ink readers need not be slower than paper (Moys et al., 2018; Sackstein et al., 2015), but have no clear advantage either. Malay students were found to read faster on paper, but demonstrated better comprehension on screens (Tajuddin and Mohamad, 2019). A meta-analysis found that the consistent advantage of paper for reading speed has not declined over time with increasing digitalization (Delgado et al., 2018). `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;