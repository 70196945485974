import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { useColorScaleVDP, VerticalDotPlotAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { useConfig } from "../config";
import { metadata } from "../data/data_quality_fig_ELECTRIC";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  getLevelName,
  mkGemEntityDecoder,
  ordLevel,
  useButtonGroupState,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useNamedEntitiesWithLevel,
} from "../domain";
import { useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, identity, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_quality_fig_ELECTRIC";

const DEFAULT_LEVEL = "primary";

const Indicator = io.keyof({
  "SCHBSP.WINTERN": null,
  "SCHBSP.WELEC": null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  "SCHBSP.WINTERN": t("fig.quality.ELECTRIC.SCHBSP.WINTERN")`Internet`,
  "SCHBSP.WELEC": t("fig.quality.ELECTRIC.SCHBSP.WELEC")`Electricity`,
};

export const Data = mkGemEntityDecoder(["year", "level"], {
  value: io.number,
  ind_id: Indicator,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const env = useConfig();
  const [selectedLevel] = useButtonGroupState("level");
  const { activeSection } = useReadScrolly();

  const entityData_ = useNamedEntitiesWithLevel(dataRaw);
  const entityData = React.useMemo(() => {
    const data = entityData_.map((x) => ({
      ...x,
      ind_name: i18n._(indicatorLookup[x.ind_id]),
    }));
    type Dtm = typeof data[number];

    return Ar.sortBy([
      Ord.contramap((x: Dtm) => x.level)(ordLevel),
      Ord.contramap((x: Dtm) => (x.ind_id === "SCHBSP.WELEC" ? 0 : 1))(
        Ord.ordNumber
      ),
      Ord.contramap((x: Dtm) => x.value)(Ord.ordNumber),
    ])(data);
  }, [i18n, entityData_]);
  const countryData = useCountryEntities(entityData);
  const levelControlItems = useFigureControlItems(
    countryData,
    (x) => x.level,
    (x) => getLevelName(i18n, x.level),
    { ord: ordLevel }
  );

  const highlightItems = pipe(
    activeSection,
    O.map((x) => x.split(",")),
    O.chain((strIds) =>
      Ar.array.sequence(O.option)(
        strIds.map((strId) =>
          getCountryMeta(env.countries, countryIdIso.wrap(strId))
        )
      )
    ),
    O.map((xs) => xs.map((meta) => meta.country)),
    O.chain((xs) => An.fromArray(xs))
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "level",
      {
        type: "ButtonGroup",
        selected: DEFAULT_LEVEL,
      },
      levelControlItems
    );
  }, [actions, levelControlItems]);

  const chartData = React.useMemo(() => {
    return unsafeFromArray(
      countryData.filter((x) => x.level === selectedLevel)
    );
  }, [countryData, selectedLevel]);
  type Datum = typeof chartData[number];

  const getX = React.useCallback((x: Datum) => x.entity_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.ind_name, []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      showTooltip
      showLegend
      showYExtent={O.isNone(highlightItems)}
      dotStyle={"small"}
      labelStyleX="none"
      highlightItems={highlightItems}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_quality_fig_ELECTRIC.json"),
  csv: require("../data/data_quality_fig_ELECTRIC.zip"),
  xlsx: require("../data/data_quality_fig_ELECTRIC.xlsx"),
  metadata,
  Data,
  Chart,
});
