import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { max } from "d3-array";
import { scaleOrdinal } from "d3-scale";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_access_fig_OOSREL";
import {
  ChartRenderer,
  getLevelName,
  mkGemEntityDecoder,
  mkOrdRegion,
  ordLevel,
  regionIdIso,
  useButtonGroupState,
  useCheckboxState,
  useFigureControlItems,
  useFigureState,
  useNamedEntitiesWithLevel,
  useRegionEntities,
  useSingleSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { insertionOrderSet, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_access_fig_OOSREL";

const DEFAULT_REGION = "Sub-Saharan Africa";
const DEFAULT_LEVEL = "primary";

const Indicator = io.keyof({
  "OFST.REL": null,
  "ROFST.REL": null,
  "SAP.REL": null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  "OFST.REL": t("fig.access.OOSREL.OFST.REL")`Number of out-of-school children`,
  "ROFST.REL": t("fig.access.OOSREL.ROFST.REL")`Rate of out-of-school children`,
  "SAP.REL": t("fig.access.OOSREL.SAP.REL")`School age population`,
};

export const Data = mkGemEntityDecoder(["year", "value", "level"], {
  ind_id: Indicator,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedLevel] = useButtonGroupState("level");
  const [selectedRegion] = useSingleSelectState("region");
  const [showWorld] = useCheckboxState("toggleWorld");

  const entityData_ = useNamedEntitiesWithLevel(data);
  const entityData = React.useMemo(() => {
    return entityData_.map((x) => {
      const ind_name = i18n._(indicatorLookup[x.ind_id]);
      return {
        ...x,
        ind_name,
        category:
          x.entity_type === "world"
            ? `${x.entity_name}, ${ind_name}`
            : ind_name,
      };
    });
  }, [i18n, entityData_]);
  const regionData = useRegionEntities(entityData);
  const regionDataWithoutWorld = React.useMemo(
    () => regionData.filter((x) => x.entity_type !== "world"),
    [regionData]
  );
  const regionControlItems = useFigureControlItems(
    regionDataWithoutWorld,
    (x) => regionIdIso.unwrap(x.id),
    (x) => x.entity_name,
    {
      ord: mkOrdRegion(
        env.regions,
        (x: typeof regionDataWithoutWorld[number]) => x.id
      ) as unknown as Ord.Ord<string> /* This ord would need a RegionId, not a string */,
    }
  );
  const levelControlItems = useFigureControlItems(
    entityData,
    (x) => x.level,
    (x) => getLevelName(i18n, x.level),
    { ord: ordLevel }
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "level",
      {
        type: "ButtonGroup",
        selected: DEFAULT_LEVEL,
      },
      levelControlItems
    );

    actions.setSelectionControl(
      "region",
      {
        type: "SingleSelect",
        selected: pipe(
          regionControlItems,
          Ar.findFirst((x) => x.value === DEFAULT_REGION),
          O.alt(() => Ar.head(regionControlItems)),
          O.map((x) => x.value)
        ),
      },
      regionControlItems
    );

    actions.setToggleControl("toggleWorld", {
      type: "Checkbox",
      label: i18n._(t`Compare to world`),
      selected: false,
    });
  }, [i18n, actions, regionControlItems, levelControlItems]);

  const chartData = React.useMemo(() => {
    const region = pipe(
      regionData,
      Ar.filter(({ id, level }) =>
        pipe(
          selectedRegion,
          O.fold(
            () => false,
            (x) => x === regionIdIso.unwrap(id) && level === selectedLevel
          )
        )
      )
    );

    const world = regionData.filter(
      (x) => x.entity_type === "world" && x.level === selectedLevel
    );

    return showWorld ? [...region, ...world] : region;
  }, [regionData, selectedRegion, selectedLevel, showWorld]);

  const maxYear = React.useMemo(
    () => max(data, (x) => x.year) as number,
    [data]
  );

  // Hard-coded on demand
  const baselineYear = "2000";
  const yAxisLabel = pipe(
    Ar.head(chartData),
    O.map((x) => {
      const entity = x.entity_name;
      const level = x.level_name;
      return i18n._(
        t(
          "fig.access.OOSREL.yUnitLabel"
        )`(${baselineYear} = 100) ${entity}, ${level}`
      );
    }),
    O.toUndefined
  );

  const legendEntries = React.useMemo(
    () => insertionOrderSet(unsafeFromArray(chartData), (x) => x.category),
    [chartData]
  );

  const colorScale = React.useMemo(
    () =>
      scaleOrdinal(
        Ar.chunksOf(3)(M.discretePalette)
          // Careful: b or c could be undefined for different chunk sizes / palette lengths
          .flatMap(([a, b, c]) => [a, b, c, a, b, c])
      ).domain(legendEntries),
    [legendEntries]
  );

  return (
    <LineChart
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.l}
      tLabel={(s) => s}
      x="year"
      yAnnotations={[
        {
          type: "line",
          value: 100,
          label: " ",
        },
      ]}
      xTicks={[2000, 2010, maxYear]}
      yTicks={[0, 50, 150, 200, 250]}
      yNice={0}
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".0f"
      category="datum.category"
      yAxisLabel={yAxisLabel}
      colorLegend
      colorScale={colorScale}
      values={chartData}
      markStyle={(x) => (x.entity_type === "world" ? "muted" : "normal")}
      showTooltip
    />
  );
};

export default withFigureIO({
  url: require("../data/data_access_fig_OOSREL.json"),
  csv: require("../data/data_access_fig_OOSREL.zip"),
  xlsx: require("../data/data_access_fig_OOSREL.xlsx"),
  metadata,
  Data,
  Chart,
  size: "narrow",
});
