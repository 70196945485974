import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "WLGPI",
  title: /*i18n*/ {
    id: "fig.equity.WLGPI.title",
    defaults: `Parity index for selected indicators and characteristicsss`,
  },
  caption: /*i18n*/ {
    id: "fig.equity.WLGPI.caption",
    defaults: `Disparity accumulates over the course of the education trajectory at the expense of the poorest children.`,
  },
  source: /*i18n*/ {
    id: "fig.equity.WLGPI.source",
    defaults: `GEM Report team analysis based on household surveys`,
  },
};
