
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "School buildings have ample technology";
export const lead = "Schooling does not require a building, but buildings such as tents and temporary structures can provide ventilation, hygiene facilities and shelter from the natural elements.";
export const layout = "focus";
export const article = "quality";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="School buildings have ample technology" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=303" mdxType="FocusDetails">
      <h1 {...{
        "id": "school-buildings-have-ample-technology"
      }}>{`School buildings have ample technology`}</h1>
      <p>{`Schooling does not require a building, but buildings such as tents and temporary structures can provide ventilation, hygiene facilities and shelter from the natural elements. Appropriate technology can help improve the safety, sustainability, efficiency and speed of school construction.`}</p>
      <p>{`There is no shortage of sustainable building materials for schools, including wood, bamboo and mud bricks (Robles et al., 2015). In India, school walls are built from ‘bricks’ made of used plastic water bottles filled with sand (Manjarekar, 2019). Prototype schools in Malawi were constructed at one quarter the cost of a conventional school by using locally sourced timber and soil blocks, allowing lighting and ventilation without electricity (Arup, 2023). Materials technology has also provided non-toxic alternatives to water pipes and paint containing lead, and fireproofing containing asbestos.`}</p>
      <p>{`Innovation may be in the building technique as much as the materials. In Malawi, a single-classroom school for 50 students was 3D-printed in less than a day using concrete ‘ink’ that is less energy- and carbon-intensive than standard construction methods. Another pilot school was printed in Madagascar (Matchar, 2021). In Europe, the first 3D-printed schools may appear as part of the reconstruction efforts in Ukraine (Hanaphy, 2022). However, the high cost of 3D-printing equipment currently constrains the parallel construction of multiple sites (Pensulo, 2021). Historically, innovations in school construction have not matched proven methods such as reinforced concrete (Theunynck, 2009).`}</p>
      <p>{`An exception is emergency settings, which require modularity, mobility and rapid deployment not offered by conventional, permanent construction. In Türkiye, UNICEF procured 300 modular container classrooms to accommodate 60,000 Syrian refugee children. In Bangladesh, UNICEF supported the Ministry of Education and the Ministry of Disaster Management and Relief with the construction of learning centres from bamboo and sungrass (UNICEF, 2022b). Geodesic domes are an ingenious way of assembling thin-shelled dome-shaped structures out of a lattice of interconnected rods or bars that can withstand strong forces even when made out of relatively light or flexible materials. Because they are easy to transport and quick to set up, they are suitable for providing emergency shelter and schooling spaces in crisis situations (Friedrich Naumann Foundation, 2016; Solardome 2023).`}</p>
      <p>{`Classrooms can also be produced by converting shipping containers or similar prefabricated modules. In the United States, an industry survey in 2012 showed that some 5 million students were taught in 280,000 trailer classrooms (Baker, 2014). They made up 30% of all available classroom space in the Los Angeles Unified School District (Clough, 2015). While adequate in the short term, challenges arise when such structures end up being used indefinitely (Inside Science, 2009). Temporary structures are often used as a way to circumvent rules and regulations on air quality and other standards that apply to standard school buildings (Profita, 2014).`}</p>
      <p>{`Technology can also help protect buildings from natural disasters, which frequently destroy or damage schools. Suitable construction, adapted to the most prevalent hazards in a given school location, makes a difference. For instance, lightweight timber construction is beneficial in the case of an earthquake, but more vulnerable to damage from high winds (Arup International Development, 2013). In 2007, schools built to Peru’s new codes withstood the Pisco earthquake far better than older schools (Bastidas and Petal, 2012). Similarly, during the 2015 earthquake in Nepal, 7,000 schools were destroyed or damaged (GFDRR, 2015), but retrofitted school buildings largely remained intact (ADB, 2015).`}</p>
      <FocusImage size="auto" src={"25-schoolbuilding.png"} alt="Number of schools damaged or destroyed by major natural disasters 2010–23" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=305" mdxType="FocusImage" />
      <p>{`Technological innovations can alleviate other safety and well-being concerns. Some governments have taken steps to improve the ventilation of classrooms, an issue that became prominent during the COVID pandemic. In the Republic of Korea, the government mandated the installation of air cleaning systems and air quality sensors in classrooms, which can then be monitored by parents and school councils (Arin, 2019). Technology has also helped improve acoustic design and sound insulation (Shield and Richardson, 2018), which can prevent disturbances and improve long-term health (Klatte et al., 2013). In Florida, schools moving from non-compliant to new compliant buildings saw an increase in scores and pass rates on standardized tests (Lumpkin et al., 2014).`}</p>
      <p>{`Finally, technology can help optimize school construction planning. For example, 3D laser scanning can support modelling to inform sustainable refurbishment by efficiently capturing detailed information on the physical shape and dimensions of school buildings (Le, 2021). Virtual reality technology has been used to simulate fire emergency evacuations from a school building, taking into account the spread of fire and smoke as well as the dynamic movement of students and teachers (Cimellaro et al., 2019; Lorusso et al., 2022). Such studies can inform new school construction, the adaptation of existing buildings and the design of escape routes. Agent-based modelling, i.e. computer simulations used to study the interactions among people, things, places and time, can give insights into preventing stampede risks in emergencies by optimizing the design and placement of stairs and toilets in primary schools (Xie, 2018). `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;