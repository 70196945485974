import { ThemeProvider } from "emotion-theming";
import "isomorphic-unfetch";
import { AppProps } from "next/app";
import * as React from "react";
import { Frontmatter, mkAppLayout } from "../components/app-layout";
import * as MDXComponents from "../components/mdx";
import { RenderException } from "../components/render-exception";
import { HideGlobalSpinner } from "../components/spinner";
import { ConfigProvider } from "../config";
import { Exception } from "../domain";
import { AppStateProvider } from "../domain/state";
import { getFigure } from "../figures";
import { useQueryParamsWithDefault, useThemeFor } from "../hooks";
import { FocusVisibleManager } from "../lib/focusVisibleProvider";
import { mkMDXProvider } from "../lib/mdx-provider";
import { LocaleProvider } from "../locales";
import { O, pipe } from "../prelude";
import { REnv } from "../routes";
import Router, { useRouter } from "next/router";
import { GTMPageView } from "lib/gtm";
import { AppContextType } from "next/dist/shared/lib/utils";
import useLocale from "hooks/useLocale";

// -----------------------------------------------------------------------------
// MDX Provider

const handleError = (e: Exception) => <RenderException e={e} />;
const baseMDXProps = {
  decoder: Frontmatter,
  handleError,
  wrap: mkAppLayout,
  components: MDXComponents,
};
const DefaultMDXProvider = mkMDXProvider({
  ...baseMDXProps,
  renderChildrenAsMDX: true,
});
const RuntimeMDXProvider = mkMDXProvider({
  ...baseMDXProps,
  renderChildrenAsMDX: false,
  customWrapProps: { title: "Markdown Preview", lead: "", layout: "none" },
});

// -----------------------------------------------------------------------------
// App

export default function App(props: AppProps<REnv>) {
  const locale = useLocale();

  // Google Analytics
  React.useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <LocaleProvider locale={locale}>
      <ConfigProvider locale={locale}>
        <HideGlobalSpinner />
        <Main {...props} />
      </ConfigProvider>
    </LocaleProvider>
  );
}

App.getInitialProps = async ({ Component, ctx }: AppContextType) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return { pageProps };
};

// -----------------------------------------------------------------------------
// Main – this is the main app component
// WARNING: Re-renders the whole app on window resize
const Main = ({ Component }: AppProps<REnv>) => {
  const { locale, ...query } = useQueryParamsWithDefault(REnv);
  const { route } = useRouter();
  const isMarkdownPreview = route === "/_markdown";

  let alias = query.alias;
  if (alias === "embed" || alias === "figure" || alias === "figures") {
    alias = pipe(
      // Ideally we would parse this with routes.figure.decoder
      O.fromNullable((query as $IntentionalAny).id),
      O.chain((id) => getFigure(id)),
      O.map((x) => x.theme),
      O.getOrElse(() => alias)
    );
  }

  const theme = useThemeFor(locale, alias);

  return React.useMemo(() => {
    const MDXProvider = isMarkdownPreview
      ? RuntimeMDXProvider
      : DefaultMDXProvider;

    return (
      <ThemeProvider theme={theme}>
        <AppStateProvider>
          <MDXProvider>
            <FocusVisibleManager>
              <Component locale={locale} alias={alias} />
            </FocusVisibleManager>
          </MDXProvider>
        </AppStateProvider>
      </ThemeProvider>
    );
  }, [isMarkdownPreview, theme, locale, alias, Component]);
};
