import css from "@emotion/css";
import { Annotation, HtmlLabel } from "@visx/annotation";
import { Group } from "@visx/group";
import { springConfigs } from "charts/lib/motion";
import { ScaleLinear } from "d3-scale";
import { motion } from "framer-motion";
import React from "react";

import { getContrastText } from "lib/colorManipulator";
import * as M from "../../../../materials";
import { ANNOTATION_WIDTH } from "../constants";
import { RuleItem, RulePoint } from "../types";
import { useTheme } from "hooks";

interface RuleProps {
  rule: RuleItem;
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleLinear<number, number>;
  activePointX?: number;
}

interface RuleAnnotationProps {
  style: React.SVGProps<SVGLineElement> & { stroke: string };
  text?: string;
  x: number;
  y: number;
}

const ANNOTATION_ARROW_DX = +M.spacing.base8(3, "");

const RuleAnnotation = React.memo(
  ({ style, text, x, y }: RuleAnnotationProps): React.ReactElement => {
    const { client } = useTheme();

    return (
      <Annotation
        dx={-ANNOTATION_ARROW_DX}
        dy={-M.spacing.base8(3, "")}
        x={x}
        y={y}
      >
        <HtmlLabel
          verticalAnchor="end"
          horizontalAnchor="start"
          showAnchorLine={false}
          containerStyle={{ position: "fixed" }}
        >
          <motion.div
            css={css`
              ${M.fontChartLabel};
              color: ${getContrastText(style.stroke, {
                light: M.whiteText,
                dark: M.blackText,
              })};
              border-radius: ${M.spacing.base8(0.5)};
              padding: ${M.spacing.base8(1)} ${M.spacing.base8(1.5)};
              background: ${style.stroke};
              direction: ${client.isRTL ? "rtl" : "ltr"};
              width: ${ANNOTATION_WIDTH}px;
              &::after {
                position: absolute;
                top: 100%;
                left: ${ANNOTATION_ARROW_DX}px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                pointer-events: none;
                border-top-color: ${style.stroke};
                border-width: ${M.spacing.base8(1)};
                margin-left: ${M.spacing.base8(-1)};
              }
            `}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={springConfigs.gentle}
          >
            <p>{text}</p>
          </motion.div>
        </HtmlLabel>
      </Annotation>
    );
  }
);

function Rule({
  rule,
  scaleX,
  scaleY,
  activePointX,
}: RuleProps): React.ReactElement {
  const { start, end, style } = rule;

  const shouldRenderAnnotation = (point: RulePoint) => {
    const conditions = [
      point.annotation,
      activePointX === point.datum.x || point?.annotation?.isAlwaysVisible,
    ];
    return conditions.every(Boolean);
  };

  const coordinates = {
    x1: scaleX(start.datum.x) ?? 0,
    y1: scaleY(start.datum.y) ?? 0,
    x2: scaleX(end.datum.x) ?? 0,
    y2: scaleY(end.datum.y) ?? 0,
  };
  if (coordinates.x1 <= scaleX.range()[0] || coordinates.x2 > scaleX.range()[1])
    return <g />;

  return (
    <Group>
      <motion.line
        initial={false}
        animate={{
          opacity: 1,
          ...coordinates,
        }}
        transition={{ duration: 0.05, ease: "linear" }}
        {...style}
      />

      {[start, end].map((point, index) => (
        <Group key={index}>
          {point.symbol && (
            <circle
              cx={scaleX(point.datum.x)}
              cy={scaleY(point.datum.y)}
              r={3}
              fill={style.stroke}
            />
          )}
          {shouldRenderAnnotation(point) && (
            <RuleAnnotation
              x={scaleX(point.datum.x) ?? 0}
              y={scaleY(point.datum.y) ?? 0}
              text={point?.annotation?.text}
              style={style}
            />
          )}
        </Group>
      ))}
    </Group>
  );
}

export default React.memo(Rule) as typeof Rule;
