
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "من نحن";
export const lead = "ماهيته";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const TargetFourLogo = makeShortcode("TargetFourLogo");
const Title = makeShortcode("Title");
const IndicatorSection = makeShortcode("IndicatorSection");
const IndicatorTable = makeShortcode("IndicatorTable");
const IndicatorRow = makeShortcode("IndicatorRow");
const ThemeLabel = makeShortcode("ThemeLabel");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TargetFourLogo mdxType="TargetFourLogo" />
    <Title mdxType="Title"> SDG 4 Indicators </Title>
    <p>{`Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all`}</p>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-41"
      }}>{`Target 4.1`}</h2>
      <p>{`٤-١ ضمان أن يتمتّع جميع الفتيات والفتيان بتعليم ابتدائي وثانوي مجاني ومنصف وجيّد يؤدي إلى تحقيق نتائج تعليمية ملائمة وفعالة بحلول عام 2030 `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=236"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.1.0" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{` نسبة الأطفال/الشباب المستعدين للمستقبل، بحسب الجنس`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`نسبة الأطفال والشباب: (أ) في الصف الثاني/الثالث؛ (ب) في نهاية المرحلة الابتدائية؛ (جـ) في نهاية المرحلة الدنيا من التعليم الثانوي الذين يحققون على الأقل الحد الأدنى من مستوى الكفاءة في (1) القراءة، (2) الرياضيات، بحسب الجنس.`}</p>
          <ThemeLabel theme="learning" id="4.1.1a" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`معدل إكمال الدراسة (التعليم الابتدائي، والتعليم الإعدادي، والتعليم الثانوي)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`نسبة القبول الإجمالية في الصف الأخير (المرحلة الابتدائية والمرحلة الدنيا من التعليم الثانوي).`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`نسبة غير الملتحقين بالمدارس (سنة قبل التعليم الابتدائي، التعليم الابتدائي، المرحلة الأولى من التعليم الثانوي، المرحلة الثانية من التعليم الثانوي)`}</p>
          <ThemeLabel theme="access" id="4.1.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية للأطفال الأكبر سناً من عمر صفهم الدراسي (التعليم الابتدائي، المرحلة الأولى من التعليم الثانوي)`}</p>
          <ThemeLabel theme="access" id="4.1.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`تنظيم عملية لتقييم التعلّم على المستوى الوطني (أ) في الصف الثاني/الثالث؛ و(ب) في نهاية المرحلة  الابتدائية؛ و(ج) في نهاية المرحلة الأولى من التعليم الثانوي`}</p>
          <ThemeLabel theme="access" id="4.1.6" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`عدد سنوات التعليم (1) المجاني و(2) الإلزامي في الابتدائي والثانوي المضمون بموجب الأُطر القانونية.`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-42"
      }}>{`Target 4.2`}</h2>
      <p>{`ضمان أن تتاح لجميع البنات والبنين فرص الحصول على نوعية جيدة من النماء والرعاية في مرحلة الطفولة المبكرة والتعليم قبل الابتدائي حتى يكونوا جاهزين للتعليم الابتدائي بحلول عام 2030
`}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=253"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.2.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`نسبة الأطفال دون الخامسة الذين يسيرون على المسار الصحيح من حيث النمو في مجالات الصحة والتعلم والرفاه النفسي، بحسب الجنس.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`معدل المشاركة في التعلُّم المنظَّم (قبل سنة واحدة من عمر الالتحاق الرسمي بالتعليم الابتدائي)، بحسب الجنس.`}</p>
          <ThemeLabel theme="access" id="4.2.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.2.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية للأطفال دون سن الخامسة الذين يعيشون في بيئة منزلية إيجابية وحافزة للتعلم`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`نسبة القيد الصافية لتعليم الطفولة المبكرة في (أ) التعليم ما قبل الابتدائي و(ب) برامج تنمية الطفولة المبكرة`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`عدد سنوات التعليم قبل الابتدائي )1( المجاني و) 2( الإلزامي المضمون بموجب الأطر القانونية`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-43"
      }}>{`Target 4.3`}</h2>
      <p>{`ضمان تكافؤ فرص جميع النساء والرجال في الحصول على التعليم المهني والتعليم العالي الجيّد والميسور التكلفة، بما في ذلك التعليم الجامعي، بحلول عام 2030
`}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=263"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.3.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`معدل مشاركة الشباب و الكبار في التعليم الرسمي وغير الرسمي و التدريب خلال الاثني عشر شهرا السابقة، بحسب الجنس`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`نسبة القيد الإجمالية في التعليم العالي، بحسب الجنس`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`نسبة المشاركة في برامج التعليم التقني والمهني )لمن تتراوح أعمارهم بين 15 و 24( بحسب نوع الجنس`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-44"
      }}>{`Target 4.4`}</h2>
      <p>{`الزيادة بنسبة كبيرة في عدد الشباب والكبار الذين تتوافر لديهم المهارات المناسبة، بما في ذلك المهارات التقنية والمهنية، للعمل وشغل وظائف لائقة ولمباشرة الأعمال الحرة بحلول عام 2030
`}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=271"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.4.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`نسبة الشباب والكبار الذين تتوفر لديهم مهارات تكنولوجيا المعلومات والاتصالات، بحسب نوع المهارة`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية للشباب و الكبار الذين يحرزون مستوى المعايير الدنيا للكفاءة في مهارات الإدارية الرقمية`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`معدلات التحصيل التعليمي للشباب و الكبار بحسب الفئة العمرية، ومستوى النشاط الاقتصادي، والمستوى التعليمي، وتوجُّه البرنامج`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-45"
      }}>{`Target 4.5`}</h2>
      <p>{`القضاء على التفاوت بين الجنسين في التعليم وضمان تكافؤ فرص الوصول إلى جميع مستويات التعليم والتدريب المهني للفئات الضعيفة، بما في ذلك للأشخاص ذوي الإعاقة والشعوب الأصلية والأطفال الذين يعيشون في ظل أوضاع هشة، بحلول عام 2030
`}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=281"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.5.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`بيانات المساواة (أنثى/ذكر، وريفي/حضري، ومستوى الثراء من القاع إلى القمة ومؤشرات أخرى مثل حالة الإعاقة، والشعوب الأصلية، والمتضررين من النزاعات متى أصبحت البيانات متوافرة) فيما يتعلق بجميع المؤشرات الواردة في هذه القائمة والتي يمكن تصنيفها.`}</p>
          <ThemeLabel theme="equity" id="4.5.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية لتلاميذ التعليم الابتدائي الذين تكون لغتهم الأولى أو لغتهم الأم هي ذاتها لغة التدريس.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`مدى تخصيص الموارد التعليمية للفئات السكانية المحرومة بالاستناد إلى سياسات قائمة على صيغ صريحة.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`مبلغ الإنفاق على تعليم التلميذ حسب المستوى التعليمي ومصدر التمويل.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
النسبة المئوية للمساعدة التي تقدم إلى البلدان المنخفضة الدخل من مجموع المساعدات المخصصة للتعليم.
          <ThemeLabel theme="finance" id="4.5.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow"> 
          <p>{`الإنفاق التعليمي الأولي بحسب مصدر التمويل  (عام، خاص، دولي) كنسبة مئوية من الناتج المحلي الإجمالي`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-46"
      }}>{`Target 4.6`}</h2>
      <p>{`ضمان أن يلمّ جميع الشباب ونسبة كبيرة من الكبار، رجالاً ونساءً على حد سواء، بالقراءة والكتابة والحساب بحلول عام 2030
`}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=292"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.6.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`النسبة المئوية للسكان في فئة عمرية معينة الذين يحققون على الأقل مستوى ثابتا من الكفاءة في تصنيفات وظيفية تتناول (أ) الأمية، (ب) المهارات الحسابية، بحسب الجنس.`}</p>
          <ThemeLabel theme="learning" id="4.6.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.6.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`معدل إلمام الشباب/الكبار بالقراءة والكتابة.`}</p>
          <ThemeLabel theme="learning" id="4.6.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-47"
      }}>{`Target 4.7`}</h2>
      <p>{`ضمان أن يكتسب جميع المتعلّمين المعارف والمهارات اللازمة لدعم التنمية المستدامة، بما في ذلك بجملة من السُبُل من بينها التعليم لتحقيق التنمية المستدامة واتّباع أساليب العيش المستدامة، وحقوق الإنسان، والمساواة بين الجنسين، والترويج لثقافة السلام واللاعنف والمواطنة العالمية وتقدير التنوع الثقافي وتقدير مساهمة الثقافة في التنمية المستدامة، بحلول عام 2030. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=300"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.7.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`٤-٧-١ مدى تعميم ’1‘ تعليم المواطنة العالمية و ’2‘ التعليم من أجل التنمية المستدامة، بما في ذلك المساواة بين الجنسين وحقوق الإنسان، وذلك على جميع الصعد في (أ) السياسات التعليمية على الصعيد الوطني، و (ب) المناهج الدراسية و (ج) تدريب المعلمين، و (د) تقييم الطلاب`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية للمدارس التي تعلّم مهارات حياتية بشأن فيروس نقص المناعة البشرية (HIV) وتقدم تعليماً في التربية الجنسية`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.3" mdxType="IndicatorRow">
          <p>{`مدى تعميم نوايا السياسة الخضراء في وثائق المناهج الدراسية`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{` النسبة المئوية للتلاميذ في المرحلة الأولى من التعليم الثانوي الذين يُظهرون فهما كافياً للمسائل المتعلقة بالمواطنة العالمية والاستدامة`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية للتلاميذ في المرحلة الأولى من التعليم الثانوي الذين يُظهرون الكفاءة في معارف العلوم البيئية وعلوم الأرض`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-4a"
      }}>{`Target 4.a`}</h2>
      <p>{`بناء المرافق التعليمية التي تراعي الفروق بين الجنسين، والإعاقة، والأطفال، ورفع مستوى المرافق التعليمية القائمة وتهيئة بيئة تعليمية فعالة ومأمونة وخالية من العنف للجميع، بحلول عام2030
`}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=308"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.a.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`أ نسبة المدارس التي تقدم الخدمات الأساسية، بحسب نوع الخدمة`}</p>
          <ThemeLabel theme="quality" id="4.a.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية الطلبة الذين يتعرضون لترهيب، وعقوبات جسدية، ومضايقات، وعنف، وتمييز جنسي واعتداء جنسي.`}</p>
          <ThemeLabel theme="quality" id="4.a.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`عدد الاعتداءات على الطلبة وأفراد الهيئة التعليمية والمؤسسات.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.a.4" mdxType="IndicatorRow">
          <p>{`نسبة الأطفال الملتحقين بالمدارس الذين يتلقون وجبات مدرسية`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-4b"
      }}>{`Target 4.b`}</h2>
      <p>{`الزيادة بنسبة كبيرة في عدد المنح المدرسية المتاحة للبلدان النامية على الصعيد العالمي للبلدان النامية، وبخاصة لأقل البلدان نموا والدول الجزرية الصغيرة النامية والبلدان الأفريقية، للالتحاق بالتعليم العالي، بما في ذلك منح التدريب المهني وتكنولوجيا المعلومات والاتصالات، والبرامج التقنية والهندسية والعلمية في البلدان المتقدمة النمو والبلدان النامية الأخرى، بحلول عام 2020. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=321"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
  
        <IndicatorRow id="4.b.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`حجم تدفقات المساعدة الإنمائية الرسمية لتقديم المنح بحسب القطاع ونوع الدراسة.`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "target-4c"
      }}>{`Target 4.c`}</h2>
      <p>{`الزيادة بنسبة كبيرة في عدد المعلمين المؤهلين، بما في ذلك من خلال التعاون الدولي لتدريب المعلمين في البلدان النامية، وبخاصة في أقل البلدان نموًّا والدول الجزرية الصغيرة النامية، بحلول عام 2030. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=331"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.c.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{` نسبة المعلمين الحاصلين على الحد الأدنى من المؤهلات اللازمة، بحسب المستوى التعليمي`}</p>
          <ThemeLabel theme="quality" id="4.c.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`متوسط عدد التلاميذ للمدرس المؤهَّل الواحد، بحسب المستوى التعليمي.`}</p>
          <ThemeLabel theme="quality" id="4.c.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`نسبة المعلمين المؤَهّلين وفقاً للمعايير الوطنية بحسب المستوى التعليمي ونوع المؤسسة التعليمية`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`متوسط عدد التلاميذ للمدرس المؤهَّل الواحد، بحسب المستوى التعليمي`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`متوسط أجر المعلمين مقارنة بأجور غيرهم من العاملين في المهن الأخرى التي تتطلب الحصول على مستوى مماثل من المؤهلات التعليمية.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`معدَّل تناقص أعداد المعلمين بحسب المستوى التعليمي.`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`النسبة المئوية للمعلمين الذين تلقوا تدريباً أثناء الخدمة خلال الأشهر الاثني عشر الأخيرة، بحسب نوع التدريب.`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "finance"
      }}>{`Finance`}</h2>
      <p><a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=340"
        }}>{`See latest GEM Report chapter`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="F.1" mdxType="IndicatorRow">
          <p>{`الإنفاق الحكومي على التعليم كنسبة مئوية من الناتج المحلي الإجمالي`}</p>
          <ThemeLabel theme="finance" id="F.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="F.2" mdxType="IndicatorRow">
          <p>{`الإنفاق الحكومي على التعليم كنسبة مئوية من إجمالي الإنفاق الحكومي`}</p>
          <ThemeLabel theme="finance" id="F.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;