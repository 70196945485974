import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "TRAJECTORIES_EXPLORER_SIMULATION",
  title: null,
  caption: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_EXPLORER_SIMULATION.caption",
    defaults: `Descriptive learning trajectories showing the percentage of children (aged 7–14 years) who have mastered foundational skills, typically at the Grade 2–3 level. This is below the minimum proficiency level defined by SDG global indicator 4.1.1. Trajectories by age include all children (whether they were in school at the time of the survey, had dropped out or had never attended school). For trajectories showing groups inside countries, the data might be less reliable due to smaller sample sizes.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_EXPLORER_SIMULATION.source",
    Source: `UNICEF MICS6 data based on simulation methodology and analysis by RISE.`,
  },
};
