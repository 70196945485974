import { I18n, MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { Ar, io, Ord, pipe } from "../prelude";

const levelOrder = {
  preprimary: 0,
  primary: 1,
  "lower secondary": 2,
  "upper secondary": 3,
  tertiary: 4,
};

export const Level = io.keyof(levelOrder);
export type Level = io.TypeOf<typeof Level>;

export const getLevelIndex = (k: Level) => levelOrder[k];

export const ordLevel = Ord.contramap((level: Level) => getLevelIndex(level))(
  Ord.ordNumber
);
export const ordLevelReverse = Ord.contramap(
  (level: Level) => -getLevelIndex(level)
)(Ord.ordNumber);

const levelLookup: Record<Level, MessageDescriptor> = {
  preprimary: t("preprimary")`Pre-primary`,
  primary: t("primary")`Primary`,
  "lower secondary": t("lower secondary")`Lower Secondary`,
  "upper secondary": t("upper secondary")`Upper Secondary`,
  tertiary: t("tertiary")`Tertiary`,
};
export const getLevelName = (i18n: I18n, level: Level) =>
  i18n._(levelLookup[level]);

export function sortLevels<A>(xs: Array<A>, get: (x: A) => Level) {
  return pipe(xs, Ar.sort(Ord.contramap((needle: A) => get(needle))(ordLevel)));
}

// -----------------------------------------------------------------------------
// Level, but with attendance/completion

export const LevelAC = io.keyof({
  ever_attend: null,
  prim_completion: null,
  lsec_completion: null,
  upsec_completion: null,
  postsec_attendance: null,
});
export type LevelAC = io.TypeOf<typeof LevelAC>;

const levelACOrder = {
  ever_attend: 0,
  prim_completion: 1,
  lsec_completion: 2,
  upsec_completion: 3,
  postsec_attendance: 4,
};
export const getLevelACIndex = (k: LevelAC) => levelACOrder[k];
export const ordLevelAC = Ord.contramap((level: LevelAC) =>
  getLevelACIndex(level)
)(Ord.ordNumber);

const levelACLookup: Record<LevelAC, MessageDescriptor> = {
  ever_attend: t("ever_attend")`Ever been to school`,
  prim_completion: t("prim_completion")`Primary completion`,
  lsec_completion: t("lsec_completion")`Lower secondary completion`,
  upsec_completion: t("upsec_completion")`Upper secondary completion`,
  postsec_attendance: t("postsec_attendance")`Post secondary attendance`,
};
export const getLevelACName = (i18n: I18n, level: LevelAC) =>
  i18n._(levelACLookup[level]);
