import css from "@emotion/css";
import baseStyled, { CreateStyled } from "@emotion/styled";
import {
  darken,
  fade,
  getContrastText,
  lighten,
} from "../lib/colorManipulator";
import * as M from "../materials";
import { O, pipe } from "../prelude";
import {
  findArticleAlias,
  RAlias,
  RAliasArticle,
  RAliasTheme,
} from "../routes";
import imageAccess from "./assets/bg_access-revision.svg?raw";
import imageEquity from "./assets/bg_equity-revision.svg?raw";
import imageFinance from "./assets/bg_finance-revision.svg?raw";
import imageFinanceAid from "./assets/bg_finance_aid-revision.svg?raw";
import imageLearning from "./assets/bg_learing-revision.svg?raw";
import imageTrajectories from "./assets/bg_trajectories-revision.svg?raw";
import imageQuality from "./assets/bg_quality-revision.svg?raw";
import {
  blackText,
  colorRanges,
  gemRed,
  gemTeal,
  grayscalePalette,
  lightText,
  q01Palette,
  q02Palette,
  q04Palette,
  q06Palette,
  q07Palette,
  q08Palette,
  q09Palette,
  unescoDarkBlue,
  unescoMarineBlue,
  whiteText,
} from "./colors";
export type GEMTheme = ReturnType<typeof mkTheme>;
export const styled: CreateStyled<GEMTheme> = baseStyled;

export const zIndex = {
  aside: 1,
  tooltip: 10,
  overlapContext: 11,
  header: 20,
};

/**
 * State of the clients browser
 * The advantage over having it in the reducer is that it is available in the theme for css as well as the `useTheme` hook.
 */
export interface Client {
  screenSDown: boolean;
  screenMDown: boolean;
  screenLDown: boolean;
  screenLUp: boolean;
  isRTL: boolean;
  isTouchDevice: boolean;
}

export const mkTheme = (routeAlias: RAlias, client: Client) => {
  const palette = {
    action: {
      main: unescoMarineBlue,
      light: lighten(unescoMarineBlue, 0.3),
      dark: darken(unescoMarineBlue, 0.3),
    },
    text: {
      black: blackText,
      white: whiteText,
      light: lightText,
    },
    brand: {
      blue: {
        main: unescoDarkBlue,
        light: lighten(unescoDarkBlue, 0.3),
        dark: darken(unescoDarkBlue, 0.3),
      },
      marine: {
        main: unescoMarineBlue,
        light: lighten(unescoMarineBlue, 0.3),
        dark: darken(unescoMarineBlue, 0.3),
      },
      teal: {
        main: gemTeal,
        light: lighten(gemTeal, 0.3),
        dark: darken(gemTeal, 0.3),
      },
      red: {
        main: gemRed,
        light: lighten(gemRed, 0.3),
        dark: darken(gemRed, 0.3),
      },
    },
    theme: themes,
    grayscale: grayscalePalette,
    ranges: colorRanges,
  };
  const utils = {
    lighten,
    darken,
    fade,
    getContrastText,
  };

  const activeTheme = pipe(
    findArticleAlias(routeAlias),
    O.getOrElse(() => "default" as DocumentKey)
  );
  const typography = {
    preset: {
      fontTera: M.fontTera,
      fontGiga: M.fontGiga,
      fontHeading1: M.fontHeading1,
      fontHeading2: M.fontHeading2,
      fontHeading3: M.fontHeading3,
      fontHeading4: M.fontHeading4,
      fontLead1: M.fontLead1,
      fontLead2: M.fontLead2,
      fontBody1: M.fontBody1,
      fontBody2: M.fontBody2,
      fontBody2Hero: M.fontBody2Hero,
      fontButton: M.fontButton,
      fontTable: M.fontTable,
      fontMeta: M.fontMeta,
      fontFooter: M.fontFooter,
    },
    bold: M.notoBold.cssProperties,
    regular: css`
      font-weight: 400;
      font-style: normal;
    `,
    link: M.linkBody,
  };

  return {
    zIndex,
    palette,
    typography,
    spacing: M.spacing,
    utils,
    activeTheme: themes[activeTheme],
    breakpoints: { values: M.bp, down: M.bpDown, up: M.bpUp },
    client,
  };
};

export interface DocumentBase {
  textColor: string;
  background: {
    main: string;
    light: string;
    dark: string;
  };
  palette: Array<string>;
}

export interface DocumentDefault extends DocumentBase {
  kind: "default";
}

export interface DocumentArticle extends DocumentBase {
  kind: "article";
  illustration: string;
}

export type DocumentKey = RAliasTheme | RAliasArticle | "default";

export type DocumentTheme = {
  [K in DocumentKey]: DocumentArticle | DocumentDefault;
};

const themes: DocumentTheme = {
  default: {
    kind: "default",
    textColor: blackText,
    background: {
      main: q01Palette[2],
      light: lighten(q01Palette[2], 0.85),
      dark: darken(q01Palette[2], 0.6),
    },
    palette: q07Palette,
  },
  access: {
    kind: "article",
    textColor: q02Palette[6],
    background: {
      main: q02Palette[3],
      light: lighten(q02Palette[2], 0.85),
      dark: q02Palette[6],
    },
    palette: q02Palette,
    illustration: imageAccess,
  },
  equity: {
    kind: "article",
    textColor: q04Palette[7],
    background: {
      main: q04Palette[7],
      light: lighten(q04Palette[7], 0.85),
      dark: darken(q04Palette[7], 0.6),
    },
    palette: q04Palette,
    illustration: imageEquity,
  },
  learning: {
    kind: "article",
    textColor: q06Palette[6],
    background: {
      main: q06Palette[7],
      light: lighten(q06Palette[7], 0.85),
      dark: darken(q06Palette[7], 0.6),
    },
    palette: q06Palette,
    illustration: imageLearning,
  },
  trajectories: {
    kind: "article",
    textColor: q06Palette[6],
    background: {
      main: q06Palette[7],
      light: lighten(q06Palette[7], 0.85),
      dark: darken(q06Palette[7], 0.6),
    },
    palette: q06Palette,
    illustration: imageTrajectories,
  },
  quality: {
    kind: "article",
    textColor: q08Palette[6],
    background: {
      main: q08Palette[5],
      light: lighten(q08Palette[5], 0.85),
      dark: darken(q08Palette[5], 0.6),
    },
    palette: q08Palette,
    illustration: imageQuality,
  },
  finance: {
    kind: "article",
    textColor: q09Palette[7],
    background: {
      main: q09Palette[4],
      light: lighten(q09Palette[4], 0.85),
      dark: darken(q09Palette[4], 0.6),
    },
    palette: q09Palette,
    illustration: imageFinance,
  },
  financeAid: {
    kind: "article",
    textColor: q09Palette[7],
    background: {
      main: q09Palette[4],
      light: lighten(q09Palette[4], 0.85),
      dark: darken(q09Palette[4], 0.6),
    },
    palette: q09Palette,
    illustration: imageFinanceAid,
  },
};
