import css from "@emotion/css";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { MDXProvider } from "@mdx-js/react";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";

const useStyles = mkStyles(
  ({ activeTheme, spacing: { base8, base1 }, breakpoints, utils }) => ({
    root: css`
      background: ${activeTheme.background.main};
      color: ${M.whiteText};
    `,
    contentBox: css`
      grid-column: ${M.layoutAreas.figure} !important;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: ${base8(3)};
      margin-top: 0;

      @media ${breakpoints.down("l")} {
        grid-column: ${M.layoutAreas.full};
        flex-direction: column;
      }
    `,
    box: css`
      background: ${utils.fade(M.grayscalePalette[3], 0.25)};
      display: flex;
      align-items: center;
      padding-top: ${base8(5)};
      padding-bottom: ${base8(2)};
      padding-left: ${base8(1)};
      padding-right: ${base8(1)};
      margin: ${base8(1)};
      position: relative;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;

      &:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-left-color: ${utils.fade(M.grayscalePalette[3], 0.25)};
        border-width: 10px;
      }

      & > h2 {
        ${M.fontHeading2};
        padding-bottom: ${base8(2)};
      }

      & > h3 {
        ${M.fontHeading3};
        padding-bottom: ${base8(1)};
        padding-top: ${base8(1)};
        margin-top: ${base8(1)};
        padding-left: ${base8(4)};
        padding-right: ${base8(4)};
      }

      @media ${breakpoints.down("l")} {
        width: 100%;

        &:after {
          top: 100%;
          left: calc(50% - 10px);
          border-left-color: transparent;
          border-top-color: ${utils.fade(M.grayscalePalette[3], 0.25)};
        }
      }
    `,
    noTip: css`
      &:after {
        border: none;
      }
    `,
    title: css`
      ${M.fontHeading1};
      color: ${M.whiteText};
      margin-top: ${base8(4)};
      text-align: center;
      font-weight: 700;
    `,
    intro: css`
      ${M.fontBody2};
      color: ${M.whiteText};
      text-align: center;
      max-width: ${base8(85)};
      margin: 0.75em auto 1.5em auto;
    `,
    boxRow: css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: white;

      @media ${breakpoints.down("l")} {
        flex-direction: row;
      }
    `,
    highlighted: css`
      color: ${activeTheme.background.main};
      background-color: ${M.whiteText};
      border-radius: ${base1(2)};
    `,
  })
);

const useComponents = mkStyles(({ spacing: { base8 } }) => {
  return {
    h2: styled.h2`
      ${M.fontHeading2};
      padding-top: ${base8(1)};
    `,
  };
});

export interface FrameworkProps {
  highlighted?: string;
}

export function QualityFrameworkTable({ highlighted }: FrameworkProps) {
  const styles = useStyles();

  return (
    <MDXProvider components={useComponents()}>
      <h1 css={styles.title}>
        <Trans>Framework for Education Quality</Trans>
      </h1>
      <p css={styles.intro}>
        <Trans id="quality-framework.caption">
          This framework proposed in the 2005 EFA Global Monitoring Report has
          been the standard reference for understanding eduation quality for
          more than a decade. It helps understand the importance of all the
          different themes and topics selected to appear on the Education
          Progress website.
        </Trans>
      </p>
      <div css={styles.contentBox}>
        <div css={styles.boxRow}>
          <div css={styles.box}>
            <h2>
              <Trans>Learners</Trans>
            </h2>
          </div>
          <div css={styles.box}>
            <h2>
              <Trans>Systems</Trans>
            </h2>
          </div>
        </div>

        <div css={styles.boxRow}>
          <div css={styles.box}>
            <h2>
              <Trans>Schools and classrooms</Trans>
            </h2>
            <h3 css={highlighted === "teachers" && styles.highlighted}>
              <Trans>Teachers and teaching</Trans>
            </h3>
            <h3 css={highlighted === "school" && styles.highlighted}>
              <Trans>School and environment</Trans>
            </h3>
            <h3 css={highlighted === "material" && styles.highlighted}>
              <Trans>Material inputs</Trans>
            </h3>
          </div>
        </div>

        <div css={styles.boxRow}>
          <div css={[styles.box, styles.noTip]}>
            <h2>
              <Trans>Outcomes</Trans>
            </h2>
            <h3 css={highlighted === "learners" && styles.highlighted}>
              <Trans>For learners</Trans>
            </h3>
            <h3 css={highlighted === "societies" && styles.highlighted}>
              <Trans>For societies</Trans>
            </h3>
          </div>
        </div>
      </div>
    </MDXProvider>
  );
}
