import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID",
  title: /*i18n*/ {
    id: "fig.finance.AID.title",
    defaults: `Aid to education by level`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.AID.caption",
    defaults: `Aid to education has been stagnant and unpredictable since the financial crisis.`,
  },
  source: /*i18n*/ {
    id: "fig.finance.AID.source",
    defaults: `GEM Report team analysis based on OECD CRS`,
  },
};
