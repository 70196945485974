import css from "@emotion/css";
import { t, Trans } from "@lingui/macro";
import React from "react";
import { AspectRatioContainer } from "../../components/aspect-ratio";
import { mkStyles, useTheme } from "../../hooks";
import { useI18n } from "../../locales";
import * as M from "../../materials";
import { gridCenteredMaxWidth } from "../app-layout";

const image1 = "/link-boxes/bg_gem.png";
const image2 = "/link-boxes/bg_peer.jpeg";
const image3 = "/link-boxes/bg_view.png";
const image4 = "/link-boxes/bg_wide.jpg";

const useStyles = mkStyles(
  ({
    activeTheme,
    client: { isRTL },
    spacing: { base8 },
    palette,
    breakpoints,
  }) => {
    const { grid, areas } = gridCenteredMaxWidth();

    return {
      root: css`
        ${grid};
        grid-column: ${M.layoutAreas.full};
        background: #f5f5f5;
        color: black;

        /* This targets the iPad mini: Until exactly 1024 add padding. */
        @media screen and (max-width: ${breakpoints.values.l}px) {
          padding: ${base8(3)};
        }
      `,
      contentBox: css`
        grid-area: ${areas.main};
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${base8(3)} 0;
      `,
      title: css`
        ${M.fontHeading1};
        padding-top: ${base8(3)};
        padding-bottom: ${base8(8)};
        font-weight: 600;
      `,
      linkGrid: css`
        grid-column: ${M.layoutAreas.full};
        width: 100%;
        display: grid;
        grid-gap: ${base8(3)};
        grid-template-columns: repeat(2, 1fr);

        @media ${breakpoints.down("m")} {
          grid-template-columns: unset;
        }
      `,
      linkGridItemFallback: M.onlyIE(css`
        display: block;
        float: left;
        width: 50%;
      `),
      link: css`
        ${M.fontBody2}
        color: transparent;
        font-size: ${base8(2)};
        font-weight: 600;
      `,

      linkBox: css`
        ${M.fontHeading2};
        color: ${activeTheme.textColor};
        padding: ${base8(3)};
        font-weight: 400;
        background-color: white;
        display: flex;
        flex-direction: row;
        transition: box-shadow 0.3s;
        box-shadow: 0 25px 50px -25px rgba(0, 0, 0, 0);

        &:hover {
          box-shadow: 0 25px 50px -25px rgba(0, 0, 0, 0.3);
        }

        &:hover p {
          display: grid;
          color: ${palette.action.main};
        }

        @media ${breakpoints.down("l")} {
          flex-direction: column;
        }
      `,
      linkBoxTouch: css`
        & p {
          display: grid;
          color: ${palette.action.main};
        }
      `,
      content: css`
      padding-${isRTL ? "right" : "left"}: ${base8(3)};
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media ${breakpoints.down("l")} {
        padding-${isRTL ? "right" : "left"}: 0;
        padding-top: ${base8(3)};
      }
    `,
      image: css`
        flex-shrink: 0;
        flex-basis: 50%;
        & img {
          width: 100%;
        }
      `,
      linkBoxFallback: M.onlyIE(css`
        display: block;
        margin: ${base8(2)};
      `),
    };
  }
);

export function RelatedContent() {
  const i18n = useI18n();
  const styles = useStyles();
  const { client } = useTheme();
  const ratio: [number, number] = client.screenLDown ? [3, 1] : [1, 1];
  const offsetY = client.screenLDown ? -100 : 0;
  const isTouch = client.isTouchDevice;

  return (
    <div css={styles.root} data-skip-search>
      <div css={styles.contentBox}>
        <h2 css={styles.title}>{i18n._(t`Related content`)}</h2>
        <div css={styles.linkGrid}>
          <a
            css={styles.linkGridItemFallback}
            href={i18n._(
              t("url.gem-report-home")`https://www.unesco.org/gem-report/en`
            )}
          >
            <div
              css={[
                styles.linkBox,
                isTouch && styles.linkBoxTouch,
                styles.linkBoxFallback,
              ]}
            >
              <div css={styles.image} role="presentation">
                <AspectRatioContainer ratio={ratio} offsetY={offsetY}>
                  <img src={image1} alt="" />
                </AspectRatioContainer>
              </div>
              <div css={styles.content}>
                <h2>
                  <Trans>GEM Report website</Trans>
                </h2>
                <p css={styles.link}>
                  <Trans>Learn more</Trans>
                </p>
              </div>
            </div>
          </a>

          <a
            css={styles.linkGridItemFallback}
            href={i18n._(
              t("url.education-profiles")`https://education-profiles.org/`
            )}
          >
            {console.log(image2)}
            <div
              css={[
                styles.linkBox,
                isTouch && styles.linkBoxTouch,
                styles.linkBoxFallback,
              ]}
            >
              <div css={styles.image} role="presentation">
                <AspectRatioContainer ratio={ratio} offsetY={offsetY}>
                  <img src={image2} alt="" />
                </AspectRatioContainer>
              </div>
              <div css={styles.content}>
                <h2>
                  <Trans>Profiles Enhancing Education Reviews</Trans>
                </h2>

                <p css={styles.link}>
                  <Trans>Learn more</Trans>
                </p>
              </div>
            </div>
          </a>

          <a
            css={styles.linkGridItemFallback}
            href={i18n._(
              t("url.education-estimates")`https://education-estimates.org/`
            )}
          >
            <div
              css={[
                styles.linkBox,
                isTouch && styles.linkBoxTouch,
                styles.linkBoxFallback,
              ]}
            >
              <div css={styles.image} role="presentation">
                <AspectRatioContainer ratio={ratio} offsetY={offsetY}>
                  <img src={image3} alt="" />
                </AspectRatioContainer>
              </div>
              <div css={styles.content}>
                <h2>
                  <Trans>
                    Visualizing Indicators of Education for the World
                  </Trans>
                </h2>

                <p css={styles.link}>
                  <Trans>Learn more</Trans>
                </p>
              </div>
            </div>
          </a>

          <a
            css={styles.linkGridItemFallback}
            href={i18n._(
              t(
                "url.education-inequalities"
              )`https://www.education-inequalities.org`
            )}
          >
            <div
              css={[
                styles.linkBox,
                isTouch && styles.linkBoxTouch,
                styles.linkBoxFallback,
              ]}
            >
              <div css={styles.image} role="presentation">
                <AspectRatioContainer ratio={ratio} offsetY={offsetY}>
                  <img src={image4} alt="" />
                </AspectRatioContainer>
              </div>
              <div css={styles.content}>
                <h2>
                  <Trans>World Inequality Database on Education</Trans>
                </h2>
                <p css={styles.link}>
                  <Trans>Learn more</Trans>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
