import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { ascending, descending } from "d3-array";
import { useReadScrolly } from "components/scrolly";
import { useConfig } from "config";
import { format } from "d3-format";
import React from "react";
import { StackedBarAuto } from "../charts-motion";
import { removeTrailingZero } from "../charts/lib/utils";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_finance_aid_fig_EDU_SCHOL_TT";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  mkGemDataDecoder,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, io, O, Ar } from "../prelude";
import { pipe } from "fp-ts/lib/pipeable";

export * from "../data/data_finance_aid_fig_EDU_SCHOL_TT";

const TypeOrder = {
  not_scholarship: 0,
  scholarship: 1,
};

const Type = io.keyof(TypeOrder);
type Type = io.TypeOf<typeof Type>;

const typeLookup: Record<Type, MessageDescriptor> = {
  not_scholarship: t(
    "fig.finance.aid.EDU_SCHOL_TT.not_scholarship"
  )`Not scholarship`,
  scholarship: t("fig.finance.aid.EDU_SCHOL_TT.scholarship")`Scholarship`,
};

export const Data = mkGemDataDecoder([], {
  value: io.number,
  donor: io.string,
  type: Type,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const env = useConfig();
  const { activeSection } = useReadScrolly();

  const scrollyCountries = React.useMemo(
    () =>
      pipe(
        activeSection,
        O.map((x) => x.split(",")),
        O.fold(
          () => O.none,
          (strIds) => {
            const countryIds = strIds.filter((d) => d.length === 3);
            return pipe(
              Ar.array.sequence(O.option)(
                countryIds.map((mbId) =>
                  getCountryMeta(env.countries, countryIdIso.wrap(mbId))
                )
              ),
              O.map((xs) => xs.map((meta) => meta.country)),
              O.chain(An.fromArray)
            );
          }
        )
      ),
    [activeSection, env.countries]
  );

  const chartData = React.useMemo(() => {
    return data
      .sort((a, b) =>
        // Moving Others to the end.
        a.donor === "Others"
          ? 1
          : b.donor === "Others"
          ? -1
          : ascending(a.type, b.type) || descending(a.value, b.value)
      )
      .map((x) => ({
        ...x,
        type: i18n._(typeLookup[x.type]),
      }));
  }, [i18n, data]);
  type Datum = typeof chartData[number];

  const getStack = React.useCallback((x: Datum) => `${x.donor}`, []);
  const getLayer = React.useCallback((x: Datum) => x.type, []);
  const getLayerLabel = React.useCallback((x: string) => x, []);
  const getValue = React.useCallback((x: Datum) => x.value || 0, []);
  const formatX = React.useCallback((stack) => stack, []);
  const formatY = React.useMemo(() => removeTrailingZero(format(".1f")), []);
  const getColorPalette = React.useCallback(
    (count) => M.fromCount(M.colorRanges.sequential[0], count),
    []
  );

  return pipe(
    An.fromArray(chartData),
    O.map((xs) => (
      <StackedBarAuto
        key={"linter-map"}
        height={client.screenLDown ? M.chartHeight.s : M.chartHeight.m}
        data={xs}
        getStack={getStack}
        getLayer={getLayer}
        getLayerLabel={getLayerLabel}
        getValue={getValue}
        formatX={formatX}
        formatY={formatY}
        yNice={6}
        colorPalette={getColorPalette}
        yUnitLabel={i18n._(t`USD million`)}
        showTooltip
        rotateXLabels
        highlightedItems={scrollyCountries}
      />
    )),
    O.toNullable
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_EDU_SCHOL_TT.json"),
  csv: require("../data/data_finance_aid_fig_EDU_SCHOL_TT.zip"),
  xlsx: require("../data/data_finance_aid_fig_EDU_SCHOL_TT.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
