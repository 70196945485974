
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Basic numeracy skills have stagnated among Africa's poorest for decades";
export const lead = "Numeracy is important. Calculating numbers and proportions is crucial for many activities in production and trade, and even in household activities and agriculture.";
export const layout = "focus";
export const article = "learning";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Basic numeracy skills have stagnated among Africa's poorest for decades" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=320" mdxType="FocusDetails">
      <h1 {...{
        "id": "basic-numeracy-skills-have-stagnated-among-africas-poorest-for-decades"
      }}>{`Basic numeracy skills have stagnated among Africa's poorest for decades`}</h1>
      <p>{`Numeracy is important. Calculating numbers and proportions is crucial for many activities in production and trade, and even in household activities and agriculture (Tollnek and Baten, 2017).1 Health-related activities similarly require numerical judgements about proportions and the exact use of time. Overall, numerical abilities have a positive impact on economic growth, and also on the economic success of migrants in major destination countries (Hanushek and Woessmann, 2012). Despite its importance, numeracy has been evaluated much less than literacy, not least because of data scarcity (Lilenstein, 2018; Pritchett, 2013). This is especially true for sub-Saharan Africa and for disadvantaged populations.`}</p>
      <p>{`Learning assessments such as the Programme d'analyse des systèmes éducatifs de la CONFEMEN (PASEC) have filled data gaps on numeracy of schoolchildren, although they have been limited by changes in countries participating and measurement methodology over the years (Dickerson et al., 2015). Most recently, the UNICEF Multiple Indicator Cluster Surveys (MICS) have included foundational numeracy assessments for children, and work is progressing on the People’s Action for Learning Network’s International Common Assessment of Numeracy, a citizen-led assessment.`}</p>
      <p>{`However, these initiatives do not provide information on adult numeracy and trends over past decades. Unlike for literacy, there has been no tradition of including numeracy information in censuses.`}</p>
      <p>{`There are, however, techniques for extracting indirect estimates of numeracy from past surveys and censuses, even when they did not explicitly include assessments or questions about numeracy. In particular, it is well established that the amount of age heaping in such data provides viable indirect estimates of basic numeracy (A'Hearn et al., 2009; Tollnek and Baten, 2016). Age heaping is a particular kind of age misreporting, where less numerate survey respondents round their age, typically to multiples of five. A proxy measure of basic numeracy can be calculated as the percentage stating their age correctly, after estimating the number of round ages exceeding what would be expected based on demographic patterns.`}</p>
      <p>{`This measure correlates consistently with other measures of education and learning across cultures, periods and regions, including for developing countries after 1950 (Crayen and Baten, 2010), and even going back to Roman antiquity and the Inca before European contact (Juif and Baten, 2013). This basic indicator reflects the ability to work with simple, low integers, well below even minimum proficiency in the sense of SDG 4. Accordingly, most cross this threshold, even among the poorest. Nevertheless, it is suited for examining historical numeracy trends.`}</p>
      <p>{`An analysis for this report of MICS and population census data allows the numeracy of cohorts born between the 1960s and the 2010s to be traced for 42 sub-Saharan African countries. The indirect measure of numeracy based on age heaping is highly correlated at the subnational level with results from school-based numeracy assessments, confirming both numeracy deficits’ persistence and the proxy measure’s validity. Across all PASEC countries in western and central Africa, the correlation is as high as 0.8, reaching 0.95 in Niger. The high degree of persistence has also been observed in Europe and Latin America (Baten and Hippe, 2018; Baten and Juif, 2014).`}</p>
      <FocusImage size="small" src={"19-numeracyskills_1.png"} alt="Indirect estimates of numeracy among adults are highly correlated with children’s numeracy skills" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=321" mdxType="FocusImage" />
      <p>{`Sizeable socioeconomic differences in numeracy are especially seen in Cameroon, the Gambia, Mali, Mauritania, Nigeria, Sudan and Sierra Leone. The difference is as large as 19 percentage points in Nigeria among 50- to 59-year-olds, with 44% numeracy among the poorest compared with 63% among the richest. On average, improvements over time have been marginal and not sustained among the poorest.`}</p>
      <FocusImage size="small" src={"19-numeracyskills_2.png"} alt="Basic adult numeracy has improved only slowly in recent decades, and not at all among the poorest" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=321" mdxType="FocusImage" />
      <p>{`These trends apply both for women and men. In general, gender gaps are marginal at this elementary level of numeracy, at less than five percentage points for all but three of the countries analysed. However, urban–rural and wealth gaps are significant. In Sudan, numeracy in rural areas is more than 10 points behind for all age groups. For the age cohort now between 40 and 50, urban–rural differences are particularly large in the Gambia, Mauritania, Nigeria, Sierra Leone and Sudan.`}</p>
      <p>{`Even bigger, unsurprisingly, is the difference in numeracy between those who benefited from any schooling and the unschooled, reaching 22 percentage points in Senegal and 24 in Cameroon. In Cameroon, Kenya, Malawi, Nigeria and Senegal, improvements in numeracy have been modest at best, both among the unschooled and those with some schooling. For example, numeracy among those who reported no schooling in Senegal was 69% for those born in the 1950s and 70% for those born in the 1970s. Cameroon saw a modest increase in numeracy skills among those who received some schooling, from 84% to 89%. But in most countries, progress was limited or even slightly negative, as among the unschooled in Kenya. This means the overall increase in numeracy was due almost entirely to rising school participation, mirroring findings for literacy (Barakat, 2016).`}</p>
      <p>{`Notably high levels of numeracy are observed in the Democratic Republic of the Congo, exceeding 95% in the 1960s birth cohorts, even among the poorest. Indeed, elite numeracy was already very high in the 16th century (Baten and Alexopoulou, 2021) in the early kingdoms of Ba-Kongo and Kuba, among others.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=568"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;