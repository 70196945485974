import { t } from "@lingui/macro";
import { format } from "d3-format";
import React from "react";
import { withDataLoader } from "../components/data-loader";
import { EmbedChart, StaticChart } from "../components/figure";
import { HeroControlsWrapper } from "../components/hero-controls/hero-controls";
import { Area } from "../components/hero-vis/area";
import { Hero } from "../components/hero-vis/hero";
import { Lines } from "../components/hero-vis/line";
import { metadata } from "../data/data_access_hero";
import {
  ChartRenderer,
  mkGemEntityDecoder,
  RenderMode,
  useCountryEntities,
  useNamedEntities,
  useRegionEntities,
} from "../domain";
import {
  foldChartType,
  HeroStateProvider,
  useChartType,
} from "../domain/hero-state";
import { useContentRect } from "../hooks";
import { useI18n } from "../locales";
import { io } from "../prelude";

export * from "../data/data_access_hero";

const formatValue = format(".0%");
const formatAxisValue = format(".0%");

const targetTitle = t("fig.access.HERO.targetTitle")`2030 Target`;
const targetLabel = t(
  "fig.access.HERO.target"
)`100% of children complete upper secondary school`;
const infoLabel = t(
  "fig.access.HERO.info"
)`100% of children complete upper secondary school`;

export const Data = mkGemEntityDecoder(["ind_id", "year"], {
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({
  data: dataRaw,
  width,
  csv,
  xlsx,
  renderMode,
}: ChartRenderer<Data> & { width: number; csv: string; xlsx: string }) => {
  const i18n = useI18n();
  const [chartType] = useChartType();

  const indicatorLabel = i18n._(
    t("fig.access.HERO.indicatorLabel")`Complete primary school`
  );
  const tooltipRowLabels = [
    i18n._(t("fig.access.HERO.tooltipNotReached")`Not completed`),
    i18n._(t("fig.access.HERO.tooltipReached")`Completed`),
  ] as const;

  const data = useNamedEntities(dataRaw);
  const countryData = useCountryEntities(data);
  const regionData = useRegionEntities(data);

  return (
    <HeroControlsWrapper
      renderMode={renderMode}
      caption={i18n._(metadata.caption)}
      content={i18n._(infoLabel)}
      source={i18n._(metadata.source)}
      id={metadata.id}
      csv={csv}
      xlsx={xlsx}
    >
      {foldChartType(chartType, {
        today: () => (
          <Area
            key="today"
            label={indicatorLabel}
            width={width}
            values={countryData}
            tooltipRowLabels={tooltipRowLabels}
            formatValue={formatValue}
            formatAxisValue={formatAxisValue}
          />
        ),
        timeseries: () => (
          <Lines
            key="timeseries"
            label={indicatorLabel}
            width={width}
            values={regionData}
            staggerDelay={renderMode === "static" ? 0 : undefined}
            showTarget={renderMode !== "embed"}
          />
        ),
      })}
    </HeroControlsWrapper>
  );
};

const csv = require("../data/data_access_hero.zip");
const xlsx = require("../data/data_access_hero.xlsx");

const HA = ({ renderMode = "default" }: { renderMode: RenderMode }) => {
  const i18n = useI18n();
  const [ref, contentRect] = useContentRect();
  const Renderer = withDataLoader(
    require("../data/data_access_hero.json"),
    Data,
    (props) => (
      <Chart {...props} width={contentRect.width} csv={csv} xlsx={xlsx} />
    ),
    { narrow: 400, wide: 400 }
  );

  const children = (
    <div ref={ref} style={{ width: "100%", overflow: "hidden" }}>
      <Renderer key="access" renderMode={renderMode} />
    </div>
  );

  return renderMode === "embed" ? (
    <EmbedChart
      caption={i18n._(infoLabel)}
      source={i18n._(metadata.source)}
      isHeroChart
    >
      <HeroStateProvider initial={"today"}>{children}</HeroStateProvider>
    </EmbedChart>
  ) : renderMode === "static" ? (
    <StaticChart
      layout={"columns"}
      title={i18n._(metadata.title)}
      caption={i18n._(metadata.caption)}
      source={i18n._(metadata.source)}
    >
      <HeroStateProvider initial={"today"}>{children}</HeroStateProvider>
      <div style={{ height: "40px" }}></div>
      <HeroStateProvider initial={"timeseries"}>{children}</HeroStateProvider>
    </StaticChart>
  ) : (
    <HeroStateProvider>
      <Hero
        title={i18n._(metadata.title)}
        caption={i18n._(metadata.caption)}
        targetTitle={i18n._(targetTitle)}
        targetLabel={i18n._(targetLabel)}
      >
        {children}
      </Hero>
    </HeroStateProvider>
  );
};
export default HA;
