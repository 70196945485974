import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { keyof } from "io-ts";
import { keyObject } from "lib";
import { io } from "../prelude";

// Types
const DisparityCategory = io.union([
  io.literal("WEALTH"),
  io.literal("GENDER"),
  io.literal("GEOGRAPHY"),
]);
export type DisparityCategory = io.TypeOf<typeof DisparityCategory>;
export const DISPARITY_CATEGORIES: DisparityCategory[] = [
  "WEALTH",
  "GENDER",
  "GEOGRAPHY",
];

export const DISPARITY_GROUPS = [
  "all",
  "bottom20",
  "top20",
  "f",
  "m",
  "urban",
  "rural",
];

const DisparityGroup = keyof(keyObject(DISPARITY_GROUPS));
export type DisparityGroup = io.TypeOf<typeof DisparityGroup>;

export const disparitiesGroupsLookup: Record<
  DisparityCategory,
  [DisparityGroup, DisparityGroup]
> = {
  WEALTH: ["bottom20", "top20"],
  GENDER: ["f", "m"],
  GEOGRAPHY: ["urban", "rural"],
};

// -----------------------------------------------------------------------------
// Translations Lookups

export const GroupLabelLookup: Record<DisparityGroup, MessageDescriptor> = {
  bottom20: t("fig.learning.trajectories.explorer.group.label.bottom20")`Poor`,
  top20: t("fig.learning.trajectories.explorer.group.label.top20")`Rich`,
  f: t("fig.learning.trajectories.explorer.group.label.f")`Girls`,
  m: t("fig.learning.trajectories.explorer.group.label.m")`Boys`,
  urban: t("fig.learning.trajectories.explorer.group.label.urban")`Urban`,
  rural: t("fig.learning.trajectories.explorer.group.label.rural")`Rural`,
};

interface DisparitySimulationConfig {
  group: DisparityGroup;
  comparisonGroup: DisparityGroup;
}

export const disparitySimulationLookup: Record<
  DisparityCategory,
  DisparitySimulationConfig
> = {
  WEALTH: { group: "bottom20", comparisonGroup: "top20" },
  GENDER: { group: "f", comparisonGroup: "m" },
  GEOGRAPHY: { group: "rural", comparisonGroup: "urban" },
};
