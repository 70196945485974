import { io } from "../prelude";

// -----------------------------------------------------------------------------
// Sex

export const Sex = io.union([io.literal("Female"), io.literal("Male")]);
export type Sex = io.TypeOf<typeof Sex>;

// -----------------------------------------------------------------------------
// Year

export interface YearBrand {
  readonly Year: unique symbol;
}

export const Year = io.brand(
  io.number,
  (n): n is io.Branded<number, YearBrand> => Number.isInteger(n),
  "Year"
);

export type Year = io.TypeOf<typeof Year>;
