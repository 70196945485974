import memoizeOne from "memoize-one";
import { An, Eq, flow, nt, O, pipe, R } from "../prelude";
import { RegionId } from "./region";

export type CountryId = nt.Newtype<"CountryId", string>;
export const countryIdIso = nt.iso<CountryId>();
export const countryIdEq = nt.getEq<CountryId>(Eq.eqString);

export interface CountryMeta {
  iso3c: CountryId;
  country: string;
  sdg_region: RegionId;
  income: RegionId | null;
  level_ages: [number, number, number]; // [primary start, secondary start, secondary end]
}

export const getCountryMeta = (function () {
  const countriesById = memoizeOne((countries: Array<CountryMeta>) => {
    return pipe(
      An.fromArray(countries),
      O.map(flow(An.groupBy((x) => countryIdIso.unwrap(x.iso3c))))
    );
  });

  return (countries: Array<CountryMeta>, id: CountryId) =>
    pipe(
      countriesById(countries),
      O.chain((r) => R.lookup(countryIdIso.unwrap(id), r)),
      O.map(An.head)
    );
})();

export const getCountryName = (countries: Array<CountryMeta>, id: CountryId) =>
  pipe(
    getCountryMeta(countries, id),
    O.map((x) => x.country),
    O.getOrElse(() => {
      console.warn(`No country metadata found for "${id}"`);
      return countryIdIso.unwrap(id);
    })
  );
