import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { useReadScrolly } from "components/scrolly";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_finance_aid_fig_SECTOR_ALLOCABLE";
import { ChartRenderer, mkGemDataDecoder } from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, pipe, O } from "../prelude";

export * from "../data/data_finance_aid_fig_SECTOR_ALLOCABLE";

const Sector = io.keyof({
  Education: null,
  Health: null,
});
type Sector = io.TypeOf<typeof Sector>;

const sectorLookup: Record<Sector, MessageDescriptor> = {
  Education: t("fig.finance.aid.SECTOR_ALLOCABLE.education")`Education`,
  Health: t("fig.finance.aid.SECTOR_ALLOCABLE.health")`Health`,
};

const sectorDescriptionLookup: Record<Sector, MessageDescriptor> = {
  Education: t(
    "fig.finance.AID_LICS.education_detail"
  )`Education in sector allocable ODA`,
  Health: t(
    "fig.finance.AID_LICS.health_detail"
  )`Health in sector allocable ODA`,
};

export const Data = mkGemDataDecoder(["value", "ind_id", "year"], {
  sector: Sector,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const { activeSection } = useReadScrolly();

  const scrollySector = pipe(
    activeSection,
    O.getOrElse(() => "")
  );

  const entityData = React.useMemo(
    () =>
      data.map((x) => ({
        ...x,
        sector: i18n._(sectorLookup[x.sector]),
        sector_description: i18n._(sectorDescriptionLookup[x.sector]),
      })),
    [i18n, data]
  );

  return (
    <LineChart
      tLabel={(s) => s}
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      x="year"
      yTicks={[0, 0.1, 0.2, 0.3]}
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".1%"
      category="datum.sector_description"
      tooltipFormatX={(x) => x.sector}
      colorLegend
      values={entityData}
      showTooltip
      isFaded={(x) => scrollySector !== "" && x.sector !== scrollySector}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_SECTOR_ALLOCABLE.json"),
  csv: require("../data/data_finance_aid_fig_SECTOR_ALLOCABLE.zip"),
  xlsx: require("../data/data_finance_aid_fig_SECTOR_ALLOCABLE.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
