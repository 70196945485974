import css from "@emotion/css";
import * as React from "react";
import { mkStyles } from "../hooks";
import * as M from "../materials";
import checkUrl from "./select/check.svg?raw";

const useStyles = mkStyles(({ spacing: { base8 }, utils }) => ({
  root: css`
    height: ${base8(4)};
    display: flex;
    align-items: center;
    min-width: 180px;
    margin-bottom: ${M.spacing.base8(1)};
    background: ${M.whiteText};
    border: 1px solid ${M.grayscalePalette[4]};
    border-radius: 4px;
    color: ${M.unescoMarineBlue};
    ${M.fontTable};

    & input {
      position: absolute;
      width: 0;
      height: 0;
      z-index: -9999;
    }

    & span {
      width: ${base8(2)};
      height: ${base8(2)};
      display: inline-block;
      background-color: white;
      border: 1px solid ${M.unescoMarineBlue};
      border-radius: 2px;
      margin-left: ${base8(1)};
      margin-right: ${base8(1)};
    }

    & input:checked + span {
      background-color: ${M.unescoMarineBlue};
      background-image: url(${checkUrl});
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
      color: white;
      content: " ";
    }

    &:focus: {
      box-shadow: ${utils.fade(M.blackText, 0.5)};
    }
  `,
  check: css`
    border: 1px solid ${M.unescoMarineBlue};
    margin: ${M.spacing.base8(1)};
  `,
}));

interface CheckboxProps {
  label: React.ReactNode;
  onChange(checked: boolean): void;
  checked?: boolean;
}

export function Checkbox({ label, onChange, checked = false }: CheckboxProps) {
  const styles = useStyles();
  const [state, setState] = React.useState(checked);

  React.useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return (
    <label css={styles.root}>
      <input
        type="checkbox"
        css={styles.check}
        checked={state}
        onChange={() => setState(!state)}
      />
      <span></span>
      <p>{label}</p>
    </label>
  );
}
