
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Access";
export const lead = "Globally, 89% of children complete primary school, 76% of adolescents complete lower secondary school and 50% of youth complete upper secondary school.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const PageLink = makeShortcode("PageLink");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`The world failed on its promise for all children to go to primary school by 2015. The new education goal, SDG 4, has set the level of ambition a step higher, calling for all young people to complete secondary school by the 2030 deadline. To achieve this, all children of school starting age should have begun school in 2018, but in reality, only 70% did so in low-income countries for instance.`}</p>
    </blockquote>
    <p>{`Around one in sixty children, most of them in low-income countries, will `}<strong parentName="p">{`never go to school`}</strong>{`. Girls are still more likely than boys to never go to school, as discrimination against them means that they are expected to carry out childcare and other household responsibilities.`}</p>
    <h2 {...{
      "id": "pre-school-attendance"
    }}>{`Pre-school attendance`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Attending pre-school`}</strong>{` is a crucial starting point for children's journey through education. Yet, in low-income countries, only 73% of children enter pre-primary education on time.`}</p>
    </IndicatorReference>
    <p>{`Since 2000, progress in pre-school attendance has been slow but steady across all countries, including in many of the poorest, but many are still left behind. Globally, 69% of children now attend pre-primary education compared to 61% in 2009.`}</p>
    <p>{`Some countries have seen vast increases. In Kyrgyzstan, for example, the participation rate increased from 42% in 2000 to 95% in 2017. In Bolivia, it increased from 66% to 92% over the same period.`}</p>
    <p>{`Both `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Cabo Verde`}</HighlightByIndex>{` and `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Senegal`}</HighlightByIndex>{` highlighted below are examples of countries that have seen a steady increase in pre-primary attendance, even though there are huge gaps between the two today. Select your country to see its progress.`}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "over-age-participation"
    }}>{`Over-age participation`}</h2>
    <IndicatorReference id="4.1.6" mdxType="IndicatorReference">
      <p>{`We do not talk enough about the fact that in a number of countries more than a quarter of primary school students are `}<strong parentName="p">{`over-age`}</strong>{` when they enrol, meaning they are significantly older than students in their grade are supposed to be. This has significant implications for their future, increasing the likelihood of further repeating grades, failing exams and eventually dropping out of school.`}</p>
    </IndicatorReference>
    <p>{`This graph looks at `}<strong parentName="p">{`education participation`}</strong>{`, showing how old children, adolescents and youth are when they attend pre-primary, primary, lower and upper secondary, as well as post-secondary education. The spread of colour indicates how many children are over-age for their education level. The grey shaded area shows the percentage of children and adolescents who are out of school by age.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OVERAGE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="PAK" mdxType="ScrollySection">
    In <Highlight mdxType="Highlight">Pakistan</Highlight>, most children should be in school at 10
    years old, yet 24% are still out of school at that age. Many are too old for
    their grade. There are 14-year-olds who are still attending primary school,
    for instance.
  </ScrollySection>
  <ScrollySection id="HTI" mdxType="ScrollySection">
    The over-age rate is even higher in countries such as{" "}
    <Highlight mdxType="Highlight">Haiti</Highlight> with 5% of 10 year olds in pre-school and 17%
    of 19 year olds still in primary school.
  </ScrollySection>
  <ScrollySection id="LBR" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Liberia</Highlight> faces the same challenge
  </ScrollySection>
  <ScrollySection id="MWI" mdxType="ScrollySection">
    as does <Highlight mdxType="Highlight">Malawi</Highlight>.
  </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "out-of-school-children"
    }}>{`Out-of-school children`}</h2>
    <IndicatorReference id="4.1.5" mdxType="IndicatorReference">
      <p>{`Globally, there are 258 million `}<strong parentName="p">{`out of school`}</strong>{` children, a number that has stagnated since 2007 in the case of primary education and 2012 in secondary education.`}</p>
      <p>{`Conflict is a key reason for this stagnation. Unfortunately conflict also means that the availability and quality of data deteriorates, although some countries continue to report estimates.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OOSRATE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="IDN,MYS" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Indonesia</Highlight> and <Highlight mdxType="Highlight">Malaysia</Highlight> show
    very different directions of progress. In upper secondary education onwards,
    the out-of-school rate in Indonesia has plummeted, while it has climbed or
    stagnated in Malaysia.
  </ScrollySection>
  <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Eritrea</Highlight> and <Highlight mdxType="Highlight">Ethiopia</Highlight> also show
    very different rates of progress. The after-effects of conflict in Eritrea
    visibly took a toll on education access, while Ethiopia has prioritised
    education in its budget, which led to the rapid reduction in out-of-school
    children over a short period of time.
  </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Another reason for a lack of progress in reducing out-of-school numbers is population growth.`}</p>
    <p>{`In sub-Saharan Africa, the primary `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`school-aged population`}</HighlightByIndex>{` has more than doubled between 1990 and 2017. As a result, even though the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`rate of out-of-school children`}</HighlightByIndex>{` has more than halved during this period, the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`number of children out-of-school`}</HighlightByIndex>{` has barely changed.`}</p>
    <p>{`In Niger and in Chad, for instance, the percentage of primary school-age children out of school has roughly halved in the past twenty years, but the number of children has roughly doubled. As a result, the number of children out of school has hardly changed.`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "completion"
    }}>{`Completion`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`The ultimate objective of SDG 4 is universal upper secondary `}<strong parentName="p">{`completion`}</strong>{` and not just enrolment. This is despite the fact that, in many countries, education is only free and compulsory up to lower secondary education at most.`}</p>
      <p>{`The world is not even on track to achieve universal primary completion by the deadline, let alone upper secondary completion.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Low-income countries`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Global`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`2030`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Primary completion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`68%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`89%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`93%`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Lower secondary completion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`41%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`76%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`85%`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Upper secondary completion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`19%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`50%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`60%`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`The indicator refers to children who may complete education levels up to 3 or 5 years later than the official graduation age – this can be defined as `}<Tooltip title="Timely completion" content="Children who complete education levels up to 3 or 5 years beyond the final grade" mdxType="Tooltip">{`timely completion`}</Tooltip>{`.`}</p>
      <p>{`But many children complete even later than that - this can be defined as `}<Tooltip title="Ultimate completion" content="Children who complete no matter how late" mdxType="Tooltip">{`ultimate completion`}</Tooltip>{`. In low-income countries, for instance, 55% completed primary education 3 to 5 years beyond the final grade (typically ages 14 to 16) in 2018, but 68% completed 8 years later.`}</p>
      <p>{`Looking at the graph below, in Mozambique, for example, there is a 20 percentage point gap between `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`timely`}</HighlightByIndex>{` (77%) and `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`ultimate`}</HighlightByIndex>{` completion (55%) at the primary level. Nepal shows a similar gap.`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`When children enrol, at what age and whether or not they graduate are crucial policy concerns.`}</p>
    <p>{`The next themes on this site look at how close we are to achieving `}<PageLink name="equity" mdxType="PageLink">{`equity`}</PageLink>{` in education, what students are `}<PageLink name="learning" mdxType="PageLink">{`learning`}</PageLink>{`, how good their education `}<PageLink name="quality" mdxType="PageLink">{`quality`}</PageLink>{` is, and who `}<PageLink name="finance" mdxType="PageLink">{`finances`}</PageLink>{` it.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
          }}>{`Education above the odds – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unicef.org/reports/a-world-ready-to-learn-2019"
          }}>{`A world ready to learn: Prioritizing quality early childhood education – UNICEF`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining data on out-of-school children, Completion and learning to offer a more comprehensive view on SDG 4`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;