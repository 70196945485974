import { Ar, O, pipe } from "../prelude";

export type AidFlowType = "donor" | "level" | "recipient" | "channel";

export interface AidFlowMeta {
  type: AidFlowType;
  id: string;
  label: string;
}

export const getAidFlowName = (function () {
  return (xs: Array<AidFlowMeta>, type: AidFlowType, id: string) =>
    pipe(
      xs,
      Ar.findFirst((x) => x.type === type && x.id === id),
      O.map((x) => x.label),
      O.getOrElse(() => {
        console.warn(`No ${type} metadata found for "${id}"`);
        return id;
      })
    );
})();
