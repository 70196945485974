import { scaleOrdinal } from "d3-scale";
import { pipe } from "fp-ts/lib/pipeable";
import { Ar, O } from "../prelude";

export const unescoDarkBlue = "#23305f";
export const unescoMarineBlue = "#0075D1";
export const unescoDarkRed = "#C72031";

export const unescoMainBlue = "#0069B4";

export const unescoMainGreen = "#23B850";

export const gemTeal = "#00A99D";
export const gemRed = "#C72030";

export const blackText = "#333333";
export const whiteText = "#FFFFFF";
export const lightText = "#585858";

// Used in charts – so far
export const divider = "#DDDDDD";

// Explorer
export const background = "#D5D5D5";

// Linear palettes
export const grayscalePalette = [
  "#FFFFFF",
  "#FAFAFA",
  "#F5F5F5",
  "#EBEBEB",
  "#DDDDDD",
  "#B1B1B1",
  "#858585",
  "#585858",
  "#333333",
  "#000000",
];

export const q01Palette = [
  "#FFEEB2",
  "#FFDD6E",
  "#FFCC32",
  "#F5B300",
  "#E59A00",
  "#CF8100",
  "#B26800",
  "#8F4E00",
  "#663400",
  "#361A00",
];

export const q02Palette = [
  "#FFE5C1",
  "#FFCC8B",
  "#FFB45B",
  "#F99D33",
  "#E9820C",
  "#D36900",
  "#B65100",
  "#923B00",
  "#682600",
  "#371200",
];

export const q03Palette = [
  "#FFD6EB",
  "#FFB1D6",
  "#FF8FC2",
  "#FF71AD",
  "#FF5799",
  "#FF3F85",
  "#F72C70",
  "#DA1C5C",
  "#A10B3D",
  "#58011F",
];

export const q04Palette = [
  "#FFD7F7",
  "#FFB3EC",
  "#FF92E0",
  "#FF74D1",
  "#FF5AC0",
  "#ED43AC",
  "#D62F96",
  "#B91F7E",
  "#870E58",
  "#49042E",
];

export const q05Palette = [
  "#FFDAFF",
  "#FDB8FB",
  "#F599F3",
  "#EA7CE7",
  "#DA63D7",
  "#C64CC3",
  "#AE38AB",
  "#92278F",
  "#681566",
  "#370836",
];

export const q06Palette = [
  "#E8E4FF",
  "#D1C9FF",
  "#BAB0FF",
  "#A498FF",
  "#8E81FF",
  "#796BFA",
  "#6457E1",
  "#4F43C1",
  "#342B8D",
  "#1A144C",
];

export const q07Palette = [
  "#C7F3FF",
  "#95E4FF",
  "#6AD4FF",
  "#45C1F9",
  "#28ACE8",
  "#1194D1",
  "#007BB3",
  "#00608F",
  "#004266",
  "#002236",
];

export const q08Palette = [
  "#C1F9F5",
  "#8BF0E9",
  "#5DE3DA",
  "#36D3C8",
  "#17C0B4",
  "#00A99D",
  "#008E83",
  "#007066",
  "#004E47",
  "#002925",
];

export const q09Palette = [
  "#E6F6D0",
  "#CEECA4",
  "#B7E17F",
  "#A2D45D",
  "#8DC63F",
  "#70AD1A",
  "#549100",
  "#3B7200",
  "#254F00",
  "#112900",
];

export const q10Palette = [
  "#EFF7B2",
  "#DFEE6D",
  "#CEE332",
  "#BDD600",
  "#A4C100",
  "#8AA900",
  "#708E00",
  "#556F00",
  "#394D00",
  "#1D2800",
];

export const q1 = q06Palette[7];
export const q2 = q03Palette[7];
export const q3 = q08Palette[5];
export const q4 = q01Palette[2];
export const q5 = q07Palette[6];
export const q6 = q05Palette[7];
export const q7 = q02Palette[3];
export const q8 = q09Palette[4];
export const q9 = q04Palette[7];
export const q10 = q10Palette[3];

export const discretePalette = [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10];

export const divergingPalette = [
  ...fromCount(q03Palette, 4).reverse(),
  grayscalePalette[4],
  grayscalePalette[4],
  ...fromCount(q06Palette, 4),
];

export const divergingPaletteAlt = [
  ...fromCount(q08Palette, 4).reverse(),
  grayscalePalette[4],
  grayscalePalette[4],
  ...fromCount(q06Palette, 4),
];

export const colorRanges = {
  discrete: discretePalette,
  diverging: divergingPalette,
  divergingAlt: divergingPaletteAlt,
  sequential: [
    [...q06Palette].reverse(),
    [...q03Palette].reverse(),
    [...q08Palette].reverse(),
    [...q01Palette].reverse(),
    [...q07Palette].reverse(),
    [...q05Palette].reverse(),
    [...q02Palette].reverse(),
    [...q09Palette].reverse(),
    [...q04Palette].reverse(),
    [...q10Palette].reverse(),
  ],
  sequentialGray: [...grayscalePalette].reverse(),
};

export type ColorRanges = typeof colorRanges;

export function fromIndexDiscrete(discretePalette: Array<string>, idx: number) {
  return discretePalette[idx % discretePalette.length];
}

export function fromIndex(paletteGroup: Array<Array<string>>, idx: number) {
  return paletteGroup[idx % paletteGroup.length];
}

export function fromCount(palette: Array<string>, count: number) {
  if (palette.length <= 10) {
    switch (count) {
      case 1:
        return [palette[2]];
      case 2:
        return [palette[1], palette[8]];
      case 3:
        return [palette[1], palette[5], palette[8]];
      case 4:
        return [palette[1], palette[3], palette[6], palette[8]];
      case 5:
        return [palette[1], palette[3], palette[5], palette[7], palette[8]];
      case 6:
        return [
          palette[1],
          palette[2],
          palette[3],
          palette[6],
          palette[7],
          palette[8],
        ];
      case 7:
        return [
          palette[0],
          palette[1],
          palette[3],
          palette[5],
          palette[7],
          palette[8],
          palette[9],
        ];
      case 8:
        return [
          palette[0],
          palette[1],
          palette[2],
          palette[3],
          palette[6],
          palette[7],
          palette[8],
          palette[9],
        ];
      case 9:
        return [
          palette[0],
          palette[1],
          palette[2],
          palette[3],
          palette[5],
          palette[6],
          palette[7],
          palette[8],
          palette[9],
        ];
      default:
        return palette;
    }
  } else {
    return palette;
  }
}

/**
 * mkScrollyColorPalette
 * @param sections An array of section IDs, e.g. ["IDN,MYS", "ERI"]
 * @param idO An entry of the sections array (falls back to default palette otherwise)
 */
export function mkScrollyColorPalette(
  sections: Array<string>,
  idO: O.Option<string>
) {
  return pipe(
    idO,
    O.chain((id) =>
      pipe(
        sections,
        Ar.findIndex((x) => id === x)
      )
    ),
    O.fold(
      () => colorRanges.sequential[0],
      (idx) => fromIndex(colorRanges.sequential, idx)
    )
  );
}

/**
 * mkScrollyColorPalette
 * @param sections An array of section IDs, e.g. ["IDN,MYS", "ERI"]
 * @param multiIdO A string that contains one or multiple IDs (separated by comma), e.g. "IDN,MYS"
 */
export function mkScrollyColorScale(
  sections: Array<string>,
  multiIdO: O.Option<string>
) {
  const count = pipe(
    multiIdO,
    O.fold(
      () => 1,
      (id) => id.split(",").length
    )
  );
  const palette = mkScrollyColorPalette(sections, multiIdO);
  return scaleOrdinal(fromCount(palette, count));
}
