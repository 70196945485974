import facepaint from "facepaint";
import { mapResponsive, Responsive, responsiveToArray } from "./types";

/**
 * xs: `0`,
 * s: `600`,
 * m: `900`,
 * l: `1200`
 */
export const bp: Responsive<number> = {
  xs: 0,
  s: 600,
  m: 900,
  l: 1200,
};
export type Breakpoint = keyof typeof bp;

export const bpDown = (b: Breakpoint) => `(max-width: ${bp[b] - 1}px)`;
export const bpUp = (b: Breakpoint) => `(min-width: ${bp[b]}px)`;

export const mqFeatures: Responsive<string> = {
  xs: "screen",
  s: `screen and (min-width: ${bp.s}px)`,
  m: `screen and (min-width: ${bp.m}px)`,
  l: `screen and (min-width: ${bp.l}px)`,
};

export const mq: (...styles: $FixMe[]) => $FixMe = facepaint(
  responsiveToArray(
    mapResponsive(mqFeatures, (features: string) => `@media ${features}`)
  ).slice(1)
);
