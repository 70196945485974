import css from "@emotion/css";
import React from "react";
import ReactDOM from "react-dom";

const PORTAL_ROOT_ID = "portal-root";

const portalRootStyle = css`
  position: absolute;
  top: 0;
  left: 0;
`;

/**
 * Used through React Portal to display modal dialogs, tooltips, etc.
 */
export function PortalRoot() {
  return <div id={PORTAL_ROOT_ID} css={portalRootStyle}></div>;
}

/**
 * Use this to render a component outside of the normal application's DOM tree
 * in the "portal root". This element is defined in `pages/_document.tsx` next
 * to the application's root DOM node.
 *
 * See https://reactjs.org/docs/portals.html
 */
export function Portal({ children }: $PropsWithChildren) {
  const modalRoot = document.getElementById(PORTAL_ROOT_ID);
  return modalRoot
    ? ReactDOM.createPortal(children, modalRoot)
    : (console.error(
        `Portal root not found at id #${PORTAL_ROOT_ID}, could not create portal.`
      ),
      null);
}
