import React from "react";
import { motion } from "framer-motion";
import { springConfigs } from "../motion";

const HoverCircle = React.memo(
  ({
    cx,
    cy,
    r,
    fill,
    stroke,
  }: {
    cx: number;
    cy: number;
    r: number;
    fill: string;
    stroke: string;
  }): React.ReactElement => (
    <motion.circle
      strokeWidth={3}
      pointerEvents={"none"}
      initial={{
        cx,
        cy,
        opacity: 0,
        r: 0,
        fill,
        stroke,
      }}
      animate={{
        cx,
        cy,
        opacity: 1,
        r,
        fill,
        stroke,
      }}
      exit={{ opacity: 0, r: 0, fill, stroke }}
      transition={springConfigs.gentle}
    />
  )
);

export default HoverCircle;
