/**
 * Note: lot's of hard-coded things in this chart, because it's the only one.
 * Please adjust, if it turns out we need it more generically.
 */
import css from "@emotion/css";
import { Delaunay } from "d3-delaunay";
import { format } from "d3-format";
import * as scale from "d3-scale";
import * as shape from "d3-shape";
import { flow } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import { motion } from "framer-motion";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { ChartTooltip, TooltipGridBody } from "../components/tooltip";
import { useContentRect, usePointerPosition } from "../hooks";
import { extentNumber, insertionOrderSet } from "../lib";
import * as M from "../materials";
import { An, Ar, O, Ord, R } from "../prelude";
import { ChartBasis } from "./stacked-bar";

const formatPct = format(".1%");
const formatPctRound = format(".0%");

const labelStyle = css`
  ${M.fontAxisLabel};
  fill: ${M.lightText};
`;

interface DataShape {
  year: number;
}

export interface ScatterplotProps<A extends DataShape> extends ChartBasis {
  data: An.NonEmptyArray<A>;
  height: number;
  width: number;
  getX(x: A): number;
  getY(x: A): number;
  getColor(x: A): string;
  getGroup(x: A): string;
  xLabel: string;
  yLabel: string;
  formatX?(x: number): string;
  formatY?(x: number): string;
  formatColor(x: A): string;
  formatGroup(x: A): string;
  selectedYear: number;
  displaySelectedYear?: boolean;
  highlightedGroups: O.Option<Array<string>>;
  annotations?: {
    x: Array<number>;
    y: Array<number>;
    labels: Array<{
      label: string;
      x: number;
      y: number;
      dx: string;
      dy: string;
    }>;
  };
  xTicks?: Array<number>;
  yTicks?: Array<number>;
  displayColorLegend?: boolean;
  onSelect?(x: A): void;
}

export const Scatterplot = React.memo(Scatterplot_);
function Scatterplot_<A extends DataShape>(p: ScatterplotProps<A>) {
  const [pointerPositionRef, pointerPosition] =
    usePointerPosition<SVGRectElement>();

  const margin = React.useMemo(
    () => ({ top: 30, right: 16, bottom: 38, left: 40 }),
    []
  );
  const outerHeight = p.height + margin.top + margin.bottom;
  const innerHeight = p.height;
  const outerWidth = p.width;
  const innerWidth = p.width - margin.left - margin.right;

  const { xScale, xPos, yScale, yPos, timeSteps } = React.useMemo(() => {
    const xDomain = p.xTicks ? p.xTicks : extentNumber(p.data, p.getX);
    const xScale = scale.scaleLinear().domain(xDomain).range([0, innerWidth]);
    const xPos = (x: A) => xScale(p.getX(x));

    const yDomain = p.yTicks ? p.yTicks : extentNumber(p.data, p.getY);
    const yScale = scale.scaleLinear().domain(yDomain).range([innerHeight, 0]);
    const yPos = (x: A) => yScale(p.getY(x));

    const timeSteps = Ar.sort(Ord.ordNumber)(
      Array.from(new Set(p.data.map((x) => x.year)).values())
    );
    const timeExtent = [timeSteps[0], timeSteps[timeSteps.length - 1]];
    const timeScale = (x: number) => timeSteps.indexOf(x);
    timeScale.invert = (x: number) => timeSteps[x];

    return {
      xDomain,
      xScale,
      xPos,
      yDomain,
      yScale,
      yPos,
      timeScale,
      timeSteps,
      timeExtent,
    };
    // eslint-disable-next-line
  }, [innerHeight, innerWidth, p.getX, p.getY, p.data]);

  const [colorScale, colorLegendValues] = useColorScale({
    data: p.data,
    getColor: p.getColor,
  });

  const yearlyData = React.useMemo(
    () => yearlyDataPerGroup({ getGroup: p.getGroup, data: p.data, timeSteps }),
    [p.getGroup, p.data, timeSteps]
  );

  const snapshotData = React.useMemo(() => {
    return pipe(
      yearlyData,
      R.filterMap(flow(Ar.findFirst((x) => x.year === p.selectedYear))),
      R.collect((_, xs) => xs)
    );
  }, [yearlyData, p.selectedYear]);

  const delaunay = React.useMemo(
    () => Delaunay.from(snapshotData, xPos as $FixMe, yPos as $FixMe),
    [snapshotData, xPos, yPos]
  );

  const hoveredDatum = React.useMemo(() => {
    return pipe(
      pointerPosition,
      O.chain((pos) => {
        const i = delaunay.find(pos.x, pos.y);
        const datum = snapshotData[i];
        const cx = xPos(datum);
        const cy = yPos(datum);
        return distance([pos.x, pos.y], [cx as $FixMe, cy as $FixMe]) <= 20
          ? O.some(datum)
          : O.none;
      })
    );
  }, [delaunay, pointerPosition, snapshotData, xPos, yPos]);

  const timeseriesData = React.useMemo(() => {
    return pipe(
      pipe(
        hoveredDatum,
        O.map((x) =>
          [p.getGroup(x)].concat(
            pipe(
              p.highlightedGroups,
              O.fold(
                () => [],
                (xs) => xs
              )
            )
          )
        )
      ),
      O.alt(() => p.highlightedGroups),
      O.map((xs) => {
        return pipe(
          yearlyData,
          R.filterWithIndex((k) => xs.includes(k)),
          R.filterMap((xs) =>
            An.fromArray(xs.filter((x) => x.year <= p.selectedYear))
          ),
          R.collect((_, vs) => vs)
        );
      })
    );
    // eslint-disable-next-line
  }, [
    p.highlightedGroups,
    yearlyData,
    hoveredDatum,
    p.getGroup,
    p.selectedYear,
  ]);

  const lineGenerator = React.useMemo(() => {
    return shape
      .line<A>()
      .x(xPos as $FixMe)
      .y(yPos as $FixMe)
      .curve(shape.curveCatmullRom);
  }, [xPos, yPos]);

  const displaySelectedYear = p.displaySelectedYear ?? true;

  const displayColorLegend = p.displayColorLegend ?? true;

  return (
    <div>
      <svg height={outerHeight} width={outerWidth}>
        <g key={"axes"} transform={`translate(${margin.left}, ${margin.top})`}>
          <text css={labelStyle} x={0} dy={"-0.6em"}>
            {p.yLabel}
          </text>
          <text
            css={labelStyle}
            x={innerWidth}
            y={innerHeight}
            dy={"2.5em"}
            textAnchor={"end"}
          >
            {p.xLabel}
          </text>
          <XTicks formatTick={p.formatX} xScale={xScale} height={innerHeight} />
          <YTicks formatTick={p.formatY} yScale={yScale} width={innerWidth} />

          {p.annotations && (
            <Annotations
              xScale={xScale}
              yScale={yScale}
              annotations={p.annotations}
              height={innerHeight}
              width={innerWidth}
            />
          )}

          {displaySelectedYear && (
            <text textAnchor={"end"}>
              <tspan
                x={innerWidth - 12}
                y={innerHeight}
                dy={"-48px"}
                css={css`
                  ${M.fontChartLabel};
                  fill: ${M.divider};
                `}
              >
                Year
              </tspan>
              <tspan
                x={innerWidth - 12}
                y={innerHeight}
                dy={"-0.6em"}
                css={css`
                  ${M.fontHeading1};
                  fill: ${M.divider};
                `}
              >
                {p.selectedYear}
              </tspan>
            </text>
          )}
        </g>

        <g key={"inner"} transform={`translate(${margin.left}, ${margin.top})`}>
          {snapshotData.map((datum) => {
            const cx = xPos(datum);
            const cy = yPos(datum);

            return (
              <motion.circle
                key={p.getGroup(datum)}
                r={5}
                fill={colorScale(p.getColor(datum))}
                opacity={0.25}
                initial={{ cx, cy }}
                animate={{ cx, cy }}
                transition={{ duration: 1, easing: "linear" }}
              />
            );
          })}
          {pipe(
            timeseriesData,
            O.map((xxs) =>
              xxs.map((xs, i) => (
                <React.Fragment key={i}>
                  <motion.path
                    d={lineGenerator(xs) || ""}
                    stroke={colorScale(p.getColor(xs[0]))}
                    strokeWidth={1}
                    fill="transparent"
                  />
                  {xs.map((datum) => {
                    const cx = xPos(datum);
                    const cy = yPos(datum);
                    const r = datum.year === p.selectedYear ? 5 : 2;
                    return (
                      <motion.circle
                        key={`${p.getGroup(datum)}-${datum.year}`}
                        fill={colorScale(p.getColor(datum))}
                        initial={{ cx, cy, r }}
                        animate={{ cx, cy, r }}
                      />
                    );
                  })}
                </React.Fragment>
              ))
            ),
            O.toNullable
          )}
          {pipe(
            hoveredDatum,
            O.map((datum) => (
              <Highlight
                key="highlight"
                datum={datum}
                getX={p.getX}
                getY={p.getY}
                getColor={p.getColor}
                xPos={xPos as $FixMe}
                yPos={yPos as $FixMe}
                formatTitle={p.formatGroup}
                formatX={p.formatX}
                formatY={p.formatY}
                xLabel={p.xLabel}
                yLabel={p.yLabel}
                colorScale={colorScale}
              />
            )),
            O.toNullable
          )}
          <rect
            ref={pointerPositionRef}
            x={0}
            y={0}
            style={{ cursor: "pointer" }}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            onClick={() =>
              pipe(
                hoveredDatum,
                O.map((x) => p.onSelect && p.onSelect(x))
              )
            }
          />
        </g>
      </svg>
      {displayColorLegend && (
        <div style={{ marginLeft: margin.left }}>
          <ColorLegend
            maxWidth={innerWidth}
            inline
            values={colorLegendValues}
          />
        </div>
      )}
    </div>
  );
}

export function ScatterplotAuto<A extends DataShape>(
  props: Omit<ScatterplotProps<A>, "width">
) {
  const [ref, contentRect] = useContentRect();
  return (
    <div ref={ref} style={{ width: "100%" }}>
      {contentRect.width > 0 && (
        <Scatterplot {...props} width={contentRect.width} />
      )}
    </div>
  );
}

// -----------------------------------------------------------------------------

export function useColorScale<A extends DataShape>({
  ...p
}: Pick<ScatterplotProps<A>, "data" | "getColor">) {
  const domainColor = React.useMemo(
    () => insertionOrderSet(p.data, p.getColor),
    [p.data, p.getColor]
  );

  const colorScale = React.useMemo(
    () => scale.scaleOrdinal(M.colorRanges.discrete).domain(domainColor),
    [domainColor]
  );

  const colorLegendValues = React.useMemo(() => {
    return domainColor.map((key) => ({
      color: colorScale(key),
      label: key,
    }));
  }, [colorScale, domainColor]);

  return [colorScale, colorLegendValues] as const;
}

// -----------------------------------------------------------------------------

const axisLineStyle = css`
  stroke-width: 1;
  stroke: rgba(0, 0, 0, 0.15);
  shape-rendering: crispEdges;
`;

const tickLabel = css`
  ${M.fontAxisLabel};
  fill: ${M.lightText};
`;

const XTicks = React.memo(
  ({
    xScale,
    formatTick = formatPctRound,
    height,
  }: {
    xScale: scale.ScaleLinear<number, number>;
    formatTick?: (x: number) => string;
    height: number;
  }) => {
    const ticks = xScale.domain();
    return (
      <>
        {ticks.map((tick, i) => {
          const x = xScale(tick) || 0;
          return (
            <React.Fragment key={`xaxis-${tick}`}>
              <line css={axisLineStyle} x1={x} x2={x} y1={0} y2={height + 4} />
              <text
                css={tickLabel}
                x={x}
                y={height}
                dy={"1.2em"}
                textAnchor={
                  i === 0 ? "start" : i === ticks.length - 1 ? "end" : "middle"
                }
              >
                {formatTick(tick)}
              </text>
            </React.Fragment>
          );
        })}
      </>
    );
  }
);

const YTicks = React.memo(
  ({
    yScale,
    formatTick = formatPctRound,
    width,
  }: {
    yScale: scale.ScaleLinear<number, number>;
    formatTick?: (x: number) => string;
    width: number;
  }) => {
    const ticks = yScale.domain();
    return (
      <>
        {ticks.map((tick) => {
          const y = yScale(tick) || 0;
          return (
            <React.Fragment key={`xaxis-${tick}`}>
              <line css={axisLineStyle} x1={-4} x2={width} y1={y} y2={y} />
              <text
                css={tickLabel}
                x={-8}
                y={y}
                textAnchor={"end"}
                dominantBaseline="middle"
              >
                {formatTick(tick)}
              </text>
            </React.Fragment>
          );
        })}
      </>
    );
  }
);

const Annotations = React.memo(
  ({
    xScale,
    formatX = formatPctRound,
    yScale,
    formatY = formatPctRound,
    annotations,
    height,
    width,
  }: {
    xScale: scale.ScaleLinear<number, number>;
    formatX?(d: number): string;
    yScale: scale.ScaleLinear<number, number>;
    formatY?(d: number): string;
    annotations: {
      x: Array<number>;
      y: Array<number>;
      labels: Array<{
        label: string;
        x: number;
        y: number;
        dx: string;
        dy: string;
      }>;
    };
    height: number;
    width: number;
  }) => {
    return (
      <>
        <g key="x-annotations">
          {annotations.x.map((pos, i) => (
            <React.Fragment key={i}>
              <line
                x1={xScale(pos)}
                x2={xScale(pos)}
                y1={0}
                y2={height}
                strokeDasharray={"6 4"}
                stroke={M.unescoDarkBlue}
                strokeWidth={2}
                opacity={0.2}
              />
              <text
                css={tickLabel}
                x={xScale(pos)}
                y={height}
                dy={"1.2em"}
                textAnchor={"middle"}
              >
                {formatX(pos)}
              </text>
            </React.Fragment>
          ))}
        </g>

        <g key="y-annotations">
          {annotations.y.map((pos, i) => (
            <React.Fragment key={i}>
              <line
                y1={yScale(pos)}
                y2={yScale(pos)}
                x1={0}
                x2={width}
                strokeDasharray={"6 4"}
                stroke={M.unescoDarkBlue}
                strokeWidth={2}
                opacity={0.2}
              />
              <text
                css={tickLabel}
                x={-8}
                y={yScale(pos)}
                textAnchor={"end"}
                dominantBaseline="middle"
              >
                {formatY(pos)}
              </text>
            </React.Fragment>
          ))}
        </g>

        {annotations.labels.map((label, i) => (
          <text
            key={i}
            x={xScale(label.x)}
            y={yScale(label.y)}
            textAnchor="start"
            dx={label.dx}
            dy={label.dy}
            css={css`
              ${M.fontChartLabel};
              font-weight: bold;
              fill: ${M.unescoDarkBlue};
              paint-order: stroke;
              stroke: white;
              stroke-width: 1;
              stroke-linecap: butt;
              stroke-linejoin: miter;
            `}
          >
            {label.label}
          </text>
        ))}
      </>
    );
  }
);

interface HighlightProps<A extends DataShape> {
  datum: A;
  getX(x: A): number;
  getY(x: A): number;
  getColor(x: A): string;
  xPos(x: A): number;
  yPos(x: A): number;
  formatTitle(x: A): string;
  formatX?(x: number): string;
  formatY?(x: number): string;
  xLabel: string;
  yLabel: string;
  colorScale: scale.ScaleOrdinal<string, string>;
}

const Highlight = React.memo(Highlight_);
function Highlight_<A extends DataShape>({
  datum,
  getX,
  getY,
  getColor,
  xLabel,
  yLabel,
  xPos,
  yPos,
  formatX = formatPct,
  formatY = formatPct,
  formatTitle,
  colorScale,
}: HighlightProps<A>) {
  const cx = xPos(datum);
  const cy = yPos(datum);
  return (
    <>
      <circle
        key="hovered"
        fill="transparent"
        stroke={colorScale(getColor(datum))}
        strokeWidth={5}
        opacity={0.5}
        cx={cx}
        cy={cy}
        r={9}
      />
      <ChartTooltip
        title={`${formatTitle(datum)} (${datum.year})`}
        content={
          <TooltipGridBody
            rows={[
              [yLabel, formatY(getY(datum))],
              [xLabel, formatX(getX(datum))],
            ]}
          />
        }
        placement="right-start"
        showOnCreate
        hideOnClick={false}
        arrow={false}
      >
        <circle cx={0} cy={16} r={1} fill={"transparent"} />
      </ChartTooltip>
    </>
  );
}

// -----------------------------------------------------------------------------

/**
 * Fill in an array for every time step, carrying over data from previous years,
 * if a more recent one doesn't have data.
 */
function yearlyDataPerGroup<A extends DataShape>(
  p: Pick<ScatterplotProps<A>, "getGroup" | "data"> & {
    timeSteps: Array<number>;
  }
) {
  return pipe(
    An.groupBy(p.getGroup)(p.data),
    R.map((xs) => {
      const first = An.head(xs);
      const years = p.timeSteps.slice(p.timeSteps.indexOf(first.year));
      const lookup = pipe(
        xs,
        An.groupBy((x) => `${x.year}`)
      );

      let template = first;
      return years.map((year) => {
        const x = lookup[year];
        if (x != null) {
          template = An.head(x);
          return template;
        } else {
          return { ...template, year };
        }
      });
    })
  );
}

function distance([x1, y1]: [number, number], [x2, y2]: [number, number]) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}
