import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import { flag } from "flags";
import { useRouter } from "next/router";
import { mkStyles, useFocusVisible, useQueryParamsWithDefault } from "../hooks";
import { useAppReducer } from "../hooks/useAppState";
import { ReactComponent as ArrowTopIcon } from "../icons/ic_arrow-top_16dp.svg";
import { locales } from "../locales";
import * as M from "../materials";
import { E, O, pipe } from "../prelude";
import { RAlias, REnv, findArticleAlias } from "../routes";
import { gridCenteredMaxWidth } from "./app-layout";
import { buttonReset } from "./button";
import { LocaleLink, PageLink } from "./links";
import { LogoIO } from "./logo";
import { Search } from "./search/search";
import SecondaryBrandsBanner from "./secondary-brands-banner";
import React from "react";

const focusArticlesFlag = flag("focus-articles");

const useStyles = mkStyles(
  ({ palette, spacing, typography, zIndex, breakpoints }) => {
    const { grid, areas } = gridCenteredMaxWidth();
    return {
      root: css`
        ${grid};
        grid-template-rows: 1fr 0;
        grid-template-areas:
          ". ${areas.main} ."
          "${areas.banner} ${areas.banner} ${areas.banner}";
        background: ${palette.brand.blue.main};
        color: ${palette.text.white};
        padding: ${spacing.base8(2)} 0;
        position: relative;
        margin-bottom: -1rem;

        /* This targets the iPad mini: Until exactly 1024 add padding. */
        @media screen and (max-width: ${breakpoints.values.l}px) {
          padding: ${spacing.base8(2)};
        }
      `,
      content: css`
        z-index: ${zIndex.header};
        grid-area: ${areas.main};
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 1rem;
      `,
      banner: css`
        grid-area: ${areas.banner};
      `,
      locales: css`
        ${typography.preset.fontMeta};
        display: flex;
        align-items: center;

        @media ${M.bpDown("m")} {
          display: none;
        }
      `,
      navigation: css`
        display: flex;
        flex-direction: column;
        min-height: ${spacing.base8(9)};
        justify-content: space-between;
        align-items: flex-end;
        padding: ${spacing.base8(0.5)} 0;
      `,
      navigationMenu: css`
        display: flex;
      `,
      linkButton: css`
        ${buttonReset};
        margin-left: ${spacing.base8(3)};
        html[dir="rtl"] & {
          margin-left: unset;
          margin-right: ${spacing.base8(3)};
        }
      `,
      link: css`
        ${typography.preset.fontHeading3};
        font-weight: bold;
        ${spacing.intersperseCSS(3)}
        color: inherit;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        opacity: 0.5;
        transition: 0.3s opacity;
        &:hover {
          opacity: 1;
        }
      `,
      linkFocus: css`
        opacity: 1;
        outline: 2px solid white;
        outline-offset: 2px;
      `,
      linkActive: css`
        opacity: 1;
      `,
      linkHoverArea: css`
        opacity: 1;
        &::after {
          content: "";
          width: 150%;
          position: absolute;
          top: 0;
          left: -25%;
          height: 150%;
        }
      `,
      linkButtonDrawer: css`
        display: flex;
        align-items: center;
        gap: ${spacing.base8(1)};
      `,
      linkDrawerArrow: css`
        transition: transform 0.2s ease;
      `,
    };
  }
);

export function Header() {
  const [{ themeDrawer }, dispatch] = useAppReducer();

  function closeDrawer() {
    dispatch({ type: "themeDrawer.close" });
  }

  const styles = useStyles();
  const router = useRouter();

  const alias = pipe(
    router.query.alias,
    RAlias.decode,
    E.fold(
      () => (router.pathname.startsWith("/focus/") ? "focusPosts" : "home"),
      (alias) => alias
    )
  );

  const { locale } = useQueryParamsWithDefault(REnv);

  return (
    <div css={styles.root} data-skip-search>
      <div css={styles.content}>
        <FocusLink>
          {({ focusVisible, ...handleFocus }) => (
            <PageLink name="home" query={{}}>
              <a
                style={{ display: "block" }}
                css={[focusVisible && styles.linkFocus]}
                onClick={closeDrawer}
                onKeyDown={closeDrawer}
                role="button"
                tabIndex={0}
                {...handleFocus}
              >
                <LogoIO size="small" />
              </a>
            </PageLink>
          )}
        </FocusLink>
        <div css={styles.navigation}>
          <nav css={styles.locales}>
            {locales.map(({ lang, name }) => (
              <LocaleLink key={lang} locale={lang}>
                {name}
              </LocaleLink>
            ))}
            <Search locale={locale} />
          </nav>
          <nav css={styles.navigationMenu}>
            <FocusLink>
              {({ focusVisible, ...handleFocus }) => (
                <PageLink name="home" query={{}}>
                  <a
                    css={[
                      styles.link,
                      alias === "home" && styles.linkActive,
                      focusVisible && styles.linkFocus,
                    ]}
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                    role="button"
                    tabIndex={0}
                    {...handleFocus}
                  >
                    <Trans>Home</Trans>
                  </a>
                </PageLink>
              )}
            </FocusLink>

            <FocusLink>
              {({ focusVisible, ...handleFocus }) => (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch({ type: "themeDrawer.toggle" });
                  }}
                  aria-expanded={themeDrawer.isOpen}
                  aria-haspopup="true"
                  aria-owns="article-switcher"
                  id="article-switcher-toggle"
                  css={[
                    styles.linkButton,
                    styles.link,
                    styles.linkButtonDrawer,
                    O.isSome(findArticleAlias(alias)) && styles.linkActive,
                    themeDrawer.isOpen && styles.linkHoverArea,
                    focusVisible && styles.linkFocus,
                  ]}
                  {...handleFocus}
                >
                  <Trans>Themes</Trans>
                  <div
                    css={[
                      styles.linkDrawerArrow,
                      {
                        transform: `rotate(${themeDrawer.isOpen ? 0 : 180}deg)`,
                      },
                    ]}
                  >
                    <ArrowTopIcon />
                  </div>
                </button>
              )}
            </FocusLink>
            {focusArticlesFlag && (
              <FocusLink>
                {({ focusVisible, ...handleFocus }) => (
                  <PageLink name="focusPosts" query={{}}>
                    <a
                      css={[
                        styles.link,
                        alias === "focusPosts" && styles.linkActive,
                        focusVisible && styles.linkFocus,
                      ]}
                      onClick={closeDrawer}
                      onKeyDown={closeDrawer}
                      role="button"
                      tabIndex={0}
                      {...handleFocus}
                    >
                      <Trans>Focuses</Trans>
                    </a>
                  </PageLink>
                )}
              </FocusLink>
            )}
            <FocusLink>
              {({ focusVisible, ...handleFocus }) => (
                <PageLink name="indicators" query={{}}>
                  <a
                    css={[
                      styles.link,
                      alias === "indicators" && styles.linkActive,
                      focusVisible && styles.linkFocus,
                    ]}
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                    role="button"
                    tabIndex={0}
                    {...handleFocus}
                  >
                    <Trans>SDG 4 indicators</Trans>
                  </a>
                </PageLink>
              )}
            </FocusLink>
            <FocusLink>
              {({ focusVisible, ...handleFocus }) => (
                <PageLink name="about" query={{}}>
                  <a
                    css={[
                      styles.link,
                      alias === "about" && styles.linkActive,
                      focusVisible && styles.linkFocus,
                    ]}
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                    role="button"
                    tabIndex={0}
                    {...handleFocus}
                  >
                    <Trans>About</Trans>
                  </a>
                </PageLink>
              )}
            </FocusLink>
          </nav>
        </div>
      </div>
      <div css={styles.banner}>
        {router.query.alias !== "home" ? <SecondaryBrandsBanner /> : null}
      </div>
    </div>
  );
}

const FocusLink = ({
  children,
}: {
  children: (props: {
    focusVisible: boolean;
    onFocus: () => void;
    onBlur: () => void;
  }) => JSX.Element;
}) => {
  const childprops = useFocusVisible();
  return children(childprops);
};
