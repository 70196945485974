
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "学习";
export const lead = "仅仅让儿童上学是不够的。在不脱离各中小学正在落实以及必须落实的许多其他合理目标的同时，应该让学生掌握阅读和基本计算能力。这是进行其他学习、提高个人权能和福祉的基础，也是取得社会、政治和经济成果的基础。";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />{" "}
    <blockquote>
      <p parentName="blockquote">{`仅仅让儿童上学是不够的。在不脱离各中小学正在落实以及必须落实的许多其他合理目标的同时，应该让学生掌握阅读和基本计算能力。这是进行其他学习、提高个人权能和福祉的基础，也是取得社会、政治和经济成果的基础。`}</p>
    </blockquote>
    <h2 {...{
      "id": "儿童与青年"
    }}>{`儿童与青年`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`在世界上一些最贫困国家，许多儿童缺乏基本的阅读和计算技能。例如，对尼日利亚北部的卡杜纳和索克托等州以及刚果民主共和国的赤道省和加丹加等省进行的低年级阅读能力评估表明，超过 80%的三年级小学生甚至连课文当中的一`}<strong parentName="p">{`个单词`}</strong>{`都不认识。`}</p>
      <Figure id="EGRA" mdxType="Figure" />{" "}
    </IndicatorReference>
    <p>{`这一数据并未包含不上学儿童。入户调查能够同时统计出上学儿童和不上学儿童的技能。`}</p>
    <p>{`例如，下图以公民自主评估为基础，反映了印度和巴基斯坦不同地区的情况。`}</p>
    <p>
  <HighlightByIndex index="1" mdxType="HighlightByIndex">红线</HighlightByIndex>
  按年级显示具备基本阅读技能的在校儿童百分比，
  <HighlightByIndex index="0" mdxType="HighlightByIndex">而蓝线</HighlightByIndex>
  显示**无论在校与否、其年龄与其应就读年级相对应**的所有具备基本阅读技能儿童的百分比。
    </p>{" "}
    <p>{`两条线之间的差距提醒人们，在失学率高的国家，如果只监测在校儿童的学习状况，政策制定者只能了解到片面的情况。应当指出，监测到的技能水平非常低。即使在表现最好的地区，也存在部分青少年中学生无法阅读二年级课文的情况。`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" />{" "}
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`估计有近 40%的小学年龄儿童不具备基本阅读技能。在中亚和南亚，这个比例是 43%，在撒哈拉以南非洲是 84%，但在欧洲和北美只有 3%。`}</p>
    </IndicatorReference> 
    <IndicatorReference id="4.1.0" label="4.1.0" mdxType="IndicatorReference">
      <p>{`在许多国家，即使儿童完成了小学教育，也无法保证其具备阅读能力。从全球看，在完成相当于小学程度教育的儿童当中，只有十分之四无法阅读简单的句子。在撒哈拉以南非洲，这个比例升高至十分之八，如下图所示。`}</p>
      <ScrollyArea mdxType="ScrollyArea"> 
        <ScrollySticky mdxType="ScrollySticky"> 
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" /> 
        </ScrollySticky> 
        <ScrollySection mdxType="ScrollySection">
          <HighlightByIndex index="-1" mdxType="HighlightByIndex">灰色区域</HighlightByIndex>
显示完成小学教育却未达到最低阅读水平的儿童百分比。
          <HighlightByIndex index="1" mdxType="HighlightByIndex">红色区域</HighlightByIndex>
显示未完成小学教育的儿童所占百分比，
          <HighlightByIndex index="0" mdxType="HighlightByIndex">蓝色区域</HighlightByIndex>
是完成小学教育并达到最低阅读水平的学生百分比。{" "}
        </ScrollySection> 
      </ScrollyArea>
    </IndicatorReference>
    <p>{`从国家层面看，在乍得和尼日利亚，上学五、六年后离校的青年中约有 90%不掌握读写能力。而一些国家在这一数据上则表现更好。例如，在布隆迪、哥斯达黎加和洪都拉斯，五、六年级之后离校的青年几乎都掌握读写能力。`}</p>
    <ScrollyArea mdxType="ScrollyArea"> 
      <ScrollySticky mdxType="ScrollySticky"> 
        <Figure id="LITBYYRS" mdxType="Figure" /> 
      </ScrollySticky> 
      <ScrollySection id="KEN" mdxType="ScrollySection">
        <p>{`例如，在`}<strong parentName="p">{`肯尼亚`}</strong>{`，上学四年后掌握读写能力的学生为四分之一，但在上学五、六年（相当于小学教育）的学生中，这一比例便跃升至四分之三。`}</p>
      </ScrollySection>
      <ScrollySection id="NER" mdxType="ScrollySection">
        <p>{`在`}<strong parentName="p">{`尼日尔`}</strong>{`，上学四年的儿童几乎都不掌握阅读能力，但如果能够读完小学，三分之一会掌握基本读写技能。`}</p>
      </ScrollySection> 
    </ScrollyArea>
    <p>{`为比较各国的学习情况，评估或测试需要与国际标准配套，采用共同的定义。近期，已经运用这样的标准进行了跨国评估，几个国家的学生进行相同的测试。但采用相同方法开展国内评估尚不多见。`}</p>
    <p>{`国际数学与科学趋势研究（TIMSS）的评估每四年进行一次，能够反映出不同时间的四年级和八年级学生的进展情况。该研究也显示出哪些国家进展尤其缓慢。例如，在埃及，达到最低数学水平的八年级学生仅从 2003 年的 24%上升到了 2019 年的 27%。`}</p>
    <HighlightByIndex index="0" mdxType="HighlightByIndex">马来西亚</HighlightByIndex>
等一些国家达到最低学习水平的学生比例甚至随着时间的推移而下降。{" "}
    <p>{`在`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`巴林`}</HighlightByIndex>{`等其他国家，进展很快——2003 年至 2019 年间，达到最低学习水平的学生比例增加了两倍多。`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`与大多数人的预期相反，性别之间的学习差距并不大——比如，在小学阶段，几乎所有国家的这一差距都不超过十个百分点。`}</p>
      <p>{`然而国家之间的学习差距巨大。撒哈拉以南非洲国家的小学阅读技能往往处在较低水平，达到最低阅读水平的学生比例从布隆迪的 4%到加蓬的 76%不等（结果均出自 2019 年法语国家和政府教育部长会议教育系统分析计划（PASEC））。`}</p>
      <p>{`在拉丁美洲国家的小学数学教育方面，合格率从多米尼加共和国的仅 2%到秘鲁的 39%（结果均出自 2019 年拉丁美洲教育质量评估实验室（LLECE）的评估）。`}</p>
      <Figure id="ASSESS" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "成人"
    }}>{`成人`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`在 2000 年到 2020 年之间，全球掌握读写能力的成人的百分比从 81%升至 87%，掌握读写能力的青年的百分比则从 87%升至 92%。`}</p>
      <p>{`尽管有这些进步，文盲在许多国家依然普遍存在，尤其是在妇女当中。在拉丁美洲和加勒比地区，一些偏远地区最弱势群体中的文盲比例维持在。例如，在巴拉圭，女性老年人的识字率过去 10 年没有什么变化，维持在约 73%。`}</p>
      <p>{`在撒哈拉以南非洲的 18 个国家，超过一半成年女性（25-64 岁)不识字。识字率进展情况或如马里，维持在 17%以下的低水平不变，或如刚果那样一直进步缓慢，在十年间从 72%提高到 74%。`}</p>
      <p>{`从全球看，之所以目前掌握读写能力的成人多于以往，主要是因为更多成人小时候上过学，而不是因为不曾上学的成人再次获得了学习的机会。为证明这一说法，下图显示了特定人群随着年龄增长的读写技能数据情况。`}</p>
    </IndicatorReference>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="LITCOHORT" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection mdxType="ScrollySection">
        <p>{`在大多数国家，由于读写技能达不到充分运用，随着时间推移，`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`特定成年人群的读写能力`}</HighlightByIndex>{`维持不变甚至下降。`}</p>
      </ScrollySection>
      <ScrollySection mdxType="ScrollySection">
        <p>{`在`}<strong parentName="p">{`海地`}</strong>{`，蓝线显示`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`2000 年 20—34 岁妇女`}</HighlightByIndex>{`、`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{` 2006 年 26—40 岁妇女`}</HighlightByIndex><HighlightByIndex index="0" mdxType="HighlightByIndex">{`年 37—61 岁妇`}</HighlightByIndex>{`女的识字率略有下降。然而，由于越来越多的年轻女性开始掌握读写技能，红线显示`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`20-34 岁女性的识字率`}</HighlightByIndex>{`随着时间的推移一直在增加。`}</p>
      </ScrollySection>
    </ScrollyArea>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`这些评估所衡量的都是非常基本的读写技能，表明人们是否能够阅读简单的句子。而表明人们能够阅读和解释书面指示、正式信函或合同的功能性读写能力则是另一回事。`}</p>
      <p>{`收集功能性读写能力数据的国际调查很少，定期这样做的更少。`}</p>
      <p>{`从我们所能掌握的数据看，我们发现在功能性读写能力方面，不具备功能性读写能力（即低于 1 级熟练水平）的城市成人在哥伦比亚和越南占十分之一，在玻利维亚占四分之一。`}</p>
      <p>{`在`}<strong parentName="p">{`加纳`}</strong>{`，即使城市成人也仅有 39%具备功能性读写技能。`}</p>
      <Figure id="LITPROF" mdxType="Figure" /> 
    </IndicatorReference>
    <h2 {...{
      "id": "结论"
    }}>{`结论`}</h2>
    <p>{`尽管可持续发展目标 4 将重点转移到监测学习方面，但是许多国家没有报告阅读和计算方面的学习成果指标。即使希望着手评估和报告学习情况的国家也往往面临各种障碍，无法采取有效而具有成本效益的解决方案。但是，我们能够掌握的证据表明，在世界上最贫困的国家，大部分学生的最基本技能达不到最低水平要求。`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/32553"
          }}>{`Ending learning poverty – what will it take? – World Bank`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000225660_chi"
          }}>{`EFA GMR 2013/4: Teaching and learning: Achieving quality for all`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/20/the-learning-assessment-market-pointers-for-countries-part-2/"
          }}>{`The learning assessment market - pointers for countries`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;