import React from "react";

/**
 * mapChildrenRec
 *
 * Recursively map over React children and apply a function to each if they
 * are ReactElements.
 */
export function mapChildrenRec(
  children: React.ReactNode,
  f: (x: React.ReactNode) => React.ReactNode
): React.ReactNode {
  return React.Children.map(children, (child) => {
    return React.isValidElement(child)
      ? child.props.children
        ? f(
            React.cloneElement<$FixMe>(child, {
              children: mapChildrenRec(child.props.children, f),
            })
          )
        : f(child)
      : child;
  });
}
