import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { scaleOrdinal } from "d3-scale";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_access_fig_COMPLETION";
import {
  ChartRenderer,
  countryIdIso,
  foldGeoEntity,
  mkGemEntityDecoder,
  useCountryEntities,
  useFigureControlItems,
  useNamedEntitiesWithLevel,
  useSingleSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { insertionOrderSet, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, io, O, pipe } from "../prelude";

export * from "../data/data_access_fig_COMPLETION";

const DEFAULT_COUNTRY = "KEN";

const Completion = io.keyof({
  timely: null,
  ultimately: null,
});
type Completion = io.TypeOf<typeof Completion>;

const completionLookup: Record<Completion, MessageDescriptor> = {
  timely: t("fig.access.COMPLETION.timely")`timely`,
  ultimately: t("fig.access.COMPLETION.ultimately")`ultimately`,
};

export const Data = mkGemEntityDecoder(["ind_id", "value", "year", "level"], {
  completion: Completion,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const [selectedCountry, actions] = useSingleSelectState("country");

  const entityData_ = useNamedEntitiesWithLevel(data);
  const entityData = React.useMemo(
    () =>
      entityData_.map((x) => ({
        ...x,
        category: `${x.entity_name} (${i18n._(
          completionLookup[x.completion]
        )})`,
      })),
    [i18n, entityData_]
  );
  const countryData = useCountryEntities(entityData);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "country",
      {
        type: "SingleSelect",
        selected: pipe(
          countryControlItems,
          Ar.findFirst((x) => x.value === DEFAULT_COUNTRY),
          O.alt(() => Ar.head(countryControlItems)),
          O.map((x) => x.value)
        ),
      },
      countryControlItems
    );
  }, [actions, countryControlItems]);

  const chartData = React.useMemo(() => {
    return unsafeFromArray(
      entityData.filter(
        foldGeoEntity<ArrayElement<typeof entityData>, boolean>({
          country: ({ id }) =>
            pipe(
              selectedCountry,
              O.fold(
                () => false,
                (x) => x === countryIdIso.unwrap(id)
              )
            ),
          region: ({ entity_type }) => entity_type === "world",
        })
      )
    );
  }, [entityData, selectedCountry]);

  const legendEntries = React.useMemo(
    () => insertionOrderSet(chartData, (x) => x.category),
    [chartData]
  );
  const colorScale = React.useMemo(
    () =>
      scaleOrdinal(
        Ar.chunksOf(2)(M.discretePalette)
          // Careful: b could be undefined for different chunk sizes / palette lengths
          .flatMap(([a, b]) => [a, b, a, b])
      ).domain(legendEntries),
    [legendEntries]
  );

  return (
    <LineChart
      tLabel={(s) => s}
      height={
        client.screenSDown
          ? M.chartHeight.xs
          : client.screenMDown
          ? M.chartHeight.s
          : M.chartHeight.m
      }
      endLabel={!client.screenMDown}
      x="year"
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".0%"
      column="level_name"
      columns={3}
      columnSort="none"
      category="datum.category"
      colorLegend
      colorScale={colorScale}
      values={chartData}
      yTicks={[0, 0.5, 1]}
      showTooltip
      markStyle={(x) => (x.entity_type === "world" ? "muted" : "normal")}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_access_fig_COMPLETION.json"),
  csv: require("../data/data_access_fig_COMPLETION.zip"),
  xlsx: require("../data/data_access_fig_COMPLETION.xlsx"),
  metadata,
  Data,
  Chart,
});
