import css from "@emotion/css";
import * as scale from "d3-scale";
import { motion } from "framer-motion";
import * as React from "react";
import { mkStyles } from "../hooks";
import * as M from "../materials";
import { ReactComponent as PauseButton } from "./timeline/pause.svg";
import { ReactComponent as PlayButton } from "./timeline/play.svg";

const useStyles = mkStyles(({ spacing: { base8 }, utils }) => ({
  root: css`
    direction: ltr;
    border-radius: 20px;
    height: ${base8(4)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 220px;
    background: ${M.whiteText};
    border: 1px solid ${M.grayscalePalette[3]};
    color: ${M.unescoMarineBlue};
    ${M.fontTable};
    padding-right: 12px;

    "&:focus": {
      box-shadow: 0 5px 20px ${utils.fade(M.blackText, 0.5)};
    }

    @media ${M.bpDown("s")} {
      min-width: ${M.spacing.base8(35)};
    }
  `,
  label: css`
    text-align: right;
    white-space: nowrap;
    width: calc(100% - 44px);
  `,
  check: css`
    width: ${base8(4)};
    height: ${base8(4)};
    background: white;
    background-color: ${M.unescoMarineBlue};
    border: 1px solid ${M.unescoMarineBlue};
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: -1px;

    &:focus {
      outline: none;
      box-shadow: 0 0 10px ${utils.fade(M.blackText, 0.5)};
    }
  `,
}));

interface TimelineProps {
  range: [number, number];
  onChange(time: number): void;
  checked?: boolean;
  interval?: number; // ms
}

export function Timeline({
  range,
  onChange,
  checked = false,
  interval = 1000,
}: TimelineProps) {
  const styles = useStyles();
  const [from, to] = range;
  const steps = to - from + 1;

  const [isChecked, setIsChecked] = React.useState(checked);
  const [selectedTime, setSelectedTime] = React.useState(to);
  const index = React.useRef(0);

  const { timeScale, percentScale } = React.useMemo(() => {
    const timeScale = scale
      .scaleLinear()
      .domain([0, steps - 1])
      .range([from, to]);
    const percentScale = scale.scaleLinear().domain([from, to]).range([80, 0]); // Magic number based on text width for a year, e.g. "2001"
    return { timeScale, percentScale };
  }, [steps, from, to]);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const go = (i: number) => {
      const next = timeScale(i % steps);
      next && setSelectedTime(Math.round(next) as $FixMe);
      timeoutId = setTimeout(() => go(++index.current), interval);
    };
    isChecked && go(index.current);
    return () => clearTimeout(timeoutId);
  }, [isChecked, steps, to, timeScale, interval]);

  React.useEffect(() => {
    onChange(selectedTime);
  }, [onChange, selectedTime]);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div css={styles.root}>
      <button css={styles.check} onClick={() => setIsChecked(!isChecked)}>
        {isChecked ? <PauseButton /> : <PlayButton />}
      </button>
      <div css={styles.label}>
        <motion.span
          initial={{ marginRight: `${percentScale(selectedTime)}%` }}
          animate={{ marginRight: `${percentScale(selectedTime)}%` }}
        >
          {selectedTime}
        </motion.span>
      </div>
    </div>
  );
}
