import { t } from "@lingui/macro";
import React from "react";
import { ChartGrid } from "../charts-motion";
import ColorLegend from "../charts/lib/ColorLegend";
import { Line as FixedWidthLineChart } from "../charts/lib/Lines";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_quality_fig_TRAINED";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  foldGeoEntity,
  getCountryName,
  mkGemEntityDecoder,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_quality_fig_TRAINED";

const DEFAULT_COUNTRIES = ["RWA", "LSO", "GHA", "ERI", "SEN", "MOZ"];

const yUnitLabel = t(
  "fig.quality.TRAINED.legend"
)`Percentage of trained teachers`;

export const Data = mkGemEntityDecoder(
  ["ind_id", "level", "year", "value"],
  {}
);
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedCountries] = useMultiSelectState("countries");

  const entityData_ = useNamedEntities(data);
  const entityData = React.useMemo(() => {
    return entityData_.filter((x) => x.level === "primary");
  }, [entityData_]);
  const countryData = useCountryEntities(entityData);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        selected: O.some(DEFAULT_COUNTRIES),
        maxItems: 8,
      },
      countryControlItems
    );
  }, [i18n, actions, countryControlItems]);

  const chartData = React.useMemo(() => {
    const hasCountry = (country: CountryId) =>
      pipe(
        selectedCountries,
        O.fold(
          () => false,
          (xs) => xs.includes(countryIdIso.unwrap(country))
        )
      );

    return unsafeFromArray(
      entityData.filter(
        foldGeoEntity({
          country: ({ id }) => hasCountry(id),
          region: () => true,
        })
      )
    );
  }, [entityData, selectedCountries]);
  type Datum = typeof chartData[number];

  const selectedCountryLabels = React.useMemo(() => {
    return pipe(
      selectedCountries,
      O.map((xs) =>
        xs.map((x) => getCountryName(env.countries, countryIdIso.wrap(x)))
      )
    );
  }, [env.countries, selectedCountries]);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    selectedCountryLabels
  );

  return (
    <ChartGrid
      data={chartData}
      getCell={React.useCallback(
        (x: Datum) =>
          x.entity_type === "country"
            ? i18n._(t`Countries`)
            : i18n._(t`Regions`),
        [i18n]
      )}
      columnCount={2}
      minWidth={100}
    >
      {({ data, width, first }) => {
        return (
          <>
            <FixedWidthLineChart
              tLabel={(s) => s}
              width={width}
              height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
              endLabel={!client.screenMDown}
              x="year"
              timeParse="%Y"
              timeFormat="%Y"
              numberFormat=".0%"
              yTicks={[0, 0.2, 0.4, 0.6, 0.8, 1]}
              category="datum.entity_name"
              colorLegend={!first}
              colorScale={first ? colorScale : undefined}
              values={data}
              markStyle={(x) =>
                x.entity_type === "world" ? "reference" : "normal"
              }
              connectLines
              showTooltip
              yAxisLabel={i18n._(yUnitLabel)}
            />
            {first && (
              <ColorLegend
                inline
                maxWidth={"100%"}
                values={colorLegendValues}
              />
            )}
          </>
        );
      }}
    </ChartGrid>
  );
};

export default withFigureIO({
  url: require("../data/data_quality_fig_TRAINED.json"),
  csv: require("../data/data_quality_fig_TRAINED.zip"),
  xlsx: require("../data/data_quality_fig_TRAINED.xlsx"),
  metadata,
  Data,
  Chart,
});
