import dynamic from "next/dynamic";
import React from "react";
import { AidFlowMeta, CountryMeta, RegionMeta } from "../domain";
import { foldLocale, Locale } from "../locales";

export interface ConfigContext {
  aidflows: Array<AidFlowMeta>;
  countries: Array<CountryMeta>;
  regions: Array<RegionMeta>;
}

const context = React.createContext<ConfigContext>({
  aidflows: [],
  countries: [],
  regions: [],
});

const mkProv = ([
  { default: aidflows },
  { default: countries },
  { default: regions },
]: [$Unexpressable, $Unexpressable, $Unexpressable]) => {
  // TODO: If problems arise, we should parse this data through io-ts, for now we blindly believe
  // that the data we get is correct.
  const value: ConfigContext = { aidflows, countries, regions };
  return ({ children }: $PropsWithChildren) => (
    <context.Provider value={value}>{children}</context.Provider>
  );
};

const configProviders = foldLocale<React.ComponentType<$PropsWithChildren>>({
  ar: dynamic(() =>
    Promise.all([
      import("./aidflows.ar.json"),
      import("./countries.ar.json"),
      import("./regions.ar.json"),
    ]).then(mkProv)
  ),
  de: dynamic(() =>
    Promise.all([
      import("./aidflows.de.json"),
      import("./countries.de.json"),
      import("./regions.de.json"),
    ]).then(mkProv)
  ),
  en: dynamic(() =>
    Promise.all([
      import("./aidflows.en.json"),
      import("./countries.en.json"),
      import("./regions.en.json"),
    ]).then(mkProv)
  ),
  es: dynamic(() =>
    Promise.all([
      import("./aidflows.es.json"),
      import("./countries.es.json"),
      import("./regions.es.json"),
    ]).then(mkProv)
  ),
  fr: dynamic(() =>
    Promise.all([
      import("./aidflows.fr.json"),
      import("./countries.fr.json"),
      import("./regions.fr.json"),
    ]).then(mkProv)
  ),
  ru: dynamic(() =>
    Promise.all([
      import("./aidflows.ru.json"),
      import("./countries.ru.json"),
      import("./regions.ru.json"),
    ]).then(mkProv)
  ),
  zh: dynamic(() =>
    Promise.all([
      import("./aidflows.zh.json"),
      import("./countries.zh.json"),
      import("./regions.zh.json"),
    ]).then(mkProv)
  ),
});

// -----------------------------------------------------------------------------
// Config Provider

export const ConfigProvider = ({
  locale,
  ...props
}: $PropsWithChildren<{ locale: Locale }>) => {
  const Provider = React.useMemo(() => configProviders(locale), [locale]);
  return React.createElement(Provider, props);
};

export const ConfigConsumer = context.Consumer;

export const useConfig = () => React.useContext(context);
