import { scaleLinear } from "d3-scale";
import * as shape from "d3-shape";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { YearBrand } from "../../domain";
import { useTheme } from "../../hooks";
import { usePointerPosition } from "../../hooks/usePointerPosition";
import { rtlDir } from "../../lib/rtl";
import * as M from "../../materials";
import { Ar, io, nt, O, Ord, pipe } from "../../prelude";
import { TooltipFlag } from "../tooltip-flag";
import { Text, YTick } from "./line";

interface Datum {
  ind_id: nt.Newtype<"IndicatorId", string>;
  entity_name: string;
  value: number;
  year: io.Branded<number, YearBrand>;
}

export interface AreaProps {
  label: string;
  values: Array<Datum>;
  width: number;
  formatValue(x: number): string;
  formatAxisValue(x: number): string;
  tooltipRowLabels?: Readonly<[string, string]>;
  target?: number;
  max?: number;
  tickValuesY?: number[];
}

const PADDING = {
  left: 40,
  right: 24,
  top: 24,
  bottom: 3,
};

export const Area = ({
  label,
  max = 1,
  target = 1,
  values: values_,
  tooltipRowLabels,
  width,
  formatValue,
  formatAxisValue,
  tickValuesY = [0, 0.25, 0.5, 0.75, 1],
}: AreaProps) => {
  const { activeTheme, client } = useTheme();
  const [pointerPositionRef, pointerPosition] =
    usePointerPosition<SVGRectElement>();

  const values = React.useMemo(() => {
    return Ar.sort(Ord.contramap((x: Datum) => x.value)(Ord.ordNumber))(
      values_
    ).map((x, i) => ({
      ...x,
      idx: i,
    }));
  }, [values_]);

  const [focusedCountry, setFocusedCountry] = React.useState<
    O.Option<typeof values[number]>
  >(O.none);

  const height = Math.min(Math.max(width / 1.6, 210), 400);
  const innerWidth = width - (PADDING.left + PADDING.right);
  const innerHeight = height - (PADDING.top + PADDING.bottom);

  const xScale = React.useCallback(
    scaleLinear()
      .domain([0, values.length - 1])
      .range([0, innerWidth])
      .clamp(true),
    [values, innerWidth]
  );

  const yScale = React.useCallback(
    scaleLinear().domain([0, max]).range([innerHeight, 0]).clamp(true),
    [innerHeight, max]
  );

  const areaGenerator = React.useCallback(
    (shape as $FixMe)
      .area()
      .x((x: $FixMe) => xScale(x.idx))
      .y0(yScale(0))
      .y1((x: $FixMe) => yScale(x.value))
      .curve(shape.curveMonotoneX),
    []
  );

  React.useEffect(() => {
    setFocusedCountry(
      pipe(
        pointerPosition,
        O.map((pos) => Math.round(xScale.invert(pos.x))),
        O.chain((countryIdx) => O.fromNullable(values[countryIdx]))
      )
    );
  }, [pointerPosition, values, xScale]);

  return (
    <svg width={width} height={height}>
      <defs>
        <VerticalPattern />
      </defs>

      <g transform={`translate(${PADDING.left}, ${PADDING.top})`}>
        <rect
          width={innerWidth}
          y={yScale(target)}
          height={(yScale(0) as $FixMe) - (yScale(target) as $FixMe)}
          fill={activeTheme.background.light}
        />

        <motion.path
          d={areaGenerator(values) || ""}
          fill={activeTheme.textColor}
          fillOpacity={0.5}
        />

        <motion.rect
          ref={pointerPositionRef}
          width={innerWidth}
          height={innerHeight}
          fill={"url(#lines)"}
          initial="visible"
          whileTap="hidden"
          whileHover="hidden"
          transition={M.defaultTransition}
          variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
        />

        <Text
          transform={`translate(0, 0)`}
          textAnchor={rtlDir(client.isRTL, "start")}
          dy={"-0.6em"}
        >
          {label}
        </Text>
      </g>

      <g transform={`translate(0, ${PADDING.top})`}>
        {tickValuesY.map((d, i) => (
          <YTick
            key={d}
            labelWidth={PADDING.left}
            yPos={i === 0 ? (yScale(d) as $FixMe) + 2 : (yScale(d) as $FixMe)}
            totalWidth={PADDING.left + innerWidth}
            label={d}
            isTarget={d === target}
            showTarget={true}
            highlightLabel
            darken={i === 0 || i === tickValuesY.length - 1}
            formatValue={formatAxisValue}
          />
        ))}
      </g>

      <g transform={`translate(${PADDING.left}, ${PADDING.top})`}>
        <AnimatePresence>
          {pipe(
            focusedCountry,
            O.map((x) => (
              <>
                <Highlight
                  x={xScale(x.idx) as $FixMe}
                  y1={yScale(0) as $FixMe}
                  y2={yScale(x.value) as $FixMe}
                  clamped={x.value > yScale.domain()[1]}
                  r={7}
                />
                {pointerPositionRef.current && (
                  <TooltipFlag
                    cx={
                      pointerPositionRef.current.getBoundingClientRect().left +
                      (xScale(x.idx) as $FixMe)
                    }
                    cy={
                      pointerPositionRef.current.getBoundingClientRect().top +
                      (yScale(x.value) as $FixMe)
                    }
                    distance={7}
                    position="left"
                    title={tooltipRowLabels ? x.entity_name : undefined}
                    rows={
                      tooltipRowLabels
                        ? [
                            // This is currently unwanted, but leaving it here in case of change of mind
                            // [tooltipRowLabels[0], formatValue(1 - x.value)],
                            [tooltipRowLabels[1], formatValue(x.value)],
                          ]
                        : [[x.entity_name, formatValue(x.value)]]
                    }
                    disableInteraction
                  />
                )}
              </>
            )),
            O.toNullable
          )}
        </AnimatePresence>
      </g>
    </svg>
  );
};

const Highlight = ({
  x,
  y1,
  y2,
  clamped,
  r,
}: {
  x: number;
  y1: number;
  y2: number;
  clamped: boolean;
  r: number;
}) => {
  const { activeTheme } = useTheme();
  return (
    <motion.g
      key={"highlight"}
      variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
      animate="visible"
      initial="hidden"
      exit="hidden"
      transition={M.defaultTransition}
    >
      <motion.line
        x1={x}
        x2={x}
        y1={y1}
        y2={y2}
        stroke={activeTheme.textColor}
        strokeWidth={3}
        pointerEvents="none"
      />
      <motion.circle
        cx={x}
        cy={y2}
        r={r}
        fill={"white"}
        stroke={activeTheme.textColor}
        strokeWidth={3}
        pointerEvents="none"
      />
      {clamped && (
        <motion.path
          transform={`translate(${x}, ${y2 + 2})`}
          d={"M-3.5,0 L0,-5 L3.5,0 Z"}
          fill={activeTheme.textColor}
        />
      )}
    </motion.g>
  );
};

function VerticalPattern() {
  return (
    <pattern id="lines" patternUnits="userSpaceOnUse" width={3} height={1}>
      <line
        x1={0.5}
        x2={0.5}
        y1={0}
        y2={1}
        strokeWidth={1}
        stroke="white"
        shapeRendering="crispEdges"
        vectorEffect="non-scaling-stroke"
      />
    </pattern>
  );
}
