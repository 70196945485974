
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Качество";
export const lead = "Образование хорошего качества не может приравниваться к результатам обучения или сводиться только к ним. Слишком велико число детей, не имеющих достаточно подготовленных учителей, доступа к хорошей школьной инфраструктуре или безопасной учебной среде, в которой нет насилия.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const QualityFramework = makeShortcode("QualityFramework");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_QUALITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Образование хорошего качества не может приравниваться к результатам обучения или сводиться только к ним. Слишком велико число детей, не имеющих достаточно подготовленных учителей, доступа к хорошей школьной инфраструктуре или безопасной учебной среде, в которой нет насилия.`}</p>
    </blockquote>
    <p>{`Сложно проводить мониторинг качества образования, поскольку каждая страна понимает и определяет его по-своему. Помимо результатов обучения, в глобальную цель в области образования (ЦУР 4) включено не так много показателей для измерения качества образования.`}</p>
    <h2 {...{
      "id": "учителя"
    }}>{`Учителя`}</h2>
    <IndicatorReference id="4.c.2" mdxType="IndicatorReference">
      <p>{`Учителя являются важнейшим элементом качественного образования. Однако, после 2000 г., вслед за принятием стимулирующих мер для обеспечения всеобщего начального образования, в школы поступило гораздо больше учащихся, а темпы набора учителей были более медленными. В результате этого классы оказались переполненными, что отразилось и на качестве образования.`}</p>
      <QualityFramework highlighted="teachers" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
      <p>{`На приведенных ниже графиках показано соотношение учеников и учителей в школе. В некоторых странах Африки к югу от Сахары число учеников на одного учителя все еще меньше, чем в 1980-х гг., хотя ситуация немного улучшилась после 2000 г.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PTR" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`В `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`странах Африки к югу от Сахары`}</HighlightByIndex>{` после принятия в период 1990-2000 гг. стимулирующих мер, направленных на обеспечение бесплатного начального образования, набор детей в школы шел быстрее, чем набор новых учителей. В результате этого нередко резко возрастало число учеников, приходившихся на одного учителя.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`В то же время в `}<HighlightByIndex index="6" mdxType="HighlightByIndex">{`Северной Америке и Европе`}</HighlightByIndex>{` это соотношение с 2005 г. остается неизменным и составляет примерно 15 учеников на одного учителя.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Преподавание – это сложная задача, и плохо подготовленные учителя приходят в школу с серьезным дефицитом знаний. Существуют два основных способа, с помощью которых страны измеряют качество их подготовки. Учителя оцениваются по двум параметрам:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`являются ли они `}<strong parentName="p">{`квалифицированными`}</strong>{`, то есть обладают ли они соответствующими академическими дипломами, отвечающими национальным стандартам;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`являются ли они `}<strong parentName="p">{`подготовленными`}</strong>{`, то есть прошли ли они соответствующую педагогическую подготовку вне зависимости от наличия у них квалификации.`}</p>
      </li>
    </ul>
    <IndicatorReference id="4.c.1" mdxType="IndicatorReference">
      <p>{`В ходе подготовки учителя получают `}<Tooltip title="Pedagogical knowledge" content="Knowledge on approaches, methods and techniques of teaching." mdxType="Tooltip">{`педагогические знания`}</Tooltip>{` и `}<Tooltip title="Professional knowledge" content="Knowledge on, for example, the legal frameworks governing the teaching profession." mdxType="Tooltip">{`профессиональные знания `}</Tooltip>{`. Некоторые программы могут также включать `}<Tooltip title="Content knowledge" content="Knowledge of the curriculum, subject matter and use of relevant materials." mdxType="Tooltip">{`знакомство с учебными материалами `}</Tooltip>{`. Однако виды требуемой подготовки в разных странах отличаются, по ним имеется совсем немного информации. В силу этих различий несмотря на то, что пока-затель, касающийся подготовленных учителей `}{`[4.c.1]`}{`, представляет собой один из важнейших элементов качественного образования, в системе мониторинга достижения ЦУР 4 он является одним из наименее сопоставимых.`}</p>
      <p>{`Как следует из приведенного ниже графика, в глобальном масштабе большинство учителей обладает квалификацией, подготовкой, или и тем, и другим. Например, в `}<HighlightByIndex index="4" mdxType="HighlightByIndex">{`Латинской Америке и Карибском бассейне`}</HighlightByIndex>{` подготовку прошли более 80% учителей.`}</p>
      <p>{`И напротив, в `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`странах Африки к югу от Сахары`}</HighlightByIndex>{` доля подготовленных учителей сократилась с 84% в 2000 г. до 69% в 2019 г.`}</p>
      <Figure id="TRAINED" mdxType="Figure" />
      <p>{`Странам, испытывающим нехватку квалифицированных и подготовленных учителей, нелегко решить эту проблему. Многие из них не располагают достаточным количеством отвечающих требованиям учителей для удовлетворения спроса, учитывая небольшое число выпускников высших учебных заведений в целом. Это свидетельствует о том, насколько взаимосвязанными могут стать проблемы в рамках системы образования: проблемы на одном уровне, такие как низкие показатели завершения младших классов средней школы, могут легко привести к таким негативным последствиям в других областях, как нехватка подготовленных учителей для будущих учеников.`}</p>
    </IndicatorReference>
    <h2 {...{
      "id": "инфраструктура"
    }}>{`Инфраструктура`}</h2>
    <IndicatorReference id="4.a.1e" mdxType="IndicatorReference">
      <p>{`Соответствующая учебная среда должна быть оборудована адекватными средствами `}<strong parentName="p">{`водоснабжения`}</strong>{` и санитарии, особенно для девочек. Однако базовое водоснабжение имеется лишь в 42% начальных школ в странах Африки к югу от Сахары, в 78% школ в Восточной и Юго-Восточной Азии и в 83% школ в Центральной и Южной Азии.`}</p>
      <QualityFramework highlighted="material" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1f" mdxType="IndicatorReference">
      <p>{`В частности, `}<strong parentName="p">{`санитарные блоки`}</strong>{`, не обеспечивающие защиту от чужих глаз, не могут считаться безопасными, а туалеты, в которых невозможно обеспечить соблюдение гигиены менструального цикла, могут стать препятствием для посещения школы девочками. Поэтому обустройство раздельных туалетов является важнейшей политической мерой предотвращения повсеместного позднего зачисления в начальную школу и отсева девочек из школы с течением времени.`}</p>
      <p>{`Приведенный ниже график показывает, что раздельными туалетами располагают менее половины начальных школ не только в странах Африки к югу от Сахары, но и в таких азиатских странах, как Бангладеш, Лаосская НДР и Непал. В Афганистане раздельные туалеты имеются только в 26% начальных школ, 56% средних школ первой ступени и 75% средних школ второй ступени.`}</p>
      <Figure id="SANITATION" mdxType="Figure" />
      <p>{`Эти оценки следует интерпретировать с осторожностью, поскольку в них могут использоваться разные определения. Некоторые полагают, что к надлежащим санитарно-техническим средствам можно отнести такие простейшие туалеты, как выгребные ямы, другие учитывают только нормально функционирующие туалеты, а третьи – только туалеты, отвечающие национальным стандартам.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1a" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Электричество`}</strong>{` является базовой потребностью для качественной учебной среды, однако во многих странах она до сих пор не удовлетворена. В странах с низким уровнем дохода 72% начальных школ и 53% средних школ второй ступени не имеют электричества.`}</p>
      <p>{`Как видно из приведенного ниже графика, менее половины начальных школ в Афганистане, Кирибати и Непале имеют доступ к электричеству. В некоторых странах Африки к югу от Сахары доступ к электричеству в школах крайне ограничен: на Мадагаскаре электричество имеют 8% школ, а в Центральноафриканской Республике и Чаде – всего 4%. Без электричества освещение может быть недостаточным, а учителя не могут использовать компьютеры для административной работы или обучения. Отсутствие электричества означает отсутствие вентиляции, кондиционирования или отопления, в связи с чем классные комнаты могут быть непригодными для преподавания и обучения.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1b" mdxType="IndicatorReference">
      <p>{`Отсутствие электричества также означает невозможность использования технологий и `}<strong parentName="p">{`интернета`}</strong>{`. Однако электричество – это не единственный фактор, замедляющий распространение интернета. В странах со средним уровнем дохода 43% начальных школ и 30% средних школ высшей ступени имеют электричество, но не имеют доступа к интернету. Даже если школы подключены к источнику питания, скачки напряжения и отключения электроэнергии являются обычным явлением. Подключение к интернету зависит от национальной телекоммуникационной инфраструктуры, географического положения и способности школ оплачивать эту услугу.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="ELECTRIC" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="EGY" mdxType="ScrollySection">
          <p>{`В `}<strong parentName="p">{`Египте`}</strong>{` электричество есть во всех начальных школах, однако только 71% из них имеют доступ к интернету. То же самое отмечается в 64% школ в Азербайджане  и 34% школ в Иордании.`}</p>
        </ScrollySection>
        <ScrollySection id="ECU,URY" mdxType="ScrollySection">
          <p>{`Во многих латиноамериканских странах есть школы, в которых нет ни электричества, ни интернета. Только 41% школ в `}<strong parentName="p">{`Эквадоре`}</strong>{` и 5% школ в Парагвае сообщили о том, что у них имеется доступ и к электричеству, и к интернету. Исключение составляет `}<strong parentName="p">{`Уругвай`}</strong>{`, где 100% начальных школ имеют доступ к электричеству и интернету.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "насилие"
    }}>{`Насилие`}</h2>
    <p>{`Акты насилия или угрозы в школе могут быть психологическими, физическими или сексуальными и происходить на территории школы, а также по пути в школу, дома или в киберпространстве. Они зачастую происходят из-за неравномерного соотношения сил и являются результатом негативных гендерных норм и стереотипов.`}</p>
    <p>{`Хотя внимание обычно привлекают экстремальные проявления насилия, такие как стрельба из огнестрельного оружия, наибольшее негативное воздействие на образование детей и подростков оказывают более распространенные и часто остающиеся незамеченными формы насилия, например травля. По этой проблематике все еще недостаточно данных, поскольку вопросы, которые задаются при сборе данных, включают разные определения насильственного поведения, различные временные рамки отчетности, несопоставимые варианты ответов, механизмы обеспечения конфиденциальности или этические протоколы. Кроме того, не всегда сообщается информация о таких формах насилия, как травля на основе гомофобии, поскольку порой ее относят к запретным темам.`}</p>
    <QualityFramework highlighted="school" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
    <IndicatorReference id="4.a.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Травля`}</strong>{` является самой распространенной и задокументированной формой насилия в школе. Она может включать физическое насилие, словесные оскорбления и намерение нанести психологическую травму путем унижения или изоляции. Приведенная ниже диаграмма показывает, что по меньшей мере каждый третий подросток становится жертвой травли, причем в большинстве стран большинство из них зачастую составляют мальчики, а не девочки.`}</p>
      <Figure id="BULLY_FIGHT" mdxType="Figure" />
    </IndicatorReference>
    <p><strong parentName="p">{`Физическое насилие`}</strong>{` включает участие в школьных драках, ношение оружия или угрозу применения оружия, например, пистолета, ножа или биты, на территории школы, участие в банде или ее поддержку, или физическое насилие со стороны сотрудников школы.`}</p>
    <p>{`По данным отчетов, более 30% подростков из 26 стран участвовали в драках в период 2012-2017 гг., а в Тувалу этот показатель достиг более 70%. Школьное насилие также является серьезной проблемой в таких странах Персидского залива, как Бахрейн, Кувейт и Оман.`}</p>
    <h2 {...{
      "id": "заключение"
    }}>{`Заключение`}</h2>
    <p>{`В то же время, как ни странно, существует серьезная нехватка сопоставимых на между-народном уровне данных по показателям, касающимся учителей. Относительно немногие страны сообщают хотя бы об общем числе учителей, не включая в эти данные количество учебных часов, число учителей на административных должностях и другие сложные данные. Необходима типология стандартов для подготовленных учителей, с тем чтобы надлежащим образом выявлять пробелы и поднять этот вопрос на глобальный уровень.`}</p>
    <p>{`Как показано в настоящей статье, создание «учитывающих интересы детей, инвалидов и гендерные аспекты» образовательных учреждений и «безопасной, ненасильственной, инклюзивной и эффективной учебной среды для всех» представляет собой сложную задачу для многих стран. В то же время измерить прогресс в этом вопросе нелегко, поскольку соответствующий глобальный показатель предполагает измерение не одного параметра, а набора различных параметров.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.oecd-ilibrary.org/education/education-at-a-glance_19991487"
          }}>{`Education at a glance – OECD`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theirworld.org/news/safe-schools-report-reveals-crises-challenges-action-needed-to-educate-children"
          }}>{`Safe Schools Report – Their World`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/fs46-more-than-half-children-not-learning-en-2017.pdf"
          }}>{`More Than One-Half of Children and Adolescents Are Not Learning Worldwide – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232107"
          }}>{`School-related gender-based violence is preventing the achievement of quality education for all`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;