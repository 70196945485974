import css from "@emotion/css";
import styled from "@emotion/styled";
import Tippy, { TippyProps } from "@tippy.js/react";
import { useTheme } from "hooks";
import React from "react";
import "tippy.js/dist/tippy.css";
import * as M from "../materials";

// It should be safe to cast this to "any" because we wrap it. We need to do
// this because "styled" omits some props it can't deal with, it seems …
const SHARED_PROPS: $FixMe = {
  maxWidth: M.spacing.base8(38),
  zIndex: M.zIndex.tooltip,
  placement: "bottom",
};

// Using new, UNESCO Global Library styles.
const GEMTippy = styled(Tippy)`
  min-width: 200px;
  background: white;
  border-radius: ${M.spacing.base1(2)};
  padding: ${M.spacing.base8(1.5)} ${M.spacing.base8(2)} ${M.spacing.base8(0)};
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);

  .tippy-content {
    padding: 0;
  }

  &[data-placement^="top"] .tippy-arrow {
    border-top-color: white;
  }
  &[data-placement^="right"] .tippy-arrow {
    border-right-color: white;
  }
  &[data-placement^="bottom"] .tippy-arrow {
    border-bottom-color: white;
  }
  &[data-placement^="left"] .tippy-arrow {
    border-left-color: white;
  }
`;

function TooltipContainer({ children, ...overrides }: TippyProps) {
  return (
    <GEMTippy {...SHARED_PROPS} {...overrides}>
      {children}
    </GEMTippy>
  );
}

// Legacy tooltip, not yet using new, UNESCO Global Library styles.
// FIXME: in case design differs UNESCO Global Library.
const GEMChartTippy = styled(Tippy)`
  background: white;
  border-radius: ${M.spacing.base1(2)};
  padding: ${M.spacing.base8(1)} ${M.spacing.base8(1)} ${M.spacing.base8(2)}
    ${M.spacing.base8(1)};
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.15);
  &[data-placement^="top"] .tippy-arrow {
    border-top-color: white;
  }
  &[data-placement^="right"] .tippy-arrow {
    border-right-color: white;
  }
  &[data-placement^="bottom"] .tippy-arrow {
    border-bottom-color: white;
  }
  &[data-placement^="left"] .tippy-arrow {
    border-left-color: white;
  }
`;

function ChartTooltipContainer({ children, ...overrides }: TippyProps) {
  return (
    <GEMChartTippy {...SHARED_PROPS} {...overrides}>
      {children}
    </GEMChartTippy>
  );
}

// -----------------------------------------------------------------------------
// InteractiveTooltip

const interactiveStyles = {
  contentWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  title: css`
    ${M.fontLead2};
    font-weight: bold;
    color: ${M.blackText};
    margin-bottom: ${M.spacing.base8(1)};
  `,
  body: css`
    ${M.fontTable};
    color: ${M.blackText};
    margin-bottom: ${M.spacing.base8(1)};
  `,
  source: css`
    margin-bottom: ${M.spacing.base8(1.5)};
    &,
    & small {
      ${M.fontAxis};
      color: ${M.lightText};
    }
  `,
};

export function InteractiveTooltip({
  children,
  content,
  ...props
}: Omit<TippyProps, "content"> & {
  content: {
    title: React.ReactChild;
    body: TippyProps["content"];
    source?: React.ReactChild;
  }[];
}) {
  return (
    <TooltipContainer
      interactive
      trigger="click"
      content={
        <div css={interactiveStyles.contentWrapper}>
          {content.map(({ title, body, source }, i) => (
            <React.Fragment key={i}>
              <h2 css={interactiveStyles.title}>{title}</h2>
              <div css={interactiveStyles.body}>{body}</div>
              {source && (
                <div css={interactiveStyles.source}>
                  <small>{source}</small>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      }
      {...props}
    >
      {children}
    </TooltipContainer>
  );
}

// -----------------------------------------------------------------------------
// InfoTooltip

const chartTooltipStyles = {
  title: css`
    ${M.fontMeta};
    font-weight: bold;
    color: ${M.blackText};
    margin-bottom: ${M.spacing.base8(0.5)};
  `,
  body: css`
    ${M.fontMeta};
    color: ${M.blackText};
    margin-bottom: ${M.spacing.base8(-1)};
  `,
};

export function ChartTooltip({
  children,
  content,
  title,
  ...props
}: TippyProps & {
  title: React.ReactChild;
}) {
  return (
    <ChartTooltipContainer
      content={
        <>
          <h2 css={chartTooltipStyles.title}>{title}</h2>
          <div css={chartTooltipStyles.body}>{content}</div>
        </>
      }
      {...props}
    >
      {children}
    </ChartTooltipContainer>
  );
}

// -----------------------------------------------------------------------------
// GridBody

export function TooltipGridBody({
  rows,
}: {
  rows: Array<Array<React.ReactNode>>;
}) {
  const {
    client: { isRTL },
  } = useTheme();
  const dir = isRTL ? "right" : "left";

  return (
    <div>
      {rows.map((row, i) => (
        <div
          key={i}
          css={css`
            display: flex;
            justify-content: flex-start;
            & > * {
              padding-${dir}: ${M.spacing.base8(1)};
              &:first-of-type {
                padding-${dir}: 0;
              }
              &:last-child {
                margin-${dir}: auto;
              }
            }
          `}
        >
          {row.map((item, j) => (
            <div key={j}>{item}</div>
          ))}
        </div>
      ))}
    </div>
  );
}

/**
 * Simplified version of TooltipGridBody
 *
 * - Expects the first element to be a color string.
 * - Only accepts strings, not ReactNodes
 */
export function TooltipGridBodyWithLegend({
  rows,
}: {
  rows: Array<Array<string>>;
}) {
  return (
    <TooltipGridBody
      rows={rows.map(([color, ...rest]) => [
        <div
          key={""}
          css={css`
            flex-grow: 0;
            position: relative;
            top: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${color};
          `}
        ></div>,
        ...rest,
      ])}
    />
  );
}
