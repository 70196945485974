import { useTheme } from "hooks";
import React from "react";
import * as M from "../../materials";
import css from "@emotion/css";
import { ReactComponent as BookIcon } from "../../icons/ic_book_32dp.svg";

export function ReferenceButton({
  url,
  children,
}: $PropsWithChildren & { url: string }) {
  const { palette, client } = useTheme();
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      css={css`
        width: fit-content;
      `}
    >
      <button
        css={css`
          min-width: ${client.screenSDown ? "100%" : "330px"}};
          border: solid 1px ${palette.brand.marine.main};
          color: ${palette.brand.marine.main};
          ${M.fontBody2}
          background-color: transparent;
          display: flex;
          align-items: center;
          gap: ${M.spacing.base8(2)};
          padding: ${M.spacing.base8(2)};
          cursor: pointer;

          &:hover {
            background-color: #cce1f0;
          }
        `}
      >
        <BookIcon />
        {children}
      </button>
    </a>
  );
}
