import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "TRAINED",
  title: /*i18n*/ {
    id: "fig.quality.TRAINED.title",
    defaults: `Percentage of teachers who are trained according to national standards`,
  },
  caption: /*i18n*/ {
    id: "fig.quality.TRAINED.caption",
    defaults: `Many teachers are not trained, and in Sub-Saharan Africa, fewer are trained than in the past.`,
  },
  source: /*i18n*/ {
    id: "fig.quality.TRAINED.source",
    defaults: `UIS database`,
  },
};
