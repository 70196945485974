import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "PREPRIM",
  title: /*i18n*/ {
    id: "fig.access.PREPRIM.title",
    defaults: `Participation rate in organized learning (one year before the official primary entry age)`,
  },
  caption: /*i18n*/ {
    id: "fig.access.PREPRIM.caption",
    defaults: `Three in four children attend pre-school the year before entering primary education`,
  },
  source: /*i18n*/ {
    id: "fig.access.PREPRIM.source",
    defaults: `UIS database`,
  },
};
