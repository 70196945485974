import React from "react";
import { lineChartDefaultStyles as styles } from "./styles";

const TICK_HEIGHT = 4;

const XTick = React.memo(
  ({
    textAnchor,
    label,
    transform,
  }: {
    textAnchor: "inherit" | "middle" | "end" | "start" | undefined;
    label?: string;
    transform?: string;
  }): React.ReactElement => (
    <g transform={transform}>
      <line css={styles.axisXLine} y2={TICK_HEIGHT} />
      <text
        css={styles.axisLabel}
        y={TICK_HEIGHT + 5}
        dy="0.6em"
        textAnchor={textAnchor}
      >
        {label}
      </text>
    </g>
  )
);

export default XTick;
