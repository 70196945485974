import css from "@emotion/css";
import { io } from "../prelude";
export const DEFAULT_LAYOUT: Layout = "article";

export const Layout = io.keyof({
  home: null,
  simple: null,
  article: null,
  articleNarrow: null,
  full: null,
  explorer: null,
  focus: null,
  none: null,
});
export type Layout = io.TypeOf<typeof Layout>;

export function foldLayout<A>(x: Layout, pattern: Record<Layout, () => A>): A {
  return (pattern[x] as $Unexpressable)(x);
}

export const layoutAreas = {
  full: "full-start / full-end",
  fullStart: "full-start",
  fullEnd: "full-end",
  main: "main-start / main-end",
  mainStart: "main-start",
  mainEnd: "main-end",
  side: "side-start / side-end",
  sideStart: "side-start",
  sideEnd: "side-end",
  figure: "figure-start / figure-end",
  figureStart: "figure-start",
  figureEnd: "figure-end",
  wide: "wide-start / wide-end",
  wideStart: "wide-start",
  wideEnd: "wide-end",
  center: "center-start / center-end",
  centerStart: "center-start",
  centerEnd: "center-end",
};

export const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`;
