import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "COMPLETION",
  title: /*i18n*/ {
    id: "fig.access.COMPLETION.title",
    defaults: `Timely and ultimate cohort completion rate by level`,
  },
  caption: /*i18n*/ {
    id: "fig.access.COMPLETION.caption",
    defaults: "Many children complete school late",
  },
  source: /*i18n*/ {
    id: "fig.access.COMPLETION.source",
    defaults: "GEM Report team analysis using household survey data",
  },
};
