import { I18n, MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { Ar, io, Ord, pipe } from "../prelude";

const genderOrder = {
  T: 0,
  F: 1,
  M: 2,
};

export const Gender = io.keyof(genderOrder);
export type Gender = io.TypeOf<typeof Gender>;

export const getGenderIndex = (k: Gender) => genderOrder[k];

export const ordGender = Ord.contramap((gender: Gender) =>
  getGenderIndex(gender)
)(Ord.ordNumber);

const genderLookup: Record<Gender, MessageDescriptor> = {
  T: t`Total`,
  F: t`Female`,
  M: t`Male`,
};
export const getGenderName = (i18n: I18n, gender: Gender) =>
  i18n._(genderLookup[gender]);

export function sortGenders<A>(xs: Array<A>, get: (x: A) => Gender) {
  return pipe(
    xs,
    Ar.sort(Ord.contramap((needle: A) => get(needle))(ordGender))
  );
}
