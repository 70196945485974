
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Nutzungsbedingungen";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">Nutzungsbedingungen </Title>
    <p>{`Der Weltbildungsbericht hält zur Nutzung der Website Scoping Progress in Education (SCOPE) zu rein informativen Zwecken an. Alle Inhalte dieser Website sind urheberrechtlich geschützt. Durch den Besuch der Website und die Nutzung jeglicher darauf befindlicher Inhalte stimmen Sie (die „Nutzerin“/der „Nutzer“) zu, dass Sie diese Nutzungsbedingungen vollständig gelesen und verstanden haben und sich einverstanden erklären, daran gebunden zu sein.`}</p>
    <p>{`Die auf dieser Website verfügbaren Informationen wurden in der Absicht veröffentlicht, für die persönliche oder öffentliche nicht-kommerzielle Nutzung leicht verfügbar zu sein. Sie können vollständig oder teilweise und auf jegliche Weise reproduziert werden, ohne dass dafür Gebühren anfallen oder weitere Genehmigungen erforderlich wären, wenn nicht anders angegeben. Jegliche Anfragen hinsichtlich kommerzieller Nutzung und Übersetzungsrechten senden Sie bitte an:`}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "die-nutzerinnen-und-nutzer-sind-aufgefordert"
    }}>{`Die Nutzerinnen und Nutzer sind aufgefordert:`}</h3>
    <ul>
      <li parentName="ul">{`Mit äußerster Sorgfalt dafür zu sorgen, dass reproduzierte Materialien fehlerfrei sind;`}</li>
      <li parentName="ul">{`Die Quelle gut sichtbar neben den Daten in folgendem Format anzugeben:`}</li>
    </ul>
    <p>{`Quelle: (Falls zutreffend „adaptiert aus“) GEM Report Scoping Progress in Education (SCOPE) website, `}<a parentName="p" {...{
        "href": "https://www.education-progress.org"
      }}>{`https://www.education-progress.org`}</a>{`, Datum des Abrufs.`}</p>
    <p>{`Es ist darauf hinzuweisen, dass der Datenbestand regelmäßig aktualisiert und überarbeitet wird. Daher ist es wichtig, beim Zitieren von Angaben das Abrufdatum anzugeben.`}</p>
    <h3 {...{
      "id": "lizenzen"
    }}>{`Lizenzen`}</h3>
    <p>{`Sie sind dazu angehalten, die Daten zu Ihrem eigenen Nutzen und dem anderer auf kreative Weise zu verwenden. Sie können die in den Datensätzen enthaltenen Informationen entnehmen, herunterladen und kopieren. Zudem können Sie diese Informationen mit Dritten teilen.`}</p>
    <h3 {...{
      "id": "ausschluss-von-zugehörigkeit-oder-befürwortung"
    }}>{`Ausschluss von Zugehörigkeit oder Befürwortung`}</h3>
    <p>{`Die Nutzerin oder der Nutzer darf es weder so darstellen noch andeuten, dass der Weltbildungsbericht an ihrer oder seiner Nutzung beziehungsweise Reproduktion des Materials beteiligt war, diese genehmigt, befürwortet oder anderweitig unterstützt hat. Die Nutzerin oder der Nutzer darf keinerlei Zugehörigkeit zur Website Fortschritte im Bildungsbereich (oder dem Weltbildungsbericht) behaupten.`}</p>
    <h3 {...{
      "id": "website-links"
    }}>{`Website-links`}</h3>
    <p>{`Eine externe Website kann einen Hyperlink zu einer Seite dieser Website Fortschritte im Bildungsbereich enthalten, ohne die Erlaubnis dafür eingeholt zu haben. Dadurch dürfen jedoch die geistigen Eigentumsrechte des Weltbildungsberichts, insbesondere in Bezug auf Namen, Logos, Urheber- und Autorenrechte, nicht verletzt werden.`}</p>
    <h3 {...{
      "id": "haftungsausschluss"
    }}>{`Haftungsausschluss`}</h3>
    <p>{`Der Weltbildungsbericht unterhält diese Website, um den öffentlichen Zugang zu Daten und damit zusammenhängenden Informationen zu stärken. Er bemüht sich nach Kräften, die Richtigkeit und Vollständigkeit der Informationen auf dieser Website zu gewährleisten, garantiert diese jedoch nicht. Unser Ziel ist es, diese Informationen zeitnah und akkurat zu halten. Sollten wir auf Fehler hingewiesen werden, bemühen wir uns, diese zu korrigieren.`}</p>
    <p>{`Die verwendeten Bezeichnungen und die Präsentation der Inhalte auf dieser Website stellen keinerlei Meinungsäußerung seitens der UNESCO hinsichtlich des Rechtsstatus eines Landes, eines Territoriums, einer Stadt, eines Gebietes, deren Behörden oder von Grenzverläufen dar.`}</p>
    <p>{`Der Global Education Monitoring Report trägt die Verantwortung für die Auswahl und Präsentation der auf dieser Website enthaltenen Fakten, die nicht unbedingt der Meinung der UNESCO entsprechen und die Organisation in keiner Weise verpflichten.`}</p>
    <p>{`Wir bemühen uns, durch technische Fehler verursachte Störungen zu minimieren. Es ist jedoch möglich, dass Daten oder Informationen auf der Website in Dateien oder Formaten erstellt oder strukturiert wurden, die nicht fehlerfrei sind, und wir können nicht garantieren, dass unser Service durch derlei Probleme nicht unterbrochen oder anderweitig beeinträchtigt wird. Der Weltbildungsbericht übernimmt keine Haftung für die Probleme (Leistungsausfall, Computervirus, Ausfall von Kommunikationsleitungen, Änderung von Inhalten usw.), die infolge der Nutzung der Website SCOPE oder eines Links zu externen Websites auftreten.`}</p>
    <h3 {...{
      "id": "datenschutz"
    }}>{`Datenschutz`}</h3>
    <p>{`Wenn Sie auf die Website SCOPE zugreifen, werden bestimmte Informationen, wie IP-Adressen, Navigationsverhalten auf der Website, genutzte Software und Dauer des Besuchs neben anderen ähnlichen Informationen auf unseren Servern gespeichert. Diese Informationen identifizieren Sie nicht ausdrücklich, sie werden vielmehr zur Analyse unserer Websitenutzung verwendet.`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;