
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "(How) do writing tools and techniques matter?";
export const lead = "Despite being part of the ‘three Rs’ of basic skills taught in schools, writing – unlike reading and mathematics – is rarely included in standardized learning assessments.";
export const layout = "focus";
export const article = "learning";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="(How) do writing tools and techniques matter?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=236" mdxType="FocusDetails">
      <h1 {...{
        "id": "how-do-writing-tools-and-techniques-matter"
      }}>{`(How) do writing tools and techniques matter?`}</h1>
      <p>{`Despite being part of the ‘three Rs’ of basic skills taught in schools, writing – unlike reading and mathematics – is rarely included in standardized learning assessments. The 2019 Regional Comparative and Explanatory Study assessment in Latin America, known by its Spanish acronym, ERCE, included a module for assessing writing skills, but the results, which showed that struggles with writing are at least as widespread as with those reading, were not widely disseminated. In the United States, the National Assessment of Educational Progress (NAEP) included a writing module in 2007, 2011 and 2017. The results from 2011 show a stark gender gap, with 37% of girls performing at a proficient or advanced level compared to 18% of boys. The 2017 results have not been made available because preliminary analyses revealed confounding factors affecting them: in particular, the writing task used to be a pen and paper task, but was moved to laptops for the 2011 assessment and then to tablets in 2017. `}</p>
      <p>{`The challenges presented by the choice of writing technology is a clear reminder that writing has a material dimension that cannot be separated from technology, whether it is writing on a slate with chalk or writing on a tablet with a stylus. Writing is, in and of itself, technology (Haas, 2013). Creating a visual mark for others to read has a history spanning millennia, starting with drawing in the sand with toes, fingers or a stick. Other writing surfaces and instruments have included papyrus, clay, ink applied with quills and mechanical typewriters.`}</p>
      <p>{`Technology also has a role to play in writing, from the banal, such as automatic spell-checking, to the less tangible, such as enabling online collaborative critique. Assistive technologies are also key for writing, even if they tend to blur the line between writing and speaking: cheek-controlled virtual keyboards (Lange, 2011) may count as writing but are a stepping stone for text-to-speech synthesis. Conversely, even the relatively well-established technology of speech-to-text conversion has so far not diminished the continuing reliance on mechanical writing in classrooms, although this may change in the future. In a survey in Sweden, more than a third of teachers reported using speech-to-text technology to help writing instruction with all their students, not just the ones needing assistance, ‘once or several times’ per week (Fälth and Selenius, 2022).`}</p>
      <p>{`But technology also has a role to play in the physical act of writing. Two technologies predominate: pens, pencils and paper on the one hand and screens and keyboards on the other. Less clear is how each shapes language performance and learning. A variety of studies shows that each technology may have learning benefits. `}</p>
      <p>{`Multisensory learning (Shams and Seitz, 2008) might be one of the reasons explaining experimental research showing higher learning gains in handwriting (Vasylets and Marín, 2022) or the retention of notes taken (Mueller and Oppenheimer, 2014). Stimulation in the reading brain circuitry of 5-year-olds was found when they were handwriting but not when they were typing (Lee et al., 2022). A recent review documents evidence (partly from neuroimaging) on the benefits of handwriting, notably including with digital pens, in terms of writing letters and words as well as reading (Vasylets and Marín, 2022). One hypothesis is that the variability in handwriting provides a stimulus beneficial for learning. By contrast, the greater amount of movement involved in typing, in turn, offers kinaesthetic learning benefits of its own (Askvik et al., 2020). However, differences in alphabet matter. In Chinese language learning, for instance, handwriting benefited learners’ orthography and orthography-semantic mapping, while typing showed an advantage in phonography recognition and phonology-orthographic mapping (Lyu et al., 2021).`}</p>
      <p>{`Another question is whether the choice of writing technology impacts the style and literary quality of the writing. As linear finger movements are less demanding of motor skills, learners can reach an ‘automatic’ level faster when typing, leaving more time to think about higher-level features of what they want to write (Trubek, 2016). While some true- and quasi-experimental evidence does point in the direction of handwriting making better writers (Santangelo and Graham, 2016), recent writing research has provided evidence that typing might favour writing processes and performance (Vasylets and Marín, 2022). A meta-analysis found that handwriting and keyboarding fluency were significantly related, and both make for better writers (Feng et al., 2019). A study of Norwegian grade 1 students found no difference between texts written with pen on paper or touch-typed on a tablet (Spilling et al., 2021). A small-scale qualitative study suggested that students, especially reluctant writers, were more motivated to write longer texts when typing (Rønningsbakk, 2022). Indeed, offering typing as an alternative is a well-established accommodation in response to certain learning or functional difficulties (Freeman et al., 2005). `}</p>
      <p>{`Ultimately, in their daily lives outside of school, young people tend to use different writing technologies depending on their subjective advantages and disadvantages in a given context (Farinosi et al., 2016). More important than the choice between them may be the proficiency in the chosen technique. When handwriting is used, better handwriting is associated with higher text quality (Limpo et al., 2017; Skar et al., 2021) and academic success (McCarroll and Fletcher, 2017). Similarly, without the ability to touch-type, in other words, type without looking at the keys, typing is not necessarily faster than handwriting (Weigelt-Marom and Weintraub, 2018) and the quality of texts written on a computer suffers (Weerdenburg et al., 2019). Students in grade 8 at schools that required better keyboarding skills scored higher in the NAEP writing task.`}</p>
      <p>{`Detailed data from the NAEP on the writing habits and performance of grade 8 students in the United States show that both students who report writing their school assignments by hand more frequently and those who use a computer more frequently score higher writing scores. The same is true of students whose teachers encourage them to use a mixed approach of editing and finishing handwritten early drafts on a computer.`}</p>
      <FocusImage size="small" src={"21-writingtools.png"} alt="Strong writers can be found both among those writing by hand and those typing" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=237" mdxType="FocusImage" />
      <p>{`Whether typing, handwriting or using a combination of the two, students always had higher proficiency levels when carrying out writing assignments more often. What seems to matter most is simply performing writing assignments frequently, by any means. It seems then that, as handwriting and typing are not mutually exclusive in real life, both have a place in the classroom. `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;