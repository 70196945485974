import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "GOVHH_ABS_REL",
  title: /*i18n*/ {
    id: "fig.finance.GOVHH_ABS_REL.title",
    defaults: `Shares of governments and households in education spending`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.GOVHH_ABS_REL.caption",
    defaults: `In many low and middle income countries, households contribute between one fifth and half of all education spending.`,
  },
  source: /*i18n*/ {
    id: "fig.finance.GOVHH_ABS_REL.source",
    defaults: `UIS database`,
  },
};
