
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Conditions d’utilisation";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title"> Conditions d’utilisation </Title>
    <p>{`Le Rapport mondial de suivi sur l’éducation encourage l’utilisation du site Web Scoping Progress in Education (SCOPE) à des fins d’information uniquement. Tous les contenus de ce site Web sont protégés par le droit d’auteur. En consultant le site et en utilisant son contenu, vous (« l’utilisateur ») déclarez avoir pris connaissance de l’intégralité des conditions générales d’utilisation et acceptez de vous y conformer.`}</p>
    <p>{`Les informations présentées sur ce site sont publiées dans le but d’être aisément accessibles pour un usage personnel ou public à des fins non commerciales. Leur reproduction, en tout ou en partie, et par quelque moyen que ce soit, est autorisée sans frais et sans autre autorisation, sauf indication contraire. Toutes les demandes relatives à un usage commercial et aux droits de traduction sont à adresser à `}<a parentName="p" {...{
        "href": "mailto:gemreport@unesco.org"
      }}>{`gemreport@unesco.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "les-utilisateurs-sont-tenus-"
    }}>{`Les utilisateurs sont tenus :`}</h3>
    <ul>
      <li parentName="ul">{`d’exercer la diligence requise en vue de garantir l’exactitude des éléments reproduits ;`}</li>
      <li parentName="ul">{`de mentionner la source de façon bien visible à côté des données utilisées selon la présentation ci-après :`}</li>
    </ul>
    <p>{`Source: (« adapté de » s’il y a lieu),Scoping Progress in Education (SCOPE) – Rapport GEM, `}<a parentName="p" {...{
        "href": "https://www.education-progress.org"
      }}>{`https://www.education-progress.org`}</a>{`, date de consultation.`}</p>
    <p>{`Il est à noter que la base de données fait régulièrement l’objet de mises à jour et de révisions. C’est pourquoi il est important de préciser la date de consultation lorsque l’on cite des données.`}</p>
    <h3 {...{
      "id": "licences"
    }}>{`Licences`}</h3>
    <p>{`Nous vous encourageons à utiliser les données à votre profit et à celui d’autrui de façon créative. Vous pouvez extraire, télécharger et copier les informations contenues dans les ensembles de données afin de les partager avec des tiers.`}</p>
    <h3 {...{
      "id": "aucune-association-ni-adhésion"
    }}>{`Aucune association ni adhésion`}</h3>
    <p>{`L’utilisateur ne saurait ni représenter le Rapport mondial de suivi de l’éducation, ni laisser entendre que celui-ci a participé à l’utilisation ou à la reproduction du matériel par l’utilisateur, ni qu’il les a approuvées, cautionnées ou soutenues de quelque manière que ce soit. L’utilisateur ne saurait prétendre à une quelconque affiliation avec l’outil sur les Progrès de l’éducation (ni avec le Rapport mondial de suivi de l’éducation).`}</p>
    <h3 {...{
      "id": "liens-internet"
    }}>{`Liens internet`}</h3>
    <p>{`Un site Web externe peut ajouter un lien hypertexte vers une page du site Web SCOPE sans en demander l’autorisation. Cela ne doit toutefois pas porter atteintes aux droits de propriété intellectuelle du Rapport mondial de suivi de l’éducation, notamment en ce qui concerne les noms, les logos ou les droits d’auteur.`}</p>
    <h3 {...{
      "id": "avertissement"
    }}>{`Avertissement`}</h3>
    <p>{`Le Rapport mondial de suivi sur l’éducation actualise régulièrement ce site Web afin d’améliorer l’accès du public aux données et aux informations connexes. Tout est mis en œuvre pour assurer l’exactitude et l’exhaustivité des informations présentées sur le site, sans que l’on puisse toutefois les garantir. Notre objectif est de présenter des informations exactes et tenues à jour. Si des erreurs sont portées à notre attention, nous nous attachons à les rectifier.`}</p>
    <p>{`Les appellations employées sur ce site Web et la présentation des données qui y figurent n’impliquent de la part de l’UNESCO aucune prise de position quant au statut juridique des pays, territoires, villes ou zones ou de leurs autorités, ni quant au tracé de leurs frontières ou limites.`}</p>
    <p>{`Le choix et la présentation des données contenues sur ce site Web sont ceux de l’équipe du Rapport mondial de suivi sur l’éducation ; ils ne reflètent pas nécessairement les points de vue de l’UNESCO et n’engagent en aucune façon l’Organisation.`}</p>
    <p>{`Nous faisons de notre mieux pour limiter autant que possible les inconvénients dus à des erreurs techniques. Cependant, certaines données ou informations présentées sur le site Web peuvent ne pas avoir été créées ou structurées dans des fichiers ou des formats exempts d’erreurs, de sorte que nous ne pouvons garantir que nos services ne seront pas interrompus ou autrement affectés par de tels problèmes. Le Rapport mondial de suivi de l’éducation décline toute responsabilité à l’égard des problèmes de cette sorte (erreur de fonctionnement, virus informatiques, coupure des lignes de communication, altération du contenu, etc.) provenant de l’utilisation du site Web SCOPE ou de tout autre lien vers des sites extérieurs.`}</p>
    <h3 {...{
      "id": "confidentialité"
    }}>{`Confidentialité`}</h3>
    <p>{`Lorsque vous accédez au site Web SCOPE, certaines informations, telles que les adresses de protocole Internet (IP), le comportement de navigation sur le site, le logiciel utilisé, le temps passé et autres renseignements analogues sont stockées sur nos serveurs. Ces données ne permettront pas de vous identifier spécifiquement mais serviront à analyser la fréquentation du site Web.`}</p>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;