import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "AID_TARGETING",
  title: /*i18n*/ {
    id: "fig.finance.AID_TARGETING.title",
    defaults: `Aid to education as % of GNI, and share going to low-income countries and to basic education`,
  },
  caption: /*i18n*/ { id: "fig.finance.AID_TARGETING.caption", defaults: `` },
  source: /*i18n*/ {
    id: "fig.finance.AID_TARGETING.source",
    defaults: `GEM Report team analysis based on OECD CRS`,
  },
};
