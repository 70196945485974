
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Learning Trajectories";
export const lead = "While most children now spend many years in school, they often emerge without even the most basic reading and math skills. Learning trajectories show how much children learn as they progress through school. They can help us understand the process that leads to low learning outcomes, when and how to intervene during that process, and what the simulated impact of different policies might be on the urgent challenge of low learning.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyStep = makeShortcode("ScrollyStep");
const Explorer = makeShortcode("Explorer");
const TrajectoriesTab = makeShortcode("TrajectoriesTab");
const SimulationsTab = makeShortcode("SimulationsTab");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Learning trajectories show how many years or grades it takes for children to acquire foundational skills. Because these trajectories track the pace of learning in the system, they can help us understand how many children struggle to acquire these skills, when and how to intervene, and how different policies might impact the urgent challenge of low learning.`}</p>
    </blockquote>
    <h2 {...{
      "id": "what-can-learning-trajectories-tell-us"
    }}>{`What can learning trajectories tell us?`}</h2>
    <p>{`Ideally, learning trajectories are steep. However, too often we see learning trajectories that are flat, indicating a slow pace of learning. A slow start can have long-term consequences. This is because when children fall behind, the curriculum often moves on to more advanced material with which they lack the prerequisite skills to engage.`}</p>
    <p><strong parentName="p">{`The trajectories of many countries reveal that the pace of learning is far too slow, and that this is true from the very beginning of school.`}</strong>{` In Grades 1 and 2, when global goals and many national curricula assume that children are learning to read, only a small minority of children actually do so.`}</p>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`These skills, which are measured in the sixth round of the UNICEF Multiple Indicator Cluster Surveys (MICS6), are measured here by whether children could read a simple 70-word story aloud (i.e. “Manh is in class two.”) and answer five simple questions about it (i.e. “What class is Manh in?”). These foundational skills are typically below the minimum proficiency level defined by `}<a parentName="p" {...{
          "href": "https://sdgs.un.org/goals/goal4"
        }}>{`Sustainable Development Goal (SDG) global indicator 4.1.1 a`}</a>{`. The calculated trajectories therefore overestimate progress towards SDG 4.1.1 a, but are still useful estimates to illustrate the pace of learning.`}</p>
    </IndicatorReference>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_CURRENT" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="HIGHLIGHT_VIETNAM" mdxType="ScrollySection">
    <HighlightByIndex index="2" mdxType="HighlightByIndex">Viet Nam</HighlightByIndex> is a good example of
    a relatively high-performing country trajectory. During the initial grades
    of school, most children in Viet Nam learn foundational reading skills.
  </ScrollySection>
  <ScrollySection id="LMIC_GRADE_3" mdxType="ScrollySection">
    The learning trajectory in many other{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">low and lower-middle income</HighlightByIndex>{" "}
    countries is much flatter. Only one in four children in the average country
    currently learns foundational reading skills by Grade 3.
  </ScrollySection>
  <ScrollySection id="LMIC_GRADE_6" mdxType="ScrollySection">
    Low learning persists as children continue through school. Just over half of
    Grade 6 students in the average{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">low- and lower-middle-income</HighlightByIndex>{" "}
    country in the data set have gained what are typically Grade 2-3 reading
    skills.
  </ScrollySection>
  <ScrollySection id="DIFFERENCE_TRAJECTORIES" mdxType="ScrollySection">
    However, the average trajectory hides variation among countries. Anywhere
    between 3 per cent and 76 per cent of children in low- and
    lower-middle-income countries in the data set can read by Grade 3. This
    shows that countries that commit to improving foundational learning can do
    so, even at relatively low levels of national income.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <p>{`For a country that wants to address low learning, the policy takeaways are:`}</p>
    <ul>
      <li parentName="ul">{`Measure and prioritize foundational skills, beginning in the early grades.`}</li>
      <li parentName="ul">{`Align instruction to children’s actual pace of learning. This can be done in many ways, including by setting clear learning goals focused on foundational learning, adjusting the pace of the curriculum and supporting teachers to understand and adapt their teaching to children’s current learning levels.`}</li>
    </ul>
    <h2 {...{
      "id": "policy-simulations-access-and-learning"
    }}>{`Policy simulations: access and learning`}</h2>
    <p>{`We can use learning trajectories to compare the potential impact of different policies. Here, we simulate the impact of policies aimed at increasing access and enhancing learning. Access-oriented policies seek to increase the number of years children spend in school. They include policies such as free primary and secondary education, school building, automatic promotion and other policies that primarily focus on enrolment or attainment. Learning-oriented policies seek to increase how much children learn at each grade in school. Examples of these policies include setting and measuring progress against clear learning goals, realigning instruction to match children’s pace of learning, and supporting effective teaching.`}</p>
    <p>{`Every child should have access to school, and getting children into school is a necessary first step towards universal learning. However, these simulations show that further `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`increases in access`}</HighlightByIndex>{` will, on their own, do very little to address learning. In contrast, there are large potential gains to be made by `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`increasing learning per grade`}</HighlightByIndex>{` to match the pace of higher-performing low- and lower-middle-income countries.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_SIMULATION_LMIC" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="COMPARISON" mdxType="ScrollySection">
    The difference in performance between lower- and higher-performing countries
    is driven by both providing greater access to school and, in particular,
    enabling children to learn more per grade.
  </ScrollySection>
  <ScrollySection id="FADED" mdxType="ScrollySection">
    These simulations show trajectories by age. They include both children in
    and out of school. Since out-of-school children tend to have lower learning
    achievement, these learning trajectories are flatter than those by grade.
  </ScrollySection>
  <ScrollySection id="CURRENT" mdxType="ScrollySection">
    Averaging across five low-performing{" "}
    <HighlightByIndex index="4" mdxType="HighlightByIndex">low- and lower-middle-income</HighlightByIndex>{" "}
    countries, only 8 per cent of 9-year-olds can read at an age-appropriate
    level.
  </ScrollySection>
  <ScrollySection id="SIMULATION_ACCESS" mdxType="ScrollySection">
    The <HighlightByIndex index="2" mdxType="HighlightByIndex">access simulation</HighlightByIndex>{" "}
    approximates what might happen if all children were in school. It does this
    by estimating what would happen if all the children who never attended
    school, or who dropped out, instead attained the same average grade and
    learning level as their in-school peers of the same age. In the same five
    low-performing countries, the simulations suggest that this would increase
    the number of 9-year-olds who can read by 1 percentage point.
  </ScrollySection>
  <ScrollySection id="SIMULATION_LEARNING" mdxType="ScrollySection">
    If 9-year-olds in these countries continued to reach the same grade as they
    do now, but instead were{" "}
    <HighlightByIndex index="3" mdxType="HighlightByIndex">learning as much per grade</HighlightByIndex>as
    children in the five highest-performing low- and lower-middle-income
    countries, then 43 per cent of them might be able to read – an increase of
    35 percentage points.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <h2 {...{
      "id": "policy-simulations-equality"
    }}>{`Policy simulations: equality`}</h2>
    <p>{`Learning trajectories are also a powerful tool to understand the differences in learning between groups inside countries. Here, we compare the learning of the rich and the poor, and simulate the impact of policies that try to reduce inequality between them.`}</p>
    <p>{`Averaging across all low- and lower-middle-income countries in the data set, the children of the rich (family wealth in the top 20 per cent) go to school more, and learn more in each grade, than the children of the poor (family wealth in the bottom 20 per cent). The `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`equal access simulation`}</HighlightByIndex>{` shows what might happen if instead the poor got as much schooling as the rich, while the `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`equal learning simulation`}</HighlightByIndex>{` shows what might happen if instead the poor learned as much per grade as the rich. In both scenarios, and especially in the equal learning simulation, inequality would decrease.`}</p>
    <p><strong parentName="p">{`However, even the children of the rich are not getting a very good education`}</strong>{`. Nearly half of the children of the rich are failing to acquire foundational reading skills, putting a limit on the potential impact of equity-oriented policies. Simulations of equality between boys and girls, or urban and rural children, yield even smaller gains.`}</p>
    <p>{`For a country that wants to address low learning, the policy takeaway is that closing gaps between groups inside countries is only a part of the solution. `}<strong parentName="p">{`An equally pressing challenge is to make systemic changes that improve learning for all children – rich and poor, boys and girls, in urban and rural areas – to achieve a transition from a low- to a high-performing system.`}</strong></p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="TRAJECTORIES_SIMULATION_EQUALITY" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="CURRENT" mdxType="ScrollySection">
    The children of the <HighlightByIndex index="4" mdxType="HighlightByIndex">poor</HighlightByIndex> are
    on a lower learning trajectory than the children of the rich. Averaging
    across 23 low- and lower-middle-income countries, only 19 per cent of
    children from poor families have foundational reading skills by age 9 years.
  </ScrollySection>
  <ScrollySection id="SIMULATION_ACCESS" mdxType="ScrollySection">
    The <HighlightByIndex index="2" mdxType="HighlightByIndex">first simulation</HighlightByIndex> shows
    what might happen if children from poor families got as much schooling as
    children from rich families (in terms of grade attainment by age). Averaging
    across 23 countries, this would only raise foundational reading skills by 4
    percentage points at age 9 years.
  </ScrollySection>
  <ScrollySection id="SIMULATION_LEARNING" mdxType="ScrollySection">
    The <HighlightByIndex index="3" mdxType="HighlightByIndex">second simulation</HighlightByIndex> shows
    what might happen if children from poor families maintained their current
    level of schooling (i.e. attained the same grade as they do now) but learned
    as much per grade as children from rich families. This would potentially
    increase their foundational reading skills by 17 percentage points at age 9
    years.
  </ScrollySection>
  <ScrollySection id="COMPARISON" mdxType="ScrollySection">
    If the children of the poor got as much schooling and learned as much as the
    children of the rich (i.e. if the poor had the same trajectory as the rich)
    the foundational reading skills of children of the poor might go up by 29
    percentage points at age 9 years.
  </ScrollySection>
  <ScrollySection id="COMPARISON_GROUP" mdxType="ScrollySection">
    However, this would still leave nearly half of children without foundational
    reading skills. Even the children of rich families are not getting a good
    education in many countries.
  </ScrollySection>
  <ScrollyStep id="default" mdxType="ScrollyStep" />
    </ScrollyArea>
    <p>{`Note: In this graph, the equal access simulation line does not continue after age 11 years. This is due to missing data in the sample for the equal access simulation from ages 12 to 14 years. Any missing data elsewhere on this page are displayed the same way and are sometimes also shown as a thinner line if there are missing data between two data points.`}</p>
    <h2 {...{
      "id": "create-your-own-learning-trajectories-and-policy-simulations"
    }}>{`Create your own learning trajectories and policy simulations`}</h2>
    <p>{`Below is a tool to create your own learning trajectories and run policy simulations. You can explore data from multiple countries for both foundational reading and mathematics skills.`}</p>
    <p>{`As a reminder, reading skills are measured by whether children could read a simple 70-word story aloud and answer five simple questions about the story. Mathematics skills are measured over four domains (number identification, number discrimination, simple addition, and number patterns) using 21 simple questions. A child is considered to have mastered these basic reading and mathematics skills if they answered all questions correctly. These are typically Grade 2 or 3 level skills and are below the minimum proficiency level defined by `}<a parentName="p" {...{
        "href": "https://sdgs.un.org/goals/goal4"
      }}>{`SDG global indicator 4.1.1a`}</a>{`.`}</p>
    <Explorer mdxType="Explorer">
  <TrajectoriesTab mdxType="TrajectoriesTab">
    Learning trajectories vary greatly across contexts. This tool allows you to
    build, compare and export the trajectories that are most relevant for you.
    You can visualize and compare the trajectories of different countries, or
    you can build and compare the trajectories of groups inside a country.
  </TrajectoriesTab>
  <SimulationsTab mdxType="SimulationsTab">
    These tools allow you to run policy simulations and analyse results to
    inform policy priorities. You can run policy simulations at the country
    level, where you can estimate the impact of achieving universal schooling
    and of achieving the learning per grade of a comparison country. You can
    also run policy simulations for groups inside a country, where you can
    simulate what the impact would be if two population groups had the same
    rates of school attainment and learning.
  </SimulationsTab>
    </Explorer>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Learning trajectories reveal how quickly children acquire foundational learning skills. They are an important tool that can be used to raise public awareness of low learning outcomes and help policymakers plan how to take action.`}</p>
    <h3 {...{
      "id": "acknowledgements"
    }}>{`Acknowledgements`}</h3>
    <p>{`The content of this webpage was created by the `}<a parentName="p" {...{
        "href": "http://riseprogramme.org/"
      }}>{`RISE (Research on Improving Systems of Education) Programme`}</a>{` – the large-scale education systems research programme supported by funding from the `}<a parentName="p" {...{
        "href": "https://www.gov.uk/government/organisations/foreign-commonwealth-development-office"
      }}>{`United Kingdom’s Foreign, Commonwealth and Development Office (FCDO)`}</a>{`, the `}<a parentName="p" {...{
        "href": "http://dfat.gov.au"
      }}>{`Australian Government’s Department of Foreign Affairs and Trade (DFAT)`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://www.gatesfoundation.org"
      }}>{`Bill and Melinda Gates Foundation`}</a>{`. The Programme is managed and implemented through a partnership between `}<a parentName="p" {...{
        "href": "https://www.opml.co.uk"
      }}>{`Oxford Policy Management`}</a>{` and the `}<a parentName="p" {...{
        "href": "http://bsg.ox.ac.uk"
      }}>{`Blavatnik School of Government at the University of Oxford`}</a>{`.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://riseprogramme.org/tools/learning-trajectories"
          }}>{`Learning trajectories resources – RISE`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://data.unicef.org/resources/are-children-really-learning-foundational-skills-report/"
          }}>{`Are Children Really Learning? Exploring foundational skills in the midst of a learning crisis – UNICEF 2022`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.sciencedirect.com/journal/international-journal-of-educational-development/special-issue/1035CNWP9N3"
          }}>{`Special issue on learning profiles (open source) – International Journal of Educational Development (IJED)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://mics.unicef.org/files?job=W1siZiIsIjIwMjAvMDUvMTIvMTgvMjUvNDUvNzAxLzIwXzA1XzA4X01JQ1NfR3VpZGVib29rX2NvbXByZXNzZWQucGRmIl1d&sha=6d386818d588d05c"
          }}>{`How to analyze MICS6 education data - UNICEF`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://riseprogramme.org/publications/descriptive-learning-trajectories-and-policy-simulations-using-mics6-data"
          }}>{`Simulations methodology – RISE`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;