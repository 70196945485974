
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "Прогресс в области образования";
export const lead = "Образование освещает каждый этап пути к лучшей жизни. Все дети нуждаются в доступе к образованию хорошего качества, чтобы раскрыть свой образовательный потенциал и получить более широкие преимущества от образования. Необходимо предпринять особые усилия для того, чтобы все дети и молодые люди могли в равной степени воспользоваться его преобразующей силой. Это требует достаточных ресурсов, особенно для охвата тех, кто нуждается в дополнительной поддержке.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const PageLink = makeShortcode("PageLink");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scope"
      }}>{`SCOPE`}</h1>
      <p>{`Образование освещает каждый этап пути к лучшей жизни. Все дети нуждаются в `}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`доступе `}</HighlightByTheme>{` к образованию хорошего`}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`качества`}</HighlightByTheme>{`, чтобы раскрыть свой`}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`образовательный `}</HighlightByTheme>{` потенциал и получить более широкие преимущества от образования. Необходимо предпринять особые усилия для того, чтобы все дети и молодые люди могли `}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{` в равной степени`}</HighlightByTheme>{` воспользоваться его преобразующей силой. Это требует достаточных `}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`ресурсов`}</HighlightByTheme>{`,особенно для охвата тех, кто нуждается в дополнительной поддержке.`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`В 2015 г. международное сообщество включило цель в области образования – `}<a parentName="p" {...{
          "href": "https://sustainabledevelopment.un.org/sdg4"
        }}>{`ЦУР 4`}</a>{`– в число 17 целей в области устойчивого развития, которые необходимо достичь к 2030 г. С тех пор были `}<PageLink name="indicators" mdxType="PageLink">{`разработаны рамки`}</PageLink>{` для мониторинга прогресса в решении задач, поставленных в этой цели. На этом сайте обобщены основные факты и тенденции в области образования во всем мире по пяти темам.`}</p>
    </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`Он дополняет информацию, содержащуюся в печатном варианте `}<a parentName="p" {...{
          "href": "https://ru.unesco.org/gem-report/allreports"
        }}>{`Всемирного доклада по мониторингу образования`}</a>{`, который является инструментом международного сообщества для мониторинга деятельности и обзора прогресса в достижении нашей цели в области образования – ЦУР 4.`}</p>
    </LandingText>
    <LandingButton mdxType="LandingButton">Начать читать</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;