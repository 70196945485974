import lunr from "lunr";

require("lunr-languages/lunr.stemmer.support.js")(lunr);
require("lunr-languages/lunr.ar.js")(lunr);
require("lunr-languages/lunr.de.js")(lunr);
require("lunr-languages/lunr.es.js")(lunr);
require("lunr-languages/lunr.fr.js")(lunr);
require("lunr-languages/lunr.ru.js")(lunr);

export default lunr;
