
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "Les progrès de l’éducation";
export const lead = "L’éducation éclaire chaque étape du chemin qui mène à une vie meilleure. Tous les enfants ont besoin d’avoir accès à une éducation de bonne qualité pour réaliser leur potentiel d’apprentissage et profiter des bienfaits plus généraux de l’éducation. Des efforts particuliers doivent être entrepris pour que son pouvoir de transformation bénéficie équitablement à tous les enfants et les jeunes. Cela exige des ressources suffisantes, notamment pour atteindre ceux qui ont davantage besoin de soutien.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const PageLink = makeShortcode("PageLink");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scope"
      }}>{`SCOPE`}</h1>
      <p>{`L’éducation éclaire chaque étape du chemin qui mène à une vie meilleure. Tous les enfants ont besoin d’avoir `}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`accès`}</HighlightByTheme>{` à une éducation de bonne `}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`qualité`}</HighlightByTheme>{` pour réaliser leur potentiel d’`}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`apprentissage`}</HighlightByTheme>{` et profiter des bienfaits plus généraux de l’éducation. Des efforts particuliers doivent être entrepris pour que son pouvoir de transformation bénéficie `}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{`équitablement`}</HighlightByTheme>{` à tous les enfants et les jeunes. Cela exige des`}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`ressources`}</HighlightByTheme>{`, notamment pour atteindre ceux qui ont davantage besoin de soutien.`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`En 2015, la communauté internationale a inclus un objectif sur l’éducation `}<a parentName="p" {...{
          "href": "https://sustainabledevelopment.un.org/sdg4"
        }}>{`l'ODD 4`}</a>{`, parmi les 17 Objectifs de développement durable à atteindre avant 2030. Depuis, un `}<PageLink name="indicateurs" mdxType="PageLink">{`cadre détaillé `}</PageLink>{` a été mis en place pour suivre les progrès accomplis en faveur des cibles de l’Objectif. Le présent site récapitule les principaux faits et tendances de l’éducation dans le monde à travers cinq thèmes :`}</p>
    </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`Il complète les informations présentées dans la version imprimée du `}<a parentName="p" {...{
          "href": "https://fr.unesco.org/gem-report/allreports"
        }}>{`Rapport mondial de suivi sur l’éducation`}</a>{`, instrument qui permet à la communauté internationale de suivre et d’examiner les progrès réalisés en faveur de notre objectif sur l’éducation, l’ODD 4.`}</p>
    </LandingText>
    <LandingButton mdxType="LandingButton">Commencer à lire</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;