import { t } from "@lingui/macro";
import { format } from "d3-format";
import React from "react";
import { ScatterplotAuto } from "../charts-motion/scatterplot";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_aid_fig_AID_BASIC";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  getRegionName,
  mkGemEntityDecoder,
  mkOrdRegion,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
} from "../domain";
import { useTheme } from "../hooks";
import { catOptions, extentNumber, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_finance_aid_fig_AID_BASIC";

const DEFAULT_COUNTRIES = ["TGO", "SLE", "COM"];

export const Data = mkGemEntityDecoder(["year"], {
  AID_PERCAPITA: io.number,
  "CR.2": io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const env = useConfig();
  const [, actions] = useFigureState();
  const [selectedCountries] = useMultiSelectState("countries");

  const entityData = useNamedEntities(dataRaw);
  const countryData_ = useCountryEntities(entityData);
  const countryData = React.useMemo(() => {
    const data = countryData_.map((x) => {
      return pipe(
        getCountryMeta(env.countries, x.id),
        O.chain((meta) =>
          pipe(
            O.fromNullable(meta.income),
            O.fold(
              () => O.none,
              (income) => O.some({ ...meta, income })
            )
          )
        ),
        O.map((meta) => ({
          ...x,
          region: meta.income,
          region_name: getRegionName(env.regions, meta.income),
        }))
      );
    });
    return pipe(
      data,
      catOptions,
      Ar.sortBy([
        mkOrdRegion<$FixMe>(env.regions, (x) => x.region),
        Ord.contramap((x: { year: number }) => x.year)(Ord.ordNumber),
      ])
    );
  }, [env.countries, env.regions, countryData_]);

  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  const yearExtent = React.useMemo(
    () =>
      extentNumber(unsafeFromArray(countryData), (x) => x.year) as [
        number,
        number
      ],
    [countryData]
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        minItems: 0,
        maxItems: 5,
        selected: O.some(DEFAULT_COUNTRIES),
      },
      countryControlItems
    );
  }, [i18n, actions, countryControlItems, yearExtent]);

  const chartData = unsafeFromArray(countryData);
  type Datum = typeof chartData[number];

  const getX = React.useCallback((x: Datum) => x?.["CR.2"], []);
  const getY = React.useCallback((x: Datum) => x?.AID_PERCAPITA, []);
  const getColor = React.useCallback((x: Datum) => `${x.year}`, []);
  const getGroup = React.useCallback(
    (x: Datum) => countryIdIso.unwrap(x.id),
    []
  );

  const formatColor = React.useCallback((x: Datum) => `${x.year}`, []);
  const formatGroup = React.useCallback((x: Datum) => x.entity_name, []);
  const xTicks = React.useMemo(() => [0, 1], []);
  const yTicks = React.useMemo(() => [0, 60], []);

  return (
    <div>
      <ScatterplotAuto
        height={client.screenLDown ? M.chartHeight.s : M.chartHeight.m}
        data={chartData}
        getX={getX}
        getY={getY}
        getColor={getColor}
        getGroup={getGroup}
        xLabel={i18n._(t("CR.2")`Lower secondary completion rate`)}
        yLabel={i18n._(
          t("AID_PERCAPITA")`Per capita aid to basic education, US$`
        )}
        formatX={format(".0%")}
        formatY={format(".0f")}
        formatColor={formatColor}
        formatGroup={formatGroup}
        xTicks={xTicks}
        yTicks={yTicks}
        // Year doesn't matter in this case, but the chart needs a value.
        selectedYear={2022}
        displaySelectedYear={false}
        highlightedGroups={selectedCountries}
        onSelect={(x) =>
          actions.toggleMultiSelectItem("countries", countryIdIso.unwrap(x.id))
        }
        displayColorLegend={false}
      />
    </div>
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_AID_BASIC.json"),
  csv: require("../data/data_finance_aid_fig_AID_BASIC.zip"),
  xlsx: require("../data/data_finance_aid_fig_AID_BASIC.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
