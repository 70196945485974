import { Group } from "@visx/group";
import { line } from "@visx/shape";
import { Text } from "@visx/text";
import { springConfigs } from "charts/lib/motion";
import { ScaleLinear } from "d3-scale";
import { curveMonotoneX } from "d3-shape";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { Y_CONNECTOR, Y_CONNECTOR_PADDING } from "charts/lib/shared/constants";
import { lighten } from "../../../../lib/colorManipulator";
import { lineChartDefaultStyles } from "../../shared/styles";
import { ActivePoint, SeriesItem } from "../types";
import { TrajectoriesChartProps } from "../Trajectories";
import {
  LIGHTEN_COEFFICIENT,
  OPACITY_FADED,
  PADDING,
  SERIES_STROKE_WIDTH,
} from "../constants";
import { AccessorForArrayItem } from "@visx/shape/lib/types";

interface SeriesProps<A> {
  data: SeriesItem<A>[];
  seriesAccessor: TrajectoriesChartProps<A>["seriesAccessor"];
  xAccessor: TrajectoriesChartProps<A>["xAccessor"];
  yAccessor: TrajectoriesChartProps<A>["yAccessor"];
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleLinear<number, number>;
  boundedWidth: number;
  lineStyle: TrajectoriesChartProps<A>["lineStyle"];
  formatSeriesLabel?: TrajectoriesChartProps<A>["formatSeriesLabel"];
  backgroundSeriesFilter: TrajectoriesChartProps<A>["backgroundSeriesFilter"];
  activePoint: ActivePoint<A> | null;
  filterSeriesValues?: TrajectoriesChartProps<A>["filterSeriesValues"];
}

const Line = React.memo(
  ({
    path,
    styles,
  }: {
    path: string;
    styles: React.CSSProperties & { stroke: string };
  }): React.ReactElement => {
    /** framer-motion has an issue with some svg props,
     * so for now we spread a typed object */
    const pathProps: React.SVGProps<SVGPathElement> = {
      d: path,
      fill: "none",
      strokeWidth: SERIES_STROKE_WIDTH,
      strokeLinecap: "round",
    };

    return (
      <AnimatePresence>
        <motion.path
          key="data-line"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            stroke: styles.stroke,
            transition: springConfigs.gentle,
          }}
          exit={{ opacity: 0 }}
          {...(pathProps as $IntentionalAny)}
          {...styles}
        />
      </AnimatePresence>
    );
  }
);

function Series<A>({
  data,
  seriesAccessor,
  xAccessor,
  yAccessor,
  scaleX,
  scaleY,
  lineStyle,
  formatSeriesLabel = () => "",
  boundedWidth,
  activePoint,
  backgroundSeriesFilter = () => false,
  filterSeriesValues = () => true,
}: SeriesProps<A>): React.ReactElement {
  const pathGenerator = line({
    x: ((d: A) => scaleX(xAccessor(d))) as AccessorForArrayItem<A, number>,
    y: ((d: A) => scaleY(yAccessor(d) ?? 0)) as AccessorForArrayItem<A, number>,
    defined: (d: A) => yAccessor(d) !== null,
    curve: curveMonotoneX,
  });

  const backdropLines = (values: A[]) => {
    const xValues = values.map(xAccessor);

    let connectLines: A[][] = [];
    let hasFoundValue = false;
    let startDatum: A | undefined;

    xValues.forEach((x: number) => {
      const currentDatum = values.find((d) => xAccessor(d) === x);

      if (currentDatum && yAccessor(currentDatum) !== null) {
        if (!hasFoundValue) {
          hasFoundValue = true;
          return;
        }
        if (startDatum) {
          connectLines = connectLines.concat([[startDatum, currentDatum]]);
          startDatum = undefined;
          return;
        }
      }
      if (
        !startDatum &&
        hasFoundValue &&
        currentDatum &&
        yAccessor(currentDatum) === null
      ) {
        const prevValue = values.find((d) => xAccessor(d) === x - 1);
        if (prevValue) startDatum = prevValue;
        return;
      }
    });

    return connectLines;
  };

  return (
    <>
      {data.map(({ key, values }) => {
        if (values.every((d) => yAccessor(d) === null)) return;
        const seriesStyle = lineStyle(key, values);
        const seriesLabel = formatSeriesLabel(key, values);
        const endY = scaleY(yAccessor(values[values.length - 1]) ?? 0);

        let isFaded = false;
        let shouldRenderLabel = false;
        let strokeWidth = seriesStyle.strokeWidth;

        if (activePoint && activePoint.datum) {
          isFaded = [
            backgroundSeriesFilter(key),
            seriesAccessor(activePoint.datum) !== key,
          ].every(Boolean);

          shouldRenderLabel = [
            seriesLabel && activePoint.x !== scaleX.domain()[1],
          ].every(Boolean);

          if (seriesAccessor(activePoint.datum) === key && strokeWidth < 2) {
            strokeWidth = strokeWidth + 1;
          }
        }

        const stroke = isFaded
          ? lighten(seriesStyle.stroke, LIGHTEN_COEFFICIENT)
          : seriesStyle.stroke;

        const showRenderBackdropLine =
          values.filter((d) => yAccessor(d) !== null).length < values.length &&
          values.filter((d) => yAccessor(d) !== null).length > 1;

        return (
          <Group key={key}>
            <Line
              path={
                pathGenerator(values.filter((d) => filterSeriesValues(d))) || ""
              }
              styles={{
                ...seriesStyle,
                stroke,
                strokeWidth,
              }}
            />
            {showRenderBackdropLine && (
              <>
                {backdropLines(values).map((line, index) => (
                  <g key={index}>
                    <circle
                      cx={scaleX(xAccessor(line[0]))}
                      cy={scaleY(yAccessor(line[0]) ?? 0)}
                      fill={stroke}
                      r={2}
                    />
                    <Line
                      path={pathGenerator(line) || ""}
                      styles={{
                        ...seriesStyle,
                        stroke,
                        strokeWidth: 1,
                      }}
                    />
                    <circle
                      cx={scaleX(xAccessor(line[1]))}
                      cy={scaleY(yAccessor(line[1]) ?? 0)}
                      fill={stroke}
                      r={2}
                    />
                  </g>
                ))}
              </>
            )}

            {shouldRenderLabel && (
              <Group left={boundedWidth + Y_CONNECTOR_PADDING} top={endY}>
                <motion.line
                  x1={0}
                  x2={Y_CONNECTOR}
                  y1={0}
                  y2={0}
                  stroke={seriesStyle.stroke}
                  strokeWidth={SERIES_STROKE_WIDTH}
                  animate={{
                    stroke,
                  }}
                />
                <motion.g
                  opacity={1}
                  animate={{ opacity: isFaded ? OPACITY_FADED : 1 }}
                >
                  <Text
                    x={Y_CONNECTOR + Y_CONNECTOR_PADDING}
                    dx={"0.3em"}
                    verticalAnchor="middle"
                    width={PADDING.right}
                    css={lineChartDefaultStyles["value"]}
                    style={{ fill: stroke }}
                  >
                    {seriesLabel}
                  </Text>
                </motion.g>
              </Group>
            )}
          </Group>
        );
      })}
    </>
  );
}

export default React.memo(Series) as typeof Series;
