import React, { MouseEvent, TouchEvent } from "react";
import { throttle } from "throttle-debounce";
import { O, pipe, tuple } from "../prelude";
// -----------------------------------------------------------------------------
// Config

// "(hover: hover)" is buggy in chrome. Workaround from here:
// https://productforums.google.com/forum/?hl=en-au#!topic/chrome/m2p6BBURymo;context-place=forum/chrome
export const CAN_HOVER =
  typeof window !== "undefined"
    ? !window.matchMedia("(any-hover: none) and (any-pointer: coarse)").matches
    : false;

export const MOVE_THRESHOLD = 30;

type PointerEvent<A> = MouseEvent<A> | TouchEvent<A>;
type ContainerElement = HTMLElement | SVGElement;

// -----------------------------------------------------------------------------
// Hook

export interface PointerPosition {
  x: number;
  y: number;
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
  screenX: number;
  screenY: number;
  elementWidth: number;
  elementHeight: number;
}

export interface PointerPositionConfig {
  delay: number;
  fps: number;
}

export function usePointerPosition<A extends ContainerElement>(
  { delay, fps }: PointerPositionConfig = { delay: 500, fps: 1000 / 30 }
) {
  const pointerTargetRef = React.useRef<A>(null);
  const timerId = React.useRef<null | NodeJS.Timeout>(null);
  const touchEndTimerId = React.useRef<null | NodeJS.Timeout>(null);
  const touchPos = React.useRef<null | [number, number]>(null);
  const canMove = React.useRef(false);
  const [pointerPosition, setPointerPosition] = React.useState<
    O.Option<PointerPosition>
  >(O.none);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseEnter = React.useCallback(
    ((evt: PointerEvent<A>) => {
      if (touchEndTimerId.current) {
        clearTimeout(touchEndTimerId.current);
      }
      allowUserSelect(false);

      canMove.current = true;
      if (pointerTargetRef.current) {
        setPointerPosition(statsFromEvt(pointerTargetRef.current, evt));
      }
    }) as unknown as EventListener,
    [setPointerPosition]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTouchStart = React.useCallback(
    ((evt: PointerEvent<A>) => {
      if (touchEndTimerId.current) {
        clearTimeout(touchEndTimerId.current);
      }
      allowUserSelect(false);

      const startingPos = posFromEvt(evt);
      touchPos.current = posFromEvt(evt);

      if (timerId.current) {
        clearTimeout(timerId.current);
      }

      // If the target element is briefly touched (for at least 100ms), we return
      // a valid pointer position unless the current touch position is too far
      // away from when the event started; in this case, we assume the user is
      // scrolling.
      //
      // If the target element is let go after this first timeout, we dismiss the
      // position after a short timeout.
      //
      // If the target element is still being touched after the first timeout,
      // we transition into a long-press state and keep the pointer position
      // continuously updated through the touchmove event until touching stops.
      timerId.current = setTimeout(() => {
        if (
          pointerTargetRef.current &&
          touchPos.current &&
          pointDist(startingPos, touchPos.current) < MOVE_THRESHOLD
        ) {
          setPointerPosition(statsFromEvt(pointerTargetRef.current, evt));
        }
        const innerTimerId = setTimeout(() => {
          if (pointerTargetRef.current) {
            setPointerPosition(O.none);
          }
        }, 600 /* Hide the tooltip if touch stopped before entering long-press */);
        timerId.current = setTimeout(() => {
          clearTimeout(innerTimerId);
          if (
            pointerTargetRef.current &&
            touchPos.current &&
            pointDist(startingPos, touchPos.current) < MOVE_THRESHOLD
          ) {
            canMove.current = true;
            setPointerPosition(statsFromEvt(pointerTargetRef.current, evt));
          } else {
            setPointerPosition(O.none);
          }
        }, 400 /* Long-press delay minus the tap delay */);
      }, 100 /* Very brief tap delay, just long enough to not fire on scrolling */);
    }) as unknown as EventListener,
    [setPointerPosition, delay]
  );

  const onMove = React.useMemo(
    () =>
      throttle(fps, false, (evt: PointerEvent<A>) => {
        touchPos.current = posFromEvt(evt);
        if (pointerTargetRef.current && canMove.current) {
          evt.preventDefault();
          setPointerPosition(statsFromEvt(pointerTargetRef.current, evt));
          return false;
        }
        return true;
      }) as unknown as EventListener,
    [setPointerPosition, fps]
  );

  const onLeave = React.useCallback(() => {
    touchPos.current = null;
    canMove.current = false;
    setPointerPosition(O.none);

    // Wait a brief moment with disabling user-select to prevent selects on touchend
    touchEndTimerId.current = setTimeout(() => allowUserSelect(true), 100);
  }, [setPointerPosition]);

  React.useEffect(() => {
    const target = pointerTargetRef.current;
    if (target !== null) {
      if (CAN_HOVER) {
        target.addEventListener("mouseenter", onMouseEnter);
        target.addEventListener("mousemove", onMove);
        target.addEventListener("mouseleave", onLeave);
      } else {
        target.addEventListener("touchstart", onTouchStart);
        target.addEventListener("touchmove", onMove);
        target.addEventListener("touchend", onLeave);
        target.addEventListener("touchcancel", onLeave);
      }
    }

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }

      if (target !== null) {
        if (CAN_HOVER) {
          target.removeEventListener("mouseenter", onMouseEnter);
          target.removeEventListener("mousemove", onMove);
          target.removeEventListener("mouseleave", onLeave);
        } else {
          target.removeEventListener("touchstart", onTouchStart);
          target.removeEventListener("touchmove", onMove);
          target.removeEventListener("touchend", onLeave);
          target.removeEventListener("touchcancel", onLeave);
        }
      }
    };
  }, [onMouseEnter, onTouchStart, onMove, onLeave]);

  return tuple(pointerTargetRef, pointerPosition);
}

// -----------------------------------------------------------------------------
// Functions

function allowUserSelect(allow: boolean) {
  if (typeof document !== "undefined") {
    const el = document.body;

    // User select
    el.style.userSelect = allow ? "auto" : "none";
    el.style.webkitUserSelect = allow ? "auto" : "none";
    el.style["msUserSelect" as "userSelect"] = allow ? "auto" : "none";
    allow
      ? el.removeAttribute("unselectable")
      : el.setAttribute("unselectable", "on");
    el[allow ? "removeEventListener" : "addEventListener"](
      "onselectstart",
      preventAction
    );
    el[allow ? "removeEventListener" : "addEventListener"](
      "ondragstart",
      preventAction
    );

    // Context menu
    window[allow ? "removeEventListener" : "addEventListener"](
      "oncontextmenu",
      preventAction
    );
  }
}

function preventAction() {
  return false;
}

function pointDist([xa, ya]: [number, number], [xb, yb]: [number, number]) {
  return Math.sqrt(Math.pow(xb - xa, 2) + Math.pow(yb - ya, 2));
}

function posFromEvt<A extends ContainerElement>(evt: PointerEvent<A>) {
  const { pageX, pageY } = "touches" in evt ? evt.touches[0] : evt;
  return tuple(pageX - window.pageXOffset, pageY - window.pageYOffset);
}

function statsFromEvt<A extends ContainerElement>(
  el: A,
  evt: PointerEvent<A>
): O.Option<PointerPosition> {
  const evtData: O.Option<{
    pageX: number;
    pageY: number;
    clientX: number;
    clientY: number;
    screenX: number;
    screenY: number;
  }> =
    "touches" in evt
      ? evt.touches.length > 0
        ? O.some(evt.touches[0])
        : O.none
      : O.some(evt);

  return pipe(
    evtData,
    O.chain(({ pageX, pageY, clientX, clientY, screenX, screenY }) => {
      const rect = el.getBoundingClientRect();
      return O.some({
        x: pageX - rect.left - window.pageXOffset,
        y: pageY - rect.top - window.pageYOffset,
        pageX,
        pageY,
        clientX,
        clientY,
        screenX,
        screenY,
        elementWidth: rect.width,
        elementHeight: rect.height,
      });
    })
  );
}
