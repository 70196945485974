import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import { mkStyles, useTheme, useThemeNavigation } from "../hooks";
import * as M from "../materials";
import { RAliasArticle } from "../routes";
import { gridCenteredMaxWidth } from "./app-layout";
import { getThemeLabel } from "./article-switcher";
import { PageLink } from "./links";
import React from "react";

const useStyles = mkStyles(({ spacing, palette, typography }) => {
  const { grid, areas } = gridCenteredMaxWidth();
  return {
    root: css`
      ${grid};
      height: ${spacing.base8(20)};
      margin-top: 0;
    `,
    content: css`
      grid-area: ${areas.main};
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 100%;
      color: ${palette.grayscale[7]};
    `,
    item: css`
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      text-align: left;
      cursor: pointer;

      img {
        width: ${spacing.base8(12)};
        transition: 0.4s transform;
      }
      &:hover img {
        transform: translateY(${spacing.base8(-1)});
      }

      &:nth-of-type(2) {
        text-align: right;
        flex-direction: row;
        display: inherit;
      }

      html[dir="rtl"] & {
        text-align: right;

        &:nth-of-type(2) {
          text-align: left;
        }
      }

      @media ${M.bpDown("m")} {
        justify-content: center;
      }
    `,
    itemPrev: css`
      @media ${M.bpDown("m")} {
        display: none;
      }
    `,
    description: css`
      margin: 0 ${spacing.base8(2)};
      color: ${palette.grayscale[7]};
    `,
    title: css`
      ${typography.preset.fontHeading1};
      text-transform: capitalize;
      color: ${palette.grayscale[8]};
    `,
    separator: css`
      min-width: 1px;
      margin: 20px 0;
      background: ${palette.grayscale[4]};
      @media ${M.bpDown("m")} {
        display: none;
      }
    `,
  };
});

export const ArticleNavigation = ({
  aliasRoute,
}: {
  aliasRoute: RAliasArticle;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const [prevTheme, nextTheme] = useThemeNavigation(aliasRoute);

  if (!prevTheme || !nextTheme) return null;

  const prevThemeStyle = theme.palette.theme[prevTheme];
  const nextThemeStyle = theme.palette.theme[nextTheme];

  return (
    <div key="ArticleNavigation" css={styles.root}>
      <div css={styles.content}>
        <PageLink name={prevTheme} query={{}}>
          <a css={[styles.item, styles.itemPrev]}>
            <div css={styles.description}>
              <Trans>Previous theme</Trans>
              <div css={styles.title}>{getThemeLabel(prevTheme)}</div>
            </div>
            {prevThemeStyle.kind === "article" && (
              <img src={prevThemeStyle.illustration} alt="" />
            )}
          </a>
        </PageLink>

        <span css={styles.separator} role="presentation"></span>

        <PageLink name={nextTheme} query={{}}>
          <a css={styles.item}>
            <div css={styles.description}>
              <Trans>Next theme</Trans>
              <div css={styles.title}>{getThemeLabel(nextTheme)}</div>
            </div>
            {nextThemeStyle.kind === "article" && (
              <img src={nextThemeStyle.illustration} alt="" />
            )}
          </a>
        </PageLink>
      </div>
    </div>
  );
};
