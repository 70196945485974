import { I18n, MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { ChartGrid } from "../charts-motion";
import {
  useColorScaleVDP,
  VerticalDotPlot,
} from "../charts-motion/vertical-dot-plot";
import ColorLegend from "../charts/lib/ColorLegend";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_equity_fig_GPIBYWEALTH";
import {
  ChartRenderer,
  foldGeoEntity,
  getLevelACName,
  getRegionIndex,
  LevelAC,
  mkGemEntityDecoder,
  ordLevelAC,
  useNamedEntities,
  useRegionEntities,
} from "../domain";
import { useTheme } from "../hooks";
import { extentNumber, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, identity, io, Ord } from "../prelude";
export * from "../data/data_equity_fig_GPIBYWEALTH";

const yUnitLabel = t(
  "fig.equity.GPIBYWEALTH.legend"
)`Education levels, low to high`;

const wealthOrder = {
  POOREST: 0,
  AVERAGE: 1,
  RICHEST: 2,
};

export const Wealth = io.keyof(wealthOrder);
export type Wealth = io.TypeOf<typeof Wealth>;

export const getWealthIndex = (k: Wealth) => wealthOrder[k];

export const ordWealth = Ord.contramap((Wealth: Wealth) =>
  getWealthIndex(Wealth)
)(Ord.ordNumber);

const wealthLookup: Record<Wealth, MessageDescriptor> = {
  RICHEST: t("fig.equity.GPIBYWEALTH.RICHEST")`Richest`,
  AVERAGE: t("fig.equity.GPIBYWEALTH.AVERAGE")`Average`,
  POOREST: t("fig.equity.GPIBYWEALTH.POOREST")`Poorest`,
};
export const getWealthName = (i18n: I18n, wealth: Wealth) =>
  i18n._(wealthLookup[wealth]);

export const Data = mkGemEntityDecoder([], {
  value: io.number,
  variable: LevelAC,
  wealth: Wealth,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();

  const entityData_ = useNamedEntities(dataRaw);
  const entityData = React.useMemo(() => {
    const data = entityData_.map((x) => ({
      ...x,
      ind_name: getLevelACName(i18n, x.variable),
      wealth_name: getWealthName(i18n, x.wealth),
    }));
    return Ar.sortBy([
      // FIXME: It would be better to have an Ord instance that could sort geo entities and the like
      Ord.contramap((x: typeof data[number]) => {
        return foldGeoEntity({
          country: () => -Infinity,
          region: ({ id }) => getRegionIndex(env.regions, id),
        })(x);
      })(Ord.ordNumber),
      Ord.contramap((x: typeof data[number]) => x.variable)(ordLevelAC),
      Ord.contramap((x: typeof data[number]) => x.wealth)(ordWealth),
      Ord.contramap((x: typeof data[number]) => x.entity_name)(Ord.ordString),
    ])(data);
  }, [env.regions, entityData_, i18n]);
  const regionData = useRegionEntities(entityData);

  const chartData = React.useMemo(() => {
    return unsafeFromArray(
      regionData.filter(({ entity_type }) => entity_type === "geo")
    );
  }, [regionData]);
  type Datum = typeof chartData[number];

  const getColumn = React.useCallback((x: Datum) => x.entity_name, []);
  const getX = React.useCallback((x: Datum) => x.ind_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.wealth_name, []);
  const getColorPalette = React.useCallback(
    (count) => M.fromCount(M.colorRanges.diverging, count),
    []
  );
  const formatValue = useFormat(".2f");
  const formatAxisValue = useFormat(".1f");

  const extentAll = React.useMemo(() => {
    return extentNumber(unsafeFromArray(chartData), getValue) as [
      number,
      number
    ];
  }, [chartData, getValue]);

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <>
      <ChartGrid
        data={chartData}
        getCell={getColumn}
        columnCount={3}
        minWidth={80}
      >
        {({ data, width, firstInRow }) => (
          <VerticalDotPlot
            width={width}
            height={
              client.screenSDown
                ? M.chartHeight.xs
                : client.screenMDown
                ? M.chartHeight.s
                : M.chartHeight.m
            }
            data={data}
            getX={getX}
            getValue={getValue}
            getColor={getColor}
            formatX={identity}
            formatValue={formatValue}
            formatAxisValue={formatAxisValue}
            domainY={extentAll}
            colorScale={colorScale}
            colorLegendValues={colorLegendValues}
            showTooltip
            labelStyleX="none"
            marginLeft={20}
            hideYAxis={!firstInRow}
            yUnitLabel={firstInRow ? i18n._(yUnitLabel) : null}
            yAnnotations={[
              {
                type: "band",
                label: i18n._(t`Parity`),
                range: [0.95, 1.05],
                dy: client.screenMDown ? "-0.7em" : undefined,
              },
              {
                type: "line",
                value: 1,
              },
            ]}
            dotStyle={client.screenSDown ? "medium" : "default"}
          />
        )}
      </ChartGrid>
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_equity_fig_GPIBYWEALTH.json"),
  csv: require("../data/data_equity_fig_GPIBYWEALTH.zip"),
  xlsx: require("../data/data_equity_fig_GPIBYWEALTH.xlsx"),
  metadata,
  Data,
  Chart,
  size: "wide",
});
