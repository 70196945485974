import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "HERO_QUALITY",
  title: /*i18n*/ { id: "fig.quality.HERO.title", defaults: `Quality` },
  caption: /*i18n*/ {
    id: "fig.quality.HERO.caption",
    defaults: `Globally, most teachers are trained but in sub-Saharan Africa, the percentage of trained teachers fell gradually from 84% in 2000 to 61% in 2016.`,
  },
  source: /*i18n*/ { id: "fig.quality.HERO.source", defaults: `UIS database` },
};
