import { t } from "@lingui/macro";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_access_fig_PREPRIM";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  foldGeoEntity,
  getCountryName,
  mkGemEntityDecoder,
  useCheckboxState,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_access_fig_PREPRIM";

const DEFAULT_COUNTRIES = ["GTM", "NER"];

export const Data = mkGemEntityDecoder(["ind_id", "year"], {
  gender: io.string,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();

  const [, actions] = useFigureState();
  const [selectedCountries] = useMultiSelectState("countries");
  const [showComparison] = useCheckboxState("toggleComparison");

  const entityData = useNamedEntities(data);
  const countryData = useCountryEntities(entityData);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        selected: O.some(DEFAULT_COUNTRIES),
      },
      countryControlItems
    );

    actions.setToggleControl("toggleComparison", {
      type: "Checkbox",
      label: i18n._(t`Show income groups`),
      selected: false,
    });
  }, [i18n, actions, env.countries, countryControlItems]);

  const chartData = React.useMemo(() => {
    const hasCountry = (country: CountryId) =>
      pipe(
        selectedCountries,
        O.fold(
          () => false,
          (xs) => xs.includes(countryIdIso.unwrap(country))
        )
      );

    return entityData.filter(
      foldGeoEntity({
        country: ({ id }) => hasCountry(id),
        region: ({ entity_type }) =>
          showComparison ? true : entity_type === "world",
      })
    );
  }, [entityData, selectedCountries, showComparison]);

  const selectedCountryLabels = React.useMemo(() => {
    return pipe(
      selectedCountries,
      O.map((xs) =>
        xs.map((x) => getCountryName(env.countries, countryIdIso.wrap(x)))
      )
    );
  }, [env.countries, selectedCountries]);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    selectedCountryLabels
  );

  return (
    <>
      <LineChart
        tLabel={(s) => s}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        x="year"
        timeParse="%Y"
        timeFormat="%Y"
        numberFormat=".0%"
        yTicks={[0, 0.2, 0.4, 0.6, 0.8, 1]}
        category="datum.entity_name"
        colorLegend={false}
        colorScale={colorScale}
        values={chartData}
        markStyle={(x) =>
          x.entity_type === "world"
            ? "reference"
            : x.entity_type === "country"
            ? "normal"
            : "muted"
        }
        connectLines
        showTooltip
      />
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_access_fig_PREPRIM.json"),
  csv: require("../data/data_access_fig_PREPRIM.zip"),
  xlsx: require("../data/data_access_fig_PREPRIM.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
