
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "home";
export const title = "教育进展";
export const lead = "教育照亮通向更美好生活旅程的每一个阶段。所有儿童都需要获得高质量教育的机会，以挖掘他们的学习潜力并使教育产生更广泛的惠益。要特别努力确保所有儿童和青年都能平等受益于教育的变革性力量。这需要有充足的资源，特别是要惠及那些需要更多支持的人。";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IndexHero = makeShortcode("IndexHero");
const HighlightByTheme = makeShortcode("HighlightByTheme");
const LandingText = makeShortcode("LandingText");
const PageLink = makeShortcode("PageLink");
const ThemeLinks = makeShortcode("ThemeLinks");
const LandingButton = makeShortcode("LandingButton");
const RelatedContent = makeShortcode("RelatedContent");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <IndexHero mdxType="IndexHero">
      <h1 {...{
        "id": "scope"
      }}>{`SCOPE`}</h1>
      <p>{`教育照亮通向更美好生活旅程的每一个阶段。所有儿童都需要`}<HighlightByTheme theme="access" mdxType="HighlightByTheme">{`获得`}</HighlightByTheme>{`高`}<HighlightByTheme theme="quality" mdxType="HighlightByTheme">{`质量`}</HighlightByTheme>{`教育的机会，以挖掘他们的`}<HighlightByTheme theme="learning" mdxType="HighlightByTheme">{`学习`}</HighlightByTheme>{`潜力并使教育产生更广泛的惠益。要特别努力确保所有儿童和青年都能`}<HighlightByTheme theme="equity" mdxType="HighlightByTheme">{`平等`}</HighlightByTheme>{`受益于教育的变革性力量。这需要有充足的`}<HighlightByTheme theme="finance" mdxType="HighlightByTheme">{`资源`}</HighlightByTheme>{`，特别是要惠及那些需要更多支持的人。`}</p>
    </IndexHero>
    <LandingText mdxType="LandingText">
      <p>{`2015 年，国际社会将关于教育的`}<a parentName="p" {...{
          "href": "https://sustainabledevelopment.un.org/sdg4"
        }}>{`可持续发展目标 4`}</a>{`列入到 2030 年要实现的 17 项可持续发展目标中。自那时起，已经制订了一个`}<PageLink name="indicators" mdxType="PageLink">{`详细的框架`}</PageLink>{`，用以监测实现该目标各项具体目标的进展情况。本网站通过五个主题总结了全世界教育领域的关键事实与主要趋势：`}</p>
    </LandingText>
    <ThemeLinks mdxType="ThemeLinks" />
    <LandingText mdxType="LandingText">
      <p>{`网站是对《`}<a parentName="p" {...{
          "href": "https://zh.unesco.org/gem-report/allreports"
        }}>{`全球教育监测报告`}</a>{`》印刷版所提供信息的补充，该报告是国际社会跟踪和审查实现我们的教育目标（可持续发展目标 4）进展情况的工具。`}</p>
    </LandingText>
    <LandingButton mdxType="LandingButton">开始阅读</LandingButton>
    <RelatedContent mdxType="RelatedContent" />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;