import { DecoderError, InvariantViolation, mkDecoderError } from ".";
import { E, io, pipe, Te } from "../prelude";
// -----------------------------------------------------------------------------
// FileReader

export class FileReaderAbort {
  public readonly type: "FileReaderAbort" = "FileReaderAbort";
  constructor(readonly filename: string) {}
}

export class FileReaderError {
  public readonly type: "FileReaderError" = "FileReaderError";
  constructor(readonly filename: string) {}
}

export class FileReaderReject {
  public readonly type: "FileReaderReject" = "FileReaderReject";
  constructor(readonly rejectedFiles: Array<File>) {}
}

export type ReadFileException =
  | FileReaderAbort
  | FileReaderError
  | DecoderError
  | InvariantViolation;

export function readFile<I, O>(decoder: io.Decoder<I, O>) {
  return (file: File): Te.TaskEither<ReadFileException, O> => {
    return Te.tryCatch<ReadFileException, O>(
      () =>
        new Promise<O>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onabort = () => reject(new FileReaderAbort(file.name));
          fileReader.onerror = () => reject(new FileReaderError(file.name));
          fileReader.onload = () => {
            const data = JSON.parse(fileReader.result as $Unexpressable);
            const validation = decoder.decode(data);
            pipe(
              validation,
              E.mapLeft(
                (errors) =>
                  mkDecoderError(validation)(errors.slice(0, 5)).errorMessages
              ),
              E.fold(reject, resolve)
            );
          };
          fileReader.readAsBinaryString(file);
        }),
      (reason) => new InvariantViolation(String(reason))
    );
  };
}
