import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import React from "react";
import * as M from "../materials";
import { FigureHeight } from "./figure";
import LoadingIcon from "./logo/ic_brandmark_40dp.svg?raw";

const styles = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: ${M.lightText};
  `,
  global: css`
    @keyframes gem-spinner-container {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    position: absolute;
    opacity: 0;
    animation: gem-spinner-container 1s cubic-bezier(1, 0, 1, 0) 2s forwards;

    body[data-ready] & {
      display: none;
    }
  `,
  spinner: css`
    @keyframes gem-spinner {
      to {
        transform: rotate(720deg);
      }
    }
    animation: gem-spinner 4s cubic-bezier(0.19, 0.26, 0.14, 0.94) infinite;

    width: ${M.spacing.base8(5)};
    height: ${M.spacing.base8(5)};
    background-image: ${`url(${LoadingIcon})`};
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: ${M.spacing.base8(1)};
  `,
};

export const Spinner = ({
  global,
  minHeight,
}: {
  global?: boolean;
  minHeight?: FigureHeight;
}) => {
  return (
    <div
      css={[styles.root, global && styles.global]}
      style={{
        minHeight: minHeight?.wide /** @FIXME handle mobile sizes if needed  */,
      }}
    >
      <div css={[styles.spinner]} />
      <Trans>Education Progress</Trans>
    </div>
  );
};

/**
 * The GlobalSpinner is used together with the HideGlobalSpinner component.
 * The intention is to render the GlobalSpinner in the server-side rendered
 * HTML (i.e. in _document.tsx) and to hide it as soon as the application
 * is through with loading the config data that it needs.
 *
 * The "signal" on when loading is done happens in _app.tsx where the hiding
 * component is rendered as soon as all the configs have been loaded.
 */

export const GlobalSpinner = () => {
  return <Spinner global />;
};

export const HideGlobalSpinner = () => {
  React.useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.setAttribute("data-ready", "");
  });
  return null;
};
