import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "GOVVGDP",
  title: /*i18n*/ {
    id: "fig.finance.GOVVGDP.title",
    defaults: `Total public expenditure on education as a percentage of GDP and of total government expenditure`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.GOVVGDP.caption",
    defaults: `Some countries have increasingly prioritised investment in education.`,
  },
  source: /*i18n*/ {
    id: "fig.finance.GOVVGDP.source",
    defaults: `UIS database`,
  },
};
