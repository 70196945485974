
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "入学";
export const lead = "在全球范围内，87%的儿童完成小学教育，77%的青少年完成初中教育，58%的青年完成高中教育。";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const PageLink = makeShortcode("PageLink");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`世界未能兑现到2015年让所有儿童都能上小学的承诺。新的教育目标——可持续发展目标4更为宏大，呼吁到2030年这一最后期限前让所有年轻人完成中学教育。为了实现这一目标，所有达到入学年龄的儿童在2018年都应该已经开始上学，但实际上，在低收入国家，只有70%的儿童做到了这一点。`}</p>
    </blockquote>
    <p>{`大约每六十名儿童中就有一名`}<strong parentName="p">{`永远上不了学`}</strong>{`，其中大多数是低收入国家的儿童。女童仍然比男童更有可能永远丧失入学机会，因为对她们的歧视意味着人们预期她们要承担育儿和其他家庭责任。`}</p>
    <h2 {...{
      "id": "接受学前教育"
    }}>{`接受学前教育`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`接受学前教育`}</strong>{`是儿童教育之旅的重要起点。然而，在低收入国家，只有43%的儿童在小学前一年接受学前教育。`}</p>
    </IndicatorReference>
    <p>{`自2000年以来，包括许多最贫困国家在内，大多数国家接受学前教育的情况都取得了缓慢但稳定的进展，但是仍然有许多国家掉队。在全球范围内，目前接受学前教育的儿童占75%，而2002年这一比例为65%。`}</p>
    <p>{`一些国家的增幅巨大。例如，在吉尔吉斯斯坦，参与率从2000年的42%上升到了2021年的84%。在老挝，同期比例从10%上升到71%。`}</p>
    <p>{`下文突出显示的`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`危地马拉`}</HighlightByIndex>{`和`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`尼日尔`}</HighlightByIndex>{`两国是学前教育就读率稳步上升的例子，尽管今天这两个国家之间存在着巨大的差距。选择国家以查看其进展情况。`}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "超龄参与"
    }}>{`超龄参与`}</h2>
    <IndicatorReference id="4.1.5" mdxType="IndicatorReference">
      <p>{`在一些国家，超过四分之一的小学生在入学时已经`}<strong parentName="p">{`超龄`}</strong>{`，这意味着他们的年龄远远大于所在年级学生应有的年龄，关于这一问题的讨论还不够。这种情况增加了进一步留级、考试不及格和最终辍学的可能性，对这些学生的未来影响巨大。`}</p>
    </IndicatorReference>
    <p>{`本图关注的是`}<strong parentName="p">{`教育参与`}</strong>{`情况，显示了儿童、青少年和青年在接受学前教育、小学教育、初中和高中教育以及高等教育时的年龄。颜色的范围表明年龄超过所处教育层次的儿童的数量。灰色阴影区域显示了按年龄划分的失学青少年和儿童的百分比。`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="OVERAGE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="PAK" mdxType="ScrollySection">
在<Highlight mdxType="Highlight">巴基斯坦</Highlight>，大多数儿童10岁时都应该在上学，但21%的儿童在这一年龄仍未入学。很多儿童的年龄偏大，不适于所在年级。例如，存在着14岁孩子还在上小学的情况。
      </ScrollySection>
      <ScrollySection id="LBR" mdxType="ScrollySection">
在<Highlight mdxType="Highlight">利比里亚</Highlight>等国家，超龄率更高，10岁儿童中有28%在接受学前教育，18岁的人中有20%仍在上小学。
      </ScrollySection>
      <ScrollySection id="HTI" mdxType="ScrollySection">
        <Highlight mdxType="Highlight">海地</Highlight>面临着同样的挑战，
      </ScrollySection>
      <ScrollySection id="MWI" mdxType="ScrollySection">
        <Highlight mdxType="Highlight">马拉维</Highlight>也一样。.
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "失学儿童"
    }}>{`失学儿童`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`全球有2.44亿`}<strong parentName="p">{`失学`}</strong>{`儿童和青年。联合国教科文组织统计研究所和《全球教育监测报告》开发的一个新模型表明，小学失学率的下降速度比以前想象的要快，尽管近年来下降速度有所放缓。自2014年以来，初中失学儿童人数保持相对稳定。`}</p>
      <p>{`冲突是造成这种停滞的关键原因。遗憾的是，冲突还意味着数据获取更加困难以及数据质量下降，尽管一些国家继续报告估计数据。要正确评估新冠肺炎疫情对入学率的近期影响，还需要掌握较长一段时间内的更多信息。`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="OOSRATE" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="NIC,PAN" mdxType="ScrollySection">
          <Highlight mdxType="Highlight">尼加拉瓜</Highlight>和<Highlight mdxType="Highlight">巴拿马</Highlight>的进展方向截然不同。在小学和中学教育阶段，尼加拉瓜的失学比率直线下降，而巴拿马的失学比率则有所攀升。
        </ScrollySection>
        <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
          <Highlight mdxType="Highlight">厄立特里亚</Highlight>和<Highlight mdxType="Highlight">埃塞俄比亚</Highlight>的进展速度也差异显著。在厄立特里亚，冲突的后续影响明显波及了受教育机会，而埃塞俄比亚则在预算中将教育列为优先事项，使得失学儿童数量在短时间内迅速减少。
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`在减少失学人数方面几无进展的另一个原因是人口增长。`}</p>
    <p>{`在撒哈拉以南非洲，2000—2022年，`}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`小学适龄人口`}</HighlightByIndex>{`几乎翻了一番。因此，尽管在此期间`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`儿童失学率`}</HighlightByIndex>{`降低了近一半，`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`失学儿童`}</HighlightByIndex>{`的数量却几乎没有变化。`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "完成"
    }}>{`完成`}</h2>
    <IndicatorReference id="4.1.2" mdxType="IndicatorReference">
      <p>{`可持续发展目标4的最终目标是普遍`}<strong parentName="p">{`完成`}</strong>{`高中教育，而不仅仅是实现入学。尽管事实上在许多国家，免费义务教育至多持续到初中教育。`}</p>
      <p>{`世界甚至无法如期实现普遍完成小学教育这一目标，更遑论完成高中教育的目标。`}</p>
      <p>{`该项指标指的是儿童比正式毕业年龄晚最多3年或5年才完成相应教育层次，这一情况可定义为 `}<Tooltip title="及时完成" content="Children who complete education levels up to 3 or 5 years beyond the final grad e" mdxType="Tooltip">{`及时完成`}</Tooltip>{`。但是，许多儿童完成时间更晚，这一情况则可定义为`}<Tooltip title="最终完成" content="Children who complete education levels up to 8 years beyond the final grade " mdxType="Tooltip">{`最终完成`}</Tooltip>{`。`}</p>
      <p>{`以下图中的肯尼亚为例：在小学教育阶段，`}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`及时`}</HighlightByIndex>{`完成（74%）和`}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`最终`}</HighlightByIndex>{`完成（93%）之间相差20个百分点。莫桑比克也显示出现 类似的差距。`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "结论"
    }}>{`结论`}</h2>
    <p>{`儿童入学时间、毕业年龄以及毕业与否都是重大的政策性问题。`}</p>
    <p>{`本网站接下来的主题探讨的是我们与实现教育`}<PageLink name="equity" mdxType="PageLink">{`公平`}</PageLink>{`的距离、学生`}<PageLink name="learning" mdxType="PageLink">{`学习`}</PageLink>{`的内容、教育`}<PageLink name="quality" mdxType="PageLink">{`质量`}</PageLink>{`高低以及教育`}<PageLink name="finance" mdxType="PageLink">{`资助`}</PageLink>{`者。`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
            }}>{`Education above the odds – Save the Children`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.unicef.org/reports/a-world-ready-to-learn-2019"
            }}>{`A world ready to learn: Prioritizing quality early childhood education – UNICEF`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
            }}>{`Combining data on out-of-school children, Completion and learning to offer a more comprehensive view on SDG 4`}</a></p>
        </li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;