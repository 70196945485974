import React from "react";
import { useContentRect } from "../hooks";
import { Line as FixedWidthLineChart, LineChartProps } from "./lib/Lines";

export function LineChart<A>(props: Omit<LineChartProps<A>, "width">) {
  const [ref, contentRect] = useContentRect();

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {contentRect.width > 0 && (
        <FixedWidthLineChart {...props} width={contentRect.width} />
      )}
    </div>
  );
}
