import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "LEARN_REACHFINAL",
  title: /*i18n*/ {
    id: "fig.learning.LEARN_REACHFINAL.title",
    defaults: `Learning by expected school completion`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.LEARN_REACHFINAL.caption",
    defaults: `Most children who do not learn do so despite completing school.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.LEARN_REACHFINAL.source",
    defaults: `UIS database`,
  },
};
