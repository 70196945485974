import { css } from "@emotion/core";
import { springConfigs } from "charts/lib/motion";
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import * as M from "../../materials";
import React, { useContext } from "react";
import { useTheme } from "hooks";

export const TOCContext = React.createContext<{
  ids: string[];
  addId: (x: string) => void;
  removeId: (x: string) => void;
}>({
  ids: [],
  addId: () => {},
  removeId: () => {},
});

export const TOC = () => {
  const [headings, setHeadings] =
    React.useState<HTMLCollectionOf<HTMLHeadingElement> | null>(null);
  const [visible, setVisible] = React.useState(false);

  const { ids } = useContext(TOCContext);

  React.useEffect(() => {
    const $headings = document.getElementsByTagName("h2");
    setHeadings($headings);
  }, []);

  const goTop = React.useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const { client, activeTheme } = useTheme();

  const { scrollYProgress, scrollY } = useViewportScroll();
  const scaleX = useTransform(scrollYProgress, [0, 0.95], [0, 1]);
  const opacity = useTransform(scrollY, [200, 400], [0, 1]);
  const posY = useTransform(scrollY, [200, 400], [100, 0]);

  if (client.screenMDown) {
    return null;
  }

  return (
    <>
      <motion.div
        initial={false}
        style={{ opacity, translateY: posY }}
        css={css`
          padding: 16px;
          background: white;
          box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.28); /* FIXME: define global shadow styles */
        `}
        onMouseEnter={() => {
          setVisible(true);
        }}
        onMouseLeave={() => {
          setVisible(false);
        }}
      >
        <AnimatePresence>
          <motion.div
            animate={{
              height: visible ? "auto" : "0px",
              opacity: visible ? 1 : 0,
            }}
            transition={springConfigs.gentle}
            style={{
              overflow: "hidden",
              width: "100%",
              background: "white",
              boxSizing: "border-box",
              position: "absolute",
              bottom: "3em",
              left: 0,
              zIndex: 2,
            }}
          >
            {Items(headings, ids, "sidebar")}
          </motion.div>
        </AnimatePresence>

        <a
          href="#"
          onClick={() => {
            goTop();
          }}
          css={css`
            display: block;
            color: black;
            &:hover {
              opacity: 0.5;
            }
          `}
        >
          Top
        </a>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 4,
            background: activeTheme.background.light,
          }}
        />
        <motion.div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 4,
            transformOrigin: "left",
            scaleX,
            background: activeTheme.background.main,
          }}
        />
      </motion.div>
    </>
  );
};

export const TOCStatic = () => {
  const [headings, setHeadings] =
    React.useState<HTMLCollectionOf<HTMLHeadingElement> | null>(null);
  React.useEffect(() => {
    const $headings = document.getElementsByTagName("h2");
    setHeadings($headings);
  }, []);
  return <div>{Items(headings, [], "top")}</div>;
};

function Items(
  headings: HTMLCollectionOf<HTMLHeadingElement> | null,
  ids: string[],
  variant: "sidebar" | "top"
): React.ReactNode {
  const {
    activeTheme,
    client: { isRTL },
  } = useTheme();

  return [...Object.values(headings || {})]
    .filter((d) => !!d.id)
    .map((d, i) => {
      const active = ids.includes(d.id);
      const sidebar = variant === "sidebar";
      return (
        <div key={i}>
          <a
            href={`#${d.id}`}
            css={css`
              ${sidebar ? M.fontBody2Alt : M.fontLead1};
              background: ${sidebar ? activeTheme.background.light : "none"};
              display: block;
              padding: ${sidebar ? "8px 16px" : "0px"};
              margin-${isRTL ? "right" : "left"}: ${sidebar ? 0 : 1}em;
              color: ${activeTheme.textColor};
              font-weight: ${active ? "bold" : "normal"};
              position: relative;
              &:hover {
                opacity: 0.5;
              }
              &:before {
                content: "–";
                display: block;
                position: absolute;
                opacity: 0.8;
                ${isRTL ? "right" : "left"}: -1em;
              }
            `}
          >
            {d.textContent}
          </a>
        </div>
      );
    });
}
