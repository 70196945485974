import css from "@emotion/css";
import { t, Trans } from "@lingui/macro";
import { springConfigs } from "charts/lib/motion";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import React, { forwardRef, useState } from "react";
import { mkStyles, useQueryParamsWithDefault, useTheme } from "../hooks";
import { useAppReducer } from "../hooks/useAppState";
import * as M from "../materials";
import {
  articleAliases,
  foldRAliasArticle_,
  foldRAliasTheme_,
  getFocusMetadata,
  RAlias,
  RAliasArticle,
  RAliasTheme,
  REnv,
  subthemesIndex,
  themeAliases,
} from "../routes";
import { gridCenteredMaxWidth } from "./app-layout";
import { ReactComponent as BookIcon } from "./article-switcher/ic_book_32dp.svg";
import { ReactComponent as FocusIcon } from "../icons/ic_article_16dp.svg";
import { PageLink } from "./links";
import { focusArticles } from "focus";
import { useI18n } from "locales";

const useStyles = mkStyles(
  ({ typography, spacing: { base8 }, palette, utils, zIndex }) => {
    const { grid, areas } = gridCenteredMaxWidth();
    return {
      root: css`
        ${grid};
        background: ${palette.brand.blue.main};
        position: absolute;
        width: 100%;
        // 120px - approx. header height
        max-height: min(calc(100vh - 120px), ${M.spacing.base8(85)});
        z-index: ${zIndex.overlapContext};
        box-shadow: 0 ${base8(5)} ${base8(10)} ${base8(-3)}
          ${utils.fade(palette.brand.blue.dark, 0.75)};
      `,
      main: css`
        grid-area: ${areas.main};
        display: grid;
        grid-template-columns: minmax(auto, ${M.spacing.base8(60)}) 1px 1fr;
        gap: ${M.spacing.base8(12)};
        padding-top: ${M.spacing.base8(7)};
        padding-bottom: ${M.spacing.base8(5)};
        overflow: hidden;
      `,
      section: css`
        display: flex;
        flex-direction: column;
        gap: ${M.spacing.base8(2)};
        padding: ${M.spacing.base8(1)};
        overflow-y: auto;
      `,
      listItem: css`
        cursor: pointer;
        border-radius: ${base8(0.5)};
        margin: 0 ${base8(1)};

        img {
          height: ${base8(8)};
        }

        a {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          padding: ${M.spacing.base8(2)} 1em;
          color: inherit;
        }
      `,
      themeListItem: css`
        a {
          gap: ${base8(3)};
        }
      `,
      contentListItem: css`
        a {
          gap: ${base8(2)};
        }
      `,
      listItemTexts: css`
        display: flex;
        flex-direction: column;
      `,
      listItemThemeTexts: css`
        gap: ${M.spacing.base8(0.5)};
      `,
      listItemThemeLabel: css`
        ${typography.preset.fontHeading3};
      `,
      listItemContentLabel: css`
        ${typography.preset.fontTable};
      `,
      listItemContentDescription: css`
        ${typography.preset.fontHeading3};
      `,
      listItemFallback: M.onlyIE(css`
        height: auto;
        a {
          display: block;
        }
      `),
      divider: css`
        margin: 1em 0;
        border-left: 1px solid ${M.divider};
        opacity: 0.35;
        transition: opacity 0.2s ease-in-out;
      `,
    };
  }
);

type ContentItem = {
  alias: RAlias;
  colors: M.DocumentArticle | M.DocumentDefault;
  article?: RAliasArticle;
  title: string | React.ReactNode;
  icon: typeof BookIcon | typeof FocusIcon;
  type: "subtheme" | "focus";
};

export const expandHeightMotionProps: MotionProps = {
  variants: {
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        staggerChildren: 0.06,
      },
    },
    hidden: {
      opacity: 1,
      height: 0,
      transition: {
        delay: 0.2,
        staggerChildren: 0.03,
        staggerDirection: -1,
      },
    },
  },
  initial: "hidden",
  animate: "show",
  exit: "hidden",
};

export const getThemeLabel = foldRAliasTheme_({
  access: () => <Trans>Access</Trans>,
  equity: () => <Trans>Equity</Trans>,
  learning: () => <Trans>Learning</Trans>,
  quality: () => <Trans>Quality</Trans>,
  finance: () => <Trans>Finance</Trans>,
});

export const getLabel = foldRAliasArticle_({
  access: () => <Trans>Access</Trans>,
  equity: () => <Trans>Equity</Trans>,
  learning: () => <Trans>Learning outcomes</Trans>,
  trajectories: () => <Trans>Learning trajectories</Trans>,
  quality: () => <Trans>Quality</Trans>,
  finance: () => <Trans>Finance – Governments and households</Trans>,
  financeAid: () => <Trans>Finance – Aid</Trans>,
});

const slideMotionProps = {
  variants: {
    show: { opacity: 1, y: "0%" },
    hidden: { opacity: 0, y: "-55%" },
  },
  transition: springConfigs.gentle,
};

export const ArticleSwitcher = () => {
  const { locale } = useQueryParamsWithDefault(REnv);
  const styles = useStyles();
  const [state, dispatch] = useAppReducer();
  const [hoveredTheme, setHoveredTheme] = useState<RAliasTheme | null>(null);
  const [hoveredContent, setHoveredContent] = useState<RAlias | null>(null);
  const { palette } = useTheme();

  const listRef = React.useRef<HTMLAnchorElement>(null);
  const lastIndex = articleAliases.length - 1;

  React.useEffect(() => {
    setHoveredTheme(null);
    setHoveredContent(null);

    if (typeof document !== "undefined") {
      const el = listRef.current;
      const origin = document ? document.activeElement : null;

      if (state.themeDrawer.isOpen && el) {
        el.focus();
      }
      return () => {
        /** return focus to origin */
        origin &&
          origin.id === "article-switcher-toggle" &&
          (origin as HTMLButtonElement).focus();
      };
    } else return;
  }, [state.themeDrawer.isOpen]);

  const subthemes = hoveredTheme ? subthemesIndex[hoveredTheme] : undefined;
  const hasSubthemes = subthemes && subthemes.length > 1;

  const focuses = React.useMemo(
    () =>
      focusArticles.published
        .map((a) => getFocusMetadata(a as RAlias, locale))
        .filter((a) => subthemes?.includes(a.article)),
    [locale, subthemes]
  );
  const content: ContentItem[] = [
    hasSubthemes
      ? [
          ...subthemes?.map((a) => ({
            alias: a,
            colors: palette.theme[a],
            title: getLabel(a),
            icon: BookIcon,
            type: "subtheme" as const,
          })),
        ]
      : [],
    ...focuses.map((f) => ({
      alias: f.alias,
      colors: palette.theme[f.article as RAliasArticle],
      title: f.title,
      icon: FocusIcon,
      type: "focus" as const,
    })),
  ].flat();

  return (
    <AnimatePresence initial={false} key="themeDrawer">
      {state.themeDrawer.isOpen && (
        <motion.div css={styles.root} {...expandHeightMotionProps}>
          <div css={styles.main}>
            <ul css={styles.section} role="menu" id="article-switcher">
              {themeAliases.map((alias, i) => {
                return (
                  <motion.li
                    key={alias}
                    onMouseEnter={() => setHoveredTheme(alias)}
                    {...slideMotionProps}
                  >
                    <ThemeCard
                      key={alias}
                      ref={i === 0 ? listRef : null}
                      alias={alias}
                      active={hoveredTheme === alias}
                      dimmed={hoveredTheme !== null && hoveredTheme !== alias}
                      onClick={() => dispatch({ type: "themeDrawer.close" })}
                      onKeyUp={() => {
                        lastIndex === i &&
                          dispatch({ type: "themeDrawer.close" });
                      }}
                    />
                  </motion.li>
                );
              })}
            </ul>

            <div
              css={[
                styles.divider,
                !(hoveredTheme && content.length > 0) && {
                  opacity: 0,
                },
              ]}
            />

            <ul css={styles.section} role="menu" id="subarticle-switcher">
              {content.length > 0 &&
                content.map((c, i) => {
                  return (
                    <motion.li
                      key={c.alias}
                      onMouseEnter={() => setHoveredContent(c.alias)}
                      onMouseLeave={() => setHoveredContent(null)}
                      {...slideMotionProps}
                    >
                      <ContentCard
                        type={c.type}
                        alias={c.alias}
                        colors={c.colors}
                        title={c.title}
                        icon={c.icon}
                        active={hoveredContent === c.alias}
                        dimmed={
                          hoveredContent !== null && hoveredContent !== c.alias
                        }
                        onClick={() => dispatch({ type: "themeDrawer.close" })}
                        onKeyUp={() => {
                          lastIndex === i &&
                            dispatch({ type: "themeDrawer.close" });
                        }}
                        variants={{
                          show: { y: 0 },
                          hidden: i > 0 ? { y: 10 } : { y: -10 },
                        }}
                      />
                    </motion.li>
                  );
                })}
            </ul>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const ThemeCard = forwardRef(
  (
    {
      alias,
      active,
      dimmed,
      onClick,
      onKeyUp,
      ...props
    }: {
      alias: RAliasTheme;
      active: boolean;
      dimmed: boolean;
      onClick: () => void;
      onKeyUp: () => void;
    } & MotionProps,
    ref: React.ForwardedRef<HTMLAnchorElement>
  ) => {
    const { palette } = useTheme();
    const styles = useStyles();
    const theme = palette.theme[alias];
    const imageUrl = theme.kind === "article" ? theme.illustration : null;

    return (
      <motion.div
        css={[styles.listItem, styles.themeListItem, styles.listItemFallback]}
        animate={{
          opacity: dimmed ? 0.8 : 1,
          backgroundColor: active
            ? theme.background.light
            : palette.brand.blue.main,
          color: active ? theme.textColor : M.whiteText,
          zIndex: active ? 1 : 0,
        }}
        transition={{
          duration: 0.25,
          ease: "easeInOut",
        }}
        {...props}
      >
        <PageLink name={alias} query={{}}>
          <motion.a
            ref={ref}
            id={`themedrawer-${alias}`}
            role="menuitem"
            tabIndex={0}
            onClick={onClick}
            onKeyUp={onKeyUp}
            animate={{}}
          >
            {imageUrl && <img src={imageUrl} alt="" />}
            <div css={[styles.listItemTexts, styles.listItemThemeTexts]}>
              <span css={styles.listItemThemeLabel}>
                {getThemeLabel(alias)}
              </span>
            </div>
          </motion.a>
        </PageLink>
      </motion.div>
    );
  }
);

const ContentCard = forwardRef(
  (
    {
      alias,
      colors,
      title,
      icon,
      type,
      active,
      dimmed,
      onClick,
      onKeyUp,
      ...props
    }: {
      active: boolean;
      dimmed: boolean;
      onClick: () => void;
      onKeyUp: () => void;
    } & MotionProps &
      ContentItem,
    ref: React.ForwardedRef<HTMLAnchorElement>
  ) => {
    const { palette } = useTheme();
    const styles = useStyles();
    //const themeColors = palette.theme[alias as RAliasArticle];
    const i18n = useI18n();

    const Icon = icon;
    const label = type === "subtheme" ? t`Sub-theme` : t`Focus`;

    return (
      <motion.div
        css={[styles.listItem, styles.contentListItem, styles.listItemFallback]}
        animate={{
          opacity: dimmed ? 0.8 : 1,
          backgroundColor: active
            ? colors.background.light
            : palette.brand.blue.main,
          color: active ? colors.textColor : M.whiteText,
          zIndex: active ? 1 : 0,
        }}
        transition={{
          duration: 0.25,
          ease: "easeInOut",
        }}
        {...props}
      >
        <PageLink name={alias} query={{}}>
          <a
            ref={ref}
            id={`themedrawer-content-${alias}`}
            role="menuitem"
            tabIndex={0}
            onClick={onClick}
            onKeyUp={onKeyUp}
          >
            <div
              css={css`
                width: ${M.spacing.base8(4)};
                height: ${M.spacing.base8(4)};
              `}
            >
              <Icon
                css={css`
                  width: ${M.spacing.base8(4)};
                  height: ${M.spacing.base8(4)};
                `}
              />
            </div>

            <div css={styles.listItemTexts}>
              <span
                css={[
                  styles.listItemContentLabel,
                  {
                    color: active ? colors.textColor : M.grayscalePalette[5],
                  },
                ]}
              >
                {i18n._(label)}
              </span>
              <span css={styles.listItemContentDescription}>{title}</span>
            </div>
          </a>
        </PageLink>
      </motion.div>
    );
  }
);
