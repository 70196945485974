import { Ar, Ord } from "../prelude";
const THRESHOLD = 2;

export function reduceVerticalOverlap<A, B>(
  unsortedItems: Array<A>,
  height: number,
  getId: (x: A) => B,
  getValue: (x: A) => number
) {
  const ord = Ord.contramap(getValue)(Ord.ordNumber);
  const mutableItems = Ar.sort(ord)(unsortedItems).map((x) => ({
    id: getId(x),
    top: getValue(x),
    bottom: getValue(x) + height,
  }));

  let iterations = 10;
  while (iterations--) {
    // Calculate overlap and correct position
    mutableItems.forEach(function (first, i) {
      mutableItems.slice(i + 1).forEach(function (second) {
        const overlap = first.bottom - second.top;
        if (overlap >= THRESHOLD) {
          const offset = overlap / 2;
          first.bottom -= offset;
          first.top -= offset;
          second.bottom += offset;
          second.top += offset;
        }
      });
    });
  }

  return mutableItems;
}
