import css from "@emotion/css";
import { t, Trans } from "@lingui/macro";
import React from "react";
import { mkStyles } from "../hooks";
import { useI18n } from "../locales";
import { gridCenteredMaxWidth } from "./app-layout";
import { ReactComponent as Facebook } from "./footer/ic_social-media-facebook_16dp.svg";
import { ReactComponent as Instagram } from "./footer/ic_social-media-instagram_16dp.svg";
import { ReactComponent as Linkedin } from "./footer/ic_social-media-linkedin_16dp.svg";
import { ReactComponent as Twitter } from "./footer/ic_social-media-twitter_16dp.svg";
import { ReactComponent as Wordpress } from "./footer/ic_social-media-wordpress_16dp.svg";
import { ReactComponent as Youtube } from "./footer/ic_social-media-youtube_16dp.svg";
import { PageLink } from "./links";
import combinedUnescoGemLogo from "../materials/assets/combined_unesco_gem_white_eng.svg";

const footerAreas = {
  socialMedia: "social-media",
  footerLinks: "footer-links",
  logo: "logo",
};

const useStyles = mkStyles(
  ({
    client: { isRTL },
    spacing: { base8 },
    palette,
    breakpoints,
    typography,
  }) => {
    const { grid, areas } = gridCenteredMaxWidth();
    return {
      root: css`
        ${grid};
        background: ${palette.brand.blue.main};
        color: white;
        min-height: 233px;

        /* This targets the iPad mini: Until exactly 1024 add padding. */
        @media screen and (max-width: ${breakpoints.values.l}px) {
          padding: ${base8(2)};
          padding-top: ${base8(3.75)};
          padding-bottom: ${base8(4)};
        }

        @media ${breakpoints.down("m")} {
          min-height: auto;
        }
      `,
      content: css`
        grid-area: ${areas.main};
        grid-template-areas:
          ". ."
          "${footerAreas.socialMedia} ${footerAreas.logo}"
          "${footerAreas.footerLinks} ${footerAreas.logo}"
          ". .";

        @media ${breakpoints.down("m")} {
          grid-gap: 1.75rem;
          grid-template-areas: "${footerAreas.logo}" "${footerAreas.footerLinks}" "${footerAreas.socialMedia}";
        }

        display: grid;
        width: 100%;
      `,

      footerLinks: css`
      grid-area: ${footerAreas.footerLinks};
      display: flex;
      ${typography.preset.fontFooter}
      padding: 0.5rem 0;
      align-items: center;

      a + a {
        margin-${isRTL ? "right" : "left"}: 1rem;
      }

      @media ${breakpoints.down("m")} {
        flex-direction: column;
        font-size: 0.85em;
        font-weight: bold;
        display: block;
        align-items: flex-start;

        a + a {
          margin-${isRTL ? "right" : "left"}: 0;
          margin-top: 0.5em;
          display: block;
          border-top: rgba(255, 255, 255, 0.25) 1px solid;
          padding-top: 1em;
        }
      }
    `,

      link: css`
        display: block;
        color: white;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      `,
      iconLink: css`
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        @media ${breakpoints.down("m")} {
          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        & + a {
          padding-left: 2rem;

          html[dir="rtl"] & {
            padding-left: 0;
            padding-right: 16px;
          }
        }
      `,
      UNLogo: css`
        grid-area: ${footerAreas.logo};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        line-height: 1;

        @media ${breakpoints.down("m")} {
          justify-content: start;
        }
        svg {
          color: unset;
        }
      `,
      socialMedia: css`
        grid-area: ${footerAreas.socialMedia};
        display: flex;
        align-items: center;

        @media ${breakpoints.down("m")} {
          margin-top: 1rem;
          justify-content: space-between;
        }
      `,
    };
  }
);

export const Footer = React.memo(Footer_);
export function Footer_() {
  const i18n = useI18n();
  const styles = useStyles();

  return (
    <div css={styles.root} data-skip-search>
      <div css={styles.content}>
        <div css={styles.UNLogo}>
          <PageLink name="home" query={{}}>
            <a>
              <img
                src={combinedUnescoGemLogo}
                alt="UNESCO GEM Logo"
                width={133}
              />
            </a>
          </PageLink>
        </div>
        <div css={styles.footerLinks}>
          <a
            css={styles.link}
            href={i18n._(t("url.unesco-home")`https://en.unesco.org/`)}
          >
            <Trans>UNESCO Home</Trans>
          </a>
          <a
            css={styles.link}
            href={i18n._(
              t("url.gem-report-home")`https://www.unesco.org/gem-report/en`
            )}
          >
            <Trans>GEM Report Home</Trans>
          </a>
          <PageLink name="terms" query={{}}>
            <a css={styles.link}>
              <Trans>Terms and Conditions</Trans>
            </a>
          </PageLink>
        </div>

        <div css={styles.socialMedia}>
          <a
            css={[styles.link, styles.iconLink]}
            href="https://www.instagram.com/gemreport/"
          >
            <Instagram />
          </a>
          <a
            css={[styles.link, styles.iconLink]}
            href="https://www.facebook.com/efareport"
          >
            <Facebook />
          </a>
          <a
            css={[styles.link, styles.iconLink]}
            href="https://twitter.com/GEMReport"
          >
            <Twitter />
          </a>
          <a
            css={[styles.link, styles.iconLink]}
            href="https://www.youtube.com/user/efareport"
          >
            <Youtube />
          </a>
          <a
            css={[styles.link, styles.iconLink]}
            href="https://www.linkedin.com/groups/4349287/"
          >
            <Linkedin />
          </a>
          <a
            css={[styles.link, styles.iconLink]}
            href="https://gemreportunesco.wordpress.com/"
          >
            <Wordpress />
          </a>
        </div>
      </div>
    </div>
  );
}
