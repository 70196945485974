import { area } from "@visx/shape";
import { ScaleLinear } from "d3-scale";
import { curveMonotoneX } from "d3-shape";
import React from "react";

import { BandItem, SeriesItem } from "../types";
import { TrajectoriesChartProps } from "../Trajectories";

interface BandProps<A> {
  band: BandItem;
  seriesData: SeriesItem<A>[];
  xAccessor: TrajectoriesChartProps<A>["xAccessor"];
  yAccessor: TrajectoriesChartProps<A>["yAccessor"];
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleLinear<number, number>;
}

interface BandValue {
  x: number;
  upperY: number;
  lowerY: number;
}

function Band<A>({
  band,
  seriesData,
  xAccessor,
  yAccessor,
  scaleX,
  scaleY,
}: BandProps<A>): React.ReactElement | null {
  const upperSeriesValues = seriesData.find(
    ({ key }) => key === band.upper
  )?.values;
  const lowerSeriesValues = seriesData.find(
    ({ key }) => key === band.lower
  )?.values;

  if (!upperSeriesValues || !lowerSeriesValues) return null;

  const bandValues: BandValue[] = upperSeriesValues.reduce(
    (prev: BandValue[], curr: A) => {
      const lowerPoint = lowerSeriesValues.find(
        (s) => xAccessor(s) === xAccessor(curr)
      );
      if (
        lowerPoint &&
        yAccessor(lowerPoint) !== null &&
        yAccessor(curr) !== null
      ) {
        const value: BandValue = {
          x: xAccessor(curr),
          upperY: yAccessor(curr) as $FixMe,
          lowerY: yAccessor(lowerPoint) as $FixMe,
        };
        return prev.concat(value);
      }
      return prev;
    },
    []
  );

  let bands: BandValue[][] = [];
  let currentBand: BandValue[] = [];
  bandValues.forEach((value, index) => {
    if (index === 0) {
      currentBand = [value];
      return;
    }
    if (value.x - bandValues[index - 1].x > 1) {
      bands = bands.concat([currentBand]);
      currentBand = [value];
    } else {
      currentBand = currentBand.concat(value);
    }
    if (index === bandValues.length - 1) {
      bands = bands.concat([currentBand]);
    }
  });

  const bandArea = area({
    x: (d: BandValue) => scaleX(d.x) ?? -1,
    y0: (d: BandValue) => scaleY(d.lowerY) ?? -1,
    y1: (d: BandValue) => scaleY(d.upperY) ?? -1,
    curve: curveMonotoneX,
  });

  return (
    <g>
      {bands.map((b, index) => (
        <path key={index} d={bandArea(b) ?? ""} style={band.style} />
      ))}
    </g>
  );
}

export default React.memo(Band) as typeof Band;
