
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "How do demand factors prevent universal schooling?";
export const lead = "In 2020, 260 million children, adolescents and youth were out of school. Examining the reasons and the barriers that keep them from education can help governments design better policies to address the problem.";
export const layout = "focus";
export const article = "access";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusTitle = makeShortcode("FocusTitle");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="How do demand factors prevent universal schooling?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=231" mdxType="FocusDetails">
      <FocusTitle mdxType="FocusTitle">How do demand factors prevent universal schooling</FocusTitle>
      <p>{`In 2020, 260 million children, adolescents and youth were out of school. Examining the reasons and the barriers that keep them from education can help governments design better policies to address the problem. Policies focusing on provision, based on the idea that if schools are built, students will come, have limited success if they do not respond to community demand for schooling. Communities often already prioritize education, even in emergency situations. But schools of low quality and with inappropriate curricula can lead to disillusionment and disengagement.`}</p>
      <p>{`It is possible to distinguish three types of barriers to schooling: situational (life circumstances), dispositional (personal attitudes) and institutional (structural conditions) (UNESCO, 2020). An analysis for this report of reasons given by secondary school-age adolescents (or their parents) in Malawi, Nigeria and Sierra Leone sheds light on why they were not in school. Among those who have never attended school, half cite dispositional barriers related to an ostensible lack of value or interest in education as their main reason for being out of school. Only in Nigeria do institutional barriers, such as lack of schools nearby, play a role in keeping youth from ever going to school. Among those who have attended school at some point but dropped out, dispositional barriers remain important in Malawi but their importance falls by half in Sierra Leone and by two thirds in Nigeria. Situational barriers, notably lack of resources, keep at least 40% of adolescents from going back to school in Nigeria and Sierra Leone. Marriage and pregnancy keep about 10% of adolescents, mostly girls, away from education. `}</p>
      <FocusImage size="medium" src={"1-demand_1.png"} alt="Lack of interest, motivation or valuing of education is a commonly cited reason for never having attended school" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=232" mdxType="FocusImage" />
      <p>{`Different types of barriers require different policy responses. As countries moved towards making education compulsory, as is the case in at least 159 countries (UNESCO, 2019), they increased the number of schools available. After Malawi made education compulsory in 2013, the government rolled out school construction programmes to increase the school supply (Malawi Government, 2013). Countries have also introduced automatic promotion, overturning another institutional barrier that leads to repetition and eventual early school leaving. In Sierra Leone, 5% of those who left school did so because they failed exams and 2% were dismissed.`}</p>
      <p>{`Many countries have tried to address the largest situational barrier by reducing the cost of schooling, although only 46 guarantee free education for 12 years (UNESCO, 2019) and household costs unrelated to fees remain a major burden. Since 2019, at least five sub-Saharan African countries – Mozambique, Sao Tome and Principe, Sierra Leone, Uganda and Zimbabwe – have either revoked policies that restricted pregnant girls’ access to school or implemented policies allowing them to stay in school. In addition to ensuring all schools prioritize admission of young mothers or girls after pregnancy, Uganda’s 2020 revised policy on teenage pregnancy included guidance to schools on how to combat discrimination and stigma affecting students who are pregnant or new parents (Human Rights Watch, 2021).`}</p>
      <p>{`Addressing dispositional barriers can be more challenging. In some contexts, there may not even be recognition that they need to be addressed. A ‘lack of interest’ in education can be used as an excuse to shift the blame for low education achievement from policymakers to individuals, especially members of marginalized groups who are stigmatized as lazy. Dispositional barriers may reflect not lack of demand  but rather hidden costs or the poor quality of the schooling on offer. In the Ugandan National Panel Survey, the response option ‘Education is not useful’ could be a criticism of education in general or of the specific education provided in the local context  – or both (Uganda Bureau of Statistics, 2019).`}</p>
      <p>{`Lack of interest may also reflect negative learning experiences. In South Africa, 23% of students who dropped out before age 18 cited poor academic performance as the main reason (Statistics South  Africa, 2018). In Nigeria, 7% of children who left  school did so because they reportedly ‘could not  learn’ (Nigeria National Bureau of Statistics, 2019).Lack of opportunity for continuing education may also explain low motivation. Studies have shown that the availability of secondary schools nearby increases the likelihood that students will complete primary education (Mukhopadhyay and Sahoo, 2016). Even if students can continue their education, though, they may not consider doing so, as research has long shown that students’ socioeconomic backgrounds heavily influence their aspirations (Gölz and Wohlkinger, 2019; Kao and Tienda, 1998). In rural China, migrant children who do not feel capable of achieving educational success are likely to dismiss education as irrelevant (Chen, 2020).`}</p>
      <p>{`At least partly due to such challenges, dispositional barriers are less often the focus of policies designed to increase attendance. But their prevalence among responses demonstrates that supplying affordable education of good quality is not enough. It is also important to address socioeconomic barriers keeping families and students from wanting to attend or believing they can do so.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-access"
      }}>{`Focuses published in Access`}</h4>
      <hr></hr>
      <FocusThemeList theme="access" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=552"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;