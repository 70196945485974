
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Learning does not progress in a linear fashion";
export const lead = "Target 4.1 calls for monitoring learning outcomes at three points in a learner’s school trajectory: in the early grades (4.1.1a), at the end of primary school (4.1.1b) and at the end of lower secondary school (4.1.1c).";
export const layout = "focus";
export const article = "learning";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Learning does not progress in a linear fashion" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=243" mdxType="FocusDetails">
      <h1 {...{
        "id": "learning-does-not-progress-in-a-linear-fashion"
      }}>{`Learning does not progress in a linear fashion`}</h1>
      <p>{`Target 4.1 calls for monitoring learning outcomes at three points in a learner’s school trajectory: in the early grades (4.1.1a), at the end of primary school (4.1.1b) and at the end of lower secondary school (4.1.1c). This reflects an understanding that those who lag in the beginning may catch up and that those who are on track halfway through their schooling can fall behind. Measurements at three points over the course of a schooling trajectory are a reasonable compromise in terms of systematic international monitoring. In principle, however, learning progression would be best understood using annual assessment data from each grade. Such data are rarely available, especially in low- and middle-income countries, while longitudinal data for individual students are rarer still (Bau et al., 2021).`}</p>
      <p>{`An analysis of learning profiles of Peruvian children based on receptive vocabulary tests administered at ages 5, 8, 12 and 15 shows how such skills develop. The shape of the growth curve is non-linear with a decreasing rate of change, i.e. receptive vocabulary develops more rapidly earlier in life and learning gains decrease with age. Results are consistent with studies showing decreasing growth rates for reading achievement as students move from early to later grades (Bloom, et. al., 2008).`}</p>
      <FocusImage size="medium" src={"6-Learningnotlinear_1.png"} alt="Progress in individual learning is not linear" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=244" mdxType="FocusImage" />
      <p>{`An average flattening of learning in higher grades is in line with models of adolescent development (Buchmann and Steinhoff, 2017). Theoretical models and empirical evidence reveal that students’ expectations and motivation during adolescence influence their engagement and achievement in school (Eccles and Wigfield, 2002). Student engagement declines during adolescence and achievement growth tends to be slower as a result of the decline in student motivation (Akos et al., 2015). The degree of decline seems to depend on the subject. It is greater for mathematics than for reading, and particularly affects students from disadvantaged backgrounds. In terms of policy, studies call attention to subject-specific interest and the influence of instructional practices, teacher support and parental support on student engagement in school (Lam et al., 2016). Similarly, they highlight the need to look at student beliefs, including self-efficacy, self-concept and motivation, in addition to student achievement.`}</p>
      <p>{`While the average pattern is somewhat predictable, individual trajectories display significant variability. There are differences between children in initial achievement levels and the amount and timing of achievement growth, resulting in qualitatively different learning profiles (Helbling et al., 2019). Examples of fast and slow progression exist among those starting at high as well as low levels. One implication of this heterogeneity is that cross-sectional information on which learners have higher achievement at a given point does not necessarily reveal who has gained the most learning until now or who will have the highest achievement in the end.`}</p>
      <p>{`With some limitations, cross-sectional data on learning outcomes for children of different age cohorts offer an alternative for characterizing learning profiles (Kaffenberger, 2019; Silverstein, 2021). However, interpretation of learning trajectories that are not based on individual longitudinal observations is not straightforward. Consistent improvements over cohorts result in cross-sectional learning profiles that are flatter than individual trajectories, i.e. if successive cohorts reach the same level at earlier and earlier points in the course of their schooling. Conclusions that flat learning profiles imply that students ‘do not learn anything’ in school should be drawn only with great care.`}</p>
      <p>{`When assessment data are available only for those who remain in school, interpretation becomes still more challenging. Assumptions of how learning interacts with dropout drive the conclusions. It may seem plausible in many contexts that the lowest performers are more likely to drop out and that the gradient of learning over grades will be generally overestimated in cross-sectional school-based assessment profiles. In addition, if low performers who leave school disproportionately at transition points in the education system, such as the end of primary school, follow the ‘slow-fast’ trajectory, then keeping them in school would lead to steeper average learning gains in later grades, apart from other potential positive consequences.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=552"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;