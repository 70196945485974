import * as React from "react";

function SvgIcArrowLeft(props: $IntentionalAny) {
  return (
    <svg width={8} height={14} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 1.933L6.555.5 0 7l6.555 6.5L8 12.067 2.891 7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcArrowLeft;
