
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Equidad";
export const lead = "Ha habido progresos a nivel mundial con respecto a la paridad de género en la educación, pero siguen existiendo grandes brechas entre las personas de zonas rurales y urbanas, y entre los ricos y los pobres.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const PageLink = makeShortcode("PageLink");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`El deseo de no dejar a nadie atrás atañe a todos los Objetivos de Desarrollo Sostenible (ODS) y se menciona explícitamente en la quinta meta del objetivo de educación, el ODS 4. Se trata de asegurar que los responsables políticos den prioridad a ayudar primero a los más rezagados.`}</p>
    </blockquote>
    <p>{`Para poder verificar que se estén cerrando las brechas educativas, necesitamos datos desglosados `}<strong parentName="p">{`por ingresos, género, edad, raza, origen étnico, situación migratoria, discapacidad, ubicación geográfica y otras características`}</strong>{` pertinentes para los contextos nacionales, que provienen de encuestas de hogares.`}</p>
    <p>{`Desde 2010, la `}<a parentName="p" {...{
        "href": "https://www.education-inequalities.org/"
      }}>{`Base de Datos sobre la Desigualdad Mundial en la Educación`}</a>{` Base de Datos sobre la Desigualdad Mundial en la Educación utiliza encuestas de hogares para mostrar cuán amplias son las brechas educativas en los distintos países. En este artículo se resumen algunos de los hechos y tendencias clave de la desigualdad en la educación, mientras que ciertos aspectos adicionales se abordan en el artículo sobre `}<PageLink name="financia" mdxType="PageLink">{`financiación`}</PageLink>{`.`}</p>
    <h2 {...{
      "id": "género"
    }}>{`Género`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`En el gráfico a continuación se abordan las disparidades en materia de educación entre diversos grupos, como las niñas y los niños, o los más pobres y los más ricos, para cuyo cálculo se emplea un `}<Tooltip title="índice de paridad" content="Si un índice de paridad está cerca de 0, el grupo desfavorecido está muy rezagado; si está cerca de 1, hay paridad; y si está cerca de 2, el grupo favorecido es el que tiene más retraso. Por ejemplo, un índice de paridad de 0,5 podría significar que hay un 30% de niñas y un 60% de niños matriculados. Un índice de paridad de 1,5 supondría que hay un 30% de niños y un 60% de niñas matriculados." mdxType="Tooltip">{`parity index`}</Tooltip>{`.`}</p>
      <p>{` Aunque se celebre con poca frecuencia, la `}<Tooltip title="paridad de género" content="Cuando hay igual número de niños que de niñas" mdxType="Tooltip">{`gender parity`}</Tooltip>{` ha mejorado de forma continua en la última generación. Las niñas y las mujeres jóvenes de muchas partes del mundo, por lo general las más ricas, superan en número a los niños y los hombres jóvenes en las escuelas y universidades.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`Toda línea que pase por debajo de la `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`zona sombreada en gris `}</HighlightByIndex>{` muestra que la cantidad de niños u hombres jóvenes matriculados es superior a la de las niñas.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`En `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`África Subsahariana`}</HighlightByIndex>{`, en la educación terciaria todavía hay menos de cuatro mujeres jóvenes por cada cinco hombres jóvenes.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <HighlightByIndex index="2" mdxType="HighlightByIndex">Asia central y meridional</HighlightByIndex>, donde las niñas se habían quedado muy atrás en 1990, ya han alcanzado la paridad de género en el primer y segundo ciclo de la educación secundaria y una mayor tasa de matriculación de mujeres en la educación terciaria, sobre todo gracias a los espectaculares progresos realizados en la India. Esto ha llevado a la región a superar a <HighlightByIndex index="0" mdxType="HighlightByIndex">África Subsahariana </HighlightByIndex> en términos del progreso alcanzado para lograr la paridad de género.
        </ScrollySection>
      </ScrollyArea>
      <p>{`En el plano mundial, son grandes los avances conseguidos desde el año 2000. Sin embargo, si se desciende al nivel nacional se descubren brechas persistentes; así pues, solo dos de cada tres países han logrado la paridad de género con respecto a la matriculación en la educación primaria, uno de cada dos en el primer ciclo de la educación secundaria, y uno de cada cuatro en el segundo ciclo de la educación secundaria.`}</p>
      <p>{`La proporción de países con una `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`gran disparidad en detrimento de las niñas `}</HighlightByIndex>{` se ha reducido al menos a la mitad desde 2000 en todos los niveles de la enseñanza, pero sigue siendo del 4% en la educación primaria, el 8% en el primer ciclo de la educación secundaria, y el 14% en el segundo ciclo de la educación secundaria.`}</p>
      <p>{`No debemos olvidar a los chicos cuando hablamos de igualdad de género. Si bien es menos común que los países tengan una `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`gran disparidad en detrimento de los chicos`}</HighlightByIndex>{` en la educación primaria, las desigualdades son mucho más comunes en el segundo ciclo de secundaria y los niveles superiores, con una tendencia que se estanca o empeora.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En este gráfico, cuanto mayor y más oscura es la parte azul de la barra, son más los países con `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`más niños que niñas sin escolarizar`}</HighlightByIndex>{`. Cuanto mayor y más oscura es la parte verde de la barra, son más los países con `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`más niñas que niños sin escolarizar`}</HighlightByIndex>{`.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
En la educación terciaria, por ejemplo, más de dos tercios de los países presentan <HighlightByIndex index="0" mdxType="HighlightByIndex">profundas disparidades en detrimento de los chicos</HighlightByIndex>, una situación que empeora con el tiempo.
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "riqueza"
      }}>{`Riqueza`}</h2>
      <p>{`En general, las disparidades en materia de educación entre niñas y niños no son tan grandes como entre las zonas rurales y urbanas, y entre los ricos y pobres, según revelan las encuestas de hogares.`}</p>
      <p>{`En el Pakistán, por ejemplo, mientras que el 92% de los `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`más ricos `}</HighlightByIndex>{` cursa educación primaria, entre los `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`más pobres `}</HighlightByIndex>{` el porcentaje es solo del 28%. En Nigeria, el 97% de los más ricos recibe educación del primer ciclo de secundaria, pero este es el caso únicamente para el 27% de los más pobres.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Los puntos rojos muestran qué porcentaje de niños o jóvenes `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`más pobres `}</HighlightByIndex>{` van a la escuela, en comparación con los `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`más ricos`}</HighlightByIndex>{`, que se muestran en azul. Puede cambiar el nivel de educación de primaria a primer ciclo de secundaria y niveles superiores en la parte superior del gráfico.`}</p>
        </ScrollySection>
        <ScrollySection id="ALB" mdxType="ScrollySection">
          <p>{`En la educación terciaria, las brechas son mayores en los países de ingresos medianos. En `}<strong parentName="p">{`Albania`}</strong>{`, por ejemplo, el 55% de los más ricos, pero menos del 1% de los más pobres, cursó alguna modalidad de estudios postsecundarios.`}</p>
        </ScrollySection>
        <ScrollySection id="MNG" mdxType="ScrollySection">
          <p>{`En `}<strong parentName="p">{`Mongolia`}</strong>{`, los porcentajes respectivos eran del 70% y el 10%.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`Es aún más revelador observar la forma en que cambian las brechas a medida que los niños prosiguen sus estudios.`}</p>
      <p>{`El examen a continuación de las brechas de `}<strong parentName="p">{`gender`}</strong>{` gaps en `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Malí`}</HighlightByIndex>{` demuestra hasta qué punto las niñas abandonan la escuela cuanto mayor es el nivel de educación.`}</p>
      <p>{`Por otra parte, seleccionar la `}<strong parentName="p">{`riqueza`}</strong>{` pone de manifiesto cómo se acumulan las brechas a lo largo de la trayectoria educativa en detrimento de los más pobres, ya que muy pocos de este grupo prosiguen después del segundo ciclo de secundaria.`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Rara vez un niño pertenece a una sola categoría, lo que significa que es crucial considerar también la forma en que las características individuales, como el sexo, interactúan con otras, como la riqueza, tal como se hace en la siguiente figura.`}</p>
    <p>{`En África Subsahariana, las brechas entre los `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`más pobres `}</HighlightByIndex>{` y los `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`más ricos `}</HighlightByIndex>{` se ensanchan a lo largo de la trayectoria educativa, ya que por cada 100 de sus pares masculinos, menos de 45 de las niñas más pobres, frente a casi 80 de las más ricas, reciben educación terciaria.`}</p>
    <p>{`En Asia oriental y sudoriental, en cambio, los puntos suben y se alejan de la línea gris porque las brechas entre los géneros van en detrimento de los niños más pobres. En el nivel de educación terciaria, las niñas más ricas solo tienen alrededor de una cuarta parte más de probabilidades de cursar estudios que sus compañeros varones, pero las niñas más pobres tienen una probabilidad dos tercios mayor.`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`Existen niveles inaceptables de desigualdad en la educación entre países y entre grupos dentro de un mismo país. Es indispensable contar con datos desglosados de alta calidad para formular estrategias apropiadas que permitan reducir estas desigualdades y, en última instancia, no dejar a nadie atrás en nuestro empeño por alcanzar el ODS 4.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`Worldwide Inequalities Database on Education (WIDE)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Still left behind – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/2019genderreport"
          }}>{`Building Bridges for Gender Equality – GEM Gender Report 2019`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;