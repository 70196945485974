
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "full";
export const title = "ODD 4 indicateurs";
export const lead = "Assurer l’accès de tous à une éducation de qualité, sur un pied d’égalité, et promouvoir les possibilités d’apprentissage tout au long de la vie";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const TargetFourLogo = makeShortcode("TargetFourLogo");
const Title = makeShortcode("Title");
const IndicatorSection = makeShortcode("IndicatorSection");
const IndicatorTable = makeShortcode("IndicatorTable");
const IndicatorRow = makeShortcode("IndicatorRow");
const ThemeLabel = makeShortcode("ThemeLabel");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TargetFourLogo mdxType="TargetFourLogo" />
    <Title mdxType="Title">Indicateurs de l’ODD 4</Title>
    <p>{`Assurer l’accès de tous à une éducation de qualité, sur un pied d’égalité, et promouvoir les possibilités d’apprentissage tout au long de la vie`}</p>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-41"
      }}>{`Cible 4.1`}</h2>
      <p>{`D’ici 2030, s’assurer que toutes les filles et garçons ont bien terminé un enseignement primaire et secondaire équitable gratuit et de qualité conduisant à des résultats d’apprentissage pertinents et efficaces. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=236"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.1.0" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Proportion d'enfants / jeunes préparés pour l'avenir, par sexe`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Pourcentage d’enfants et de jeunes : (a) en 2e ou 3e année d’études ; (b) en fin de cycle primaire ; et (c) en fin de premier cycle du secondaire qui maîtrisent au moins les normes d’aptitudes minimales en (i) lecture et (ii) mathématiques, par sexe`}</p>
          <ThemeLabel theme="apprentissage" id="4.1.1a" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Taux d’achèvement (primaire, premier cycle du secondaire et second cycle du secondaire)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Taux brut d’admission en dernière année du niveau (primaire, premier cycle du secondaire)`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.1.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Taux d’enfants non scolarisés (cycle primaire, premier cycle du secondaire et second cycle du secondaire)`}</p>
          <ThemeLabel theme="l'accès" id="4.1.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d’enfants plus âgés que l'âge correspondant à leur année d'études (enseignement primaire, 1er cycle de l'enseignement du secondaire)`}</p>
          <ThemeLabel theme="l'accès" id="4.1.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Administration d’une évaluation de l’apprentissage représentative au plan national (a) en 2e ou 3e année d’études ; (b) en fin de cycle primaire ; et (c) en fin de premier cycle du secondaire`}</p>
          <ThemeLabel theme="l'accès" id="4.1.6" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.1.7" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Nombre d’années d’enseignement primaire et secondaire (i) gratuit et (ii) obligatoire garanti par le cadre juridique`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-42"
      }}>{`Cible 4.2`}</h2>
      <p>{`D’ici à 2030, faire en sorte que toutes les filles et tous les garçons aient accès à des activités de développement et de soins de la petite enfance et à une éducation préscolaire de qualité qui les préparent à suivre un enseignement primaire. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=253"
        }}>{`Voir le dernier chapitre du Rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.2.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion d’enfants âgés de 24 à 59 mois dont le développement est en bonne voie en matière de santé, d’apprentissage et de bien-être psychosocial, par sexe`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.2" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Taux de participation à des activités organisées d’apprentissage (un an avant l’âge officiel de scolarisation dans le primaire), par sexe`}</p>
          <ThemeLabel theme="l'accès" id="4.2.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.2.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d'enfants de moins de 5 ans vivant dans des environnements d'apprentissage familiaux positifs et stimulants`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Taux net de scolarisation dans l'éducation de la petite enfance (a) au préprimaire et (b) dans le développement éducatif de la petite enfance`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.2.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Nombre d’années d’enseignement préprimaire (a) gratuit et (b) obligatoire garanti par le cadre juridique`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-43"
      }}>{`Cible 4.3`}</h2>
      <p>{`D’ici à 2030, faire en sorte que les femmes et les hommes aient tous accès dans des conditions d’égalité à un enseignement technique, professionnel ou tertiaire, y compris universitaire, de qualité et d’un coût abordable. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=263"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.3.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Taux de participation des jeunes et des adultes à un programme d’éducation et de formation scolaire ou non scolaire au cours des 12 mois précédents, par sexe`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Taux brut de scolarisation dans l’enseignement supérieur`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.3.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Taux de participation aux programmes d’enseignement technique et professionnel (15-24 ans), par sexe`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-44"
      }}>{`Cible 4.4`}</h2>
      <p>{`D’ici à 2030, augmenter considérablement le nombre de jeunes et d’adultes disposant des compétences, notamment techniques et professionnelles, nécessaires à l’emploi, à l’obtention d’un travail décent et à l’entrepreneuriat. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=271"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.4.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion de jeunes et d’adultes ayant des compétences dans le domaine des technologies de l’information et des communications, par type de compétence`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage de jeunes/adultes ayant atteint au moins un niveau minimum de compétence en littéracie numérique`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.4.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Taux du niveau d’éducation atteint des jeunes/adultes par groupe d’âge et niveau d’enseignement`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-45"
      }}>{`Cible 4.5`}</h2>
      <p>{`D’ici à 2030, éliminer les inégalités entre les sexes dans le domaine de l’éducation et assurer l’égalité d’accès des personnes vulnérables, y compris les personnes handicapées, les autochtones et les enfants en situation vulnérable, à tous les niveaux d’enseignement et de formation professionnelle. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=281"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.5.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Indices de parité (femmes/hommes, urbain/rural, quintile inférieur/supérieur de richesse et autres paramètres tels que le handicap, le statut d’autochtone et les situations de conflit, à mesure que les données deviennent disponibles) pour tous les indicateurs dans le domaine de l’éducation de cette liste pouvant être ventilés`}</p>
          <ThemeLabel theme="l'équité" id="4.5.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d'élèves a) au cours des premières années, b) en fin de cycle primaire, et c) en fin de premier cycle du secondaire qui ont leur première langue ou leur langue maternelle comme langue d'enseignement`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Existence de mécanismes de financement pour réaffecter les ressources éducatives aux populations défavorisées`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.5.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Dépenses d’éducation par élève, par niveau d’enseignement et source de financement`}</p>
          <ThemeLabel theme="finance" id="4.5.4" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage de l’aide totale à l’éducation alloué aux pays à faible revenu`}</p>
          <ThemeLabel theme="finance" id="4.5.5" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.5.6" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Dépenses d’éducation par source de financement (public, privé, international) en pourcentage du PIB`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-46"
      }}>{`Cible 4.6`}</h2>
      <p>{`D’ici à 2030, veiller à ce que tous les jeunes et une proportion considérable d’adultes, hommes et femmes, sachent lire, écrire et compter. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=292"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.6.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion de la population d’un groupe d’âge donné ayant les compétences voulues à au moins un niveau d’aptitude fixé a) en alphabétisme et b) arithmétique fonctionnels, par sexe`}</p>
          <ThemeLabel theme="apprentissage" id="4.6.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.6.2" mdxType="IndicatorRow">
          <p>{` Taux d’alphabétisme des jeunes/adultes`}</p>
          <ThemeLabel theme="learning" id="4.6.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-47"
      }}>{`Cible 4.7`}</h2>
      <p>{`DD’ici à 2030, faire en sorte que tous les élèves acquièrent les connaissances et compétences nécessaires pour promouvoir le développement durable, notamment par l’éducation en faveur du développement et de modes de vie durables, des droits de l’homme, de l’égalité des sexes, de la promotion d’une culture de paix et de non-violence, de la citoyenneté mondiale et de l’appréciation de la diversité culturelle et de la contribution de la culture au développement durable. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=300"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.7.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Degré d’intégration de i) l’éducation à la citoyenneté mondiale et ii) l’éducation au développement durable dans a) les politiques nationales d’éducation, b) les programmes d’enseignement, c) la formation des enseignants et d) l’évaluation des étudiants`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d’écoles qui dispensent une éducation au VIH et à la sexualité basée sur les compétences utiles dans la vie`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.3" mdxType="IndicatorRow">
          <p>{`Degré d’intégration des intentions de la politique verte  dans les documents du programme`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.4" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d’élèves du premier cycle du secondaire montrant une compréhension adéquate des questions liées à la citoyenneté mondiale et à la durabilité`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.7.5" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d’élèves du premier cycle du secondaire démontrant des compétences en sciences de l’environnement et les géosciences`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-4a"
      }}>{`Cible 4.a`}</h2>
      <p>{`D’ici à 2030, faire construire des établissements scolaires qui soient adaptés aux enfants, aux personnes handicapées et aux deux sexes ou adapter les établissements existants à cette fin et fournir un cadre d’apprentissage effectif qui soit sûr, exempt de violence et accessible à tous. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=308"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.a.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Proportion d’établissements scolaires offrant des services essentiels, par type de service`}</p>
          <ThemeLabel theme="qualité" id="4.a.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.2" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Pourcentage d’élèves victimes d’harcèlement scolaire au cours des 12 derniers mois au a) cycle primaire et b) premier cycle du secondaire`}</p>
          <ThemeLabel theme="qualité" id="4.a.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.a.3" link="http://sdg4-data.uis.unesco.org/" mdxType="IndicatorRow">
          <p>{`Nombre d’attaques contre les élèves, le personnel et les établissements`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.a.4" global mdxType="IndicatorRow">
          <p>{`Proportion d'enfants scolarisés recevant des repas scolaires`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-4b"
      }}>{`Cible 4.b`}</h2>
      <p>{`D’ici à 2020, augmenter considérablement à l’échelle mondiale le nombre de bourses d’études offertes aux pays en développement, en particulier aux pays les moins avancés, aux petits États insulaires en développement et aux pays d’Afrique, pour financer le suivi d’études supérieures, y compris la formation professionnelle, les cursus informatiques, techniques et scientifiques et les études d’ingénieur, dans des pays développés et d’autres pays en développement. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=321"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.b.1" link="http://sdg4-data.uis.unesco.org/" global mdxType="IndicatorRow">
          <p>{`Volume de l’aide publique au développement consacrée aux bourses d’études, par secteur et type de formation`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "cible-4c"
      }}>{`Cible 4.c`}</h2>
      <p>{`D’ici à 2030, accroître considérablement le nombre d’enseignants qualifiés, notamment au moyen de la coopération internationale pour la formation d’enseignants dans les pays en développement, surtout dans les pays les moins avancés et les petits États insulaires en développement. `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=331"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="4.c.1" global mdxType="IndicatorRow">
          <p>{`Proportion d’enseignants possédant les qualifications minimales requises par niveau d’études`}</p>
          <ThemeLabel theme="qualité" id="4.c.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.2" mdxType="IndicatorRow">
          <p>{`Ratio élèves-enseignant formé par niveau d'enseignement`}</p>
          <ThemeLabel theme="qualité" id="4.c.2" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="4.c.3" mdxType="IndicatorRow">
          <p>{`Pourcentage d'enseignants qualifiés selon les normes nationales, par niveau d'enseignement et type d'établissement`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.4" mdxType="IndicatorRow">
          <p>{`Ratio élèves-enseignant qualifié par niveau d'enseignement`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.5" mdxType="IndicatorRow">
          <p>{`Salaire moyen des enseignants par rapport aux autres professions nécessitant un niveau de qualification comparable`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.6" mdxType="IndicatorRow">
          <p>{`Taux d'attrition des enseignants par niveau d'éducation`}</p>
        </IndicatorRow>
        <IndicatorRow id="4.c.7" mdxType="IndicatorRow">
          <p>{`Pourcentage d'enseignants ayant reçu une formation continue au cours des 12 derniers mois par type de formation`}</p>
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>
    <IndicatorSection mdxType="IndicatorSection">
      <h2 {...{
        "id": "finance"
      }}>{`Finance`}</h2>
      <p><a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=340"
        }}>{`Voir le dernier chapitre du rapport GEM`}</a></p>
      <IndicatorTable mdxType="IndicatorTable">
        <IndicatorRow id="F.1" mdxType="IndicatorRow">
          <p>{`Dépenses publiques pour l’éducation en pourcentage du PIB`}</p>
          <ThemeLabel theme="finance" id="F.1" mdxType="ThemeLabel" />
        </IndicatorRow>
        <IndicatorRow id="F.2" mdxType="IndicatorRow">
          <p>{`Dépenses publiques pour l’éducation en pourcentage des dépenses totales du gouvernement`}</p>
          <ThemeLabel theme="finance" id="F.2" mdxType="ThemeLabel" />
        </IndicatorRow>
      </IndicatorTable>
    </IndicatorSection>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;