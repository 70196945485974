import css from "@emotion/css";
import React from "react";
import { mkStyles } from "../../hooks";
import * as M from "../../materials";
import BMBFSponsoredLogoDe from "./files/bg_bmbf-sponsored_de.svg?raw";
import BMBFSponsoredLogoEn from "./files/bg_bmbf-sponsored_en.svg?raw";

const useStyles = mkStyles(({ spacing: { base8 }, breakpoints }) => {
  return {
    root: css`
      grid-column: ${M.layoutAreas.main};
      padding-top: ${base8(7)};
      padding-bottom: ${base8(1)};

      @media ${breakpoints.down("l")} {
        padding: ${base8(4)} 0;
      }

      img {
        max-width: 22em;
      }
    `,
  };
});

export const FederalMinistryGermanyLogo = ({ lang }: { lang?: string }) => {
  const styles = useStyles();

  const logo: Record<string, string> = {
    de: BMBFSponsoredLogoDe,
    en: BMBFSponsoredLogoEn,
  };

  return (
    <div css={styles.root}>
      <img alt="" src={logo[lang || "en"] || BMBFSponsoredLogoEn}></img>
    </div>
  );
};
