import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "TOTBYSOURCE",
  title: /*i18n*/ {
    id: "fig.finance.TOTBYSOURCE.title",
    defaults: `Education spending by governments and households`,
  },
  caption: /*i18n*/ {
    id: "fig.finance.TOTBYSOURCE.caption",
    defaults: `Households are expected to contribute most to education funding where they can least afford to do so, and only a tiny fraction of global education spending goes to where the need is greatest.`,
  },
  source: /*i18n*/ {
    id: "fig.finance.TOTBYSOURCE.source",
    defaults: `GEM Report team analysis based on the UIS (government and household) and OECD CRS (donor) databases`,
  },
};
