import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { format } from "d3-format";
import React, { useCallback } from "react";

import { TrajectoriesChartProps } from "charts/lib/Trajectories/Trajectories";
import { TrajectoriesChart } from "charts/TrajectoriesChart";
import { useReadScrolly } from "components/scrolly";
import { useI18n } from "locales";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_EQUALITY";
import {
  ChartRenderer,
  goalRule,
  RiseDataDecoder,
  Series,
  xAxisTitleLookup,
  yAxisTitleLookup,
} from "../domain";
import { useTheme } from "../hooks";
import * as M from "../materials";
import { E, io, O, pipe } from "../prelude";

export * from "../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_EQUALITY";

// Scrollytelling steps
const Steps = io.union([
  io.literal("CURRENT"),
  io.literal("SIMULATION_ACCESS"),
  io.literal("SIMULATION_LEARNING"),
  io.literal("COMPARISON"),
  io.literal("COMPARISON_GROUP"),
  io.literal("default"),
]);
type Steps = io.TypeOf<typeof Steps>;

type StepProps<A> = Record<
  Steps,
  Partial<TrajectoriesChartProps<A>> &
    Pick<TrajectoriesChartProps<A>, "lineStyle">
>;

// Translation lookups
const seriesLabelsLookup: Record<Series, MessageDescriptor> = {
  CURRENT: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.label.current"
  )`Trajectory of the poor`,
  SIMULATION_ACCESS: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.label.simulationAccess"
  )`Equal access simulation`,
  SIMULATION_LEARNING: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.label.simulationLearning"
  )`Equal learning simulation`,
  COMPARISON: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.label.comparison"
  )`Trajectory of the rich`,
};

const legendLookup: Record<Series, MessageDescriptor> = {
  CURRENT: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.legend.current"
  )`Current learning of poor children (average of 23 low- and lower-middle-income countries)`,
  SIMULATION_ACCESS: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.legend.simulationAccess"
  )`Equal access simulation (if poor children got as much schooling as rich children)`,
  SIMULATION_LEARNING: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.legend.simulationLearning"
  )`Equal learning simulation (if poor children learned as much per grade as rich children)`,
  COMPARISON: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.legend.comparison"
  )`Current learning of rich children (average of 23 low- and lower-middle-income countries)`,
};

const lineStyler = (series: string | number) => {
  const strokeWidth = 3;
  if (series === "CURRENT") {
    return {
      strokeWidth,
      stroke: M.q07Palette[6],
    };
  }
  if (series === "SIMULATION_ACCESS") {
    return {
      strokeWidth,
      stroke: M.q08Palette[5],
      strokeDasharray: "6 12",
    };
  }
  if (series === "SIMULATION_LEARNING") {
    return {
      strokeWidth,
      stroke: M.q01Palette[3],
      strokeDasharray: "6 12",
    };
  }
  if (series === "COMPARISON") {
    return {
      strokeWidth,
      stroke: M.q03Palette[7],
    };
  }
  return {
    strokeWidth,
    stroke: M.grayscalePalette[4],
  };
};

export const Data = RiseDataDecoder(["value", "country", "year", "series"], {});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data, renderMode }: ChartRenderer<Data>) => {
  const { client } = useTheme();
  const i18n = useI18n();
  const { activeSection } = useReadScrolly();
  const activeSectionId = pipe(
    Steps.decode(O.toNullable(activeSection)),
    E.getOrElse(() => Steps.encode("default"))
  );

  const chartPropsAtScrollSteps: StepProps<Data[number]> = {
    CURRENT: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: series === "CURRENT" ? 1 : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) => series !== "CURRENT",
    },
    SIMULATION_ACCESS: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: ["CURRENT", "SIMULATION_ACCESS"].includes(
          series.toString()
        )
          ? 1
          : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) =>
        !["CURRENT", "SIMULATION_ACCESS"].includes(series.toString()),
    },
    SIMULATION_LEARNING: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: ["CURRENT", "SIMULATION_LEARNING"].includes(
          series.toString()
        )
          ? 1
          : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) =>
        !["CURRENT", "SIMULATION_LEARNING"].includes(series.toString()),
    },
    COMPARISON: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: ["CURRENT", "COMPARISON"].includes(series.toString())
          ? 1
          : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) =>
        !["CURRENT", "COMPARISON"].includes(series.toString()),
    },
    COMPARISON_GROUP: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: series === "COMPARISON" ? 1 : 0.5,
      }),
      lockActivePoint: data.find(
        (d) => d.series === "COMPARISON" && d.year === 9
      ),
      backgroundSeriesFilter: (series: string | number) =>
        series !== "COMPARISON",
    },
    default: {
      lineStyle: lineStyler,
    },
  };

  const seriesAccessor = useCallback((d) => d.series, []);
  const xAccessor = useCallback((d) => d.year, []);
  const yAccessor = useCallback((d) => d.value, []);

  return (
    <TrajectoriesChart<Data[number]>
      height={M.chartHeight.m}
      dataset={data}
      seriesAccessor={seriesAccessor}
      xAccessor={xAccessor}
      yAccessor={yAccessor}
      yAxisTitle={i18n._(yAxisTitleLookup["LIT"])}
      xAxisTitle={i18n._(xAxisTitleLookup["AGE"])}
      formatTooltipLabel={(d) =>
        i18n._(seriesLabelsLookup[d.series as Series]) || ""
      }
      formatTooltipValue={(d) =>
        d.value === null
          ? t("fig.label.data.not.available")`Not Available`
          : format(".0%")(d.value)
      }
      formatLegendLabel={(series) =>
        i18n._(legendLookup[series as Series]) || ""
      }
      rules={renderMode === "embed" ? [] : [goalRule(i18n, "AGE", "LIT")]}
      showColorLegend={true}
      legendValues={[
        "COMPARISON",
        "SIMULATION_LEARNING",
        "SIMULATION_ACCESS",
        "CURRENT",
      ]}
      flipTooltip={client.screenSDown}
      {...chartPropsAtScrollSteps[activeSectionId]}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_EQUALITY.json"),
  csv: require("../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_EQUALITY.zip"),
  xlsx: require("../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_EQUALITY.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
