import React from "react";
import { DEFAULT_RENDER_MODE } from "../../domain";
import { getFigureRenderer } from "../../figures";
import { O, pipe } from "../../prelude";

const FallbackMessage = ({ id }: { id: string }) => (
  <div>Figure not found: {id}</div>
);

export function Figure({ id }: { id: string }) {
  return pipe(
    getFigureRenderer(id),
    O.fold(
      () => <FallbackMessage id={id} />,
      (Renderer) => <Renderer renderMode={DEFAULT_RENDER_MODE} />
    )
  );
}
