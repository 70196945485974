
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "How many children attend single-sex schools?";
export const lead = "In about 60% of education systems in the mostly upper-middle and high-income countries that took part in the 2015 TIMSS, less than 5% of primary schools were single-sex.";
export const layout = "focus";
export const article = "equity";
export const year = 2020;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="How many children attend single-sex schools?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=279" mdxType="FocusDetails">
      <h1 {...{
        "id": "how-many-children-attend-single-sex-schools"
      }}>{`How many children attend single-sex schools?`}</h1>
      <p>{`Disaggregation of enrolment by sex is routine in international education statistics, but comparative cross-country data on single-sex vs co-education enrolment are scarce. Cross-national learning assessments, such as the Programme for International Student Assessment and the Trends in International Mathematics and Science Study (TIMSS), which collect information on student class and sex, offer valuable insights.`}</p>
      <p>{`In about 60% of education systems in the mostly upper-middle and high-income countries that took part in the 2015 TIMSS, less than 5% of primary schools were single-sex. However, gender segregation in separate classes or schools is common in countries as diverse as Chile, Ireland, Israel and Singapore and is prevalent in many Muslim-majority countries. The prevalence of single-sex schools generally increases in secondary education, for instance from close to zero for primary to almost one in five for lower secondary education in England (United Kingdom).`}</p>
      <FocusImage size="large" src={"4-Singlesex_schools_1.png"} alt="In many countries, the share of single-sex schools is large" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=280" mdxType="FocusImage" />
      <p>{`In most countries, the proportion of students in single-sex schools corresponds to the proportion of such schools. Exceptions relate to the size and type of schools that tend to be single-sex. In the Islamic Republic of Iran, single-sex primary schools (66%) enrol 84% of grade 4 students, partly because public single-sex schools are larger than private co-education schools. By contrast, single-sex primary schools in the Russian Federation (8%) account for 1% of grade 4 enrolment, as single-sex religious and/or private schools are smaller, on average.`}</p>
      <p>{`Although sudden changes in school system structure are rare, comparisons over time for the countries that participated in the 2007 and 2015 TIMMS capture some shifts. Single-sex schooling decreased in Australia and the Republic of Korea. The latter shifted to co-education schools in the 1980s, and a recent policy decisively favours co-education (Dustmann et al., 2018). The situation is more complex in Western Asia. In Jordan, the share of single-sex lower secondary schools increased by 8 percentage points and the share of students attending them by 12 points. One reason may be the influx after 2011 of Syrian refugees, who attended public single-sex schools. The share of single-sex schools decreased in Bahrain and Kuwait. While public schools remain segregated in Gulf Cooperation Council countries, the changes are attributable to an increasing share of mixed private international schools. The United Arab Emirates introduced co-education primary schooling in 2018 (Dajani and Rizvi, 2018).`}</p>
      <p>{`From a gender inclusion perspective, single-sex schooling may be an acceptable temporary compromise when the de facto alternative in some culture- or country-specific contexts is females not attending (Marcus and Page, 2016; Sperling and Winthrop, 2015). Parents may prefer to send daughters to single-sex schools once they reach adolescence; lack of such provision in parts of Pakistan is one reason reported for low female enrolment (Aslam and Kingdon, 2008).`}</p>
      <p>{`Some argue that gender social dynamics are educationally counterproductive (Bigler et al., 2014). Females may show greater affinity for and achievement in science, technology, engineering and mathematics when less exposed to negative gender stereotypes about ability and to males monopolizing equipment (Marcus and Page, 2016). yet single-sex schooling is unlikely to affect choices, attainment or achievement unless it challenges dominant notions of masculinity and femininity (Smyth, 2010). The counterargument is that single-sex schooling can prevent females from developing social skills needed to navigate unsegregated workplaces and adult life (Fabes et al., 2013; Wong et al., 2018).`}</p>
      <p>{`Evidence on the effects of single-sex schooling is mixed (Unterhalter et al., 2014). The main difficulty is isolating the characteristics of students likely to attend single-sex schools and those of segregated schools themselves from the single-sex-schooling effect. In Thailand and Trinidad and Tobago, single-sex schools tend to attract wealthier females, leading to overestimation of the benefits (Arms, 2007; Jackson, 2012). A meta-analysis of 184 studies from 21 countries found that, while some showed modest learning outcome benefits of gender segregation, higher-quality research that adjusted for confounding factors showed little to no benefit and a slight negative effect on female education aspirations (Pahlke et al., 2014). The Republic of Korea provides one of the few natural experiments, as students are randomly assigned to secondary schools (Link, 2012). A study exploiting this found that single-sex schooling had a small positive effect on achievement (Park et al., 2013).`}</p>
      <p>{`The question is not which setting is better but why single-sex schools sometimes produce better outcomes and how to replicate the benefits in more inclusive settings (Riordan, 2015; Sax et al., 2009). State-run primary schools in Malta have been co-educational since 1980, while secondary schools were single sex until 2013. Due to this history and the many single-sex church-run schools, the prevalence of single-sex secondary schools is among the highest for non-Muslim-majority countries. A study on the centralized lottery for Catholic school admission suggested that students with single-sex schooling subsequently chose less gendered subjects (Giardili, 2019).`}</p>
      <p>{`Malta’s recent move towards public co-education occurred as part of a framework of policies to support and promote social inclusion. One benefit is easier inclusion and freedom of expression of lesbian, gay, bisexual, transgender and intersex students, who may be particularly excluded in single-sex schools premised on a homogeneous gender identity. With its 2015 Gender Identity, Gender Expression and Sex Characteristics Act, Malta adopted Europe’s first comprehensive education policy focused on their needs; it included confidentiality and ended gender segregation in uniforms and some sports (Ávila, 2018).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-equity"
      }}>{`Focuses published in Equity`}</h4>
      <hr></hr>
      <FocusThemeList theme="equity" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=509"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;