import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import React from "react";
import { useColorScaleVDP, VerticalDotPlotAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_learning_fig_EGRA";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  getCountryName,
  mkGemDataDecoder,
} from "../domain";
import { useTheme } from "../hooks";
import { fromNewtype, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, identity, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_learning_fig_EGRA";

const yUnitLabel = t(
  "fig.learning.EGRA.yUnitLabel"
)`Percentage of students who can't read a single word`;
const yUnitLabelShort = t(
  "fig.learning.EGRA.yUnitLabelShort"
)`Pct. of students who can't read a single word`;

const gradeOrder = {
  "Grade 2": 0,
  "Grade 3": 1,
};

const Grade = io.keyof(gradeOrder);
type Grade = io.TypeOf<typeof Grade>;

const gradeLookup: Record<Grade, MessageDescriptor> = {
  "Grade 2": t("fig.learning.EGRA.grade2")`Grade 2`,
  "Grade 3": t("fig.learning.EGRA.grade3")`Grade 3`,
};

export const Data = mkGemDataDecoder(["year", "ind_id"], {
  value: io.number,
  country: fromNewtype<CountryId>(io.string),
  language_or_region: optionFromNullable(io.string),
  grade: Grade,
});

export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: rawData }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();

  const data = React.useMemo(() => {
    return rawData.map((x) => {
      const country_name = getCountryName(env.countries, x.country);
      return {
        ...x,
        country_name,
        country_language: pipe(
          x.language_or_region,
          O.fold(
            () => country_name,
            (lr) => `${country_name} – ${lr}`
          )
        ),
        country_language_id: pipe(
          x.language_or_region,
          O.fold(
            () => countryIdIso.unwrap(x.country),
            (lr) => `${countryIdIso.unwrap(x.country)}:${lr}`
          )
        ),
        grade_name: i18n._(gradeLookup[x.grade]),
      };
    });
  }, [env.countries, i18n, rawData]);
  type Datum = typeof data[number];

  const chartData = React.useMemo(() => {
    return unsafeFromArray(
      Ar.sortBy([
        Ord.contramap((x: Datum) => gradeOrder[x.grade])(Ord.ordNumber),
        Ord.contramap((x: Datum) => x.value)(Ord.ordNumber),
      ])(data)
    );
  }, [data]);

  const getX = React.useCallback((x: Datum) => x.country_language, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.grade_name, []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      showTooltip
      showLegend
      showYExtent
      dotStyle={client.screenSDown ? "small" : "medium"}
      labelStyleX="none"
      yUnitLabel={
        client.screenSDown ? i18n._(yUnitLabelShort) : i18n._(yUnitLabel)
      }
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_EGRA.json"),
  csv: require("../data/data_learning_fig_EGRA.zip"),
  xlsx: require("../data/data_learning_fig_EGRA.xlsx"),
  metadata,
  Data,
  Chart,
});
