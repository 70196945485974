import { PageLink } from "components/links";
import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { mkStyles } from "hooks";
import css from "@emotion/css";
import { AnimatePresence, motion } from "framer-motion";
import { springConfigs } from "charts/lib/motion";
import { RAlias } from "routes";
import { E, pipe } from "prelude";
import lunr from "../../search/lunr";

interface Page {
  id: string;
  title: string;
  locale: string;
  description: string;
  pagename: string;
  filename: string;
}
interface Props {
  searchIndex: {
    idx: lunr.Index;
    pages: Record<string, Page>;
  };
  query: string;
}

const Results = (props: Props) => {
  const { searchIndex, query } = props;
  const idx = useMemo(
    () => lunr.Index.load(searchIndex.idx),
    [searchIndex.idx]
  );
  const results = idx.search(query ? `${query}*~2` : "");
  const styles = useStyles();

  return (
    <ul css={styles.list.root}>
      <AnimatePresence>
        {results.map((d) => {
          const match = Object.values(searchIndex.pages).find(
            (x) => x.id === d.ref
          );
          const keywords = Object.keys(d.matchData.metadata);

          if (!match || ["figure", "figures"].includes(match.filename)) {
            return null;
          }

          return pipe(
            RAlias.decode(match.filename),
            E.fold(
              () => null,
              (alias) => (
                <PageLink name={alias} query={{}} key={d.ref}>
                  <motion.button
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    css={styles.list.button}
                    tabIndex={0}
                    layout
                    transition={springConfigs.slow}
                    key={d.ref}
                  >
                    <li css={styles.list.item}>
                      <b style={{ display: "block" }}>
                        <Highlighter
                          searchWords={keywords}
                          autoEscape={true}
                          textToHighlight={match.title ?? ""}
                        />
                      </b>
                      <Highlighter
                        searchWords={keywords}
                        autoEscape={true}
                        textToHighlight={
                          match.description.substring(0, 100) + " [...]" ?? ""
                        }
                      />
                    </li>
                  </motion.button>
                </PageLink>
              )
            )
          );
        })}
      </AnimatePresence>
    </ul>
  );
};

export const SearchComponent = (props: $PropsWithChildren) => {
  const styles = useStyles();
  return <div css={styles.input.root} {...props} />;
};

export const SearchInput = (props: React.HTMLProps<HTMLInputElement>) => {
  const styles = useStyles();
  return <input css={styles.input.input} type="search" {...props} />;
};

const useStyles = mkStyles(({ client, palette, spacing: { base8 } }) => {
  return {
    input: {
      root: css`
        margin-right: ${client.isRTL
          ? base8(client.screenLDown ? 0 : 2)
          : base8(0)};
        margin-left: ${client.isRTL
          ? base8(0)
          : base8(client.screenLDown ? 0 : 2)};
        margin-top: ${client.screenLDown ? base8(2) : 0};
      `,
      input: css`
        width: ${client.screenLDown ? "100%" : "auto"};
        padding: ${base8(client.screenLDown ? 1 : 0.5)} ${base8(2)};
        border-radius: ${base8(2)};
        border: none;
        outline: none;
        opacity: 0.5;
        transition: 0.4s opacity;
        &:active {
          opacity: 1;
        }
      `,
    },
    list: {
      root: css`
        position: ${client.screenLDown ? "static" : "absolute"};
        width: ${client.screenLDown ? "100%" : "500px"};
        right: ${client.isRTL ? "auto" : 0};
        left: ${client.isRTL ? 0 : "auto"};
        max-width: 640px;
        max-height: ${client.screenLDown ? 300 : 500}px;
        margin-top: ${client.screenLDown ? 8 : 0}px;
        overflow: auto;
        background: white;
        border-radius: 4px;
        color: black;
        z-index: 2;
        box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.28); /* FIXME: define global shadow styles */
      `,
      button: css`
        border: none;
        background: none;
        text-align: ${client.isRTL ? "right" : "left"};
        &:focus {
          outline: none;
          background: ${palette.grayscale[2]};
        }
      `,
      item: css`
        padding: ${base8(1)};
        cursor: pointer;
        &:hover {
          background: ${palette.grayscale[2]};
          opacity: 0.8;
        }
        & mark {
          background: ${palette.grayscale[3]};
          font-weight: bold;
        }
      `,
    },
  };
});

export default Results;
