
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Safe schools must be safe to reach";
export const lead = "In poorer countries, there is little political support and therefore limited budget for improving children’s safety on the way to and from school.";
export const layout = "focus";
export const article = "quality";
export const year = 2020;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Safe schools must be safe to reach" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=309" mdxType="FocusDetails">
      <h1 {...{
        "id": "safe-schools-must-be-safe-to-reach"
      }}>{`Safe schools must be safe to reach`}</h1>
      <p>{`In poorer countries, there is little political support and therefore limited budget for improving children’s safety on the way to and from school. Children experience physical exhaustion, violence and harassment, and exposure to significant danger on their routes. Journeys may be long and arduous, especially in rural areas with poor infrastructure and public transport. This disproportionately affects poor children whose parents cannot afford public transport or commute long distances to work and cannot accompany children to school. Some children walk several hours. Evidence from Brazil showed that the longer the commute, the worse the impact on academic performance (Tigre et al., 2017). In Delhi, India, young women were willing to enrol in a college of lower quality if it was accessible by a safer route (Borker, 2018). `}</p>
      <p>{`Violence against girls and neighbourhood violence are common. In Haiti, 27% of women who received money for sex before age 18 listed schools and school neighbourhoods as the most common location for solicitation (Rames et al., 2016). Rape on the school commute was a parental worry in many countries in Africa (Greene et al., 2011). Students in urban and peri-urban areas of South Africa cited violence as a major fear (International Forum for Rural Transport and Development, 2010). Evidence from Brazil showed that exposure to violence and homicide on the way to school, in areas surrounding schools or at home negatively affected repetition, dropout and achievement (Koppensteiner and Menezes, 2017). Walking instead of taking a bus through violent neighbourhoods in Baltimore, United States, increased absenteeism (Burdick-Will et al., 2019). Introduction of a bus service at Marquette University in the US state of Wisconsin reduced crime in the neighbourhood, especially along the route (Heywood and Weber, 2019). `}</p>
      <p>{`Students face physical risks, such as ditches that especially younger children find difficult to cross. Flash floods in the wet season can be lethal for children unable to swim – a gendered risk, as girls are less likely to know how. Wildlife presents significant dangers (International Forum for Rural Transport and Development, 2010). Students in Kenya’s Kakuma refugee camp collaborated with graduate students at the University of Geneva to geolocate incidences of snakebite with mobile phones. An app mapped snake sightings to help identify safe routes (Moser-Mercer, 2018). `}</p>
      <p>{`Traffic accidents rank at or near the top of the most significant dangers. Children walking to school are disproportionately affected because many schools are located along major highways, and children have more limited impulse control, slower reaction time and poorer risk perception than adults (Silverman, 2016). Traffic is especially dangerous in poorer countries (Silverman and Billingsley, 2015) and neighbourhoods (Lin et al., 2019). Rapid, unplanned growth contributes to poor road conditions and inadequate urban traffic design, putting pedestrians and other vulnerable road users at risk, especially when combined with lack of stringent vehicle safety standards. `}</p>
      <p>{`Despite having far fewer vehicles, poorer countries have a far higher risk of accidents and fatal accidents. There are 6.2 fatalities among 5- to 14-year-olds per 100,000 people in low-income countries, which average 8 vehicles per 1,000 people, compared with 1.7 in high-income countries, where the average is 528. An International Road Assessment Programme survey of nearly 250,000 km of roads in 60 countries found that more than 80% with a traffic flow over 40 km/h and used by pedestrians had no pavements (Welle et al., 2016).`}</p>
      <FocusImage size="medium" src={"7-Safe_schools_1.png"} alt="Children in poorer countries with fewer vehicles are more likely to die in traffic accidents" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=310" mdxType="FocusImage" />
      <p>{`Traffic education is important. The Global Initiative for Child Health and Mobility, coordinated and funded by the FIA Foundation, aims to ensure safe and healthy school journeys for all children by 2030 (FIA Foundation, 2016). Within the SDG framework, it campaigns for speed limits, viable footpaths and cycle lanes (FIA Foundation, 2020). Finding a safe place to cross the road is more difficult for those with intellectual disability, as their ability to focus and ignore irrelevant stimuli is weaker. Road safety education should take into account their attention and cognitive style using virtual reality technology (Alevriadou, 2010).`}</p>
      <p>{`Systemic solutions are needed. The Safe System approach to traffic planning is a promising evidence-based initiative, pioneered in Sweden as Vision Zero. It recognized that, while human fallibility is inevitable, serious injuries and fatalities are not. Road system design should ensure that human error has no serious outcomes (Welle et al., 2016). The Republic of Korea reduced child traffic injuries by 95%, from 1,766 in 1988 to 83 in 2012, using a comprehensive approach that combined safe school routes, road safety legislation and education, and measures such as free car seats for low-income households (Silverman, 2016). `}</p>
      <p>{`Many successful initiatives and system designs are yet to be widely adopted. In Kenya, Global Road Safety Partnership and WHOworked with government to lower speed limits, reducing traffic injuries among students. Thailand’s 7 Percent Project coalition tackles the issue of the more than a million children who ride to school on their parents’ scooters, of whom only 7% wear helmets. Uruguay’s Safely Back to School campaign resulted in legislation requiring all school transport vehicles to have three-point, height-adjustable seat belts, which has become a reference for other Latin American countries (Silverman and Billingsley, 2015).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=513"} mdxType="ReferenceButton">
  2020 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;