// This file could be generated, but currently isn't …

import { stringify } from "query-string";
import { withDataLoader } from "../components/data-loader";
import { RenderMode, useSerializedFigureState } from "../domain";
import { useQueryParams } from "../hooks";
import { Ar, E, O, pipe } from "../prelude";
import { getRoute, RAliasArticle, routes } from "../routes";
import * as AID from "./AID";
import * as AID_FLOWS from "./AID_FLOWS";
import * as AID_FLOWS_SSA from "./AID_FLOWS_SSA";
import * as AID_LICS from "./AID_LICS";
import * as AID_TARGETING from "./AID_TARGETING";
import * as AID_FLOWS_GROUPED from "./AID_FLOWS_GROUPED";
import * as ASSESS from "./ASSESS";
import * as BULLY_FIGHT from "./BULLY_FIGHT";
import * as COMPLETION from "./COMPLETION";
import * as EGRA from "./EGRA";
import * as ELECTRIC from "./ELECTRIC";
import * as GOVHH_ABS_REL from "./GOVHH_ABS_REL";
import * as AID_EDU_SSA from "./AID_EDU_SSA";
import * as GOVVGDP from "./GOVVGDP";
import * as GPIBYWEALTH from "./GPIBYWEALTH";
import * as GPITREND from "./GPITREND";
import * as AID_BASIC from "./AID_BASIC";
import * as HERO_ACCESS from "./HERO_ACCESS";
import * as HERO_EQUITY from "./HERO_EQUITY";
import * as EDU_SCHOL_TT from "./EDU_SCHOL_TT";
import * as AID_TO_EDU from "./AID_TO_EDU";
import * as HERO_FINANCE from "./HERO_FINANCE";
import * as HERO_LEARNING from "./HERO_LEARNING";
import * as HERO_QUALITY from "./HERO_QUALITY";
import * as LEARN_AGE_GRADE from "./LEARN_AGE_GRADE";
import * as LEARN_REACHFINAL from "./LEARN_REACHFINAL";
import * as LITBYYRS from "./LITBYYRS";
import * as LITCOHORT from "./LITCOHORT";
import * as LITPROF from "./LITPROF";
import * as ODA_GDP from "./ODA_GDP";
import * as EDU_SUB from "./EDU_SUB";
import * as SECTOR_ALLOCABLE from "./SECTOR_ALLOCABLE";
import * as GPE from "./GPE";
import * as OOSRATE from "./OOSRATE";
import * as OOSREL from "./OOSREL";
import * as OVERAGE from "./OVERAGE";
import * as PARITYCOUNT from "./PARITYCOUNT";
import * as PIRLSGAIN from "./PIRLSGAIN";
import * as PREPRIM from "./PREPRIM";
import * as PSECWGAP from "./PSECWGAP";
import * as PTR from "./PTR";
import * as SANITATION from "./SANITATION";
import * as TRAJECTORIES_CURRENT from "./TRAJECTORIES_CURRENT";
import * as TRAJECTORIES_EXPLORER_CURRENT from "./TRAJECTORIES_EXPLORER_CURRENT";
import * as TRAJECTORIES_EXPLORER_SIMULATION from "./TRAJECTORIES_EXPLORER_SIMULATION";
import * as TRAJECTORIES_SIMULATION_LMIC from "./TRAJECTORIES_SIMULATION_LMIC";
import * as TRAJECTORIES_SIMULATION_EQUALITY from "./TRAJECTORIES_SIMULATION_EQUALITY";
import * as TOTBYSOURCE from "./TOTBYSOURCE";
import * as TRAINED from "./TRAINED";
import * as WLGPI from "./WLGPI";
import React from "react";
import useLocale from "hooks/useLocale";
import { ExplorerProvider } from "components/mdx/explorer-provider";

/**
 * 1. Not started
 * 2. In progress
 * 3. In review/refinement
 * 4. Done
 *
 * -1 = Questions/problems
 */
export type FigureStatus = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export const foldFigureStatus = (status: FigureStatus) => {
  switch (status) {
    case -1:
      return "❌ ";
    case 0:
      return "◻️ ";
    case 1:
      return "🔶 ";
    case 2:
      return "🔷 ";
    case 3:
      return "✅ ";
    case 4:
      return "🔒 ";
    case 5:
      return "";
  }
};

export interface FigureSpec {
  theme: RAliasArticle;
  id: string;
  Component: ReturnType<typeof withDataLoader>;
  status: FigureStatus;
}

export const figures: Array<FigureSpec> = [
  {
    theme: "access",
    id: COMPLETION.metadata.id,
    Component: COMPLETION.default,
    status: 5,
  },
  {
    theme: "access",
    id: HERO_ACCESS.metadata.id,
    Component: HERO_ACCESS.default,
    status: 5,
  },
  {
    theme: "access",
    id: OOSRATE.metadata.id,
    Component: OOSRATE.default,
    status: 5,
  },
  {
    theme: "access",
    id: OOSREL.metadata.id,
    Component: OOSREL.default,
    status: 5,
  },
  {
    theme: "access",
    id: OVERAGE.metadata.id,
    Component: OVERAGE.default,
    status: 5,
  },
  {
    theme: "access",
    id: PREPRIM.metadata.id,
    Component: PREPRIM.default,
    status: 5,
  },
  {
    theme: "equity",
    id: GPITREND.metadata.id,
    Component: GPITREND.default,
    status: 5,
  },
  {
    theme: "equity",
    id: PARITYCOUNT.metadata.id,
    Component: PARITYCOUNT.default,
    status: 5,
  },
  {
    theme: "equity",
    id: PSECWGAP.metadata.id,
    Component: PSECWGAP.default,
    status: 5,
  },
  {
    theme: "equity",
    id: WLGPI.metadata.id,
    Component: WLGPI.default,
    status: 5,
  },
  {
    theme: "equity",
    id: GPIBYWEALTH.metadata.id,
    Component: GPIBYWEALTH.default,
    status: 5,
  },
  {
    theme: "equity",
    id: HERO_EQUITY.metadata.id,
    Component: HERO_EQUITY.default,
    status: 5,
  },
  {
    theme: "learning",
    id: EGRA.metadata.id,
    Component: EGRA.default,
    status: 5,
  },
  {
    theme: "learning",
    id: LEARN_AGE_GRADE.metadata.id,
    Component: LEARN_AGE_GRADE.default,
    status: 5,
  },
  {
    theme: "learning",
    id: LEARN_REACHFINAL.metadata.id,
    Component: LEARN_REACHFINAL.default,
    status: 5,
  },
  {
    theme: "learning",
    id: LITBYYRS.metadata.id,
    Component: LITBYYRS.default,
    status: 5,
  },
  {
    theme: "learning",
    id: ASSESS.metadata.id,
    Component: ASSESS.default,
    status: 5,
  },
  {
    theme: "learning",
    id: PIRLSGAIN.metadata.id,
    Component: PIRLSGAIN.default,
    status: 5,
  },
  {
    theme: "learning",
    id: LITCOHORT.metadata.id,
    Component: LITCOHORT.default,
    status: 5,
  },
  {
    theme: "learning",
    id: LITPROF.metadata.id,
    Component: LITPROF.default,
    status: 5,
  },
  {
    theme: "learning",
    id: HERO_LEARNING.metadata.id,
    Component: HERO_LEARNING.default,
    status: 5,
  },
  {
    theme: "trajectories",
    id: TRAJECTORIES_CURRENT.metadata.id,
    Component: TRAJECTORIES_CURRENT.default,
    status: 2,
  },
  {
    theme: "trajectories",
    id: TRAJECTORIES_SIMULATION_LMIC.metadata.id,
    Component: TRAJECTORIES_SIMULATION_LMIC.default,
    status: 2,
  },
  {
    theme: "trajectories",
    id: TRAJECTORIES_SIMULATION_EQUALITY.metadata.id,
    Component: TRAJECTORIES_SIMULATION_EQUALITY.default,
    status: 2,
  },
  {
    theme: "trajectories",
    id: TRAJECTORIES_EXPLORER_CURRENT.metadata.id,
    Component: TRAJECTORIES_EXPLORER_CURRENT.default,
    status: 5,
  },
  {
    theme: "trajectories",
    id: TRAJECTORIES_EXPLORER_SIMULATION.metadata.id,
    Component: TRAJECTORIES_EXPLORER_SIMULATION.default,
    status: 5,
  },
  {
    theme: "quality",
    id: HERO_QUALITY.metadata.id,
    Component: HERO_QUALITY.default,
    status: 5,
  },
  { theme: "quality", id: PTR.metadata.id, Component: PTR.default, status: 5 },
  {
    theme: "quality",
    id: TRAINED.metadata.id,
    Component: TRAINED.default,
    status: 5,
  },
  {
    theme: "quality",
    id: SANITATION.metadata.id,
    Component: SANITATION.default,
    status: 5,
  },
  {
    theme: "quality",
    id: ELECTRIC.metadata.id,
    Component: ELECTRIC.default,
    status: 5,
  },
  {
    theme: "quality",
    id: BULLY_FIGHT.metadata.id,
    Component: BULLY_FIGHT.default,
    status: 5,
  },
  {
    theme: "finance",
    id: TOTBYSOURCE.metadata.id,
    Component: TOTBYSOURCE.default,
    status: 5,
  },
  {
    theme: "finance",
    id: GOVVGDP.metadata.id,
    Component: GOVVGDP.default,
    status: 5,
  },
  { theme: "finance", id: AID.metadata.id, Component: AID.default, status: 5 },
  {
    theme: "finance",
    id: AID_LICS.metadata.id,
    Component: AID_LICS.default,
    status: 5,
  },
  {
    theme: "finance",
    id: AID_TARGETING.metadata.id,
    Component: AID_TARGETING.default,
    status: 5,
  },
  {
    theme: "finance",
    id: AID_FLOWS.metadata.id,
    Component: AID_FLOWS.default,
    status: 5,
  },
  {
    theme: "finance",
    id: AID_FLOWS_SSA.metadata.id,
    Component: AID_FLOWS_SSA.default,
    status: 5,
  },
  {
    theme: "finance",
    id: GOVHH_ABS_REL.metadata.id,
    Component: GOVHH_ABS_REL.default,
    status: 5,
  },
  {
    theme: "finance",
    id: HERO_FINANCE.metadata.id,
    Component: HERO_FINANCE.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: ODA_GDP.metadata.id,
    Component: ODA_GDP.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: SECTOR_ALLOCABLE.metadata.id,
    Component: SECTOR_ALLOCABLE.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: EDU_SUB.metadata.id,
    Component: EDU_SUB.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: EDU_SCHOL_TT.metadata.id,
    Component: EDU_SCHOL_TT.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: GPE.metadata.id,
    Component: GPE.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: AID_TO_EDU.metadata.id,
    Component: AID_TO_EDU.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: AID_BASIC.metadata.id,
    Component: AID_BASIC.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: AID_FLOWS_GROUPED.metadata.id,
    Component: AID_FLOWS_GROUPED.default,
    status: 5,
  },
  {
    theme: "financeAid",
    id: AID_EDU_SSA.metadata.id,
    Component: AID_EDU_SSA.default,
    status: 5,
  },
];

export const getFigure = (id: string) => {
  return pipe(
    figures,
    Ar.findFirst((x) => x.id === id)
  );
};

export const getFigureRenderer = (id: string) => {
  return pipe(
    getFigure(id),
    O.map((x) => x.Component)
  );
};

const FallbackMessage = ({ id }: { id?: string }) =>
  IS_DEV ? (
    <div>{id ? `No figure with ID "${id}"` : "Figure not found"}</div>
  ) : null;

export const useFigureRendererFromUrl = ({
  renderMode,
}: {
  renderMode: RenderMode;
}) => {
  return pipe(
    useQueryParams(routes.figure.decoder),
    E.fold(
      () => <FallbackMessage />,
      ({ id }) => {
        return pipe(
          getFigureRenderer(id),
          O.fold(
            () => <FallbackMessage id={id} />,
            (Renderer) => {
              const Wrapper = id.startsWith("TRAJECTORIES")
                ? ExplorerProvider
                : React.Fragment;
              return (
                <Wrapper>
                  <Renderer renderMode={renderMode} />
                </Wrapper>
              );
            }
          )
        );
      }
    )
  );
};

export const useFigureScreenshotUrl = (figureId: string) => {
  const locale = useLocale();
  const state = useSerializedFigureState();
  const alias = "figure";
  return pipe(
    getFigure(figureId),
    O.map(({ id }) => {
      const route = getRoute(alias)({
        alias,
        locale,
        id,
        state: JSON.stringify(state),
      });
      const url = `${SITE_URL}${route.as}`;
      const payload = { url };
      return `${SCREENSHOT_URL}?${stringify(payload)}`;
    })
  );
};

export const useStatefulFigureEmbedUrl = (figureId: string) => {
  const locale = useLocale();
  const state = useSerializedFigureState();
  const alias = "embed";
  return pipe(
    getFigure(figureId),
    O.map(({ id }) => {
      const route = getRoute(alias)({
        alias,
        locale,
        id,
        state: JSON.stringify(state),
      });
      return `${SITE_URL}${route.as}`;
    })
  );
};

export const useStatefulFigureScreenshotUrl = (figureId: string) => {
  const locale = useLocale();
  const state = useSerializedFigureState();
  const alias = "figure";
  return pipe(
    getFigure(figureId),
    O.map(({ id }) => {
      const route = getRoute(alias)({
        alias,
        locale,
        id,
        state: JSON.stringify(state),
      });
      const url = `${SITE_URL}${route.as}`;
      const filename = `gem-report-education-progress-${id}-${timestamp()}`;
      const payload = { url, filename };
      return `${SCREENSHOT_URL}?${stringify(payload)}`;
    })
  );
};

function timestamp() {
  const d = new Date();
  return d.toISOString().substr(0, 19).replace(/[-:]/g, "").replace(/T/, "-");
}
