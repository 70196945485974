import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { tuple } from "fp-ts/lib/function";
import React from "react";
import { ChartGrid } from "../charts-motion";
import ColorLegend from "../charts/lib/ColorLegend";
import { Line as FixedWidthLineChart } from "../charts/lib/Lines";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_fig_AID_TARGETING";
import {
  ChartRenderer,
  getAidFlowName,
  mkGemDataDecoder,
  useMultiSelectState,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, Eq, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_finance_fig_AID_TARGETING";

const DEFAULT_DONORS = ["Germany", "United Kingdom"];

const indicatorOrder = {
  "AID.OF.GNI": 0,
  "AID.TO.BASIC": 1,
  "AID.TO.LICS": 2,
};

const Indicator = io.keyof(indicatorOrder);
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  "AID.OF.GNI": t(
    "fig.finance.AID_TARGETING.AID.OF.GNI"
  )`Official development aid as a share of GNI (%)`,
  "AID.TO.BASIC": t(
    "fig.finance.AID_TARGETING.AID.TO.BASIC"
  )`Share of basic education in total ODA to education (%)`,
  "AID.TO.LICS": t(
    "fig.finance.AID_TARGETING.AID.TO.LICS"
  )`Share of ODA to low income countries in total ODA to education (%)`,
};

export const Data = mkGemDataDecoder(["value", "year"], {
  ind_id: Indicator,
  donor: io.string,
  type: io.union([io.literal("average"), io.literal("entity")]),
});

export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const { aidflows } = useConfig();
  const [selectedDonors, actions] = useMultiSelectState("donors");

  const data = React.useMemo(() => {
    return dataRaw.map((x) => ({
      ...x,
      indicator_name: i18n._(indicatorLookup[x.ind_id]),
      donorId: x.donor,
      donor: getAidFlowName(aidflows, "donor", x.donor),
    }));
  }, [dataRaw, i18n, aidflows]);

  type Datum = typeof data[number];

  const averageData = React.useMemo(
    () => data.filter((x) => x.type === "average"),
    [data]
  );
  const entityData = React.useMemo(
    () =>
      Ar.sort(
        Ord.contramap((x: Datum) => tuple(indicatorOrder[x.ind_id], x.donor))(
          Ord.getTupleOrd(Ord.ordNumber, Ord.ordString)
        )
      )(data.filter((x) => x.type === "entity")),
    [data]
  );

  const donorKeys = React.useMemo(
    () =>
      pipe(
        entityData,
        Ar.uniq(Eq.fromEquals((a, b) => a.donorId === b.donorId)),
        unsafeFromArray,
        An.sort(
          Ord.contramap((x: typeof entityData[number]) => x.donor)(
            Ord.ordString
          )
        )
      ),
    [entityData]
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "donors",
      {
        type: "MultiSelect",
        label: i18n._(t`Donors`),
        selected: pipe(
          donorKeys,
          An.map((x) => x.donorId),
          An.filter((x) => DEFAULT_DONORS.includes(x)),
          O.chain(An.fromArray)
        ),
      },
      donorKeys.map((x) => ({ value: x.donorId, label: x.donor }))
    );
  }, [donorKeys, actions, i18n]);

  const { chartData, mainLegendEntities } = React.useMemo(() => {
    const entities = pipe(
      selectedDonors,
      O.fold(
        () => [],
        (selected) => entityData.filter((x) => selected.includes(x.donorId))
      )
    );
    return {
      chartData: unsafeFromArray(entities.concat(averageData)),
      mainLegendEntities: unsafeFromArray(
        pipe(
          selectedDonors,
          O.map((selected) =>
            selected.map(
              (id) =>
                entityData.find(
                  (x) => x.donorId === id
                ) as unknown as typeof entityData[number]
            )
          ),
          O.fold(
            () => [],
            (entities) => entities.map((x) => x.donor)
          )
        )
      ),
    };
  }, [averageData, entityData, selectedDonors]);

  const getColumn = React.useCallback((x: Datum) => x.indicator_name, []);
  const getColor = React.useCallback((x: Datum) => x.donor, []);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    O.some(mainLegendEntities)
  );

  return (
    <>
      <ChartGrid
        data={chartData}
        getCell={getColumn}
        columnCount={3}
        minWidth={client.screenMDown ? 60 : undefined}
      >
        {({ data, width, first }) => {
          const averageValuesOnly = pipe(
            data,
            Ar.filter((x) => x.type === "average"),
            Ar.uniq(Eq.contramap(getColor)(Eq.eqString)),
            Ar.map((x) => ({
              label: getColor(x),
              color: M.grayscalePalette[5],
              style: "reference" as const,
            }))
          );
          return (
            <>
              <FixedWidthLineChart
                tLabel={(s) => s}
                height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
                width={width}
                endLabel={!client.screenMDown}
                x="year"
                yTicks={first ? [0, 0.007, 0.014] : [0, 0.5, 1]}
                yNice={0}
                timeParse="%Y"
                timeFormat="%Y"
                numberFormat={first ? ".2%" : ".0%"}
                category="datum.donor"
                values={data}
                colorLegend={false}
                colorScale={colorScale}
                showTooltip
                markStyle={(x) =>
                  x.type === "average" ? "reference" : "normal"
                }
              />
              <ColorLegend
                inline
                maxWidth={"100%"}
                values={averageValuesOnly}
              />
            </>
          );
        }}
      </ChartGrid>
      <div style={{ marginTop: 8 }}>
        <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
      </div>
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_AID_TARGETING.json"),
  csv: require("../data/data_finance_fig_AID_TARGETING.zip"),
  xlsx: require("../data/data_finance_fig_AID_TARGETING.xlsx"),
  metadata,
  Data,
  Chart,
});
