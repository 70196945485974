import React from "react";
import { tuple } from "../prelude";

export type ChartType = "today" | "timeseries";
export const DEFAULT_CHART_TYPE: ChartType = "today";

export function foldChartType<A>(
  chartType: ChartType,
  pattern: { [K in ChartType]: () => A }
) {
  return pattern[chartType]();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const HeroStateContext = React.createContext(["today", () => {}] as [
  ChartType,
  (x: ChartType) => void
]);

export const HeroStateProvider = ({
  children,
  initial = DEFAULT_CHART_TYPE,
}: {
  children: React.ReactNode;
  initial?: ChartType;
}) => {
  const [chartType, setChartType] = React.useState<ChartType>(initial);
  return (
    <HeroStateContext.Provider value={tuple(chartType, setChartType)}>
      {children}
    </HeroStateContext.Provider>
  );
};

export const useChartType = () => React.useContext(HeroStateContext);
