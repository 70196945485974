import { io } from "../prelude";

export const DEFAULT_RENDER_MODE: RenderMode = "default";

export const RenderMode = io.keyof({
  default: null,
  static: null,
  dropzone: null,
  embed: null,
});
export type RenderMode = io.TypeOf<typeof RenderMode>;

export function foldRenderMode<A>(pattern: { [K in RenderMode]: () => A }) {
  return (renderMode: RenderMode): A => pattern[renderMode]();
}

export interface ChartRenderer<A> {
  data: A;
  renderMode: RenderMode;
}
