import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { format } from "d3-format";
import React, { useCallback, useMemo } from "react";

import { TrajectoriesChartProps } from "charts/lib/Trajectories/Trajectories";
import { TrajectoriesChart } from "charts/TrajectoriesChart";
import { useReadScrolly } from "components/scrolly";
import {
  goalRule,
  xAxisTitleLookup,
  yAxisTitleLookup,
} from "domain/trajectories";
import { useI18n } from "locales";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_LMIC";
import { ChartRenderer, RiseDataDecoder, Series } from "../domain";
import { useTheme } from "../hooks";
import * as M from "../materials";
import { E, io, O, pipe } from "../prelude";

export * from "../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_LMIC";

// Scrollytelling steps
const Steps = io.union([
  io.literal("FADED"),
  io.literal("CURRENT"),
  io.literal("SIMULATION_ACCESS"),
  io.literal("SIMULATION_LEARNING"),
  io.literal("COMPARISON"),
  io.literal("default"),
]);
type Steps = io.TypeOf<typeof Steps>;

type StepProps<A> = Record<
  Steps,
  Partial<TrajectoriesChartProps<A>> &
    Pick<TrajectoriesChartProps<A>, "lineStyle">
>;

// Translation lookup
const seriesLabelsLookup: Record<Series, MessageDescriptor> = {
  CURRENT: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.label.current"
  )`Low-performing countries average`,
  SIMULATION_ACCESS: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.label.simulationAccess"
  )`Access simulation`,
  SIMULATION_LEARNING: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.label.simulationLearning"
  )`Learning simulation`,
  COMPARISON: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.label.comparison"
  )`High-performing countries average`,
};

const legendLookup: Record<Series, MessageDescriptor> = {
  CURRENT: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.legend.current"
  )`Current average of five higher-performing countries`,
  SIMULATION_ACCESS: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.legend.simulationAccess"
  )`Access simulation  (100% grade attainment * learning per grade of in-school children in low-performing countries)`,
  SIMULATION_LEARNING: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.legend.simulationLearning"
  )`Learning simulation (grade attainment in low-performing countries * learning per grade in higher-performing countries)`,
  COMPARISON: t(
    "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.legend.comparison"
  )`Current average of five low-performing countries`,
};

export const colors = {
  current: M.q07Palette[6],
  accessSimulation: M.q08Palette[5],
  learningSimulation: M.q01Palette[3],
  comparison: M.q03Palette[7],
};

export const makeLineStyler =
  (highlightedSeries: string | number | undefined) =>
  (series: string | number) => {
    const strokeWidth = 3;
    const strokeOpacity = highlightedSeries
      ? highlightedSeries === series
        ? 1
        : 0.1
      : 1;
    if (series === "CURRENT") {
      return {
        strokeOpacity,
        strokeWidth,
        stroke: colors.current,
      };
    }
    if (series === "SIMULATION_ACCESS") {
      return {
        strokeOpacity,
        strokeWidth,
        stroke: colors.accessSimulation,
        strokeDasharray: "6 12",
      };
    }
    if (series === "SIMULATION_LEARNING") {
      return {
        strokeOpacity,
        strokeWidth,
        stroke: colors.learningSimulation,
        strokeDasharray: "6 12",
      };
    }
    if (series === "COMPARISON") {
      return {
        strokeOpacity,
        strokeWidth,
        stroke: colors.comparison,
      };
    }
    return {
      strokeWidth,
      stroke: M.grayscalePalette[4],
    };
  };

export const Data = RiseDataDecoder(["value", "country", "year", "series"], {});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data, renderMode }: ChartRenderer<Data>) => {
  const { client } = useTheme();
  const { activeSection } = useReadScrolly();
  const i18n = useI18n();

  const activeSectionId = pipe(
    Steps.decode(O.toNullable(activeSection)),
    E.getOrElse(() => Steps.encode("default"))
  );

  const lineStyler = useMemo(() => makeLineStyler(undefined), []);

  const chartPropsAtScrollSteps: StepProps<Data[number]> = {
    FADED: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: 0.5,
      }),
      isInteractive: false,
    },
    CURRENT: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: series === "CURRENT" ? 1 : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) => series !== "CURRENT",
    },
    SIMULATION_ACCESS: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: ["CURRENT", "SIMULATION_ACCESS"].includes(
          series.toString()
        )
          ? 1
          : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) =>
        !["CURRENT", "SIMULATION_ACCESS"].includes(series.toString()),
    },
    SIMULATION_LEARNING: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: ["CURRENT", "SIMULATION_LEARNING"].includes(
          series.toString()
        )
          ? 1
          : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) =>
        !["CURRENT", "SIMULATION_LEARNING"].includes(series.toString()),
    },
    COMPARISON: {
      lineStyle: (series: string | number) => ({
        ...lineStyler(series),
        strokeOpacity: ["CURRENT", "COMPARISON"].includes(series.toString())
          ? 1
          : 0.5,
      }),
      lockActivePoint: data.find((d) => d.series === "CURRENT" && d.year === 9),
      backgroundSeriesFilter: (series: string | number) =>
        !["CURRENT", "COMPARISON"].includes(series.toString()),
    },
    default: {
      lineStyle: lineStyler,
    },
  };
  const seriesAccessor = useCallback((d) => d.series, []);
  const xAccessor = useCallback((d) => d.year, []);
  const yAccessor = useCallback((d) => d.value, []);

  return (
    <TrajectoriesChart<Data[number]>
      height={M.chartHeight.m}
      dataset={data}
      seriesAccessor={seriesAccessor}
      xAccessor={xAccessor}
      yAccessor={yAccessor}
      yAxisTitle={i18n._(yAxisTitleLookup["LIT"])}
      xAxisTitle={i18n._(xAxisTitleLookup["AGE"])}
      formatTooltipLabel={(d) =>
        i18n._(seriesLabelsLookup[d.series as Series]) || ""
      }
      formatTooltipValue={(d) =>
        d.value === null
          ? t("fig.label.data.not.available")`Not Available`
          : format(".0%")(d.value)
      }
      formatLegendLabel={(series) =>
        i18n._(legendLookup[series as Series]) || ""
      }
      rules={renderMode === "embed" ? [] : [goalRule(i18n, "AGE", "LIT")]}
      showColorLegend={true}
      legendValues={[
        "COMPARISON",
        "SIMULATION_LEARNING",
        "SIMULATION_ACCESS",
        "CURRENT",
      ]}
      flipTooltip={client.screenSDown}
      {...chartPropsAtScrollSteps[activeSectionId]}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_LMIC.json"),
  csv: require("../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_LMIC.zip"),
  xlsx: require("../data/data_learning_trajectories_fig_TRAJECTORIES_SIMULATION_LMIC.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
