import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "ELECTRIC",
  title: /*i18n*/ {
    id: "fig.quality.ELECTRIC.title",
    defaults: `Availability of electricity and internet for pedagogical purposes in schools in selected countries`,
  },
  caption: /*i18n*/ {
    id: "fig.quality.ELECTRIC.caption",
    defaults: `The availability of internet at school often lags far behind electrification.`,
  },
  source: /*i18n*/ {
    id: "fig.quality.ELECTRIC.source",
    defaults: `UIS database`,
  },
};
