import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "PIRLSGAIN",
  title: /*i18n*/ {
    id: "fig.learning.PIRLSGAIN.title",
    defaults: `Percentage of Grade 8 students above the TIMSS Intermediate International Benchmark`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.PIRLSGAIN.caption",
    defaults: `In many countries, progress in improving learning is slow or even stagnant, but rapid progress is possible.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.PIRLSGAIN.source",
    defaults: `TIMSS surveys`,
  },
};
