import {
  DecoderError,
  FetchException,
  FileReaderAbort,
  FileReaderError,
  FileReaderReject,
  InvariantViolation,
} from "../lib";
import { MkPattern } from "../lib/type-utils";

export type Exception =
  | InvariantViolation
  | FetchException
  | FileReaderAbort
  | FileReaderError
  | FileReaderReject
  | DecoderError;

export function foldException<A>(
  e: Exception,
  pattern: MkPattern<Exception, "type", A>
): A {
  return (pattern[e.type] as $Unexpressable)(e);
}
