import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { constant } from "fp-ts/lib/function";
import useFormat from "hooks/useFormat";
import React from "react";
import { StackedBarAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { useConfig } from "../config";
import { metadata } from "../data/data_access_fig_OVERAGE";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  getCountryName,
  mkGemDataDecoder,
  useFigureControlItems,
  useSingleSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { mkScrollyColorPalette } from "../materials";
import { An, Ar, io, O, Ord, pipe, tuple } from "../prelude";

export * from "../data/data_access_fig_OVERAGE";

const DEFAULT_COUNTRY = "PAK";

const attendKeyOrd = {
  "attend.preschool": 0,
  "attend.primary": 1,
  "attend.secondary": 2,
  "attend.higher": 3,
  "no.attend": 4,
};

const AttendKey = io.keyof(attendKeyOrd);
type AttendKey = io.TypeOf<typeof AttendKey>;

export const Data = mkGemDataDecoder(["iso3c", "year"], {
  age: io.number,
  ind_id: AttendKey,
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

const getAttendKeyIndex = (k: AttendKey) => attendKeyOrd[k];
const attendKeyLookup: Record<AttendKey, MessageDescriptor> = {
  "attend.preschool": t("fig.access.OVERAGE.attend.preschool")`Pre-primary`,
  "attend.primary": t("fig.access.OVERAGE.attend.primary")`Primary`,
  "attend.secondary": t("fig.access.OVERAGE.attend.secondary")`Secondary`,
  "attend.higher": t("fig.access.OVERAGE.attend.higher")`Higher`,
  "no.attend": t("fig.access.OVERAGE.no.attend")`Out of school`,
};

export const Chart = React.memo(({ data }: ChartRenderer<Data>) => {
  const { client } = useTheme();
  const i18n = useI18n();
  const env = useConfig();
  const [selectedCountry, actions] = useSingleSelectState("country");
  const { activeSection, sections } = useReadScrolly();

  const scrollyCountry = React.useMemo(
    () =>
      pipe(
        activeSection,
        O.chain((strId) =>
          getCountryMeta(env.countries, countryIdIso.wrap(strId))
        ),
        O.map((meta) => meta.iso3c),
        O.getOrElse(() => countryIdIso.wrap(DEFAULT_COUNTRY))
      ),
    [env.countries, activeSection]
  );

  const sortedData = React.useMemo(() => {
    const ordDimensions = Ord.getTupleOrd(
      Ord.ordString,
      Ord.ordNumber,
      Ord.ordNumber
    );
    const ord = Ord.contramap((x: Data[number]) =>
      tuple(countryIdIso.unwrap(x.iso3c), x.age, getAttendKeyIndex(x.ind_id))
    )(ordDimensions);
    return Ar.sort(ord)(data);
  }, [data]);

  const countryControlItems = useFigureControlItems(
    sortedData,
    (x) => countryIdIso.unwrap(x.iso3c),
    (x) => getCountryName(env.countries, x.iso3c)
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "country",
      {
        type: "SingleSelect",
        selected: O.some(DEFAULT_COUNTRY),
      },
      countryControlItems
    );
  }, [actions, countryControlItems]);

  React.useEffect(() => {
    if (O.isSome(activeSection)) {
      actions.updateSelectionControl(
        "country",
        O.some(countryIdIso.unwrap(scrollyCountry))
      );
    }
  }, [actions, activeSection, scrollyCountry]);

  const chartData = React.useMemo(() => {
    return sortedData.filter(({ iso3c }) =>
      pipe(
        selectedCountry,
        O.fold(constant(false), (x) => x === countryIdIso.unwrap(iso3c))
      )
    );
  }, [sortedData, selectedCountry]);
  type Datum = typeof chartData[number];

  const annotations = React.useMemo(() => {
    return pipe(
      selectedCountry,
      O.chain((strId) =>
        getCountryMeta(env.countries, countryIdIso.wrap(strId))
      ),
      O.map((meta) => ({
        label: i18n._(
          t("fig.access.OVERAGE.level_ages_label")`Theoretical age range for:`
        ),
        items: [
          {
            layer: "attend.primary",
            startStack: `${meta.level_ages[0]}`,
            endStack: `${meta.level_ages[1]}`,
          },
          {
            layer: "attend.secondary",
            startStack: `${meta.level_ages[1]}`,
            endStack: `${meta.level_ages[2]}`,
          },
        ],
      })),
      O.toUndefined
    );
  }, [i18n, env.countries, selectedCountry]);

  const currentPalette = React.useMemo(() => {
    const countryKeys = sections.size > 0 ? Array.from(sections.values()) : [];
    return mkScrollyColorPalette(countryKeys, selectedCountry);
  }, [sections, selectedCountry]);

  const getStack = React.useCallback((x: Datum) => `${x.age}`, []);
  const getLayer = React.useCallback((x: Datum) => `${x.ind_id}`, []);
  const getLayerLabel = React.useCallback(
    (x: string) => i18n._(attendKeyLookup[x as AttendKey]) || x,
    [i18n]
  );
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const formatX = React.useCallback(
    (stack, i) =>
      i === 0 ? (client.screenMDown ? `${stack}y` : `${stack} years`) : stack,
    [client.screenMDown]
  );
  const formatY = useFormat(".0%");
  const getColorPalette = React.useCallback(
    (count) =>
      M.fromCount(currentPalette, count - 1).concat(M.grayscalePalette[3]),
    [currentPalette]
  );

  return pipe(
    An.fromArray(chartData),
    O.map((xs) => (
      <StackedBarAuto
        key={"linter-map"}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        data={xs}
        getStack={getStack}
        getLayer={getLayer}
        getLayerLabel={getLayerLabel}
        getValue={getValue}
        formatX={formatX}
        formatY={formatY}
        colorPalette={getColorPalette}
        showTooltip
        annotations={annotations}
      />
    )),
    O.toNullable
  );
});

export default withFigureIO({
  url: require("../data/data_access_fig_OVERAGE.json"),
  csv: require("../data/data_access_fig_OVERAGE.zip"),
  xlsx: require("../data/data_access_fig_OVERAGE.xlsx"),
  metadata,
  Data,
  Chart,
});
