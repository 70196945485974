
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Lernen";
export const lead = "In den meisten Ländern Subsahara-Afrikas erlangen über die Hälfte der Schülerinnen und Schüler in den unteren Klassenstufen weniger als Mindestlesekompetenzen.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Es reicht nicht aus, Kinder in die Schule zu bringen. Ohne Abstriche bei den vielen anderen erstrebenswerten Zwecken, denen Schulen dienen und die sie verfolgen müssen, ist es notwendig, dass Schülerinnen und Schüler in der Lage sind, zu lesen und Grundlagen der Mathematik zu beherrschen. Sie bilden das Fundament für weiteres Lernen, für persönliche Befähigung und Wohlergehen, sowie für soziale, politische und ökonomische Erfolge.`}</p>
    </blockquote>
    <h2 {...{
      "id": "kinder-und-jugendliche"
    }}>{`Kinder und Jugendliche`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`Viele Kinder in einigen der ärmsten Länder der Welt verfügen nicht einmal über Grundkompetenzen in Lesen und Mathematik. Lesebewertungen in den unteren Klassenstufen in den nördlichen Bundesstaaten von Nigeria, wie Kaduna und Sokoto, sowie in Provinzen der Demokratischen Republik Kongo, zum Beispiel Équateur und Katanga, legen nahe, dass über 80% der Schülerinnen und Schüler der dritten Klassenstufe der Grundschule `}<strong parentName="p">{`kein einziges Wort`}</strong>{` eines zusammenhängenden Textes lesen können.`}</p>
      <Figure id="EGRA" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Diese Daten vernachlässigen die Kinder, die nicht zur Schule gehen. In Haushalten durchgeführte Befragungen können sowohl die Fähigkeiten von Kindern, die zur Schule gehen, als auch von Kindern, die keine Schule besuchen, erfassen.`}</p>
    <p>{`Die folgenden Abbildungen untersuchen beispielsweise verschiedene Regionen in Indien und Pakistan und basieren auf von Bürgerinnen und Bürgern geführten Bewertungen.`}</p>
    <p>{`Die roten Kurven zeigen den Anteil der Kinder `}<strong parentName="p">{`in der Schule`}</strong>{` nach Klassenstufe an, die über Grundlesekompetenzen verfügen, während die blauen Kurven den Anteil aller Kinder `}<strong parentName="p">{`nach dem ihrer Klassenstufe entsprechenden Alter`}</strong>{` darlegen, die über Grundlesekompetenzen verfügen, `}<strong parentName="p">{`unabhängig davon, ob sie eine Schule besuchen oder nicht`}</strong>{`.`}</p>
    <p>{`Die Differenzen zwischen den beiden Kurven führen uns erneut vor Augen, dass politische Entscheidungsträger in Ländern mit hohen Raten an Kindern, die keine Schule besuchen, nur einen Teil des Ganzen erfassen, wenn sie lediglich das Lernen derjenigen messen, die zur Schule gehen. Es ist wichtig festzuhalten, dass das gemessene Kompetenzniveau sehr niedrig ist. Selbst in den leistungsstärksten Regionen können zumindest einige Jugendliche an der Sekundarschule einen Text der Klassenstufe 2 nicht lesen.`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Es wird geschätzt, dass knapp 40% der Kinder im Grundschulalter nicht über Grundlesekompetenzen verfügen. Dies trifft auf 43% der Kinder in Zentral- und Südasien und 84% der Kinder in Subsahara-Afrika zu, aber nur auf 3% der Kinder in Europa und Nordamerika.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.1.0" label="4.1.0" mdxType="IndicatorReference">
      <p>{`In vielen Ländern gibt es keine Garantie, dass Kinder lesen können, selbst wenn sie die Grundschule abgeschlossen haben. Weltweit waren von allen, die das Äquivalent einer Grundschulbildung abschlossen, vier von zehn Kindern nicht in der Lage, einen einfachen Satz zu lesen. Dieser Anteil steigt in Subsahara-Afrika auf acht von zehn Kindern, wie die Abbildung unten zeigt.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Die `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`grauen Bereiche`}</HighlightByIndex>{` stehen für den Anteil der Kinder, die die Grundschule abschließen, aber kein Mindestniveau an Lesekompetenzen erreichen. `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Rot dargestellt`}</HighlightByIndex>{` sind diejenigen Kinder, die die Grundschule nicht abschließen, und die `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`blauen Bereiche`}</HighlightByIndex>{` zeigen diejenigen Schülerinnen und Schüler an, die die Grundschule abschließen und Mindestkompetenzen erlangen.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Ein Blick auf Länderebene zeigt, dass in Tschad und Nigeria etwa 90% der jungen Menschen, die die Schule nach 5 oder 6 Jahren verließen, keine Lese- und Schreibfähigkeiten aufwiesen. In einigen Ländern ist der Anteil jedoch viel besser. So verfügten beispielsweise in Burundi, Costa Rica und Honduras fast alle, die die Schule nach der 5. oder 6. Klassenstufe verließen, über Lese- und Schreibfähigkeiten.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="LITBYYRS" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="KEN" mdxType="ScrollySection">
        <p>{`In `}<strong parentName="p">{`Kenia`}</strong>{` zum Beispiel verfügt nach `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`bis zu 4 Jahren Schule`}</HighlightByIndex>{` nur ein Viertel der Schülerinnen und Schüler über Lese- und Schreibfähigkeiten, ihr Anteil erhöht sich jedoch sprunghaft auf drei von vier bei denjenigen, die `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`5-6 Jahre Schulbildung`}</HighlightByIndex>{` genossen haben – was einer Grundschulbildung entspricht.`}</p>
      </ScrollySection>
      <ScrollySection id="NER" mdxType="ScrollySection">
        <p>{`In `}<strong parentName="p">{`Niger`}</strong>{` ist kaum ein Kind `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`nach 4 Jahren Schulbildung`}</HighlightByIndex>{` fähig zu lesen, doch ein Drittel der Kinder entwickelt Lese- und Schreibgrundkompetenzen, wenn sie die `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`gesamte Grundschulbildung`}</HighlightByIndex>{` durchlaufen.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <p>{`Um Erlerntes zwischen den Ländern zu vergleichen, müssen Bewertungen oder Tests an internationale Standards angepasst werden und eine gemeinsame Definition anwenden. Solche Standards wurden kürzlich für länderübergreifende Bewertungen eingeführt, bei denen Lernende aus mehreren Ländern denselben Test ablegen. Doch für nationale Bewertungen sind noch weniger Mappings dieser Art verfügbar.`}</p>
    <p>{`Eine Bewertung – die TIMSS-Studie (Trends in International Mathematics and Science Study) – wird alle vier Jahre durchgeführt und ermöglicht uns die Einschätzung der Fortschritte von Viert- und Achtklässlern im Lauf der Zeit. Sie zeigt, wo sich die Entwicklung besonders langsam vollzieht. In Ägypten stieg beispielsweise der Anteil der Schülerinnen und Schüler in der 8. Klassenstufe, welche Mindestrechenkompetenzen erreichten, zwischen 2003 und 2019 von 24% auf nur 27%,.`}</p>
    <p>{`In einigen Ländern, wie `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Malaysia`}</HighlightByIndex>{`, ist sogar der Anteil der Schülerinnen und Schüler, die Mindestniveaus erreichen, im Lauf der Zeit gesunken.`}</p>
    <p>{`In anderen Ländern, wie `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Bahrain`}</HighlightByIndex>{`, wurde rasche Fortschritte erzielt – der Anteil an Schülerinnen und Schülern mit Mindestkompetenzen hat sich hier von 2003 bis 2019 mehr als verdreifacht.`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Entgegen der Erwartungen der meisten Menschen sind die geschlechterspezifischen Unterschiede bei den Lernergebnissen nicht sehr groß – in der Grundschulbildung beispielsweise übersteigen sie in kaum einem Land zehn Prozentpunkte.`}</p>
      <p>{`Doch die Unterscheide zwischen den Ländern sind enorm. In den Ländern Subsahara-Afrikas, die in den unteren Bereich der Lesekompetenzen in der Grundschulbildung fallen, reicht der Anteil an Lernenden mit Mindestkompetenzen von nur 4% in Burundi bis zu 76% in Gabun (beide Ergebnisse stammen aus der PASEC-Studie 2019.`}</p>
      <p>{`In lateinamerikanischen Ländern reichen die Ergebnisse in Mathematik in der Grundschulbildung von nur 2% in der Dominikanischen Republik bis zu 39% in Peru (beide Ergebnisse stammen aus der LLECE-Studie 2019).`}</p>
      <Figure id="ASSESS" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "erwachsene"
    }}>{`Erwachsene`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`Zwischen 2000 und 2020 stieg weltweit der Anteil der Erwachsenen mit Lese- und Schreibfähigkeiten von 81% auf 87%, bei den Jugendlichen von 87% auf 92%.`}</p>
      <p>{`Trotz dieser Verbesserungen bleibt Analphabetismus in vielen Ländern – und besonders bei Frauen – weit verbreitet. In Lateinamerika und der Karibik spiegelt der Stillstand auf relativ hohem Niveau stark benachteiligte Gruppen wider, die schwer zu erreichen sind. So hat sich beispielsweise in Paraguay die Alphabetisierungsrate von älteren Frauen in den vergangenen 10 Jahren kaum verändert und liegt bei etwa 73%.`}</p>
      <p>{`In 18 Ländern Subsahara-Afrikas kann über die Hälfte der erwachsenen Frauen (im Alter von 25-64 Jahren) nicht lesen. Die Entwicklung ist entweder auf sehr niedrigem Niveau zum Erliegen gekommen, wie in Mali, wo weniger als 17% der Frauen lesen können, oder sie vollzieht sich langsam, wie in Kongo, wo sich der Anteil im Lauf von zehn Jahren von 72% auf 74% verbesserte.`}</p>
      <p>{`Wenn heute weltweit mehr Erwachsene Lese-, und Schreibkompetenzen haben als früher, liegt dies hauptsächlich daran, dass mehr Erwachsene in ihrer Jugend zur Schule gingen, und ist nicht darin begründet, dass Erwachsene, die keine Schule besuchten, eine zweite Chance bekamen. Zum Nachweis dieser Behauptung zeigt die Abbildung unten Daten, welche die Lese- und Schreibfähigkeiten einer bestimmten Kohorte – oder Gruppe – mit zunehmendem Alter darstellen.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LITCOHORT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In den meisten Ländern stagnieren Lese- und Schreibfähigkeiten innerhalb einer bestimmten `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Kohorte von Erwachsenen`}</HighlightByIndex>{` oder nehmen sogar im Lauf der Zeit ab, da sie nicht genutzt werden. `}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In `}<strong parentName="p">{`Haiti`}</strong>{` zeigt die blaue Kurve, dass sich die Alphabetisierungsrate von `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Frauen, die im Jahr 2000 20-34 Jahre alt waren`}</HighlightByIndex>{`,  `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`2006 26-40 Jahre alt`}</HighlightByIndex>{`  und `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`2017 37–61 Jahre alt`}</HighlightByIndex>{` waren leicht gesunken ist. Dennoch weist die rote Kurve darauf hin, dass die `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Alphabetisierungsrate von Frauen im Alter von 20-34 Jahren`}</HighlightByIndex>{`  im Lauf der Zeit steigt, da junge Frauen lesen und schreiben lernen.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`Die bei diesen Bewertungen gemessenen Lese- und Schreibfähigkeiten sind Grundkompetenzen. Sie zeigen, ob Menschen in der Lage sind, einen einfachen Satz zu lesen. Funktionale Alphabetisierung – also die Fähigkeit, schriftliche Anweisungen, formale Briefe oder Verträge zu lesen und zu interpretieren – ist etwas ganz anderes.`}</p>
      <p>{`Nur wenige internationale Erhebungen sammeln überhaupt Daten zu funktionaler Alphabetisierung und regelmäßige Messungen dazu erfolgen noch viel seltener.`}</p>
      <p>{`Aus den Daten, die uns vorliegen, können wir ablesen, dass mindestens jeder 10. Erwachsene in Städten in Kolumbien und Vietnam und jeder 4. in Bolivien nicht über funktionale Alphabetisierung verfügen (d.h. sie fallen unter Kompetenzniveau 1).`}</p>
      <p>{`In `}<strong parentName="p">{`Ghana`}</strong>{` weisen selbst von den Erwachsenen in Städten nur 39% Kompetenzen der funktionalen Alphabetisierung auf.`}</p>
      <Figure id="LITPROF" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "schlussbemerkung"
    }}>{`Schlussbemerkung`}</h2>
    <p>{`Wenngleich SDG 4 den Schwerpunkt auf die Messung von Erlerntem verlagert hat, legen viele Länder nach wie vor keine Zahlen zu Lernergebnisindikatoren in Lesen und Mathematik vor. Selbst Länder, die mit der Bewertung und Berichtslegung zum Lernen beginnen möchten, sind häufig mit Hindernissen konfrontiert, die sie davon abhalten, effektive und kosteneffiziente Lösungen einzuführen. Doch die Belege, die uns vorliegen, legen nahe, dass die meisten Schülerinnen und Schüler in den ärmsten Ländern der Welt keine Mindestkompetenzen im Bereich der einfachsten Grundfähigkeiten erreichen.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://openknowledge.worldbank.org/handle/10986/32553"
            }}>{`Ending learning poverty – what will it take? – World Bank`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
            }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://unesdoc.unesco.org/ark:/48223/pf0000225660"
            }}>{`Weltbericht Bildung für alle 2013/2014: Lehren und Lernen: Qualität für alle ermöglichen`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.unesco.de/publikationen?page=10#row-534"
            }}>{`The learning assessment market - pointers for countries`}</a></p>
        </li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;