import * as React from "react";

function SvgIcClose16Dp(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <path d="M2.706 2.705l10.588 10.58M2.706 13.295l10.588-10.58" />
      </g>
    </svg>
  );
}

export default SvgIcClose16Dp;
