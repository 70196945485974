import { max, min } from "d3-array";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { timeParse } from "d3-time-format";
import { constant } from "fp-ts/lib/function";
import React from "react";
import { invertSparseArray } from "../../lib";
import * as M from "../../materials";
import { fontChartLabel as VALUE_FONT } from "../../materials/typography/fonts";
import { Ar, Eq, pipe } from "../../prelude";
import { createTextGauger } from "./textGauger";
import { foldMarkStyle } from "./types";
import {
  calculateAxis,
  deduplicate,
  groupBy,
  runSort,
  subsup,
  unsafeDatumFn,
} from "./utils";

const COLUMN_TITLE_HEIGHT = 24;
const AXIS_TOP_HEIGHT = 24;
const AXIS_BOTTOM_HEIGHT = 24;

export const Y_CONNECTOR = 6;
export const Y_CONNECTOR_PADDING = 4;
// const Y_END_LABEL_SPACE = 3; // width of space between label and value

const valueGauger = createTextGauger(VALUE_FONT, {
  dimension: "width",
  html: true,
});
// const labelGauger = createTextGauger(LABEL_FONT, {
//   dimension: "width",
//   html: true
// });

const mkLineColor = (markStyle, fallback) =>
  foldMarkStyle(markStyle, {
    normal: fallback,
    muted: fallback,
    reference: () => M.grayscalePalette[5],
  });

const useLayout = (props) => {
  const { values, mini, yAnnotations } = props;
  let data = values;
  if (props.filter) {
    const filter = unsafeDatumFn(props.filter);
    data = data.filter(filter);
  }
  let xParser = (x) => x;
  if (props.xScale === "time") {
    xParser = timeParse(props.timeParse);
  }
  data = data.map((d) => ({
    datum: d,
    x: xParser(d[props.x]),
    value: d.value,
  }));
  if (props.category) {
    const categorize = unsafeDatumFn(props.category);
    data.forEach((d) => {
      d.category = categorize(d.datum);
    });
  }
  const xAccessor = React.useMemo(() => (d) => d.x, []);
  runSort(props.xSort, data, xAccessor);

  let groupedData;
  if (props.columnFilter) {
    groupedData = props.columnFilter.map(({ test, title }) => {
      const filter = unsafeDatumFn(test);
      return {
        key: title,
        values: data.filter((d) => filter(d.datum)),
      };
    });
    data = groupedData.reduce((all, group) => all.concat(group.values), []);
  } else {
    groupedData = groupBy(data, (d) => d.datum[props.column]);
  }
  const lineGroup = props.category
    ? (d) => d.category
    : (d) => d.datum[props.color];

  const paddingTop =
    AXIS_TOP_HEIGHT +
    (props.column || props.columnFilter ? COLUMN_TITLE_HEIGHT : 0);
  const paddingBottom = AXIS_BOTTOM_HEIGHT;
  const innerHeight = mini
    ? props.height - paddingTop - paddingBottom
    : props.height;
  const columnHeight = innerHeight + paddingTop + paddingBottom;

  let yValues = props.yTicks ? props.yTicks : data.map((d) => d.value);
  if (yAnnotations) {
    yValues = yValues.concat(yAnnotations.map((d) => d.value));
  }
  const minValue = min(yValues);
  const y = scaleLinear()
    .domain([props.zero ? Math.min(0, minValue) : minValue, max(yValues)])
    .nice(props.yNice)
    .range([innerHeight + paddingTop, paddingTop]);
  const colorAccessor = props.color
    ? (d) => d.datum[props.color]
    : (d) => d.category;

  const colorRange = props.colorScale
    ? props.colorScale
    : M.colorRanges[props.colorRange] ||
      props.colorRange ||
      M.colorRanges.discrete;

  const colorValues = props.colorScale
    ? props.colorScale.range()
    : [].concat(data.map(colorAccessor)).filter(deduplicate).filter(Boolean);

  const color =
    props.colorScale || scaleOrdinal(colorRange).domain(colorValues);

  const { unit, tLabel } = props;
  const yAxis = calculateAxis(props.numberFormat, tLabel, y.domain(), unit);
  const { format: yFormat } = yAxis;

  const startValue = !mini && props.startValue;
  const endLabel = props.endLabel && colorValues.length > 0;

  let startValueSizes = [];
  let endValueSizes = [];

  const mkMarkStyle = props.markStyle ? props.markStyle : constant("normal");
  const mkIsFaded = props.isFaded ? props.isFaded : constant(false);

  const labelFilter = props.labelFilter
    ? unsafeDatumFn(props.labelFilter)
    : () => true;
  groupedData = groupedData
    .map(({ values, key }) => ({
      key,
      values: groupBy(values, lineGroup),
    }))
    .map(({ values: lines, key }) => {
      const linesWithLabels = lines
        .filter(({ values: line }) => line.some((d) => d.value !== null))
        .map(({ values: line }) => {
          const start = line[0];
          let end = line[line.length - 1];
          // Find the latest data point with a value
          for (let i = line.length - 1; i >= 0; --i) {
            if (line[i].value !== null) {
              end = line[i];
              break;
            }
          }

          const label = labelFilter(start.datum);
          const markStyle = mkMarkStyle(start.datum);
          const isFaded = mkIsFaded(start.datum);

          return {
            line,
            // We only compute this when requested because it's expensive
            lineDefined: props.connectLines ? invertSparseArray(line) : line,
            start,
            end,
            isFaded,
            markStyle,
            lineColor: mkLineColor(markStyle, () =>
              color(colorAccessor(start))
            ),
            startValue: label && startValue && yFormat(start.value),
            endValue: label && yFormat(end.value),
            endLabel,
          };
        });

      if (startValue) {
        startValueSizes = startValueSizes.concat(
          linesWithLabels.map((line) =>
            line.startValue ? valueGauger(line.startValue) : 0
          )
        );
      }
      if (endLabel) {
        endValueSizes = endValueSizes.concat(
          linesWithLabels.map((line) =>
            line.endValue ? valueGauger(line.endValue) : 0
          )
        );
      }

      return {
        key,
        values: [
          ...linesWithLabels.filter((line) =>
            foldMarkStyle(line.markStyle, {
              normal: () => false,
              muted: () => true,
              reference: () => false,
            })
          ),
          ...linesWithLabels.filter((line) =>
            foldMarkStyle(line.markStyle, {
              normal: () => false,
              muted: () => false,
              reference: () => true,
            })
          ),
          ...linesWithLabels.filter((line) =>
            foldMarkStyle(line.markStyle, {
              normal: () => true,
              muted: () => false,
              reference: () => false,
            })
          ),
        ],
      };
    });

  let colorLegend = !mini && colorValues.length > 0;
  let paddingLeft = 0;
  let paddingRight = 0;

  const whiteSpacePadding =
    groupedData.length > 1 && props.columns > 1 ? 15 : 0;

  if (!mini || endLabel) {
    const yConnectorSize = Y_CONNECTOR + Y_CONNECTOR_PADDING * 2;
    const startValueSize = startValue
      ? Math.ceil(max(startValueSizes) + yConnectorSize)
      : 0;
    const endValueSize = Math.ceil((max(endValueSizes) || 0) + yConnectorSize);
    if (startValue) {
      paddingLeft = paddingRight =
        Math.max(startValueSize, endValueSize) + whiteSpacePadding;
    } else {
      paddingRight = endValueSize + whiteSpacePadding;
    }
    if (props.paddingRight !== undefined) {
      paddingRight = props.paddingRight + whiteSpacePadding;
    }
    if (props.paddingLeft !== undefined) {
      paddingLeft = props.paddingLeft + whiteSpacePadding;
    }
  }

  const colorValuesForLegend = pipe(
    data.filter((d) => labelFilter(d.datum)),
    Ar.uniq(Eq.contramap(colorAccessor)(Eq.eqString))
  );

  const colorLegendValues = colorValuesForLegend.map((value) => {
    const style = mkMarkStyle(value.datum);
    return {
      color: mkLineColor(style, () => color(colorAccessor(value))),
      label: subsup(colorAccessor(value)),
      style,
    };
  });

  const translatedYAnnotations = (yAnnotations || []).map((d) => ({
    formattedValue: yFormat(d.value),
    ...d,
    label: d.label,
    x: d.x ? xParser(d.x) : undefined,
  }));

  return {
    data,
    groupedData,
    xParser,
    xAccessor,
    y,
    yAxis,
    yAnnotations: translatedYAnnotations,
    yFormat,
    colorLegend,
    colorLegendValues,
    paddingLeft,
    paddingRight,
    columnHeight,
  };
};

export default useLayout;
