
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Template - Many families have trouble repaying student debt";
export const lead = "Student loans have become an increasingly popular instrument to promote affordable access to tertiary education. They are currently available in over 70 countries, to varying degrees. The average share of tertiary students receiving a public or government-sponsored student loan in 19 mostly high-income countries was 40%, ranging from 1% in Switzerland to 42% in South Africa and 84% in New Zealand";
export const layout = "focus";
export const article = "finance";
export const year = 2017;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusTitle = makeShortcode("FocusTitle");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="test" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=272" mdxType="FocusDetails">
      <FocusTitle mdxType="FocusTitle">Template Focus Article</FocusTitle>
      <p>{`Student loans have become an increasingly popular instrument to promote affordable
access to tertiary education. They are currently available in over 70 countries,
to varying degrees. The average share of tertiary students receiving a public or
government-sponsored student loan in 19 mostly high-income countries was 40%, ranging
from 1% in Switzerland to 42% in South Africa and 84% in New Zealand.`}</p>
      <FocusImage size="small" src={"0-fig_1.png"} alt="Almost all students receive a loan in some countries, almost none in others" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=272" mdxType="FocusImage" />
      <p>{`Often, however, student loans fall short of their promises of increased access and affordability. In Brazil, of the 2.2 million new student loans issued between 2009 and 2015, fewer than half translated into new enrolments; the majority of new loans were taken by students who were already enrolled or would have enrolled anyway (Brazil Ministry of Economy, 2017). In the United States, much of the increase in national student debt fed tuition spikes rather than new enrolments (Lucca et al., 2017).Student loans may also have different uptake rates by population group or programme type. Studies show that the risk-averse, who may be over-represented in some ethnic or immigrant groups or among poorer households, are less likely to take on student loans (Boatman et al., 2017). In Japan, debt aversion in poorer families diverts students from general to vocational high schools, hindering their prospect of entering tertiary education (Furuta, 2021).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=272"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;