import React from "react";
import {
  ButtonGroupControl,
  CheckboxControl,
  FigureControlItem,
  FigureControlPosition,
  foldFigureControl,
  lookupFigureControlItems,
  MultiCheckboxControl,
  MultiSelectControl,
  RadioControl,
  SingleSelectControl,
  TimelineControl,
  useFigureState,
} from "../domain";
import { An, O, pipe, R } from "../prelude";
import { ButtonGroupAuto } from "./button";
import { Checkbox } from "./checkbox";
import { ControlBox, Divider } from "./figure";
import MultiCheckbox from "./multi-checkbox";
import { MultiSelect } from "./multi-select";
import RadioSelectBox from "./radio-select";
import { Select } from "./single-select";
import { Timeline } from "./timeline";

export function FigureControls({
  showDivider = true,
  position = "TOP",
}: {
  showDivider?: boolean;
  position?: FigureControlPosition;
}) {
  const [state, actions] = useFigureState();

  return (
    <>
      {pipe(
        state.controls,
        O.map((controls) => (
          <>
            {showDivider && <Divider />}
            <ControlBox
              orientation={
                position === "TOP" || position === "TOOLBAR"
                  ? "horizontal"
                  : "vertical"
              }
            >
              {R.toArray(controls).map(([id, control_]) => {
                if (control_.position !== position) return null;

                return foldFigureControl(control_, {
                  ButtonGroup: (control) =>
                    pipe(
                      lookupFigureControlItems(id)(state.controlItems),
                      O.map((items) => (
                        <ButtonGroupAdapter
                          key={id}
                          control={control}
                          controlItems={items}
                          onChange={(selected: string) =>
                            actions.updateSelectionControl(id, selected)
                          }
                        />
                      )),
                      O.toNullable
                    ),
                  Checkbox: (control) => (
                    <CheckboxAdapter
                      key={id}
                      control={control}
                      onChange={(selected: boolean) =>
                        actions.updateToggleControl(id, selected)
                      }
                    />
                  ),
                  SingleSelect: (control) =>
                    pipe(
                      lookupFigureControlItems(id)(state.controlItems),
                      O.map((items) => (
                        <SingleSelectAdapter
                          key={id}
                          control={control}
                          controlItems={items}
                          onChange={(selected: string) =>
                            actions.updateSelectionControl(id, O.some(selected))
                          }
                        />
                      )),
                      O.toNullable
                    ),
                  MultiSelect: (control) =>
                    pipe(
                      lookupFigureControlItems(id)(state.controlItems),
                      O.map((items) => (
                        <MultiSelectAdapter
                          key={id}
                          control={control}
                          controlItems={items}
                          onChange={(items: Array<FigureControlItem>) =>
                            actions.setMultiSelectItems(id, items)
                          }
                          isInline={
                            position === "TOP" || position === "TOOLBAR"
                          }
                        />
                      )),
                      O.toNullable
                    ),
                  Timeline: (control) => (
                    <TimelineAdapter
                      key={id}
                      control={control}
                      onChange={(selected: number) =>
                        actions.updateToggleControl(id, selected)
                      }
                    />
                  ),
                  Radio: (control) =>
                    pipe(
                      lookupFigureControlItems(id)(state.controlItems),
                      O.map((items) => (
                        <RadioSelectBoxAdapter
                          key={id}
                          control={control}
                          controlItems={items}
                          onChange={(selected: string) =>
                            actions.updateSelectionControl(id, O.some(selected))
                          }
                        />
                      )),
                      O.toNullable
                    ),
                  MultiCheckbox: (control) =>
                    pipe(
                      lookupFigureControlItems(id)(state.controlItems),
                      O.map((items) => (
                        <MultiCheckboxAdapter
                          key={id}
                          control={control}
                          controlItems={items}
                          onChange={(items: Array<FigureControlItem>) =>
                            actions.setMultiCheckboxItems(id, items)
                          }
                        />
                      )),
                      O.toNullable
                    ),
                });
              })}
            </ControlBox>
          </>
        )),
        O.toNullable
      )}
    </>
  );
}

// -----------------------------------------------------------------------------
// Adapters

export function ButtonGroupAdapter({
  control: { selected },
  controlItems,
  onChange,
}: {
  control: ButtonGroupControl;
  controlItems: An.NonEmptyArray<FigureControlItem>;
  onChange: (selected: string) => void;
}) {
  const handler = React.useCallback(
    (x) => onChange(x.value),
    [] // eslint-disable-line
  );
  return (
    <ButtonGroupAuto
      items={controlItems}
      selectedItem={selected}
      onChange={handler}
    />
  );
}

export function CheckboxAdapter({
  control: { selected, label },
  onChange,
}: {
  control: CheckboxControl;
  onChange: (selected: boolean) => void;
}) {
  const handler = React.useCallback(
    (checked) => onChange(checked),
    [] // eslint-disable-line
  );
  return <Checkbox label={label} checked={selected} onChange={handler} />;
}

export function SingleSelectAdapter({
  control: { selected, title, label, position = "TOP" },
  controlItems,
  onChange,
}: {
  control: SingleSelectControl;
  controlItems: An.NonEmptyArray<FigureControlItem>;
  onChange: (selected: string) => void;
}) {
  const handler = React.useCallback(
    (x) => onChange(x.value),
    [] // eslint-disable-line
  );

  return (
    <Select
      key="select"
      value={pipe(
        selected,
        O.getOrElse(() => "")
      )}
      options={controlItems}
      onChange={handler}
      position={position}
      title={title}
      label={label}
    />
  );
}

export function MultiSelectAdapter({
  control: { label, selected, minItems, maxItems, title },
  controlItems,
  onChange,
  isInline,
}: {
  control: MultiSelectControl;
  controlItems: An.NonEmptyArray<FigureControlItem>;
  onChange: (selected: Array<FigureControlItem>) => void;
  isInline: boolean;
}) {
  return (
    <MultiSelect
      label={label}
      items={controlItems}
      selectedItems={pipe(
        selected,
        O.getOrElse<Array<string>>(() => [])
      )}
      onChange={(xs) => onChange(xs ? xs : [])}
      minItems={minItems}
      maxItems={maxItems}
      title={title}
      isInline={isInline}
    />
  );
}

function TimelineAdapter({
  control: { range, checked },
  onChange,
}: {
  control: TimelineControl;
  onChange: (selected: number) => void;
}) {
  const handler = React.useCallback(
    (x) => onChange(x),
    [] // eslint-disable-line
  );
  return <Timeline range={range} onChange={handler} checked={checked} />;
}

function RadioSelectBoxAdapter({
  control: { selected, title },
  controlItems,
  onChange,
}: {
  control: RadioControl;
  controlItems: An.NonEmptyArray<FigureControlItem>;
  onChange: (selected: string) => void;
}) {
  const handler = React.useCallback(
    (x) => onChange(x),
    [] // eslint-disable-line
  );
  return (
    <RadioSelectBox
      title={title}
      options={controlItems}
      selected={pipe(
        selected,
        O.getOrElse(() => An.head(controlItems).value)
      )}
      onChange={handler}
    />
  );
}

function MultiCheckboxAdapter({
  control: { selected, title },
  controlItems,
  onChange,
}: {
  control: MultiCheckboxControl;
  controlItems: An.NonEmptyArray<FigureControlItem>;
  onChange: (selected: Array<FigureControlItem>) => void;
}) {
  return (
    <MultiCheckbox
      title={title}
      items={controlItems}
      selectedItems={pipe(
        selected,
        O.getOrElse<Array<string>>(() => [])
      )}
      onChange={(xs) => onChange(xs ? xs : [])}
    />
  );
}
