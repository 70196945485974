import { ThemeContext } from "@emotion/core";
import React from "react";
import { useMedia, useWindowSize } from "../hooks";
import { Locale, lookupLocale } from "../locales";
import { bp } from "../materials";
import { GEMTheme, mkTheme } from "../materials/theme";
import { RAlias } from "../routes";

export function useThemeFor(locale: Locale, theme: RAlias) {
  const { width } = useWindowSize();

  const isRTL = lookupLocale(locale).dir === "rtl";

  const screenSDown = width < bp.s;
  const screenMDown = width < bp.m;
  const screenLDown = width < bp.l;
  const screenLUp = width >= bp.l;

  const isTouchDevice = useMedia("(any-hover: none) and (any-pointer: coarse)");

  return React.useMemo(() => {
    return mkTheme(theme, {
      screenSDown,
      screenMDown,
      screenLDown,
      screenLUp,
      isRTL,
      isTouchDevice,
    });
  }, [
    theme,
    screenSDown,
    screenMDown,
    screenLDown,
    screenLUp,
    isRTL,
    isTouchDevice,
  ]);
}

/**
 * Using `as` here, because ThemeContext is a hardcoded {}
 */
export const useTheme = () =>
  React.useContext(ThemeContext as React.Context<GEMTheme>);

export function mkStyles<A>(f: (theme: GEMTheme) => A): () => A {
  return () => {
    const theme = useTheme();
    return React.useMemo(() => f(theme), [theme]);
  };
}

export function mkStylesWithProps<A, Props>(
  f: (theme: GEMTheme, props: Props) => A
): (props: Props) => A {
  return (props: Props) => f(useTheme(), props);
}
