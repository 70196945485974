import React from "react";
import css from "@emotion/css";
import * as M from "../../materials";

export function Header({ children }: $PropsWithChildren) {
  return (
    <div
      css={css`
        grid-column: ${M.layoutAreas.wide};
        display: flex;
        justify-content: space-between;
      `}
    >
      {children}
    </div>
  );
}
