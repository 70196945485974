
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Financial Aid";
export const lead = "Fianancial Aid Lead";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const IndicatorReference = makeShortcode("IndicatorReference");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`International solidarity is needed for the world’s poorest countries to fulfil their children’s right to education.`}</p>
    </blockquote>
    <p>{`Aid to education is particularly important in low-income counties where it accounts for 16% of total education spending, or 24%, if household education spending is excluded.`}</p>
    <h2 {...{
      "id": "aid-trends"
    }}>{`Aid trends`}</h2>
    <p>{`As early as 1970, a UN resolution set the target for `}<a parentName="p" {...{
        "href": "https://www.oecd.org/dac/financing-sustainable-development/development-finance-standards/the07odagnitarget-ahistory.htm"
      }}>{`Official Development Assistance (ODA) at 0.7%`}</a>{` of Gross National Income (GNI); Yet ODA levels among OECD Development Assistance Committee (DAC) donors have stagnated at some 0.3% of GNI for the past 15 years. The GEM Report has estimated that if this target had been met, an additional US$3.3 trillion in ODA would have been disbursed between 1990 and 2016. Of the 30 OECD DAC donors, only Denmark, Germany, Luxembourg, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Norway`}</HighlightByIndex>{`, Sweden and the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`United Kingdom`}</HighlightByIndex>{` were spending more than 0.7% in 2020 – but the United Kingdom reduced its spending to 0.5% in 2021.`}</p>
    <Figure id="ODA_GDP" mdxType="Figure" />
    <p>{`The `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`share of education in total aid`}</HighlightByIndex>{` fell sharply from 14.7% in 2003 to 9.7% in 2013, where it has remained since, despite strong advocacy efforts to prioritize education in aid portfolios. By contrast, the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`share of health in total aid`}</HighlightByIndex>{` has been increasing.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="SECTOR_ALLOCABLE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="Health" mdxType="ScrollySection">
    As a result of the COVID-19 pandemic, the{" "}
    <HighlightByIndex index="1" mdxType="HighlightByIndex">share of health in total aid</HighlightByIndex>{" "}
    increased sharply in 2019–2021, reaching an all-time high of 23%.
  </ScrollySection>
    </ScrollyArea>
    <p>{`In a context of a constant share of aid in total income and a falling share of education in total aid, the trajectory of aid to education since the early 2000s is characterised by three stages: a considerable increase up to 2010, a slight decrease until 2014 as a result of the financial crisis, and then an increase in the following years.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="EDU_SUB" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,2020,2021" mdxType="ScrollySection">
        <p>{`Aid to education peaked in 2020 at US$18 billion, as donors provided education-related COVID-19 recovery support; it fell to US$16.8 billion in 2021.`}</p>
      </ScrollySection>
      <ScrollySection id="relative" mdxType="ScrollySection">
        <p>{`The share of aid to `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`secondary education`}</HighlightByIndex>{` has been increasing (from 12% in 2005 to 21% in 2021), at the expense of `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`basic education`}</HighlightByIndex>{` (from 42% to 39%) and `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`tertiary education`}</HighlightByIndex>{` (from 46% to 40%).`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "largest-donors"
    }}>{`Largest donors`}</h2>
    <p>{`The top five donors – the European Union, Germany, the United Kingdom, the United States and the World Bank's International Development Association (IDA) – account for more than 40% of total aid to education. But their priorities vary considerably.`}</p>
    <p>{`If scholarships are excluded, the World Bank tops the donor list for aid to education, having disbursed US$1.7 billion per year on average in 2019–2021. It is followed by Germany and the United States, each with US$1.3 billion, and the European Union with US$981 million.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="EDU_SCHOL_TT" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="DEU,0" mdxType="ScrollySection">
  
        <b>Germany</b> is currently the largest donor, with a rolling average of US$3 billion per year in 2019–2021...
      </ScrollySection>
      <ScrollySection id="DEU,1" mdxType="ScrollySection">
  
...but about 60% of these funds support <HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">scholarships and imputed student fees</HighlightByPalette> for those, mostly from China and India, who study in Germany.
      </ScrollySection>
      <ScrollySection id="JPN" mdxType="ScrollySection">
  
        <b>Japan</b>, the seventh largest education donor, allocates more than a third of its aid to <HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">scholarships and imputed student fees</HighlightByPalette>.
      </ScrollySection>
    </ScrollyArea>
    <p>{`The Global Partnership for Education (GPE) is not shown alongside other donors, as it channels other donors’ funds to support the implementation of national education sector plans. Between 2003 and 2022, it approved implementation grants worth a total of US$6.5 billion. Annual GPE disbursements were about US$500 million around the mid-2010s but fell sharply in the second half of the 2010s. Total disbursement increased from US$237 million in 2019 to US$411 million in 2020 and to US$521 million in 2021, before falling back to US$402 million in 2022 .`}</p>
    <Figure id="GPE" mdxType="Figure" />
    <p>{`UNICEF is another major funder of education: its 2020 annual report shows that it spent `}<a parentName="p" {...{
        "href": "https://www.unicef.org/media/102271/file/Global-annual-results-report-2020-goal-area-2.pdf"
      }}>{`US$1.2 billion on education`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://www.unicef.org/partnerships/funding"
      }}>{`16% of its total spending`}</a>{`, of which US$146 million was drawn from regular resources and US$1.02 billion from funds earmarked for specific programmes. But officially, UNICEF disbursements amounted to just US$72 million per year in 2019–2021. The discrepancy is because, first, 25% of UNICEF revenue is from private sources and is therefore not recorded as ODA, and second, UNICEF does not report regular, earmarked or humanitarian funds to the aid database, only thematic funds.`}</p>
    <h2 {...{
      "id": "equity"
    }}>{`Equity`}</h2>
    <p>{`Volume of aid is not the only important consideration. It also matters how effective aid is and how equitably it is allocated. There are several ways to look at equity in aid.`}</p>
    <IndicatorReference id="4.5.5" mdxType="IndicatorReference">
      <p>{`First, the share of total aid to education going to low-income countries plays a significant role in providing aid equitably. It has remained relatively stable between 2002 and 2021 at about 21%, even though, according to recent estimates, these countries host one third of the out-of-school children of primary school age. The share of total aid to education that Sweden allocates to low-income countries, for instance, has fallen from a peak of 45% in 2007 to 27% in 2020.`}</p>
 </IndicatorReference>
    <p>{`Second, considering that the poorest children are more likely not to even complete primary school, the share of aid allocated to basic education is another indication of equity orientation.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="AID_TO_EDU" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="GBR,0" mdxType="ScrollySection">
    The <HighlightByIndex index="0" mdxType="HighlightByIndex">United Kingdom</HighlightByIndex> allocates
    10% of its total aid to education...
  </ScrollySection>
  <ScrollySection id="GBR,1" mdxType="ScrollySection">
    ...but the share of aid to education that the{" "}
    <HighlightByIndex index="0" mdxType="HighlightByIndex">United Kingdom</HighlightByIndex> is spending on
    basic education has fallen from a peak of 81% in 2006 to 46% in 2021.
  </ScrollySection>
  <ScrollySection id="GBR,2" mdxType="ScrollySection">
    The percentage of total aid to education that the{" "}
    <HighlightByIndex index="0" mdxType="HighlightByIndex">United Kingdom</HighlightByIndex> allocates to
    low-income countries fell from 35% in 2010 to 15% in 2020.
  </ScrollySection>
    </ScrollyArea>
    <p>{`Third, donors may spend very different amounts per child between countries, even between those at the same level of education development.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="AID_BASIC" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="TGO,COM,SLE," mdxType="ScrollySection">
    The amount of aid per child varies from US$6 in <b>Togo</b> to US$14 in{" "}
    <b>Comoros</b> and US$21 in <b>Sierra Leone</b>, all countries with similar
    levels of lower secondary completion rates.
  </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "sub-saharan-africa"
    }}>{`Sub-Saharan Africa`}</h2>
    <p>{`Comparing regions, the largest recipient of aid to education in 2021 was Northern Africa and Western Asia (US$4.9 billion), followed by sub-Saharan Africa (US$4.3 billion). In total, around a quarter of donors' aid to basic education is allocated to sub-Saharan Africa, home to 55% of the world's out of school children.`}</p>
    <p>{`The World Bank is the largest donor to education in sub-Saharan Africa. Its support, which fluctuated around US$500 million in the 2000s, more than tripled from US$350 million in 2015 to US$1.1 billion in 2021. The United Kingdom has reduced their direct funding to the region, but this is because it has shifted funds to support the Global Partnership for Education, which focuses on low-income countries and in particular on Sub-Saharan Africa.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="AID_EDU_SSA" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="DEU,FRA" mdxType="ScrollySection">
    <HighlightByIndex index="0" mdxType="HighlightByIndex">France</HighlightByIndex> and{" "}
    <HighlightByIndex index="1" mdxType="HighlightByIndex">Germany</HighlightByIndex> are currently the
    second and third largest donors to the region but their emphasis is on
    post-secondary education, scholarships and imputed fees.
  </ScrollySection>
  <ScrollySection id="World Bank (IDA)" mdxType="ScrollySection">
    The increase in the{" "}
    <HighlightByIndex index="2" mdxType="HighlightByIndex">World Bank (IDA)'s</HighlightByIndex> aid to
    sub-Saharan Africa since 2019 is mostly driven by aid to two countries:
    Rwanda and the Democratic Republic of the Congo.
  </ScrollySection>
    </ScrollyArea>
    <p>{`This flow diagram below of the 10 largest donors to sub-Saharan Africa shows that only 30% of direct aid to education goes straight to recipient countries, with the remainder going through donors’ own aid agencies, international and domestic NGOs and multilateral organizations, including GPE and UNICEF. Of the 10 largest donors, only the World Bank channels funds directly to recipient countries while bilateral donors such as the European Union, Germany, the United Kingdom and the United States allocate no more than 20% of aid to recipient countries. As a result, one third of the aid from the top 10 donors goes to unspecified recipient countries.`}</p>
    <Figure id="AID_FLOWS_GROUPED" mdxType="Figure" />
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Aid is an important source of education financing in low-income countries, which must be analysed jointly with `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/en/articles/finance"
      }}>{`domestic public and private funding`}</a>{`. In addition to increasing the funds available for education, equitable distribution needs to be strengthned. In order to achieve the global education goal, SDG 4, it is important that countries and donors take responsibility and fulfil their commitments.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gem-report-2023.unesco.org/wp-content/uploads/2023/07/2023aidtables.xlsx"
          }}>{`Download Aid to Education tables`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/3141"
          }}>{`COVID-19 is a Serious Threat to Aid to Education Recovery`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2022-efw"
          }}>{`Education Finance Watch 2022`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;