import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { HighlightByTheme } from ".";
import { baseElements } from "../../materials";
import { E } from "../../prelude";
import { RAlias, RAliasArticle } from "../../routes";
import { PageLink as PageLink_ } from "../links";
import { ReactComponent as ArrowLeftIcon } from "../../icons/ic_arrow-left.svg";
import css from "@emotion/css";
import { useTheme } from "hooks";
import * as M from "../../materials";

export function PageLink({
  children,
  name,
}: $PropsWithChildren<{ name: string }>) {
  const theme = useTheme();
  const BE = baseElements(theme);

  return pipe(
    RAlias.decode(name),
    E.fold(
      () => <span>{children}</span>,
      (name_) => {
        return pipe(
          RAliasArticle.decode(name_),
          E.fold(
            () => (
              <PageLink_ name={name_} query={{}}>
                <BE.a>{children}</BE.a>
              </PageLink_>
            ),
            (article) => (
              <HighlightByTheme theme={article}>
                <span style={{ paddingLeft: "0.25em", paddingRight: "0.25em" }}>
                  {children}
                </span>
              </HighlightByTheme>
            )
          )
        );
      }
    )
  );
}

export function BackLink({
  children,
  name,
}: $PropsWithChildren<{ name: string }>) {
  const { client } = useTheme();
  return (
    <PageLink name={name}>
      <ArrowLeftIcon
        css={css`
          display: inline-block;
          vertical-align: middle;
          margin-inline-end: ${M.spacing.base8(1)};
          transform: ${client.isRTL ? "rotate(180deg)" : "none"};
        `}
      />
      {children}
    </PageLink>
  );
}
