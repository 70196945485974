import React from "react";

/**
 * useContentRect
 *
 * Make stuff responsive like a boss!
 */
export function useContentRect() {
  const ro = React.useRef<ResizeObserver>();
  const [contentRect, setContentRect] = React.useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  const refCB: React.Ref<Element> = React.useCallback((el) => {
    // We use a ref to store the ResizeObserver
    if (!ro.current) {
      ro.current = new ResizeObserver((entries) => {
        setContentRect({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      });
    }
    if (el !== null) {
      ro.current.observe(el);
    } else {
      ro.current.disconnect();
    }
  }, []);

  return [refCB, contentRect] as const;
}
