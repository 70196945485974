import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "TRAJECTORIES_CURRENT",
  title: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_CURRENT.title",
    defaults: `Learning trajectories in many low- and lower-middle-income countries reveal that the pace of learning is far too slow in early grades`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_CURRENT.caption",
    defaults: `Descriptive learning trajectories for 23 low- and lower-middle-income countries showing the percentage of children (aged 7–14 years) who have mastered foundational skills, typically at the Grade 2–3 level. This is below the minimum proficiency level defined by SDG global indicator 4.1.1. Trajectories by grade include all children for whom this was the highest grade attended (whether they were in that grade at the time of the survey or had attended that grade in the past and then dropped out).`,
  },
  source: /*i18n*/ {
    id: "fig.learning.TRAJECTORIES_CURRENT.source",
    Source: `Analysis by RISE based on United Nations Children’s Fund (UNICEF) Multiple Indicator Cluster Survey (MICS6) data.`,
  },
};
