import { FigureMetadata } from "../components/figure";
export const metadata: FigureMetadata = {
  id: "LITBYYRS",
  title: /*i18n*/ {
    id: "fig.learning.LITBYYRS.title",
    defaults: `Youth literacy rate by years of schooling`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.LITBYYRS.caption",
    defaults: `Even six years of schooling do not guarantee literacy.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.LITBYYRS.source",
    defaults: `GEM Report team analysis based on household surveys`,
  },
};
