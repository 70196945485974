import css from "@emotion/css";
import { motion, useCycle } from "framer-motion";
import React from "react";
import { mkStylesWithProps, useFocusVisible } from "../../hooks";
import * as M from "../../materials";
import { layoutAreas } from "../../materials";
import buttonOpen from "./files/ic_expand_24dp.svg?raw";
import { QualityFrameworkTable } from "./quality-framework-table";

const useQFStyles = mkStylesWithProps(
  (
    { activeTheme, palette, spacing: { base8, base1 } },
    props: { isOpen: boolean }
  ) => ({
    root: css`
      margin-top: 1.5rem !important;
      text-align: center;
      background: ${props.isOpen ? "transparent" : activeTheme.background.main};
      padding-top: ${base8(4)};
    `,
    section: css`
      grid-column: ${layoutAreas.figure};
      background: ${activeTheme.background.main};
      margin-top: 0;
    `,
    label: css`
      color: ${palette.brand.marine.main};
      max-width: 25ch;
      display: flex;
      justify-self: center;
      margin-left: auto;
      margin-right: auto;
    `,
    button: css`
      margin-top: ${base8(4)};
      width: max-content;
      border-radius: 50%;
      text-align: center;
      border: 1px solid transparent;
      margin: auto;
      height: ${base8(3)};
      width: ${base8(3)};
      display: flex;
      background: none;
      padding: 0;
      outline: none;
      &:hover {
        cursor: pointer;
      }

      & > img {
        margin: ${base1(4)};
      }
    `,
    buttonFocused: css`
      outline: 2px solid ${palette.brand.marine.dark};
      outline-offset: 2px;
    `,
    buttonCollapsed: css`
      background: ${palette.brand.marine.main};
      margin-top: ${base8(4)};
      border: 1px solid ${palette.brand.marine.main};
      text-align: center;
      margin: auto;
      box-sizing: content-box;
      height: ${base8(3)};
      width: ${base8(3)};
      display: flex;

      & > img {
        margin: ${base1(4)};
      }
    `,
  })
);

export interface QualityFrameworkProps {
  description: string;
  highlighted?: string;
}

export const QualityFramework = ({
  highlighted,
  description,
}: QualityFrameworkProps) => {
  const [isOpen, toggleOpen] = useCycle(true, false);
  const { focusVisible, ...handleFocus } = useFocusVisible();

  const styles = useQFStyles({ isOpen });

  return (
    <M.Grid layout={"article"} styles={styles.root}>
      <motion.button
        css={[
          styles.button,
          isOpen && styles.buttonCollapsed,
          focusVisible && styles.buttonFocused,
        ]}
        animate={
          isOpen ? { scale: 1.0, rotate: 0 } : { scale: 1.5, rotate: 45 }
        }
        whileHover={{ scale: 1.2 }}
        onClick={() => {
          toggleOpen();
        }}
        aria-expanded={isOpen}
        {...handleFocus}
      >
        <img src={buttonOpen} alt="" />
      </motion.button>
      {isOpen ? (
        <p css={styles.label}>{description}</p>
      ) : (
        <motion.div
          style={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ duration: 0.3 }}
          css={styles.section}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.4, ease: "easeIn" }}
          >
            <QualityFrameworkTable highlighted={highlighted} />
          </motion.div>
        </motion.div>
      )}
    </M.Grid>
  );
};
