import { t } from "@lingui/macro";
import { io } from "../prelude";
import { identity } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import { getBreadcrumbSchema } from "lib/schema";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { useFigureScreenshotUrl } from "../figures";
import { useQueryParams } from "../hooks";
import { foldLocaleL, Locale, useI18n } from "../locales";
import { O } from "../prelude";
import { isNoIndexRoute, REnv } from "../routes";

export function publicUrl(path: string) {
  return `${SITE_URL}${path}`;
}

export function publicLocaleUrl(locale: Locale, path: string) {
  return publicUrl(`/${locale}${path}`);
}

export function getCanonicalURL(path: string) {
  // remove queries and in-page id link
  const _pathSliceLength = Math.min.apply(Math, [
    path.indexOf("?") > 0 ? path.indexOf("?") : path.length,
    path.indexOf("#") > 0 ? path.indexOf("#") : path.length,
  ]);
  return `${SITE_URL}${path.substring(0, _pathSliceLength)}`;
}

export const graphImgSrc = foldLocaleL<string>({
  ar: () => require("./graph-images/unesco-education-progress.jpg"),
  de: () => require("./graph-images/unesco-education-progress.jpg"),
  en: () => require("./graph-images/unesco-education-progress.jpg"),
  es: () => require("./graph-images/unesco-education-progress.jpg"),
  fr: () => require("./graph-images/unesco-education-progress.jpg"),
  ru: () => require("./graph-images/unesco-education-progress.jpg"),
  zh: () => require("./graph-images/unesco-education-progress.jpg"),
});

const PageType = io.union([io.literal("website"), io.literal("article")]);
type PageType = io.TypeOf<typeof PageType>;

export function HeadMetaDynamic({
  locale,
  title,
  description,
  pageType,
}: {
  locale: Locale;
  title: string;
  description: string;
  pageType: PageType;
}) {
  const router = useRouter();
  const i18n = useI18n();
  const figureId = pipe(
    useQueryParams(REnv),
    O.fromEither,
    O.chain(({ figure }) => O.fromNullable(figure)),
    O.getOrElse(() => "")
  );
  const screenshotUrl = pipe(
    useFigureScreenshotUrl(figureId),
    O.fold(() => publicUrl(graphImgSrc(locale)), identity)
  );

  const siteTitle = i18n._(t("meta.title")`GEM Report Education Progress`);
  const headTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  // @todo
  // og and twitter title normally don't contain a suffix site title
  // but it could be. Decide this after checking card preview
  const pageTitle = title || siteTitle;
  const pageDesp =
    description ||
    i18n._(
      t(
        "meta.description"
      )`An interactive site telling the story behind education data from around the world.`
    );
  const pageCanonicalURL = getCanonicalURL(router.asPath);
  const noIndex = isNoIndexRoute(router.pathname);

  // Currently we only have subpages under `/articles`
  const isArticle = router.pathname.startsWith("/articles");
  const breadcrumb = isArticle
    ? [
        { position: 1, name: siteTitle, item: `${SITE_URL}/${locale}` },
        {
          position: 2,
          name: headTitle,
          item: pageCanonicalURL,
        },
      ]
    : null;

  return (
    <Head>
      {/* Prevent robots from crawling the page */}
      {noIndex && <meta name="robots" content="noindex" />}

      {/* Essential META Tags */}
      <title>{headTitle}</title>
      <meta name="description" content={pageDesp} />

      {/* Solve GoogleBot "Duplicate without user-selected canonical" issue */}
      <link rel="canonical" href={pageCanonicalURL} />

      {/* OpenGraph tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDesp} />
      <meta property="og:type" content={pageType} />
      <meta property="og:url" content={pageCanonicalURL} />
      <meta property="og:image" content={screenshotUrl} />
      <meta property="og:site_name" content={siteTitle} />

      {/* Twitter Card data */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDesp} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@GEMReport" />
      <meta name="twitter:creator" content="@GEMReport" />
      <meta name="twitter:image" content={screenshotUrl} />

      {/* Structured data */}
      {breadcrumb && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: getBreadcrumbSchema(breadcrumb),
          }}
        />
      )}
    </Head>
  );
}
