
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const layout = "simple";
export const title = "Über uns";
export const lead = "";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Title = makeShortcode("Title");
const LinkBlock = makeShortcode("LinkBlock");
const FederalMinistryGermanyLogo = makeShortcode("FederalMinistryGermanyLogo");
const Aside = makeShortcode("Aside");
const layoutProps = {
  layout,
title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title padded mdxType="Title">Über uns</Title>
    <h2 {...{
      "id": "scope"
    }}>{`SCOPE`}</h2>
    <p>{`Diese Website vereint administrative Daten, Haushaltsbefragungen, Lern-Assessments und Angaben zur Bildungsfinanzierung aus mehreren Quellen, insbesondere des UNESCO-Instituts für Statistik, um die Fortschritte auf dem Weg zur Erreichung von SDG4 – des globalen Bildungsziels – zu beleuchten.`}</p>
    <p>{`Die Website ergänzt die `}<a parentName="p" {...{
        "href": "https://en.unesco.org/gem-report/allreports"
      }}>{`Druckfassung`}</a>{` des Weltbildungsberichts und ermöglicht es Nutzerinnen und Nutzern, mit den Daten zu interagieren, um besser zu verstehen, was Länder und Regionen bereits erreicht haben und vor welchen Herausforderungen sie auf dem Weg zur Erreichung der Ziele stehen. Die Inhalte können geteilt und heruntergeladen werden, Nutzerinnen und Nutzer können Grafiken und Dateien erstellen, um sich eingehender mit den Themen zu befassen, und diese ausdrucken beziehungsweise online oder in Präsentationen verwenden.`}</p>
    <h2 {...{
      "id": "über-den-weltbildungsbericht-global-education-monitoring-report"
    }}>{`Über den Weltbildungsbericht Global Education Monitoring Report`}</h2>
    <p>{`Der Weltbildungsbericht ist ein redaktionell unabhängiger jährlicher Bericht, der durch die UNESCO unterstützt und veröffentlicht wird. Laut `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
      }}>{`Aktionsrahmen zur Bildungsagenda 2030`}</a>{`, hat er den Auftrag, Bildungsfortschritte im Bereich der Ziele für nachhaltige Entwicklung (SDGs) sowie Entwicklungen bei der Umsetzung nationaler und internationaler Bildungsstrategien zu überwachen und so dazu beizutragen, alle beteiligten Partner bezüglich ihrer Verpflichtungen zur Rechenschaft zu ziehen.`}</p>
    <LinkBlock title="Website ansehen" url="https://en.unesco.org/gem-report/" variant="gem" mdxType="LinkBlock" />
    <h2 {...{
      "id": "über-das-ziel-für-nachhaltige-entwicklung-sdg-4"
    }}>{`Über das Ziel für nachhaltige Entwicklung SDG 4`}</h2>
    <p>{`Auf dem Weltgipfel für nachhaltige Entwicklung der Vereinten Nationen nahmen die Mitgliedsstaaten im September 2105 die `}<a parentName="p" {...{
        "href": "https://sustainabledevelopment.un.org/post2015/transformingourworld"
      }}>{`Agenda 2030 für nachhaltige Entwicklung`}</a>{` formal an. Diese Agenda enthält 17 Ziele für nachhaltige Entwicklung (Sustainable Development Goals, SDGs), darunter SDG 4: „Inklusive, gleichberechtigte und hochwertige Bildung gewährleisten und Möglichkeiten lebenslangen Lernens für alle fördern“. Zu diesem Ziel bestehen sieben Unterziele und drei Implementierungsmechanismen.`}</p>
    <LinkBlock title=" Die Unterziele und damit zusammenhängende Monitoring-Rahmen" url="https://sustainabledevelopment.un.org/sdg4" variant="sdg4" mdxType="LinkBlock" />
    <FederalMinistryGermanyLogo lang="de" mdxType="FederalMinistryGermanyLogo" />
    <p>{`Besonderer Dank gilt dem deutschen Bundesministerium für Bildung und Forschung für seine Partnerschaft und großzügige Unterstützung zur Entwicklung der Website Education Progress.`}</p>
    <Aside mdxType="Aside">
      <h2 {...{
        "id": "kontakt"
      }}>{`Kontakt`}</h2>
      <hr></hr>
      <p>{`Allgemeine E-Mail`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:gemreport@unesco.org"
        }}>{`gemreport@unesco.org`}</a></p>
      <hr></hr>
      <p>{`Das Team`}</p>
      <p>{`GEM Report Team, UNESCO`}</p>
      <hr></hr>
      <p>{`Unsere Anschrift`}</p>
      <p>{`Global Education Monitoring Report Team (GEM Report),
UNESCO, 7 place de Fontenoy, 75352 Paris 07 SP, France`}</p>
    </Aside>



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;