
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "First-generation students have it hard everywhere";
export const lead = "One group that is not mentioned at all in the SDG 4 framework is first-generation learners, i.e. learners who are the first in their family to attend a particular level of schooling.";
export const layout = "focus";
export const article = "equity";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="First-generation students have it hard everywhere" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=277" mdxType="FocusDetails">
      <h1 {...{
        "id": "first-generation-students-have-it-hard-everywhere"
      }}>{`First-generation students have it hard everywhere`}</h1>
      <p>{`The monitoring of target 4.5 has emphasized three individual characteristics for which data are most commonly available and which are assumed to be comparable between countries: gender, socioeconomic status and urban/rural location. While gaps in education access and outcomes relating to these three dimensions are considerable, other characteristics grouped under the catch-all category of ‘vulnerable situations’ have not received as much attention. One group that is not mentioned at all in the SDG 4 framework is first-generation learners, i.e. learners who are the first in their family to attend a particular level of schooling. Emerging analyses show that completing a level of education that one's parents never attended is a formidable challenge, whether for children of illiterate parents in low-income countries or first-in-their-family university students in high-income countries.`}</p>
      <p>{`While parental education often serves as a proxy for socioeconomic status, first-generation learners face specific education challenges that are distinct from disadvantages resulting from poverty (Spiegler, 2013). These challenges have as much to do with cultural capital, such as familiarity with academic etiquette (Collier, 2008), and social capital, such as social connections with teachers or faculty, as they do with material resources. Less educated households may also not be as familiar with the written and unwritten rules of getting into higher levels of education.`}</p>
      <p>{`First-generation students are more likely to have norms, such as a belief in collaboration, that are at odds with the more individualistic environment of higher education (Phillips et al., 2020). They are also more likely to doubt their skills and experience a fear of being exposed, a feeling exacerbated in courses which tend to be more competition-oriented, such as science, technology, engineering and mathematics (STEM) courses (Canning et al., 2020). First-generation students in France suffered a greater negative impact on their performance when compared explicitly to their classmates than did other students (Jury et al., 2015).`}</p>
      <p>{`Some recent research in the United States has suggested that contrary to what is often believed, caregivers with different levels of education show little difference in parenting styles (Hastings and Pesando, 2022); in other words, it is not the case that the less educated are less demanding parents. Instead, a crucial constraint for schoolchildren is that their less educated parents may not be familiar with the learning material and cannot help with homework and assignments or give first-hand advice on effective strategies on how to learn (Portela et al., 2020). Analysis from Germany shows that children from disadvantaged families, including less educated households, are not as likely to receive a good grade, even given identical performance, be recommended for the academic secondary track even when receiving identical grades, and actually choose the academic track even when recommended to do so. This is a triple disadvantage for learners taking a decision as early as the age of 10 to 12 (Maaz, 2020). Data from the Programme for the International Assessment of Adult Competencies show as few as one in five complete tertiary education in Organisation for Economic Co-operation and Development countries among those whose parents did not complete upper secondary school, compared to two in three whose parents were university educated (OECD, 2018). `}</p>
      <p>{`Longitudinal data from the Young Lives project in Ethiopia, India, Peru and Viet Nam also allow an analysis of what happens when learners become first-generation students once they progress beyond their parents’ schooling. At age 8, when they are in primary school, relatively few children are first-generation learners, except in Ethiopia, where more than one in three are. But by age 15, when they are in secondary school, two thirds are first-generation learners in all countries except Peru. In India and Viet Nam, they have a greater risk of dropping out from secondary school, a level above what their parents had achieved (Portela et al., 2020).`}</p>
      <p>{`Even when first-generation students reach the top of the attainment distribution, there is still inequality in learning. In Ethiopia, first-generation learners are significantly disadvantaged in terms of learning, even accounting for poverty, and this gap widens over the course of schooling (Iyer et al., 2020). Inequality extends higher up the education ladder to distribution across disciplines and institution prestige. Only 14% of recent US-born economics Ph.D. recipients were first-generation college graduates, dropping to only 5% at the top 6 economics departments. This compares to 26% across all fields and over 40% of education Ph.D. recipients (Schultz and Stansbury, 2022).`}</p>
      <p>{`Such analyses require various methodological choices. Whose education determines a young person’s first-generation status at a given level? Do they cease being first-generation if either of their parents attended that level themselves, or only if both did? In practice, although an imperfect choice, data coverage is maximized if the focus is on the education of the head of household or the highest-educated adult in the household, perhaps above a certain minimum age (e.g. 25 years), in an attempt to exclude older siblings. More than half of all students in tertiary education in the United States may be first-generation (Laiduc et al., 2021; Redford and Hoyer, 2017). However, this estimate is highly dependent on the definition used: a study using a sample of 7,300 students found that the share ranged from 22% to 77%, depending on which of several definitions was used (Toutkoushian et al., 2018).`}</p>
      <p>{`In an extension of the WIDE, which has been documenting the level and change of education inequality since 2010, the GEM Report team has analysed individual education status according to the education level of the head of household, the aim of which is to understand the extent of first-generation learners’ disadvantage compared to their peers. One measure is the relative gap in primary and lower-secondary completion by first-generation status in low- and lower-middle-income countries. The median gap in primary completion is 23 percentage points and exceeds 40 points in Nigeria, which is larger than the urban–rural gap. The median gap between first-generation and non-first-generation adolescents in lower secondary completion is 34 percentage points and reaches 46 points in Cameroon and 50 points in Madagascar.`}</p>
      <FocusImage size="small" src={"23-firstgeneration.png"} alt="First-generation students have it hard everywhere" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=278" mdxType="FocusImage" />
      <p>{`First-generation disadvantage is related to but distinct from intergenerational educational mobility: the strong relationship between the educational attainment of parents and children means there is lower mobility (Bhalotra et al., 2015; Razzu and Wambile, 2022). In the context of educational expansion and increasing mobility where a large number of first-generation students is a positive development, the question then becomes how to help them succeed. In a context of few first-generation students and low mobility, the question is how to increase their number.`}</p>
      <p>{`In a study of intergenerational educational mobility over 50 years in Comoros, Ghana, Guinea, Madagascar, Malawi, Nigeria, Rwanda, the United Republic of Tanzania and Uganda, parental education accounted for 51% of the inequality in children’s years of schooling. This suggests that levels of educational mobility in sub-Saharan Africa are similar to those in Asia and higher than those observed in Latin America. In most countries in the study, there was a moderate increase in mobility over time, driven by progress towards universal, mandatory and free primary schooling. Notably, educational mobility was higher when education was given a higher priority in public expenditure (Azomahou and Yitbarek, 2016). While first-generation disadvantage persists in upper-middle- and high-income countries, examples of higher mobility have been documented, such as girls in Türkiye (Abdurrahman and Hakki, 2019), learners in richer regions in Italy (Güell et al., 2018) and in the United States (Chetty et al., 2014).`}</p>
      <p>{`A range of initiatives tries to address challenges faced by first-generation learners (Whitley et al., 2018). For example, campaigns may focus on role models who highlight their status as first-in-their-family graduates who achieved academic success. First-generation students felt greater belonging and were more likely to seek support when meeting faculty whose own first-generation status was made explicit. The University of California implemented a First-Generation Initiative across its 10 campuses in 2017 to raise awareness, create an inclusive and supportive culture, and ensure that first-generation students were connected to relevant resources and networks (Laiduc et al., 2021).`}</p>
      <p>{`While campaigns are focused on encouraging first-generation students to enrol, other mechanisms are required to support them at institutions once they do. Some schools offer support programmes by assigning mentors or buddies who can explain how things work at the university, reducing reliance on cultural capital at home. A study of a supplementary curriculum, a three-week orientation course followed by targeted counselling and mentorship, offered to first-generation students from poor rural families at an engineering school in Chennai, India, found that the special curriculum helped them to overcome social and cultural barriers but the participants doubted that higher education alone would be enough to overcome the challenges they face in competing with their peers for gainful employment (Vijayakumar, 2020).`}</p>
      <p>{`First-generation learners can also be supported by programmes targeting their family members. If family members prioritize education, they can facilitate the success of their children through non-material support (Gofen, 2009), even if they are not familiar with higher education (Spiegler, 2013).`}</p>
      <p>{`For support programmes to succeed, it must first be recognized that first-generation students are far from homogeneous, and that first-generation status intersects with other forms of disadvantage (Nguyen, 2018). Moreover, institutional support structures may undermine first-generation students’ sense of belonging if they frame support in terms of overcoming a supposed limitation (Means and Pyne, 2017). Rejecting a deficit view, many first-generation students see themselves as being more motivated, adaptable and, almost by definition, more self-reliant (Tate, 2015), contributing positively to their academic confidence and consequently their learning.  `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-equity"
      }}>{`Focuses published in Equity`}</h4>
      <hr></hr>
      <FocusThemeList theme="equity" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;