
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Финансирование";
export const lead = "Каждая четвертая страна не достигла ни одного из ключевых контрольных фи-нансовых показателей, установленных для правительств в рамочной програм-ме действий «Образование-2030».";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const IndicatorReference = makeShortcode("IndicatorReference");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Масштабных целей в области образования невозможно достичь к установленному сроку без дополнительных ресурсов, особенно в странах с наибольшим отставанием.`}</p>
    </blockquote>
    <p>{`Для достижения цели обеспечения всеобще-го среднего образования к 2030 г. странам с низким уровнем дохода и уровнем дохода ниже среднего необходимо будет ежегодно увеличивать объем своих расходов со 149 млрд. долл. в 2012 г. до 340 млрд. долл. в 2030 г., или до 6,3% их валового внутреннего продукта (ВВП). Однако увеличения объема ресурсов не всегда достаточно. Так, страны, расходующие одинаковую долю дохода на душу населения, могут иметь совершенно разные результаты в области образования в зависимости от того, являются ли эти расхо-ды обоснованными и эффективными или нет.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`Из общей суммы в 4,7 трлн. долл., расходуе-мой во всем мире ежегодно на цели образо-вания, доля расходов стран с низким уров-нем дохода составляет лишь 0,5%, а стран с высоким уровнем дохода – 65%, хотя в этих двух группах стран примерно одинаковое число детей школьного возраста.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`Во всем мире домохозяйства покрывают пятую часть (20%) расходов на образование. На практике расходы домохозяйств на образование в странах с низким уровнем дохода выше (29%), чем в странах с высоким уровнем дохода (18%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`Хотя на долю доноров в странах с низким уровнем дохода приходится 12% расходов ....`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`... во всем мире этот показатель составляет лишь 0,3% от общего объема расходов, что представляет собой совершенно незначительную сумму в абсолютных значениях.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "государственные-расходы"
    }}>{`Государственные расходы`}</h2>
    <p>{`В рамочной программе действий `}<a parentName="p" {...{
        "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656_rus"
      }}>{`«Образова-ние-2030»`}</a>{` для правительств определены два ключевых контрольных финансовых показа-теля. Им рекомендуется:`}</p>
    <ul>
      <li parentName="ul">{`выделять на образование не менее 4-6% ВВП и/или`}</li>
      <li parentName="ul">{`выделять на образование не менее 15-20% государственных расходов.`}</li>
    </ul>
    <p>{`Во всем мире правительства в среднем при-держиваются этих показателей. Их расходы составляют в среднем 4,4% ВВП, они варьи-руются от 3,4% в Восточной и Юго-Восточной Азии до 5,1% в Латинской Америке и Кариб-ском бассейне. Кроме того, они выделяют на образование 14,1% от общего объема госу-дарственных расходов, при этом средние региональные показатели колеблются от 11,6% в Европе и Северной Америке до 18% в Латинской Америке и Карибском бассейне.`}</p>
    <p>{`Однако в целом каждая четвертая страна не достигла ни одного из этих показателей. Вы можете наложить эти данные на временные рамки, чтобы увидеть, как изменяются расхо-ды с течением времени.`}</p>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`Для определения объема расходов прави-тельств на образование можно использовать два ключевых параметра: `}<strong parentName="p">{`налоги`}</strong>{` и `}<strong parentName="p">{`число детей школьного возраста`}</strong>{`. Даже если в бюджетах всех стран приоритетное внимание будет уделяться образованию, страны, которые, например, собирают боль-ше налогов и располагают другими внутрен-ними ресурсами, будут тратить больше средств на образование.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`Кроме того, даже если страны будут собирать одинаковый объем налогов, странам с более высокой долей детей и подростков в общей численности населения придется тратить меньшие суммы в расчете на одного ребенка школьного возраста.`}</p>
    </IndicatorReference>
    <p>{`Вместе с тем значительные различия в сум-мах расходов на образование в странах с аналогичными характеристиками свидетель-ствуют о том, что эти взаимосвязи ничего не предопределяют. Некоторые страны предпо-читают тратить на образование больше для достижения своих целей. И мы должны обра-тить внимание на те страны, которые этого не делают.`}</p>
    <h2 {...{
      "id": "оплата-расходов-за-счет-помощи"
    }}>{`Оплата расходов за счет помощи`}</h2>
    <p>{`Хотя требуется наращивать финансирование, сталкивающиеся с наиболее серьезными проблемами страны не могут справиться с этой задачей в одиночку. Необходима международная солидарность. По оценкам, содержащимся во Всемирном докладе по мониторингу образования, `}{`[ежегодный дефицит финансирования]`}{`(annual funding gap of at least US\\$39 billion per year) в период 2015-2030 гг. в странах с низким уровнем дохода и уровнем дохода ниже среднего составит не менее 39 млрд. долл: это означает необходимость увеличения объема помощи, предоставленной этим странам в 2012 г., в шесть раз. Такие расходы вполне реальны: их можно было бы обеспечить, если бы все доноры из Комитета содействия развитию (КСР) ОЭСР и отдельные доноры, не являющиеся членами КСР, выделили 0,7% валового национального дохода (ВНД) на оказание помощи и направили 10% своих пакетов помощи на цели базового и среднего образования.`}</p>
    <p>{`Однако после более чем двукратного увели-чения помощи на цели образования в пери-од с 2002 г. по 2009 г. ее объемы с тех пор практически не изменялись.`}</p>
    <Figure id="AID" mdxType="Figure" />
    <p>{`Они не только не растут, но и средства рас-пределяются неравномерно. Для достижения целей необходимо в приоритетном порядке оказывать поддержку беднейшим странам, а также базовому образованию от дошкольно-го до младших классов среднего образова-ния. Однако этого не происходит, как пока-зано на графике ниже.`}</p>
    <IndicatorReference id="4.5.5" mdxType="IndicatorReference">
      <p>{`Доля помощи на цели образования, направ-ляемая странам с низким уровнем дохода, сократилась с 25% в 2002 г. до 22% в 2016 г.`}</p>
      <p>{`За тот же период несмотря на то, что 35% детей младшего школьного возраста, не по-сещающих школу, проживают в беднейших странах мира, доля общей помощи на цели базового образования, направляемой стра-нам с низким уровнем дохода, сократилась с 37% до 27%.`}</p>
      <Figure id="AID_LICS" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Доноры также расходуют очень разные сум-мы на одного ребенка в странах с одинако-вым уровнем развития образования. Напри-мер, в Либерии и Мавритании начальную школу заканчивают около половины детей, при этом Либерия получает в 10 раз больший объем помощи на цели базового образова-ния в расчете на одного ребенка школьного возраста.`}</p>
    <p>{`Анализ помощи двусторонних и многосторонних доноров за период с 2002 по 2017 гг. на приведенной ниже диаграмме показывает, чьи расходы распределяются на равноправной основе. Например, процентная доля общей образовательной помощи, выделяемая Соединенным Королевством на базовое образование, сократилась с самого высокого показателя в 81% в 2006 г. до 55% в 2017 г. Доля общей образовательной помощи, выделяемая Швецией странам с низким уровнем дохода, сократилась за тот же период с самого высокого показателя в 58% до 39%.`}</p>
    <p>{`Доноры КСР ОЭСР в целом согласились направлять 0,7% своего валового национального дохода (ВНД) на официальную помощь в целях развития, по крайней мере в качестве долгосрочной цели. Заметными исключениями стали Швейцария и Соединенные Штаты. По состоянию на 2017 г. этот целевой показатель соблюдают только семь доноров – Германия, Дания, Люксембург, Норвегия, Соединенное Королевство, ОАЭ и Турция.`}</p>
    <Figure id="AID_TARGETING" mdxType="Figure" />
    <p>{`В приведенной ниже таблице показано рас-пределение помощи между десятью основ-ными донорами и десятью основными полу-чателями помощи по всему миру в разбивке по уровням образования.`}</p>
    <p>{`На первый взгляд может показаться, что Гер-мания, например, является крупнейшим до-нором на цели образования в этих странах. Вместе с тем значительная доля этой помощи направляется на нужды послесреднего обра-зования, что свидетельствует о том, что эти средства в основном расходуются на ино-странных студентов, главным образом китай-ских, которые учатся в Германии, и не всегда направлены на обеспечение равенства и ка-чества на уровне базового образования.`}</p>
    <Figure id="AID_FLOWS" mdxType="Figure" />
    <p>{`В целом около четверти (24%) объема донорской помощи на цели базового образования выделяется странам Африки к югу от Сахары, в которых проживает половина (54%) от общемирового числа детей, не посещающих школу. На данном графике показано, каким странам уделяют первоочередное внимание основные доноры на нужды образования и на каком уровне.`}</p>
    <p>{`Например, в 2017 г. Франция выделила 130 млн. долл. четырем из десяти основных стран-получателей помощи в данном реги-оне, однако подавляющее большинство этих средств было направлено на послесреднее образование, вероятно, в форме стипендий для обучения во Франции африканских сту-дентов.`}</p>
    <p>{`В области образования важное значение имеет активная глобальная координация донорской помощи. В то же время на этом графике показано большое число доноров, оказывающих финансовую поддержку какой-либо одной стране, несмотря на дополни-тельную нагрузку по управлению этими во-просами на страновом уровне, которой в результате может подвергнуться правитель-ство этой страны.`}</p>
    <Figure id="AID_FLOWS_SSA" mdxType="Figure" />
    <h2 {...{
      "id": "расходы-домохозяйств"
    }}>{`Расходы домохозяйств`}</h2>
    <p>{`Практически в каждой стране при исследова-ниях доходов и расходов национальные ста-тистические управления измеряют расходы домохозяйств на образование.`}</p>
    <p>{`Эти расходы могут принимать форму платы за обучение и других сборов, но могут также относиться и к образовательным товарам и услугам, при этом некоторые из них являются обязательными (например, школьная форма, учебники), а другие – нет (например, частные уроки).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`В тех случаях, когда такие данные стандарти-зированы по странам, можно наглядно уви-деть вклад, который вносят домохозяйства в общие расходы на образование. Это может повлиять на анализ деятельности стран в области образования.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,UGA,ARG,FRA" mdxType="ScrollySection">
          <p>{`Например, в 2013-2015 гг. государственные расходы `}<strong parentName="p">{`Уганды`}</strong>{` были одними из самых низких в процентном отношении к ВВП. Од-нако с учетом вклада домохозяйств общие национальные расходы Уганды на образова-ние в процентном отношении к ВВП были аналогичны расходам `}<strong parentName="p">{`Аргентины`}</strong>{` и пре-вышали расходы `}<strong parentName="p">{`Франции`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,SLV,IDN,FRA,NLD" mdxType="ScrollySection">
          <p>{`Правительства `}<strong parentName="p">{`Сальвадора`}</strong>{` и `}<strong parentName="p">{`Индонезии`}</strong>{` выделяют на образование на два процента ВВП меньше, чем `}<strong parentName="p">{`Франция`}</strong>{` и `}<strong parentName="p">{`Нидерланды`}</strong>{` (выделено голубым цветом), однако в целом они тратят больше, поскольку домохозяйства расходуют на образование более трех процентов ВВП (выделено красным цветом).`}</p>
        </ScrollySection>
        <ScrollySection id="relative,KHM,TJK,UGA" mdxType="ScrollySection">
          <p>{`В одной трети стран с низким и средним уровнем дохода доля домохозяйств в общих расходах на образование составляла не ме-нее 1/3 и выросла примерно до 60% или бо-лее в `}<strong parentName="p">{`Камбодже`}</strong>{`, `}<strong parentName="p">{`Таджикистане`}</strong>{` и `}<strong parentName="p">{`Уганде`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "заключение"
    }}>{`Заключение`}</h2>
    <p>{`Лишь немногие страны предоставляют своевременные и последовательные данные об объемах расходов на образование в разбивке по источникам за длительный период времени.`}</p>
    <p>{`Помимо необходимости улучшения финансирования образования следует также усовершенствовать средства диагностики финансирования этого сектора. Достижение нашей глобальной цели в области образования (ЦУР 4) зависит от обеспечения ответственности стран и доноров за выполнение их обязательств.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://report.educationcommission.org/report/"
          }}>{`Поколение образованных – инвести-рование в образование в условиях меняющегося мира`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/14/aid-to-education-falls-slightly-in-2017-shifts-away-from-primary-education/"
          }}>{`В 2017 г. объемы помощи на нужды образования несколько снизи-лись – пресс-релиз ВДМО`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/819"
          }}>{`Сколько стоит право на образова-ние? – программный документ для ВДМО за 2015 г.`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;