import React from "react";
import { ReactComponent as FocusIcon } from "../../icons/ic_article_16dp.svg";
import css from "@emotion/css";
import { useTheme } from "hooks";
import * as M from "../../materials";

export function FocusLogo() {
  const { palette, breakpoints } = useTheme();

  return (
    <div
      css={css`
        grid-column: ${M.layoutAreas.full};
      `}
    >
      <FocusIcon
        css={css`
          margin: 0 auto;
          color: ${palette.brand.blue.dark};
          width: ${M.spacing.base8(25)};
          height: ${M.spacing.base8(25)};

          @media ${breakpoints.down("l")} {
            width: ${M.spacing.base8(15)};
            height: ${M.spacing.base8(15)};
          }
        `}
      />
    </div>
  );
}
