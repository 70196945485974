import React from "react";

/** Use to temporary set a value and then revert back to initial value. */
export const useStateWithAutoRevert = <T>({
  timeout = 1000,
  revertState,
}: {
  timeout?: number;
  revertState: T;
}): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = React.useState(revertState);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setState(revertState);
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [state, timeout, revertState]);

  return [state, setState];
};
