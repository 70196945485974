import React from "react";
import css from "@emotion/css";
import * as M from "../../materials";

/**
 *
 * This is an early draft of a component that will be used to display images in the focus pages.
 * Sizing implementation is currently naive and will be improved to work for mobile.
 */
export function FocusImage({
  src,
  alt,
  href,
  size,
}: {
  src: string;
  alt: string;
  href: string;
  size?: "small" | "large" | "auto";
}) {
  try {
    const imageSrc = require(`./files/focus/${src}`);
    return (
      <div
        css={css`
          text-align: center;
          margin: ${M.spacing.base8(4)} 0;
        `}
      >
        <a href={href} target="_blank" rel="noreferrer">
          <img
            css={css`
              width: ${size === "auto"
                ? "auto"
                : size === "small"
                ? "75%"
                : "100%"};
              max-width: 100%;
            `}
            src={imageSrc}
            alt={alt}
          />
        </a>
      </div>
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}
