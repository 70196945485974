import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "HERO_ACCESS",
  title: /*i18n*/ { id: "fig.access.HERO.title", defaults: `Access` },
  caption: /*i18n*/ {
    id: "fig.access.HERO.caption",
    defaults: `Globally, 89% of children complete primary school, 76% of adolescents complete lower secondary school and 50% of youth complete upper secondary school.`,
  },
  source: /*i18n*/ {
    id: "fig.access.HERO.source",
    defaults: `GEM Report team analysis using household survey data`,
  },
};
