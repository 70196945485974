import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { rollup, sum } from "d3-array";
import { scaleLinear } from "d3-scale";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { BarChart } from "../charts/BarChart";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { metadata } from "../data/data_finance_fig_TOTBYSOURCE";
import {
  ChartRenderer,
  mkGemDataDecoder,
  RegionId,
  useButtonGroupState,
  useNamedRegions,
} from "../domain";
import { insertionOrderSet, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O } from "../prelude";

export * from "../data/data_finance_fig_TOTBYSOURCE";

const Source = io.keyof({
  Donors: null,
  Government: null,
  Households: null,
});
type Source = io.TypeOf<typeof Source>;

const sourceLookup: Record<Source, MessageDescriptor> = {
  Donors: t("fig.finance.TOTBYSOURCE.donors")`Donors`,
  Government: t("fig.finance.TOTBYSOURCE.goverment")`Government`,
  Households: t("fig.finance.TOTBYSOURCE.households")`Households`,
};

export const Data = mkGemDataDecoder(["ind_id", "region"], {
  source: Source,
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: rawData }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const [selectedUnit, actions] = useButtonGroupState("unit");
  const { activeSection } = useReadScrolly();

  const [scrollyUnit, scrollyId] = pipe(
    activeSection,
    // Format: "relative,1", but we throw away the "1" (sections need to have distinct IDs)
    O.map((str) => {
      const [a = "", b = ""] = str.split(",");
      return [a, b] as [string, string];
    }),
    O.getOrElse(() => ["absolute", "0"])
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "unit",
      {
        type: "ButtonGroup",
        selected: "absolute",
      },
      [
        { value: "absolute", label: i18n._(t`USD trillion`) },
        { value: "relative", label: i18n._(t`Share`) },
      ]
    );
  }, [i18n, actions]);

  React.useEffect(() => {
    if (O.isSome(activeSection)) {
      actions.updateSelectionControl("unit", scrollyUnit);
    }
  }, [i18n, actions, activeSection, scrollyUnit, scrollyId]);

  const isAbsolute = selectedUnit === "absolute";

  const data_ = useNamedRegions(rawData);
  const data = React.useMemo(
    () => data_.map((x) => ({ ...x, source: i18n._(sourceLookup[x.source]) })),
    [i18n, data_]
  );

  const stretchedScale = React.useMemo(() => {
    const scales = rollup(
      data,
      (xs) =>
        scaleLinear()
          .domain([0, sum(xs, (x) => x.value)])
          .range([0, 1]),
      (x) => x.region
    );
    return (region: RegionId, value: number) =>
      (scales.get(region) as $FixMe)(value);
  }, [data]);

  const chartData = React.useMemo(() => {
    return isAbsolute
      ? data
      : data.map((x) => ({ ...x, value: stretchedScale(x.region, x.value) }));
  }, [isAbsolute, data, stretchedScale]);

  const sourceKeys = insertionOrderSet(unsafeFromArray(data), (x) =>
    i18n._(x.source)
  );

  const colorPalette = M.fromCount(
    M.colorRanges.sequential[0],
    sourceKeys.length
  );

  return (
    <>
      <BarChart
        tLabel={(s) => s}
        y="region_name"
        xTicks={isAbsolute ? undefined : [0, 0.2, 0.4, 0.6, 0.8, 1]}
        color="source"
        colorRange={colorPalette}
        colorLegend
        numberFormat={isAbsolute ? ".2f" : ".1%"}
        barStyle="small"
        values={chartData}
        sort={"none"}
        showTooltip
      />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_TOTBYSOURCE.json"),
  csv: require("../data/data_finance_fig_TOTBYSOURCE.zip"),
  xlsx: require("../data/data_finance_fig_TOTBYSOURCE.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
